angular
    .module('app').directive("mdCheckboxGroup", function () {
    function link(scope, element, attrs, ngModel) {
        
        scope.values = {};
        
        ngModel.$render = function() {
            if (scope.ngModel) {
                _.forEach(scope.options, (label,option) => {
                    scope.values[option] = scope.ngModel.includes(option);
                });
            }
        };

        scope.$watch('values', function(checkboxValues) {
            let value = _.keys(_.pickBy(checkboxValues));
            
            if (!_.isEqual(value, ngModel.$viewValue)) {
                ngModel.$setViewValue(value)
            }
        }, true );

        ngModel.$isEmpty = function(value) {
            return !value || value.length === 0;
        };
    }

    return {
        link: link,
        template: `
                <div ng-repeat="(option,label) in options">
                    <md-checkbox ng-model="values[option]" ng-disabled="ngDisabled">{{ label }}</md-checkbox>
                </div>`,
        restrict: 'E',
        transclude: true,
        require: '?ngModel',
        scope: {
            ngModel: '<',
            ngDisabled: '<',
            options: '<',
        }
    };
});