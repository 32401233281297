angular
  .module('app')
  .component('payPalSetup', {
    templateUrl: 'paypal-setup.tpl.html',
    controller: payPalSetupController,
    controllerAs: 'vm'
});

function payPalSetupController($scope, payPalService, routingService, entityService, orgService, configSettingsService) {
    const vm = this;
    vm.disconnectPayPal = disconnectPayPal;
    vm.reconnectPayPal = reconnectPayPal;
    vm.loading = true;
    vm.settingsLoading = true;
    vm.settingsSections = [];
    vm.globalPayPalEnabledFlag = orgService.globalSettings.PayPalPartnerEnabled;
    
    if (vm.globalPayPalEnabledFlag) {
      getPayPalInfo();
    } 

    $scope.$on('adoptParent', function(event, configId) {
        if (!configId) {
            vm.reconnectPayPal();
        }
    });

    $scope.$on('overrideParent', function(event, configId) {
        if (!configId) {
            vm.disconnectPayPal()
        }
    });

    configSettingsService.getPayPalSettings().then(function(r) {
        vm.settingsLoading = false;
        if (r.success && r.form) {
            vm.settingsSections = r.form.FormSections;
        }
    });

    vm.onSettingsChange = function(config) {
        return configSettingsService.updateSetting(config);
    };
    
    vm.connectToPayPal = function() {
        if (!vm.partner) {
            payPalService.createPayPalReferral().then(r => {
                if (r.success) {
                    vm.partner = `${r.data.PartnerUrl}&displayMode=minibrowser`;
                    vm.showPayPalConnect = true; 
                }
            });
        } else {
            vm.showPayPalConnect = true;
        }
        
    }

    function getPayPalInfo() {
        return payPalService.getPayPalInfo().then(response => {
              if (response.success) {
                setResponseData(response.data);
                vm.loading = false;
              }                
        });
    }

    function disconnectPayPal() {
        let entityTypeText = '';
        switch (vm.openEntityType) {
            case 'Org':
                entityTypeText = 'all campaigns';
                break;
            case 'CampaignGroup':
                entityTypeText = 'all campaigns in this group';
                break;        
            default:
                entityTypeText = 'this campaign';
                break;
        }
        if (!vm.inherited) {
            vm.confirmHeader = 'Are you sure?';
            vm.confirmBody = `This will remove the option for donors to donate through PayPal from ${entityTypeText}.`;
            vm.confirmButton = 'Remove';
        } else {
            vm.confirmHeader = 'Are you sure?';
            vm.confirmBody = `This will remove PayPal from ${entityTypeText}. Additionally, any future changes made on a parent group will not affect this ${vm.openEntityType === 'CampaignGroup' ? 'group' : 'campaign'}.`;
            vm.confirmButton = 'Override';
        }
        vm.confirmFunc = deactivatePayPal;
        vm.showConfirmDialog = true;
    }

    function deactivatePayPal() {
      return payPalService.deactivatePayPal().then(response => {
        if (response.success) {
          setResponseData(response.data);
        }
      });
    }
    
    function reconnectPayPal() {   
        vm.confirmHeader = 'Are you sure?';
        vm.confirmBody = `This will replace the PayPal account configured on this ${vm.openEntityType === 'CampaignGroup' ? 'group' : 'campaign'} with the account configured on a parent group.`;
        vm.confirmButton = 'Clear override';     
        vm.confirmFunc = adoptParentPayPal;
        vm.showConfirmDialog = true;
    }

    function adoptParentPayPal() {
        return payPalService.reconnectPayPal().then(response => {
            if (response.success) {
                setResponseData(response.data);
            }
        });
    }

    function setResponseData(data) {
        vm.payPalEmail = data.PayPalEmail;
        vm.payPalMerchantId = data.PayPalMerchantID;
        vm.payPalPartnerHref = data.PartnerUrl ? `${data.PartnerUrl}&displayMode=minibrowser` : null;
        vm.hasEnabledPayPal = data.HasEnabledPayPal;
        vm.payPalPending = data.State == 5 && data.Enabled;
        vm.inherited = data.Inherited;
        vm.isDefault = data.IsDefault;
        vm.parentName = data.OverriddenParentName;
        
        let entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
        vm.openEntityType = entity.entityType;
        vm.onOrg = vm.openEntityType == 'Org';
    }
}