angular.module('app').component('modalAddOrgIntegration', {
  bindings: {
    show: '=',
    reload: '<',
  },
  controller: ModalAddOrgIntegrationController,
  controllerAs: 'vm'
});

function ModalAddOrgIntegrationController($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-add-org-integration.tpl.html',
        clickOutsideToClose: false,
        controller: ModalContentCtrl,
        controllerAs: 'vm'
      })
      .then(response => {
        if (response && response.reload && typeof vm.reload == "function") {
          vm.reload();
        }
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($scope, $mdDialog) {
  const vm = this;
  vm.closeModal = () => {
    $mdDialog.cancel({ reload: false });
  };

  vm.location = null;
  vm.open = location => vm.location = location;

  vm.onConnected = () => {
    $mdDialog.hide({ reload: true });
  }
}