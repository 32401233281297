angular
  .module('app')
  .factory('emailDomainsService', emailDomainsService);

function emailDomainsService($http, $q, API_PREFIX) {
    const url = `${API_PREFIX}DomainAuthentication`;

    var DOMAIN_STATUSES = {
        0: 'Unverified',
        1: 'Pending',
        2: 'Verified',
    }
    
    var DOMAIN_STATUS_KEYS = swapArrayForKeys(DOMAIN_STATUSES);

    return {
        getEmailDomains,
        getEmailDomainDetails,
        getEmailDomainStatus,
        getDomainStatusText,
        isVerified,
        verifyDomain,
        addDomain,
        removeDomain,
        DOMAIN_STATUSES,
        DOMAIN_STATUS_KEYS,
    };
    
    function getEmailDomainDetails(domain) {
        return $http.post(`${url}/GetEmailDomainDetails`, { domain })
            .then(response => response.data.emailDomainDetails)
    }

    function getEmailDomains() {
        return $http.post(`${url}/GetAllEmailDomains`)
            .then(response => response.data.allEmailDomains)
    }

    function getEmailDomainStatus(domain) {
        return $http.post(`${url}/GetEmailDomainStatus`, { domain })
            .then(response => response.data.verified)
    }
    
    function addDomain(domain) {
        return $http.post(`${url}/AddDomain`, { domain })
            .then(response => response.data)
    }

    function verifyDomain(domain) {
        const messageConfig = {
            suppressErrorHandling: true
        };
        return $http({ url: `${url}/VerifyDomain`, data: { domain }, method: 'POST', messageConfig })
            .then(response => response.data)
    }
    
    function removeDomain(domain) {
        return $http.post(`${url}/RemoveDomain`, { domain })
            .then(response => response.data.success)
    }
    
    function getDomainStatusText(status) {
        return DOMAIN_STATUSES[status];
    }

    function isVerified(status) {
        return status == DOMAIN_STATUS_KEYS.VERIFIED;
    }
    
    function swapArrayForKeys(arr){
        var ret = {};
        for(var key in arr){
            ret[arr[key].toUpperCase()] = key;
        }
        return ret;
    }

}