angular
  .module('app')
  .factory('settingsService', settingsService);

function settingsService($http, $q, API_PREFIX, entityService, messageService) {
  let target = {};

  let cache = null;
  let inFlightRequest = null;

  return {
    setTargetEntityId,
    getTargetEntityId,
    createNeonLink,
    getPageInfo,
    getTimeZoneInfo,
    getIanaTimeZones,
    getCountries,
    getCDN,
    getHelpLinks,
    getPaymentTypes,

    loadStaticInfo,
  };

  //#region Static Info
  function getTimeZoneInfo() {
    return getStaticInfo().then(info => info.timezone);
  }
  
  function getIanaTimeZones() {
    return getStaticInfo().then(info => info.ianaTimeZones);
  }

  function getCountries() {
    return getStaticInfo().then(info => info.countries);
  }

  function getCDN() {
    return getStaticInfo().then(info => info.cdn);
  }

  function getHelpLinks() {
    return getStaticInfo().then(info => info.helpLinks);
  }

  function getPaymentTypes() {
    return getStaticInfo().then(info => info.paymentTypes);
  }

  function loadStaticInfo() {
    return getStaticInfo();
  }

  function getStaticInfo() {
    if (cache) {
      return $q.resolve(cache);
    }

    if (inFlightRequest) {
      return inFlightRequest;
    }

    inFlightRequest = $http({
      url: `${API_PREFIX}/GetStaticInfo`,
      headers: { orgId: entityService.getOrgId()  },
      method: 'POST',
    }).then(response => { 
      cache = response.data.data;
      cache.ianaTimeZones = cache.ianaTimeZones.map(x => ({ display: x.replace('/', ' - ').replace('_', ' '), value: x }));
      if (cache.retently && !document.querySelector('#retently-survey-embed')) {
        const div = document.createElement('div');
        div.id = 'retently-survey-embed';
        div.setAttribute('data-href', cache.retently.retentlyHref);
        div.setAttribute('data-rel', 'dialog');
        div.setAttribute('data-campaign', 'regular');
        div.setAttribute('data-email', cache.retently.email);
        div.setAttribute('data-firstname', cache.retently.firstName);
        div.setAttribute('data-lastname', cache.retently.lastName);
        div.setAttribute('data-company', cache.retently.companyName);
        div.setAttribute('data-tags', `orgid_${cache.retently.neonOrgId},neon_fundraise,contactid_${cache.retently.neonOrgId}:${cache.retently.neonUserId}`);
        document.body.appendChild(div);
        (function (d, s, id) {
          var js, rjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = "https://cdn.retently.com/public/components/embed/sdk.min.js";
          rjs.parentNode.insertBefore(js, rjs);
        }(document, 'script', 'retently-jssdk'));
      }
      return cache;
    })
      .catch(err => { inFlightRequest = null; cache = null; return $q.reject(err); });

    return inFlightRequest;
  }
  //#endregion

  function setTargetEntityId(entityId) {
    target.entityId = entityId
  }

  function getTargetEntityId(){
    return target.entityId
  }

  function getPageInfo(pageId) {
    var endpoint = getEndpointFromPageId(pageId);
    return $http({
      url: `${API_PREFIX}Cmp/${endpoint}`,
      headers: { entityId: entityService.getOpenedEntityId() },
      method: 'POST',
    }).then(response => response.data);
  }

  function getEndpointFromPageId(pageId) {
    switch (pageId) {
      case 'neon':
        return 'GetNeonPayInfo';    
      default:
        break;
    }
  }

  function createNeonLink(sandbox){
    const messageConfig = {
        advancedErrorFunction: messageService.advancedErrorsDialog,
        advancedErrorEnabled: true
    };
    return $http({ 
      url: `${API_PREFIX}Cmp/CreateNeonPayReferralLink`,
      headers: { entityId: entityService.getOpenedEntityId() },
      data: {sandbox: sandbox},
      messageConfig,
      method: 'POST',
    }).then(response => response.data);
  }
}