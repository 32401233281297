angular.module("app").component("featherIcon", {
    controller: featherIconController,
    controllerAs: "vm",
    template: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
        <path d="M4.254,19.567c0.307-0.982,0.77-2.364,1.391-4.362c2.707-0.429,3.827,0.341,5.546-2.729
        \tc-1.395,0.427-3.077-0.792-2.987-1.321c0.091-0.528,3.913,0.381,6.416-3.173c-3.155,0.696-4.164-0.836-3.757-1.067
        \tc0.939-0.534,3.726-0.222,5.212-1.669c0.766-0.745,1.125-2.556,0.813-3.202c-0.374-0.781-2.656-1.946-3.914-1.836
        \tc-1.258,0.109-3.231,4.79-3.817,4.754C8.573,4.925,8.454,2.864,9.476,0.949c-1.077,0.477-3.051,1.959-3.67,3.226
        \tc-1.153,2.357,0.108,7.766-0.296,7.958c-0.405,0.193-1.766-2.481-2.172-3.694c-0.555,1.859-0.568,3.721,1.053,6.194
        \tc-0.611,1.623-0.945,3.491-0.996,4.441C3.371,19.833,4.119,19.996,4.254,19.567z"/>
        </svg>`,
    bindings: {
      fill: '<'
    }
  });
  
  function featherIconController() {
    var vm = this;
  }