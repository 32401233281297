angular
  .module('app')
  .directive('activeLink', ActiveLink);

function ActiveLink($location, $timeout) {
  return {
    restrict: 'A',
    link($scope, $element) {
      const updateClasses = () => {
      let nextHref = $location.path();

      $scope.$parent.vm.activeLink = undefined;
      angular.forEach($element.find('a'), ((a) => {
          a = angular.element(a);
          let href = (a.attr('href') || a.attr('ng-href'));
          let activeLinkMatch = a.data('activeLink');
		  if(href){
      href = href.split('?')[0];
			if(href.indexOf('.') == 0){
				href = href.substring(1);
			}
			
			if (nextHref == href || (nextHref.indexOf(href) > -1) || (activeLinkMatch && nextHref.indexOf(activeLinkMatch) > -1) ) {
				a.parent().addClass('active');
          a.closest('[top-nav-menu-item]').addClass('active')
                $scope.$parent.vm.activeLink = a.attr('ng-link');
			  } else {
				a.parent().removeClass('active');
			  }
		  }
        }));
      };

      updateClasses();

      $scope.$on('$locationChangeStart', () => {
        updateClasses();
      });
    }
  }
}