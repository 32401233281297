angular
  .module('app')
  .component('modalRemoveGroup', {
    bindings: {
      show: '=',
      groupId: '='
    },
    controller: ModalRemoveGroupCtrl,
    controllerAs: 'vm'
  });

function ModalRemoveGroupCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-remove-group.tpl.html',
        clickOutsideToClose: true,
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          groupId: vm.groupId
        }
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($mdDialog, $rootScope, $route, $document,
                          campaignService, entityService, orgService,
                          groupId) {
  const vm = this;

  vm.remove = remove;
  vm.closeModal = closeModal;

  let group = campaignService.getGroupById(groupId),
      { entityId, parentGroupId: parent} = group;

  checkContent();

  ////////////////////////////////////

  function checkContent() {

    let groups = campaignService.getRawGroups();
    vm.containChildren = groups.some(item => item.parentGroupId == groupId);

    if (!vm.containChildren) {

      let campaigns = campaignService.getRawCampaigns();
      vm.containChildren = campaigns.some(item => item.groupId == groupId)
    }

  }


  function remove() {
    vm.removeInProgress = true;

    entityService.removeGroup(entityId)
      .then(response => {
        campaignService.openGroup(parent, orgService.getOrg().entityId)
          .then((campaignsData) => {
            $rootScope.$emit('tree.changed', campaignsData);
          });

        closeModal()
      })
      .finally(() => vm.removeInProgress = false)
  }


  function closeModal() {
    $mdDialog.hide()
  }


}
