angular
  .module('app')
  .component('splashNotifications', {
    bindings: {
      splashNotificationsData: '<',
      splashLastFinishedNotificationId: '<' 
    },
    controllerAs: 'vm',
    controller: modalAddSplashNotificationCtrl    
  });

function modalAddSplashNotificationCtrl($scope, $rootScope, $mdDialog, cookieService ) {
  const vm = this;

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'splash-notifications.tpl.html',
        clickOutsideToClose: true,
        controller: SplashNotificationsCtrl,
        controllerAs: 'vm',
        locals: {
          splashNotificationsData: vm.splashNotificationsData,
          activeNotificationIds: vm.activeNotificationIds
        }
      })
      .finally(() => {
      });
  }

    vm.$onInit = function () {
      vm.splashNotificationsData = vm.splashNotificationsData.filter(s => s.notificationId > vm.splashLastFinishedNotificationId || s.persistent);
      if(vm.splashNotificationsData.length){
          showModal();
      }
  }
}

function SplashNotificationsCtrl($mdDialog, $http, API_PREFIX, splashNotificationsData,activeNotificationIds, cookieService) {
  const vm = this;
  vm.close = close;
  vm.slickConfig = {
    enabled: true,
    infinite: false,
    draggable: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    event: {
     afterChange: (event, slick, currentSlide, nextSlide) => {
      vm.currentSlide = currentSlide + 1;
    }
 },
  method: {}
 }; 
  vm.splashNotificationsData = splashNotificationsData;
  vm.totalSlides = vm.splashNotificationsData.length;
  vm.currentSlide = 1;

  function close(markAsViewed){
    if (markAsViewed){
      dontShowAgain();
    }    
    $mdDialog.hide();
  }

function dontShowAgain() {
  vm.lastNotificationId = vm.splashNotificationsData[vm.totalSlides-1].notificationId;
  $http({
      url: API_PREFIX + 'Profile/UpdateUserSettings',
      data: { list: { lastFinishedSplashNotificationId: vm.lastNotificationId } },
      method: 'POST'
  });
}

}