angular
    .module('app')
    .directive('selectField', SelectField);

function SelectField() {
    return {
        restrict: 'A',
        link($scope, $element, $attrs) {
            let $input = $element.is('input, md-select, md-checkbox') ? $element : $element.find('input, md-select, md-checkbox').first();

            $element.click(() => {
                $input.focus();
                $input.select();
            })
        }
    }
}