angular
    .module('app')
    .service('requestHelperService', requestHelperService);

function requestHelperService(entityService, messageService, formService, $http, $q, API_PREFIX) {
    
    function RequestHelper(controllerName) {

        this.successToastMessage = function(message) {
            return {
                successToastEnabled: true,
                successMsg: message,
            }
        }

        this.sendEntityRequest = function(actionName, data, messageConfig, entityId = entityService.getOpenedEntityId()) {
            return this.sendRequest(actionName, data, messageConfig, entityId)
        }

        this.sendCampaignRequest = function(actionName, data, messageConfig, campaignId = entityService.getOpenedCampaignId()) {
            return this.sendRequest(actionName, data, messageConfig, campaignId, true)
        }

        this.sendRequest = function(actionName, data, messageConfig = {}, id, campaignRequest) {
            var requestOptions = {
                url: `${API_PREFIX}${controllerName}/${actionName}`,
                data,
                headers: {},
                method: 'POST',
                messageConfig
            };

            if (campaignRequest) {
                requestOptions.headers.campaignId = id;
            } else {
                requestOptions.headers.entityId = id;
            }

            return $http(requestOptions).then(response => response.data);
        }

        this.sendExtJsRequest = function(endpoint, data, eventId = entityService.getOpenEventId()) {
            var requestOptions = {
                url: `/Admin/${endpoint}`,
                data,
                headers: {'__rbhost': eventId},
                method: 'POST',
                messageConfig: {
                    suppressErrorHandling: true,
                }
            };

            return $http(requestOptions).then(response => response.data);
        }
    }
    
    return {
        getInstance: (controllerName) => new RequestHelper(controllerName),
    }
}