angular.module('app').component('textareaItem', {
  bindings: {
    value: '=',
    label: '<',
    configId: '<',
    onChange: '<',
    sectionUpdating: '<',
    number: '<',
    inherited: '<',
    isDefault: '<',
    parentName: '<',
    disabled: '<',
    tooltip: '<',
    entityType: '<',
    noLock: '<',
    useEditor: '<',
    advancedEditing: '<',
  },
  controllerAs: 'vm',
  controller: textareaItemController,
  templateUrl: 'textarea-item.tpl.html'
});

function textareaItemController(ckEditorService, cookieService, $mdDialog, $timeout) {
  var vm = this;

  vm.editorProcessing = false;
  vm.itemEditing = false;
  vm.itemUpdating = false;
  vm.editorId = "editor" + Math.random().toString(16).slice(2);
  
  vm.$onInit = function() {
    if (!vm.advancedEditing) {
      vm.advancedEditing = htmlIsInvalid(vm.value);
    }
  }

  vm.onSave = function() {
    vm.value = ckEditorService.getEditorValue(vm.editorId, vm.value);
    var unchanged = vm.originalValue == vm.value;
    if (vm.inherited || vm.disabled || vm.itemUpdating || unchanged) {
      vm.itemEditing = false;
      return;
    }

    vm.itemUpdating = true;
    
    vm.onChange(vm.configId, vm.value, vm.advancedEditing)
      .then(function(success) {

        if (success) {
          vm.originalValue = vm.value;
          vm.itemEditing = false;
        }
        vm.itemUpdating = false;

        return success;
      });
  };
  
  vm.cancelEdit = function () {
      vm.itemEditing = false;
      vm.value = vm.originalValue;
  }
  
  function htmlIsInvalid(val) {
    if (!val) return;
    
    // To improve accuracy do some replacements that are still valid html that we expect the innerHTML to make
    var initialVal = val.toString()
        .replace(/\s\s+/gi, ' ')
        .replace(/\s*\/>/gi, '>')
    
    var doc = document.createElement('div');
    doc.innerHTML = initialVal;
    
    return doc.innerHTML !== initialVal;
  }
  
  function confirmSwitchToWysiwyg() {
    return $mdDialog.confirm()
        .title('Are you sure?')
        .textContent(`This will switch the editor to WYSIWYG mode. All HTML content (for example, open tags) will be sanitized and validated. This may affect your email design.`)
        .ok('Confirm')
        .cancel('Cancel');
  }
  
  vm.beforeEditorSetMode = function(evnt) {
    
    if (evnt.data === 'source') {
      
      if (!vm.itemEditing && !vm.advancedEditing) {
        vm.editorProcessing = true;
        
        $timeout(() => {
          evnt.editor.destroy();
          vm.editSource(false);
        }, 300); // Magic number ensures that the CodeMirror plugin can fully run the switch to source before destroying the instance as there is no way to cancel it and destroying the instance before it is done running will error
      }

      vm.advancedEditing = true;
    }
    
    if (evnt.data === 'wysiwyg') {
      let currentValue = evnt.editor.getData();

      if (vm.advancedEditing && htmlIsInvalid(currentValue)) {
        
        $mdDialog.show(confirmSwitchToWysiwyg()).then(
            () => vm.advancedEditing = false,
            () => {
              
              // Hack to revert editor to invalid html code
              //   setData() on an editor validates the html code of the data
              //   However a new editor instance can be created with invalid code already set
              //   Therefore destroy editor (ng-if on editor textarea) and re-initialize editor 
              //   after reverting the value
              vm.destroyEditor = true;
              
              // Set the value and "un-destroy" in timeout to run in the next digest cycle
              $timeout(() => {
                vm.value = currentValue;
                vm.destroyEditor = false;
              })
            }
        );
      } else {
        vm.advancedEditing = false;
      }
    }
  }

  vm.editSource = function(editing = true) {
    vm.advancedEditing = true;
    vm.destroyEditor = true;
    vm.value = vm.originalValue;
    vm.editorProcessing = true;

    $timeout(() => {
      vm.destroyEditor = false;
      vm.itemEditing = editing;
      vm.editorProcessing = false;
    })
  }

  const ESC = 27;
  vm.checkHotKey = function(event) {
    var key = event.keyCode;
    if (key === ESC) {
      vm.cancelEdit();
    }
  };
}
