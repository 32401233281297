angular
  .module('app')
  .directive('sideNav', SideNav);


  function SideNav($location, routingService, entityService, configRouteService) {
    return {
        restrict: 'E',
        template: `<ul class="side-nav-list">   
                        <li class="side-nav" ng-repeat="nav in vm.navs" ng-if="canShow(nav)" ng-class="{ active: isActive(nav.path)}">
                            <a ng-link="[nav.name]">
                                {{nav.name}}
                            </a>
                        </li>
                    </ul>`,
        link($scope) {
            $scope.canShow = function(nav){
                return configRouteService.hasPermission(nav.path, nav.data) && configRouteService.shouldShow(nav.data)
            }

            $scope.isActive = function(path){
                return configRouteService.isActive(path);
            }
        }
    }
  }