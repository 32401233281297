angular.module('app').component('startupGuide', {
  controllerAs: 'vm',
  controller: startupGuideController,
  template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'
               loading='vm.loading'></form-template>`,
  require: {
    parentComponent: '^fundraising'
  },
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateNoPendingChanges($nextInstruction);
  }
});

function startupGuideController(configSettingsService, extJsLoader) {
  const vm = this;
  this.$routerOnActivate = function(next, prev) {
    this.parentComponent.loading = false;
    this.parentComponent.showIframe = false;
  };
  vm.sectionUpdating = false;
  vm.sections = [];

  configSettingsService.getStartupGuideSettings().then(function(r) {
    if (r.success && r.form) {
      vm.sections = r.form.FormSections;
    } else {
    }
  });

  vm.onChange = function(config) {
    return configSettingsService.updateSetting(config);
  };
}
