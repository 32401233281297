angular
  .module('app')
  .component('information', {
    templateUrl: 'information.tpl.html',
    controller: infoSettingsCtrl,
    controllerAs: 'vm'
  });



function infoSettingsCtrl($rootScope, settingsService) {
  const vm = this;

  $rootScope.$on('open.settings', (data) => {
    console.log(data);
  });

  vm.name = settingsService.getTargetEntityId() || 'test';
}