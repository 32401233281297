angular
    .module('app')
    .service('pendingChangesService', pendingChangesService);

function pendingChangesService($q, $mdDialog, $window) {
    const vm = this;

    let pendingChange = undefined;
    
    vm.add = function(change) {
        pendingChange = change;
        vm.addWindowEvent();
    }

    vm.clear = function() {
        pendingChange = undefined;
    }

    vm.confirm = function() {
        
        if (vm.hasPendingChanges()) {

            let confirm = $mdDialog
                .confirm()
                .multiple(true)
                .title('Discard unsaved changes?')
                .htmlContent(`You have pending unsaved changes, do you want to discard them?`)
                .ok('Discard Changes')
                .cancel('Cancel');

            return $mdDialog.show(confirm)
                .then(() => vm.clear());
        }
        
        vm.clear();
        return $q.resolve();
    }
    
    vm.hasPendingChanges = function() {
        return pendingChange && (typeof pendingChange !== 'function' || pendingChange());
    }
    
    vm.addWindowEvent = function() {
        $window.onbeforeunload = function () {
            if (vm.hasPendingChanges()) {
                return 'You have unsaved pending changes, are you sure you want to leave?'
            }
        };
    }
}