angular.module('app').component('campaignsGroups', {
  bindings: {
    groups: '=',
    filter: '=',
    sort: '=',
    mode: '@',
    onGroupClick: '&',
    onGroupDbclick: '&',
    onInfoBtnClick: '&',
    onOutsideClick: '&'
  },
  templateUrl: 'campaigns-groups.tpl.html',
  controller: CampaignsGroupsController,
  controllerAs: 'vm',
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateNoPendingChanges($nextInstruction);
  },
});

function CampaignsGroupsController($filter, $rootScope, securityService, orgService) {
  const vm = this;
  
  vm.getGroups = getGroups;
  vm.renameGroup = renameGroup;
  vm.removeGroup = removeGroup;
  vm.getSecurity = getSecurity;

  vm.isSupervisor = orgService.getOrg().supervisor;
  vm.orgId = orgService.getOrgId();

  function getGroups() {
    return $filter('chankArray', vm.groups, 4);
  }

  function renameGroup(entityId, groupId) {
    $rootScope.$emit('group.should.rename', { entityId, groupId });
  }

  function removeGroup(groupId) {
    $rootScope.$emit('group.should.remove', { groupId });
  }

  function getSecurity(entity, resource) {
    return securityService.getAdminPermissions(entity, resource);
  }

  $rootScope.$emit('entity.changedInView');

}