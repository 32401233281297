angular
  .module('app')
  .component('modalAddNewCampaign', {
    bindings: {
      show: '=',
      type: '=',
      replicate: '='
    },
    controller: modalAddNewCampaignCtrl,
    controllerAs: 'vm'
  });

function modalAddNewCampaignCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-add-new-campaign.tpl.html',
        clickOutsideToClose: true,
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          type: vm.type,
          replicate: vm.replicate,
        }
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($mdDialog, $route, $rootScope, $timeout, mdcDateTimeDialog,
                          entityService, campaignService, orgService, messageService,
                          type, replicate, ngModelFiltersService) {
  const vm = this;
  vm.saveInProgress = false;
  vm.campaignType = type;
  vm.replicate = replicate;
  vm.entityId = orgService.getOrg().entityId;
  vm.entityIdParent = entityService.getOpenedEntityId();
  vm.curGroupId = entityService.getOpenedGroupId();
  vm.domains = campaignService.getCampaignDomains();
  vm.path = false;// true = site.domain.com | false = domain.com/site

  vm.cloneCmpSettings = {
      cmpSettings: {
          value: 1,
          checked: false
      },
      paymentProcessor: {
          value: 2,
          checked: false
      },
      templates: {
          value: 4,
          checked: false
      },
      defaults: {
          value: 8,
          checked: false
      },
      siteContent: {
          value: 16,
          checked: false
      },
      adminUsers: {
          value: 32,
          checked: false
      }
  };

  vm.updateSubDomain = updateSubDomain;
  vm.addCampaign = addCampaign;
  vm.replaceInputs = replaceInputs;
  vm.cancel = closeModal;
  vm.validateSubdomain = validateSubdomain;
  vm.validSubdomain = false;
  vm.displayDateDialog = displayDateDialog;
  vm.displayTimeDialog = displayTimeDialog;
  vm.filters = ngModelFiltersService;
  vm.currencySymbol = entityService
    .getEntityWithSettings(entityService.getOpenedEntityId())
    .settings.DefaultCurrency
    .Symbol;
  let campaign = campaignService
  .getCampaignByEntityId(entityService.getOpenedEntityId())
  vm.settingsForm = campaign ? campaign.settingsForm : undefined

  
  function displayDateDialog() {
    mdcDateTimeDialog.show({
        time: false,
        date: true,
        currentDate: vm.date,
        disableParentScroll: true
    })
    .then(function (date) {
        vm.date = new moment(date);
        vm.date.hour(0).minute(0).second(0).millisecond(0);
        vm.dateDisplay = vm.date.format("MM/DD/YYYY");
    }, function () {
    });
  };

  function displayTimeDialog() {
    mdcDateTimeDialog.show({
        time: true,
        date: false,
        shortTime: true,
        currentDate: vm.date,
        disableParentScroll: true
    })
    .then(function (date) {
        vm.date = new moment(date);
        vm.timeDisplay = vm.date.format("h:mm a");
    }, function () {
    });
  };


  if (vm.domains.length == 1){
    vm.onlyOneDomain = true;
    vm.urlDomain = vm.domains[0];
    replaceInputs();
  }

  if (vm.replicate) {
    let campaignId = orgService.getUrlCampaignId();
    let campaign = campaignService.getCampaignById(campaignId);

    vm.ianaTimeZone = campaign.ianaTimeZone;
    if (campaign.groupUrl != null) {
      vm.onlyOneDomain = true;
      vm.urlDomain = campaign.groupUrl;
      replaceInputs();
    } 
  }

  function updateSubDomain() {
    if (vm.name) vm.subDomain = vm.name.toLowerCase().replace(/\s+/g, '-')
  }

  function addCampaign() {
    vm.validateSubdomain();
    if (!vm.validSubdomain) {
        document.getElementById('subDomainInput').focus();
        return;
    }
    vm.saveInProgress = true;

    let data = {
      name: vm.name,
      description: vm.description,
      goalAmount: vm.goalAmount,
      subDomain: vm.subDomain,
      urlDomain: vm.urlDomain,
      location: vm.location,
      campaignGroupId: vm.curGroupId != 0 ? vm.curGroupId : null
    };

    if (data.location) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: data.location }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                var newlocation = results[0].geometry.location;
                data.locationLat = newlocation.lat();
                data.locationLng = newlocation.lng();
                var stateCountry = getGeocodeStateCountry(results[0]);
                data.locationSubdivision = stateCountry.state;
                data.locationCountry = stateCountry.country;
            }
            processCampaign(data);
        });
        return;
    }
      processCampaign(data);
  }

  function getGeocodeStateCountry(data) {
      let loc = {};
      data.address_components.forEach(function(addr) {
          if (addr.types.includes("administrative_area_level_1")) {
              loc.state = addr.short_name;
          } else if (addr.types.includes("country")) {
              loc.country = addr.short_name;
          }
      });
      return loc;
  }

  function processCampaign(data) {

      let messageConfig = {
          advancedErrorFunction: messageService.advancedErrorsDialog,
          advancedErrorEnabled: true,
          successToastEnabled: !vm.replicate,
          successMsg: vm.name + ' campaign added successfully!'
      };

      if (vm.replicate) {
          let copyCmpSettings = Object
              .values(vm.cloneCmpSettings)
              .filter(cmpSetting => cmpSetting.checked)
              .reduce((cmpSettingsTotal, cmpSetting) => cmpSettingsTotal | cmpSetting.value, 0);
          
          let customFields = {};
          if (vm.settingsForm && vm.settingsForm.fields) {

              let formFields = vm.settingsForm.fields
                  .filter(field => field.value)
                  .map(field => {
                      let value;
                      if (field.type === 6) {//multiselect
                          const selectedOptionsId = field.options
                              .filter(option => field.value.includes(option.label))
                              .map(option => option.id);
                          
                          value = selectedOptionsId.join(',');
                      } else {
                          value = field.value;
                      }
                      return {[field.name]: value}
                  });
              Object.assign(customFields, ...formFields);
          }

          let campaignId = orgService.getUrlCampaignId();
          let campaignEntityId = campaignService.getCampaignById(campaignId).entityId;

          Object.assign(
            data, 
              { date: vm.date.format('YYYY-MM-DD[T]HH:mm:ss') }, 
              { cloneCmpSettings: copyCmpSettings },
              { customFields: customFields },
              { isRelaunch: false }
          );

          campaignService.replicateCampaign(campaignEntityId, data, messageConfig)
            .then(response => showAdded(response.data.data))
            .finally(() => vm.saveInProgress = false);
      } else if (vm.campaignType == 'Donation') {
          entityService.newCmpDonation(vm.entityIdParent, data, messageConfig)
            .then(response => showAdded(response), () => { })
            .finally(() => vm.saveInProgress = false)
      } else if (vm.campaignType == 'Standalone Ticketing') {
          entityService.newCmpTicket(vm.entityIdParent, data, messageConfig)
            .then(response => showAdded(response))
            .finally(() => vm.saveInProgress = false)
      } else if (vm.campaignType == 'Template') {
          entityService.newCmpFromTemplate(vm.entityIdParent, data, messageConfig)
            .then(response => showAdded(response))
            .finally(() => vm.saveInProgress = false)
      }
  }

  function closeModal() {
    $mdDialog.hide();
  }

  function showAdded(data) {
      closeModal();
      $rootScope.$emit('tree.clear');

    campaignService.pushToRawCampaigns(data);

    let orgEntityId = orgService.getOrg().entityId;

    campaignService.openGroup(vm.curGroupId, orgEntityId)
      .then((campaignsData) => {
        $rootScope.$emit('tree.changed', campaignsData);
		orgService.updatePathToCampaign(data);
      })
  }

  function replaceInputs() {
    vm.path = /^\./.test(vm.urlDomain);
  }

  function validateSubdomain() {
      let regex = /.*(\.|\/|\s).*/;
      if (String(vm.subDomain).match(regex)) {
          vm.validSubdomain = false;
          messageService.showErrorToast('Please do not use the "/", ".", or " " characters.');
          return;
      }

      //validate url if chaging subdomain
      if (vm.path) {
          regex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/;
          if (!regex.test(String(vm.subDomain))) {
              vm.validSubdomain = false;
              messageService.showErrorToast('Please only enter valid Url characters');
              return;
          }
      } else {
          regex = /^[a-zA-Z0-9-]*$/;
          if (!String(vm.subDomain).match(regex)) {
              vm.validSubdomain = false;
              messageService.showErrorToast('Only letters, numbers, and hyphens are valid for the url path.');
              return;
          }
      }

      vm.validSubdomain = true;
  }

}
