angular
  .module('app')
  .component('sfConnectBtn', {
    templateUrl: 'sf-connect-btn.tpl.html',
    bindings: {
      isSandbox: '=',
      authenticationId: '=',
      buttonText: '@',
      onConnected: '<',
      newWindow: '<'
    },
    controller: SfConnectBtnComponent,
    controllerAs: 'vm'
  });

function SfConnectBtnComponent($scope, $interval, salesforceService, messageService) {
  const vm = this;
  vm.connecting = false;

  window.addEventListener("message", completeConnection, false);
  if (window.BroadcastChannel) {
    const channel = new window.BroadcastChannel("OA_salesforce_connection");
    channel.onmessage = completeConnection;
  }

  vm.connectToSalesforce = () => {
    vm.connecting = true;

    salesforceService.getSalesforceUrl(vm.isSandbox || false, vm.authenticationId)
      .then(data => {
        if (!data.loginPath) {
          vm.connecting = false;
          messageService.showErrorToast("Unable to get Salesforce login link. Please contact support.");
          return;
        }
        // open the popup
        vm.win = window.open(data.loginPath, '_blank', 'location=no,menubar=no,status=no,titlebar=no,toolbar=no');

        vm.windowOpenTimer = $interval(function() {
          if (!vm.win || vm.win.closed) {
            if (!vm.win) {
              messageService.showErrorToast("Salesforce pop-up blocked. Please unblock pop-ups in your browser settings.");
            }
            $interval.cancel(vm.windowOpenTimer);
            vm.connecting = false;
          }
        }, 500);
      })
      .catch(e => {
        vm.connecting = false;
      });
  };

  function completeConnection(event) {
    vm.connecting = false;

    if (event.data.success) {
      if (typeof vm.onConnected == "function") {
        vm.onConnected(event.data);
      }
    }

    if (event.data.redirect) {
      messageService.showSuccessToast("Starting Salesforce to Neon Fundraise Sync...", "Show", vm.win.focus, 30000);
      window.focus();
    } else {
      vm.win.close();
    }
    
    if (!event.data.success && event.data.error) {
      messageService.showErrorToast(event.data.error)
    }
  }
}
