angular
  .module('app')
  .component('objectContainer', {
      bindings: {
          type: '@',
          title: '@',
      },
      transclude: true,
      controller: ObjectFieldController,
      templateUrl: 'object-container.tpl.html',
      controllerAs: 'vm'
  });

function ObjectFieldController($scope, $timeout, $q, $mdDialog, $location, $mdConstant, entityService,
                          campaignService, administratorService, messageService) {
    const vm = this;
}
