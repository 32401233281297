angular
  .module('app')
  .component('integrations', {
    templateUrl: 'integrations.tpl.html',
    controller: integrationController,
    controllerAs: 'vm',
    require: {
        parentComponent: '^settings'
    },
  });

function integrationController(configSettingsService, extJsLoader) {
  const vm = this;

  vm.loading = true;
  vm.sections = [];
  configSettingsService.getIntegrationSettings().then(r => {
    vm.loading = false;
    if (r.success && r.form) {
      vm.sections = r.form.FormSections;
    }
  });

  vm.onChange = function(config) {
    return configSettingsService.updateSetting(config);
  };

  this.$routerOnActivate = function(next, prev) {
    extJsLoader.extJsController.updateInnerPage('Settings-IntegrationOptions');
    this.parentComponent.showIframe = true;
  }
}