angular
  .module('app')
  .factory('automationService', automationService);

function automationService($http, API_PREFIX, $q) {

    return {
        getEntityActions,
        createEntityAction,
        deleteEntityAction,
        getAvailableActions,
        getEntityActionErrorLogs
    }

    function getEntityActions(entityId)
    {
        return $http({
            url: `${API_PREFIX}Admins/GetEntityActions`,
            headers: { entityId },
            method: 'POST'
        })
        .then(response => response.data.data)
    }

    function createEntityAction(entityId, action, scheduledFor)
    {
        const body = {
            action: action,
            scheduledFor: scheduledFor
        }

        return $http({
            url: `${API_PREFIX}Admins/CreateEntityAction`,
            headers: { entityId },
            data: body,
            method: 'POST'
        })
        .then(response => response.data)
    }

    function deleteEntityAction(entityId, actionId)
    {
        return $http({
            url: `${API_PREFIX}Admins/DeleteEntityAction`,
            headers: { entityId },
            data: { actionId },
            method: 'POST'
        })
        .then(response => response.data)
    }

    function getEntityActionErrorLogs(entityId) {
        return $http({
            url: `${API_PREFIX}Admins/GetEntityActionErrorLogs`,
            headers: { entityId },
            method: 'POST'
        })
        .then(response => response.data)
    }

    // cache the action types as they only change on rebuilds
    let actionTypes = undefined;
    function getAvailableActions()
    {
        if (actionTypes) {
            return $q.resolve(actionTypes)
        }

        return $http({
            url: `${API_PREFIX}Admins/GetEntityActionTypes`,
            method: 'POST'
        })
        .then(response => response.data.data)
        .then(types => { actionTypes = types; return actionTypes; })
    }
}
