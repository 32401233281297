angular.module('app').factory('salesforceService', salesforceService);

function salesforceService($http, $q, API_PREFIX) {
    return {
        getSalesforceLogs,
        getSalesforceInfo,
        getSalesforceUrl,
        reSync
    };

    function getSalesforceInfo(id){
        return $http.post(`${API_PREFIX}Integration/SfInfo`, { id })
          .then(response => response.data);
    }

    /**
     * Get the URL to connect to salesforce for authentication
     * @param {boolean} sandbox weather or not to use Sandbox
     * @param {number} authenticationId optional existing authentication id
     * @returns {*}
     */
    function getSalesforceUrl(sandbox, authenticationId) {
        return $http.post(`${API_PREFIX}Integration/SfConnectUrl`, { sandbox, authenticationId })
          .then(response => response.data);
    }

    function getSalesforceLogs(id, from, to) {
        return $http.post(`${API_PREFIX}Integration/SfSyncs`, {
            id,
            to: moment(to).format('YYYY-MM-DD'),
            from: moment(from).format('YYYY-MM-DD')
        })
          .then(response => {
            return response.data.items.map(log => {
                log.InitiatedOn = log.InitiatedOn && new Date(log.InitiatedOn);
                log.LastRetriedOn = log.LastRetriedOn && new Date(log.LastRetriedOn);
                log.canResync = !!(log.ErroredOn || log.CompletedOn);

                
                if (log.CompletedOn) {
                    log.Status = 'successful';
                } else if (log.ErroredOn && log.LastRetriedOn && !log.FirstRetriedOn) {
                    log.Status = 'retrying';
                } else {
                    log.Status = 'failed';
                }

                if (log.CompletedOn) {
                    log.CompletedOn = new Date(log.CompletedOn);
                } else if (log.ErroredOn) {
                    log.CompletedOn = new Date(log.ErroredOn);
                }

                return log;
            });
        });
    }

    function reSync(record) {
        // Don't send other fields back to server in case they have html
        let recordToSync = {
            SalesforceQueueId: record.SalesforceQueueId,
            EventId: record.EventId,
        }
        return $http.post(`${API_PREFIX}Integration/SfResync`, recordToSync).then(response => response.data);
    }
}
