angular
  .module('app')
  .factory('configRouteService', configRouteService);

function configRouteService(transactionRouteConfig, peopleRouteConfig, emailRouteConfig, formsRouteConfig, websiteRouteConfig, fundraisingRouteConfig, systemRouteConfig, entityService, routingService, $location) {

  const vm = this;

  vm.routeConfigs = {
    'transactions': transactionRouteConfig,
    'people': peopleRouteConfig,
    'email': emailRouteConfig,
    'forms': formsRouteConfig,
    'website': websiteRouteConfig,
    'fundraising': fundraisingRouteConfig,
    'system': systemRouteConfig
  }
  function getRouteConfig(name) {
    return vm.routeConfigs[name]
  }

  function isActive(path) {
    return $location.path().endsWith(path);
  }
  function hasPermission(path, data) {
    if (!data.resource) {
      return true;
    }
    return routingService.validateRoutePermission(data, entityService.getEntityWithSettings(entityService.getOpenedEntityId()));
  }
  function shouldShow(data) {
    if (!routingService.willRoute(data, entityService.getEntityWithSettings(entityService.getOpenedEntityId()))) {
      return false;
    }

    if (data.showFunc) {
      if (!data.showFunc(entityService.getEntityWithSettings(entityService.getOpenedEntityId()))) {
        return false;
      }
    }
    return true;
  }
  return {
    getRouteConfig,
    isActive,
    hasPermission,
    shouldShow
  };
}
