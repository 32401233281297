angular.module('app').factory('clearviewService', clearviewService);

function clearviewService($http, $q, API_PREFIX, messageService, crmSyncsService) {
    return {
        getClearViewLogs,
        reSync,
        syncClearView
    };

    /**
     * Load clear view logs from server
     * @param {Date} date date to load records for
     */
    function getClearViewLogs(id, from, to) {
        return crmSyncsService.getCrmLogsForDates('CvSyncs', id, from, to);
    }

    function reSync(record) {
        // Don't send other fields back to server in case they have html
        let recordToSync = {
            ClearViewQueueId: record.ClearViewQueueId,
            EventId: record.EventId,
        }
        return crmSyncsService.reSync(recordToSync, 'CvResync');
    }
      
    function syncClearView(appealCode, entityId) {
        const url = entityId === -1
            ? 'SyncTicketsUsingAppealCode'
            : 'SyncEventAndTicketsUsingAppealCode';

        const messageConfig = {
            advancedErrorFunction: messageService.advancedErrorsDialog,
            advancedErrorEnabled: true
        };

        return $http({
            url: `${API_PREFIX}Cmp/${url}`,
            data: { appealCode: appealCode } ,
            headers: { entityId },
            method: 'POST',
            messageConfig
        }).then(response => response.data);
    }
}
