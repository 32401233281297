angular
  .module('app')
  .component('cardItems', {
    controller: cardItems,
    templateUrl: 'card-items.tpl.html',
    bindings: {
      grid: '=?',
      item: '<?',
      save: '=',
      open: '=',
      close: '=',
      cancel: '=',
      delete: '=',
      afterSave: '=',
      afterSaveField: '=',
      overrideParent: '<',
      adoptParent: '<',
      configId: '<',
      isTerm: '<',
      entityType: '<',
      cardActions: '<',
      editorButtons: '<',
      showActivateOptions: '<',
      editInModal: '<',
      fullScreenModal: '<?',
      newItem: '<',
      alwaysShowDescription: '<',
      titlePlaceholder: '@',
      newButtonText: '@',
      newItemText: '@',
      modalClass: '@',
      security: '<',
      loading: '<',
      saveAndCloseOpenCard: '=?',
      beforeOpenCard: '<?',
    },
    controllerAs: 'vm'
  });


function cardItems($scope, $element, $timeout, $q, messageService, $mdDialog, $document, formService, pendingChangesService) {
  const vm = this;
  
  vm.selectedIndex = undefined;
  
  vm.isCardOpen = isCardOpen;
  vm.toggleCard = toggleCard;
  vm.addNewCard = addNewCard;
  vm.cancelCard = cancelCard;
  vm.cancelNewCard = cancelNewCard;
  vm.titleEditable = titleEditable;
  vm.saveAndCloseCard = saveAndCloseCard;
  vm.saveCardIfChanges = saveCardIfChanges;
  vm.saveCard = saveCard;
  vm.activateCard = activateCard;
  vm.deactivateCard = deactivateCard;
  vm.closeCard = closeCard;
  vm.deleteCard = deleteCard;
  vm.isEditable = isEditable;
  vm.isDeletable = isDeletable;
  vm.isDirty = isDirty;
  vm.inEditMode = inEditMode;
  vm.editModalOpen = editModalOpen;
  vm.editCard = editCard;
  vm.fieldName = fieldName;
  
  function isCardOpen(index) {
    return index === vm.selectedIndex;
  }
  
  function toggleCard(item, index) {
    if (vm.beforeOpenCard) {
      vm.beforeOpenCard()
          .then(() => hideShowCard(item, index).then(angular.noop, angular.noop))
          .catch(angular.noop);
    } else {
      hideShowCard(item, index).then(angular.noop, angular.noop);
    }
  }

  function addNewCard() {
    if (vm.newCardShowing) return;

    let newCard = _.cloneDeep(vm.newItem);
    newCard.IsNew = true;
    vm.grid.push(newCard);

    let index = vm.grid.length - 1;
    hideShowCard(newCard, index)
        .then(() => {
          $timeout(() => {
            if (!vm.editInModal) {
              newCard.data.form.$setDirty()
            }
            editCard(newCard);
          });
          vm.newCardShowing = true;
        })
        // Remove new card if unable to open it (i.e. an invalid save is in progress on a different open card)
        .catch(() => vm.grid.pop());

  }

  function titleEditable(item, $index) {
    return vm.isCardOpen($index) && item.TitleEditable && inEditMode(item);
  }
  
  function ensureCardOpen(item) {
    return hideShowCard(item, getCardIndex(item), true)
  }
  
  function hideShowCard(item, index, keepOpen) {
    
    if (vm.updating) return $q.reject();
    
    if (vm.singleCardMode && !keepOpen) {
      return saveAndCloseCard(item);
    }
    
    if (vm.selectedIndex !== index) {
      return saveAndSwitchCard(item, index);
      
    } else if(!keepOpen) {
      return saveAndCloseCard(item)
    }

    return $q.resolve();
  }

  function saveAndSwitchCard(item, index) {
    var openItem = vm.grid[vm.selectedIndex];
    
    if (openItem) {
      return saveAndCloseCard(openItem, undefined, true)
          .then(() => openCard(item, index))
          .catch(() => $q.reject());
    } else {
      openCard(item, index);
      return $q.resolve();
    }
  }

  function saveAndCloseCard(item, $event, externalSave) {
    if (inEditMode(item) && isDirty(item)) {
      return saveCard(item, $event, externalSave).then(() => closeCard(item)).catch(() => $q.reject());
    } else {
      closeCard(item)
      return $q.resolve();
    }
  }
  
  function saveAndCloseOpenCard() {
    if (vm.updating) return $q.reject();
    var openItem = vm.grid[vm.selectedIndex];

    if (openItem) {
      return saveAndCloseCard(openItem, undefined, true)
          .catch(() => $q.reject());
    } else {
      return $q.resolve();
    }
  }

  function openCard(item, index) {
    vm.cardBeforeChanges = _.cloneDeep(item);
    vm.selectedIndex = index;
    item.IsEditable = isEditable(item);

    if (!item.data) {
      item.data = {};
    }

    item.saveField = (field, value) => saveField(item, field, value);
    item.saveIfChanges = (externalSave) => saveCardIfChanges(item, null, externalSave);

    if (inEditMode(item)) {
      addPendingChange(item);
    }

    if (!vm.singleCardMode && !(vm.editInModal && item.IsNew)) {
      scrollToItem(index);
    }

    if (vm.open) {
      vm.open(item);
    }
  }

  function closeCard(item) {
    vm.selectedIndex = undefined;
    vm.newCardShowing = false;
    item.IsNew = false;
    item.InEditMode = false;
    
    if (vm.close) {
      vm.close(item);
    }

    clearPendingChanges();
  }
  
  function deleteCard(item, $event) {
    if (vm.delete) {
      let index = getCardIndex(item);

      return $mdDialog.show(
        $mdDialog.confirm()
          .clickOutsideToClose(true)
          .title('Confirm Delete')
          .htmlContent(`Are you sure you want to delete ${item.Title}?`)
          .targetEvent($event)
          .ok('Delete')
          .cancel('Cancel')
          
      ).then(() => 
        vm.delete(item).then(r => {
          if (!vm.singleCardMode) {
            vm.selectedIndex = undefined;
            vm.grid.splice(index, 1);
          }
          clearPendingChanges();
        })
      )
      
    }
  }
  
  function saveCardIfChanges(item, $event, externalSave) {
    if (inEditMode(item) && isDirty(item)) {
      return saveCard(item, $event, externalSave);
    }
    
    item.InEditMode = false;
    return $q.resolve();
  }

  function saveCard(item, $event, externalSave) {

    return validateCard(item)
      .then(() => {
        vm.updating = true;
    
        return vm.save(item)
          .then(() => {
            item.data.form.$setPristine();
            item.data.inEditorFormDirty = false;
            item.InEditMode = false;
            item.IsNew = false;
            vm.newCardShowing = false;

            if (item.data.titleForm) {
              item.data.titleForm.$setPristine();
            }

            if (vm.afterSave) {
              vm.afterSave(item);
            }
          })
          .catch(response => {
            if (response.data && response.data.error) {
              return $q.reject(response.data.error);
            }
            return $q.reject();
          })
          .finally(() => {
            vm.updating = false;
          });
      })
      .catch(error => {
        if (error) {
          showCardError(item, error, externalSave);
        }
        return $q.reject();
      })
  }
  
  function showCardError(item, error, externalSave) {
    let parent = item.data.form.$$element.closest('md-card');

    if (externalSave) {
      $document.scrollToElement(parent, 15, 300);
    }

    messageService.showErrorToast(error, parent, 3000);
  }

  function activateCard(item, $event) {
    return saveActiveOrInactiveOnCard(item, true);
  }

  function deactivateCard(item, $event) {
    return saveActiveOrInactiveOnCard(item, false);
  }
  
  function saveActiveOrInactiveOnCard(item, active) {
    let clonedItem = _.cloneDeep(vm.cardBeforeChanges)
    clonedItem.IsActive = active;

    return vm.save(clonedItem)
        .then(() => {
          vm.cardBeforeChanges.IsActive = active;
          item.IsActive = active;
        });
  }
  
  function saveField(item, fieldName, value) {
    let clonedItem = _.cloneDeep(vm.cardBeforeChanges)
    let field = formService.getFieldFromItem(clonedItem, fieldName);
    
    if (field) {
      field.value = value;
      
      return vm.save(clonedItem)
          .then(() => {

            formService.setFieldValueOnItem(item, fieldName, value)
            formService.setFieldValueOnItem(vm.cardBeforeChanges, fieldName, value)
            
            if (vm.afterSaveField) {
              vm.afterSaveField(item, fieldName, value);
            }
          });
    }
    
    return $q.reject();
  }
  
  function cancelNewCard() {
    vm.selectedIndex = undefined;
    vm.newCardShowing = false;
    vm.grid.pop();

    clearPendingChanges();
  }
  
  function cancelCard(item) {
    let revertedCard = revertCardFields(item);
    
    if (!vm.singleCardMode && !vm.editInModal) {
      closeCard(revertedCard)
    } else {
      revertedCard.InEditMode = false;
    }

    if (vm.cancel) {
      vm.cancel(revertedCard);
    }

    clearPendingChanges();
  }
  
  function revertCardFields(item) {
    _.forEach(item.Fields, field => {
      let cachedField = formService.getFieldFromItem(vm.cardBeforeChanges, field.name);
      
      if (cachedField) {
        field.value = cachedField.value;
        field.displayOptions = _.cloneDeep(cachedField.displayOptions);
      }
    })
    item.Title = vm.cardBeforeChanges.Title;
    return item;
  }
  
  function validateCard(item) {
    
    let errors = [];
    
    if (item.TitleEditable && !item.data.titleForm.$valid) {
      errors.push(`${vm.titlePlaceholder || 'Title'} is required`);
    }

    if (!item.data.form.$valid) {
      errors = item.formErrors(true);
    }
    
    if (errors.length) {
      return $q.reject(_.first(errors));
    } else {
      return $q.resolve();
    }
  }
  
  function isEditable(item) {
    return vm.entityType == 'Org' || !(item.Inherited || item.IsDefault) && (!vm.security || vm.security.EDIT);
  }
  
  function isDeletable(item) {
    return vm.delete && (!vm.security || vm.security.DELETE);
  }
  
  function inEditMode(item) {
    return isEditable(item) && ((!vm.editInModal && !vm.singleCardMode) || item.InEditMode);
  }
  
  function editModalOpen(item) {
    return inEditMode(item) && vm.editInModal;
  }
  
  function editCard(item, $event) {
    vm.cardBeforeChanges = _.cloneDeep(item);
    item.InEditMode = true;
    addPendingChange(item);

    if (vm.editInModal) {
      openEditModal(item, $event);
    }
  }
  
  function openEditModal(item, $event) {
    
    $mdDialog
        .show({
          templateUrl: 'card-item-modal.tpl.html',

          // Handled by modal itself to allow intercepting close on server errors
          escapeToClose: false,
          clickOutsideToClose: false,
          
          targetEvent: $event,

          controller: editModalController,
          controllerAs: 'vm',
          multiple: true,
          locals: {
            item: item,
            save: vm.newCardShowing ? saveCard : saveCardIfChanges,
            cancel: vm.newCardShowing ? cancelNewCard : cancelCard,
            titlePlaceholder: vm.titlePlaceholder,
            editorButtons: vm.editorButtons,
            isNewCard: vm.newCardShowing,
            fullScreenModal: vm.fullScreenModal,
            modalClass: vm.modalClass,
          },
          onComplete: function(scope) {
            scope.loaded = true;
          },
        })
  }

  function editModalController($scope, $mdDialog, $timeout, item, save, cancel, editorButtons, titlePlaceholder, isNewCard, fullScreenModal, modalClass) {
    const vm = this;
    vm.item = item;
    vm.save = save;
    vm.cancel = cancel;
    vm.editorButtons = editorButtons;
    vm.titlePlaceholder = titlePlaceholder;
    vm.isNewItem = isNewCard;
    vm.steps = isNewCard;
    vm.fullScreenModal = fullScreenModal;
    vm.modalClass = modalClass;
  
    vm.item.InPopup = true;
    vm.item.data.$popupScope = $scope;
    
    vm.closeModal = function() {
      vm.item.InPopup = false;
      $mdDialog.hide();
    }
    
    vm.saveAndClose = function() {
      return vm.save(vm.item).then(vm.closeModal);
    }
    
    vm.cancelAndClose = function() {
      vm.cancel(vm.item)
      vm.closeModal();
    }

    vm.nextGroup = function() {
      $scope.$broadcast('nextGroup');
    }

    $scope.$watch('vm.item.data.form.$valid', function (valid) {
      if (!valid && vm.item.data && vm.item.data.form) {
        $scope.$broadcast('markGroupIncomplete', vm.item.data.form.activeGroup)
      }
    });
  }
  
  function fieldName(field) {
    return field.name.replace()
  }
  
  function getCardIndex(item) {
    return vm.grid.findIndex(f => f.Id == item.Id);
  }
  
  function addPendingChange(item) {
    pendingChangesService.add(() => itemHasPendingChanges(item));
  }

  function clearPendingChanges() {
    pendingChangesService.clear();
  }
  
  function itemHasPendingChanges(item) {
    return inEditMode(item) && isDirty(item);
  }
  
  function isDirty(item) {
    if (!item.data.form) {
      return false;
    }
    
    return item.data.form.$dirty || item.data.inEditorFormDirty || (item.TitleEditable && item.data.titleForm && item.data.titleForm.$dirty);
  }
  
  function scrollToItem(index) {
    $timeout(() => {
      let el = $element.find('.card-item-' + index);
      $document.scrollToElement(el, 15, 300);
    })
  }

  vm.$onInit = function() {
    if (vm.item) {
      vm.grid = [vm.item];
      vm.singleCardMode = true;
      openCard(_.first(vm.grid), 0);
    }

    vm.saveAndCloseOpenCard = saveAndCloseOpenCard;
  }

  $scope.$on('overrideParent', function(event, item) {
    ensureCardOpen(item).finally(() => {
      if (vm.overrideParent) {
        vm.overrideParent(item).then(() => {          
          item.IsDefault = false;
          item.Inherited = false;
          vm.cardBeforeChanges = _.cloneDeep(item);
        });
      }
    });
  });

  $scope.$on('adoptParent', function(event, item) {
    if (vm.adoptParent) {
      vm.adoptParent(item).then(newItems => {
        let newItem = newItems.find(f => f.Id == item.Id);
        let index = getCardIndex(item);
        vm.grid[index] = newItem;
        vm.cardBeforeChanges = _.cloneDeep(newItem);
        
        if (vm.isCardOpen(index)) {
          if (vm.open) {
            vm.open(newItem);
          }
        }
      });
    }
  });
}
