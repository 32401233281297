angular
  .module('app')
  .component('dictionary', {
    controller: dictionaryController,
    templateUrl: 'dictionary.tpl.html',
    bindings: { 
      $router: '<'
    },
    require: {
      website: '^website'
    },
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function dictionaryController(entityContentService, orgService, entityService) {
  const vm = this;
  this.$routerOnActivate = function (next, prev) {
    this.website.showIframe = false;
    this.website.loading = false;
  }
  vm.entityType = entityService.getOpenEntity().entityType;
  vm.termType = 'Dictionary';
  vm.getData = getData;
  vm.loading = true;

  function getData() {
    dataRequest()
      .then(items => {
        vm.grid = items;
      }).catch(() => {

      }).finally(() => vm.loading = false);
  }

  function dataRequest() {
     return entityContentService.getAllTerms(vm.website.openEntity, vm.selectedLanguage)
  }

  orgService.orgInfoLoadedPromise.then(r => {
    entityService.getEntitySettings(vm.website.openEntity).then(entity => {
      vm.languages = entity.settings.LanguagesKeyValue;
      vm.selectedLanguage = entity.settings.DefaultLanguage;
      vm.isMultiLanguage = entity.settings.IsMultiLanguage;
      getData();
    });
  });

  vm.clicked = function(item) {
    return vm.saveTerm(item, false);
  }

  
  vm.open = function(item) {
    entityContentService.getTemplatePlaceholders(vm.website.openEntity, item.Flags).then(items =>
      CKEDITOR.config.macros = items
    );
  }
  vm.overrideParent = function(item) {
    return vm.saveTerm(item, true);
  }

  vm.saveTerm = function(item, createDuplicate) {
    return entityContentService.updateTerm(vm.website.openEntity, item, vm.termType, vm.selectedLanguage, createDuplicate);
  }

  vm.adoptParent = function(item) {
    return entityContentService.deleteTerm(vm.website.openEntity, item, vm.termType, vm.selectedLanguage).then(() => dataRequest());
  }
}