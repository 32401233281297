
var routeConfig = [     
  {
    path: '/',
    name: 'PeopleRouter',
    component: 'peopleRouter',
    data: { dontRoute: true,
           showFunc: function() {return false;} },
    useAsDefault: true
  }, 
  {
    path: '/contacts',
    name: 'Contacts',
    component: 'contacts',
    data: {
      resource: 'PEOPLE_CONTACTS',
      hideOnStandaloneAndTicketing: true,
      limitToCampaign: true
    }
  },
  {
    path: '/donors',
    name: 'Donors',
    component: 'donors',
    data: {
      resource: 'PEOPLE_DONORS',
      limitToCampaign: true
    }
  },
  {
    path: '/registrants',
    name: 'Registrants',
    component: 'registrants',
    data: {
      resource: 'PEOPLE_REGISTRANTS',
      hideOnStandaloneAndTicketing: true,
      limitToCampaign: true
    }
  },
  {
    path: '/teams',
    name: 'Teams',
    component: 'teams',
    data: {
      resource: 'PEOPLE_TEAMS',
      hideOnStandaloneAndTicketing: true,
      limitToCampaign: true
    }
  },
  {
    path: '/nationalTeams',
    name: 'National Teams',
    component: 'nationalTeams',
    data: {
      resource: 'PEOPLE_NATIONAL_TEAMS',
      routeFunc: function(entity) {
        return entity.settings.NationalTeamsEnabled;
      }
    }
  }
];

angular
  .module('app')
  .component('people', {
    templateUrl: 'people.tpl.html',
    controllerAs: 'vm',
    bindings: { $router: '<' },
    require: {
      campaigns: '^campaigns'
    },
    controller: peopleController,
    $routeConfig: routeConfig,
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
  });

  angular.module('app').constant('peopleRouteConfig',routeConfig);

function peopleController($rootScope, routingService, entityService, orgService, extJsLoader, iframeMessageHandler, $timeout) {
  const vm = this;
  vm.loading = true;
  vm.navs = routeConfig;
  vm.showComposeModal = false;
  vm.iframeData = {};
  vm.showIframe = false;

  this.$routerOnDeactivate = function(p, n) {
    extJsLoader.extJsController.iframeCreated = false;
  }

  routingService.updateRouting()
  .then(r => {
      vm.openEntity = r;
      let entity = entityService.getRawEntityById(r);
      if (entity) {
        vm.onGroup = entity.entityType === 'CampaignGroup';
        vm.onOrg = entity.entityType === 'Org';
        if (entity.curEventId) {
          vm.iframeSrc = `/site/${entity.curEventId}?type=plat&value=`;
          if (!extJsLoader.extJsController.iframeCreated) {
            vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'people');
          } else {
            vm.loading = false;
          }
          
          iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
        } else {
          vm.loading = false;
        }
      }
  });

  $rootScope.$on('loaded', r => {
    extJsLoader.extJsController.loadInnerPage().then(r => {
      vm.loading = false;
      extJsLoader.extJsController.checkParams();
    });
  });

  $rootScope.$on('emailFromReports', ($event, targetEvent) => {
    $timeout(function(){
      vm.showComposeModal = true;
    });
  });
}
