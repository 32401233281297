var routeConfig = [      
  {
    path: '/integrations',
    name: 'Integrations',
    component: 'integrations',
    useAsDefault: true,
    data: {
      resource: 'SETTINGS_INTEGRATION_OPTIONS'
    }
  },
  {
    path: '/template',
    name: 'Templates',
    component: 'liquidTemplate',
    data: {
      showFunc: function() { return false; }
    }
  },
  {
    path: '/paymentProcessor',
    name: 'Payment Processors',
    component: 'paymentProcessors',
    data: {
      resource: 'SETTINGS_DONATION_PROCESSOR',
      limitToCampaign: true,
      hideOnNewOrgs: true,
      routeFunc: function(entity) {
        return location.search.indexOf('pass') >= 0;
      }
    }
  },
  {
    path: '/neonPay',
    name: 'Neon Pay Account',
    component: 'neonPay',
    data: {
      resource: 'SETTINGS_DONATION_PROCESSOR',
    }
  },
  {
    path: '/processors',
    name: 'Processors',
    component: 'processors',
    data: {
      resource: 'SETTINGS_DONATION_PROCESSOR',
    }
  },
  {
    path: '/advanced',
    name: 'Advanced',
    component: 'systemAdvanced',
    data: {
      anyResource: ['SYSTEM_NOTIFICATIONS']
    }
  },
  {
    path: '/advancedAdmin',
    name: 'Advanced Admin',
    component: 'advancedAdmin',
    data: {
      showFunc: function() { return false; },
    }
  },
];

angular
  .module('app')
  .component('settings', {
    templateUrl: 'settings.tpl.html',
    controllerAs: 'vm',
    controller: settingsController,
    require: {
      parentComponent: '^campaigns'
    },
    $routeConfig: routeConfig,
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateNoPendingChanges($nextInstruction);
    }
  });

  angular.module('app').constant('systemRouteConfig',routeConfig);

  function settingsController($location, routingService, entityService, orgService, $rootScope, extJsLoader, iframeMessageHandler) {
    const vm = this;
    vm.navs = routeConfig;
    vm.iframeData = {};
    vm.showIframe = false;

    this.$routerOnDeactivate = function(p, n) {
      extJsLoader.extJsController.iframeCreated = false;
    }

    routingService.updateRouting()
    .then(r => {
        vm.openEntity = r;
        let entity = entityService.getRawEntityById(r);
        if (entity) {
          vm.onGroup = entity.entityType === 'CampaignGroup';
          vm.onOrg = entity.entityType === 'Org';
          vm.onCampaign = !vm.onGroup && !vm.onOrg;
          if (entity.curEventId) {
            addOnSite(entity);
            vm.iframeSrc = `/site/${entity.curEventId}?${location.search.indexOf('pass') > -1 ? `pass=true&` : ``}type=plat&value=`;
            if (!extJsLoader.extJsController.iframeCreated) {
              vm.loading = true;
              vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'settings');
            } else {
              vm.loading = false;
            }
            
            iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
          }
        }
    });

    function addOnSite(entity) {
      if (entity.settings.EnableOnSite) {
        angular.element('side-nav').find('ul').append(`<li><a href="/site/${entity.curEventId}/Here" target="_blank">On Site</a></li>`)
      }
    }

    $rootScope.$on('loaded', r => {
      extJsLoader.extJsController.loadInnerPage().then(r => vm.loading = false);
    });

    vm.$onInit = function() {
        vm.parentComponent.onSettingsTab = true;
    };
  }