angular
    .module('app')
    .service('placeholdersService', placeholdersService);


function placeholdersService() {
    const vm = this;

    /**
     * @typedef {Object} PlaceholderCondition
     * @property {string}                       tag         the opening tag as it appears in the source code.
     * @property {string}                       label       the descriptive label of the tag, shown to the user.
     * @property {boolean}                      [checked]   user chose this tag to be rendered as true/false (undefined uses default).
     * @property {string}                       keyword     the liquid keyword for the tag, e.g. if, elsif etc.
     * @property {boolean}                      [isGroup]   the tag is a group of related tags e.g. if and elsif tags together.
     * @property {array[PlaceholderCondition]}  [items]     sub items belonging in this group.
     */
    

    /**
     * Gets conditional liquid placeholders from source code.
     * @param  {string}          sourceCode   Source code with liquid tags.
     * @return {array[PlaceholderCondition]}  List of placeholder conditions from the source code.
     */
    vm.getConditionsFromPlaceholders = function(sourceCode) {
        var placeholderConditions = [];
        let previousGroupTag = undefined;

        /*
            Matches an opening/closing tags for liquid conditionals:
            
            Examples:
               {% if condition == true %}
                - matches[0] = Tag:       {% if condition == true %}
                - matches[1] = Keyword:   if
                - matches[2] = label:     condition == true
                
               {% endif %}
                - matches[0] = Tag:       {% endif %}
                - matches[1] = Keyword:   endif
                - matches[2] = label:     undefined
        */
        
        const regex = new RegExp('{% ?(if|unless|elsif|endif|endunless) ?(.*?)?? ?%}', 'mig');
        let m;

        while ((m = regex.exec(sourceCode)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            
            let condition = {
                tag: m[0],
                label: formatLabel(m[2]),
                checked: undefined,
                keyword: m[1],
            };

            /*
               When reaching an endif or endunless reset the previous group tag start
               This is to prevent a elsif being grouped with a nested if statement withing the starting if statement
            
               For example:
               {% if outerCondition %} {% if innerCondition } {% endif %} {% elseif otherOuterCondition %} {% endif %}
               Without this, otherOuterCondition would be grouped with innerCondition, with this no groupings are created
            */
            if (condition.keyword === 'endif' || condition.keyword === 'endunless') {
                previousGroupTag = undefined;
                continue;
            }
            
            // Only show the tag as an option once
            // Necessary because for duplicate tags we do not have a way to replace only a specific occurrence when recompiling the body
            if (placeholderConditions[condition.tag]) continue;

            // If possible (i.e. obvious which if statement it belongs to),
            // consolidate elseif conditions into single group with original if statement
            if (condition.keyword === 'elsif' && previousGroupTag && placeholderConditions[previousGroupTag]) {
                var previousItem = placeholderConditions[previousGroupTag];

                if (!previousItem.items) {
                    var firstInGroup = _.clone(previousItem);
                    previousItem.items = [];
                    previousItem.items.push(firstInGroup)
                    previousItem.isGroup = true;
                    previousItem.label = '';
                }

                previousItem.items.push(condition);
                
            } else {
                
                placeholderConditions[condition.tag] = condition;

                if (condition.keyword === 'if' || condition.keyword === 'unless') {
                    previousGroupTag = condition.tag;
                }
            }
        }
        
        return _(placeholderConditions)
            .values()
            .forEach(generalizeGroup);
    }
    
    function generalizeGroup(condition) {
        /*
            If all the conditions in the group follow a similar pattern then generalize
            the labels and give the entire group a label
            
            Pattern matched is:
             GroupName == 'IndividualCondition'
            
            For example a group with the following sub conditions:
             - MyCondition == 'Option1'
             - MyCondition == 'Option2' 
             - MyCondition == 'Option3'
            
            Would be converted to:
             Group Name: MyCondition
             Options:
              - Option1
              - Option2
              - Option3          
        */
        
        if (condition.isGroup) {

            let useGeneralizedItems = true;
            let groupName = '';
            let generalizedItems = [];

            _.forEach(condition.items, (item) => {
                let match = item.label.match(/(.*) ?== ? '(.*)'/i);
                if (match) {
                    let thisGroupName = match[1].trim();

                    if (!groupName) {
                        groupName = thisGroupName;
                    }

                    if (groupName !== thisGroupName) {
                        useGeneralizedItems = false;
                    } else {
                        let generalizedItem = _.clone(item);
                        let label = match[2];
                        if (!label) label = 'is blank';
                        generalizedItem.label = label;
                        generalizedItems.push(generalizedItem);
                    }
                } else {
                    useGeneralizedItems = false
                }
            })

            if (useGeneralizedItems) {
                condition.label = groupName;
                condition.items = generalizedItems;
            }
        }
    }

    function formatLabel(label) {
        if (!label || !label.toString) {
            return '';
        }

        label = label.toString();

        label = label
            // Trim spaces, dashes, parentheses and braces
            .replace(/^\(+|\)+$/g, '')
            .replace(/^{{+|}}+$/g, '')
            .replace(/^-+|-+$/g, '')
            .trim()
            // Allow line breaks in labels at . or _
            .replace(/\./g, '.​')
            .replace(/_/g, '_​')

        return label;
    }


    /**
     *  Get source code with conditional tags replaced per user overrides.
     *  @param  {string}                        sourceCode      Source code with liquid tags.
     *  @param  {array[PlaceholderCondition]}   placeholders    List of placeholder conditions.
     *  @return {string}    source code with conditional tags replaced.
     */
    vm.replaceConditionalPlaceholders = function(sourceCode, placeholders) {
        let sourceCodeReplaced = sourceCode;

        _.forEach(placeholders, (value) => {
            if (value.checked !== undefined) {

                if (value.isGroup) {
                    var selected = value.checked.tag;

                    _.forEach(value.items, (subItem) => {
                        var checked = selected === subItem.tag;
                        sourceCodeReplaced = replaceTag(sourceCodeReplaced, subItem, checked);
                    });

                } else {
                    sourceCodeReplaced = replaceTag(sourceCodeReplaced, value, value.checked);
                }
            }
        });

        return sourceCodeReplaced;
    }

    function replaceTag(sourceCode, value, checked) {
        let oldTagPattern = new RegExp(escapeRegExp(value.tag), "gi"); // uses regex for case insensitive AND replace all instead of only first occurence
        let newTag = `{% ${value.keyword} ${checked ? 'true' : 'false'} %}`
        return sourceCode.replace(oldTagPattern, newTag);
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|\[\]\\]/g, '\\$&');
    }


    /**
     * @typedef {Object} PlaceholderSampleObject
     * @property {string}           type        The type of object this is a sample of.
     * @property {string}           label       The descriptive label of the object, shown to the user.
     * @property {boolean}          loading     If the preview items are currently loading.
     * @property {array[Object]}    items       Preview items selectable for this object.
     * @property {Object}           [selected]  Populated with the item selected by the user from items.
     */
    
    
    /**
     * Load sample objects for placeholder types.
     * @param   {Function}           request         Request promise to load the sample objects.
     * @param   {array[string]}      placeholders    Sample object placeholders.
     * @param   {Function}           mapItemsFunc    Function to use to map the response from the request to a list of items.
     * @param   {string}             sourceCode      Source code to check for placeholders.
     * @return  {Object[PlaceholderSampleObject]} Sample object placeholders with items being loaded.
     */
    vm.loadPlaceholderSampleObjects = function(request, placeholders, mapItemsFunc, sourceCode) {
        
        let placeholderSampleObjects = {};
        let shouldShowAny = false;

        _.forEach(placeholders, placeholder => {
            if (hasPlaceholdersInSourceCode(sourceCode, placeholder)) {
                placeholderSampleObjects[placeholder] = {
                    selected: undefined,
                    placeholder,
                    label: placeholder.replace(/_/, ' '),
                    items: [],
                    loaded: false,
                };
                shouldShowAny = true;
            }
        });

        if (shouldShowAny) {
            request()
                .then(r => {
                    let items = mapItemsFunc(r);

                    _.forEach(placeholders, placeholder => {
                        let sampleObject = placeholderSampleObjects[placeholder];

                        if (sampleObject) {
                            sampleObject.loaded = true;
                            sampleObject.items = items;
                        }
                    })
                }).catch(angular.noop)
        }
        return placeholderSampleObjects;
    }
    
    function hasPlaceholdersInSourceCode(sourceCode, placeholder) {
        if (!sourceCode) return false;
        
        let regex = new RegExp('{{ ?'+placeholder+'.* ?}}|{% ?'+placeholder+'.* ?%}', 'gmi')
        return sourceCode.match(regex);
    }

    /**
     * Load sample objects for placeholder types.
     * @param   {Object[PlaceholderSampleObject]} placeholderSampleObjects  Sample object placeholders.
     * @return  {Object} Object with placeholders as key and selected sample object id as value.
     */
    vm.getPlaceholderSampleObjectsSelection = function(placeholderSampleObjects) {
        return _.reduce(placeholderSampleObjects, (obj, sampleObject) => {
            if (sampleObject.selected) {
                obj[sampleObject.placeholder] = sampleObject.selected.id;
            }
            return obj;
        }, {});
    }
    
}