angular
    .module('app')
    .component('credits', {
        controller: creditsController,
        bindings: { 
            $router: '<'
        },
        require: {
            parentComponent: '^transactions'
        },
        templateUrl: `credits.tpl.html`,
        controllerAs: 'vm',
        $canActivate: function($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });

function creditsController(agGridService, entityService, securityService, settingsService, creditService, helpersService, formService, $element, $q, $rootScope, $scope, $location) {
    const vm = this;
    var entity = entityService.getOpenEntity();
    vm.openRowBeforeChanges = {};
    vm.entityType = entity.entityType;
    vm.security = securityService.getAdminPermissions(entity, 'CREDITS');
    vm.showCreditModal = false;
    vm.hasNewCreditInRoute = false;

    $rootScope.$on('addNewSelected', ($event, targetEvent) => {
        showNewCreditModal();
        vm.creditModalTargetEvent = targetEvent;
    });

    vm.$onInit = function() {
        if (vm.entityType === 'Campaign') {
            vm.parentComponent.campaigns.updateShowAddBtn(vm.security.EDIT, 'addCredit', true, 'Add Credit');
        }
    };
    
    this.$routerOnActivate = function(next, prev) {
        this.parentComponent.showIframe = false;
        this.parentComponent.loading = false;
        
        if (next.routeData.data && next.routeData.data.showNewForm) {
            vm.hasNewCreditInRoute = true;
            showNewCreditModal(next.params.ids, next.params.amount, next.params.memberId, next.params.donorCampaignId);
        }
    };

    this.$routerOnDeactivate = function(p, n) {
        vm.parentComponent.campaigns.updateShowAddBtn(false, 'addCredit');
    }
    
    function showNewCreditModal(preselectCreditForTransactions, preselectCreditAmount, preselectCreditMemberId, preselectedDonorCampaignId) {
        vm.preselectCreditForTransactions = preselectCreditForTransactions;
        vm.preselectCreditAmount = preselectCreditAmount;
        vm.preselectCreditMemberId = preselectCreditMemberId;
        vm.preselectedDonorCampaignId = preselectedDonorCampaignId;
        vm.showCreditModal = true;
    }

    vm.loadRow = function(params) {
        vm.openRowBeforeChanges = _.cloneDeep(vm.grid.openRow);
    }

    vm.updateRow = function(updatedItem) {
        let updatedRow = vm.grid.api.getRowNode(updatedItem.Id);
        if (updatedRow) {
            updatedRow.setData(updatedItem);
        }
    }

    vm.cancelRow = function(updatedItem) {
        vm.updateRow(updatedItem);
    }

    vm.afterSave = function(updatedItem) {
        vm.updateRow(updatedItem);
    }

    vm.afterSaveField = function(updatedItem) {
        vm.updateRow(updatedItem);
    }

    vm.saveClosedRow = function(params, closedRow) {
        if (closedRow && closedRow.data && closedRow.data.saveIfChanges) {
            return closedRow.data.saveIfChanges(true);
        }

        return $q.resolve();
    }

    vm.deleteCredit = function(credit) {
        return creditService.deleteCredit(credit).then(() => {
            vm.grid.closeOpenRow();
            vm.refreshRows();
        });
    }

    vm.saveCredit = function(credit) {
        return creditService.saveCredit(credit).then(x => {
            vm.grid.openRow.node.setData(x.item);
            vm.setItemTitle(credit, x.item);
        });
    }

    vm.openCredit = function(credit) {
        vm.setItemTitle(credit, vm.grid.openRow.data);
    }

    vm.refreshRows = function() {
        vm.grid.api.refreshServerSideStore({ purge: vm.grid.totalResults == 0 })
    }

    vm.cardActions = [
    ]
    
    vm.getRequiredData = function() {

        let data = {
            paymentTypes: settingsService.getPaymentTypes(),
        };
        
        return $q.all(data);
    }
    
    vm.setItemTitle = function(credit, fullItem) {
        credit.Title = `${fullItem.FormattedAmount} by ${helpersService.getUserDisplayName(fullItem)}`;
    }

    vm.grid = agGridService.createNewGrid([], {
        domLayout: 'autoHeight',
        sideBar: false,
        advancedFiltering: true,
        gridElement: $element.find('.ag-master-grid'),
        expandedCardElement: $element.find('.ag-external-record-row'),
        noRowsMessage: 'Credits will show up here.',
        getRowNodeId: data => data.CreditId,
        datasource: agGridService.getDatasource('Credit', 'All'),
        defaultColDef: {
            flex: 1,
            minWidth: 160,
        },
    });

    vm.getRequiredData().then(data => {
        
        let columnDefs = [
            {
                field: 'CreditId',
                headerName: 'ID',
                filter: 'agNumberColumnFilter',
                hide: true,
            },
            {
                field: 'Donor',
                headerName: 'Donor',
                valueGetter: (params) => helpersService.getUserDisplayName(params.data),
                filter: false,
                sortable: false,
            },
            {
                field: 'FirstName',
                headerName: 'First Name',
                filter: 'agTextColumnFilter',
                hide: true,
            },
            {
                field: 'LastName',
                headerName: 'Last Name',
                filter: 'agTextColumnFilter',
                hide: true,
            },
            {
                field: 'CompanyName',
                headerName: 'Company Name',
                filter: 'agTextColumnFilter',
                hide: true,
            },
            agGridService.columnDefinitions.boolean({
                field: 'IsVerified',
                headerName: 'Is Verified',
                filter: true,
                sortable: false,
            }, {true: 'Verified', false: 'Not Verified'}),
            agGridService.columnDefinitions.boolean({
                field: 'IsCompanyTransaction',
                headerName: 'Company Transaction',
                filter: true,
                sortable: false,
                hide: true,
            }),
            {
                field: 'Amount',
                filter: 'agNumberColumnFilter',
                cellRenderer: function (params) {
                    if (!params.data) return params.value;
    
                    return params.data.FormattedAmount;
                },
                filterParams: {
                    cellRenderer: function (params) {
                        return params.data.FormattedAmount;
                    },
                },
            },
            {
                field: 'PaymentType',
                headerName: 'Payment Method',
                filter: true,
                hide: true,
                valueFormatter: params => data.paymentTypes[params.value],
                filterParams: {
                    values: Object.keys(data.paymentTypes),
                    valueFormatter: params => data.paymentTypes[params.value],
                }
            },
            {
                field: 'PaymentDateString',
                headerName: 'Date',
                hide: true,
                filter: 'agDateColumnFilter',
                valueFormatter: agGridService.formatters.dateTime,
            },
        ];
        
        vm.grid.api.setColumnDefs(columnDefs);
    });

    $scope.$on('NewCreditAdded', () => {
        if (vm.hasNewCreditInRoute) {
            vm.hasNewCreditInRoute = false;
            vm.$router.navigate(['Credits']);
        } else {
            vm.refreshRows();
        }
    })
}