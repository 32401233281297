angular.module('app').component('modalNeoncrmLogs', {
    bindings: {
        show: '=',
        username: '=',
    },
    controller: ModalNeoncrmLogsCtrl,
    controllerAs: 'vm'
});

function ModalNeoncrmLogsCtrl($scope, $mdDialog) {
    const vm = this;

    $scope.$watch('vm.show', () => {
        if (vm.show) {
            showModal();
        }
    });

    function showModal() {
        $mdDialog
            .show({
                templateUrl: 'modal-neoncrm-logs.tpl.html',
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    username: vm.username,
                }
            })
            .finally(() => {
                vm.show = false;
            });
    }
}

function ModalContentCtrl($scope, $mdDialog, messageService, neonCrmService, profileService, campaignService, agGridService, username) {
    const vm = this;
    vm.closeModal = closeModal;
    vm.reloadNeonCrmLogs = reloadNeonCrmLogs;
    vm.resyncWithNeonCrm = resyncWithNeonCrm;
    vm.resyncWithCrm = resyncWithNeonCrm;
    vm.syncing = true;
    vm.username = username;

    vm.logOptions = {
        from: moment()
          .subtract(7, 'day')
          .startOf('day')
          .toDate(),
        to: moment()
          .startOf('day')
          .toDate(),
        maxDate: new Date() // today is the latest logs
    };

    vm.formatDate = (date, format) => moment(date).format(format);

    var columnDefs = [
        {
            colId: 'NeonCrmQueueId',
            field: 'NeonCrmQueueId',
            headerName: 'QueueID',
            filter: false,
            enableRowGroup: false,
        },
        {
            colId: 'SyncType',
            field: 'SyncType',
            headerName: 'Queue Type',
            sortable: false,
        },
        {
            colId: 'InitiatedOn',
            field: 'InitiatedOn',
            headerName: 'Initiated On',
            filter: false,
            valueFormatter: agGridService.formatters.dateTime,
        },
        {
            colId: 'CompletedOn',
            field: 'CompletedOn',
            headerName: 'Completed On',
            hide: true,
            filter: false,
            valueFormatter: agGridService.formatters.dateTime,
        },
        {
            colId: 'ErroredOn',
            field: 'ErroredOn',
            headerName: 'Errored On',
            hide: true,
            filter: false,
            valueFormatter: agGridService.formatters.dateTime,
        },
        {
            colId: 'EventId',
            field: 'EventId',
            headerName: 'Campaign ID',
            filter: true,
        },
        {
            colId: 'UserAccountId',
            field: 'UserAccountId',
            headerName: 'UserID',
            sortable: false,
            cellRenderer: agGridService.templates.userAccountId,
            enableRowGroup: false,
        },
        {
            colId: 'DonationId',
            field: 'DonationId',
            headerName: 'Donation ID',
            sortable: false,
            cellRenderer: agGridService.templates.donationId,
            enableRowGroup: false,
        },
        {
            colId: 'MemberRolesSubmissionId',
            field: 'MemberRolesSubmissionId',
            headerName: 'Member Roles Submission ID',
            sortable: false,
            cellRenderer: agGridService.templates.memberRolesSubmissionId,
            enableRowGroup: false,
            hide: true,
        },
        {
            colId: 'RegistrationId',
            field: 'RegistrationId',
            headerName: 'Registration ID',
            sortable: false,
            cellRenderer: agGridService.templates.registrationId,
            enableRowGroup: false,
        },
        {
            colId: 'ErrorString',
            field: 'ErrorString',
            headerName: 'Error Message',
            filter: true,
            hide: true,
        },
        {
            colId: 'ResponseString',
            field: 'ResponseString',
            headerName: 'Response String',
            filter: true,
        },
        {
            colId: 'RequestString',
            field: 'RequestString',
            headerName: 'Request String',
            filter: true,
            hide: true,
        },
        {
            colId: 'Resync',
            headerName: 'Resync',
            cellRenderer: agGridService.templates.resync,
            filter: false,
            sortable: false,
            enableRowGroup: false,
            lockVisible: true,
        },
        {
            colId: 'Latest',
            field: 'Latest',
            headerName: 'Previous Sync Records',
            filter: agGridService.latestSyncsOnlyFilter(),
            hide: true,
            suppressColumnsToolPanel: true,
        }
    ];

    vm.gridOptions = agGridService.createNewGrid(columnDefs, {key: 'modal-neoncrm-logs'}, { onGridReady: loadNeonCrmLogs });

    profileService.getProfileInfo().then(profile => (vm.gridOptions.csvFilename = `admin_${profile.userName.split(' ')[0]}_${moment().format('YYYYMMDD-HHmm')}.csv`));

    function loadNeonCrmLogs() {
        vm.syncing = true;
        agGridService.runGridFilteringForLatest(vm);
        return neonCrmService
            .getNeonCrmLogs(vm.username, vm.logOptions.from, vm.logOptions.to)
            .then(neonCrmLogs => {
                neonCrmLogs.forEach(f => {
                    var campaign = campaignService.getCampaignByEventId(f.EventId);
                    if (campaign) {
                        f.campaignUrl = campaign.campaignUrl;
                    }
                });
                vm.gridOptions.api.setRowData(neonCrmLogs);
                vm.syncing = false;
            })
            .catch(() => (vm.syncing = false));
    }
    
    function reloadNeonCrmLogs() {
        if (vm.syncing === false) {
            return loadNeonCrmLogs();
        }
    }

    function resyncWithNeonCrm(record) {
        neonCrmService
            .reSync(record)
            .then(response => {
                if (response.msgShown) return;
                if (response.success) {
                    messageService.showMsgDialog('Done', 'We added the record to be synced.');
                    loadNeonCrmLogs();
                } else {
                    messageService.showErrorDialog('Oops', 'Sorry, an error occurred. <br/><b>' + response.msg + '</b>');
                }
            })
            .catch(response => {
                if (response.msgShown) return;
                messageService.showErrorDialog('Oops', 'Sorry, an error occurred. <br/><b>' + response.msg + '</b>');
            });
    }

    function closeModal() {
        $mdDialog.cancel();
    }
}
