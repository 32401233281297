angular
  .module('app')
  .component('processorCard', {
    bindings: {
      processor: '<',
      $router: '<',
      showMessage: '<',
      showOptions: '<',
      showHover: '<',
    },
    templateUrl: 'processor-card.tpl.html',
    controller: ProcessorCardController,
    controllerAs: 'vm'
  });

function ProcessorCardController($scope) {
  const vm = this;
  vm.showImage = true;

  vm.getDefaultImage = (processorName) =>{
    if(processorName === 'FirstPay ACH'){
      return 'FirstPay'
    }else if(processorName === 'Vantiv'){
      return 'Worldpay'
    }else {
      return processorName
    }
  }

  vm.updateInstance = function(action) {
    $scope.$emit(action, vm.processor.PaymentProcessorInstanceId);
  }

}