agGrid.initialiseAgGridWithAngular1(angular);
agGrid.LicenseManager.setLicenseKey("CompanyName=Rallybound,LicensedApplication=Rallybound,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-007910,ExpiryDate=27_April_2021_[v2]_MTYxOTQ3ODAwMDAwMA==e94e54430d82e29e50ab9d7af9fa43f8");

angular
    .module('app')
    .service('agGridService', agGridService);

function agGridService($http, $q, API_PREFIX, $templateCache, entityService, $timeout, $compile, $rootScope, profileService) {
    const vm = this;
    
    this.createNewGrid = function (columnDefs, options, callbacks) {
        var gridOptions = {
            openRow: undefined,
            columnStateLoaded: false,
            firstDataLoaded: false,
        };

        // Callbacks do not correspond directly to agGrid callbacks
        //   For example agGrid has a single callback for opened/closed groups: onRowGroupOpened
        //   Here the callback is split onRowGroupOpened and onRowGroupClosed
        if (!callbacks) callbacks = {};
        
        var defaults = {
            angularCompileRows: true,
            columnDefs: columnDefs,
            tooltipShowDelay: 400,
            enableRangeSelection: true,
            suppressPropertyNamesCheck: true,

            onGridReady: (params) => {
                let gridOptions = params.api.gridOptionsWrapper.gridOptions;
                if (gridOptions.key) {
                    params.api.showLoadingOverlay();
                    profileService.getAgGridSetting(gridOptions.key).then(setting => {
                        if (setting) {
                            let state = JSON.parse(setting);
                            params.columnApi.applyColumnState({
                                state,
                                applyOrder: true
                            });
                            gridOptions.appliedColumnState = state;
                        }
                        if (gridOptions.firstDataLoaded && gridOptions.totalResults == 0) {
                            params.api.showNoRowsOverlay();
                        } else {
                            params.api.hideOverlay();
                        }
                        gridOptions.columnStateLoaded = true;
                    });
                } else {
                    gridOptions.columnStateLoaded = true;
                }
                callbacks.onGridReady && callbacks.onGridReady(params);
            },

            onFirstDataRendered: (params) => {
                gridOptions.firstDataLoaded = true;
                callbacks.onFirstDataRendered && callbacks.onFirstDataRendered(params);
            },
            
            onRowGroupOpened: (params) => {

                if (params.node.expanded) {

                    // Use a timeout to ensure the openRow is set in the next angular digest
                    //  This ensures that openRow is reset to undefined for the previous row being closed
                    //  in case any components need to deconstruct when a row is closed
                    $timeout(() => {
                        
                        gridOptions.openRow = params

                        if (gridOptions.expandedCardElement) {
                            let gridRow = gridOptions.getRowDomElement(params.rowIndex);
                            gridOptions.expandedCardElement.css('transform', gridRow.get(0).style.transform)
                        }

                        callbacks.onRowGroupOpened && callbacks.onRowGroupOpened(params);
                    })
                    
                } else {
                    gridOptions.openRow = undefined;

                    callbacks.onRowGroupClosed && callbacks.onRowGroupClosed(params);
                }
            },

            onDisplayedColumnsChanged: (event) => {
                vm.updateSavedColumnState(event);
            },

            onColumnResized: (event) => {
                if (event.source === 'uiColumnDragged' && event.finished) {
                    vm.updateSavedColumnState(event);
                }
            },

            onSortChanged: (event) => {
                gridOptions.closeOpenRow();

                vm.updateSavedColumnState(event);

                callbacks.onSortChanged && callbacks.onSortChanged(event);
            },
            
            onFilterChanged: (params) => {
                gridOptions.closeOpenRow();

                callbacks.onFilterChanged && callbacks.onFilterChanged(params);
            },

            onSelectionChanged: (params) => {
                callbacks.onSelectionChanged && callbacks.onSelectionChanged(params);
            },
            
            onModelUpdated: (params) => {
                
                if (gridOptions.openRow) {
                    let id = gridOptions.openRow.node.id;
                    let node = gridOptions.api.getRowNode(id);
                    if (node) {
                        node.setExpanded(true)
                    }
                }

                if (gridOptions.api.gridOptionsWrapper.gridOptions.totalServerRows == 0 ) {
                    gridOptions.api.showNoRowsOverlay();
                } 
                else if (gridOptions.columnStateLoaded) {
                    gridOptions.api.hideOverlay();
                }

                if (gridOptions.api.gridOptionsWrapper.gridOptions.totalServerRows != undefined) {
                    gridOptions.totalResults = gridOptions.api.gridOptionsWrapper.gridOptions.totalServerRows;
                }
                
                if (gridOptions.autoSizeOnLoad) {
                   gridOptions.autoSizeAll(); 
                }
                
                callbacks.onModelUpdated && callbacks.onModelUpdated(params);
            },

            onRowDataChanged: (params) => {
                if (!gridOptions.api.gridOptionsWrapper.gridOptions.datasource) {
                    gridOptions.totalResults = gridOptions.api.getDisplayedRowCount();
                }

                callbacks.onRowDataChanged && callbacks.onRowDataChanged(params);
            },

            onRowClicked: function(params) {
                
                if (params.api.gridOptionsWrapper.gridOptions.expandOnClick) {

                    let shouldCloseOpenRow = false;
                    let openRowClosedCallbackPromise = $q.resolve();
                    
                    if (params.node.detail) {
                        return;
                    }

                    if (gridOptions.openRow && gridOptions.openRow.node.id !== params.node.id) {
                        shouldCloseOpenRow = true;
                        
                        if (callbacks.onOpenRowClosed)
                        {
                            openRowClosedCallbackPromise = callbacks.onOpenRowClosed(params, gridOptions.openRow)
                        }
                    }

                    openRowClosedCallbackPromise.then(() => {
                        if (shouldCloseOpenRow) {
                            gridOptions.openRow.node.setExpanded(false);
                        }
                        
                        params.node.setExpanded(true);
                    }).catch(angular.noop)
                }

                callbacks.onRowClicked && callbacks.onRowClicked(params);
            },

            closeOpenRow: function() {
                if (gridOptions.openRow) {
                    gridOptions.openRow.node.setExpanded(false);
                    gridOptions.openRow = undefined;
                }

                callbacks.onOpenRowClosed && callbacks.onOpenRowClosed();
            },
            
            getRowDomElement: function(rowIndex) {
                if (!gridOptions.gridElement) return;
                return gridOptions.gridElement.find(`.ag-row[row-index="${rowIndex}"]`).first();
            },

            sideBar: {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: false,
                            suppressValues: true,
                            suppressPivotMode: true,
                        }
                    },
                    {
                        id: 'filters',
                        labelDefault: 'Filters',
                        labelKey: 'filters',
                        iconKey: 'filter',
                        toolPanel: 'agFiltersToolPanel',
                    }
                ]
            },

            autoSizeAll: function(skipHeader) {
                var allColumnIds = [];
                gridOptions.columnApi.getAllColumns().forEach(function (column) {
                    allColumnIds.push(column.colId);
                });

                gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
            },

            clearFilters: function() {
                gridOptions.api.setFilterModel(null);
            },

            selectAll: function() {
                gridOptions.api.forEachNode((node) => node.setSelected(true));
            },

            selectOnlyVisible: function() {
                gridOptions.api.forEachNode((node) => node.setSelected(node.displayed));
            },

            selectAllVisible: function() {
                gridOptions.api.forEachNode((node) => {
                    if (node.displayed) {
                        node.setSelected(true)
                    }
                });
            },

            deselectAll: function() {
                gridOptions.api.forEachNode((node) => node.setSelected(false));
            },

            deselectAllVisible: function() {
                gridOptions.api.forEachNode((node) => {
                    if (node.displayed) {
                        node.setSelected(false)
                    }
                });
            },

            deselectNonVisible: function() {
                gridOptions.api.forEachNode((node) => {
                    if (!node.displayed) {
                        node.setSelected(false)
                    }
                });
            },
            
            saveColumnState: _.debounce(vm.saveColumnState, 400),

            components: { noRowsOverlay: vm.noRowsOverlay() },
            noRowsOverlayComponent: 'noRowsOverlay',
            noRowsOverlayComponentParams: {
                extraText: options.noRowsMessage || '',
            },

            defaultColDef: {
                sortable: true,
                resizable: true,
                autoHeight: true,
                filter: true,
                enableRowGroup: true,
                tooltipValueGetter: (params) => { return params.value; },
                filterParams: {},
            },
            totalResults: 0,
        };
        
        if (options.expandedCardTemplate || options.expandedCardElement) {
            _.merge(defaults, {
                masterDetail: true,
                detailCellRenderer: 'expandedCardRenderer',
                detailRowAutoHeight: true,
                components: { expandedCardRenderer: vm.genericTemplateCellRenderer(options.expandedCardTemplate, options.expandedCardElement) },
                expandOnClick: true,
            });
        }
        
        if (options.datasource) {
            _.merge(defaults, {
                rowModelType: 'serverSide',
                serverSideStoreType: 'partial',
                pagination: true,
                paginationPageSize: 15,
                cacheBlockSize: 30,
                serverSideDatasource: options.datasource,
                maxConcurrentDatasourceRequests: 1,
                defaultColDef: {
                    filter: false,
                    enableRowGroup: false,
                    filterParams: {
                        suppressAndOrCondition: true,
                    }
                }
            });
            
            if (!options.serverSideStoreType || options.serverSideStoreType === 'partial') {
                _.merge(defaults, {
                    defaultColDef: {
                        filterParams: {
                            buttons: ['apply','reset'],
                            closeOnApply: true,
                        }
                    }
                });
            }
            
            if (!options.advancedFiltering) {
                _.merge(defaults, {
                    suppressMultiSort: true,
                    defaultColDef: {
                        filterParams: {
                            filterOptions: ['contains'],
                        }
                    }
                });
            }
        }
        
        if (options.pagination || (options.datasource && options.serverSideStoreType !== 'full')) {
            _.merge(defaults, {
                components: { paginationStatusBar: vm.paginationStatusBar() },
                statusBar: {
                    statusPanels: [
                        {
                            statusPanel: 'paginationStatusBar'
                        },
                    ],
                },
            })
        }
        
        return _.merge(gridOptions, defaults, options);
    }
    
    this.defaultFilterOptions = {
        text: ['contains','notContains','equals','notEqual','startsWith','endsWith'],
        number: ['equals','notEqual','lessThan','lessThanOrEqual','greaterThan','greaterThanOrEqual','inRange'],
        date: ['equals','lessThan','greaterThan','notEqual','inRange'],
    }

    this.formatters = {
        dateTime: function(params) {
            var date = moment(params.value);
            return date.isValid() ? date.format('MM/D/YYYY hh:mm a') : '';
        },

        capitalizeFirstLetter: function(params) {
            return params.value ? params.value.charAt(0).toUpperCase() + params.value.slice(1) : '';
        },

        emailProcessedMessage: function(params) {
            switch (params.value.toString()) {
                case "100": return 'Successful';
                case "200": return 'Unsuccessful';
                case "201": return 'Network Failure (Rescheduled)';
                case "300": return 'Opted Out';
                case "301": return 'Template Disabled';
                default: return '';
            }
        },
    };

    this.templates = {
        boolean: function(params) {
            return `
                <i class="text-medium ag-icon ag-icon-${params.value && params.value !== 'No' ? 'tick text-green' : 'not-allowed text-red'}"></i>
            `;
        },
        
        booleanImage: function(params) {
            return `
                <img ng-src="/Content/Admin/extjs/resources/themes/images/default/dd/drop-${params.value ? 'yes' : 'no'}.gif" />
            `;
        },

        salesforce: function(params) {
            const field = params.colDef.colId;
            return `
                <a ng-if="data.${field}" ng-href="https://login.salesforce.com/{{ data.${field} }}" ng-bind="data.${field}" target="_blank"></a>
            `;
        },

        donationId: function(params) {
            return `
                <span ng-bind="!data.DonationId ? '0' : ''"></span>
                <a ng-if="data.DonationId" ng-href="{{ data.campaignUrl }}/transactions/donations?type=Donation&value={{ data.DonationId }}" ng-bind="data.DonationId" target="_blank"></a>
            `;
        },

        transactionId: function(params) {
            return `
                <a ng-if="data.DonationId != 0" ng-href="{{ data.campaignUrl }}/transactions/donations?type=Donation&value={{ data.DonationId }}" ng-bind="data.DonationId" target="_blank"></a>                
                <a ng-if="data.RegistrationId != 0" ng-href="{{ data.campaignUrl}}/people/registrants?type=Registrant&value={{ data.DonorId }}" target="_blank" ng-bind="data.RegistrationId"></a>
                <a ng-if="data.MemberRoleId != 0" ng-href="{{ data.campaignUrl }}/people/registrants?type=Registrant&value={{ data.DonorId }}" target="_blank" ng-bind="data.MemberRoleId"></a>
            `;
        },

        status: function(params) {
            return `
                <span ng-if="data.Status === 'retrying'">Retrying</span>
                <img ng-if="data.Status !== 'retrying'" ng-src="/Content/Admin/extjs/resources/themes/images/default/dd/drop-{{ data.Status === 'successful' ? 'yes' : 'no' }}.gif" />
            `;
        },

        userAccountId: function(params) {
            return `
                <a ng-if="data.SyncType === 'Registration' && data.UserAccountId" ng-href="{{ data.campaignUrl}}/people/registrants?type=Registrant&value={{ data.UserAccountId }}" target="_blank" ng-bind="data.UserAccountId"></a>
                <a ng-if="data.SyncType === 'MemberRolesSubmission' && data.UserAccountId" ng-href="{{ data.campaignUrl }}/people/registrants?type=Registrant&value={{ data.MemberId }}" target="_blank" ng-bind="data.UserAccountId"></a>
                <a ng-if="data.SyncType === 'Donation' && data.UserAccountId" ng-href="{{ data.campaignUrl }}/people/donors?type=Donor&value={{ data.MemberId }}" target="_blank" ng-bind="data.UserAccountId"></a>

                <span ng-bind="!data.UserAccountId || (data.SyncType !== 'Registration' && data.SyncType !== 'Donation' && data.SyncType !== 'MemberRolesSubmission') ? (data.UserAccountId || 0) : ''"></span>
            `
        },

        userId: function(params) {
            return `
                <a ng-if="data.SyncType === 'Registration' && data.UserId" ng-href="{{ data.campaignUrl }}/people/registrants?type=Registrant&value={{ data.UserId }}" target="_blank" ng-bind="data.UserId"></a>

                <a ng-if="data.SyncType === 'Donation' && data.UserId" ng-href="{{ data.campaignUrl }}/people/donors?type=Donor&value={{ data.MemberId }}" target="_blank ng-bind="data.UserId"></a>

                <span ng-bind="!data.UserId || (data.SyncType !== 'Registration' && data.SyncType !== 'Donor') ? (data.UserId || 0) : ''"></span>
            `
        },

        teamId: function(params) {
            return `
                <span ng-bind="!data.TeamId ? '0' : ''"></span>
                <a ng-if="data.TeamId" ng-href="{{ data.campaignUrl }}/people/teams?type=Team&value={{ data.TeamId }}" ng-bind="data.TeamId" target="_blank"></a>
            `;
        },

        memberRolesSubmissionId: function(params) {
            return `
                <span ng-bind="!data.MemberRolesSubmissionId ? '0' : ''"></span>
                <div ng-if="data.MemberRolesSubmissionId" ng-bind="data.MemberRolesSubmissionId"></div>
            `
        },

        registrationId: function(params) {
            return `
                <span ng-bind="!data.MemberId ? '0' : ''"></span>
                <div ng-if="data.MemberId" ng-bind="data.MemberId"></div>
            `
        },

        resync: function(params) {
            return `
                <div ng-if="data.canResync" ng-click="vm.resyncWithCrm(data)">
                    <img src="/Content/Admin/images/icons/Resync_16x16.png" />
                </div>
            `
        },

        resendEmail: function(params) {
            return `
                <div ng-click="vm.resendEmail(data)">
                    <img src="/Content/Admin/images/icons/Resync_16x16.png" />
                </div>
            `
        },
    }
    
    this.columnDefinitions = {
        boolean: (properties = {}, strings = {true: 'Yes', false: 'No'}) => {
            var definition = {
                cellRenderer: this.templates.boolean,
                tooltipValueGetter: params => params.data[params.colDef.field] ? strings.true : strings.false,
                filterParams: {
                    suppressMiniFilter: true,
                    suppressSelectAll: true,
                    defaultToNothingSelected: true,
                    values: [true, false],
                    valueFormatter: params => params.value === 'true' ? strings.true : strings.false,
                }
            }
            
            return _.merge(definition, properties);
        },
    }

    this.latestSyncsOnlyFilter = function (){
        return class LatestSyncsOnlyFilter {
            constructor() {
            }
            init(params) {
                this.params = params;
                this.checked = false; // default false: not showing all syncs (only Latest)
                this.setupGui(params);
            }

            // not called by ag-Grid, just for us to help setup
            setupGui(params) {
                this.gui = document.createElement('div');
                this.gui.innerHTML = 
                    `<label class="ag-set-filter-item">
                        <!--AG-CHECKBOX-->
                        <div role="presentation" ref="eCheckbox" class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                            <label ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-checkbox-label" for="latestSyncsOnlyCheckbox"></label>
                            <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" role="presentation">
                                <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox" id="latestSyncsOnlyCheckbox">
                            </div>
                        </div>
                        <span class="ag-set-filter-item-value">Show All Attempts</span>
                    </label>`

                this.checkbox = this.gui.querySelector('#latestSyncsOnlyCheckbox');
                this.checkbox.addEventListener("change", checkboxListener);
                var that = this;
                function checkboxListener(event) {
                    that.checked = event.target.checked;
                    that.checkbox.parentElement.classList.toggle("ag-checked");
                    params.filterChangedCallback();
                }
            }
            getGui() {
                return this.gui;
            }
            doesFilterPass(params) {
                return params.data.Latest || this.checked;
            }
            isFilterActive() {
                return this.checked != null;
            }
            getModel() {
                return { value: this.checked };
            }
            setModel(model) {
                this.checked = model;
            }
        }
    };
    this.runGridFilteringForLatest = function(vm){
        vm.gridOptions.api.getFilterInstance('Latest');
        vm.gridOptions.api.onFilterChanged();
    };
    
    this.getDatasource = function(controller, endpoint, data, filterData) {
        return {
            getRows: function (params) {

                let entityId = entityService.getOpenedEntityId()
                
                let requestData = {};
                _.merge(requestData, typeof data === 'function' ? data() : data);
                
                requestData.gridParams = params.request;
                
                $http({
                    url: `${API_PREFIX}${controller}/${endpoint}`,
                    headers: { entityId },
                    data: requestData,
                    method: 'POST',
                }).then(response => {

                    params.api.gridOptionsWrapper.setProperty('totalServerRows', response.data.totalCount)
                    
                    if (filterData) {
                        response.data.items = filterData(response.data.items);
                    }
                    
                    params.success({
                        rowData: response.data.items,
                        rowCount: response.data.totalCount,
                    });

                }).catch(() => {
                    params.fail();
                });
            }
        }
    };

    this.updateSavedColumnState = function(event) {
        let gridOptions = event.api.gridOptionsWrapper.gridOptions;

        if (!gridOptions.key) {
            return;
        }
        
        if (!gridOptions.columnStateLoaded) {
            return;
        }

        gridOptions.saveColumnState(event);
    }
    
    this.saveColumnState = function(event) {
        let gridOptions = event.api.gridOptionsWrapper.gridOptions;
        let newState = event.columnApi.getColumnState();
        
        if (!gridOptions.appliedColumnState || !_.isEqual(gridOptions.appliedColumnState, newState)) {
            profileService.updateAgGridSetting(gridOptions.key, newState);
            gridOptions.appliedColumnState = newState;
        }
    }

    this.noRowsOverlay = function (){
        return class CustomNoRowsOverlay {
            constructor() {
            }
            init(params) {
                function clearFilter() {
                    
                }
                
                this.eGui = document.createElement('div');
                this.eGui.className = 'ag-overlay-loading-center ';
                
                if (params.api.isAnyFilterPresent()) {
                    this.eGui.innerHTML = `No results match the currently selected filters. <button class="btn btn-secondary">Clear Filter</button>`;
                    this.eGui.className += 'no-filters-match';

                    this.button = this.eGui.querySelector('button');
                    this.button.addEventListener("click", () => {
                        params.api.setFilterModel(null);
                        params.api.onFilterChanged();
                    });
                } else {
                    this.eGui.innerHTML = `There's nothing here yet. <span>${params.extraText}</span>`;
                    this.eGui.className += 'no-results';
                }
            }
            getGui() {
                return this.eGui;
            }
        }
    };
    
    this.genericTemplateCellRenderer = function(template, element) {
        return class GenericTemplateCellRenderer {
            constructor() {
            }
            init(params) {
                this.eGui = document.createElement('div');

                // Use a template rendered inline
                if (template) {
                    this.eGui.innerHTML = $templateCache.get(`${template}.tpl.html`);
                }
                
                // Track external element
                else if (element && element[0]) {
                    this.dummyRow = document.createElement('div');
                    this.dummyRow.className = 'ag-record-row-dummy';
                    this.eGui.append(this.dummyRow);
                    
                    new ResizeObserver(() => {
                        this.dummyRow.style.height = element.height() + "px";
                    }).observe(element[0])
                }
            }
            getGui() {
                return this.eGui;
            }
        }
    };

    this.paginationStatusBar = function() {
        return class ClickableStatusBarComponent {
            constructor() {
            }
            init(params) {
                this.scope = $rootScope.$new();
                
                this.scope.api = params.api;
                
                this.scope.pagination = {
                    currentPage: () => params.api.paginationGetCurrentPage(),
                    totalPages: () => params.api.paginationGetTotalPages(),
                    isFirstPage: () => params.api.paginationGetCurrentPage() === 0,
                    isLastPage: () => (params.api.paginationGetCurrentPage() + 1) === params.api.paginationGetTotalPages(),
                    firstRecordShown: () => _.clamp((params.api.paginationGetPageSize() * params.api.paginationGetCurrentPage()) + 1, 0, params.api.paginationGetRowCount()),
                    lastRecordShown: () => _.clamp(params.api.paginationGetPageSize() * (params.api.paginationGetCurrentPage() + 1), 0, params.api.paginationGetRowCount()),
                    totalRecords: () => params.api.paginationGetRowCount(),
                    hasNoRecords: () => params.api.paginationGetRowCount() === 0,
                };
                
                this.eGui = $compile(  $templateCache.get(`pagination.tpl.html`) )( this.scope );
            }
            getGui() {
                return this.eGui.get(0);
            }
            destroy() {
                this.scope.$destroy();
            }
        }
    };
    
    this.valueFromFields = function(params) {
        return getFieldValue(params.data.Fields, params.colDef.field);
    }

    this.getFieldValue = function(fields, fieldName) {
        return getFieldValue(fields, fieldName);
    }

    function getFieldValue(fields, fieldName) {
        let field = getField(fields, fieldName);
        return field ? field.value : undefined;
    }

    function getField(fields, fieldName) {
        if (!fields) return;
        return fields.find(x => x.name === fieldName);
    }

}