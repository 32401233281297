angular.module('app').factory('routingService', routingService);

function routingService($rootScope, $window, campaignService, entityService, authenticationService, securityService, orgService, $location, pendingChangesService, $q) {
    return {
        updateRouting, validateRoute, validateRoutePermission, openEntityIsCampaign, validateNoPendingChanges,
        openEntityIsOrg, getNextRoute, isStandaloneOrTicketing, willRoute, anyResourcePermission
    };

    function updateRouting() {
        return campaignService.checkUpdateRouting()
        .then(setCampaignAndGroup)
        .catch(setNotFound);
    }
    
    function validateNoPendingChanges($nextInstruction) {
        if (pendingChangesService.hasPendingChanges()) {
            return pendingChangesService.confirm().then(() => true, () => false);
        }
        
        return true;
    }

    function validateRoute($nextInstruction) {
        return pendingChangesService.confirm().then(() => {

            let routeData = $nextInstruction.routeData.data;

            return (routeData.authenticate ? authenticationService.checkAuthentication() : $q.resolve(true))
                .then(isAuthenticated => {
                    if (!isAuthenticated) {
                        $window.location.href = `/Account/Logon?returnUrl=${encodeURIComponent($nextInstruction.urlPath)}`;
                        return false;
                    } else {
                        return orgService.orgInfoLoadedPromise.then(r => {
                            return entityService.getEntitySettings().then(entity => {
                                if (!willRoute(routeData, entity)) {
                                    return false;
                                }

                                if (campaignService.shouldUpdateRoutingForGroup($nextInstruction, entity)) {
                                    campaignService.updateRoutingForGroup(entity);
                                    return false;
                                }

                                return true;
                            });
                        });
                    }
                });
        })
        .catch(() => false);
    }

    function willRoute(routeData, entity) {
        if (routeData.dontRoute) {
            return false;
        }
        if (routeData.limitToOrg) {
          if (!openEntityIsOrg(entity)) {
            return false;
          }
        }
        if (routeData.limitToCampaign) {
            if (!openEntityIsCampaign(entity)) {
                return false;
            }
        }
        if (openEntityIsCampaign(entity) && entity.activeEventIsArchived && !routeData.allowOnInstance)
        {
            return false;
        }
        if (orgService.getUrlCampaignInstanceId() && !routeData.allowOnInstance) {
            return false;
        }

        if  (!validateRoutePermission(routeData, entity)) {
            return false;
        }

        if (routeData.hideOnStandaloneAndTicketing) {
          if (isStandaloneOrTicketing(entity)) {
            return false;
          }
        }
        
        if (routeData.hideOnNonStandaloneAndTicketing && openEntityIsCampaign(entity) && !isStandaloneOrTicketing(entity)) {
            return false;
        }

        if (routeData.routeFunc) {
            if (!routeData.routeFunc(entity)) {
                return false;
            }
        }

        if (routeData.hideOnNewOrgs) {
            if (location.search.indexOf('pass') < 0 && orgService.getOrgId() > 424) {
                return false;
            }
        }
        return true;
    }

    function getNextRoute(router, component) { 
        var rules = router.registry._rules.get(component); 
        return orgService.orgInfoLoadedPromise.then(r => {
            var entity = entityService.getOpenEntity();
            var routing = false;
            rules.rulesByName.forEach( (v, k) => {
                var data = v.handler.data.data;
                if (!routing && willRoute(data, entity)) {
                    router.navigate([k]);
                    routing = true;
                }
            });
            return routing;
        });
    }

    function isStandaloneOrTicketing(entity) {
        return ['Donation', 'Global Donation Page', 'Standalone Ticketing', 'Ticketing'].includes(entity.type);
    }

    function openEntityIsCampaign(entity) {
        entity = entity || entityService.getOpenEntity();
        return entity.entityType == 'Campaign';
    }

    function openEntityIsOrg(entity) {
        entity = entity || entityService.getOpenEntity();
        return entity.entityType == 'Org';
    }

    function validateRoutePermission(data, entity) {
        if (data.resource) { 
            return securityService.getAdminPermissions(entity, data.resource).VIEW;
        } else if (data.allResources) {
            return routePermissionMultipleCheck(data.allResources, entity);
        } else if (data.anyResource) {
            return anyResourcePermission(data.anyResource, entity);
        }
        return true;
    }

    function routePermissionMultipleCheck(resourceArray, entity) {
        var hasPermission = true;
        resourceArray.forEach(element => {
            if (hasPermission) {
                var perm = securityService.getAdminPermissions(entity, element);
                if (!perm.VIEW) {
                    hasPermission = false;
                }
            }
        });
        return hasPermission;
    }

    function anyResourcePermission(anyResource, entity) {
        for (let perm of anyResource) {
            var hasPermission = securityService.getAdminPermissions(entity, perm);
            if (hasPermission.VIEW) {
                return true;
            }
         }
         return false;
    }    

    function setCampaignAndGroup(campaignAndGroup) {
        let openEntity = null;
        if (campaignAndGroup.campaign) {
            $rootScope.$emit('onCampaignPage');
            $rootScope.$emit('campaign.opened', {name: campaignAndGroup.campaign.name, campId: campaignAndGroup.campaign.campaignId});
            openEntity = campaignAndGroup.campaign.entityId;
        }

        if(campaignAndGroup.group) {
            var groupId = campaignAndGroup.group.groupId;
            openEntity = openEntity || campaignAndGroup.group.entityId;
        }

        openEntity = openEntity || entityService.getOrgEntityId();

        openGroup(groupId);        

        return openEntity;
    }

    function openGroup(groupId) {
        campaignService.openGroup(groupId)
          .then(campaignsData => {
              $rootScope.$emit('group.opened');
          })
    }
    
    function setNotFound() {
        $rootScope.$emit('groupOrCampaignNotFound');
    }
}