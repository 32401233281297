angular.module('app').component('textItem', {
  bindings: {
    value: '=',
    label: '<',
    configId: '<',
    onChange: '<',
    sectionUpdating: '<',
    number: '<',
    inherited: '<',
    isDefault: '<',
    parentName: '<',
    disabled: '<',
    tooltip: '<',
    entityType: '<',
    noLock: '<'
  },
  controllerAs: 'vm',
  controller: textItemController,
  templateUrl: 'text-item.tpl.html'
});

function textItemController() {
  var vm = this;

  vm.itemEditing = false;
  vm.itemUpdating = false;

  vm.onSave = function() {
    var unchanged = vm.originalValue == vm.value;
    if (vm.inherited || vm.disabled || vm.itemUpdating || unchanged) {
      vm.itemEditing = false;
      return;
    }

    vm.itemUpdating = true;
    vm.onChange(vm.configId, vm.value)
      .then(function(success) {
        if (!success && vm.originalValue) {
          vm.value = vm.originalValue;
        } else {
          vm.originalValue = vm.value;
        }
        vm.itemUpdating = false;
        vm.itemEditing = false;

        return success;
      });
  };
  
  vm.cancelEdit = function () {
      vm.itemEditing = false;
      vm.value = vm.originalValue;
  }

  const ESC = 27,
        ENTER = 13;
  vm.checkHotKey = function(event) {
    var key = event.keyCode;
    if (key === ESC) {
      vm.cancelEdit();
    } else if (key === ENTER) {
      event.preventDefault();
      event.stopPropagation();
      vm.onSave();
    }
  };
}
