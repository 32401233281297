angular
  .module('app')
  .component('registrants', {
    controller: registrantsController,
    bindings: { 
      $router: '<'
    },
    require: {
      parentComponent: '^people'
    },
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function registrantsController(extJsLoader, iframeMessageHandler, entityService) {  
  const vm = this;
  this.$routerOnActivate = function(next, prev) {
    extJsLoader.extJsController.updateInnerPage('People-Registrants');
    this.parentComponent.showIframe = true;
    
    let entityWithSettings = entityService
      .getEntityWithSettings(this.parentComponent.openEntity)
      .settings;
    this.parentComponent.showCachedRegistrantsDataNotice = entityWithSettings.UseRegistrantsGridSnapshot;
    this.parentComponent.registrantsSnapshotRefreshTime = entityWithSettings.RegistrantsGridSnapshotRefreshTime;    
  }
  this.$routerOnDeactivate = function(p, n) {
    this.parentComponent.showCachedRegistrantsDataNotice = false;
    this.parentComponent.registrantsSnapshotRefreshTime = 0;
  }
}