angular
    .module('app')
    .service('creditService', creditService);

function creditService(requestHelperService, formService) {
    const vm = this;
    let rh = requestHelperService.getInstance('Credit');
    
    vm.api = {
        getNewCreditForm: () => rh.sendEntityRequest('AddForm'),
        saveCredit: (item) => rh.sendCampaignRequest('Edit', { item: formService.getBaseFormItem(item) } ),
        addCredit: (item) => rh.sendCampaignRequest('Add', { item: formService.getBaseFormItem(item) }, rh.successToastMessage('Credit has been added.') ),
        deleteCredit: (item) => rh.sendCampaignRequest('Delete', { item: formService.getBaseFormItem(item) } ),
        getExistingUsers: () => rh.sendExtJsRequest('Member/AllMembersSimple', { limit: 10, page: 0, start: 0, noPaging: true }),
    }
    
    return vm.api;
    
}