angular
  .module('app')
  .component('modalAddNewGroup', {
    bindings: {
      show: '=',
      rename: '=',
      groupId: '=',
      entityId: '='
    },
    controller: modalAddNewGroupCtrl,
    controllerAs: 'vm'
  });

function modalAddNewGroupCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-add-new-group.tpl.html',
        clickOutsideToClose: true,
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          rename: vm.rename,
          groupId: vm.groupId,
          entityId: vm.entityId
        }
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($mdDialog, $route, $rootScope,
                          entityService, campaignService, orgService,
                          rename, groupId, entityId ) {
  const vm = this;
  vm.saveInProgress = false;
  vm.entityIdParent = entityService.getOpenedEntityId();
  vm.curGroup = groupId
    ? campaignService.getGroupById(groupId)
    : campaignService.getOpenedGroup();
  vm.curGroupId = groupId || vm.curGroup.groupId;
  vm.rename = rename;

  if (rename) {
    vm.newGroup = vm.curGroup.name;
  }

  vm.cancel = closeModal;
  vm.addGroup = addNewGroupToCurrent;
  vm.renameGroup = renameGroup;

  function closeModal() {
    $mdDialog.hide();
  }

  function addNewGroupToCurrent() {

    vm.saveInProgress = true;

    let messageConfig = {
        successToastEnabled: true,
        successMsg: vm.newGroup + ' group added successfully!'
    };
    entityService.createNewCampaignGroup(vm.entityIdParent, vm.newGroup, messageConfig)
    .then((response)=>{
      $rootScope.$emit('tree.clear');

      let data = response.data.data;

      campaignService.pushToRawGroups({
        amountRaised: 0,
        entityId: data.entityId,
        groupId: data.groupId,
        members: 0,
        name: data.name,
        parentGroupId: data.parentGroupId,
        permissions: data.permissions
      });
     
      campaignService.openGroup(data.groupId, data.entityId, true)
        .then((campaignsData) => {
            entityService.updateAllEntities();
            $rootScope.$emit('tree.changed', campaignsData);
            orgService.updatePathToGroup(data);
        });
      closeModal();
    }).finally(() => {
      vm.saveInProgress = false;
    });

  }
  
  function renameGroup() {

    vm.saveInProgress = true;

    entityService.renameGroup(vm.curGroupId, vm.newGroup)
      .then(() => {
        
        campaignService.updateGroupName(vm.curGroupId, vm.newGroup);

        if(entityId){

        campaignService.openGroup(vm.curGroup.parentGroupId, entityId)
          .then((campaignsData) => {
            $rootScope.$emit('tree.changed', campaignsData);
          })
        }
        closeModal();
      })
      .finally(() => vm.saveInProgress = false);
  }
}
