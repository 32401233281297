angular
    .module('app')
    .component('creditModal', {
        bindings: {
            showModal: '=',
            targetEvent: '<',
            creditForTransactions: '<',
            amount: '<',
            memberId: '<',
            donorCampaignId: '<',
            endurancePledgeId: '<'
        },
        controller: creditModalCtrl,
        controllerAs: 'vm'
    });

function creditModalCtrl($scope, $mdDialog) {
    const vm = this;

    $scope.$watch('vm.showModal', () => {
        if (vm.showModal) {
            showModal();
        }
    });

    function showModal() {
        $mdDialog
            .show({
                templateUrl: 'add-edit-modal.tpl.html',
                escapeToClose: false,
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                multiple: true,
                targetEvent: vm.targetEvent,
                locals: {
                    parent: $scope,
                    creditForTransactions: vm.creditForTransactions,
                    amount: vm.amount,
                    memberId: vm.memberId,
                    donorCampaignId: vm.donorCampaignId,
                    endurancePledgeId: vm.endurancePledgeId
                }
            }).catch(() => {})
            .finally(() => {
                vm.showModal = false;
            });
    }
}

function ModalContentCtrl($q, $rootScope, $scope, $element, $mdDialog, $timeout, creditService, formService, helpersService, creditForTransactions, amount, memberId, donorCampaignId, endurancePledgeId) {

    const vm = this;
    
    vm.item = undefined;
    vm.form = { };
    vm.errors = {};
    
    creditService.getNewCreditForm().then(r => {
        vm.item = r.item.Card;
        vm.item.InPopup = true;

        let usersField = formService.getFieldFromItem(vm.item, 'Existing User');
        usersField.loading = true;
        
        creditService.getExistingUsers().then((r) => {
            let memberIdsToUserAccountIds = {};
            usersField.loading = false;

            usersField.options = _.reduce(r.items, (result, value) => {
                value = helpersService.normalizeUserPropertyNames(value);
                result[value.UserAccountID] = `<div>${helpersService.getUserDisplayName(value, ['UserAccountId', 'Email'])}</div>`;
                memberIdsToUserAccountIds[value.MemberID] = value.UserAccountID;
                return result;
            }, {})

            if (memberId && parseInt(memberId)) {
                // Use timeout so that the update that shows this field have already gone through the cycle
                $timeout(() => {
                    formService.setFieldValueOnItem(vm.item, 'Existing User', memberIdsToUserAccountIds[memberId]);
                })
            }
        });

        let debitsField = formService.getFieldFromItem(vm.item, 'Debits');
        debitsField.change = (item, value) => {
            var amountField = formService.getFieldFromItem(item, 'Amount');
            if (!vm.form['Amount'].$dirty) {
                amountField.value = value.totalUnfulfilledAmount;
            }
        };
        
        if (creditForTransactions) {
            debitsField.value = {ids: creditForTransactions};
        }
        if (amount && parseInt(amount)) {
            formService.setFieldValueOnItem(vm.item, 'Amount', parseInt(amount));
        }

        if (memberId && parseInt(memberId)) {
            formService.setFieldValueOnItem(vm.item, 'New or Existing User', 'Existing');
        }

        if (donorCampaignId && parseInt(donorCampaignId)) {
            formService.setFieldValueOnItem(vm.item, 'DonorCampaignId', donorCampaignId, true, 'NumberInt', { hidden: true });
        }

        if (endurancePledgeId && parseInt(endurancePledgeId)) {
            formService.setFieldValueOnItem(vm.item, 'EndurancePledgeId', endurancePledgeId, true, 'NumberInt', { hidden: true });
        }
    })

    vm.cancel = closeModal;
    vm.nextGroup = nextGroup;
    vm.add = addCredit;
    vm.addButtonText = 'Add Credit';
    vm.newTitleText = 'New Credit';
    
    function closeModal() {
        $rootScope.$broadcast('CreditModalClosed');
        $mdDialog.hide();
    }
    
    function nextGroup() {
        $scope.$broadcast('nextGroup');
    }
    
    function addCredit() {
        if (vm.validateActiveGroup()) {
            return creditService.addCredit(vm.item).then(notifyScopeOfNewCredit).then(closeModal, () => $q.reject());
        } else {
            return $q.reject();
        }
    }

    function notifyScopeOfNewCredit() {
        $rootScope.$broadcast('NewCreditAdded');
    }

    $scope.$watch('vm.form.$valid', function (valid) {
        if (!valid) {
            $scope.$broadcast('markGroupIncomplete', vm.form.activeGroup)
        }
    });

    $scope.$on('closeModal', function(event) {
        closeModal();
    })

}