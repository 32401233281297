angular
  .module('app')
  .directive('affix', Affix);

function Affix($window, $timeout) {
  return {
    restrict: 'AE',
    scope: {
      affixTopMargin: "@",
      affixedClass: "@",
      unaffixedClass: "@"
    },
    link($scope, $element, $attrs) {
        const marginTop = Number($scope.affixTopMargin) || 0;

        $timeout(() => {
            const initialOffsetTop = $element.offset().top;

            const changeElementPosition = () => {
                if ($window.scrollY >= initialOffsetTop - marginTop) {
                    $element.css({'position': 'fixed', top: marginTop + 'px'});
                    $element.addClass($scope.affixedClass);
                    $element.removeClass($scope.unaffixedClass);

                } else {
                    $element.css({'position': '', top: ''});
                    $element.addClass($scope.unaffixedClass);
                    $element.removeClass($scope.affixedClass);
                }
            };

            angular.element($window).on('scroll', changeElementPosition);

            changeElementPosition();

        })
    }
  }
}