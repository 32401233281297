angular
  .module('app')
  .component('modalCampaigns', {
    bindings: {
      show: '=',
      admin: '<',
      index: '<',
      roles: '<',
      onUpdateAdmin: '&'
    },
    controller: ModalCampaignsCtrl,
    controllerAs: 'vm'
  });


function ModalCampaignsCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-campaigns.tpl.html',
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        clickOutsideToClose: true,
        locals: {
          admin: vm.admin,
          indexInParent: vm.index,
          roles: vm.roles,
          onUpdateAdmin: vm.onUpdateAdmin
        },
      })
      .finally(() => {
          vm.show = false;
          
      });
  }
}

function ModalContentCtrl($q, $mdDialog, $timeout, entityService,
                          campaignService, administratorService,
                          admin, indexInParent, roles, onUpdateAdmin, messageService) {

  const vm = this;
  vm.roles = roles;
  vm.onUpdateAdmin = onUpdateAdmin;
  vm.closeModal = closeModal;
  vm.addCampaign = addCampaign;
  vm.addedCampaigns = [];
  vm.removeEntity = removeEntity;
  vm.addRemoveFlag = addRemoveFlag;
  //vm.saveAdministrators = saveAdministrators;
  vm.addRoleChanges = addRoleChanges;
  vm.saveInProgress = false;
  vm.revertDeleteIcon = revertDeleteIcon;
  let listRoles = [];
  let campaignsData = campaignService.getRawCampaigns();
  let groupsData = campaignService.getRawGroups();
  let orgGroupsCampaigns = groupsData.concat(campaignsData);

  $timeout(() => removeUndefinedCmpnsAndGroups(admin)
      .then(() => {
        // autocomplete campaigns
        vm.campaigns = loadCampaigns();
        vm.querySearch = querySearch;
        vm.selectedItemChange = selectedItemChange;
      })
  );


    function removeUndefinedCmpnsAndGroups(admins) {
        //to stop list from showing double for a second
        let adminWithoutUndefinedCmpns = angular.copy(admins);
        let filteredCampaigns = [];
        admins.campaigns.forEach((camp) => {
            if (camp.currentRoleId != undefined) {
                filteredCampaigns.push(camp);
            }
        });
        adminWithoutUndefinedCmpns.campaigns = filteredCampaigns;

        let filteredGroups = [];
        admins.groups.forEach((group) => {
            if (group.currentRoleId != undefined) {
                filteredGroups.push(group);
            }
        });
        adminWithoutUndefinedCmpns.groups = filteredGroups;

        vm.admin = adminWithoutUndefinedCmpns;

        return $q.resolve();
    }

  function addRoleChanges(id, currentRoleId){
    let arg = [];
    arg.push({ userId: vm.admin.id, entityId: id, roleId: currentRoleId });
      administratorService.addAdminToCompanies(vm.admin.email, id, currentRoleId)
    .then((response) => {
        //update parent admins object
        let resultAdmin = response.data.data;
        administratorService.updateAdminsCurrentRoleIds(resultAdmin);
        vm.onUpdateAdmin({ admin: resultAdmin, index: indexInParent });
    })
    .finally(() => {
        //closeModal();
        //vm.saveInProgress = false;
    });
 }

  function closeModal() {
    $mdDialog.hide();
  }
  
  function addRemoveFlag(campaign) {
    campaign.remove = true;
  }

  function revertDeleteIcon(){
    vm.admin.groups.forEach((group) => {
      if (group.remove == true) {
        group.remove = false;
      }
    });

    vm.admin.campaigns.forEach((campaign) => {
      if (campaign.remove == true) {
        campaign.remove = false;
      }
    });

    if (vm.admin.entireOrgRole != undefined) {
        vm.admin.entireOrgRole.remove = false;
    }
  }

  function removeEntity(campOrGroup) {

      administratorService.removeAdmin(vm.admin.id, campOrGroup.entityId)
          .then((response) => {

              let resultAdmin = response.data.data[0];

              //check if admin has no more groups or campaigns 
              if (resultAdmin == undefined) {
                  vm.admin.campaigns = [];
                  vm.admin.groups = [];
                  vm.admin.entireOrgRole = null;
                  vm.onUpdateAdmin({ admin: vm.admin, index: indexInParent });
              } else {
                  administratorService.updateAdminsCurrentRoleIds(resultAdmin);
                  removeUndefinedCmpnsAndGroups(resultAdmin); //to fix glitch that was making some campaigns display double for a moment
                  vm.onUpdateAdmin({ admin: resultAdmin, index: indexInParent });
              }

              //add this campaign to options in search
              var campInDropDownForm = {
                  value: campOrGroup.name.toLowerCase(),
                  entityId: campOrGroup.entityId,
                  entityType: campOrGroup.entityType || 'Group',
                  url: campOrGroup.url,
                  smallImage: campOrGroup.smallImage,
                  display: campOrGroup.name
              };
              vm.campaigns.splice(0, 0, campInDropDownForm);
          });
  }

  function querySearch(query) {
      let results = query ? vm.campaigns.filter(createFilterFor(query)) : vm.campaigns;
      return results;
  }

  function selectedItemChange(item) {
      if (vm.newAdmin.campaign) {
          vm.campaigns.forEach((camp, index) => {
              if (camp.display === vm.newAdmin.campaign.display) {
                  vm.campaigns.splice(index, 1);
              }
          })
      };
  } // remove campaign from the list;

  function isNotArchived(cmp) {
      return !cmp.archived;
  }

  function loadCampaigns() {
      orgGroupsCampaigns = orgGroupsCampaigns.filter(isNotArchived)
      orgGroupsCampaigns = orgGroupsCampaigns.map(function (camp) {
          return {
              value: camp.name.toLowerCase(),
              entityId: camp.entityId,
              entityType: camp.entityType || 'Group',
              url: camp.url,
              smallImage: camp.smallImage,
              display: camp.name
          };
      });

      var entireOrgOption = {
          value: "entire organization",
          entityId: entityService.getOrgEntityId(),
          entityType: 'Org',
          display: "Entire Organization"
      };

      if (vm.admin.entireOrgRole.name == undefined) {
          orgGroupsCampaigns.splice(0, 0, entireOrgOption);
      }
      
      vm.admin.groups.forEach(function(element) {
          for (var i = 0; i < orgGroupsCampaigns.length; i++) {
              if (element.currentRoleId != undefined && orgGroupsCampaigns[i].entityId == element.entityId) {
                  orgGroupsCampaigns.splice(i, 1);
                  break;
              }
          }
      });
      vm.admin.campaigns.forEach(function (element) {
          for (var i = 0; i < orgGroupsCampaigns.length; i++) {
              if (element.currentRoleId != undefined && orgGroupsCampaigns[i].entityId == element.entityId) {
                  orgGroupsCampaigns.splice(i, 1);
                  break;
              }
          }
      });
      return orgGroupsCampaigns;
  }

  function createFilterFor(query) {
      var lowercaseQuery = angular.lowercase(query);

      return function filterFn(camp) {
          return (camp.value.indexOf(lowercaseQuery) === 0);
      };
  };

    //end autocomplete

  function addCampaign() {
      let newCamp = angular.copy(vm.newAdmin);
      vm.saveInProgress = true;
      let arg = [];
      arg.push({ userId: vm.admin.id, entityId: newCamp.campaign.entityId, roleId: newCamp.role });
      administratorService.addAdminToCompanies(vm.admin.email, newCamp.campaign.entityId, newCamp.role)
      .then((response) => {

            //add to mddialog(child) admin object so it goes at bottom of list
            if (newCamp.campaign.entityType == "CampaignGroup") {
                vm.admin.groups.push({
                    name: newCamp.campaign.display
                    , entityId: newCamp.campaign.entityId
                    , currentRoleId: newCamp.role
                    , entityType: newCamp.campaign.entityType
                });
            } else if (newCamp.campaign.entityType == "Campaign") {
                vm.admin.campaigns.push({
                    name: newCamp.campaign.display
                    , entityId: newCamp.campaign.entityId
                    , url: newCamp.campaign.url
                    , currentRoleId: newCamp.role
                    , entityType: newCamp.campaign.entityType
                    , smallImage: newCamp.campaign.smallImage
                });
            } else {
                vm.admin.entireOrgRole = {
                    name: newCamp.campaign.display
                    , entityId: newCamp.campaign.entityId
                    , currentRoleId: newCamp.role
                    , entityType: 'Org'
                };
            }
            
            //remove added campaign from dropdown list
            vm.updatedCampaignsList = angular.copy(vm.campaigns);
            vm.campaigns.forEach((camp, index) => {
                if (camp.display === newCamp.campaign.display) {
                    vm.updatedCampaignsList.splice(index, 1);
                }
            });
            vm.campaigns = vm.updatedCampaignsList;
              
            //update parent admins object
            let resultAdmin = response.data.data;
            administratorService.updateAdminsCurrentRoleIds(resultAdmin);
            vm.onUpdateAdmin({ admin: resultAdmin, index: indexInParent });
            
          })
      .finally(() => {
          //closeModal();
          vm.saveInProgress = false;
      });

      vm.newAdmin.campaign = null;
      vm.newAdmin.role = null;
      vm.searchText = null;
  }


}
