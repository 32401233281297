angular
  .module('app')
  .component('administratorsList', {
    templateUrl: 'administrators-list.tpl.html',
    controller: AdministratorsListController,
    controllerAs: 'vm'
  });

function AdministratorsListController($q, administratorService, messageService, campaignService, $timeout, $mdDialog, orgService, securityService) {
  const vm = this;

  vm.inviteInProgress = false;
  vm.inviteExistingAdmin = inviteExistingAdmin;
  vm.focusInput = focusInput;
  vm.searchAdmin = searchAdmin;
  vm.showRemoveConfirm = showRemoveConfirm;
  vm.dataLoaded = false;
  vm.removeInProgress = false;
  vm.showModalCampaigns = false;
  vm.showModalAdmin = false;
  vm.admins = [];
  vm.roles = [];
  vm.show = false;
  vm.security = {};
  loadData();

  //////////////////////////////////////////////////////////////
  function advancedInviteErrors(currentMessageService, response) {
    var msg = '';
    response.data.data.errors.forEach((error) => {
        error.errorsList.forEach((entity) => {
            msg += error.key + ' could not be invited to ' + entityService.getEntityName(entity.errorMessage) + '.<br>';
        });
    });
    msg += "All the other invites were successful."
    messageService.showErrorDialog(msg);
}

function inviteExistingAdmin($event, index) {
    vm.inviteInProgress = true;
    $event.stopPropagation();

    for (let i = 0; i < vm.admins[index].email.length; i++) {
        if (typeof vm.admins[index].email[i] === 'object') {
            vm.admins[index].email[i] = vm.admins[index].email[i].email;
        }
    }

    let entityIds = vm.admins[index].roleById.map(role => role.entityId);

    let messageConfig = {
        advancedErrorFunction: advancedInviteErrors,
        advancedErrorEnabled: true,
        successToastEnabled: true,
        successMsg: `Successfully invited ${vm.admins[index].email}`
    };

    administratorService
        .inviteAdmin(vm.admins[index].email, vm.admins[index].roleById, undefined, entityIds, '', messageConfig)
        .then(data => {
            let newAdmins = data.data.data;

            administratorService.updateAdminsCurrentRoleIds(newAdmins[0]);
            vm.updateAdmin(newAdmins[0], index);

            $mdDialog.hide();
          })
        .finally(() => vm.inviteInProgress = false);
}

  function loadData() {
    vm.dataLoaded = false;
	campaignService.openGroup(0).then(() => {
		let promises = [
		  administratorService.getAllOrgAdmins(),
		  administratorService.getAllRoles()
		];

		$q.all(promises)
		  .then(([admins, roles]) => {
			vm.admins = admins;
			vm.roles = roles;
		  })
		  .finally(() => vm.dataLoaded = true);
	})
    
  }

  vm.security = securityService.getAdminPermissions(orgService.getOrg(), 'ADMIN_USERS');

  function searchAdmin(){
    let data = administratorService.getAdminsFromOrg();
    console.log(data);
      vm.admins=[];
        data.forEach(function(admin) {
             let query = vm.searchQuery.toLowerCase();
             if((admin.email.indexOf(query) > -1) || (admin.firstName && admin.firstName.toLowerCase().indexOf(query) > -1)
               || (admin.lastName && admin.lastName.toLowerCase().indexOf(query) > -1)){
                    vm.admins.push(admin);
              };
          });
  }

  function focusInput(){
    let input = document.getElementById('admin-searchBox');
        $timeout(function () { 
          input.focus(); 
        }, 0);
  } 

  function showRemoveConfirm(adminId, event, email) {
    event.stopPropagation();
    let confirm = $mdDialog.confirm()
      .clickOutsideToClose(true)
      .title('Are you sure?')
      .htmlContent(`This action will remove admin ${email}.`)
      .targetEvent(event)
      .ok('Remove Admin')
      .cancel('Cancel');

    $mdDialog.show(confirm).then(() => removeAdmin(adminId, event));
  };


  function removeAdmin(adminId, event) {
    event.stopPropagation();
    vm.removeInProgress = true;
    administratorService.removeAdminFromOrg(adminId).then(() => {

      let newArr = vm.admins.filter((admin) =>{
        return admin.id !== adminId;
      });
      
      vm.admins = newArr;
      vm.updateAdmin({ id: adminId }, null, true);
	})
    .finally(() => vm.removeInProgress = false);
  };

  vm.updateAdmin = function(admin, index, remove) {
      if (remove) {
          administratorService.updateAllAdmins(admin, index, true);
      } else {
          if (index !== undefined && index != null && index > -1) {
               vm.admins[index] = admin;
              //update administratorService allAdmins object
              administratorService.updateAllAdmins(admin, index);
          } else {
              //for some reason administratorService.addToAllAdmins() is adding it to the dom already
              //  vm.admins.push(admin);

              //add to administratorService allAdmins object
              administratorService.addToAllAdmins(admin);
          }
      }
  }

}