angular
  .module('app')
  .component('modalOrgInfo', {
    bindings: {
      show: '=',
      entityId: '=',
      org: '='
    },
    controller: ModalOrgInfoCtrl,
    controllerAs: 'vm'
  });

function ModalOrgInfoCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-org-info.tpl.html',
        clickOutsideToClose: true,
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          entityId: vm.entityId,
          org: vm.org
        }
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($mdDialog, $rootScope, $q,
                          entityService, campaignService,  orgService,
                          entityId, org, settingsService, securityService) {
  const vm = this;
  vm.org = org;
  vm.entityId = entityId;
  vm.countries = [];
  vm.orgInfoFetching = false;
  vm.groups = [];
  vm.editFlag = {};
  vm.offEdits = offEdits;
  vm.cancel = closeModal;
  vm.saveOrg = saveOrg;
  vm.cancelEdit = cancelEdit;
  vm.checkHotKey = checkHotKey;
  vm.security = {};
  vm.enableEditField = enableEditField;
  loadInformation();

  function loadInformation() {
    vm.orgInfoFetching = true;

    const requests = [
      orgService.getOrgInfo()
        .then(info => {
          vm.info = info;
          vm.previousInfo = angular.copy(vm.info);
          vm.security = securityService.getAdminPermissions(entityService.getRawEntityById(vm.entityId), 'CAMPAIGN');
        }),
      settingsService.getCountries()
        .then(countries => vm.countries = countries)
    ];
    $q.all(requests).finally(() => vm.orgInfoFetching = false);
  }

  function enableEditField(fieldName) {
      if(vm.security.EDIT) {
        vm.editFlag[fieldName] = true;
      }
  }
  
  function closeModal() {
    $mdDialog.cancel();
  }

  function saveOrg(field) {
    vm.saveInProgress = true;

    orgService.saveEditOrg(vm.org.orgId, field, vm.info[field])
      .then(res => {
        vm.previousInfo[field] = vm.info[field];
        vm.org.name = vm.info.name;
        vm.editFlag[field] = false;
      })
      .catch(error => {
        cancelEdit(field);
      })
      .finally(() => vm.saveInProgress = false);
  }

  function offEdits(field) {
   for (let f in vm.info){
      if (f != field) {
        cancelEdit(f);
      }
    }
  }

  function cancelEdit(field) {
    vm.info[field] = vm.previousInfo[field];
    vm.editFlag[field] = false;
  }

 

  function checkHotKey(event, field) {

    const ENTER = 13;

    let key = event.keyCode;

    if (key === ENTER) saveOrg(field);
  };

}
