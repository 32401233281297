(function () {
    const app = angular.module('app', [
        'ngComponentRouter',
        'ngMaterial',
        'ngSanitize',
        'ngMessages',
        'ngImgCrop',
        'agGrid',
        'slickCarousel',
        'duScroll',
        //scroll spy'
        'templatesCache',
        'ngMaterialDatePicker',
        'rzModule',
        'infinite-scroll',
        'ngIdle',
        'ngAnimate'
    ]);
    angular.lowercase = text => text.toLowerCase();
    app.value('$routerRootComponent', 'app');
    app.constant('API_PREFIX', (window.championApiPrefix || '') + '/Platform');
    app.constant('FORM_ITEM_TYPES', {
        TOGGLE: 1,
        TEXT: 2,
        DATE: 3,
        DECIMAL: 4,
        SELECT: 5,
        MULTISELECT: 6,
        INT: 7,
        ORDERABLE: 8,
        TEXTAREA: 9,
        GROUP: 10
    });
    app.factory('rbTransformRequests', function ($httpParamSerializerJQLike) {
        return {
            transformFormData: function (data) {
                var formData = new FormData();
                angular.forEach(data, function (value, key) {
                    formData.append(key, value);
                });
                return formData;
            },
            transformForm: function (data) {
                return $httpParamSerializerJQLike(data);
            }
        };
    });
    app.config([
        '$httpProvider',
        function ($httpProvider) {
            $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        }
    ]);
    var myApp = angular.module('myApp', []);
    app.config(($compileProvider, $mdThemingProvider, $httpProvider, $mdInkRippleProvider) => {
        // disable debug directives
        // Increases performance
        // see https://code.angularjs.org/1.5.5/docs/guide/production
        // TODO uncomment in production
        //$compileProvider.debugInfoEnabled(false);
        // change default material primary colors
        let customPalette = $mdThemingProvider.extendPalette('indigo', {
            '400': '00698C',
            '500': '00698C',
            '600': '00698C'
        });
        //$mdInkRippleProvider.disableInkRipple(); //TODO update angular-material to 1.1.0 and uncomment
        $mdThemingProvider.definePalette('customIndigo', customPalette);
        $mdThemingProvider.theme('default').primaryPalette('customIndigo');
        $httpProvider.interceptors.push(($q, $injector, $location) => {
            return {
                request(config) {
                    if (config.method != 'GET') {
                        let currentMessageService = getCurrentMessageService(config);
                        if (currentMessageService.checkSetPendingRequest(config.url, config.data)) {
                            let response = {
                                config,
                                rejectedBcInProgress: true,
                                rejectedMsg: currentMessageService.config.requestInProgressMsg
                            };
                            return $q.reject(response);
                        }
                    }
                    addOrgIDToHeaders(config);
                    return config;
                },
                response(response) {
                    let currentMessageService = getCurrentMessageService(response.config);
                    currentMessageService.resetPendingRequest(response.config.url, response.config.data);
                    if (currentMessageService.config.suppressErrorHandling) {
                        return $q.resolve(response);
                    }
                    // handle json response
                    if (response.headers()['content-type'] === 'application/json; charset=utf-8') {
                        if (response && response.data) {
                            if (response.data.success) {
                                currentMessageService.showSuccessToast();
                            } else {
                                if (response.data.msg) {
                                    if (response.data.msg == 'LOGGED OUT') {
                                        location.href = window.isChampion ? '/Account/Logon?returnUrl=' + encodeURIComponent(location.pathname) : '/Admin/Account/LogOff?redirectToLogin=true&redirectTo=' + encodeURIComponent(location.pathname);
                                        return;
                                    } else if (response.data.msg == 'ACCESS DENIED') {
                                        currentMessageService.showAccessDeniedDialog();
                                    } else {
                                        currentMessageService.showErrorToast(response.data.msg);
                                    }
                                    return $q.reject(response.data.msg);
                                }
                                currentMessageService.showAdvancedError(response);
                                return $q.reject(response);
                            }
                        }
                    }
                    return $q.resolve(response);
                },
                // global error handler
                responseError(response) {
                    let currentMessageService = getCurrentMessageService(response.config);
                    if (response.rejectedBcInProgress) {
                        currentMessageService.showRequestInProgressToast();
                        return $q.reject(response.rejectedMsg);
                    } else if (response.status === 503) {
                        currentMessageService.resetPendingRequest(response.config.url, response.config.data);
                        currentMessageService.showErrorToast(response.data.msg);
                        return $q.reject(response.data.msg);
                    }
                    currentMessageService.resetPendingRequest(response.config.url, response.config.data);
                    currentMessageService.showErrorToast();
                    return $q.reject(response.statusText);
                }
            };
            function getCurrentMessageService(config) {
                let messageService = $injector.get('messageService');
                config.messageService = config.messageService || new messageService(config.messageConfig);
                return config.messageService;
            }
            function addOrgIDToHeaders(config) {
                var orgId = getOrgIdFromPath();
                if (orgId) {
                    //it may have it from actual request and if iyt's initial call before url changed we don't want to remove it
                    config.headers['orgId'] = getOrgIdFromPath();
                }
            }
        });
    });
    window.getOrgIdFromPath = function () {
        var pathSplit = location.pathname.split('/');
        var orgIndex = pathSplit.indexOf('org');
        if (orgIndex > -1 && pathSplit.length > orgIndex) {
            return pathSplit[orgIndex + 1];
        }
    };
    const startEndRegex = /(?:^\/+)|(?:\/+$)/;
    const middleRegex = /\/+/;
    window.getCampaignPathFromPath = function () {
        const pathSplit = location.pathname.replace(startEndRegex, '').split(middleRegex);
        if (pathSplit.length < 2 || pathSplit[1].toLowerCase() != 'championadmin') {
            return undefined;
        }
        return pathSplit[0];
    };
    app.config([
        '$mdAriaProvider',
        function ($mdAriaProvider) {
            $mdAriaProvider.disableWarnings();
        }
    ]);
    app.config(function ($locationProvider) {
        $locationProvider.html5Mode({ enabled: true });
    });
    app.config(function ($provide) {
        $provide.decorator('$mdDialog', function ($delegate) {
            let dialogs = [
                'confirm',
                'prompt',
                'alert'
            ];
            let defaults = {
                templateUrl: 'generic-dialog.tpl.html',
                controllerAs: 'vm'
            };
            dialogs.forEach(dialog => {
                let originalDialog = $delegate[dialog];
                $delegate[dialog] = function () {
                    let args = angular.extend({}, defaults, arguments[0]);
                    return originalDialog(args);
                };
            });
            return $delegate;
        });
        $provide.decorator('$exceptionHandler', [
            '$delegate',
            '$window',
            function ($delegate, $window) {
                return function (exception, cause) {
                    if (window.TrackJS) {
                        if (cause != undefined) {
                            window.TrackJS.addMetadata('cause', cause);
                        }
                        window.TrackJS.track(exception);
                        window.TrackJS.removeMetadata('cause');
                    } else {
                        // In *non-production* environments you may still want the error sent to the console.
                        // You can delegate to the original handler (console.error) if you would like.
                        // Warning, this can cause double tracking of errors, so do not use in production.
                        // $delegate(exception, cause);
                        $delegate(exception, cause);
                    }
                };
            }
        ]);
    });
    app.component('app', {
        templateUrl: 'app.tpl.html',
        $routeConfig: [
            {
                path: 'org/:orgId/organization/...',
                name: 'Organization',
                component: 'organization',
                data: {
                    authenticate: true,
                    allowOnInstance: true
                }
            },
            {
                path: 'my/...',
                name: 'Profile Settings',
                component: 'profileSettings',
                data: {
                    authenticate: true,
                    allowOnInstance: true
                }
            },
            {
                path: 'p/...',
                name: 'Static',
                component: 'static'
            },
            {
                path: 'org/:orgId/group/:groupId/campaign/:campaignId/instance/:instanceId/...',
                name: 'Instance In Group',
                component: 'campaigns',
                data: {
                    authenticate: true,
                    allowOnInstance: true
                }
            },
            {
                path: 'org/:orgId/group/:groupId/campaign/:campaignId/...',
                name: 'Campaign In Group',
                component: 'campaigns',
                data: {
                    authenticate: true,
                    allowOnInstance: true
                }
            },
            {
                path: 'org/:orgId/group/:groupId/...',
                name: 'Group',
                component: 'campaigns',
                data: {
                    authenticate: true,
                    allowOnInstance: true
                }
            },
            {
                path: 'org/:orgId/campaign/:campaignId/instance/:instanceId/...',
                name: 'Instance',
                component: 'campaigns',
                data: {
                    authenticate: true,
                    allowOnInstance: true
                }
            },
            {
                path: 'org/:orgId/campaign/:campaignId/...',
                name: 'Campaign',
                component: 'campaigns',
                data: {
                    authenticate: true,
                    allowOnInstance: true
                }
            },
            {
                path: ':campaignPath/ChampionAdmin/...',
                name: 'ChampionAdmin',
                component: 'campaigns',
                data: {
                    authenticate: true,
                    allowOnInstance: true
                }
            },
            {
                path: 'org/:orgId/...',
                name: 'Org',
                component: 'campaigns',
                data: {
                    authenticate: true,
                    allowOnInstance: true
                },
                useAsDefault: true
            }
        ],
        controller: AppController,
        controllerAs: 'vm'
    });
    function AppController($http, API_PREFIX, $timeout, $q, $location, $rootScope, $mdToast, $sce, profileService, orgService, campaignService, authenticationService, entityService, settingsService, ckEditorService, cookieService) {
        const vm = this;
        vm.dataLoaded = false;
        vm.showNotification = false;
        vm.showExitButton = false;
        vm.showDontShowAgain = false;
        vm.currentNotificationId;
        vm.showTour = false;
        vm.closeBanner = closeBanner;
        vm.dontShowAgain = dontShowAgain;
        vm.currentYear = new Date().getFullYear();
        fetchData();
        ckEditorService.configureCkEditor();
        ///////////////////////////////////////////////////////
        function fetchData() {
            authenticationService.checkAuthentication().then(isAuthenticated => {
                vm.isAuthenticated = isAuthenticated;
                if (vm.isAuthenticated) {
                    profileService.getProfileInfo().then(profileInfo => {
                        let orgFromUrl = orgService.getUrlOrgId();
                        let orgOrgId = orgFromUrl || profileInfo.userSettings.orgId || profileInfo.orgs[0].orgId;
                        let curOrg;
                        if (orgOrgId) {
                            profileInfo.orgs.forEach((item, index) => {
                                if (item.orgId == orgOrgId) {
                                    curOrg = item;
                                }
                            });
                        }
                        if (curOrg) {
                            orgService.setOrg(curOrg);
                            settingsService.loadStaticInfo();
                            campaignService.openGroup(0, orgService.getOrg().entityId).then(campaigns => {
                                entityService.updateAllEntities();
                                vm.dataLoaded = true;
                            });
                            orgService.orgInfoLoadedPromise.then(r => {
                                vm.splashNotificationsData = vm.splashNotificationsData.concat(r.currentSplashNotifications);
                            });
                            vm.splashNotificationsData = profileInfo.currentSplashNotifications;
                            vm.splashLastFinishedNotificationId = profileInfo.userSettings.lastFinishedSplashNotificationId || 0;
                            let seenInSession = cookieService.readCookie('rb_sawAlert');
                            if (!seenInSession) {
                                vm.showExitButton = profileInfo.currentNotification.showCloseButton;
                                vm.showDontShowAgain = profileInfo.currentNotification.showDontShowAgain;
                                vm.msg = $sce.trustAsHtml(profileInfo.currentNotification.innerHtml);
                                let lastFinishedNotificationId = profileInfo.userSettings.lastFinishedNotificationId || 0;
                                vm.currentNotificationId = profileInfo.currentNotification.notificationId;
                                if (vm.currentNotificationId > lastFinishedNotificationId) {
                                    var fn = function () {
                                        vm.showNotification = true;
                                    };
                                    $timeout(fn, 2000);
                                }
                            }
                            if (profileInfo.userSettings.showPortAdminTour === '1') {
                                vm.showTour = true;
                            }
                            // #region Pendo installation
                            // Run only in production environment where pendo api key is available
                            if (pendoApiKey && !window.isChampion) {
                                orgService.getOrgInfo().then(data => {
                                    if (!data.isDemoOrg || orgOrgId == 260 || orgOrgId == 432) {
                                        let pendoValue = {
                                            apiKey: pendoApiKey,
                                            orgName: data.name,
                                            firstName: profileInfo.firstName,
                                            lastName: profileInfo.lastName,
                                            salesforceId: data.salesforceAccountID,
                                            creationDate: data.creationDate,
                                            email: profileInfo.userEmail,
                                            role: pendoRole
                                        };
                                        loadPendo(pendoValue);
                                    }
                                });
                            }    // #endregion
                        } else {
                            alert('Sorry, we had an issue finding your organization ' + (orgOrgId || ''));
                        }
                    });
                } else {
                    vm.dataLoaded = true;
                }
            });
        }
        vm.endTour = function sawTour() {
            vm.showTour = false;
            $http({
                url: API_PREFIX + 'Profile/UpdateUserSettings',
                data: { list: { showPortAdminTour: 0 } },
                method: 'POST'
            });
        };
        function dontShowAgain() {
            cookieService.createCookie('rb_sawAlert', '1');
            vm.showNotification = false;
            $http({
                url: API_PREFIX + 'Profile/UpdateUserSettings',
                data: { list: { lastFinishedNotificationId: vm.currentNotificationId } },
                method: 'POST'
            });
        }
        function closeBanner() {
            vm.showNotification = false;
            cookieService.createCookie('rb_sawAlert', '1');
            let notificationIdAndCnt = cookieService.readCookie('rb_alert_cnt');
            let cookieCnt;
            if (!notificationIdAndCnt) {
                cookieCnt = 1;
            } else {
                let splitArray = notificationIdAndCnt.split('|');
                let cookieNotificationId = splitArray[0];
                cookieCnt = splitArray[1];
                if (cookieCnt >= 3) {
                    $http({
                        url: API_PREFIX + 'Profile/UpdateUserSettings',
                        data: { list: { lastFinishedNotificationId: vm.currentNotificationId } },
                        method: 'POST'
                    });
                }
            }
            cookieService.createCookie('rb_alert_cnt', vm.currentNotificationId + '|' + (parseInt(cookieCnt) + 1), 30);
        }
        function loadPendo(pendoValue) {
            let apiKey = pendoValue.apiKey;
            (function (p, e, n, d, o) {
                var v, w, x, y, z;
                o = p[d] = p[d] || {};
                o._q = o._q || [];
                v = [
                    'initialize',
                    'identify',
                    'updateOptions',
                    'pageLoad',
                    'track'
                ];
                for (w = 0, x = v.length; w < x; ++w)
                    (function (m) {
                        o[m] = o[m] || function () {
                            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                        };
                    }(v[w]));
                y = e.createElement(n);
                y.async = !0;
                y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
                z = e.getElementsByTagName(n)[0];
                z.parentNode.insertBefore(y, z);
            }(window, document, 'script', 'pendo'));
            setTimeout(() => {
                if (pendo) {
                    let salesforceId = pendoValue.salesforceId ? pendoValue.salesforceId : '';
                    let visitorId = salesforceId + ' - ' + pendoValue.email;
                    pendo.initialize({
                        visitor: {
                            id: visitorId,
                            firstname_fundraise: pendoValue.firstName,
                            lastname_fundraise: pendoValue.lastName,
                            email_fundraise: pendoValue.email,
                            role: pendoValue.role
                        },
                        account: {
                            id: salesforceId,
                            name: pendoValue.orgName,
                            creationDate: pendoValue.creationDate
                        }
                    });
                }
            }, 2000);
        }
    }
}());
(function () {
    angular.module('app').component('adminTour', {
        controllerAs: 'vm',
        controller: adminTourController,
        templateUrl: 'admin-tour.tpl.html',
        bindings: { endTour: '<' }
    });
    function adminTourController(securityService, entityService, $window, $timeout) {
        const vm = this;
        vm.steps = [
            {
                id: 0,
                image: '/content/platform/img/admin-tour/step-0.png',
                title: '',
                body: `<ul>
            <li>This is now the only admin interface! All data and settings have been migrated from the Admin Panel to this interface.</li>
            <li>Settings have been moved around. </li>
            <li> Settings and dictionary entries can now be configured on the organization or group level and apply to campaigns within them.</li>
        </ul>
        <br/>
        Click "Take Tour" to learn more about these changes.`
            },
            {
                id: 1,
                image: '/content/platform/img/admin-tour/step-1.png',
                title: 'Top Level Navigation',
                body: `<ul>
            <li>Access your campaigns' data and settings under "Campaigns".</li>
            <li>Access and modify settings and libraries related to the organization under "Organization". These include administrators, security roles, and automation.</li>
        </ul>`,
                attachTo: function () {
                    return $('.nav-top-menu').children().first();
                },
                highlight: function () {
                    return $('.nav-top-menu').parent();
                }
            },
            {
                id: 2,
                image: '/content/platform/img/admin-tour/step-2.png',
                title: 'Entity Navigation',
                body: `On this level, you can navigate between groups and campaigns. Within each, you will be able to access data and settings. 
       To return to the campaigns list of a group, click the group name.`,
                attachTo: function () {
                    return $('.sub-nav-firstline').find('ul').children().last().find('a');
                },
                highlight: function () {
                    return $('.sub-nav-firstline').children().first();
                }
            },
            {
                id: 3,
                image: '/content/platform/img/admin-tour/step-3.png',
                title: 'Settings Navigation',
                body: `Use these tabs to access particular data or settings within a group or campaign. 
        <br/>
        Take note of your location by looking at the group or campaign name above these tabs — that's the location your configurations will apply to. 
        <br/>
        Any setting configured on a group will be applied to campaigns within it. 
        To indicate when you're on a campaign level, the header background will be blue.`,
                attachTo: function () {
                    return $('#secondary-navs').children().first();
                },
                highlight: function () {
                    return $('#secondary-navs').parent();
                }
            },
            {
                id: 4,
                image: '/content/platform/img/admin-tour/step-4.png',
                title: 'Locating Your Settings',
                body: `Many settings and dictionary entries have been moved. Most notably: 
        <ul>
            <li>Settings and dictionary entries related to donations and registrations have been moved to the "Forms" tab.</li><li>Pages previously accessible in the "Site Content" section are available in the "Website" tab.</li>
            <li>Settings and dictionary entries related to fundraising have been moved to the "Fundraising" tab.</li>
            <li>Processor and integration settings have been moved to the "System" tab.</li>
        </ul>
        Additionally, the permission structure has been updated to reflect these changes. No action is required on your part — administrators will continue to have the same permissions they had before the migration.`,
                attachTo: function () {
                    return $('#secondary-navs').children().last();
                },
                highlight: function () {
                    return $('#secondary-navs').parent();
                },
                center: true
            },
            {
                id: 5,
                image: '/content/platform/img/admin-tour/step-5.png',
                title: 'Configuring Your Settings',
                body: `Any setting configured on a group will apply to all groups and campaigns within that group. Additionally, groups and campaigns can override their inherited configuration.
        <br/>
        <br/>
        <div class="body-detail">
            <img src="/content/platform/img/admin-tour/lock.svg">
            <div>Look for this icon to indicate when a setting is inherited. Click the icon to override the parent configuration.</div>
        </div>
        <br/>
        <div class="body-detail">
            <img src="/content/platform/img/admin-tour/overriding.svg"> 
            <div>This icon indicates when a group or campaign is overriding its parent configuration. Click the icon to clear the override and inherit the parent configuration.</div>
        </div>
        <br/>
        <div class="body-detail">
            <img src="/content/platform/img/admin-tour/unlocked.svg"> 
            <div>This icon indicates that a setting is configured for all children of the present entity. Click the icon to clear all child overrides and force all children of the entity to inherit the parent configuration.</div>
        </div>`
            }
        ];
        vm.curStep = 0;
        vm.previousStep = function () {
            handleHighlight(false);
            vm.curStep--;
            handleChange();
        };
        vm.nextStep = function () {
            handleHighlight(false);
            vm.curStep++;
            if (vm.curStep == vm.steps.length) {
                vm.endTour();
            }
            handleChange();
        };
        vm.setStep = function (step) {
            handleHighlight(false);
            vm.curStep = step;
            handleChange();
        };
        angular.element($window).on('resize', function () {
            $('#tour-overlay').height($(document).height());
        });
        handleChange();
        function handleChange(current, next) {
            positionTour();
            handleHighlight(true);
        }
        function handleHighlight(show) {
            if (vm.steps[vm.curStep] && vm.steps[vm.curStep].highlight) {
                vm.steps[vm.curStep].highlight().css('zIndex', show ? 45 : 'inherit');
            }
        }
        function positionTour() {
            var step = vm.steps[vm.curStep];
            var container = $('#step-container');
            var centerX = $(document).width() / 2 - container.width() / 2;
            if (step && step.attachTo && step.attachTo().length == 1) {
                var target = step.attachTo();
                var targetHeight = target.outerHeight();
                var targetPosition = target.offset();
                var targetBottom = targetPosition.top + targetHeight;
                container.offset({
                    top: targetBottom + 12,
                    left: step.center ? centerX : 30
                });
                $timeout(() => $('#tour-tip').offset({
                    top: targetBottom,
                    left: targetPosition.left + target.width() / 2
                }));
                $('#tour-tip').show();
            } else {
                container.offset({
                    top: 150,
                    left: centerX
                });
                $('#tour-tip').hide();
            }
        }
    }
}());
(function () {
    angular.module('app').controller('IEDialog', [
        '$scope',
        '$mdDialog',
        function ($scope, $mdDialog) {
            var alert;
            $scope.showDialog = showDialog;
            function showDialog($event) {
                var ua = window.navigator.userAgent, msie = ua.indexOf('MSIE ');
                alert = $mdDialog.alert({
                    targetEvent: $event,
                    templateUrl: 'browser-support.tpl.html',
                    controllerAs: 'ctrl',
                    controller: 'IEDialog'
                });
                if (navigator.appVersion.indexOf('MSIE 10') != -1 || msie > 0) {
                    $mdDialog.show(alert).finally(function () {
                        alert = undefined;
                    });
                }
            }
        }
    ]);
}());
(function () {
    angular.module('app').component('activity', {
        templateUrl: 'activity.tpl.html',
        controller: ActivityController,
        controllerAs: 'vm'
    });
    function ActivityController($http, API_PREFIX, $window, $scope, $rootScope, $location, $timeout, $interval, orgService, campaignService, entityService, messageService, securityService) {
        const vm = this;
        let $document = angular.element(document);
        let idleEvents = [
            'keydown',
            'keyup',
            'click',
            'mousemove',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'scroll',
            'focus'
        ];
        vm.logs = [];
        vm.lastActivtyId = null;
        vm.firstActivityId = null;
        vm.openEntity = null;
        vm.currencySymbol = '$';
        vm.numberWithCommas = numberWithCommas;
        vm.checkboxToggle = checkboxToggle;
        vm.transactionsCheckbox = true;
        vm.registrationsCheckbox = true;
        vm.fundraisingMilestonesCheckbox = true;
        vm.campaignId = orgService.getUrlCampaignId();
        vm.groupOrCmpNotFound = false;
        vm.endOfResults = false;
        vm.loadMore = loadMore;
        vm.showObjectModal = false;
        vm.currentLog = null;
        vm.clicked = clicked;
        vm.clearError = clearError;
        vm.newActivityToastShowing = false;
        vm.isIntervalActive = false;
        vm.noFilters = false;
        vm.isChampion = !!window.isChampion;
        vm.slider = {
            min: 0,
            max: 0,
            options: {
                floor: 0,
                ceil: 0
            }
        };
        vm.filterParams = {
            amountMin: 0,
            amountMax: 0,
            types: new Set([
                'Transactions',
                'Recurring Transactions',
                'Registrations',
                'Fundraising Milestones'
            ])
        };
        campaignService.checkUpdateRouting().then(setCampaignAndGroup).catch(setNotFound);
        function setCampaignAndGroup(campaignAndGroup) {
            if (campaignAndGroup.campaign) {
                $rootScope.$emit('onCampaignPage');
                $rootScope.$emit('campaign.opened', {
                    name: campaignAndGroup.campaign.name,
                    campId: campaignAndGroup.campaign.campaignId
                });
                vm.openEntity = campaignAndGroup.campaign.entityId;
            }
            if (campaignAndGroup.group) {
                var groupId = campaignAndGroup.group.groupId;
                vm.openEntity = vm.openEntity || campaignAndGroup.group.entityId;
            }
            vm.openEntity = vm.openEntity || entityService.getOrgEntityId();
            vm.currencySymbol = entityService.getEntityWithSettings(vm.openEntity).settings.DefaultCurrency.Symbol;
            openGroup(groupId);
            intervalStart();
            startScrollWatch();
        }
        function openGroup(groupId) {
            campaignService.openGroup(groupId).then(campaignsData => {
                $rootScope.$emit('group.opened');
            });
        }
        function setNotFound() {
            $rootScope.$emit('groupOrCampaignNotFound');
            vm.groupOrCmpNotFound = true;
        }
        function sliderFilter(transactionMax, transactionMin) {
            transactionMin = transactionMin || 0;
            transactionMax = transactionMax || 0;
            if (vm.slider.min == vm.slider.options.floor) {
                vm.slider.min = transactionMin;
                vm.filterParams.amountMin = transactionMin;
            }
            ;
            if (vm.slider.max == vm.slider.options.ceil) {
                vm.slider.max = transactionMax;
                vm.filterParams.amountMax = transactionMax;
            }
            if (vm.slider.floor != transactionMin || vm.slider.ceil != transactionMax) {
                vm.slider.options = {
                    floor: transactionMin,
                    ceil: transactionMax
                };
            }
        }
        $scope.$on('slideEnded', function () {
            vm.filterParams.amountMin = vm.slider.min;
            vm.filterParams.amountMax = vm.slider.max;
        });
        function checkboxToggle(type) {
            if (type == 't') {
                if (vm.transactionsCheckbox) {
                    vm.filterParams.types.add('Transactions');
                    vm.filterParams.types.add('Recurring Transactions');
                } else {
                    vm.filterParams.types.delete('Transactions');
                    vm.filterParams.types.delete('Recurring Transactions');
                }
            } else if (type == 'r') {
                if (vm.registrationsCheckbox) {
                    vm.filterParams.types.add('Registrations');
                } else {
                    vm.filterParams.types.delete('Registrations');
                }
            } else if (type == 'f') {
                if (vm.fundraisingMilestonesCheckbox) {
                    vm.filterParams.types.add('Fundraising Milestones');
                } else {
                    vm.filterParams.types.delete('Fundraising Milestones');
                }
            }
        }
        $scope.$on('updateActivityStream', function (event, data) {
            if (data.activityInfo != undefined) {
                for (var i = 0; i < vm.logs.length; i++) {
                    if (vm.logs[i].date == data.activityInfo.date) {
                        for (var j = 0; j < vm.logs[i].daysLogs.length; j++) {
                            if (vm.logs[i].daysLogs[j].activityId == data.activityInfo.id) {
                                //if doesnt have newId was moved in same event
                                if (data.newId) {
                                    var evntId = entityService.getRawEntityById(data.newEntityId).curEventId;
                                    vm.logs[i].daysLogs[j].currentDonationId = data.newId;
                                    vm.logs[i].daysLogs[j].currentEntityId = data.newEntityId;
                                    vm.logs[i].daysLogs[j].currentDonationEventId = evntId;
                                }
                                vm.logs[i].daysLogs[j].changeType = 2;
                                //moved
                                break;
                            }
                        }
                        break;
                    }
                }
            }
            loadLatest();
        });
        function formatLogs(unformattedLogs, latest) {
            let oldHeight = $document.height();
            let oldScrollTop = $document.scrollTop();
            if (unformattedLogs.length) {
                if (!latest) {
                    let lastUnformattedLog = unformattedLogs[unformattedLogs.length - 1];
                    vm.lastActivtyId = lastUnformattedLog.activityId;
                }
                if (latest || !vm.firstActivityId) {
                    let firstUnformattedLog = unformattedLogs[0];
                    vm.firstActivityId = firstUnformattedLog.activityId;
                }
            }
            let today = new Date();
            today.setHours(0, 0, 0, 0);
            let yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            if (latest)
                unformattedLogs.reverse();
            unformattedLogs.forEach(log => {
                let date = new Date(log.date);
                date.setHours(0, 0, 0, 0);
                let lastLog = vm.logs[vm.logs.length - 1];
                let firstLog = vm.logs[0];
                if (vm.logs.length && (!latest && lastLog.dateObj.getTime() == date.getTime()) || latest && firstLog.dateObj.getTime() == date.getTime()) {
                    !latest ? lastLog.daysLogs.push(log) : firstLog.daysLogs.unshift(log);
                } else {
                    let viewDate;
                    if (today.getTime() == date.getTime()) {
                        viewDate = 'Today';
                    } else if (yesterday.getTime() == date.getTime()) {
                        viewDate = 'Yesterday';
                    } else {
                        let locale = 'en-us';
                        let month = date.toLocaleString(locale, { month: 'short' });
                        let year = date.getFullYear();
                        viewDate = `${ month }. ${ date.getDate() }${ year != today.getFullYear() ? ` ${ year }` : '' }`;
                    }
                    let formattedLog = {
                        dateObj: date,
                        date: viewDate,
                        daysLogs: [log]
                    };
                    !latest ? vm.logs.push(formattedLog) : vm.logs.unshift(formattedLog);
                }
                log.campaignUrl = campaignService.getCampaignById(log.campaignId).campaignUrl;
            });
            if (latest && oldScrollTop) {
                $timeout(() => {
                    $document.scrollTop(oldScrollTop + ($document.height() - oldHeight));
                });
            }
        }
        function startScrollWatch() {
            $scope.$watch(() => {
                return {
                    scrollHeight: document.body.scrollHeight,
                    clientHeight: document.body.clientHeight
                };
            }, LoadMoreIfNoScroll, true);
        }
        $scope.$watch('vm.filterParams.types.size', (newValue, oldValue) => {
            vm.noFilters = !newValue;
        });
        function intervalStart() {
            if (!vm.isIntervalActive) {
                vm.isIntervalActive = true;
                idleEvents.forEach(event => $document.off(event, intervalStart));
                vm.idleInterval = $interval(loadLatest, 30000, 10);
                vm.idleInterval.then(() => {
                    vm.isIntervalActive = false;
                    idleEvents.forEach(event => $document.on(event, intervalStart));
                });
            }
        }
        function clearError() {
            vm.error = false;
            loadMore();
        }
        function LoadMoreIfNoScroll(lastTry) {
            let hasVScroll;
            $timeout(() => {
                hasVScroll = document.body.scrollHeight > document.body.clientHeight;
                if (!hasVScroll) {
                    loadMore();    //Fix for delayed/jumpy ui updates.
                } else if (!lastTry) {
                    $timeout(() => LoadMoreIfNoScroll(true), 100);
                } else {
                    vm.loader = false;
                }
            });
        }
        function loadMore() {
            if (angular.element(document.body).hasClass('md-dialog-is-showing')) {
                return;
            }
            if (!vm.endOfResults && !vm.loadInProgress && !vm.error && !vm.noFilters) {
                vm.loader = true;
                vm.loadInProgress = true;
                $http({
                    url: `${ API_PREFIX }/GetNextActivityStream`,
                    headers: { parentEntityId: vm.openEntity },
                    data: { lastId: vm.lastActivtyId },
                    method: 'POST'
                }).then(response => {
                    if (response.data.data.activityList.length === 0) {
                        vm.endOfResults = true;
                        vm.loader = false;
                    } else {
                        formatLogs(response.data.data.activityList);
                    }
                    if (response.data.data.maxTransactionAmount) {
                        sliderFilter(response.data.data.maxTransactionAmount, response.data.data.minTransactionAmount);
                    }
                    LoadMoreIfNoScroll();
                }).catch(() => {
                    vm.loader = false;
                    vm.error = true;
                }).finally(() => vm.loadInProgress = false);
            }
        }
        function loadLatest() {
            if (!vm.noFilters) {
                $http({
                    url: `${ API_PREFIX }/GetNextActivityStream`,
                    headers: { parentEntityId: vm.openEntity },
                    data: { firstId: vm.firstActivityId },
                    method: 'POST'
                }).then(response => {
                    if (response.data.data.activityList.length === 0) {
                        return;
                    }
                    formatLogs(response.data.data.activityList, true);
                    if (response.data.data.maxTransactionAmount) {
                        sliderFilter(response.data.data.maxTransactionAmount, response.data.data.minTransactionAmount);
                    }
                    if (!vm.newActivityToastShowing && document.body.scrollTop) {
                        vm.newActivityToastShowing = true;
                        messageService.showSuccessToast('You have new updates.', 'Show Me', scrollToTop).finally(() => vm.newActivityToastShowing = false);
                    }
                    loadLatest();
                });
            }
        }
        function scrollToTop() {
            angular.element(document.body).animate({ scrollTop: 0 }, 'slow');
        }
        $scope.$on('$destroy', function () {
            // Make sure that the interval is destroyed too
            $interval.cancel(vm.idleInterval);
        });
        function clicked(log, day) {
            var entity = entityService.getRawEntityById(log.currentEntityId);
            var hasPermission = securityService.getAdminPermissions(entity, 'DONATION');
            var currentEventId = log.currentDonationEventId != null ? log.currentDonationEventId : log.eventId;
            var currentDonationId = log.currentDonationId != null ? log.currentDonationId : log.donationId;
            if (hasPermission.DELETE) {
                vm.objectActivityInfo = {
                    date: day.date,
                    id: log.activityId
                };
                vm.objectEntityId = log.currentEntityId;
                vm.objectEventId = currentEventId;
                vm.objectType = log.innerType;
                vm.objectId = currentDonationId;
                vm.showObjectModal = true;
            } else {
                window.open(log.campaignUrl + '/transactions/donations?type=Donation&value=' + currentDonationId, '_blank');
            }
        }
    }
}());
(function () {
    angular.module('app').component('campaigns', {
        templateUrl: 'campaigns.tpl.html',
        controller: CampaignsController,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        },
        bindings: { $router: '<' },
        $routeConfig: [
            {
                path: '/',
                name: 'Campaigns View',
                component: 'campaignsView',
                useAsDefault: true
            },
            {
                path: '/activity',
                name: 'Activity',
                component: 'activity'
            },
            {
                path: '/statistics',
                name: 'Statistics',
                component: 'statistics',
                data: { limitToOrg: true }
            },
            {
                path: '/transactions/...',
                name: 'Transactions',
                component: 'transactions',
                data: { limitToCampaign: true }
            },
            {
                path: '/people/...',
                name: 'People',
                component: 'people'
            },
            {
                path: '/email/...',
                name: 'Email',
                component: 'email'
            },
            {
                path: '/website/...',
                name: 'Website',
                component: 'website'
            },
            {
                path: '/forms/...',
                name: 'Forms',
                component: 'formsTab'
            },
            {
                path: '/fundraising/...',
                name: 'Fundraising',
                component: 'fundraising',
                data: { hideOnStandaloneAndTicketing: true }
            },
            {
                path: '/reports',
                name: 'Reports',
                component: 'reports',
                data: {
                    limitToCampaign: true,
                    resource: 'REPORTS'
                }
            },
            {
                path: '/system/...',
                name: 'System',
                component: 'settings'
            },
            {
                path: '/**',
                name: 'Page Not Found',
                component: 'pageNotFound'
            }
        ]
    }).directive('onFinishRender', function ($rootScope) {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                if (scope.$last === true) {
                    element.ready(function () {
                        $rootScope.$emit('isOverflow');
                    });
                }
            }
        };
    });
    function CampaignsController($rootScope, $location, $document, entityService, orgService, campaignService, administratorService, securityService, routingService) {
        const vm = this;
        vm.showGroupInfoModal = false;
        vm.selectedInfoEntityId = null;
        vm.org = orgService.getOrg();
        vm.overlay = false;
        vm.newCampaignType = '';
        vm.collapsePath = isOverflowed(document.querySelector('.nav-breadcrumb'));
        vm.downActive = false;
        vm.openEntityInfoModal = openEntityInfoModal;
        vm.setStyle = setStyle;
        vm.toggleActive = toggleActive;
        vm.setOverlay = setOverlay;
        vm.overlayVisible = false;
        vm.security = {};
        vm.parentSecurity = {};
        vm.adminSecurity = {};
        vm.openEntityId = null;
        vm.openedCampaignId = null;
        vm.hasFundraising = true;
        vm.groupOrCampaignFound = true;
        vm.isOrgOpen = isOrgOpen();
        vm.onCampaignPage = false;
        vm.orgHasCreateLicense = vm.org.hasCreateLicense;
        vm.isSupervisor = orgService.getOrg().supervisor;
        vm.isChampion = !!window.isChampion;
        vm.openSupervisor = openSupervisor;
        vm.openCampaignSite = openCampaignSite;
        vm.openSiteBuilder = openSiteBuilder;
        vm.entityOverrideEventId = null;
        vm.showGivingTuesdayBtn = false;
        vm.updateShowAddBtn = updateShowAddBtn;
        vm.addButtonType = 'campaign';
        vm.onSettingsTab = false;
        vm.campaignId = orgService.getUrlCampaignId();
        vm.getCurrentEntityId = function () {
            return entityService.getOpenedEntityId();
        };
        refreshShowTabs();
        $rootScope.$on('isOverflow', testOverflow);
        $rootScope.$on('group.opened', refreshGroupPath);
        $rootScope.$on('groupOrCampaignNotFound', () => {
            vm.groupOrCampaignFound = false;
        });
        $rootScope.$on('onCampaignPage', () => {
            vm.onCampaignPage = true;
            refreshShowTabs();
        });
        $rootScope.$on('orgs.loaded', () => {
            vm.org = orgService.getOrg();
        });
        $rootScope.$on('orgs.permissionsLoaded', data => {
            loadAdminPermissions();
        });
        $rootScope.$on('campaign.opened', (event, data) => {
            vm.openedCampaign = data.name;
            vm.openedCampaignId = data.campId;
            var entity = entityService.getRawEntityById(vm.getCurrentEntityId());
            vm.primaryUrl = entity.urls && entity.urls.primary;
            vm.campaignEnded = entity.archived;
            vm.hasFundraising = !routingService.isStandaloneOrTicketing(entity);
            if (vm.campaignInstanceId && entity.oldInstancesWithYrAndEvntId) {
                var currentInstance = entity.oldInstancesWithYrAndEvntId.find(x => x.cmpInstanceId == vm.campaignInstanceId);
                if (currentInstance) {
                    vm.campaignInstanceDisplay = currentInstance.year || vm.openedCampaign;
                    vm.campaignInstanceEnded = currentInstance.eventArchived;
                    vm.primaryUrl = currentInstance.urls && currentInstance.urls.primary;
                }
            }
            vm.siteBuilderUrl = siteBuilderUrl;
        });
        $rootScope.$on('campaign.name.changed', (event, data) => {
            vm.openedCampaign = data;
        });
        $rootScope.$on('admin.invite', (event, data) => {
            vm.showAdminModal = true;
            let entityIds = [];
            data.forEach(item => entityIds.push(item.entityId));
            vm.entityIds = entityIds;
        });
        $rootScope.$on('replicate', (event, data) => {
            vm.showAddNewCmpModal = true;
            vm.isReplicate = true;
            vm.newCampaignType = data.type;
        });
        $rootScope.$on('group.should.rename', (event, data) => {
            vm.clickedGroupId = data.groupId;
            vm.clickedGroupEntityId = data.entityId;
            vm.isRenameGroup = true;
            vm.showAddNewGroupModal = true;
        });
        $rootScope.$on('group.should.remove', (event, data) => {
            vm.showRemoveModal = true;
            vm.removeGroupId = data.groupId;
        });
        $rootScope.$on('unarchive', (event, data) => {
            vm.showUnarchiveCmpModal = true;
            vm.eventGroupHost = data.eventGroupHost;
        });
        loadAdminPermissions();
        /*if($location.search().groupId === undefined){
    loadData(); 
  } 

  function loadData() {

    let allAdmins =  administratorService.getAdminsFromOrg();

    if(allAdmins[0] != undefined){
      return allAdmins;
    }
    else{
       administratorService.getAllOrgAdmins()
      .then((admins) => {
        return admins;
      })
    } 
  };
  */
        function loadAdminPermissions() {
            var entity = entityService.getRawEntityById(vm.getCurrentEntityId());
            var parentEntityId = campaignService.getOpenedGroup() && campaignService.getOpenedGroup().entityId || entityService.getOrgEntityId();
            var parentEntity = entityService.getRawEntityById(parentEntityId);
            vm.security = securityService.getAdminPermissions(entity, 'CAMPAIGN');
            vm.parentSecurity = securityService.getAdminPermissions(parentEntity, 'CAMPAIGN');
            vm.adminSecurity = securityService.getAdminPermissions(entity, 'ADMIN_USERS');
        }
        function setOverlay($event) {
            var toggle = !vm.overlayVisible;
            vm.overlayVisible = toggle;
            vm.overlay = toggle;
            if (vm.overlayVisible) {
                $rootScope.$emit('addNewSelected', $event, setOverlay);
                if (vm.suppressOverlayForAddBtn) {
                    vm.overlayVisible = false;
                    vm.overlay = false;
                }
            }
        }
        function updateShowAddBtn(on, btnType, suppressOverlay, tooltip, icon) {
            vm.showAddBtn = on;
            vm.addButtonType = btnType;
            vm.suppressOverlayForAddBtn = suppressOverlay;
            vm.addButtonTooltip = tooltip;
            vm.addButtonCustomIcon = icon;
        }
        /////////////////////////////////////////////////////////////////////////////////////
        function isOrgOpen() {
            return !orgService.getUrlCampaignPath() && !orgService.getUrlCampaignId() && !orgService.getUrlGroupId();
        }
        function refreshGroupPath() {
            vm.entityOverrideEventId = entityService.getRawEntityById(entityService.getOpenedEntityId()).entityOverrideEventId;
            vm.groupsPath = campaignService.getGroupsPath();
            let last = vm.groupsPath.length - 1;
            vm.lastGroup = vm.groupsPath[last];
            if (!vm.campaignId && vm.lastGroup) {
                const regex = /group\/[^/]+\/$/;
                // URL does not have more than ".../group/{groupid}/"
                if (regex.test(window.location.href)) {
                    vm.showAddBtn = true;
                }
            }
            vm.beforeLastGroup = vm.groupsPath[last - 1];
            let groupReverse = vm.groupsPath.reverse();
            vm.groupsShiftOne = groupReverse.slice(1);
            loadAdminPermissions();
        }
        function openEntityInfoModal() {
            if (vm.campaignId) {
                return;
            }
            let groupId = orgService.getUrlGroupId();
            if (groupId) {
                vm.showAddNewGroupModal = true;
                vm.isRenameGroup = true;
                vm.clickedGroupId = groupId;
                vm.clickedGroupEntityId = campaignService.getGroupById(groupId);
            } else {
                vm.showGroupInfoModal = true;
                vm.selectedInfoEntityId = orgService.getOrg().entityId;
            }
        }
        function setStyle() {
            // 100 is bottom block, 60 is offset at top
            return { 'height': $('.overlay-height').height() + 160 + 'px' };
        }
        function isOverflowed(element) {
            return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        }
        function testOverflow() {
            vm.collapsePath = isOverflowed(document.querySelector('.nav-breadcrumb'));
        }
        function toggleActive(toBool) {
            if (typeof toBool !== 'undefined') {
                vm.downActive = toBool;
            } else {
                vm.downActive = !vm.downActive;
            }
        }
        function openSupervisor() {
            const entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
            const orgId = orgService.getOrgId();
            var entityId = entity.entityId;
            let openedCampaignInstanceId = orgService.getUrlCampaignInstanceId();
            if (openedCampaignInstanceId) {
                let campaignEntity = entityService.getRawEntityById(entity.entityId);
                let campaignInstanceEntity = entityService.getEntityFromInstanceId(campaignEntity, openedCampaignInstanceId);
                if (campaignInstanceEntity) {
                    entityId = campaignInstanceEntity.entityId;
                }
            }
            openInNewTab(`/supervisor/${ orgId }/entity/${ entityId }`);
        }
        function openCampaignSite() {
            return openInNewTab(`https://${ vm.primaryUrl }`);
        }
        function openSiteBuilder() {
            return openInNewTab(vm.siteBuilderUrl + vm.primaryUrl);
        }
        function openInNewTab(url) {
            var win = window.open(url, '_blank');
            win.focus();
        }
        function refreshShowTabs() {
            vm.campaignInstanceId = orgService.getUrlCampaignInstanceId();
            let entity = entityService.getRawEntityById(vm.getCurrentEntityId());
            vm.showTabs = {
                activity: !vm.campaignInstanceId && !entity.activeEventIsArchived && routingService.anyResourcePermission(['CAMPAIGN'], entity),
                statistics: vm.isOrgOpen && routingService.anyResourcePermission(['CAMPAIGN'], entity),
                transactions: vm.onCampaignPage && !entity.activeEventIsArchived && !vm.campaignInstanceId && routingService.anyResourcePermission(securityService.navPermissions.transactions, entity),
                people: (vm.onCampaignPage || routingService.anyResourcePermission(['PEOPLE_NATIONAL_TEAMS'], entity) && entity.settings.NationalTeamsEnabled) && !entity.activeEventIsArchived && !vm.campaignInstanceId && routingService.anyResourcePermission(securityService.navPermissions.people, entity),
                email: routingService.anyResourcePermission(securityService.navPermissions.email, entity),
                forms: !vm.campaignInstanceId && !entity.activeEventIsArchived && routingService.anyResourcePermission(securityService.navPermissions.forms, entity),
                website: !vm.campaignInstanceId && !entity.activeEventIsArchived && routingService.anyResourcePermission(securityService.navPermissions.website, entity),
                fundraising: vm.hasFundraising && !entity.activeEventIsArchived && !vm.campaignInstanceId && routingService.anyResourcePermission(securityService.navPermissions.fundraising, entity),
                reports: vm.onCampaignPage && routingService.anyResourcePermission(securityService.navPermissions.reports, entity),
                system: !vm.campaignInstanceId && !entity.activeEventIsArchived && routingService.anyResourcePermission(securityService.navPermissions.system, entity)
            };
            vm.canEditContent = securityService.getAdminPermissions(entity, 'SETTINGS_EDIT_CONTENT_ON_SITE').EDIT;
        }
    }
}());
(function () {
    angular.module('app').component('bulkReplicate', {
        bindings: {
            show: '=',
            entityId: '=',
            eventId: '='
        },
        controller: bulkReplicateCtrl,
        controllerAs: 'vm'
    });
    function bulkReplicateCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'bulk-replicate.tpl.html',
                clickOutsideToClose: true,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    entityId: vm.entityId,
                    eventId: vm.eventId
                }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($mdDialog, $rootScope, $window, campaignService, messageService, entityService, entityId, eventId) {
        const vm = this;
        vm.uploadView = true;
        vm.approveView = false;
        vm.errorsView = false;
        vm.rowErrors = false;
        vm.headerErrors = false;
        vm.finalErrors = false;
        vm.bulkReplicateCsvHref = campaignService.getBulkReplicateCsvHref(entityId);
        vm.cloneCmpSettings = {
            cmpSettings: {
                value: 1,
                checked: false
            },
            paymentProcessor: {
                value: 2,
                checked: false
            },
            templates: {
                value: 4,
                checked: false
            },
            defaults: {
                value: 8,
                checked: false
            },
            siteContent: {
                value: 16,
                checked: false
            },
            adminUsers: {
                value: 32,
                checked: false
            }
        };
        vm.closeModal = closeModal;
        vm.buttonHandler = buttonHandler;
        vm.fileUploadInput = null;
        let guid = createGuid();
        function buttonHandler() {
            if (vm.errorsView) {
                vm.rowErrors = false;
                vm.headerErrors = false;
                vm.finalErrors = false;
                vm.uploadView = true;
                vm.errorsView = false;
            } else if (vm.approveView) {
                _approveBulkReplication();
            } else if (vm.uploadView) {
                _validateBulkFile();
            }
        }
        function _approveBulkReplication() {
            vm.approveView = false;
            let messageConfig = {
                successToastEnabled: true,
                successMsg: 'Bulk replication completed successfully!',
                advancedErrorEnabled: false
            };
            campaignService.bulkReplicateCampaigns(entityId, guid, eventId, messageConfig).then(response => {
                closeModal();
                //TODO: Fix this to go to the parent group upon completion
                //$rootScope.$emit('tree.clear');
                campaignService.pushMultipleToRawCampaigns(response.cmps);
                let parentGroupId = entityService.getOpenedGroupId();
                campaignService.openGroup(parentGroupId).then(campaignsData => {
                });
            }).catch(response => {
                if (response.data) {
                    campaignService.pushMultipleToRawCampaigns(response.data.cmps);
                    let errors = response.data.errors;
                    if (errors) {
                        vm.errors = errors;
                        vm.errorsView = true;
                        vm.finalErrors = true;
                        vm.response = response.data;
                        return;
                    }
                }
                vm.approveView = true;
            });
        }
        function _validateBulkFile() {
            let fileInput = angular.element(document.querySelector('#bulkFile'));
            if (fileInput[0].files.length) {
                var file = fileInput[0].files[0];
            } else {
                messageService.showErrorToast('Please choose file');
                return;
            }
            let copyCmpSettings = Object.values(vm.cloneCmpSettings).filter(cmpSetting => cmpSetting.checked).reduce((cmpSettingsTotal, cmpSetting) => cmpSettingsTotal | cmpSetting.value, 0);
            vm.uploadView = false;
            let messageConfig = { advancedErrorEnabled: false };
            campaignService.bulkReplicateCampaignsValidateCsv(entityId, guid, eventId, file, copyCmpSettings, !!vm.autoPublish, messageConfig).then(response => {
                vm.campaignsCsv = response.campaignRequestDatas;
                vm.approveView = true;
            }).catch(response => {
                if (response.data) {
                    let earlyErrors = response.data.earlyErrors;
                    if (earlyErrors) {
                        vm.errors = earlyErrors;
                        vm.errorsView = true;
                        vm.headerErrors = true;
                        return;
                    }
                    let errors = response.data.errors;
                    if (errors) {
                        vm.errors = errors;
                        vm.errorsView = true;
                        vm.rowErrors = true;
                        return;
                    }
                }
                vm.uploadView = true;
            });
        }
        function closeModal() {
            $mdDialog.hide();
        }
        function createGuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
                let r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 3 | 8;
                return v.toString(16);
            });
        }
        vm.currentSlide = 1;
        vm.slickConfig = {
            enabled: true,
            infinite: true,
            draggable: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            event: {
                afterChange: (event, slick, currentSlide, nextSlide) => {
                    vm.currentSlide = currentSlide + 1;
                }
            },
            method: {}
        };
    }
}());
(function () {
    angular.module('app').component('campaignPage', {
        templateUrl: 'campaign-page.tpl.html',
        controller: CampaignPageController,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction).then(r => r ? routingService.validateNoPendingChanges($nextInstruction) : false);
        },
        bindings: { $router: '<' },
        $routeConfig: [{
                path: '/**',
                name: 'Page Not Found',
                component: 'pageNotFound'
            }]
    }).directive('syncFocusWith', function ($timeout, $rootScope, $compile) {
        return {
            restrict: 'A',
            link: function ($scope, $element, attrs) {
                let $input = $element.is('input, md-select, md-checkbox') ? $element : $element.find('input, md-select, md-checkbox').first();
                $scope.$watch(attrs.syncFocusWith, function (currentValue, previousValue) {
                    if (currentValue === true && previousValue !== true) {
                        $timeout(() => {
                            $input.focus();
                            $input.select();
                        });
                    } else if (currentValue === false && previousValue) {
                        $input.blur();
                    }
                });
            }
        };
    });
    function CampaignPageController($location, $rootScope, $mdDialog, $filter, $scope, $sce, $q, $timeout, mdcDateTimeDialog, settingsService, entityService, campaignService, securityService, orgService, messageService, ngModelFiltersService, fileUpload) {
        const formFieldTypes = {
            Text: 1,
            Select: 2,
            Date: 3,
            Textarea: 4,
            Checkbox: 5,
            Multiselect: 6
        };
        const vm = this;
        vm.delistCampaign = delistCampaign;
        vm.replicateCampaign = replicateCampaign;
        vm.archiveOrRelaunchClick = archiveOrRelaunchClick;
        vm.unarchiveClick = unarchiveClick;
        vm.loaders = {};
        vm.showDelistConfirm = showDelistConfirm;
        vm.addOverlay = addOverlay;
        vm.removeOverlay = removeOverlay;
        vm.security = {};
        vm.enableEditField = enableEditField;
        vm.settingsForm = {};
        vm.campaignNotFound = false;
        vm.formFieldTypes = formFieldTypes;
        vm.filters = ngModelFiltersService;
        vm.isSupervisor = orgService.getOrg().supervisor;
        vm.orgId = orgService.getOrgId();
        vm.displayDateDialog = displayDateDialog;
        vm.toggleYearsDropdown = toggleYearsDropdown;
        vm.toggleSupervisorYearsDropdown = toggleSupervisorYearsDropdown;
        vm.displayTimeDialog = displayTimeDialog;
        vm.timeZoneSearch = timeZoneSearch;
        vm.showEmbedCode = showEmbedCode;
        vm.copyEmbedCode = copyEmbedCode;
        vm.getEditableUrl = getEditableUrl;
        vm.campaignInstanceId = orgService.getUrlCampaignInstanceId();
        vm.siteBuilderUrl = siteBuilderUrl;
        vm.customCampaignFieldSecurity = {};
        vm.isChampion = !!window.isChampion;
        vm.cdn = 'cdn3.rallybound.com';
        settingsService.getCDN().then(cdn => vm.cdn = cdn);
        function displayDateDialog(dateField) {
            mdcDateTimeDialog.show({
                time: false,
                date: true,
                currentDate: vm.info[dateField].dateTime,
                disableParentScroll: true
            }).then(function (date) {
                vm.info[dateField].dateTime = new moment.utc(date);
            }, function () {
            });
        }
        ;
        function displayTimeDialog(dateField) {
            mdcDateTimeDialog.show({
                time: true,
                date: false,
                shortTime: true,
                currentDate: vm.info[dateField].dateTime,
                disableParentScroll: true
            }).then(function (date) {
                vm.info[dateField].dateTime = new moment.utc(date);
            }, function () {
            });
        }
        ;
        function timeZoneSearch(query) {
            const lowerCaseQuery = query.toLowerCase();
            return settingsService.getIanaTimeZones().then(zones => zones.filter(zone => zone.display.toLowerCase().includes(lowerCaseQuery)));
        }
        vm.campaignId = orgService.getUrlCampaignId();
        if (vm.campaignId) {
            vm.campaign = campaignService.getCampaignById(vm.campaignId);
        } else {
            let campaignHostFromUrl = orgService.getUrlHost();
            let campaignPathFromUrl = orgService.getUrlCampaignPath();
            vm.campaign = campaignService.getCampaignByUrl(campaignHostFromUrl + '/' + campaignPathFromUrl);
            vm.campaignId = vm.campaign.campaignId;
        }
        vm.orgUrl = orgService.getOrg().orgUrl;
        let previousDate = null;
        vm.showSupervisorYears = false;
        function toggleSupervisorYearsDropdown() {
            vm.showSupervisorYears = !vm.showSupervisorYears;
        }
        vm.showYears = false;
        function toggleYearsDropdown() {
            vm.showYears = !vm.showYears;
        }
        vm.security = securityService.getAdminPermissions(vm.campaign, 'CAMPAIGN');
        vm.canEdit = vm.security.EDIT && !vm.campaignInstanceId;
        vm.canEditContent = !!securityService.getAdminPermissions(vm.campaign, 'SETTINGS_EDIT_CONTENT_ON_SITE').EDIT;
        vm.customCampaignFieldSecurity = securityService.getAdminPermissions(vm.campaign, 'CUSTOM_CAMPAIGN_FIELDS');
        getCampaignInfo();
        $rootScope.$emit('campaign.opened', {
            name: vm.campaign.name,
            campId: vm.campaignId
        });
        function showEmbedCode() {
            vm.displayEmbedCode = vm.displayEmbedCode == true ? false : true;
        }
        ;
        function copyEmbedCode() {
            var codeToCopy = angular.element('#embedCode');
            codeToCopy.select();
            document.execCommand('copy');
        }
        function getCampaignInfo() {
            vm.campaignInfoFetching = true;
            //Move this to the beginning of the fetching process?
            entityService.getInfo(getCurEntityId()).then(updateInfo).finally(error => {
                vm.campaignInfoFetching = false;
            });
        }
        function getCurEntityId() {
            if (vm.campaignInstanceId) {
                return vm.campaign.oldInstancesWithYrAndEvntId.find(c => c.cmpInstanceId == vm.campaignInstanceId).entityId;
            }
            return vm.campaign.entityId;
        }
        function updateInfo(info) {
            vm.info = info;
            vm.info.eventDate = {
                dateTime: new moment.utc(vm.info.eventDate),
                timeZone: getTimezone()
            };
            vm.info.cmpStartDate = {
                dateTime: vm.info.cmpStartDate ? new moment.utc(vm.info.cmpStartDate) : undefined,
                timeZone: getTimezone()
            };
            vm.info.cmpEndDate = {
                dateTime: vm.info.cmpEndDate ? new moment.utc(vm.info.cmpEndDate) : undefined,
                timeZone: getTimezone()
            };
            vm.onInactiveCampaignInstance = vm.campaignInstanceId && vm.info.archived;
            if (vm.info.isReplicable) {
                vm.info.editableAtEnd = vm.info.urls.subdomainIsPath == undefined ? vm.info.groupUrl.charAt(0) != '.' : vm.info.urls.subdomainIsPath;
                vm.info.groupUrl = vm.info.editableAtEnd ? vm.info.groupUrl + '/' : vm.info.groupUrl;
            }
            vm.info.settingsForm && vm.info.settingsForm.fields && vm.info.settingsForm.fields.forEach(field => {
                field.isCustom = true;
                fields.push(field);
                if (field.type == formFieldTypes.Date && field.value) {
                    //fieldType date
                    field.value = new Date(field.value);
                }
                field.previousValue = angular.copy(field.value);
            });
            vm.settingsForm = vm.info.settingsForm;
            vm.previousInfo = angular.copy(vm.info);
            vm.eventSeriesEnabled = info.settings.EventSeriesEnabled;
            if (vm.eventSeriesEnabled) {
                $scope.$watch(function () {
                    return vm.editFlag.eventSeriesID;
                }, function (newValue, oldValue) {
                    if (newValue === true) {
                        if (vm.info.eventSeries) {
                            vm.info.eventSeriesID = vm.info.eventSeries.EventSeriesID;
                        } else {
                            vm.info.eventSeriesID = null;
                        }
                        orgService.getAvailableEventSeries().then(response => {
                            vm.availableEventSeries = response;
                        });
                    }
                });
            }
        }
        function updateEventSeriesDescription() {
            if (vm.availableEventSeries !== 'undefined') {
                vm.info.eventSeries = vm.availableEventSeries[vm.info.eventSeriesID];
            }
        }
        function getTimezone() {
            return {
                display: vm.info.ianaTimeZone.replace('/', ' - ').replace('_', ' '),
                value: vm.info.ianaTimeZone
            };
        }
        function delistCampaign() {
            vm.delistInProgress = true;
            campaignService.delistCampaign(vm.campaign.entityId, !vm.campaign.delisted).then(response => {
                vm.campaign.delisted = !vm.campaign.delisted;
            }).finally(() => vm.delistInProgress = false);
        }
        function archiveCampaign(recurringPlanDestination) {
            vm.loaders.archive = true;
            campaignService.archiveCampaign(vm.campaign.entityId, Boolean(vm.liveDonationRecurringCount), recurringPlanDestination).then(response => {
                vm.campaign.archived = !vm.campaign.archived;
                vm.campaign.delisted = true;
                entityService.refreshEntityAndComponent(vm.campaign.entityId);
            }).finally(() => {
                vm.liveDonationRecurringCount = null;
                vm.loaders.archive = false;
            });
        }
        function relaunchCampaign(recurringPlanDestination) {
            vm.loaders.relaunch = true;
            function advancedErrors(currentMessageService, response) {
                currentMessageService.showErrorToast(response.data.errors[0].error);
            }
            let messageConfig = {
                successToastEnabled: true,
                successMsg: 'Relaunch successful!',
                advancedErrorFunction: advancedErrors
            };
            campaignService.relaunchCampaign([vm.campaign.entityId], Boolean(vm.liveDonationRecurringCount), recurringPlanDestination, messageConfig).then(response => {
                let data = response.data[0];
                //update admin url in reason of new eventId
                if (data.archived || !data.eventHostReal) {
                    data.adminUrl = data.curEventId;
                } else {
                    data.adminUrl = data.eventHostReal.replace('/', '~');
                }
                vm.campaign = data;
                updateInfo(vm.campaign);
            }).finally(() => {
                vm.liveDonationRecurringCount = null;
                vm.loaders.relaunch = false;
            });
        }
        function replicateCampaign() {
            $rootScope.$emit('replicate', vm.campaign);
        }
        function unarchiveClick() {
            if (vm.loaders.unarchive) {
                return;
            }
            vm.loaders.unarchive = true;
            let eventGroupHost = vm.campaign.groupUrl;
            $rootScope.$emit('unarchive', { eventGroupHost });
            vm.loaders.unarchive = false;
        }
        function archiveOrRelaunchInvalid(currentMessageService, response, endClickEvent, endType) {
            response = response.data;
            if (response.liveDonationRecurringCount && (response.destinationOptions || endType === 'relaunch')) {
                showMoveRecurrigModal(response, endClickEvent, endType);
            } else {
                showUnableToEndModal();
            }
        }
        function archiveOrRelaunchClick(endClickEvent, endType) {
            if (vm.loaders.relaunch || vm.loaders.archive) {
                return;
            }
            vm.loaders[endType] = true;
            let messageConfig = {
                advancedErrorFunction: (currentMessageService, response) => archiveOrRelaunchInvalid(currentMessageService, response, endClickEvent, endType),
                advancedErrorEnabled: true
            };
            campaignService.validateArchiveOrRelaunchCmp(vm.campaign.entityId, messageConfig).then(response => {
                vm.hasActiveFacebookFundraiser = response.hasActiveFacebookFundraiser;
                endType === 'relaunch' ? showRelaunchConfirm(endClickEvent) : showArchiveConfirm(endClickEvent);
            })    //validation errors handled in message service advancedErrorFunction
.finally(() => {
                vm.loaders[endType] = false;
            });
        }
        function showMoveRecurrigModal(response, endClickEvent, endType) {
            vm.liveDonationRecurringCount = response.liveDonationRecurringCount;
            vm.destinationOptions = response.destinationOptions;
            vm.hasActiveFacebookFundraiser = response.hasActiveFacebookFundraiser;
            vm.moveTrigger = endType;
            vm.targetEvent = endClickEvent;
            vm.confirmationCallback = endType === 'relaunch' ? showRelaunchConfirm : showArchiveConfirm;
            vm.showModalMoveRecurring = true;
        }
        function showUnableToEndModal() {
            messageService.showErrorDialog(`Campaigns cannot be ended while they have active recurring donation plans or donation matches. <br>Please end your active recurring plan(s) and/or match(es), after which you will be able to end the selected campaign(s).`);
        }
        function showRelaunchConfirm(endClickEvent, recurringPlanDestination) {
            let unendedPlansMsg = ``;
            if (vm.liveDonationRecurringCount) {
                let recurringDestinationStr = recurringPlanDestination ? recurringPlanDestination.eventName : `the newly relaunched campaign`;
                unendedPlansMsg = `<br><br>${ vm.liveDonationRecurringCount } active recurring plan(s) will be transferred to ${ recurringDestinationStr }.`;
            }
            let unendedFacebookPlansMsg = ``;
            if (vm.hasActiveFacebookFundraiser) {
                unendedFacebookPlansMsg = ` Additionally, all active Facebook Fundraiser pages will be ended.`;
            }
            let confirm = $mdDialog.confirm().clickOutsideToClose(true).title('Are you sure?').htmlContent(`This will end your current campaign and relaunch a new iteration of it on the same URL with all the same information.<br><br>Reports on the ended campaign will still be available.${ unendedFacebookPlansMsg }${ unendedPlansMsg }<br><br>This action cannot be undone.`).openFrom(endClickEvent.currentTarget).closeTo(endClickEvent.currentTarget).ok('Relaunch').cancel('Cancel');
            $mdDialog.show(confirm).then(() => relaunchCampaign(recurringPlanDestination));
        }
        ;
        function showArchiveConfirm(endClickEvent, recurringPlanDestination) {
            let unendedFacebookPlansMsg = vm.hasActiveFacebookFundraiser ? ` Additionally, all active Facebook Fundraiser pages will be ended.` : ``;
            let unendedPlansMsg = vm.liveDonationRecurringCount ? `<br><br>${ vm.liveDonationRecurringCount } active recurring plan(s) will be transferred to ${ recurringPlanDestination.eventName }.` : ``;
            // Ending the Facebook Fundraiser pages and transferring recurring plans are not reversible even by reactivating the campaign.
            let nonReversibleMsg = vm.hasActiveFacebookFundraiser || vm.liveDonationRecurringCount ? `<br><br>This action cannot be undone.` : ``;
            let confirm = $mdDialog.confirm().clickOutsideToClose(true).title('Are you sure?').htmlContent(`This will end your campaign, deactivating the website and admin panel. Admin reports will still be accessible.${ unendedPlansMsg }${ unendedFacebookPlansMsg }${ nonReversibleMsg }`).openFrom(endClickEvent.currentTarget).closeTo(endClickEvent.currentTarget).ok('End Campaign').cancel('Cancel');
            $mdDialog.show(confirm).then(() => archiveCampaign(recurringPlanDestination));
        }
        function showDelistConfirm(event) {
            //if vm.campaign.delisted that means it is being published now
            if (vm.campaign.delisted) {
                delistCampaign();
                return;
            }
            let confirm = $mdDialog.confirm().clickOutsideToClose(true).title('Are you sure?').htmlContent(`This will delist your campaign, removing it from appearing on the platform.`).targetEvent(event).ok('Delist').cancel('Cancel');
            $mdDialog.show(confirm).then(() => delistCampaign());
        }
        /* Editing */
        vm.editFlag = {};
        vm.saveInProgress = {};
        vm.addUrl = addUrl;
        vm.removeHost = removeHost;
        vm.areUrlsEditable = areUrlsEditable;
        vm.updateDefaultUrl = updateDefaultUrl;
        vm.addWhitelistedUrl = addWhitelistedUrl;
        vm.removeWhitelistedUrl = removeWhitelistedUrl;
        vm.fieldClicked = fieldClicked;
        vm.saveEdit = saveEdit;
        vm.cancelEdit = cancelEdit;
        vm.cancelEditCustomField = cancelEditCustomField;
        vm.offEdits = offEdits;
        vm.checkHotKey = checkHotKey;
        vm.saveCustomFieldEdit = saveCustomFieldEdit;
        vm.showCampaignDates = false;
        let fields = [
            { id: 'name' },
            { id: 'slang' },
            { id: 'description' },
            { id: 'goalAmount' },
            { id: 'eventDate' },
            { id: 'cmpStartDate' },
            { id: 'cmpEndDate' },
            { id: 'eventYear' },
            { id: 'location' }
        ];
        function refocusField(fieldId) {
            vm.editFlag[fieldId] = 'refocus';
            $timeout(() => {
                vm.editFlag[fieldId] = true;
            });
        }
        function getEditableUrl(fullUrl) {
            return (fullUrl || '').replace(vm.info.groupUrl, '');
        }
        function areUrlsEditable() {
            return vm.info && vm.info.isReplicable && vm.campaign && !vm.campaign.archived;
        }
        function addUrl(url) {
            if (vm.saveInProgress.url) {
                return $q.reject('existing url save currently in progress');
            }
            vm.saveInProgress.url = true;
            const subdomain = getEditableUrl(url);
            let messageConfig = {
                advancedErrorFunction: messageService.advancedErrorsDialog,
                advancedErrorEnabled: true
            };
            return validateUrl(subdomain).catch(error => {
                messageService.showErrorToast(error);
                return $q.reject();
            }).then(subdomain => campaignService.addCampaignUrl(vm.info.entityId, subdomain, messageConfig)).then(response => response.data).then(urls => vm.info.urls = urls).finally(() => {
                vm.showSecondaryUrls = true;
                vm.saveInProgress.url = false;
            });
        }
        function updateDefaultUrl(url) {
            let confirm = $mdDialog.confirm().clickOutsideToClose(true).title('Are you sure?').htmlContent(`You are changing the primary URL of this campaign.`).ok('Change Primary URL').cancel('Cancel');
            if (vm.saveInProgress.url) {
                return $q.reject('existing url save currently in progress');
            }
            vm.saveInProgress.url = true;
            let messageConfig = {
                advancedErrorFunction: messageService.advancedErrorsDialog,
                advancedErrorEnabled: true
            };
            return $mdDialog.show(confirm).then(() => campaignService.updateCampaignUrl(vm.info.entityId, url, messageConfig)).then(response => response.data).then(urls => vm.info.urls = urls).finally(() => {
                vm.saveInProgress.url = false;
            });
        }
        function removeHost(host) {
            if (host.hadUnsafeRedirect) {
                const confirm = $mdDialog.confirm().clickOutsideToClose(true).title('Warning').htmlContent('Users who have already visited this site using this URL will continue to be directed to this site when entering this URL, even after it has been removed, until the user\u2019s browser cache is cleared. Click \u201CI understand\u201D below to continue with removing this URL.').ok('I understand').cancel('Cancel');
                return $mdDialog.show(confirm).then(() => removeUrl(host.url, 'This URL will no longer direct new users to this campaign site.'));
            } else {
                return removeUrl(host.url);
            }
        }
        function removeUrl(url, message) {
            if (vm.saveInProgress.url) {
                return $q.reject('existing url save currently in progress');
            }
            vm.saveInProgress.url = true;
            let confirm = $mdDialog.confirm().clickOutsideToClose(true).title('Are you sure?').htmlContent(message || 'This campaign will no longer be accessible at this URL.').ok('Remove').cancel('Cancel');
            let messageConfig = {
                advancedErrorFunction: messageService.advancedErrorsDialog,
                advancedErrorEnabled: true
            };
            return $mdDialog.show(confirm).then(() => campaignService.removeCampaignUrl(vm.info.entityId, url, messageConfig)).then(response => response.data).then(urls => vm.info.urls = urls).finally(() => {
                vm.saveInProgress.url = false;
            });
        }
        /**
   * Validates subdomain and returns full path
   * @param {string} path subdomain or path to validate
   */
        function validateUrl(path) {
            // validate for no . or /
            let regex = /.*(\.|\/|\s).*/;
            if (path.match(regex)) {
                return $q.reject('URL cannot contain / . or whitespace characters ');
            }
            const fullUrl = getFullUrl(path);
            if (!vm.info.editableAtEnd) {
                //taken from http://stackoverflow.com/questions/9208814/validate-ipv4-ipv6-and-hostname/9221063#9221063
                regex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/;
                if (!regex.test(String(fullUrl))) {
                    return $q.reject('Please only enter valid URL characters');
                }
            } else {
                regex = /^[a-zA-Z0-9-]*$/;
                if (!String(path).match(regex)) {
                    return $q.reject('Only letters, numbers, and hyphens are valid for the url path.');
                }
            }
            return $q.resolve(path);
        }
        function getFullUrl(editableUrl) {
            if (!vm.info.isReplicable) {
                return vm.info.urls.primary;
            }
            return vm.info.editableAtEnd ? vm.info.groupUrl + editableUrl : editableUrl + vm.info.groupUrl;
        }
        function fieldClicked(fieldId, $event) {
            if (vm.editFlag[fieldId])
                return;
            offEdits(fieldId).then(() => enableEditField(fieldId));    // $event.stopPropagation();
        }
        function offEdits(fieldId) {
            let field = fields.find(field => field.id != fieldId && vm.editFlag[field.id]);
            if (!field)
                return $q.resolve();
            if (field.isCustom) {
                return saveCustomFieldEdit(field);
            } else {
                return saveEdit(field.id);
            }
        }
        function enableEditField(fieldId) {
            if (!vm.canEdit) {
                return false;
            }
            vm.editFlag[fieldId] = true;
            return true;
        }
        function disableEditField(fieldId) {
            vm.editFlag[fieldId] = false;
        }
        function onFieldSaved(fieldId) {
            if (fieldId == 'eventSeriesID') {
                updateEventSeriesDescription();
            }
        }
        function saveEditField(fieldId) {
            let field = fields.find(field => field.id == fieldId);
            if (field.isCustom) {
                return saveCustomFieldEdit(field);
            } else {
                return saveEdit(field.id);
            }
        }
        function cancelEditField(fieldId) {
            let field = fields.find(field => field.id == fieldId);
            if (field.isCustom) {
                return cancelEditCustomField(field);
            } else {
                return cancelEdit(field.id);
            }
        }
        function saveEdit(fieldId) {
            if (vm.saveInProgress[fieldId]) {
                return $q.reject();
            }
            vm.saveInProgress[fieldId] = true;
            return saveProcess().finally(() => vm.saveInProgress[fieldId] = false);
            function saveProcess() {
                if (angular.equals(vm.info[fieldId], vm.previousInfo[fieldId])) {
                    disableEditField(fieldId);
                    return $q.resolve();
                }
                return beginSave(fieldId).then(values => {
                    return completeSave(fieldId, values).then(saveSuccess);
                }).catch(saveFailure);
            }
            function saveSuccess() {
                vm.previousInfo = angular.copy(vm.info);
                disableEditField(fieldId);
                onFieldSaved(fieldId);
                return $q.resolve();
            }
            function saveFailure() {
                refocusField(fieldId);
                return $q.reject();
            }
        }
        function beginSave(fieldId) {
            return validateSave(fieldId).catch(error => {
                messageService.showErrorToast(error);
                return $q.reject();
            });
        }
        function validateSave(fieldId) {
            if (fieldId == 'goalAmount') {
                //validate for non-letters values;
                let regex = /^[a-zA-Z]+$/;
                if (String(vm.info.goalAmount).match(regex)) {
                    return $q.reject('Please enter valid goal amount');
                }
                //validate for positive values;
                if (vm.info.goalAmount <= 0) {
                    return $q.reject('Please enter positive goal amount');
                }
            }
            if (fieldId == 'eventDate') {
                if (!vm.info[fieldId] || !vm.info[fieldId].dateTime || !vm.info.eventDate.timeZone) {
                    return $q.reject('Please enter valid date');
                }
                return $q.resolve([
                    vm.info[fieldId].dateTime,
                    vm.info.eventDate.timeZone.value
                ]);
            }
            if (fieldId == 'cmpStartDate' || fieldId == 'cmpEndDate') {
                return $q.resolve([
                    vm.info[fieldId].dateTime,
                    ''
                ]);
            }
            if (fieldId == 'name') {
                $rootScope.$emit('campaign.name.changed', vm.info[fieldId]);
            }
            if (fieldId == 'location') {
                var deferred = $q.defer();
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: vm.info.location }, function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        var newlocation = results[0].geometry.location;
                        vm.info.locValues = [vm.info.location];
                        vm.info.locValues[1] = newlocation.lat();
                        vm.info.locValues[2] = newlocation.lng();
                        var stateCountry = getGeocodeStateCountry(results[0]);
                        vm.info.locValues[3] = stateCountry.state;
                        vm.info.locValues[4] = stateCountry.country;
                    } else {
                        vm.info.locValues = [vm.info.location];
                    }
                    deferred.resolve(vm.info.locValues);
                });
                return deferred.promise;
            }
            let values = [vm.info[fieldId]];
            return $q.resolve(values);
        }
        function getGeocodeStateCountry(data) {
            let loc = {};
            data.address_components.forEach(function (addr) {
                if (addr.types.includes('administrative_area_level_1')) {
                    loc.state = addr.short_name;
                } else if (addr.types.includes('country')) {
                    loc.country = addr.short_name;
                }
            });
            return loc;
        }
        function completeSave(field, values) {
            let messageConfig = {
                advancedErrorFunction: messageService.advancedErrorsDialog,
                advancedErrorEnabled: true
            };
            return campaignService.saveEditCampaign(vm.info.entityId, field, values, messageConfig);
        }
        function saveCustomFieldEdit(field) {
            if (vm.saveInProgress[field.id]) {
                return $q.reject();
            }
            vm.saveInProgress[field.id] = true;
            if (angular.equals(field.value, field.previousValue)) {
                vm.editFlag[field.id] = false;
                vm.saveInProgress[field.id] = false;
                return $q.resolve();
            }
            let value = [];
            if (field.type == vm.formFieldTypes.Select) {
                field.options.forEach(option => {
                    if (option.label == field.value && value.length <= 0) {
                        value.push(option.id);
                    }
                });
            } else if (field.type == vm.formFieldTypes.Multiselect) {
                if (field.value) {
                    field.value.forEach(multiSelectValue => {
                        field.options.forEach(option => {
                            if (option.label == multiSelectValue) {
                                value.push(option.id);
                            }
                        });
                    });
                }
            } else if (field.type == vm.formFieldTypes.Date) {
                value.push(field.value ? field.value.toLocaleDateString() : field.value);
            } else {
                value.push(field.value);
            }
            return campaignService.saveEditCampaignCustomField(vm.info.entityId, field.id, value.join(',')).then(res => {
                field.previousValue = angular.copy(field.value);
                vm.editFlag[field.id] = false;
            }).catch(error => {
                refocusField(field.id);
                return $q.reject();
            }).finally(() => vm.saveInProgress[field.id] = false);
        }
        function cancelEdit(fieldId) {
            vm.info[fieldId] = angular.copy(vm.previousInfo[fieldId]);
            disableEditField(fieldId);
        }
        function cancelEditCustomField(field) {
            if (field.type == formFieldTypes.Multiselect) {
                field.value = [];
                if (field.previousValue) {
                    field.previousValue.forEach(value => {
                        field.value.push(value);
                    });
                }
            } else {
                field.value = field.previousValue;
            }
            vm.editFlag[field.id] = false;
        }
        const ESC = 27, ENTER = 13, TAB = 9;
        function checkHotKey(event, fieldId) {
            let key = event.keyCode;
            if (key === ESC)
                cancelEditField(fieldId);
            if (key === ENTER) {
                //TODO: Some custom fields don't work with enter well.
                event.preventDefault();
                event.stopPropagation();
                saveEditField(fieldId);
            }
            if (key === TAB) {
                event.preventDefault();
                let curIndex = fields.findIndex(item => item.id == fieldId);
                saveEditField(fieldId).then(() => {
                    let max = fields.length - 1;
                    let min = 0;
                    let fieldEnabled = false;
                    let startIndex = curIndex;
                    if (event.shiftKey) {
                        while (!fieldEnabled    /* && curIndex != startIndex*/) {
                            curIndex--;
                            if (curIndex < min)
                                curIndex = max;
                            fieldEnabled = enableEditField(fields[curIndex].id);
                        }
                    } else {
                        while (!fieldEnabled    /* && curIndex != startIndex*/) {
                            curIndex++;
                            if (curIndex > max)
                                curIndex = min;
                            fieldEnabled = enableEditField(fields[curIndex].id);
                        }
                    }
                });
            }
        }
        //image upload
        $scope.onUploadFile = onUploadFile;
        $scope.onDropFile = onDropFile;
        $scope.allowDrop = allowDrop;
        vm.fileUploadInput = null;
        function allowDrop(event) {
            event.preventDefault();
            event.stopPropagation();
        }
        function addOverlay() {
            document.querySelector('.fileInput-placeholder').style.zIndex = '1';
        }
        function removeOverlay() {
            document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
        }
        function onDropFile(event) {
            event.preventDefault();
            if (!vm.canEdit) {
                return;
            }
            document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
            let file = event.dataTransfer.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = event => {
                $rootScope.$emit('file.uploaded', {
                    img: event.target.result,
                    entityId: vm.info.entityId,
                    imgType: 'campaign'
                });
            };
        }
        function onUploadFile(event) {
            fileUpload.onUploadFile(event, vm.info.entityId, 'campaign');
        }
        $rootScope.$on('file.approved', (event, data) => {
            let {entityId, smallImage, largeImage} = data;
            campaignService.updateRawCampaigns({
                entityId,
                smallImage,
                largeImage
            });
        });
        function addWhitelistedUrl(url) {
            if (vm.saveInProgress.whitelistedUrl) {
                return $q.reject('existing whitelist url save currently in progress');
            }
            vm.saveInProgress.whitelistedUrl = true;
            let messageConfig = {
                advancedErrorFunction: messageService.showErrorDialogWithFirstError,
                advancedErrorEnabled: true
            };
            return campaignService.addCampaignWhitelistedUrl(vm.info.entityId, url, messageConfig).then(response => response.data).then(urls => vm.info.whitelistedUrls = urls).finally(() => {
                vm.saveInProgress.whitelistedUrl = false;
            });
        }
        function removeWhitelistedUrl(url) {
            if (vm.saveInProgress.whitelistedUrl) {
                return $q.reject('existing whitelist url save currently in progress');
            }
            vm.saveInProgress.whitelistedUrl = true;
            let confirm = $mdDialog.confirm().clickOutsideToClose(true).title('Are you sure?').htmlContent('This campaign will no longer be embeddable at this URL.').ok('Remove').cancel('Cancel');
            let messageConfig = {
                advancedErrorFunction: messageService.showErrorDialogWithFirstError,
                advancedErrorEnabled: true
            };
            return $mdDialog.show(confirm).then(() => campaignService.removeCampaignWhitelistedUrl(vm.info.entityId, url, messageConfig)).then(response => response.data).then(urls => vm.info.whitelistedUrls = urls).finally(() => {
                vm.saveInProgress.whitelistedUrl = false;
            });
        }
    }
}());
(function () {
    angular.module('app').component('modalAddUrl', {
        bindings: {
            show: '=',
            onAddUrl: '=',
            groupUrl: '=',
            editableAtEnd: '=',
            fullUrlEditable: '=',
            emailDomain: '=',
            modalTitle: '@'
        },
        controllerAs: 'vm',
        controller: ModalAddUrlController
    });
    function ModalAddUrlController($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                $mdDialog.show({
                    templateUrl: 'modal-add-url.tpl.html',
                    controller: ModalAddUrlContentController,
                    controllerAs: 'vm',
                    locals: {
                        onAddUrl: vm.onAddUrl,
                        groupUrl: vm.groupUrl,
                        editableAtEnd: vm.editableAtEnd,
                        fullUrlEditable: vm.fullUrlEditable,
                        emailDomain: vm.emailDomain,
                        modalTitle: vm.modalTitle
                    }
                }).finally(() => {
                    vm.show = false;
                });
            }
        });
    }
    function ModalAddUrlContentController($scope, $mdDialog, onAddUrl, groupUrl, editableAtEnd, fullUrlEditable, emailDomain, modalTitle) {
        const vm = this;
        vm.onAddUrl = onAddUrl;
        vm.groupUrl = groupUrl;
        vm.editableAtEnd = editableAtEnd;
        vm.fullUrlEditable = fullUrlEditable;
        vm.emailDomain = emailDomain;
        vm.modalTitle = modalTitle || 'Add URL';
        vm.maxLength = vm.fullUrlEditable ? 255 : 255 - vm.groupUrl.length;
        vm.pattern = vm.fullUrlEditable ? '^(?:[\\w-]+\\.)+[a-z]{2,10}$' : vm.editableAtEnd ? '^[a-zA-Z0-9-]*$' : '^[^.|/|\\s]*$';
        vm.placeholder = vm.fullUrlEditable ? 'Domain' : vm.editableAtEnd ? 'Path' : 'Subdomain';
        vm.url = '';
        vm.close = function () {
            $mdDialog.hide();
        };
        vm.save = function () {
            vm.saving = true;
            vm.onAddUrl(vm.url).finally(() => {
                vm.close();
                vm.saving = false;
            });
        };
    }
}());
(function () {
    angular.module('app').component('modalCrop', {
        controller: ModalCropCtrl,
        controllerAs: 'vm'
    });
    function ModalCropCtrl($scope, $mdDialog, $rootScope) {
        const vm = this;
        let uploadHandler = $rootScope.$on('file.uploaded', (event, data) => {
            vm.entityId = data.entityId;
            vm.img = data.img;
            vm.type = data.imgType;
            vm.objectId = data.objectId;
            showModal();
        });
        $scope.$on('$destroy', uploadHandler);
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-crop.tpl.html',
                controller: ModalCropInnerCtrl,
                controllerAs: 'vm',
                multiple: true,
                locals: {
                    entityId: vm.entityId,
                    img: vm.img,
                    type: vm.type,
                    objectId: vm.objectId
                },
                clickOutsideToClose: false
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalCropInnerCtrl($q, $mdDialog, $rootScope, campaignService, entityId, img, type, objectId) {
        const vm = this;
        vm.closeModal = closeModal;
        vm.applyCropped = applyCropped;
        vm.img = img;
        vm.croppedImage = '';
        vm.type = type;
        vm.objectId = objectId;
        function applyCropped() {
            if (vm.saveInProgress)
                return;
            vm.saveInProgress = true;
            if (vm.type == 'campaign') {
                campaignService.uploadCmpImage(entityId, vm.croppedImage).then(response => {
                    $rootScope.$emit('file.approved', response);
                    vm.saveInProgress = false;
                    closeModal();
                }).finally(() => {
                    vm.saveInProgress = false;
                });
            } else if (vm.type == 'national-team') {
                $rootScope.$emit('file.cropped', {
                    img: vm.croppedImage,
                    id: vm.objectId
                });
                closeModal();
            }
        }
        function closeModal() {
            $mdDialog.hide();
        }
    }
}());
(function () {
    angular.module('app').component('campaignsGroups', {
        bindings: {
            groups: '=',
            filter: '=',
            sort: '=',
            mode: '@',
            onGroupClick: '&',
            onGroupDbclick: '&',
            onInfoBtnClick: '&',
            onOutsideClick: '&'
        },
        templateUrl: 'campaigns-groups.tpl.html',
        controller: CampaignsGroupsController,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    function CampaignsGroupsController($filter, $rootScope, securityService, orgService) {
        const vm = this;
        vm.getGroups = getGroups;
        vm.renameGroup = renameGroup;
        vm.removeGroup = removeGroup;
        vm.getSecurity = getSecurity;
        vm.isSupervisor = orgService.getOrg().supervisor;
        vm.orgId = orgService.getOrgId();
        function getGroups() {
            return $filter('chankArray', vm.groups, 4);
        }
        function renameGroup(entityId, groupId) {
            $rootScope.$emit('group.should.rename', {
                entityId,
                groupId
            });
        }
        function removeGroup(groupId) {
            $rootScope.$emit('group.should.remove', { groupId });
        }
        function getSecurity(entity, resource) {
            return securityService.getAdminPermissions(entity, resource);
        }
        $rootScope.$emit('entity.changedInView');
    }
}());
(function () {
    angular.module('app').component('campaignsList', {
        bindings: {
            campaigns: '=',
            mode: '@',
            filter: '=',
            sort: '=',
            showHeader: '=',
            onCampaignClick: '&',
            onCampaignDbclick: '&',
            onOutsideClick: '&',
            turnOffFilters: '&'
        },
        templateUrl: 'campaigns-list.tpl.html',
        controller: CampaignsListController,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    function CampaignsListController(securityService, orgService, $scope, $window, $timeout) {
        const vm = this;
        vm.showToolTip = showToolTip;
        vm.getSecurity = getSecurity;
        vm.loadMore = loadMore;
        vm.filteredItems = [];
        vm.filterUpdatesInProgress = 0;
        vm.siteBuilderUrl = siteBuilderUrl;
        vm.humanizeTimeZone = humanizeTimeZone;
        vm.scrollCount = 20;
        vm.loader = true;
        $timeout(function () {
            loadMore();
        });
        vm.isSupervisor = orgService.getOrg().supervisor;
        vm.orgId = orgService.getOrgId();
        function showToolTip(campaign) {
            if (campaign.archived) {
                return '(ended)';
            } else if (campaign.delisted) {
                return '(delisted)';
            }
        }
        function humanizeTimeZone(timezone) {
            return timezone.replace('/', ' - ').replace('_', ' ');
        }
        function getSecurity(entity, resource) {
            return securityService.getAdminPermissions(entity, resource);
        }
        $scope.$on('beforeFilterOrSortUpdated', function () {
            $window.scrollTo(0, 0);
            //scroll to top because infinite-scroll was triggering a bunch of times and made it look glitchy
            vm.filterUpdatesInProgress++;
            vm.loader = true;
            vm.filteredItems.length = 0;
            vm.scrollCount = 20;
            $timeout(function () {
                loadMore();
                vm.filterUpdatesInProgress--;
            }, 500);
        });
        function loadMore() {
            //if last loadMore didn't add any new campaigns to vm.filteredItems that means we're at the end of the scroll
            var addedLessThan20 = vm.filteredItems.length < vm.scrollCount;
            vm.loader = !addedLessThan20;
            vm.scrollCount += 20;
        }
        ;
    }
}());
(function () {
    angular.module('app').component('campaignsView', {
        templateUrl: 'campaigns-view.tpl.html',
        controller: CampaignsViewController,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    function CampaignsViewController($rootScope, $location, $window, $mdDialog, $sce, campaignService, orgService, profileService, entityService, messageService, securityService, $scope, $timeout) {
        const vm = this;
        vm.selectGroup = selectGroup;
        vm.selectCampaign = selectCampaign;
        vm.updateViewSetting = updateViewSetting;
        vm.highlight = highlight;
        vm.unHighlight = unHighlight;
        vm.trueSelectedItems = trueSelectedItems;
        vm.isSelected = isSelected;
        vm.adminPermissionsOnSelected = false;
        vm.inviveAdminModal = inviveAdminModal;
        vm.moveItems = moveItems;
        vm.toggleCheck = toggleCheck;
        vm.toggleSort = toggleSort;
        vm.campaignPath = orgService.getUrlCampaignPath();
        vm.campaignHost = orgService.getUrlHost();
        if (vm.campaignPath) {
            vm.campaignId = campaignService.getCampaignByUrl(vm.campaignHost + '/' + vm.campaignPath).campaignId;
        } else {
            vm.campaignId = orgService.getUrlCampaignId();
        }
        vm.loadingNewData = false;
        vm.groupEmpty = false;
        vm.groupOrCmpNotFound = false;
        vm.selectedItems = [];
        vm.lastClicked = null;
        vm.selectedType = null;
        vm.shiftFrom = -1;
        vm.shiftTo = -1;
        vm.selecetedItems = null;
        vm.updateSelected = updateSelected;
        vm.focusInput = focusInput;
        vm.checkedFiltersCount = 0;
        vm.allFiltersChecked = true;
        vm.turnOffFilters = turnOffFilters;
        vm.searchFilterUpdate = searchFilterUpdate;
        vm.sort = {};
        vm.filterParams = {
            search: undefined,
            types: new Set(),
            delisted: new Set(),
            ended: false
        };
        $scope.allowDrop = allowDrop;
        $scope.onLeaveFile = onLeaveFile;
        vm.fileUploadInput = null;
        function onLeaveFile(event) {
            event.stopPropagation();
            document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
        }
        function allowDrop(event) {
            document.querySelector('.fileInput-placeholder').style.zIndex = '1';
        }
        profileService.getProfileInfo().then(info => {
            $rootScope.viewMode = info.userSettings.campaignView;
            vm.filterSettings = info.userFilterSettings.filterSettings;
            Object.keys(vm.filterSettings).forEach(key => {
                if (vm.filterSettings[key] == true) {
                    vm.checkedFiltersCount++;
                    let keySpaces = key.replace(/([A-Z])/g, ' $1').replace(/\s/, '');
                    switch (keySpaces) {
                    case 'Fundraising Platforms':
                        vm.filterParams.types.add('Platform');
                        break;
                    case 'Donation Pages':
                        vm.filterParams.types.add('Donation');
                        vm.filterParams.types.add('Global Donation Page');
                        break;
                    case 'Ticketing Pages':
                        vm.filterParams.types.add('Ticketing');
                        vm.filterParams.types.add('Standalone Ticketing');
                        break;
                    case 'Fundraising Campaigns':
                        vm.filterParams.types.add('Peer-to-peer');
                        vm.filterParams.types.add('Fuse');
                        vm.filterParams.types.add('Champion Campaign');
                        break;
                    case 'Memorial Campaigns':
                        vm.filterParams.types.add('Memorial');
                        break;
                    case 'Personal Campaigns':
                        vm.filterParams.types.add('Personal Fundraising');
                        break;
                    case 'Published Campaigns':
                        vm.filterParams.delisted.add(false);
                        break;
                    case 'Delisted Campaigns':
                        vm.filterParams.delisted.add(true);
                        break;
                    case 'Ended Campaigns':
                        vm.filterParams.ended = true;
                        break;
                    }
                } else {
                    vm.allFiltersChecked = false;
                }
            });
            let {sortSettings} = info.userSettings;
            vm.rule = sortSettings.slice(0, -2).replace(/([A-Z])/g, ' $1').replace(/\s/, '');
            vm.reverse = sortSettings.endsWith('1');
            switch (vm.rule) {
            case 'Recent':
                vm.sort.name = 'campaignId';
                break;
            case 'Campaign Type':
                vm.sort.name = 'type';
                break;
            case 'Amount Raised':
                vm.sort.name = 'amountRaised';
                break;
            case 'Event Date':
                vm.sort.name = 'eventDate';
                break;
            default:
                vm.sort.name = 'name';
            }
            vm.sort.reverse = vm.reverse;
        });
        campaignService.checkUpdateRouting().then(setCampaignAndGroup).catch(setNotFound);
        function setCampaignAndGroup(campaignAndGroup) {
            if (campaignAndGroup.campaign) {
                vm.campaign = campaignAndGroup.campaign;
                $rootScope.$emit('onCampaignPage');
            }
            if (campaignAndGroup.group) {
                var groupId = campaignAndGroup.group.groupId;
            }
            openGroupSetChildren(groupId);
        }
        function openGroupSetChildren(groupId) {
            campaignService.openGroup(groupId).then(campaignsData => {
                vm.campaigns = addAdminPanelUrl(campaignsData.campaigns);
                vm.groups = campaignsData.groups;
                if (!vm.campaigns.length && !vm.groups.length) {
                    vm.groupEmpty = true;
                }
                $rootScope.$emit('group.opened');
                $rootScope.$emit('entity.changedInView');
            });
        }
        function setNotFound() {
            $rootScope.$emit('groupOrCampaignNotFound');
            vm.groupOrCmpNotFound = true;
        }
        $rootScope.$on('orgs.loaded', (event, data) => {
            vm.loadingNewData = false;
            vm.campaigns = addAdminPanelUrl(data.campaigns);
            vm.groups = data.groups;
            $rootScope.$emit('group.opened');
        });
        $rootScope.$on('tree.changed', (event, data) => {
            vm.loadingNewData = false;
            vm.campaigns = addAdminPanelUrl(data.campaigns);
            vm.groups = data.groups;
        });
        $rootScope.$on('tree.clear', () => {
            vm.loadingNewData = true;
            vm.campaigns = vm.groups = [];
        });
        $rootScope.$on('move.close', () => {
            vm.actionOnSelected = false;
            //vm.unHighlight();
            vm.moveTooltip = false;
        });
        function selectOrg() {
            orgService.updatePathToOrg();
        }
        function selectGroup(group) {
            orgService.updatePathToGroup(group);
        }
        function selectCampaign(campaign) {
            orgService.updatePathToCampaign(campaign);
        }
        function highlight(item, event, longTouch) {
            let h = 'highlight', {ctrlKey, shiftKey, metaKey} = event, isCampaign = 'campaignId' in item, row = event.target.closest('.md-table-row') || event.target.closest('.md-card'), rows = [];
            if (row.closest('.md-table')) {
                rows = row.closest('.md-table').querySelectorAll('.md-table-row:not(.table-head)');
            } else if (row.closest('.grid-cards')) {
                rows = row.closest('.grid-cards').querySelectorAll('.md-card');
            }
            let rowsArr = Array.from(rows), index = rowsArr.indexOf(row);
            ctrlKey = ctrlKey || metaKey;
            if (!(ctrlKey || shiftKey) && !longTouch) {
                rows.forEach(item => item.classList.remove(h));
                vm.selectedItems = [];
                row.classList.add(h);
                vm.selectedItems[index] = row;
                vm.lastClicked = index;
            } else {
                if (ctrlKey || longTouch) {
                    if (row.classList.contains(h)) {
                        row.classList.remove(h);
                        delete vm.selectedItems[index];
                        vm.lastClicked = null;
                    } else {
                        row.classList.add(h);
                        vm.selectedItems[index] = row;
                        vm.lastClicked = index;
                    }
                }
                if (shiftKey) {
                    if (vm.lastClicked == null) {
                        row.classList.add(h);
                        vm.selectedItems[index] = row;
                        vm.lastClicked = index;
                    }
                    let direction = 'none';
                    if (index > vm.lastClicked)
                        direction = 'down';
                    else if (index < vm.lastClicked)
                        direction = 'up';
                    if (direction == 'none') {
                        // if init shift click
                        row.classList.remove(h);
                        delete vm.selectedItems[index];
                        vm.lastClicked = null;
                    } else {
                        rows.forEach((thisRow, thisIndex) => {
                            if (direction == 'up') {
                                if (thisIndex <= vm.shiftFrom && thisIndex < index && thisIndex >= vm.shiftTo) {
                                    thisRow.classList.remove(h);
                                    delete vm.selectedItems[thisIndex];
                                }
                                if (thisIndex >= index && thisIndex <= vm.lastClicked) {
                                    thisRow.classList.add(h);
                                    vm.selectedItems[thisIndex] = thisRow;
                                }
                            }
                            if (direction == 'down') {
                                if (thisIndex >= vm.shiftFrom && thisIndex > index && thisIndex <= vm.shiftTo) {
                                    thisRow.classList.remove(h);
                                    delete vm.selectedItems[thisIndex];
                                }
                                if (thisIndex <= index && thisIndex >= vm.lastClicked) {
                                    thisRow.classList.add(h);
                                    vm.selectedItems[thisIndex] = thisRow;
                                }
                            }
                        });
                        vm.shiftFrom = vm.lastClicked;
                        vm.shiftTo = index;
                    }
                }
            }
            vm.selectedItems.isCampaign = isCampaign;
            var items = trueSelectedItems();
            var hasPermission = false;
            for (var i = 0; i < items.length; i++) {
                var hasPermission = items[i].permissions.ADMIN_USERS & 4;
                if (!hasPermission) {
                    hasPermission = false;
                    break;
                }
            }
            vm.adminPermissionsOnSelected = hasPermission;
        }
        function updateSelected() {
            vm.selecetedItems = trueSelectedItems();
        }
        function trueSelectedItems() {
            let items = [];
            vm.selectedItems.forEach(item => {
                let thisId = item.id.match(/\d+/g)[0];
                let source = null;
                if (vm.selectedItems.isCampaign)
                    source = vm.campaigns.find(campaign => campaign.campaignId == thisId);
                else
                    source = vm.groups.find(group => group.groupId == thisId);
                items.push(source);
            });
            items.isCampaign = vm.selectedItems.isCampaign;
            return items;
        }
        function updateViewSetting(view) {
            unHighlight();
            $rootScope.viewMode = view;
            profileService.setProfileInfoCampView(view);
        }
        function unHighlight(target) {
            if (vm.actionOnSelected)
                return;
            let rows = document.querySelectorAll(`.table-${ target } .md-table-row.highlight`), cards = document.querySelectorAll(`.grid-${ target } .md-card.highlight`), items = null;
            if (target === undefined) {
            }
            if (rows.length) {
                items = Array.from(rows);
            }
            if (cards.length) {
                items = Array.from(cards);
            }
            if (items) {
                vm.moveTooltip = false;
                items.forEach(item => item.classList.remove('highlight'));
                if (target == 'groups' && !vm.selectedItems.isCampaign || target == 'campaigns' && vm.selectedItems.isCampaign) {
                    //when switch between groups and campaigns
                    vm.selectedItems = [];
                }
            }
        }
        function isSelected() {
            return vm.selectedItems.some(x => x !== undefined);
        }
        function turnOffFilters() {
            $scope.$broadcast('beforeFilterOrSortUpdated');
            vm.allFiltersChecked = true;
            vm.checkedFiltersCount = Object.keys(vm.filterSettings).length;
            Object.keys(vm.filterSettings).forEach(s => {
                vm.filterSettings[s] = true;
            });
            vm.searchInput = undefined;
            vm.filterParams.search = undefined;
            vm.filterParams.types.add('Platform');
            vm.filterParams.types.add('Donation');
            vm.filterParams.types.add('Global Donation Page');
            vm.filterParams.types.add('Ticketing');
            vm.filterParams.types.add('Standalone Ticketing');
            vm.filterParams.types.add('Peer-to-peer');
            vm.filterParams.types.add('Fuse');
            vm.filterParams.types.add('Personal Fundraising');
            vm.filterParams.types.add('Champion Campaign');
            vm.filterParams.types.add('Memorial');
            vm.filterParams.delisted.add(false);
            vm.filterParams.delisted.add(true);
            vm.filterParams.ended = true;
        }
        function toggleCheck(event) {
            $scope.$broadcast('beforeFilterOrSortUpdated');
            let item = event.target.closest('li'), itemProp = item.querySelector('.label').innerText, checked = item.classList.toggle('checked'), key = itemProp.replace(/\s+/g, '');
            switch (itemProp) {
            case 'Fundraising Platforms':
                if (checked)
                    vm.filterParams.types.add('Platform');
                else
                    vm.filterParams.types.delete('Platform');
                break;
            case 'Donation Pages':
                if (checked) {
                    vm.filterParams.types.add('Donation');
                    vm.filterParams.types.add('Global Donation Page');
                } else {
                    vm.filterParams.types.delete('Donation');
                    vm.filterParams.types.delete('Global Donation Page');
                }
                break;
            case 'Ticketing Pages':
                if (checked) {
                    vm.filterParams.types.add('Ticketing');
                    vm.filterParams.types.add('Standalone Ticketing');
                } else {
                    vm.filterParams.types.delete('Ticketing');
                    vm.filterParams.types.delete('Standalone Ticketing');
                }
                break;
            case 'Fundraising Campaigns':
                if (checked) {
                    vm.filterParams.types.add('Peer-to-peer');
                    vm.filterParams.types.add('Fuse');
                    vm.filterParams.types.add('Champion Campaign');
                } else {
                    vm.filterParams.types.delete('Peer-to-peer');
                    vm.filterParams.types.delete('Fuse');
                    vm.filterParams.types.delete('Champion Campaign');
                }
                break;
            case 'Memorial Campaigns':
                if (checked) {
                    vm.filterParams.types.add('Memorial');
                } else {
                    vm.filterParams.types.delete('Memorial');
                }
                break;
            case 'Personal Campaigns':
                if (checked) {
                    vm.filterParams.types.add('Personal Fundraising');
                } else {
                    vm.filterParams.types.delete('Personal Fundraising');
                }
                break;
            case 'Published Campaigns':
                if (checked)
                    vm.filterParams.delisted.add(false);
                else
                    vm.filterParams.delisted.delete(false);
                break;
            case 'Delisted Campaigns':
                if (checked)
                    vm.filterParams.delisted.add(true);
                else
                    vm.filterParams.delisted.delete(true);
                break;
            case 'Ended Campaigns':
                if (checked)
                    vm.filterParams.ended = true;
                else
                    vm.filterParams.ended = false;
                break;
            }
            vm.filterSettings[key] = checked;
            if (checked) {
                vm.checkedFiltersCount++;
                vm.allFiltersChecked = vm.checkedFiltersCount == Object.keys(vm.filterSettings).length;
            } else {
                vm.allFiltersChecked = false;
                vm.checkedFiltersCount--;
            }
            profileService.updateFilters(vm.filterSettings);
        }
        function toggleSort(event) {
            $scope.$broadcast('beforeFilterOrSortUpdated');
            let item = event.target.closest('li'), itemProp = item.querySelector('.label').innerText;
            switch (itemProp) {
            case 'Recent':
                vm.sort.name = 'campaignId';
                break;
            case 'Campaign Type':
                vm.sort.name = 'type';
                break;
            case 'Amount Raised':
                vm.sort.name = 'amountRaised';
                break;
            case 'Event Date':
                vm.sort.name = 'eventDate';
                break;
            default:
                vm.sort.name = 'name';
            }
            vm.rule = itemProp;
            let items = item.closest('.checks-list').querySelectorAll('.checks-list-item');
            if (item.classList.contains('sort-asc')) {
                items.forEach(item => item.classList.remove('sort-asc', 'sort-desc'));
                item.classList.add('sort-desc');
                vm.sort.reverse = vm.reverse = true;
            } else if (item.classList.contains('sort-desc')) {
                items.forEach(item => item.classList.remove('sort-asc', 'sort-desc'));
                item.classList.add('sort-asc');
                vm.sort.reverse = vm.reverse = false;
            } else {
                items.forEach(item => item.classList.remove('sort-asc', 'sort-desc'));
                item.classList.add('sort-asc');
                vm.sort.reverse = vm.reverse = false;
            }
            profileService.updateSort(`${ vm.rule.replace(/\s+/, '') }-${ vm.reverse ? 1 : 0 }`);
        }
        function inviveAdminModal() {
            $rootScope.$emit('admin.invite', trueSelectedItems());
        }
        function moveItems(toIds) {
            let itemsIds = [], entityIds = [], items = trueSelectedItems(), isCampaign = items.isCampaign, entityId = orgService.getOrg().entityId, fromGroupId = entityService.getOpenedGroupId(), fromEntityId = entityService.getOpenedEntityId(), toEntityId = toIds.entityId, toGroupId = toIds.groupId || null;
            items.forEach(item => {
                itemsIds.push(isCampaign ? item.campaignId : item.groupId);
                entityIds.push(item.entityId);
            });
            let successActionFunction = function () {
                if (toGroupId) {
                    let group = campaignService.getGroupById(toGroupId);
                    return selectGroup(group);
                } else {
                    return selectOrg();
                }
            };
            let messageConfig = {
                successToastEnabled: true,
                successMsg: `Successfully moved ${ itemsIds.length } ${ isCampaign ? 'campaign(s)' : 'group(s)' } .`,
                successActionTitle: 'Go to new parent',
                successActionFunction: successActionFunction
            };
            if (isCampaign) {
                entityService.moveCampaignsToGroup(fromEntityId, entityIds, toEntityId, messageConfig).then(response => {
                    movingHelper(entityId, itemsIds, toGroupId, fromGroupId, isCampaign);
                });
            } else {
                entityService.moveGroups(fromEntityId, entityIds, toEntityId, messageConfig).then(response => {
                    movingHelper(entityId, itemsIds, toGroupId, fromGroupId, isCampaign);
                });
            }
        }
        function movingHelper(entityId, itemsIds, toGroupId, fromGroupId, isCampaign) {
            $rootScope.$emit('tree.clear');
            if (isCampaign) {
                campaignService.moveCampaignCache(itemsIds, toGroupId || 0);
            } else {
                campaignService.moveGroupCache(itemsIds, toGroupId || 0);
            }
            campaignService.openGroup(fromGroupId, entityId).then(campaignsData => {
                $rootScope.$emit('tree.changed', campaignsData);
            });
        }
        function addAdminPanelUrl(campaigns) {
            campaigns.forEach(item => {
                if (item.archived || !item.eventHostReal) {
                    item.adminUrl = item.curEventId;
                } else {
                    item.adminUrl = item.eventHostReal.replace('/', '~');
                }
            });
            return campaigns;
        }
        /* More ... actions */
        vm.delistCmps = delistCmps;
        vm.loaders = {};
        vm.archiveOrRelaunchClick = archiveOrRelaunchClick;
        function updateDelistCampaigns(entityIds, delist) {
            let update = entityIds.map(item => ({
                entityId: item,
                delisted: delist
            }));
            update.forEach(item => {
                campaignService.updateRawCampaigns(item);
            });
        }
        function updateRelaunchCampaigns(successfulCampaigns) {
            let update = successfulCampaigns;
            update.forEach(item => {
                campaignService.updateRawCampaigns(item);
            });
        }
        function updateArchiveCampaigns(entityIds) {
            let update = entityIds.map(item => ({
                entityId: item,
                archived: true
            }));
            update.forEach(item => {
                campaignService.updateRawCampaigns(item);
            });
        }
        function delistCmps(delist) {
            let selectedItems = trueSelectedItems(), entityIds = selectedItems.filter(item => item.delisted !== delist).map(item => item.entityId);
            if (!entityIds.length) {
                let actionable = delist ? 'delistable' : 'publishable';
                showNoCampaignsMsg(actionable);
                return;
            }
            showConfirm(delist ? 'delist' : 'publish', entityIds.length).then(() => {
                showLoading();
                function advancedErrors(currentMessageService, response) {
                    let successfulEntityIds = response.data.successfulEntityIds;
                    updateDelistCampaigns(successfulEntityIds, delist);
                    let action = delist ? 'delist' : 'publish';
                    let actioned = delist ? 'delisted' : 'published';
                    showErrors(successfulEntityIds.length, entityIds.length, action, actioned, response.data.errors);
                }
                let messageConfig = {
                    successToastEnabled: true,
                    successMsg: `Successfully ${ delist ? 'delisted' : 'published' } ${ entityIds.length } campaign(s)!`,
                    advancedErrorFunction: advancedErrors
                };
                campaignService.delistCampaigns(entityIds, delist, messageConfig).then(() => updateDelistCampaigns(entityIds, delist)).finally(hideLoading);
            });
        }
        function archiveOrRelaunchInvalid(currentMessageService, response, endClickEvent, endType) {
            response = response.data;
            if (response.liveDonationRecurringCount && (response.destinationOptions || endType === 'relaunch')) {
                showMoveRecurrigModal(response, endClickEvent, endType);
            } else {
                showUnableToEndModal();
            }
        }
        function archiveOrRelaunchClick(endClickEvent, endType) {
            if (vm.loaders.relaunch || vm.loaders.archive) {
                return;
            }
            vm.loaders[endType] = true;
            if (!filterAndValidateEndCmps(endType)) {
                return;
            }
            let messageConfig = {
                advancedErrorFunction: (currentMessageService, response) => archiveOrRelaunchInvalid(currentMessageService, response, endClickEvent, endType),
                advancedErrorEnabled: true
            };
            campaignService.validateArchiveOrRelaunchCmps(vm.relaunchOrEndEntityIds, messageConfig).then(response => {
                endType === 'relaunch' ? showRelaunchConfirm(endClickEvent) : showArchiveConfirm(endClickEvent);
            })    //validation errors handled in message service advancedErrorFunction
.finally(() => {
                vm.loaders[endType] = false;
                vm.moreTooltip = false;
            });
        }
        function filterAndValidateEndCmps(endType) {
            let selectedItems = trueSelectedItems();
            vm.relaunchOrEndEntityIds = selectedItems.filter(item => !item.archived).map(item => item.entityId);
            if (!vm.relaunchOrEndEntityIds.length) {
                let actionable = endType = 'relaunch' ? 'relaunchable' : 'endable';
                showNoCampaignsMsg(actionable);
                return false;
            }
            return true;
        }
        function showArchiveConfirm(endClickEvent, recurringPlanDestination) {
            showConfirm('end', vm.relaunchOrEndEntityIds.length, endClickEvent, recurringPlanDestination).then(() => {
                showLoading();
                function advancedErrors(currentMessageService, response) {
                    let successfulEntityIds = response.data.successfulEntityIds;
                    updateArchiveCampaigns(successfulEntityIds);
                    let action = 'end';
                    let actioned = 'ended';
                    showErrors(successfulEntityIds.length, entityIds.length, action, actioned, response.data.errors);
                }
                let messageConfig = {
                    successToastEnabled: true,
                    successMsg: `Successfully ended ${ vm.relaunchOrEndEntityIds.length } campaign(s)!`,
                    advancedErrorFunction: advancedErrors
                };
                campaignService.archiveCampaigns(vm.relaunchOrEndEntityIds, Boolean(vm.liveDonationRecurringCount), recurringPlanDestination, messageConfig).then(() => updateArchiveCampaigns(vm.relaunchOrEndEntityIds)).finally(hideLoading);
            });
        }
        function showRelaunchConfirm(endClickEvent, recurringPlanDestination) {
            showConfirm('relaunch', vm.relaunchOrEndEntityIds.length, endClickEvent, recurringPlanDestination).then(() => {
                showLoading();
                function advancedErrors(currentMessageService, response) {
                    successfulCampaigns = response.data;
                    updateRelaunchCampaigns(successfulCampaigns);
                    let action = 'relaunch';
                    let actioned = 'relaunched';
                    showErrors(successfulCampaigns.length, vm.relaunchOrEndEntityIds.length, action, actioned, response.data.errors);
                }
                let messageConfig = {
                    successToastEnabled: true,
                    successMsg: `Successfully relaunched ${ vm.relaunchOrEndEntityIds.length } campaign(s)!`,
                    advancedErrorFunction: advancedErrors
                };
                campaignService.relaunchCampaign(vm.relaunchOrEndEntityIds, Boolean(vm.liveDonationRecurringCount), recurringPlanDestination, messageConfig).then(response => updateRelaunchCampaigns(response.data)).finally(hideLoading);
            });
        }
        function showMoveRecurrigModal(response, endClickEvent, endType) {
            vm.liveDonationRecurringCount = response.liveDonationRecurringCount;
            vm.destinationOptions = response.destinationOptions;
            vm.moveTrigger = endType;
            vm.targetEvent = endClickEvent;
            vm.confirmationCallback = endType === 'relaunch' ? showRelaunchConfirm : showArchiveConfirm;
            vm.showModalMoveRecurring = true;
        }
        function showUnableToEndModal() {
            messageService.showErrorDialog(`Campaigns cannot be ended while they have active recurring donation plans or donation matches. <br>Please end your active recurring plan(s) and/or match(es), after which you will be able to end the selected campaign(s).`);
        }
        function showNoCampaignsMsg(actionable) {
            return $mdDialog.show($mdDialog.alert().title('Notice').textContent(`No ${ actionable } campaigns selected.`).ok('Close'));
        }
        function showConfirm(action, count, endClickEvent, recurringPlanDestination) {
            let unendedPlansMsg = ``;
            if (vm.liveDonationRecurringCount) {
                let recurringDestinationStr = recurringPlanDestination ? recurringPlanDestination.eventName : `the newly relaunched campaign(s)`;
                unendedPlansMsg = `<br>${ vm.liveDonationRecurringCount } active recurring plan(s) will be transferred to ${ recurringDestinationStr }.`;
            }
            return $mdDialog.show($mdDialog.confirm().clickOutsideToClose(true).title('Are you sure?').htmlContent(`You are about to ${ action } ${ count } campaign(s).${ unendedPlansMsg }`).targetEvent(endClickEvent).ok('Confirm').cancel('Cancel'));
        }
        let loadingDialogVisible;
        function showLoading() {
            loadingDialogVisible = true;
            return $mdDialog.show({
                clickOutsideToClose: false,
                template: `<md-dialog>
                  <md-dialog-content>
                     <loader flex size="40"></loader>
                  </md-dialog-content>
                </md-dialog>`
            }).finally(() => loadingDialogVisible = false);
        }
        function hideLoading() {
            if (loadingDialogVisible)
                $mdDialog.cancel();
        }
        function showErrors(successCount, totalCount, action, actioned, errors) {
            function show() {
                let errorIntroMsg = `${ successCount } out of ${ totalCount } campaign(s) ${ actioned } successfully. <br><br>The following campaigns failed to ${ action }:`;
                messageService.showErrorDialogFromErrorsObj(errors, errorIntroMsg);
            }
            if (loadingDialogVisible) {
                $mdDialog.cancel().then(show);
            } else {
                show();
            }
        }
        function focusInput() {
            let input = document.getElementById('searchBox');
            $timeout(function () {
                input.focus();
            }, 0);
        }
        function searchFilterUpdate() {
            $scope.$broadcast('beforeFilterOrSortUpdated');
            vm.filterParams.search = vm.searchInput;
        }
    }
}());
(function () {
    angular.module('app').component('movingTree', {
        bindings: {
            items: '=',
            onMoveClick: '&'
        },
        templateUrl: 'moving-tree.tpl.html',
        controller: MovingTreeController,
        controllerAs: 'vm'
    });
    function MovingTreeController($rootScope, campaignService, orgService, entityService) {
        const vm = this;
        vm.tree = {
            groups: [],
            campaigns: []
        };
        vm.highlight = null;
        vm.disableMove = true;
        vm.highlightItem = highlightItem;
        vm.getGroups = getGroups;
        vm.goBack = goBack;
        vm.close = close;
        vm.isRoot = isRoot;
        vm.isCurrent = isCurrent;
        vm.getOpenedName = getOpenedName;
        vm.changeName = changeName;
        getGroups(campaignService.getOpenedGroup().groupId);
        function changeName(name) {
            if (name.length > 20) {
                name = name.substring(0, 20) + '...';
                return name;
            } else {
                return name;
            }
        }
        function highlightItem(group, event) {
            let groupId = group.groupId;
            let nope = false;
            if (isCurrent())
                vm.disableMove = false;
            if (!vm.items.isCampaign) {
                if (vm.items.some(item => item.groupId == groupId)) {
                    nope = true;
                    vm.disableMove = true;
                }
                vm.disableMoveSame = vm.items.some(item => item.parentGroupId == groupId);
            }
            let target = event.target;
            vm.highlight = {
                entityId: group.entityId,
                groupId: group.groupId
            };
            let h = 'highlight', li = target.closest('.checks-list-item');
            if (li.classList.contains(h)) {
                if (!nope)
                    getGroups(groupId);    //can't go down inside selected folder
            } else {
                let ul = Array.from(li.parentNode.querySelectorAll('.checks-list-item'));
                ul.forEach(item => item.classList.remove(h));
                li.classList.add(h);
            }
        }
        function getGroups(groupId) {
            campaignService.openGroup(groupId, null, false, true).then(response => {
                vm.tree.groups = response.groups;
                vm.totalCampaignsCount = response.campaigns.length;
                vm.tree.campaigns = vm.totalCampaignsCount > 25 ? response.campaigns.slice(0, 25) : response.campaigns;
            });
        }
        function goBack() {
            getGroups(campaignService.getOpenedGroupMove().parentGroupId);
            var groupId = campaignService.getOpenedGroupMove().groupId;
            var entityId = entityService.getOpenedEntityIdMove();
            vm.highlight = {
                entityId: entityId,
                groupId: groupId
            };
            vm.disableMove = true;
        }
        function getOpenedName() {
            return campaignService.getOpenedGroupMove().name || orgService.getOrg().name;
        }
        function isRoot() {
            return !!(campaignService.getOpenedGroupMove().groupId || 0);
        }
        function close() {
            $rootScope.$emit('move.close');
        }
        function isCurrent() {
            let currentLevel = campaignService.getOpenedGroup().groupId || 0, currentTreeLevel = campaignService.getOpenedGroupMove().groupId || 0;
            return currentLevel == currentTreeLevel;
        }
    }
}());
(function () {
    angular.module('app').component('modalAddNewCampaign', {
        bindings: {
            show: '=',
            type: '=',
            replicate: '='
        },
        controller: modalAddNewCampaignCtrl,
        controllerAs: 'vm'
    });
    function modalAddNewCampaignCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-add-new-campaign.tpl.html',
                clickOutsideToClose: true,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    type: vm.type,
                    replicate: vm.replicate
                }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($mdDialog, $route, $rootScope, $timeout, mdcDateTimeDialog, entityService, campaignService, orgService, messageService, type, replicate, ngModelFiltersService) {
        const vm = this;
        vm.saveInProgress = false;
        vm.campaignType = type;
        vm.replicate = replicate;
        vm.entityId = orgService.getOrg().entityId;
        vm.entityIdParent = entityService.getOpenedEntityId();
        vm.curGroupId = entityService.getOpenedGroupId();
        vm.domains = campaignService.getCampaignDomains();
        vm.path = false;
        // true = site.domain.com | false = domain.com/site
        vm.cloneCmpSettings = {
            cmpSettings: {
                value: 1,
                checked: false
            },
            paymentProcessor: {
                value: 2,
                checked: false
            },
            templates: {
                value: 4,
                checked: false
            },
            defaults: {
                value: 8,
                checked: false
            },
            siteContent: {
                value: 16,
                checked: false
            },
            adminUsers: {
                value: 32,
                checked: false
            }
        };
        vm.updateSubDomain = updateSubDomain;
        vm.addCampaign = addCampaign;
        vm.replaceInputs = replaceInputs;
        vm.cancel = closeModal;
        vm.validateSubdomain = validateSubdomain;
        vm.validSubdomain = false;
        vm.displayDateDialog = displayDateDialog;
        vm.displayTimeDialog = displayTimeDialog;
        vm.filters = ngModelFiltersService;
        vm.currencySymbol = entityService.getEntityWithSettings(entityService.getOpenedEntityId()).settings.DefaultCurrency.Symbol;
        let campaign = campaignService.getCampaignByEntityId(entityService.getOpenedEntityId());
        vm.settingsForm = campaign ? campaign.settingsForm : undefined;
        function displayDateDialog() {
            mdcDateTimeDialog.show({
                time: false,
                date: true,
                currentDate: vm.date,
                disableParentScroll: true
            }).then(function (date) {
                vm.date = new moment(date);
                vm.date.hour(0).minute(0).second(0).millisecond(0);
                vm.dateDisplay = vm.date.format('MM/DD/YYYY');
            }, function () {
            });
        }
        ;
        function displayTimeDialog() {
            mdcDateTimeDialog.show({
                time: true,
                date: false,
                shortTime: true,
                currentDate: vm.date,
                disableParentScroll: true
            }).then(function (date) {
                vm.date = new moment(date);
                vm.timeDisplay = vm.date.format('h:mm a');
            }, function () {
            });
        }
        ;
        if (vm.domains.length == 1) {
            vm.onlyOneDomain = true;
            vm.urlDomain = vm.domains[0];
            replaceInputs();
        }
        if (vm.replicate) {
            let campaignId = orgService.getUrlCampaignId();
            let campaign = campaignService.getCampaignById(campaignId);
            vm.ianaTimeZone = campaign.ianaTimeZone;
            if (campaign.groupUrl != null) {
                vm.onlyOneDomain = true;
                vm.urlDomain = campaign.groupUrl;
                replaceInputs();
            }
        }
        function updateSubDomain() {
            if (vm.name)
                vm.subDomain = vm.name.toLowerCase().replace(/\s+/g, '-');
        }
        function addCampaign() {
            vm.validateSubdomain();
            if (!vm.validSubdomain) {
                document.getElementById('subDomainInput').focus();
                return;
            }
            vm.saveInProgress = true;
            let data = {
                name: vm.name,
                description: vm.description,
                goalAmount: vm.goalAmount,
                subDomain: vm.subDomain,
                urlDomain: vm.urlDomain,
                location: vm.location,
                campaignGroupId: vm.curGroupId != 0 ? vm.curGroupId : null
            };
            if (data.location) {
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: data.location }, function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        var newlocation = results[0].geometry.location;
                        data.locationLat = newlocation.lat();
                        data.locationLng = newlocation.lng();
                        var stateCountry = getGeocodeStateCountry(results[0]);
                        data.locationSubdivision = stateCountry.state;
                        data.locationCountry = stateCountry.country;
                    }
                    processCampaign(data);
                });
                return;
            }
            processCampaign(data);
        }
        function getGeocodeStateCountry(data) {
            let loc = {};
            data.address_components.forEach(function (addr) {
                if (addr.types.includes('administrative_area_level_1')) {
                    loc.state = addr.short_name;
                } else if (addr.types.includes('country')) {
                    loc.country = addr.short_name;
                }
            });
            return loc;
        }
        function processCampaign(data) {
            let messageConfig = {
                advancedErrorFunction: messageService.advancedErrorsDialog,
                advancedErrorEnabled: true,
                successToastEnabled: !vm.replicate,
                successMsg: vm.name + ' campaign added successfully!'
            };
            if (vm.replicate) {
                let copyCmpSettings = Object.values(vm.cloneCmpSettings).filter(cmpSetting => cmpSetting.checked).reduce((cmpSettingsTotal, cmpSetting) => cmpSettingsTotal | cmpSetting.value, 0);
                let customFields = {};
                if (vm.settingsForm && vm.settingsForm.fields) {
                    let formFields = vm.settingsForm.fields.filter(field => field.value).map(field => {
                        let value;
                        if (field.type === 6) {
                            //multiselect
                            const selectedOptionsId = field.options.filter(option => field.value.includes(option.label)).map(option => option.id);
                            value = selectedOptionsId.join(',');
                        } else {
                            value = field.value;
                        }
                        return { [field.name]: value };
                    });
                    Object.assign(customFields, ...formFields);
                }
                let campaignId = orgService.getUrlCampaignId();
                let campaignEntityId = campaignService.getCampaignById(campaignId).entityId;
                Object.assign(data, { date: vm.date.format('YYYY-MM-DD[T]HH:mm:ss') }, { cloneCmpSettings: copyCmpSettings }, { customFields: customFields }, { isRelaunch: false });
                campaignService.replicateCampaign(campaignEntityId, data, messageConfig).then(response => showAdded(response.data.data)).finally(() => vm.saveInProgress = false);
            } else if (vm.campaignType == 'Donation') {
                entityService.newCmpDonation(vm.entityIdParent, data, messageConfig).then(response => showAdded(response), () => {
                }).finally(() => vm.saveInProgress = false);
            } else if (vm.campaignType == 'Standalone Ticketing') {
                entityService.newCmpTicket(vm.entityIdParent, data, messageConfig).then(response => showAdded(response)).finally(() => vm.saveInProgress = false);
            } else if (vm.campaignType == 'Template') {
                entityService.newCmpFromTemplate(vm.entityIdParent, data, messageConfig).then(response => showAdded(response)).finally(() => vm.saveInProgress = false);
            }
        }
        function closeModal() {
            $mdDialog.hide();
        }
        function showAdded(data) {
            closeModal();
            $rootScope.$emit('tree.clear');
            campaignService.pushToRawCampaigns(data);
            let orgEntityId = orgService.getOrg().entityId;
            campaignService.openGroup(vm.curGroupId, orgEntityId).then(campaignsData => {
                $rootScope.$emit('tree.changed', campaignsData);
                orgService.updatePathToCampaign(data);
            });
        }
        function replaceInputs() {
            vm.path = /^\./.test(vm.urlDomain);
        }
        function validateSubdomain() {
            let regex = /.*(\.|\/|\s).*/;
            if (String(vm.subDomain).match(regex)) {
                vm.validSubdomain = false;
                messageService.showErrorToast('Please do not use the "/", ".", or " " characters.');
                return;
            }
            //validate url if chaging subdomain
            if (vm.path) {
                regex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/;
                if (!regex.test(String(vm.subDomain))) {
                    vm.validSubdomain = false;
                    messageService.showErrorToast('Please only enter valid Url characters');
                    return;
                }
            } else {
                regex = /^[a-zA-Z0-9-]*$/;
                if (!String(vm.subDomain).match(regex)) {
                    vm.validSubdomain = false;
                    messageService.showErrorToast('Only letters, numbers, and hyphens are valid for the url path.');
                    return;
                }
            }
            vm.validSubdomain = true;
        }
    }
}());
(function () {
    angular.module('app').component('modalAddNewGroup', {
        bindings: {
            show: '=',
            rename: '=',
            groupId: '=',
            entityId: '='
        },
        controller: modalAddNewGroupCtrl,
        controllerAs: 'vm'
    });
    function modalAddNewGroupCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-add-new-group.tpl.html',
                clickOutsideToClose: true,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    rename: vm.rename,
                    groupId: vm.groupId,
                    entityId: vm.entityId
                }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($mdDialog, $route, $rootScope, entityService, campaignService, orgService, rename, groupId, entityId) {
        const vm = this;
        vm.saveInProgress = false;
        vm.entityIdParent = entityService.getOpenedEntityId();
        vm.curGroup = groupId ? campaignService.getGroupById(groupId) : campaignService.getOpenedGroup();
        vm.curGroupId = groupId || vm.curGroup.groupId;
        vm.rename = rename;
        if (rename) {
            vm.newGroup = vm.curGroup.name;
        }
        vm.cancel = closeModal;
        vm.addGroup = addNewGroupToCurrent;
        vm.renameGroup = renameGroup;
        function closeModal() {
            $mdDialog.hide();
        }
        function addNewGroupToCurrent() {
            vm.saveInProgress = true;
            let messageConfig = {
                successToastEnabled: true,
                successMsg: vm.newGroup + ' group added successfully!'
            };
            entityService.createNewCampaignGroup(vm.entityIdParent, vm.newGroup, messageConfig).then(response => {
                $rootScope.$emit('tree.clear');
                let data = response.data.data;
                campaignService.pushToRawGroups({
                    amountRaised: 0,
                    entityId: data.entityId,
                    groupId: data.groupId,
                    members: 0,
                    name: data.name,
                    parentGroupId: data.parentGroupId,
                    permissions: data.permissions
                });
                campaignService.openGroup(data.groupId, data.entityId, true).then(campaignsData => {
                    entityService.updateAllEntities();
                    $rootScope.$emit('tree.changed', campaignsData);
                    orgService.updatePathToGroup(data);
                });
                closeModal();
            }).finally(() => {
                vm.saveInProgress = false;
            });
        }
        function renameGroup() {
            vm.saveInProgress = true;
            entityService.renameGroup(vm.curGroupId, vm.newGroup).then(() => {
                campaignService.updateGroupName(vm.curGroupId, vm.newGroup);
                if (entityId) {
                    campaignService.openGroup(vm.curGroup.parentGroupId, entityId).then(campaignsData => {
                        $rootScope.$emit('tree.changed', campaignsData);
                    });
                }
                closeModal();
            }).finally(() => vm.saveInProgress = false);
        }
    }
}());
(function () {
    angular.module('app').component('modalAdministrators', {
        bindings: {
            show: '=',
            entityIds: '=',
            openEntityId: '<',
            org: '=',
            groups: '=',
            campaign: '='
        },
        controller: ModalAdministratorsCtrl,
        controllerAs: 'vm'
    });
    function ModalAdministratorsCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-administrators.tpl.html',
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    entityIds: vm.entityIds,
                    openEntityId: vm.openEntityId,
                    org: vm.org,
                    groups: vm.groups,
                    campaign: vm.campaign
                },
                clickOutsideToClose: true
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($q, $mdDialog, $timeout, campaignService, administratorService, entityService, orgService, messageService, securityService, entityIds, openEntityId, org, groups, campaign, $scope, $mdConstant) {
        const vm = this;
        vm.inviteOnly = entityIds && entityIds.length;
        vm.newAdmin = {};
        vm.roles = [];
        vm.roleIdsToRolesMap = [];
        vm.admins = [];
        vm.loadInProgress = false;
        vm.saveInProgress = false;
        vm.inviteInProgress = false;
        vm.removeInProgress = { index: null };
        vm.adminSecurity = {};
        vm.addRemoveFlag = addRemoveFlag;
        vm.inviteNewAdmin = inviteNewAdmin;
        vm.removeAdmin = removeAdmin;
        vm.addRoleChanges = addRoleChanges;
        vm.closeModal = closeModal;
        vm.getTitle = getTitle;
        vm.findRole = findRole;
        vm.showList = false;
        vm.showTextArea = false;
        vm.allEmails = [];
        vm.showChipsHint = false;
        vm.revertDeleteIcon = revertDeleteIcon;
        // vm.changeMdContainer = changeMdContainer;
        loadAdminPermissions();
        fetchData();
        function loadAdminPermissions() {
            if (!vm.inviteOnly) {
                var entity = entityService.getRawEntityById(openEntityId);
                vm.adminSecurity = securityService.getAdminPermissions(entity, 'ADMIN_USERS');
            } else {
                vm.adminSecurity.DELETE = 4;
            }
        }
        // function changeMdContainer(){
        //   document.querySelector('.md-dialog-container').className = "md-dialog-container md-container-overwrite";
        // }
        function revertDeleteIcon() {
            if (vm.admins != undefined) {
                vm.admins.forEach(admin => {
                    if (admin.remove == true) {
                        admin.remove = false;
                    }
                });
            }
        }
        function fetchData() {
            vm.loadInProgress = true;
            let curEntity = vm.inviteOnly > 0 ? entityIds[0] : openEntityId;
            let promises = [administratorService.getAllRoles(curEntity)];
            if (!vm.inviteOnly) {
                promises.push(administratorService.getAdmins(openEntityId));
            }
            $q.all(promises).then(([roles, admins]) => {
                vm.roles = roles;
                vm.roleIdsToRolesMap;
                roles.forEach(role => {
                    vm.roleIdsToRolesMap[role.roleId] = role;
                });
                vm.admins = admins;
                if (vm.admins) {
                    vm.admins.forEach(admin => {
                        vm.allEmails.push(admin.email);
                    });
                }
                loadContacts();
            }).catch(() => {
                closeModal();
            }).finally(() => {
                vm.loadInProgress = false;
            });
        }
        function findRole(id) {
            let role = vm.roles.filter(function (role) {
                return role.roleId == id;
            });
            return role[0].rolename;
        }
        //chips
        vm.adminSearch = adminSearch;
        vm.newAdmin.email = [];
        vm.selectedItem = null;
        vm.searchText = null;
        vm.selectedTags = [];
        vm.keys = [
            $mdConstant.KEY_CODE.ENTER,
            $mdConstant.KEY_CODE.COMMA,
            $mdConstant.KEY_CODE.TAB
        ];
        function adminSearch(query) {
            let results = query ? vm.allAdmins.filter(createFilterFor(query)) : [];
            return results;
        }
        ;
        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(contact) {
                return contact._loweremail.indexOf(lowercaseQuery) != -1 || contact._lowername.indexOf(lowercaseQuery) != -1;
            };
        }
        ;
        function loadContacts() {
            let contacts = administratorService.getAdminsFromOrg();
            //in case it wasn't already loaded by org home page
            if (contacts.length == 0) {
                let curEntity = vm.inviteOnly > 0 ? entityIds[0] : openEntityId;
                contacts = administratorService.getAllOrgAdminsNamesEmails(curEntity).then(response => {
                    //   contacts = administratorService.getAdminsFromOrg();
                    vm.allAdmins = filterContactsWithRoles(response);
                });    //    vm.allAdmins = filterContactsWithRoles(contacts);
                       //administratorService.getAllOrgAdmins()
                       //    .then(() => {
                       //        contacts = administratorService.getAdminsFromOrg();
                       //        vm.allAdmins = filterContactsWithRoles(contacts);
                       //    });
            } else {
                vm.allAdmins = filterContactsWithRoles(contacts);
            }
        }
        ;
        function filterContactsWithRoles(contacts) {
            for (let i = 0; i < vm.allEmails.length; i++) {
                for (let j = 0; j < contacts.length; j++) {
                    if (contacts[j].email == vm.allEmails[i]) {
                        contacts.splice(j, 1);
                    }
                }
            }
            return contacts.map(function (c, index) {
                let contact = {
                    name: c.firstName + ' ' + c.lastName,
                    email: c.email
                };
                contact.display = contact.name === ' ' ? contact.email : contact.name;
                contact.fullname = contact.name === ' ' ? contact.email : contact.name;
                contact._loweremail = contact.email.toLowerCase();
                contact._lowername = contact.name.toLowerCase();
                return contact;
            });
        }
        //watch for when the email input box is added to to check for invalid emails
        $scope.$watchCollection('vm.newAdmin.email', function () {
            if (vm.newAdmin.email && vm.newAdmin.email.length > 0) {
                let input = vm.newAdmin.email[vm.newAdmin.email.length - 1];
                //if type is not string then the chip was added by autocomplete
                if (typeof input == 'string') {
                    let pattern = /^.+@.+\..+$/;
                    let emailsRejected = '';
                    let emailsToAdd = [];
                    let invalid = false;
                    let emails = input.split(' ');
                    emails.forEach(email => {
                        if (!email.match(pattern)) {
                            invalid = true;
                            emailsRejected += ' ' + email;
                        } else {
                            emailsToAdd.push(email);
                        }
                    });
                    if (invalid || emails.length > 1) {
                        vm.newAdmin.email.splice(-1, 1);
                        vm.newAdmin.email = vm.newAdmin.email.concat(emailsToAdd);
                        if (invalid) {
                            $timeout(() => {
                                messageService.showErrorToast(`Please enter valid email`, angular.element('.invite-form_admin'));
                                vm.searchText = emailsRejected.trim();
                            });
                        }
                    }
                }
            }
        });
        //function validateEmail(chip){
        //    let pattern = /^.+@.+\..+$/;
        //    vm.newAdmin.invalidEmail = undefined;
        //    if (chip && (typeof chip == 'string')){
        //        let match = chip.match(pattern);
        //        if (chip.match(pattern)){
        //            return match[0];
        //        }
        //        else {
        //            messageService.showErrorToast(`Please enter valid email`, angular.element('.invite-form_admin'));
        //            vm.newAdmin.invalidEmail = chip
        //            return null;
        //            // event.stopPropagation();
        //        }
        //    } else if (chip && (typeof chip == 'object')){
        //        return chip;
        //    }
        //};
        // end chips
        function getTitle() {
            let last = groups.length - 1;
            if (groups.length === 0 && campaign === undefined) {
                return `${ org }  Administrators`;
            } else if (groups.length > 0 && campaign === undefined) {
                return `${ groups[0].name }  Administrators`;
            } else if (campaign !== undefined) {
                return ` ${ campaign }  Administrators`;
            }
        }
        function advancedInviteErrors(currentMessageService, response) {
            var msg = '';
            response.data.data.errors.forEach(error => {
                error.errorsList.forEach(entity => {
                    msg += error.key + ' could not be invited to ' + entityService.getEntityName(entity.errorMessage) + '.<br>';
                });
            });
            msg += 'All the other invites were successful.';
            messageService.showErrorDialog(msg);
        }
        function inviteNewAdmin() {
            vm.inviteInProgress = true;
            for (let i = 0; i < vm.newAdmin.email.length; i++) {
                if (typeof vm.newAdmin.email[i] === 'object') {
                    vm.newAdmin.email[i] = vm.newAdmin.email[i].email;
                }
            }
            let rolesOnEntitys = [];
            let currentEntity = undefined;
            //if entityIds.length is 0 - then showAdminModal event was called from campaigns.tpl.html for the entity that was open
            //else - it was called from activity.component.js for any amount of selected entities
            if (!entityIds.length) {
                currentEntity = openEntityId;
                rolesOnEntitys.push({
                    entityId: currentEntity,
                    roleId: vm.newAdmin.roleId
                });
            } else {
                entityIds.forEach(entityId => {
                    rolesOnEntitys.push({
                        entityId: entityId,
                        roleId: vm.newAdmin.roleId
                    });
                });
            }
            let messageConfig = {
                advancedErrorFunction: advancedInviteErrors,
                advancedErrorEnabled: true,
                successToastEnabled: true,
                successMsg: `Successfully invited ${ vm.newAdmin.email }`
            };
            administratorService.inviteAdmin(vm.newAdmin.email, rolesOnEntitys, currentEntity, entityIds, vm.newAdmin.message, messageConfig).then(data => {
                if (!vm.inviteOnly) {
                    let newAdmins = data.data.data;
                    newAdmins.forEach(a => {
                        a.currentRoleId = vm.newAdmin.roleId;
                        vm.admins.push(a);
                        //for reloading contacts to exclude these emails
                        vm.allEmails.push(a.email);
                    });
                    //scroll to bottom of list to show new additions
                    $timeout(() => {
                        var listDiv = document.getElementById('adminList');
                        listDiv.scrollTop = listDiv.scrollHeight;
                    });
                    loadContacts();
                }
                vm.newAdmin.email = [];
                vm.newAdmin.roleId = null;
            }).finally(msg => vm.inviteInProgress = false);
        }
        function removeAdmin(adminId, index) {
            vm.removeInProgress.index = index;
            administratorService.removeAdminFromEntity(adminId, openEntityId).then(admins => {
                vm.admins = admins;
            }).finally(() => vm.removeInProgress.index = null);
        }
        function addRemoveFlag(admin) {
            admin.remove = true;
        }
        function addRoleChanges(admin, currentRoleId) {
            let arg = [];
            arg.push({
                userId: admin.id,
                entityId: openEntityId,
                roleId: currentRoleId
            });
            administratorService.addAdminToCompanies(admin.email, openEntityId, currentRoleId).then(response => {
            }).finally(() => {
            });
        }
        function closeModal() {
            $mdDialog.hide();
        }
        function getCurrentEntity() {
            let openedCampaignId = orgService.getUrlCampaignId();
            let entityId = openedCampaignId ? campaignService.getCampaignById(openedCampaignId).entityId : entityService.getOpenedEntityId();
            return entityId;
        }
    }
}());
(function () {
    angular.module('app').component('modalMoveRecurring', {
        bindings: {
            show: '=',
            type: '<',
            onComplete: '&',
            unendedRecurringCount: '<',
            destinationOptions: '<',
            targetEvent: '<'
        },
        controller: ModalMoveRecurringCtrl,
        controllerAs: 'vm'
    });
    function ModalMoveRecurringCtrl($scope, $mdDialog, $rootScope) {
        const vm = this;
        vm.selectedDestinationCont = {};
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-move-recurring.tpl.html',
                controller: ModalMoveRecurringInnerCtrl,
                controllerAs: 'vm',
                openFrom: vm.targetEvent.currentTarget,
                closeTo: vm.targetEvent.currentTarget,
                locals: {
                    type: vm.type,
                    unendedRecurringCount: vm.unendedRecurringCount,
                    destinationOptions: vm.destinationOptions,
                    selectedDestinationCont: vm.selectedDestinationCont,
                    onComplete: vm.onComplete,
                    targetEvent: vm.targetEvent
                },
                clickOutsideToClose: false
            }).then(() => vm.onComplete({
                endClickEvent: vm.targetEvent,
                recurringPlanDestination: vm.selectedDestinationCont.selectedDestination
            })).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalMoveRecurringInnerCtrl($q, $mdDialog, $rootScope, campaignService, type, unendedRecurringCount, destinationOptions, selectedDestinationCont) {
        const vm = this;
        vm.type = type;
        vm.unendedRecurringCount = unendedRecurringCount;
        vm.destinationOptions = destinationOptions;
        vm.selectedDestinationCont = selectedDestinationCont;
        vm.showChangeOptions = showChangeOptions;
        vm.closeChangeOptions = closeChangeOptions;
        vm.next = next;
        vm.querySearch = querySearch;
        vm.closeModal = closeModal;
        vm.changing = false;
        function showChangeOptions() {
            vm.changing = true;
        }
        function closeChangeOptions() {
            vm.selectedDestinationCont.selectedDestination = null;
            vm.changing = false;
        }
        function next() {
            $mdDialog.hide();
        }
        function querySearch(query) {
            let results = query ? vm.destinationOptions.filter(createFilterFor(query)) : vm.destinationOptions;
            return results;
        }
        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(item) {
                return angular.lowercase(item.eventName).indexOf(lowercaseQuery) === 0 || item.eventID.toString().indexOf(lowercaseQuery) === 0 || (angular.lowercase(item.eventName) + ' (' + item.eventID + ')').indexOf(lowercaseQuery) === 0;
            };
        }
        ;
        function closeModal() {
            $mdDialog.cancel();
        }
    }
}());
(function () {
    angular.module('app').component('modalOrgInfo', {
        bindings: {
            show: '=',
            entityId: '=',
            org: '='
        },
        controller: ModalOrgInfoCtrl,
        controllerAs: 'vm'
    });
    function ModalOrgInfoCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-org-info.tpl.html',
                clickOutsideToClose: true,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    entityId: vm.entityId,
                    org: vm.org
                }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($mdDialog, $rootScope, $q, entityService, campaignService, orgService, entityId, org, settingsService, securityService) {
        const vm = this;
        vm.org = org;
        vm.entityId = entityId;
        vm.countries = [];
        vm.orgInfoFetching = false;
        vm.groups = [];
        vm.editFlag = {};
        vm.offEdits = offEdits;
        vm.cancel = closeModal;
        vm.saveOrg = saveOrg;
        vm.cancelEdit = cancelEdit;
        vm.checkHotKey = checkHotKey;
        vm.security = {};
        vm.enableEditField = enableEditField;
        loadInformation();
        function loadInformation() {
            vm.orgInfoFetching = true;
            const requests = [
                orgService.getOrgInfo().then(info => {
                    vm.info = info;
                    vm.previousInfo = angular.copy(vm.info);
                    vm.security = securityService.getAdminPermissions(entityService.getRawEntityById(vm.entityId), 'CAMPAIGN');
                }),
                settingsService.getCountries().then(countries => vm.countries = countries)
            ];
            $q.all(requests).finally(() => vm.orgInfoFetching = false);
        }
        function enableEditField(fieldName) {
            if (vm.security.EDIT) {
                vm.editFlag[fieldName] = true;
            }
        }
        function closeModal() {
            $mdDialog.cancel();
        }
        function saveOrg(field) {
            vm.saveInProgress = true;
            orgService.saveEditOrg(vm.org.orgId, field, vm.info[field]).then(res => {
                vm.previousInfo[field] = vm.info[field];
                vm.org.name = vm.info.name;
                vm.editFlag[field] = false;
            }).catch(error => {
                cancelEdit(field);
            }).finally(() => vm.saveInProgress = false);
        }
        function offEdits(field) {
            for (let f in vm.info) {
                if (f != field) {
                    cancelEdit(f);
                }
            }
        }
        function cancelEdit(field) {
            vm.info[field] = vm.previousInfo[field];
            vm.editFlag[field] = false;
        }
        function checkHotKey(event, field) {
            const ENTER = 13;
            let key = event.keyCode;
            if (key === ENTER)
                saveOrg(field);
        }
        ;
    }
}());
(function () {
    angular.module('app').component('modalRemoveGroup', {
        bindings: {
            show: '=',
            groupId: '='
        },
        controller: ModalRemoveGroupCtrl,
        controllerAs: 'vm'
    });
    function ModalRemoveGroupCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-remove-group.tpl.html',
                clickOutsideToClose: true,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: { groupId: vm.groupId }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($mdDialog, $rootScope, $route, $document, campaignService, entityService, orgService, groupId) {
        const vm = this;
        vm.remove = remove;
        vm.closeModal = closeModal;
        let group = campaignService.getGroupById(groupId), {
                entityId,
                parentGroupId: parent
            } = group;
        checkContent();
        ////////////////////////////////////
        function checkContent() {
            let groups = campaignService.getRawGroups();
            vm.containChildren = groups.some(item => item.parentGroupId == groupId);
            if (!vm.containChildren) {
                let campaigns = campaignService.getRawCampaigns();
                vm.containChildren = campaigns.some(item => item.groupId == groupId);
            }
        }
        function remove() {
            vm.removeInProgress = true;
            entityService.removeGroup(entityId).then(response => {
                campaignService.openGroup(parent, orgService.getOrg().entityId).then(campaignsData => {
                    $rootScope.$emit('tree.changed', campaignsData);
                });
                closeModal();
            }).finally(() => vm.removeInProgress = false);
        }
        function closeModal() {
            $mdDialog.hide();
        }
    }
}());
(function () {
    angular.module('app').component('modalUnarchiveCampaign', {
        bindings: {
            show: '=',
            unarchive: '=',
            eventGroupHost: '<'
        },
        controller: modalUnarchiveCampaignCtrl,
        controllerAs: 'vm'
    });
    function modalUnarchiveCampaignCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-unarchive-campaign.tpl.html',
                clickOutsideToClose: true,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: { eventGroupHost: vm.eventGroupHost }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($mdDialog, $route, $rootScope, $timeout, entityService, campaignService, orgService, messageService, eventGroupHost) {
        const vm = this;
        vm.saveInProgress = false;
        vm.entityId = orgService.getOrg().entityId;
        vm.entityIdParent = entityService.getOpenedEntityId();
        vm.eventGroupHost = eventGroupHost;
        vm.unarchiveCampaign = unarchiveCampaign;
        vm.cancel = closeModal;
        vm.validateSubdomain = validateSubdomain;
        vm.validSubdomain = false;
        vm.campaign = campaignService.getCampaignByEntityId(entityService.getOpenedEntityId());
        function unarchiveCampaign() {
            vm.validateSubdomain();
            if (!vm.validSubdomain) {
                document.getElementById('subDomainInput').focus();
                return;
            }
            vm.saveInProgress = true;
            let data = { urlPath: vm.subDomain };
            processCampaign(data);
        }
        function processCampaign(data) {
            let messageConfig = {
                advancedErrorFunction: messageService.advancedErrorsDialog,
                advancedErrorEnabled: true,
                successToastEnabled: !vm.unarchive,
                successMsg: 'Campaign reactivated successfully!'
            };
            let campaignId = orgService.getUrlCampaignId();
            let campaignEntityId = campaignService.getCampaignById(campaignId).entityId;
            campaignService.unarchiveCmp(campaignEntityId, data, messageConfig).then(() => {
                closeModal();
                entityService.refreshEntityAndComponent(campaignEntityId);
            }).finally(function () {
                vm.saveInProgress = false;
            });
        }
        function closeModal() {
            $mdDialog.hide();
        }
        function validateSubdomain() {
            let regex = /.*(\.|\/|\s).*/;
            if (String(vm.subDomain).match(regex)) {
                vm.validSubdomain = false;
                messageService.showErrorToast('Please do not use the "/", ".", or " " characters.');
                return;
            }
            regex = /^[a-zA-Z0-9-]*$/;
            if (!String(vm.subDomain).match(regex)) {
                vm.validSubdomain = false;
                messageService.showErrorToast('Only letters, numbers, and hyphens are valid for the url path.');
                return;
            }
            vm.validSubdomain = true;
        }
    }
}());
(function () {
    angular.module('app').component('pageNotFound', {
        templateUrl: 'page-not-found.tpl.html',
        controller: pageNotFoundController,
        controllerAs: 'vm'
    });
    function pageNotFoundController($rootScope, messageService) {
        const vm = this;
        $rootScope.$emit('groupOrCampaignNotFound');
    }
}());
(function () {
    angular.module('app').component('statistics', {
        templateUrl: 'statistics.tpl.html',
        controller: StatisticsController,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function StatisticsController($http, API_PREFIX, campaignService, orgService, messageService) {
        const vm = this;
        vm.orgId = orgService.getUrlOrgId();
        vm.csvDownloadPath = `/Org/${ vm.orgId }${ API_PREFIX }/GetGraphCsv`;
        vm.updatePlots = updatePlots;
        let allCampaigns = campaignService.getRawCampaigns();
        var statisticsIsLoaded = false;
        loadStatisticsToDOM();
        $('body').on('click', '#statisticsMainOuter', function (e) {
            var $target = $(e.target);
            var $clickedElm = $(e.currentTarget);
            if ($target.closest('.smallChartOuter').length) {
                switchReport($target.closest('.smallChartOuter'));
            }
        });
        function loadStatisticsToDOM() {
            if (statisticsIsLoaded) {
                return;
            }
            statisticsIsLoaded = true;
            var statisticsAllCampaignsDrop = $('#statisticsAllCampaignsDrop').empty().multiselect('destroy');
            allCampaigns.forEach(campaign => {
                statisticsAllCampaignsDrop.append('<option value="' + campaign.curEventId + '">' + campaign.name + (campaign.CampaignYear ? ' (' + campaign.CampaignYear + ')' : '') + '</option>');
            });
            $('#statisticsAllCampaignsDrop').multiselect({
                numberDisplayed: 1,
                buttonText: function () {
                    return 'Select campaigns';
                },
                buttonWidth: '100%'
            });
            loadPlot();
        }
        function removeActiveClass() {
            $('#globalNavMenu > li').removeClass('active');
        }
        //will update the plot to the selected date
        function updatePlots(returnCsv, reportType) {
            if (returnCsv) {
                messageService.showMsgDialog('Downloading...', '<b>Important!</b> Clicking stop or doing more admin work may interrupt the download.<br/>Hang on a minute while we do the heavy lifting.');
            } else {
                showSpinner($('#mainGraphholder'), true);
            }
            var data = {};
            var $graphReportCsvForm;
            if (returnCsv) {
                $graphReportCsvForm = $('#graphReportCsvForm').empty();
                $graphReportCsvForm.append('<input name="returnCsv" value="true"/>');
                $graphReportCsvForm.append('<input name="reports" value="' + reportType + '"/>');    //$graphReportCsvForm.append('<input name="org" value="' + orgService.getUrlOrgId() + '"/>');
            }
            var $datePickerContainer = $('#datePickerContainer');
            // startDate
            if (vm.startDate) {
                data.startDate = $.datepicker.formatDate('yy-mm-dd', vm.startDate);
                if (returnCsv) {
                    $graphReportCsvForm.append('<input name="startDate" value="' + data.startDate + '"/>');
                }
            }
            if (vm.endDate) {
                var endDateTime = vm.endDate;
                //endDateTime.setTime(new Date().getTime());
                data.endDate = $.datepicker.formatDate('yy-mm-dd', endDateTime) + ' 23:59:59';
                //console.log(endDateTimeElm.val())
                if (returnCsv) {
                    $graphReportCsvForm.append('<input name="endDate" value="' + data.endDate + '"/>');
                }
            }
            var events = '';
            var selectedElms = $('#statisticsAllCampaignsDrop').data('multiselect').getSelected();
            for (var i = 0; i < selectedElms.length; i++) {
                var selectedElm = selectedElms[i];
                events += ',' + selectedElm.value;
            }
            ;
            if (events.length > 1) {
                data.events = events.substring(1);
                if (returnCsv) {
                    $graphReportCsvForm.append('<input name="events" value="' + data.events + '" />');
                }
            }
            if (returnCsv) {
                downloadCsv($graphReportCsvForm);
            } else {
                loadPlot(data);
            }
        }
        //gets called when want to download the report as a csv
        function downloadCsv($form) {
            if (!$('#graphReportCsv').length)
                $('body').append('<iframe id="graphReportCsv" name="graphReportCsv" src="' + vm.csvDownloadPath + '" style="display: none;"></iframe>');
            $form.submit();
        }
        var allMetaNames = {};
        //maps the meta names for the reports
        function mapMetaNamesToIndex(reportDataObj) {
            for (var metaNamesObjKey in reportDataObj) {
                var metaNamesObj = JSON.parse(reportDataObj[metaNamesObjKey]);
                reportDataObj[metaNamesObjKey] = metaNamesObj;
                var names = metaNamesObj.metaNames;
                var metaData = {};
                for (var i = 0; i < names.length; i++) {
                    metaData[names[i]] = i;
                }
                ;
                allMetaNames[metaNamesObjKey] = metaData;
            }
        }
        //reformat the server data for plots
        function readyPeopleDataForPlot(metaNames, data) {
            var donor = [], walker = [], fundraiser = [];
            var totalDonerCount = 0, totalWalkersCount = 0, totalFundraiserCount = 0;
            metaNames = metaNames.people;
            data = data.people.data;
            for (var i = 0; i < data.length; i++) {
                var row = data[i];
                //We must use the $.datepicker instead of new Date in order for the graphes to work correctly in fireFox
                var date = $.datepicker.parseDate('yy-mm-dd', row[metaNames.Date]).getTime();
                var currentDonerCount = parseInt(row[metaNames.DonorCount]);
                donor.push([
                    date,
                    currentDonerCount
                ]);
                totalDonerCount += currentDonerCount;
                var currentWalkersCount = parseInt(row[metaNames.WalkerCount]);
                walker.push([
                    date,
                    currentWalkersCount
                ]);
                totalWalkersCount += currentWalkersCount;
                var currentFundraiserCount = parseInt(row[metaNames.FundraiserCount]);
                fundraiser.push([
                    date,
                    currentFundraiserCount
                ]);
                totalFundraiserCount += currentFundraiserCount;
            }
            ;
            return {
                reportInfo: {
                    donor: donor,
                    walker: walker,
                    fundraiser: fundraiser
                },
                counts: {
                    totalDonerCount: totalDonerCount,
                    totalWalkersCount: totalWalkersCount,
                    totalFundraiserCount: totalFundraiserCount
                }
            };
        }
        //reformat the server data for plots
        function readyFundsDataForPlot(metaNames, data, totalAmountBeforeStartDate) {
            var donationAmount = [], donationCount = [], runningSumDonation = [];
            metaNames = metaNames.funds;
            data = data.funds.data;
            var currentTotal = totalAmountBeforeStartDate;
            for (var i = 0; i < data.length; i++) {
                var row = data[i];
                //We must use the $.datepicker instead of new Date in order for the graphes to work correctly in fireFox
                var date = $.datepicker.parseDate('yy-mm-dd', row[metaNames.Date]).getTime();
                var todaysTotal = parseFloat(row[metaNames.DonationsAmount]);
                donationAmount.push([
                    date,
                    todaysTotal
                ]);
                donationCount.push([
                    date,
                    parseInt(row[metaNames.DonationsCount])
                ]);
                currentTotal = Math.round((currentTotal + todaysTotal) * 100) / 100;
                runningSumDonation.push([
                    date,
                    currentTotal
                ]);
            }
            ;
            return {
                reportInfo: {
                    donationAmount: donationAmount,
                    donationCount: donationCount,
                    runningSumDonation: runningSumDonation
                }
            };
        }
        //plot config
        var FlotConfig = function () {
            this.legend = {
                show: true,
                noColumns: 0
            };
            this.xaxis = { mode: 'time' };
            this.yaxis = {};
            this.grid = {
                borderWidth: 0,
                hoverable: true,
                clickable: true
            };
            // this.zoom = { interactive: true };
            this.series = {};
        };
        //plot pie config
        var FlotPieConfig = function () {
            this.legend = { show: true };
            this.xaxis = { mode: 'time' };
            this.yaxis = {};
            this.grid = { borderWidth: 0 };
            this.series = {
                pie: {
                    show: true,
                    radius: 1,
                    lable: {
                        radius: 2 / 3,
                        formatter: function (label, series) {
                            return '<div style="font-size: 8pt; text-align: center; padding: 2px; color: #fff ;">' + label + '<br/>' + series.data[0][1] + '</div>';
                        },
                        threshold: 0.1
                    }
                }
            };
            this.legend = { show: false };
        };
        //write/drow plot
        function loadPeoplePlots(peopleData) {
            var peopleChartData = [
                {
                    label: 'Donors',
                    data: peopleData.reportInfo.donor,
                    color: '#edc487'
                },
                {
                    label: 'Attendees',
                    data: peopleData.reportInfo.walker,
                    color: '#3a97cb'
                },
                {
                    label: 'Fundraisers',
                    data: peopleData.reportInfo.fundraiser,
                    color: '#79ceba'
                }
            ];
            var peopleOptions = new FlotConfig();
            var chartElms = createReportContainer(reportTypes.PlatformPeople, peopleData);
            peopleOptions.series = {
                stack: 0,
                lines: {
                    show: true,
                    fill: true,
                    steps: false
                },
                bars: {
                    show: false,
                    align: 'center',
                    barWidth: 12 * 24 * 60 * 60 * 450,
                    order: 1,
                    series_spread: true
                }
            };
            peopleOptions.xaxis = { mode: 'time' };
            peopleOptions.tooltip = true;
            peopleOptions.tooltipOpts = {
                content: '%s: %y',
                shifts: {
                    x: -60,
                    y: 25
                }
            };
            chartElms.$bigChartOuter.show();
            $.plot(chartElms.$bigChartOuter.children('.bigChart'), peopleChartData, peopleOptions);
            chartElms.$bigChartOuter.hide();
            peopleOptions.xaxis = { show: false };
            peopleOptions.yaxis = { show: false };
            peopleOptions.legend = { show: false };
            peopleOptions.tooltip = false;
            $.plot(chartElms.$smallChartOuter.children('.smallChart'), peopleChartData, peopleOptions);
            var peoplePieData = [
                {
                    label: 'Donors',
                    data: peopleData.counts.totalDonerCount
                },
                {
                    label: 'Attendees',
                    data: peopleData.counts.totalWalkersCount
                },
                {
                    label: 'Fundraisers',
                    data: peopleData.counts.totalFundraiserCount
                }
            ];
            var peoplePieOptions = new FlotPieConfig();    //plot = $.plot( $peopleChartOuter.children('.pieChart'), peoplePieData, peoplePieOptions);
        }
        function loadFundsPlots(fundsData) {
            var fundsChartData = [
                {
                    label: 'Donation count',
                    data: fundsData.reportInfo.donationCount,
                    yaxis: 1,
                    lines: { show: true }
                },
                {
                    label: 'Donation running total',
                    data: fundsData.reportInfo.runningSumDonation,
                    yaxis: 2,
                    lines: {
                        show: true,
                        fill: true
                    }
                },
                {
                    label: 'Donation amount',
                    data: fundsData.reportInfo.donationAmount,
                    yaxis: 2,
                    lines: { show: true }
                }
            ];
            var fundsOptions = new FlotConfig();
            fundsOptions.yaxes = [
                {
                    min: 0,
                    position: 'right'
                },
                {
                    min: 0,
                    position: 'left',
                    tickFormatter: function (val, axis) {
                        return '$' + numberWithCommas(val, true);
                    }
                }
            ];
            fundsOptions.tooltip = true;
            fundsOptions.tooltipOpts = {
                content: '%s: %y',
                shifts: {
                    x: -60,
                    y: 25
                }
            };
            var chartElms = createReportContainer(reportTypes.PlatformDonations, fundsData);
            chartElms.$bigChartOuter.show();
            $.plot(chartElms.$bigChartOuter.children('.bigChart'), fundsChartData, fundsOptions);
            chartElms.$bigChartOuter.hide();
            fundsOptions.xaxis = { show: false };
            fundsOptions.yaxis = { show: false };
            fundsOptions.legend = { show: false };
            fundsOptions.tooltip = false;
            $.plot(chartElms.$smallChartOuter.children('.smallChart'), fundsChartData, fundsOptions);
        }
        var reportTypes = {
            PlatformPeople: 1,
            PlatformDonations: 2
        };
        var allReportsElms = {};
        var reportNames = {
            '1': 'People',
            '2': 'Donations'
        };
        var openReport;
        //will create the report elms big and small and the btm sections.
        function createReportContainer(reportType, reportData) {
            var bottomItms;
            if (reportData && reportData.counts) {
                var $bottomAllCounts = $('#bottomAllCounts');
                $bottomAllCounts.empty();
                createAndLoadReportBottom($bottomAllCounts, reportData.counts, reportType);
                bottomItms = $bottomAllCounts.children();
            }
            //if we already created this report just return the outer if not create a new one
            if (allReportsElms[reportType]) {
                if (bottomItms)
                    allReportsElms[reportType].$bottomItems = bottomItms;
                return allReportsElms[reportType];
            }
            var $mainGraphholder = $('#mainGraphholder'), $graphSidePanel = $('#graphSidePanel'), chartElms = {};
            chartElms.$bottomItems = bottomItms;
            if (reportData.reportInfo) {
                // $reportOuter.append('<div class="legend" />');
                chartElms.$bigChartOuter = $('<div id="reportBigChartOuter-' + reportType + '" class="bigChartOuter"/>');
                var $nameDownloadRow = $('<div class="container-fluid row"/>').appendTo(chartElms.$bigChartOuter);
                $('<div class="bigChartName col-xs-6 text-left"/>').html(reportNames[reportType]).appendTo($nameDownloadRow);
                $('<div class="bigDownLoad-' + reportType + ' col-xs-6 text-right"/>').html('<a href="#" onclick="angular.element(event.target).scope().vm.updatePlots(true, ' + reportType + ')" class="btn btn-download btn-sm downloadCsv">Download</a>').appendTo($nameDownloadRow);
                $('<div class="bigChart"/>').appendTo(chartElms.$bigChartOuter);
                chartElms.$bigChartOuter.appendTo($mainGraphholder);
                chartElms.$smallChartOuter = $('<div id="reportsmallChartOuter-' + reportType + '" class="smallChartOuter"/>');
                var $smallChartHover = $('<div class="smallChartHover graphHoverImage"/>');
                $smallChartHover.appendTo(chartElms.$smallChartOuter);
                $('<div class="smallReportsName"/>').html(reportNames[reportType]).appendTo(chartElms.$smallChartOuter);
                $('<div class="smallChart"/>').appendTo(chartElms.$smallChartOuter);
                chartElms.$smallChartOuter.appendTo($graphSidePanel);
            }
            allReportsElms[reportType] = chartElms;
            return chartElms;
        }
        var reportCountsName = {};
        reportCountsName[reportTypes.PlatformPeople] = {
            totalDonerCount: 'Donors',
            totalWalkersCount: 'Attendees',
            totalFundraiserCount: 'Fundraisers'
        };
        //load the report btm
        function createAndLoadReportBottom($outerElm, countsDict, reportType) {
            var countNames = reportCountsName[reportType];
            var $reportCount = $('<div class="reportCount graphBottom" />');
            for (var countKey in countsDict) {
                var $newReportCount = $reportCount.clone();
                $('<div class="reportCountsName smallReportsName"/>').html(numberWithCommas(countNames[countKey])).appendTo($newReportCount);
                $('<div class="ReportCountsCount" />').html(numberWithCommas(countsDict[countKey])).appendTo($newReportCount);
                $outerElm.append($newReportCount);
            }
        }
        // once the report is loaded we switch the main report to the one clicked
        function switchReport($clickedElm) {
            var id = $clickedElm.attr('id');
            var reportType = id.substring(id.indexOf('-') + 1);
            if (reportType == getOpenReport()) {
                return;
            }
            var chartElms = createReportContainer(reportType);
            var $mainGraphholder = $('#mainGraphholder').children('#reportBigChartOuter-' + getOpenReport()).stop(true, true).fadeOut(function () {
                chartElms.$bigChartOuter.stop(true, true).fadeIn();
                var $bottomAllCounts = $('#bottomAllCounts');
                $bottomAllCounts.children().detach();
                if (chartElms.$bottomItems) {
                    $bottomAllCounts.append(chartElms.$bottomItems);
                }
                openReport = reportType;
            });
        }
        // returns the report thats open
        function getOpenReport() {
            return openReport ? openReport : reportTypes.PlatformPeople;
        }
        // makes the request to the server to get flot data.
        function loadPlot(data) {
            if (!data) {
                data = {};
            }
            $http({
                url: API_PREFIX + '/GetGraphReports',
                data: data,
                method: 'POST'
            }).then(response => {
                let resp = response.data;
                if (resp.reportData) {
                    mapMetaNamesToIndex(resp.reportData);
                    //prepare server from data.
                    var peopleData = readyPeopleDataForPlot(allMetaNames, resp.reportData);
                    var fundsData = readyFundsDataForPlot(allMetaNames, resp.reportData, resp.fundsTotalBeforeStartDate);
                    //load prepared data.
                    loadPeoplePlots(peopleData);
                    loadFundsPlots(fundsData);
                    //show the correct report.
                    var reportToShow = openReport ? allReportsElms[openReport] : allReportsElms[reportTypes.PlatformPeople];
                    reportToShow.$bigChartOuter.show();
                    return;
                }
            }).finally(() => hideSpinner());
        }
        //will show spinner. depending on the background pass in color
        function showSpinner($elmForSpinner, white) {
            // $elmForSpinner.append('<div id="showingSpinner" class="loader' + (white ? '' : 'Not' ) + 'White" />');
            $elmForSpinner.append('<div id="showingSpinner" class="loading-indicator"><div class="loader"><div class="dot1"></div><div class="dot2"></div><div class="dot3"></div></div></div>');
            $('body').addClass('backdrop');
        }
        //will hide the open spinner
        function hideSpinner() {
            $('#showingSpinner').remove();
            $('body').removeClass('backdrop');
        }
    }
}());
(function () {
    angular.module('app').component('adminTemplates', {
        controller: adminTemplatesController,
        bindings: { $router: '<' },
        require: { parentComponent: '^email' },
        templateUrl: 'admin-templates.tpl.html',
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function adminTemplatesController($mdDialog, $controller, $q, $scope, orgService, entityService, emailService, securityService) {
        const vm = this;
        var entity = entityService.getOpenEntity();
        vm.configOptionId = emailService.EMAIL_CONFIG_OPTIONS.adminTemplates;
        vm.entityType = entity.entityType;
        vm.security = securityService.getAdminPermissions(entity, 'EMAIL_TEMPLATES');
        vm.loading = true;
        vm.getData = function () {
            vm.dataRequest().then(r => {
                vm.grid = r.items;
                vm.newItem = r.newItem;
            }).catch(() => {
            }).finally(() => vm.loading = false);
        };
        vm.dataRequest = function () {
            return emailService.getAdminEmailTemplates();
        };
        orgService.orgInfoLoadedPromise.then(r => {
            vm.getData();
        });
        vm.showActivateOptions = (item, forTextDisplay) => emailService.showActivateOptions(item, forTextDisplay);
        vm.editorButtons = emailService.getEmailEditorButtons();
        vm.clicked = function (item) {
            return vm.saveEmail(item).then(r => {
                item.Id = r.item.Id;
            });
        };
        vm.open = function (item) {
            emailService.openEmail(item);
        };
        vm.overrideParent = function (item) {
            return vm.saveEmail(item).then(r => {
                return r.item;
            });
        };
        vm.delete = function (item) {
            return emailService.deleteEmailTemplate(item);
        };
        vm.saveEmail = function (item) {
            return emailService.createOrUpdateEmailTemplate(item);
        };
        vm.adoptParent = function (item) {
            return emailService.deleteEmailTemplate(item).then(r => {
                return r.item;
            });
        };
        $scope.$on('clearOverrides', function (event, item) {
            if (item.configOptionId === vm.configOptionId) {
                return emailService.clearEmailTemplateOverrides(item);
            }
        });
    }
}());
(function () {
    angular.module('app').component('autoEmail', {
        controller: adminTemplatesController,
        bindings: { $router: '<' },
        require: { parentComponent: '^email' },
        templateUrl: 'auto-email.tpl.html',
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function adminTemplatesController($mdDialog, $controller, $q, $scope, orgService, entityService, emailService, securityService) {
        const vm = this;
        var entity = entityService.getOpenEntity();
        vm.configOptionId = emailService.EMAIL_CONFIG_OPTIONS.transactionEmails;
        vm.entityType = entity.entityType;
        vm.security = securityService.getAdminPermissions(entity, 'AUTO_RESPONDERS');
        vm.loading = true;
        vm.getData = function () {
            vm.dataRequest().then(r => {
                vm.grids = r.items;
            }).catch(() => {
            }).finally(() => vm.loading = false);
        };
        vm.dataRequest = function () {
            return emailService.getTransactionalEmailTemplates();
        };
        orgService.orgInfoLoadedPromise.then(r => {
            vm.getData();
        });
        vm.showActivateOptions = (item, forTextDisplay) => emailService.showActivateOptions(item, forTextDisplay);
        vm.editorButtons = emailService.getEmailEditorButtons();
        vm.beforeOpenCard = function () {
            return $q.all(_.reduce(vm.grids, (result, value, key) => {
                if (value.saveAndCloseOpenCard) {
                    result.push(value.saveAndCloseOpenCard());
                }
                return result;
            }, []));
        };
        vm.clicked = function (item) {
            return vm.saveEmail(item, false);
        };
        vm.open = function (item) {
            emailService.openEmail(item);
        };
        vm.overrideParent = function (item) {
            return vm.saveEmail(item).then(r => {
                return r.item;
            });
        };
        vm.saveEmail = function (item) {
            return emailService.createOrUpdateEmailTemplate(item);
        };
        vm.adoptParent = function (item) {
            return emailService.deleteEmailTemplate(item).then(r => {
                return r.item;
            });
        };
        $scope.$on('clearOverrides', function (event, item) {
            if (item.configOptionId === vm.configOptionId) {
                return emailService.clearEmailTemplateOverrides(item);
            }
        });
    }
}());
(function () {
    angular.module('app').component('composeEmailModal', {
        bindings: {
            showModal: '=',
            targetEvent: '<'
        },
        controller: composeEmailModalCtrl,
        controllerAs: 'vm'
    });
    function composeEmailModalCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.showModal', () => {
            if (vm.showModal) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'compose-email-modal.tpl.html',
                escapeToClose: false,
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                multiple: true,
                targetEvent: vm.targetEvent,
                locals: { parent: $scope }
            }).catch(() => {
            }).finally(() => {
                vm.showModal = false;
            });
        }
    }
    function ModalContentCtrl($rootScope, $scope, $element, $mdDialog, emailService, messageService, parent, formService, entityService, securityService) {
        const vm = this;
        vm.email = undefined;
        vm.form = {};
        vm.security = {};
        vm.entity = entityService.getOpenEntity();
        vm.security.emailTemplates = securityService.getAdminPermissions(vm.entity, 'EMAIL_TEMPLATES');
        vm.groups = {
            RECIPIENTS: 0,
            CONTENT: 1,
            PREVIEW: 2
        };
        emailService.getComposeEmailForm().then(r => {
            vm.email = r.item;
            vm.email.InPopup = true;
            emailService.openEmail(vm.email);
            emailService.addComposeEmailLogic(vm.email);
            let contactsField = formService.getFieldFromItem(vm.email, 'Individual Recipients');
            contactsField.value = {
                members: [],
                contacts: []
            };
            if (window.sessionStorage['selectedContacts']) {
                //TODO: set contact session and member session separately. 
                //Pre this build, contacts would not be selected in overlapping id case, currently both will be selected
                contactsField.value.members = window.sessionStorage['selectedContacts'];
                contactsField.value.contacts = window.sessionStorage['selectedContacts'];
                formService.setFieldValueOnItem(vm.email, 'Recipients', 'Individual');
                window.sessionStorage.removeItem('selectedContacts');
            }
            contactsField.loaded = true;
        });
        vm.editorButtons = [
            emailService.getEmailEditorOptionsButton(),
            {
                label: 'Preview',
                icon: 'preview',
                toolbar: 'floating',
                action: (item, evnt) => nextGroup()
            }
        ];
        vm.cancel = closeModal;
        vm.saveAsTemplate = saveAsTemplate;
        vm.sendEmailNow = sendEmailNow;
        vm.scheduleEmail = scheduleEmail;
        vm.nextGroup = nextGroup;
        function saveAsTemplate() {
            emailService.showSaveAsEmailTemplateDialog(vm.email);
        }
        function sendEmailNow() {
            return sendEmail(undefined).then(closeModal, angular.noop);    // Pass no date to send now
        }
        function sendEmail(date, scheduled) {
            formService.setFieldValueOnItem(vm.email, 'Scheduled For', date);
            return emailService.createBlast(vm.email, scheduled).then(notifyScopeOfNewEmail);
        }
        function notifyScopeOfNewEmail() {
            $rootScope.$broadcast('NewEmailBlastComposed');
        }
        function scheduleEmail() {
            var prompt = $mdDialog.prompt({
                bindToController: true,
                locals: {
                    inputType: 'datetime-local',
                    required: true
                }
            }).multiple(true).title('Schedule Email').htmlContent(`Enter a date and time to send out the email:`).targetEvent(event).ok('Schedule').cancel('Cancel');
            $mdDialog.show(prompt).then(newDate => sendEmail(newDate, true).then(closeModal).catch(angular.noop)).catch(angular.noop);
        }
        function closeModal() {
            $mdDialog.hide();
        }
        function nextGroup() {
            $scope.$broadcast('nextGroup');
        }
        $scope.$watch('vm.form.$valid', function (valid) {
            if (!valid) {
                $scope.$broadcast('markGroupIncomplete', vm.form.activeGroup);
            }
        });
        $scope.$on('closeModal', function (event) {
            closeModal();
        });
    }
}());
(function () {
    var routeConfig = [
        {
            path: '/',
            name: 'EmailRouter',
            component: 'emailRouter',
            data: {
                dontRoute: true,
                showFunc: function () {
                    return false;
                }
            },
            useAsDefault: true
        },
        {
            path: '/automatedEmails',
            name: 'Automated Emails',
            component: 'autoEmail',
            data: { resource: 'AUTO_RESPONDERS' }
        },
        {
            path: '/customEmails',
            name: 'Custom Emails',
            component: 'triggerEmails',
            data: { resource: 'TRIGGERS' }
        },
        {
            path: '/adminTemplates',
            name: 'Admin Templates',
            component: 'adminTemplates',
            data: { resource: 'EMAIL_TEMPLATES' }
        },
        {
            path: '/outbox',
            name: 'Outbox',
            component: 'outbox',
            data: {
                resource: 'EMAIL_PENDING_EMAILS',
                limitToCampaign: true
            }
        },
        {
            path: '/sentAutoEmail',
            name: 'Sent Automated Emails',
            component: 'sentAutoEmail',
            data: {
                resource: 'EMAIL_SENT_EMAIL_SYSTEM',
                limitToCampaign: true,
                allowOnInstance: true
            }
        },
        {
            path: '/sentAdminEmail',
            name: 'Sent Admin Emails',
            component: 'sentAdminEmail',
            data: {
                resource: 'EMAIL_SENT_EMAIL_BLAST',
                limitToCampaign: true
            }
        },
        {
            path: '/emailDesigner',
            name: 'Email Designer',
            component: 'emailDesigner',
            data: { resource: 'EMAIL_HEADER_FOOTER' }
        },
        {
            path: '/pdfAttachment',
            name: 'PDF Receipt',
            component: 'pdfAttachment',
            data: {
                resource: 'PDF_RECEIPT_TEMPLATE',
                routeFunc: function (entity) {
                    return entity.settings.HasPdfReceiptingEnabled;
                }
            }
        },
        {
            path: '/emailAdvanced',
            name: 'Advanced',
            component: 'emailAdvanced',
            data: { anyResource: ['EMAIL_ADVANCED_OPTIONS'] }
        }
    ];
    angular.module('app').component('email', {
        templateUrl: 'email.tpl.html',
        controllerAs: 'vm',
        require: { campaigns: '^campaigns' },
        bindings: { $router: '<' },
        controller: emailController,
        $routeConfig: routeConfig,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    angular.module('app').constant('emailRouteConfig', routeConfig);
    function emailController($rootScope, routingService, entityService, orgService, extJsLoader, iframeMessageHandler, $rootRouter, securityService) {
        const vm = this;
        var entity = entityService.getOpenEntity();
        vm.entityType = entity.entityType;
        vm.loading = true;
        vm.navs = routeConfig;
        vm.showComposeModal = false;
        $rootScope.$on('addNewSelected', ($event, targetEvent) => {
            vm.showComposeModal = true;
            vm.composeModalTargetEvent = targetEvent;
        });
        vm.$onInit = function () {
            if (vm.entityType === 'Campaign') {
                let security = securityService.getAdminPermissions(entity, 'EMAIL_SEND_EMAILS');
                vm.campaigns.updateShowAddBtn(security.EDIT, 'composeEmail', true, 'Compose Email', 'feather');
            }
        };
        this.$routerOnDeactivate = function (p, n) {
            vm.campaigns.updateShowAddBtn(false, 'campaign');
        };
        routingService.updateRouting().then(r => {
            vm.openEntity = r;
            entityService.getEntitySettings(r).then(entity => {
                vm.onGroup = entity.entityType === 'CampaignGroup';
                vm.onOrg = entity.entityType === 'Org';
                vm.loading = false;
            });
        });
    }
}());
(function () {
    angular.module('app').component('emailPreviewOptions', {
        controller: emailPreviewOptionsController,
        bindings: {
            email: '<',
            actions: '=',
            canShowPreview: '=?'
        },
        templateUrl: 'email-preview-options.tpl.html',
        controllerAs: 'vm'
    });
    function emailPreviewOptionsController(emailService, placeholdersService, profileService, entityService, formService) {
        const vm = this;
        vm.updatePreviewOnChange = true;
        vm.loading = true;
        vm.campaignId = undefined;
        vm.actions = [];
        vm.placeholderSampleObjects = {};
        vm.placeholderConditions = {
            Subject: {},
            Body: {}
        };
        vm.previewOptions = [];
        vm.getEmail = function () {
            let email = _.cloneDeep(vm.email);
            vm.replaceConditionalPlaceholders(email);
            return email;
        };
        vm.hasPreviewOptions = () => vm.hasAnyConditionalPlaceholders() || vm.hasAnyPlaceholderSampleObjects() || vm.campaignsLength > 1;
        vm.hasAnyConditionalPlaceholders = () => _.every(vm.placeholderConditions, x => x.length === 0) === false;
        vm.hasAnyPlaceholderSampleObjects = () => _.size(vm.placeholderSampleObjects) !== 0;
        vm.replaceConditionalPlaceholders = function (email) {
            _.forEach(vm.placeholderConditions, (placeholderCondition, fieldName) => {
                let field = formService.getFieldFromItem(email, fieldName);
                if (field) {
                    field.value = placeholdersService.replaceConditionalPlaceholders(field.value, placeholderCondition);
                }
            });
        };
        vm.loadPreview = function () {
            vm.loading = true;
            return emailService.getEmailPreview(vm.getEmail(), vm.getPlaceholderSampleObjectsSelection(), vm.campaignId).then(r => {
                vm.errors = undefined;
                vm.previewBody = r.body;
                vm.previewSubject = r.subject;
                if (vm.previewOptions && vm.previewOptions.$setPristine) {
                    vm.previewOptions.$setPristine();
                }
            }).catch(r => {
                if (r.data) {
                    vm.errors = r.data.errors;
                } else {
                    vm.errors = [{ error: 'Could not load preview, please try again.' }];
                }
            }).finally(() => {
                vm.loading = false;
            });
        };
        vm.sendTest = function (adminEmail) {
            return emailService.sendTestEmail(vm.getEmail(), vm.getPlaceholderSampleObjectsSelection(), vm.campaignId, adminEmail);
        };
        vm.getPlaceholderSampleObjectsSelection = function () {
            return placeholdersService.getPlaceholderSampleObjectsSelection(vm.placeholderSampleObjects);
        };
        vm.previewOptionsChanged = function () {
            if (vm.updatePreviewOnChange) {
                vm.loadPreview();
            }
        };
        vm.resetCondition = function (condition) {
            condition.checked = undefined;
            vm.previewOptionsChanged();
        };
        vm.loadAllPlaceholdersSampleObjects = function () {
            vm.placeholderSampleObjects = {};
            let eventId = vm.eventId();
            let emailContent = formService.getFieldValueFromItem(vm.email, 'Body') + formService.getFieldValueFromItem(vm.email, 'Subject');
            let donationLabel = (donation, date) => `\$${ numberWithCommas(donation.Amount) } by ${ donation.DonorFirstName } ${ donation.DonorLastName } on ${ date.split(' ')[0] }`;
            let memberMap = r => _.map(r.items, member => ({
                id: member.MemberID,
                label: `${ member.firstName } ${ member.lastName }`
            }));
            let sampleObjectConfig = [
                {
                    placeholder: 'Donation',
                    endpoint: 'Donation/AllTransaction',
                    map: r => _.map(r.donations, donation => ({
                        id: donation.DonationID,
                        label: donationLabel(donation, donation.DonationDate)
                    }))
                },
                {
                    placeholder: 'Recurring_Donation',
                    endpoint: 'Donation/AllRecurringTransaction',
                    map: r => _.map(r.recurringDonations, recurringDonation => ({
                        id: recurringDonation.DonationRecurringID,
                        label: donationLabel(recurringDonation, recurringDonation.DonationRecurringDate)
                    }))
                },
                {
                    placeholder: 'Team',
                    endpoint: 'Team/AllTeams',
                    map: r => _.map(r.items, team => ({
                        id: team.TeamID,
                        label: team.TeamName
                    })),
                    sort: 'Established',
                    sortDir: 'DESC'
                },
                {
                    placeholders: [
                        'Registrant',
                        'Primary_Registrant',
                        'Fundraiser',
                        'User',
                        'Registered_By'
                    ],
                    endpoint: 'Member/AllRegistrants',
                    map: memberMap,
                    sort: 'RegisteredOnAsString',
                    sortDir: 'DESC'
                },
                {
                    placeholder: 'Captain',
                    endpoint: 'Member/AllMembersSimple',
                    map: memberMap,
                    filters: [{
                            value: true,
                            field: 'IsTeamCaptain'
                        }]
                },
                {
                    placeholder: 'Donor',
                    endpoint: 'Member/AllDonors',
                    map: memberMap
                }
            ];
            _.forEach(sampleObjectConfig, config => {
                if (config.placeholder) {
                    config.placeholders = [config.placeholder];
                }
                let req = () => emailService.getEmailSampleObjects(config.endpoint, eventId, config.filters, config.sort, config.sortDir);
                let sampleObjects = placeholdersService.loadPlaceholderSampleObjects(req, config.placeholders, config.map, emailContent);
                _.assign(vm.placeholderSampleObjects, sampleObjects);
            });
        };
        vm.campaignChanged = function () {
            vm.previewOptionsChanged();
            vm.loadAllPlaceholdersSampleObjects();
        };
        vm.eventId = function () {
            return vm.campaigns.find(v => v.CampaignID = vm.campaignId).EventID;
        };
        vm.getConditionsFromPlaceholders = function () {
            vm.placeholderConditions = _.mapValues(vm.placeholderConditions, (_, fieldName) => {
                let source = formService.getFieldValueFromItem(vm.email, fieldName);
                return placeholdersService.getConditionsFromPlaceholders(source);
            });
        };
        vm.$onInit = function () {
            return entityService.getEntityCampaigns(entityService.getOpenedEntityId(), true).then(campaigns => {
                vm.campaigns = campaigns;
                vm.campaignsLength = vm.campaigns.length;
                vm.canShowPreview = vm.campaignsLength > 0;
                vm.email = formService.getBaseFormItem(vm.email);
                // Ensure email object is ready to send to server and has latest changes from source editor
                if (vm.canShowPreview) {
                    vm.campaignId = vm.campaigns[0].CampaignID;
                    vm.loadPreview();
                    vm.getConditionsFromPlaceholders();
                    vm.loadAllPlaceholdersSampleObjects();
                    profileService.getProfileInfo().then(profileInfo => {
                        let adminEmail = profileInfo.userEmail;
                        vm.actions = [{
                                label: 'Send Test',
                                action: (item, $event) => vm.sendTest(adminEmail),
                                useLoader: true,
                                tooltip: `Send test email to ${ adminEmail }. Dynamic content in the test email will be populated as selected and displayed here.`
                            }];
                    });
                }
            });
        };
    }
}());
(function () {
    angular.module('app').component('emailAdvanced', {
        controllerAs: 'vm',
        controller: emailAdvancedController,
        require: { parentComponent: '^email' },
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        },
        template: `<div ng-if="vm.emailUnverified" class="warning-block md-box-shadow" style="margin-bottom: 50px">
                The domain <strong>{{vm.emailDomain}}</strong> is not verified to send emails. Any email addresses from that domain will be used as the "Reply To" email address, not the "From" email address. 
                <span ng-if="vm.hasEmailDomainsPermission"><a ng-href="/org/{{vm.orgId}}/organization/email-domains">Click here</a> to manage your email domain verification</span>
                <span ng-if="!vm.hasEmailDomainsPermission">Contact your administrator to verify the domain</span>
            </div>
            <form-template
               form-sections='vm.sections'
               on-update='vm.onChange'></form-template>`
    });
    function emailAdvancedController(configSettingsService, securityService, orgService, emailDomainsService) {
        const vm = this;
        const ADMIN_FROM_EMAIL_CONFIG_ID = 260;
        const ADVANCED_EMAIL_CONFIG_SECTION_ID = 12;
        vm.sections = [];
        vm.emailUnverified = false;
        vm.emailDomain = undefined;
        vm.orgId = orgService.getOrgId();
        vm.hasEmailDomainsPermission = securityService.getAdminPermissions(orgService.getOrg(), 'EMAIL_ADVANCED_OPTIONS').DELETE;
        configSettingsService.getEmailAdvancedSettings().then(function (r) {
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
                vm.checkEmailDomainVerified(vm.getAdminEmailFrom(vm.sections));
            } else {
            }
        });
        vm.onChange = function (config) {
            if (config.configId == ADMIN_FROM_EMAIL_CONFIG_ID) {
                // Admin From Email Address
                vm.checkEmailDomainVerified(config.value);
            }
            return configSettingsService.updateSetting(config);
        };
        vm.getAdminEmailFrom = function (sections) {
            var section = vm.sections.find(x => x.ConfigSectionId = ADVANCED_EMAIL_CONFIG_SECTION_ID);
            if (!section || !section.Fields)
                return;
            var emailField = section.Fields.find(x => x.SettingId == ADMIN_FROM_EMAIL_CONFIG_ID);
            if (!emailField)
                return;
            return emailField.Value;
        };
        vm.checkEmailDomainVerified = function (email) {
            if (!email)
                return;
            if (!email.includes('@'))
                return;
            var domain = email.split('@')[1];
            vm.emailDomain = domain;
            emailDomainsService.getEmailDomainStatus(domain).then(verified => vm.emailUnverified = !verified);
        };
    }
}());
(function () {
    angular.module('app').component('emailDesigner', {
        controller: emailDesignerController,
        require: { parentComponent: '^email' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        },
        template: `<form-template
                 loading="vm.loading"
                 form-sections='vm.sections'
                 on-update='vm.onChange'
                 on-field-init="vm.onFieldInit"></form-template>`
    });
    function emailDesignerController(configSettingsService, emailService) {
        const vm = this;
        vm.sections = [];
        vm.loading = true;
        vm.advanced = false;
        configSettingsService.getEmailHeaderFooterSettings().then(function (r) {
            vm.loading = false;
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
                vm.sections[0].HideHeader = true;
            }
        });
        vm.onChange = function (config) {
            config.enabled = (config.value.find(x => x.Label === 'Enable Header') || config.value.find(x => x.Label === 'Enable Footer')).On;
            config.content = (config.value.find(x => x.Label === 'Header Content') || config.value.find(x => x.Label === 'Footer Content')).Value;
            return emailService.updateHeaderOrFooter(config);
        };
    }
}());
(function () {
    angular.module('app').component('emailRouter', {
        controllerAs: 'vm',
        bindings: { $router: '<' },
        controller: emailRouter
    });
    function emailRouter(routingService) {
        this.$routerOnActivate = function (p, n) {
            routingService.getNextRoute(this.$router, 'email');
        };
    }
}());
(function () {
    angular.module('app').component('outbox', {
        controller: outboxController,
        bindings: { $router: '<' },
        require: { parentComponent: '^email' },
        templateUrl: `outbox.tpl.html`,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function outboxController($scope, agGridService, emailService, $q, $element, $mdDialog, formService) {
        const vm = this;
        vm.openRowBeforeChanges = {};
        var columnDefs = [
            {
                field: 'Title',
                colId: 'DisplayName',
                headerName: 'Template Name',
                filter: 'agTextColumnFilter'
            },
            {
                field: 'Scheduled For',
                colId: 'ScheduledDeliveryDate',
                headerName: 'Scheduled For',
                filter: 'agDateColumnFilter',
                valueGetter: agGridService.valueFromFields,
                valueFormatter: agGridService.formatters.dateTime,
                filterParams: {
                    filterOptions: [
                        'equals',
                        'greaterThanOrEqual',
                        'lessThanOrEqual'
                    ]
                }
            }
        ];
        vm.updateEmailDateDisplay = function (email) {
            email.DescriptionFunc = item => {
                let emailDate = formService.getFieldValueFromItem(item, 'Scheduled For');
                return `Scheduled for: <strong>${ moment(emailDate).format('LLL') }</strong>`;
            };
            email.DescriptionAction = {
                text: 'Modify',
                hide: item => item.DisableEdit,
                action: item => vm.changeEmailSchedule(item)
            };
        };
        vm.loadRow = function (params) {
            emailService.addComposeEmailLogic(vm.grid.openRow.data);
            vm.updateEmailDateDisplay(vm.grid.openRow.data);
            vm.openRowBeforeChanges = _.cloneDeep(vm.grid.openRow);
            if (vm.pastSentTime(vm.grid.openRow.data)) {
                vm.grid.openRow.data.DisableEdit = true;
            }
        };
        vm.updateRow = function (updatedItem) {
            let updatedRow = vm.grid.api.getRowNode(updatedItem.Id);
            if (updatedRow) {
                updatedRow.setData(updatedItem);
            }
        };
        vm.cancelRow = function (updatedItem) {
            vm.updateRow(updatedItem);
        };
        vm.afterSave = function (updatedItem) {
            vm.updateRow(updatedItem);
        };
        vm.afterSaveField = function (updatedItem) {
            vm.updateRow(updatedItem);
        };
        vm.saveClosedRow = function (params, closedRow) {
            if (closedRow && closedRow.data && closedRow.data.saveIfChanges) {
                return closedRow.data.saveIfChanges(true);
            }
            return $q.resolve();
        };
        vm.pastSentTime = function (data) {
            return data && agGridService.getFieldValue(data.Fields, 'ScheduledDeliveryDelta') <= 0;
        };
        vm.isSendingSoon = function (data) {
            return data && agGridService.getFieldValue(data.Fields, 'ScheduledDeliveryDelta') < 60000;
        };
        vm.grid = agGridService.createNewGrid(columnDefs, {
            domLayout: 'autoHeight',
            sideBar: false,
            key: 'outbox',
            gridElement: $element.find('.ag-master-grid'),
            expandedCardElement: $element.find('.ag-external-record-row'),
            noRowsMessage: 'Scheduled emails will show up here.',
            datasource: agGridService.getDatasource('Email', 'PendingBlasts'),
            getRowNodeId: data => data.Id,
            rowClassRules: {
                // Sending soon applied if sending in less than one minute (delta is in milliseconds)
                'sending-soon': params => vm.isSendingSoon(params.data)
            },
            defaultColDef: {
                flex: 1,
                minWidth: 160
            }
        }, {
            onRowGroupOpened: vm.loadRow,
            onOpenRowClosed: vm.saveClosedRow
        });
        vm.refreshRows = function () {
            vm.grid.api.refreshServerSideStore();
        };
        vm.deleteEmail = function (email) {
            return emailService.deletePendingEmailBlast(email.Id).then(() => {
                vm.grid.closeOpenRow();
                vm.refreshRows();
            });
        };
        vm.saveAsTemplate = function (email) {
            emailService.showSaveAsEmailTemplateDialog(email);
        };
        vm.saveEmail = function (email) {
            return emailService.updateBlast(email);
        };
        vm.openEmail = function (email) {
            emailService.openEmail(email);
            let contactsField = formService.getFieldFromItem(email, 'Individual Recipients');
            emailService.getPendingRecipients(email.Id).then(r => {
                let recipients = {};
                recipients.members = r.items[0].Members;
                recipients.contacts = r.items[0].Contacts;
                recipients.totalSelected = recipients.members.length + recipients.contacts.length;
                contactsField.value = recipients;
                contactsField.loaded = true;
            });
        };
        vm.changeEmailSchedule = function (email) {
            var dateField = formService.getFieldFromItem(email, 'Scheduled For');
            var prompt = $mdDialog.prompt({
                bindToController: true,
                locals: {
                    required: true,
                    inputType: 'datetime-local'
                }
            }).title('Modify Email Schedule').initialValue(new Date(dateField.value)).htmlContent(`This email is currently scheduled for: ${ moment(dateField.value).format('LLL') }.<br> Enter a new scheduled date and time:`).targetEvent(event).ok('Update').cancel('Cancel');
            return $mdDialog.show(prompt).then(newDate => {
                return email.saveField('Scheduled For', newDate);
            }, angular.noop);
        };
        vm.cardActions = [{
                label: 'Save as Template',
                action: (item, $event) => vm.saveAsTemplate(item)
            }];
        vm.editorButtons = emailService.getEmailEditorButtons();
        $scope.$on('NewEmailBlastComposed', () => {
            vm.refreshRows();
        });
    }
}());
(function () {
    angular.module('app').component('pdfAttachment', {
        controller: pdfAttachmentController,
        bindings: { $router: '<' },
        require: { parentComponent: '^email' },
        template: '<form-template' + ' form-sections=\'vm.sections\'' + ' on-update=\'vm.onChange\'' + ' loading=\'vm.loading\'></form-template>' + '<div class="custom-setting-item">' + '  <tax-receipt-template />' + '</div>' + '<form-template' + '  form-sections=\'vm.advancedSections\'' + '  on-update=\'vm.onChange\'' + '  loading=\'vm.advancedLoading\'></form-template>',
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function pdfAttachmentController(configSettingsService) {
        const vm = this;
        vm.sections = [];
        vm.advancedSections = [];
        configSettingsService.getEmailPdfReceiptSettings().then(r => {
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            }
            configSettingsService.getEmailPdfReceiptAdvancedSettings().then(r => {
                if (r.success && r.form) {
                    vm.advancedSections = r.form.FormSections;
                }
            });
        });
        vm.onChange = config => configSettingsService.updateSetting(config);
    }
}());
(function () {
    angular.module('app').component('sentAdminEmail', {
        controller: sentAdminEmailController,
        bindings: { $router: '<' },
        require: { parentComponent: '^email' },
        template: `<div ag-grid="vm.grid" class="ag-theme-platform grid"></div>`,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function sentAdminEmailController($scope, agGridService, emailService) {
        const vm = this;
        vm.openRow = undefined;
        var columnDefs = [
            {
                field: 'DisplayName',
                headerName: 'Template Name',
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                }
            },
            {
                field: 'Subject',
                headerName: 'Email Subject',
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                }
            },
            {
                field: 'ScheduledDeliveryDate',
                headerName: 'Sent',
                filter: 'agDateColumnFilter',
                valueFormatter: agGridService.formatters.dateTime,
                filterParams: {
                    filterOptions: [
                        'equals',
                        'greaterThanOrEqual',
                        'lessThanOrEqual'
                    ]
                }
            }
        ];
        vm.setupStatsGraph = function (stats) {
            agCharts.AgChart.create({
                theme: 'material',
                container: document.getElementById('emailStatsSentChartContainer'),
                data: [
                    {
                        label: 'Delivered',
                        value: stats.DeliveredCount
                    },
                    {
                        label: 'Suppressed',
                        value: stats.InvalidCount
                    }
                ],
                legend: { enabled: false },
                series: [{
                        type: 'pie',
                        angleKey: 'value',
                        labelKey: 'label',
                        innerRadiusOffset: -35,
                        fills: [
                            '#00698C',
                            '#66A5BA'
                        ],
                        strokeWidth: 0,
                        label: { enabled: false },
                        highlightStyle: { fill: '#ff0041' },
                        tooltip: { renderer: params => '<div class="ag-chart-tooltip-title" ' + 'style="background-color:' + params.color + '">' + params.datum[params.labelKey] + '</div>' + '<div class="ag-chart-tooltip-content">' + params.angleValue + '</div>' }
                    }]
            });
        };
        vm.loadRow = function (params) {
            emailService.getEmailStats(params.data.BlastID).then(r => {
                vm.grid.openRow.data.stats = r.emailStats;
                vm.setupStatsGraph(r.emailStats);
            }).finally(r => vm.grid.openRow.data.statsLoaded = true);
            emailService.getRecipients(params.data.BlastID).then(r => {
                if (!vm.grid.openRow.data) {
                    vm.grid.openRow.data = {};
                }
                vm.grid.openRow.data.recipients = {};
                vm.grid.openRow.data.recipients.members = r.items[0].Members;
                vm.grid.openRow.data.recipients.contacts = r.items[0].Contacts;
                vm.grid.openRow.data.recipientsLoaded = true;
            });
        };
        vm.grid = agGridService.createNewGrid(columnDefs, {
            domLayout: 'autoHeight',
            sideBar: false,
            key: 'sentAdminEmail',
            expandedCardTemplate: 'sentAdminEmailRecord',
            noRowsMessage: 'Sent emails will show up here.',
            datasource: agGridService.getDatasource('Email', 'SentBlasts'),
            defaultColDef: {
                flex: 1,
                minWidth: 160
            }
        }, { onRowGroupOpened: vm.loadRow });
    }
}());
(function () {
    angular.module('app').component('sentAutoEmail', {
        controller: sentAutoEmailController,
        bindings: { $router: '<' },
        require: { parentComponent: '^email' },
        template: `<div ag-grid="vm.grid" class="ag-theme-platform grid"></div>`,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function sentAutoEmailController($scope, $compile, agGridService, emailService, entityService, $http, $mdDialog) {
        const vm = this;
        var columnDefs = [
            {
                field: 'DisplayName',
                headerName: 'Template Name',
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                }
            },
            {
                field: 'ProcessedDate',
                headerName: 'Sent',
                filter: 'agDateColumnFilter',
                valueFormatter: agGridService.formatters.dateTime,
                filterParams: {
                    filterOptions: [
                        'equals',
                        'greaterThanOrEqual',
                        'lessThanOrEqual'
                    ],
                    suppressAndOrCondition: true
                }
            },
            {
                field: 'FirstName',
                headerName: 'First Name',
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                }
            },
            {
                field: 'LastName',
                headerName: 'Last Name',
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                }
            },
            {
                field: 'ToEmail',
                headerName: 'Email',
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                }
            },
            {
                field: 'ProcessedMessage',
                headerName: 'Processed Message',
                valueFormatter: agGridService.formatters.emailProcessedMessage,
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: [
                        100,
                        200,
                        201,
                        300,
                        301
                    ],
                    valueFormatter: agGridService.formatters.emailProcessedMessage,
                    suppressAndOrCondition: true
                }
            }
        ];
        vm.getEntity = function () {
            return vm.parentComponent.openEntity;
        };
        vm.loadRow = function (params) {
            emailService.getSystemEmailStats(params.data.ProcessedEmailID).then(r => vm.grid.openRow.data.stats = r.emailStats).finally(r => vm.grid.openRow.data.statsLoaded = true);
        };
        vm.grid = agGridService.createNewGrid(columnDefs, {
            domLayout: 'autoHeight',
            sideBar: false,
            key: 'sentAutoEmail',
            expandedCardTemplate: 'sentAutoEmailRecord',
            noRowsMessage: 'Sent emails will show up here.',
            datasource: agGridService.getDatasource('Email', 'SentSystemEmails'),
            defaultColDef: {
                flex: 1,
                minWidth: 160
            }
        }, { onRowGroupOpened: vm.loadRow });
        vm.resendEmail = function (record) {
            var locals = {};
            var templateMessage = 'The email will be re-sent exactly as it was when originally sent.';
            if (record.IsUsingNewTemplate && record.CanUseUpdatedTemplate) {
                locals.secondary = {
                    text: 'Resend With Latest Template',
                    action: () => {
                        resendEmailRequest(true);
                    }
                };
                templateMessage = 'You can choose to resend the email exactly how it was when originally sent, or to use the latest template.';
            }
            var confirm = $mdDialog.confirm({
                bindToController: true,
                locals: locals
            }).title('Resend Email').htmlContent(`Would you like to resend this email to ${ record.ToEmail }?<br><b>${ templateMessage }</b>`).targetEvent(event).ok('Resend Original').cancel('Cancel');
            $mdDialog.show(confirm).then(() => {
                resendEmailRequest(false);
            }, angular.noop);
            function resendEmailRequest(useUpdatedEmail) {
                emailService.resendEmail(record.ProcessedEmailID, useUpdatedEmail);
            }
        };
    }
}());
(function () {
    angular.module('app').component('taxReceiptTemplate', {
        templateUrl: 'tax-receipt-template.tpl.html',
        controller: taxReceiptTemplateController,
        controllerAs: 'vm'
    });
    function taxReceiptTemplateController($scope, taxReceiptService, entityService, configSettingsService, messageService) {
        const vm = this;
        vm.loading = true;
        vm.editing = false;
        vm.saving = false;
        vm.edit = () => {
            vm.editing = true;
        };
        vm.save = () => {
            vm.editing = false;
            if (vm.initialTemplate === vm.template) {
                return;
            }
            vm.saving = true;
            taxReceiptService.updateTemplate(vm.template).then(response => {
                if (response.success) {
                    setResponseData(response.data);
                } else {
                    messageService.showErrorToast(response.msg || 'Unknown Error Unable to update PDF Receipt');
                }
            }).finally(() => {
                vm.saving = false;
            });
        };
        vm.editable = () => {
            return !vm.disabled && (!(vm.inherited || vm.isDefault) || vm.entityType === 'Org');
        };
        getInfo();
        $scope.$on('adoptParent', function (event, configId) {
            vm.loading = true;
            configSettingsService.adoptParent(configId).then(getInfo);
        });
        $scope.$on('overrideParent', function (event, configId) {
            vm.loading = true;
            configSettingsService.overrideParent(configId).then(getInfo);
        });
        $scope.$on('clearOverrides', function (event, configId) {
            vm.loading = true;
            configSettingsService.clearOverrides(configId).then(getInfo);
        });
        function getInfo() {
            taxReceiptService.getTemplate().then(response => {
                if (response.success) {
                    vm.editing = false;
                    setResponseData(response.data);
                }
            });
        }
        function setResponseData(data) {
            vm.inherited = data.Inherited;
            vm.isDefault = data.IsDefault;
            vm.parentName = data.OverriddenParentEntityName;
            vm.configId = data.ConfigOptionID;
            vm.editing = false;
            vm.disabled = data.Disabled;
            CKEDITOR.config.macros = data.macros;
            vm.template = data.template;
            vm.initialTemplate = data.template;
            let entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
            vm.entityType = entity.entityType;
            vm.loading = false;
        }
    }
}());
(function () {
    angular.module('app').component('triggerEmails', {
        controller: triggerEmailsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^email' },
        templateUrl: 'trigger-emails.tpl.html',
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function triggerEmailsController($mdDialog, $controller, $q, $scope, orgService, entityService, emailService, securityService, formService) {
        const vm = this;
        var entity = entityService.getOpenEntity();
        vm.configOptionId = emailService.EMAIL_CONFIG_OPTIONS.triggers;
        vm.entityType = entity.entityType;
        vm.security = securityService.getAdminPermissions(entity, 'TRIGGERS');
        vm.loading = true;
        vm.getData = function () {
            vm.dataRequest().then(r => {
                vm.grid = r.items;
                vm.newItem = r.newItem;
            }).catch(() => {
            }).finally(() => vm.loading = false);
        };
        vm.dataRequest = function () {
            return emailService.getTriggerEmailTemplates();
        };
        orgService.orgInfoLoadedPromise.then(r => {
            vm.getData();
        });
        vm.showActivateOptions = (item, forTextDisplay) => emailService.showActivateOptions(item, forTextDisplay);
        vm.editorButtons = emailService.getEmailEditorButtons();
        vm.clicked = function (item) {
            return vm.saveEmail(item).then(r => {
                item.Id = r.item.Id;
            });
        };
        vm.open = function (item) {
            emailService.openEmail(item);
            let itemFields = {
                decimal: {
                    group: formService.getFieldFromItem(item, 'Decimal'),
                    amount: formService.getSubFieldFromItem(item, 'Decimal', 'Amount'),
                    setMaximum: formService.getSubFieldFromItem(item, 'Decimal', 'Set Maximum'),
                    maximum: formService.getSubFieldFromItem(item, 'Decimal', 'Maximum')
                },
                int: {
                    group: formService.getFieldFromItem(item, 'Int'),
                    amount: formService.getSubFieldFromItem(item, 'Int', 'Amount'),
                    setMaximum: formService.getSubFieldFromItem(item, 'Int', 'Set Maximum'),
                    maximum: formService.getSubFieldFromItem(item, 'Int', 'Maximum')
                },
                initiatorDecimal: {
                    group: formService.getFieldFromItem(item, 'InitiatorDecimal'),
                    amount: formService.getSubFieldFromItem(item, 'InitiatorDecimal', 'Amount')
                },
                trigger: formService.getFieldFromItem(item, 'Trigger'),
                enduranceChallenge: formService.getFieldFromItem(item, 'Endurance Challenge')
            };
            if (itemFields.trigger) {
                itemFields.trigger.change = () => {
                    updateIntLabel(itemFields);
                    updateDecimalLabel(itemFields);
                    updateInitiatorDecimalLabel(itemFields);
                };
            }
            if (itemFields.enduranceChallenge) {
                itemFields.enduranceChallenge.change = () => {
                    updateDecimalLabel(itemFields);
                };
            }
            updateIntLabel(itemFields);
            updateDecimalLabel(itemFields);
            updateInitiatorDecimalLabel(itemFields);
        };
        function updateIntLabel(itemFields) {
            var labels = getIntLabels(itemFields);
            updateLabelsForGroup(labels, itemFields.int);
        }
        function updateDecimalLabel(itemFields) {
            var labels = getDecimalLabels(itemFields);
            updateLabelsForGroup(labels, itemFields.decimal);
        }
        function updateInitiatorDecimalLabel(itemFields) {
            var labels = getInitiatorDecimalLabels(itemFields);
            updateLabelsForGroup(labels, itemFields.initiatorDecimal);
        }
        function updateLabelsForGroup(labels, groupItemFields) {
            if (groupItemFields.group) {
                groupItemFields.group.displayOptions.labels.alternative = labels.alternative;
            }
            if (groupItemFields.amount) {
                groupItemFields.amount.displayOptions.labels.prepend = labels.prepend;
                groupItemFields.amount.displayOptions.labels.append = labels.append;
                groupItemFields.amount.displayOptions.labels.validation = labels.alternative;
            }
            if (groupItemFields.setMaximum) {
                groupItemFields.setMaximum.displayOptions.labels.inline = 'Set Maximum ' + labels.alternative;
            }
            if (groupItemFields.maximum) {
                groupItemFields.maximum.displayOptions.labels.prepend = labels.prepend;
                groupItemFields.maximum.displayOptions.labels.append = labels.append;
                groupItemFields.maximum.displayOptions.labels.validation = labels.alternative + ' Maximum';
                groupItemFields.maximum.displayOptions.labels.alternative = 'Maximum ' + labels.alternative;
            }
        }
        function getIntLabels(itemFields) {
            if (!itemFields.trigger) {
                return {};
            }
            switch (itemFields.trigger.value) {
            case 'DonorCount':
            case 'TeamDonorCount':
                return {
                    alternative: 'Number of Donors',
                    append: 'donors'
                };
            case 'DonationCount':
                return {
                    alternative: 'Number of Donations',
                    append: 'donations'
                };
            case 'ActivitiesLoggedToEnduranceChallenge':
                return {
                    alternative: 'Activities Logged',
                    append: 'activities'
                };
            case 'ReceivedNumberOfEndurancePledges':
                return {
                    alternative: 'Number of pledges',
                    append: 'pledges'
                };
            }
            return {};
        }
        function getDecimalLabels(itemFields) {
            if (!itemFields.trigger) {
                return {};
            }
            switch (itemFields.trigger.value) {
            case 'RaisedDollars':
            case 'TeamRaisedDollars':
            case 'RaisedEndurancePledgeAmount':
                return {
                    alternative: 'Amount Raised',
                    prepend: '$'
                };
            case 'CompletedProgressTowardEnduranceChallenge':
                return {
                    alternative: 'Completed Progress',
                    append: getEnduranceUnitFromEnduranceChallengeField(itemFields)
                };
            }
            return {};
        }
        function getInitiatorDecimalLabels(itemFields) {
            if (!itemFields.trigger) {
                return {};
            }
            switch (itemFields.trigger.value) {
            case 'ReceivesDonation':
                return {
                    alternative: 'Donation Amount',
                    prepend: '$'
                };
            }
            return {};
        }
        function getEnduranceUnitFromEnduranceChallengeField(itemFields) {
            if (!itemFields.enduranceChallenge || !itemFields.enduranceChallenge.optionsMetadata) {
                return '';
            }
            return itemFields.enduranceChallenge.optionsMetadata[itemFields.enduranceChallenge.value].Unit;
        }
        vm.overrideParent = function (item) {
            return vm.saveEmail(item).then(r => {
                return r.item;
            });
        };
        vm.delete = function (item) {
            return emailService.deleteEmailTemplate(item);
        };
        vm.saveEmail = function (item) {
            return emailService.createOrUpdateTriggerEmailTemplate(item);
        };
        vm.adoptParent = function (item) {
            return emailService.deleteEmailTemplate(item).then(r => {
                return r.item;
            });
        };
        $scope.$on('clearOverrides', function (event, item) {
            if (item.configOptionId === vm.configOptionId) {
                return emailService.clearEmailTemplateOverrides(item);
            }
        });
    }
}());
(function () {
    angular.module('app').component('advancedSettings', {
        controllerAs: 'vm',
        controller: advancedSettingsController,
        require: { parentComponent: '^formsTab' },
        bindings: { $router: '<' },
        template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'
               loading='vm.loading'></form-template>`,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    function advancedSettingsController(configSettingsService, extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
            this.parentComponent.loading = false;
        };
        vm.loading = true;
        vm.sections = [];
        configSettingsService.getFormsAdvancedSettings().then(function (r) {
            vm.loading = false;
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            } else {
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
    }
}());
(function () {
    angular.module('app').component('customizeSettings', {
        templateUrl: 'customize-settings.tpl.html',
        controllerAs: 'vm',
        controller: customizeSettingsController,
        require: { parentComponent: '^formsTab' },
        bindings: { $router: '<' },
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function customizeSettingsController(entityContentService, orgService, entityService) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
            this.parentComponent.loading = false;
        };
        vm.entityType = entityService.getOpenEntity().entityType;
        vm.termType = 'Forms';
        vm.getData = getData;
        function getData() {
            dataRequest().then(items => {
                vm.grid = items;
            }).catch(() => {
            });
        }
        function dataRequest() {
            return entityContentService.getFormTerms(vm.parentComponent.openEntity, vm.selectedLanguage);
        }
        orgService.orgInfoLoadedPromise.then(r => {
            entityService.getEntitySettings(vm.parentComponent.openEntity).then(entity => {
                vm.languages = entity.settings.LanguagesKeyValue;
                vm.selectedLanguage = entity.settings.DefaultLanguage;
                vm.isMultiLanguage = entity.settings.IsMultiLanguage;
                getData();
            });
        });
        vm.clicked = function (item) {
            return vm.saveTerm(item, false);
        };
        vm.open = function (item) {
            entityContentService.getTemplatePlaceholders(vm.parentComponent.openEntity, item.Flags).then(items => CKEDITOR.config.macros = items);
        };
        vm.overrideParent = function (item) {
            return vm.saveTerm(item, true);
        };
        vm.saveTerm = function (item, createDuplicate) {
            return entityContentService.updateTerm(vm.parentComponent.openEntity, item, vm.termType, vm.selectedLanguage, createDuplicate);
        };
        vm.adoptParent = function (item) {
            return entityContentService.deleteTerm(vm.parentComponent.openEntity, item, vm.termType, vm.selectedLanguage).then(() => dataRequest());
        };
    }
}());
(function () {
    angular.module('app').component('discountCodes', {
        controllerAs: 'vm',
        controller: discountCodesController,
        bindings: { $router: '<' },
        require: { parentComponent: '^formsTab' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function discountCodesController(extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('DiscountCodes');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('donationSettings', {
        controllerAs: 'vm',
        controller: donationSettingsController,
        require: { parentComponent: '^formsTab' },
        bindings: { $router: '<' },
        template: `<form-template
              form-sections='vm.sections'
              on-update='vm.onChange'
              loading='vm.loading'></form-template>
            <div class='custom-setting-item'>
              <pay-pal-setup ng-if="vm.hasPayPalPermission"></pay-pal-setup>
            </div>`,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    function donationSettingsController(configSettingsService, extJsLoader, securityService, entityService) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
            this.parentComponent.loading = false;
            var permission = securityService.getAdminPermissions(entityService.getRawEntityById(this.parentComponent.openEntity), 'PAY_PAL');
            vm.hasPayPalPermission = permission.VIEW;
        };
        vm.loading = true;
        vm.sections = [];
        configSettingsService.getDonationSettings().then(function (r) {
            vm.loading = false;
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            } else {
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
    }
}());
(function () {
    angular.module('app').component('formBuilder', {
        controllerAs: 'vm',
        controller: formBuilderController,
        bindings: { $router: '<' },
        require: { parentComponent: '^formsTab' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function formBuilderController(extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Forms');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    var routes = [
        {
            path: '/',
            name: 'FormsRouter',
            component: 'formsRouter',
            data: {
                dontRoute: true,
                showFunc: function () {
                    return false;
                }
            },
            useAsDefault: true
        },
        {
            path: '/donationSettings',
            name: 'Donation Settings',
            component: 'donationSettings',
            data: {
                anyResource: [
                    'DONATION_SETTINGS',
                    'PROCESSOR_SETTINGS',
                    'PROCESSING_FEES',
                    'POST_DONATION_OPTIONS',
                    'PAY_PAL'
                ]
            }
        },
        {
            path: '/registrationSettings',
            name: 'Registration Settings',
            component: 'registrationSettings',
            data: {
                anyResource: ['REGISTRATION_SETTINGS'],
                hideOnStandaloneAndTicketing: true
            }
        },
        {
            path: '/registrationFields',
            name: 'Registration Fields',
            component: 'registrationFields',
            data: {
                resource: 'REGISTRATION_FIELDS',
                limitToCampaign: true,
                routeFunc: function (entity) {
                    return !entity.settings.HasRoleRegistration;
                },
                hideOnStandaloneAndTicketing: true
            }
        },
        {
            path: '/registrationBuilder',
            name: 'Registration Builder',
            component: 'registrationBuilder',
            data: {
                resource: 'REGISTRATION_FIELDS',
                limitToCampaign: true,
                routeFunc: function (entity) {
                    return entity.settings.HasRoleRegistration;
                },
                hideOnStandaloneAndTicketing: true
            }
        },
        {
            path: '/ticketBuilder',
            name: 'Ticket Builder',
            component: 'ticketBuilder',
            data: {
                resource: 'TICKETS',
                limitToCampaign: true,
                routeFunc: function (entity) {
                    return entity.type != 'Donation' && entity.type != 'Global Donation Page' && (entity.settings.EventHasTickets || entity.type != 'Memorial' && entity.type != 'Personal Fundraising');
                }
            }
        },
        {
            path: '/discountCodes',
            name: 'Discount Codes',
            component: 'discountCodes',
            data: {
                resource: 'DISCOUNT_CODES',
                limitToCampaign: true
            }
        },
        {
            path: '/formBuilder',
            name: 'Form Builder',
            component: 'formBuilder',
            data: {
                resource: 'FORMS',
                limitToCampaign: true
            }
        },
        {
            path: '/customizeSettings',
            name: 'Customize',
            component: 'customizeSettings',
            data: { resource: 'FORMS_CUSTOMIZE' }
        },
        {
            path: '/advancedSettings',
            name: 'Advanced',
            component: 'advancedSettings',
            data: { anyResource: ['SETTINGS_ADVANCED'] }
        }
    ];
    angular.module('app').component('formsTab', {
        templateUrl: 'forms-tab.tpl.html',
        controllerAs: 'vm',
        controller: formsTabController,
        $routeConfig: routes,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    angular.module('app').constant('formsRouteConfig', routes);
    function formsTabController(routingService, entityService, orgService, $rootScope, extJsLoader, iframeMessageHandler) {
        const vm = this;
        vm.navs = routes;
        vm.iframeData = {};
        vm.loading = true;
        vm.showIframe = false;
        this.$routerOnDeactivate = function (p, n) {
            extJsLoader.extJsController.iframeCreated = false;
        };
        routingService.updateRouting().then(r => {
            vm.openEntity = r;
            let entity = entityService.getRawEntityById(r);
            if (entity) {
                vm.onGroup = entity.entityType === 'CampaignGroup';
                vm.onOrg = entity.entityType === 'Org';
                if (entity.curEventId) {
                    vm.iframeSrc = `/site/${ entity.curEventId }?${ location.search.indexOf('pass') > -1 ? `pass=true&` : `` }type=plat&value=`;
                    if (!extJsLoader.extJsController.iframeCreated) {
                        vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'forms');
                    } else {
                        vm.loading = false;
                    }
                    iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
                }
            }
        });
        $rootScope.$on('loaded', r => {
            extJsLoader.extJsController.loadInnerPage().then(r => vm.loading = false);
        });
    }
}());
(function () {
    angular.module('app').component('formsRouter', {
        controllerAs: 'vm',
        bindings: { $router: '<' },
        controller: formsRouter
    });
    function formsRouter(routingService) {
        this.$routerOnActivate = function (p, n) {
            routingService.getNextRoute(this.$router, 'formsTab');
        };
    }
}());
(function () {
    angular.module('app').component('registrationBuilder', {
        controllerAs: 'vm',
        controller: registrationBuilderController,
        bindings: { $router: '<' },
        require: { parentComponent: '^formsTab' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function registrationBuilderController(extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Rolereg');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('registrationFields', {
        controllerAs: 'vm',
        controller: registrationFieldsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^formsTab' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function registrationFieldsController(extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('RegistrationFields');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('registrationSettings', {
        controllerAs: 'vm',
        controller: registrationSettingsController,
        require: { parentComponent: '^formsTab' },
        bindings: { $router: '<' },
        template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'
               loading='vm.loading'></form-template>`,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    function registrationSettingsController(configSettingsService, extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
            this.parentComponent.loading = false;
        };
        vm.loading = true;
        vm.sections = [];
        configSettingsService.getRegistrationSettings().then(function (r) {
            vm.loading = false;
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            } else {
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
    }
}());
(function () {
    angular.module('app').component('ticketBuilder', {
        controllerAs: 'vm',
        controller: ticketBuilderController,
        bindings: { $router: '<' },
        require: { parentComponent: '^formsTab' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function ticketBuilderController(extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Tickets');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('emailTemplates', {
        controller: emailTemplatesController,
        bindings: { $router: '<' },
        require: { parentComponent: '^fundraising' },
        templateUrl: 'email-templates.tpl.html',
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function emailTemplatesController($mdDialog, $controller, $q, $scope, orgService, entityService, emailService, securityService) {
        const vm = this;
        var entity = entityService.getOpenEntity();
        vm.configOptionId = emailService.EMAIL_CONFIG_OPTIONS.fundraisingEmails;
        vm.entityType = entity.entityType;
        vm.security = securityService.getAdminPermissions(entity, 'FUNDRAISER_EMAIL_TEMPLATES');
        vm.loading = true;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
            this.parentComponent.loading = false;
        };
        vm.getData = function () {
            vm.dataRequest().then(r => {
                vm.grid = r.items;
                vm.newItem = r.newItem;
            }).catch(() => {
            }).finally(() => vm.loading = false);
        };
        vm.dataRequest = function () {
            return emailService.getFundraiserEmailTemplates();
        };
        orgService.orgInfoLoadedPromise.then(r => {
            vm.getData();
        });
        vm.showActivateOptions = (item, forTextDisplay) => emailService.showActivateOptions(item, forTextDisplay);
        vm.editorButtons = emailService.getEmailEditorButtons();
        vm.clicked = function (item) {
            return vm.saveEmail(item).then(r => {
                item.Id = r.item.Id;
            });
        };
        vm.open = function (item) {
            emailService.openEmail(item);
        };
        vm.overrideParent = function (item) {
            return vm.saveEmail(item).then(r => {
                return r.item;
            });
        };
        vm.delete = function (item) {
            return emailService.deleteEmailTemplate(item);
        };
        vm.saveEmail = function (item) {
            return emailService.createOrUpdateEmailTemplate(item);
        };
        vm.adoptParent = function (item) {
            return emailService.deleteEmailTemplate(item).then(r => {
                return r.item;
            });
        };
        $scope.$on('clearOverrides', function (event, item) {
            if (item.configOptionId === vm.configOptionId) {
                return emailService.clearEmailTemplateOverrides(item);
            }
        });
    }
}());
(function () {
    angular.module('app').component('enduranceChallengeTemplate', {
        controllerAs: 'vm',
        controller: enduranceChallengeTemplateController,
        templateUrl: 'endurance-challenge-template.tpl.html',
        bindings: {
            defaultActivityTypes: '<',
            unitOptions: '<',
            durationFormats: '<',
            distanceFormats: '<',
            integrationProviders: '<',
            isOpen: '<',
            showForm: '=',
            disabled: '<',
            existingChallenge: '<',
            permission: '<'
        }
    });
    function enduranceChallengeTemplateController($scope, $q, $timeout, $location, $mdDialog, $mdConstant, mdcDateTimeDialog, enduranceChallengeService, settingsService, entityService) {
        const vm = this;
        vm.wasOpenOnInit = false;
        vm.timeZoneSearch = timeZoneSearch;
        vm.displayDateDialog = displayDateDialog;
        vm.displayTimeDialog = displayTimeDialog;
        vm.showChallengeSettings = false;
        vm.endurancePledgeSettings = false;
        vm.separatorKeys = [
            $mdConstant.KEY_CODE.ENTER,
            $mdConstant.KEY_CODE.COMMA,
            $mdConstant.KEY_CODE.TAB
        ];
        vm.PROGRESS_TO = {
            DURATION: 1,
            DISTANCE: 2,
            OTHER: 3
        };
        vm.$onInit = function () {
            if (vm.isOpen) {
                vm.wasOpenOnInit = true;
            }
            showPledgeSettings();
            var showParam = $location.search()['advanced'];
            vm.showChallengeSettings = showParam && showParam.toLowerCase() === 'true';
        };
        vm.unitOptionsIntegrationsFilter = function (selectedIntegrations) {
            return function (e) {
                var thisSupportedIntegrations = e.IntegrationProviders || [];
                var matches = true;
                thisSupportedIntegrations = thisSupportedIntegrations.map(value => value.toString());
                if (selectedIntegrations !== undefined) {
                    angular.forEach(selectedIntegrations, function (integration) {
                        if (thisSupportedIntegrations.indexOf(integration.toString()) === -1) {
                            matches = false;
                        }
                    });
                }
                return matches;
            };
        };
        vm.goalPlaceholderOrUnit = function (challengeForm, returnPlaceholder) {
            var unit;
            var placeholder;
            if (challengeForm && challengeForm.ProgressTo) {
                switch (challengeForm.ProgressTo.UnitType) {
                case vm.PROGRESS_TO.DURATION:
                    if (challengeForm.DurationFormat) {
                        var durationFormat = vm.durationFormats.filter(x => x.Value === challengeForm.DurationFormat)[0];
                        if (durationFormat.Placeholder) {
                            placeholder = durationFormat.Placeholder;
                        } else {
                            unit = durationFormat.Display;
                        }
                    }
                    break;
                case vm.PROGRESS_TO.DISTANCE:
                    if (challengeForm.DistanceFormat) {
                        var distanceFormat = vm.distanceFormats.filter(x => x.Value === challengeForm.DistanceFormat)[0];
                        unit = distanceFormat.DisplayShort;
                    }
                    break;
                case vm.PROGRESS_TO.OTHER:
                    unit = challengeForm.ChallengeUnit;
                    break;
                default:
                    if (challengeForm.ProgressTo.OtherValue) {
                        unit = challengeForm.ProgressTo.OtherValue;
                    }
                    break;
                }
            }
            if (returnPlaceholder && placeholder) {
                return placeholder;
            } else if (unit) {
                return unit;    //Unit abbreviations are plural by default
            }
            return '';
        };
        $timeout(setForm);
        function setForm() {
            vm.challengeForm = prepareChallengeForDisplay(vm.existingChallenge) || {
                AllowedActivityTypes: [],
                UseImperialSystem: true
            };
            vm.challengeForm.EndDateTime = {
                dateTime: vm.challengeForm.EndDateTime ? new moment.utc(vm.challengeForm.EndDateTime) : undefined,
                timeZone: vm.challengeForm.EndDateTimeZoneValue ? getTimezone() : ''
            };
            vm.editing = !vm.existingChallenge;
        }
        function getTimezone() {
            return {
                display: vm.challengeForm.EndDateTimeZoneValue ? vm.challengeForm.EndDateTimeZoneValue.replace('/', ' - ').replace('_', ' ') : '',
                value: vm.challengeForm.EndDateTimeZoneValue
            };
        }
        vm.onCancel = function () {
            vm.showForm = false;
            vm.editing = false;
            if (!vm.wasOpenOnInit) {
                vm.isOpen = false;
            }
        };
        function refreshChallenges(response) {
            $scope.$emit('refreshChallenges', response);
        }
        vm.onSave = function () {
            var challenge = prepareChallengeForSave(vm.challengeForm);
            var saveFunc;
            if (vm.existingChallenge) {
                saveFunc = enduranceChallengeService.updateEnduranceChallenge;
            } else {
                saveFunc = enduranceChallengeService.createEnduranceChallenge;
            }
            saveFunc(challenge).then(function (r) {
                if (r.success && r.challenges) {
                    refreshChallenges(r);
                    vm.showForm = false;
                }
            });
        };
        function showPledgeSettings() {
            entityService.getEntitySettings(entityService.getOpenedEntityId()).then(entity => {
                vm.endurancePledgeSettings = entity.settings.EndurancePledgesEnabled;
            });
        }
        vm.enableEdit = function () {
            vm.editing = true;
            vm.isOpen = true;
        };
        vm.open = function () {
            vm.isOpen = true;
        };
        vm.close = function () {
            vm.isOpen = false;
        };
        vm.confirmDeletion = function (event) {
            var confirm = $mdDialog.confirm().title('Are you sure?').textContent(`This endurance challenge will be deleted. All related endurance progress will be removed from campaign sites and fundraisers will no longer be able to log activities towards this challenge. This action cannot be undone.`).targetEvent(event).ok('Delete').cancel('Cancel');
            $mdDialog.show(confirm).then(function () {
                enduranceChallengeService.removeEnduranceChallenge(vm.existingChallenge).then(refreshChallenges);
            }, function () {
                return;
            });
        };
        function timeZoneSearch(query) {
            const lowerCaseQuery = query.toLowerCase();
            return settingsService.getIanaTimeZones().then(zones => zones.filter(zone => zone.display.toLowerCase().includes(lowerCaseQuery)));
        }
        function displayDateDialog(dateField) {
            var dateVal = vm.challengeForm[dateField] ? vm.challengeForm[dateField].dateTime : moment().utc();
            mdcDateTimeDialog.show({
                time: false,
                date: true,
                currentDate: dateVal,
                disableParentScroll: true
            }).then(function (date) {
                vm.challengeForm[dateField].dateTime = new moment.utc(date);
            }, function () {
            });
        }
        ;
        function displayTimeDialog(dateField) {
            mdcDateTimeDialog.show({
                time: true,
                date: false,
                shortTime: true,
                currentDate: vm.challengeForm[dateField].dateTime,
                disableParentScroll: true
            }).then(function (date) {
                vm.challengeForm[dateField].dateTime = new moment.utc(date);
            }, function () {
            });
        }
        ;
        function prepareChallengeForDisplay(challenge) {
            if (!challenge)
                return;
            var displayChallenge = JSON.parse(angular.toJson(challenge));
            if (vm.unitOptions) {
                var triedGettingCustomUnit = false;
                if (challenge.ProgressTo == vm.PROGRESS_TO.OTHER) {
                    triedGettingCustomUnit = true;
                    displayChallenge.ProgressTo = vm.unitOptions.filter(x => x.OtherValue == challenge.ChallengeUnit)[0];
                }
                if (!triedGettingCustomUnit || !displayChallenge.ProgressTo) {
                    displayChallenge.ProgressTo = vm.unitOptions.filter(x => x.UnitType == challenge.ProgressTo)[0];
                }
            }
            return displayChallenge;
        }
        function prepareChallengeForSave(challenge) {
            var challengePrepared = JSON.parse(angular.toJson(challenge));
            // chips API has no track-by, need to remove angular $$hashkey
            if (!vm.challengeForm.RequiresEndDate) {
                challengePrepared.EndDate = undefined;
                challengePrepared.EndDateTimeZoneValue = undefined;
                challengePrepared.EndDateTimeZone = undefined;
                challengePrepared.EndDateTime = undefined;
            } else {
                challengePrepared.EndDateTimeZoneValue = challengePrepared.EndDateTime.timeZone ? challengePrepared.EndDateTime.timeZone.value : undefined;
                challengePrepared.EndDateTime = challengePrepared.EndDateTime.dateTime ? challengePrepared.EndDateTime.dateTime.replace('Z', '') : challengePrepared.EndDateTime.dateTime;
            }
            if (challenge.ProgressTo) {
                if (challenge.ProgressTo.OtherValue) {
                    challengePrepared.ChallengeUnit = challenge.ProgressTo.OtherValue;
                    challengePrepared.ProgressTo = vm.PROGRESS_TO.OTHER;
                } else {
                    challengePrepared.ProgressTo = challenge.ProgressTo.UnitType;
                }
            }
            return challengePrepared;
        }
        function addedActivityNames() {
            return vm.challengeForm.AllowedActivityTypes.map(x => (x.NamePresent || x.Name).toLowerCase()) || [];
        }
        function remainingDefaults() {
            return vm.defaultActivityTypes.filter(x => !addedActivityNames().includes(x.Name.toLowerCase())) || [];
        }
        vm.activityFilter = function (query) {
            var remainingOptions = remainingDefaults();
            if (query) {
                return remainingOptions.filter(x => {
                    return x.Name.toLowerCase().indexOf(query.toLowerCase()) === 0;
                });
            }
            return remainingOptions;
        };
        vm.transformActivityChip = function (chip) {
            // If it is an object, it's already a known chip
            if (angular.isObject(chip)) {
                return chip;
            }
            var matchingDefault = remainingDefaults().find(x => x.Name.toLowerCase() == chip.toLowerCase());
            if (matchingDefault) {
                return matchingDefault;
            }
            var typeAdded = vm.challengeForm.AllowedActivityTypes.find(x => (x.NamePresent || x.Name).toLowerCase() == chip.toLowerCase());
            if (typeAdded)
                return null;
            var newChip = { Name: chip };
            // var prompt = $mdDialog
            //     .prompt()
            //     .title('Add Past-Tense')
            //     .textContent(`Add a past-tense version of '${chip}'` )
            //     .placeholder('Past-tense')
            //     .cancel('Cancel')
            //     .ok('Save');
            //  $mdDialog.show(prompt)
            // .then(function(input) {
            //     if(!input) {
            //         vm.challengeForm.AllowedActivityTypes.pop();
            //     } else {
            //         newChip.pastTense = input;
            //     }
            // },
            // function() {
            //     vm.challengeForm.AllowedActivityTypes.pop();
            // });
            return newChip;
        };
    }
}());
(function () {
    angular.module('app').component('enduranceChallenges', {
        controllerAs: 'vm',
        controller: enduranceChallengesController,
        templateUrl: 'endurance-challenges.tpl.html',
        require: { parentComponent: '^fundraising' }
    });
    function enduranceChallengesController($scope, enduranceChallengeService, securityService, extJsLoader, entityService, $q) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.loading = false;
            this.parentComponent.showIframe = false;
        };
        vm.CHALLENGES_LOCK_KEY = 'CHALLENGES';
        var entity = entityService.getOpenEntity();
        vm.entityType = entity.entityType;
        vm.entityId = entity.entityId;
        vm.loading = true;
        enduranceChallengeService.getEnduranceForm().then(refreshChallenges).finally(function () {
            vm.loading = false;
        });
        $scope.$on('refreshChallenges', function (event, response) {
            refreshChallenges(response);
        });
        function refreshChallenges(response) {
            if (response.success && response.challenges) {
                vm.availableChallenges = response.challenges;
                vm.challengesConfig = response.configItem;
                vm.defaultChallengeId = response.defaultChallengeId;
                vm.defaultActivityTypes = vm.defaultActivityTypes || response.defaultActivityTypes;
                vm.unitOptions = vm.unitOptions || response.unitOptions;
                vm.integrationProviders = vm.integrationProviders || response.integrationProviders;
                vm.durationFormats = vm.durationFormats || response.durationFormats;
                vm.distanceFormats = vm.distanceFormats || response.distanceFormats;
            }
            return response;
        }
        vm.permission = securityService.getAdminPermissions(entity, 'ENDURANCE_CHALLENGES');
        vm.selectedIndex = undefined;
        vm.hideShowChallengeItem = function (index) {
            if (vm.selectedIndex !== index) {
                vm.selectedIndex = index;
            }
        };
        vm.showFormAction = function () {
            vm.showForm = true;
        };
        $scope.$on('adoptParent', function (event, key) {
            if (key == vm.CHALLENGES_LOCK_KEY) {
                vm.sectionUpdating = true;
                enduranceChallengeService.adoptParent().then(refreshChallenges).finally(() => {
                    vm.sectionUpdating = false;
                });
            }
        });
        $scope.$on('overrideParent', function (event, key) {
            if (key == vm.CHALLENGES_LOCK_KEY) {
                vm.sectionUpdating = true;
                enduranceChallengeService.overrideParent().then(refreshChallenges).finally(() => {
                    vm.sectionUpdating = false;
                });
            }
        });
        $scope.$on('clearOverrides', function (event, key) {
            if (key == vm.CHALLENGES_LOCK_KEY) {
                vm.sectionUpdating = true;
                enduranceChallengeService.clearOverrides().then(refreshChallenges).finally(() => {
                    vm.sectionUpdating = false;
                });
            }
        });
    }
}());
(function () {
    angular.module('app').component('fundraiserPdf', {
        bindings: { $router: '<' },
        template: '<form-template' + ' form-sections=\'vm.sections\'' + ' on-update=\'vm.onChange\'' + ' loading=\'vm.loading\'></form-template>' + '<div class="custom-setting-item">' + '  <fundraiser-pdf-template />' + '</div>' + '<form-template' + '  form-sections=\'vm.advancedSections\'' + '  on-update=\'vm.onChange\'' + '  loading=\'vm.advancedLoading\'></form-template>',
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
}());
(function () {
    angular.module('app').component('fundraiserPdfTemplate', {
        templateUrl: 'fundraiser-pdf-template.tpl.html',
        controller: fundraiserPdfTemplateController,
        controllerAs: 'vm'
    });
    function fundraiserPdfTemplateController($scope, fundraiserPdfService, entityService, configSettingsService, messageService) {
        const vm = this;
        vm.loading = true;
        vm.editing = false;
        vm.saving = false;
        vm.edit = () => {
            vm.editing = true;
        };
        vm.save = () => {
            vm.editing = false;
            if (vm.initialTemplate === vm.template) {
                return;
            }
            vm.saving = true;
            fundraiserPdfService.updateTemplate(vm.template).then(response => {
                if (response.success) {
                    setResponseData(response.data);
                } else {
                    messageService.showErrorToast(response.msg || 'Unknown Error. Unable to update Fundraiser PDF.');
                }
            }).finally(() => {
                vm.saving = false;
            });
        };
        vm.editable = () => {
            return !vm.disabled && (!(vm.inherited || vm.isDefault) || vm.entityType === 'Org');
        };
        getInfo();
        $scope.$on('adoptParent', function (event, configId) {
            vm.loading = true;
            configSettingsService.adoptParent(configId).then(getInfo);
        });
        $scope.$on('overrideParent', function (event, configId) {
            vm.loading = true;
            configSettingsService.overrideParent(configId).then(getInfo);
        });
        $scope.$on('clearOverrides', function (event, configId) {
            vm.loading = true;
            configSettingsService.clearOverrides(configId).then(getInfo);
        });
        function getInfo() {
            fundraiserPdfService.getTemplate().then(response => {
                if (response.success) {
                    vm.editing = false;
                    setResponseData(response.data);
                } else {
                    messageService.showErrorToast(response.msg || 'Unknown Error Unable to retrieve PDF template');
                }
            });
        }
        function setResponseData(data) {
            vm.inherited = data.Inherited;
            vm.isDefault = data.IsDefault;
            vm.parentName = data.OverriddenParentEntityName;
            vm.configId = data.ConfigOptionID;
            vm.editing = false;
            vm.disabled = data.Disabled;
            CKEDITOR.config.macros = data.macros;
            vm.template = data.template;
            vm.initialTemplate = data.template;
            let entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
            vm.entityType = entity.entityType;
            vm.loading = false;
        }
    }
}());
(function () {
    var routeConfig = [
        {
            path: '/fundraisingSettings',
            name: 'Fundraising Settings',
            component: 'fundraisingSettings',
            useAsDefault: true,
            data: {
                anyResource: [
                    'FUNDRAISING_OPTIONS',
                    'LIVESTREAMS'
                ]
            }
        },
        {
            path: '/startupGuide',
            name: 'Startup Guide',
            component: 'startupGuide',
            data: {
                anyResource: [
                    'SETTINGS_HQ_TASKS',
                    'FUNDRAISING_OPTIONS'
                ]
            }
        },
        {
            path: '/taskList',
            name: 'Task List',
            component: 'taskList',
            data: {
                anyResource: [
                    'FUNDRAISER_TASK_LIST',
                    'FUNDRAISER_TASK_LIST_OPTIONS'
                ]
            }
        },
        {
            path: '/templates',
            name: 'Email Templates',
            component: 'emailTemplates',
            data: { resource: 'FUNDRAISER_EMAIL_TEMPLATES' }
        },
        {
            path: '/enduranceChallenges',
            name: 'Endurance Challenges',
            component: 'enduranceChallenges',
            data: { resource: 'ENDURANCE_CHALLENGES' }
        },
        {
            path: '/fundraiserPdf',
            name: 'Fundraiser PDF',
            component: 'fundraiserPdf',
            data: {
                anyResource: ['FUNDRAISING_CUSTOMIZE'],
                routeFunc: function (entity) {
                    return entity.settings.HasFundraiserPdfEnabled;
                }
            }
        },
        {
            path: '/customize',
            name: 'Customize',
            component: 'fundraisingCustomize',
            data: { resource: 'FUNDRAISING_CUSTOMIZE' }
        },
        {
            path: '/advanced',
            name: 'Advanced',
            component: 'fundraisingAdvanced',
            data: { anyResource: ['FUNDRAISING_ADVANCED'] }
        }
    ];
    angular.module('app').component('fundraising', {
        templateUrl: 'fundraising.tpl.html',
        controllerAs: 'vm',
        bindings: { $router: '<' },
        controller: fundraisingController,
        $routeConfig: routeConfig,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    angular.module('app').constant('fundraisingRouteConfig', routeConfig);
    function fundraisingController($rootScope, routingService, entityService, orgService, extJsLoader, iframeMessageHandler) {
        const vm = this;
        vm.navs = routeConfig;
        vm.iframeData = {};
        vm.loading = true;
        vm.showIframe = false;
        this.$routerOnDeactivate = function (p, n) {
            extJsLoader.extJsController.iframeCreated = false;
        };
        routingService.updateRouting().then(r => {
            vm.openEntity = r;
            let entity = entityService.getRawEntityById(r);
            if (entity) {
                vm.onGroup = entity.entityType === 'CampaignGroup';
                vm.onOrg = entity.entityType === 'Org';
                if (entity.curEventId) {
                    vm.iframeSrc = `/site/${ entity.curEventId }?type=plat&value=`;
                    if (!extJsLoader.extJsController.iframeCreated) {
                        vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'fundraising');
                    } else {
                        vm.loading = false;
                    }
                    iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
                }
            }
        });
        $rootScope.$on('loaded', r => {
            extJsLoader.extJsController.loadInnerPage().then(r => vm.loading = false);
        });
    }
}());
(function () {
    angular.module('app').component('fundraisingAdvanced', {
        controllerAs: 'vm',
        controller: fundraisingAdvancedController,
        template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'
               loading='vm.loading'></form-template>`,
        require: { parentComponent: '^fundraising' }
    });
    function fundraisingAdvancedController(configSettingsService) {
        const vm = this;
        vm.loading = true;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.loading = false;
            this.parentComponent.showIframe = false;
        };
        vm.sections = [];
        configSettingsService.getFundraisingAdvancedSettings().then(function (r) {
            vm.loading = false;
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            } else {
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
    }
}());
(function () {
    angular.module('app').component('fundraisingCustomize', {
        templateUrl: 'fundraising-customize.tpl.html',
        controllerAs: 'vm',
        controller: fundraisingCustomizeController,
        require: { parentComponent: '^fundraising' },
        bindings: { $router: '<' },
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function fundraisingCustomizeController(extJsLoader, entityContentService, orgService, entityService) {
        const vm = this;
        vm.entityType = entityService.getOpenEntity().entityType;
        vm.termType = 'Fundraising';
        vm.getData = getData;
        vm.loading = true;
        function getData() {
            dataRequest().then(items => {
                vm.grid = items;
            }).catch(() => {
            }).finally(() => vm.loading = false);
        }
        function dataRequest() {
            return entityContentService.getFundraisingTerms(vm.parentComponent.openEntity, vm.selectedLanguage);
        }
        orgService.orgInfoLoadedPromise.then(r => {
            entityService.getEntitySettings(vm.parentComponent.openEntity).then(entity => {
                vm.languages = entity.settings.LanguagesKeyValue;
                vm.selectedLanguage = entity.settings.DefaultLanguage;
                vm.isMultiLanguage = entity.settings.IsMultiLanguage;
                vm.hasMediaModule = entity.settings.HasMediaModuleEnabled;
                if (vm.hasMediaModule) {
                    extJsLoader.extJsController.updateInnerPage('Content-PersonalPageDefaults');
                    this.parentComponent.showIframe = true;
                } else {
                    this.parentComponent.loading = false;
                    this.parentComponent.showIframe = false;
                }
                getData();
            });
        });
        vm.clicked = function (item) {
            return vm.saveTerm(item, false);
        };
        vm.open = function (item) {
            entityContentService.getTemplatePlaceholders(vm.parentComponent.openEntity, item.Flags).then(items => CKEDITOR.config.macros = items);
        };
        vm.overrideParent = function (item) {
            return vm.saveTerm(item, true);
        };
        vm.saveTerm = function (item, createDuplicate) {
            return entityContentService.updateTerm(vm.parentComponent.openEntity, item, vm.termType, vm.selectedLanguage, createDuplicate);
        };
        vm.adoptParent = function (item) {
            return entityContentService.deleteTerm(vm.parentComponent.openEntity, item, vm.termType, vm.selectedLanguage).then(() => dataRequest());
        };
    }
}());
(function () {
    angular.module('app').component('fundraisingSettings', {
        controllerAs: 'vm',
        controller: fundraisingSettingsController,
        template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'
               loading='vm.loading'></form-template>`,
        require: { parentComponent: '^fundraising' },
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    function fundraisingSettingsController($rootScope, configSettingsService) {
        const vm = this;
        vm.loading = true;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.loading = false;
            this.parentComponent.showIframe = false;
        };
        vm.sections = [];
        configSettingsService.getFundraisingOptions().then(function (r) {
            vm.loading = false;
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            } else {
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
    }
}());
(function () {
    angular.module('app').component('livestreams', {
        controllerAs: 'vm',
        controller: startupGuideController,
        template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'
               loading='vm.loading'></form-template>`,
        bindings: { $router: '<' },
        require: { parentComponent: '^fundraising' },
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function startupGuideController(configSettingsService) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.loading = false;
            this.parentComponent.showIframe = false;
        };
        vm.sectionUpdating = false;
        vm.sections = [];
        configSettingsService.getLivestreamSettings().then(function (r) {
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            } else {
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
    }
}());
(function () {
    angular.module('app').component('pageDefaults', {
        controllerAs: 'vm',
        controller: pageDefaultsController,
        template: ``,
        bindings: { $router: '<' },
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        },
        require: { parentComponent: '^fundraising' }
    });
    function pageDefaultsController(extJsLoader) {
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Content-PersonalPageDefaults');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('startupGuide', {
        controllerAs: 'vm',
        controller: startupGuideController,
        template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'
               loading='vm.loading'></form-template>`,
        require: { parentComponent: '^fundraising' },
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    function startupGuideController(configSettingsService, extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.loading = false;
            this.parentComponent.showIframe = false;
        };
        vm.sectionUpdating = false;
        vm.sections = [];
        configSettingsService.getStartupGuideSettings().then(function (r) {
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            } else {
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
    }
}());
(function () {
    angular.module('app').component('taskList', {
        controllerAs: 'vm',
        controller: taskListController,
        template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'></form-template>`,
        require: { parentComponent: '^fundraising' },
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    function taskListController(configSettingsService, extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.loading = false;
            this.parentComponent.showIframe = false;
        };
        vm.sectionUpdating = false;
        vm.sections = [];
        configSettingsService.getHqTaskSettings().then(function (r) {
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            } else {
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
    }
}());
(function () {
    angular.module('app').component('contacts', {
        controller: contactsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^people' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function contactsController(extJsLoader, iframeMessageHandler) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('People-Contacts');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('donors', {
        controller: donorsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^people' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function donorsController(extJsLoader, iframeMessageHandler) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('People-Donors');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('nationalTeams', {
        controller: nationalTeams,
        bindings: { $router: '<' },
        require: { parentComponent: '^people' },
        controllerAs: 'vm',
        templateUrl: 'national-teams.tpl.html',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function nationalTeams(extJsLoader, $rootScope, $scope, $mdDialog, entityService, nationalTeamsService, configSettingsService, securityService) {
        const vm = this;
        var entity = entityService.getOpenEntity();
        vm.entityType = entity.entityType;
        vm.entityId = entity.entityId;
        vm.LOCK_KEY = nationalTeamsService.NT_CONFIG_ID;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
            this.parentComponent.loading = false;
            vm.parentComponent = this.parentComponent;
        };
        this.$routerOnDeactivate = function (next, pref) {
            this.parentComponent.campaigns.updateShowAddBtn(false, 'campaign');
        };
        vm.security = securityService.getAdminPermissions(entity, 'PEOPLE_NATIONAL_TEAMS');
        vm.showNationalTeamModal = false;
        vm.currentTeam = {};
        vm.loading = true;
        nationalTeamsService.getEnabledNationalTeams().then(r => {
            vm.loading = false;
            vm.nationalTeams = r.nationalTeams;
            vm.setting = r.setting;
            vm.parentComponent.campaigns.updateShowAddBtn(!vm.setting.Inherited && vm.security.EDIT, 'nationalTeam', true);
        });
        vm.confirmRemoveNationalTeam = function (team, event) {
            var confirm = $mdDialog.confirm().title('Are you sure?').textContent(`This National Team will no longer be available on ${ vm.entityType === 'Campaign' ? 'this campaign' : 'campaigns within this group' }.`).targetEvent(event).ok('Remove').cancel('Cancel');
            $mdDialog.show(confirm).then(function () {
                vm.removeNationalTeam(team);
            }, function () {
                return;
            });
        };
        vm.viewNationalTeam = function (team) {
            vm.currentTeam = team;
            vm.showNationalTeamModal = true;
        };
        vm.removeNationalTeam = function (team) {
            nationalTeamsService.removeNationalTeam(team.TeamId).then(response => {
                vm.setting.IntValues.splice(vm.setting.IntValues.indexOf(team.TeamId), 1);
                nationalTeamsService.getEntityNationalTeams(vm.setting.IntValues).then(r => {
                    vm.nationalTeams = r.nationalTeams;
                });
            });
        };
        vm.refreshNationalTeams = function () {
            nationalTeamsService.getEntityNationalTeams(vm.setting.IntValues).then(r => {
                vm.nationalTeams = r.nationalTeams;
                vm.parentComponent.campaigns.updateShowAddBtn(!vm.setting.Inherited, 'nationalTeam');
            });
        };
        $rootScope.$on('addNewSelected', () => {
            vm.showNationalTeamModal = true;
        });
        $scope.$on('adoptParent', function (event, key) {
            if (key == vm.LOCK_KEY) {
                vm.sectionUpdating = true;
                configSettingsService.adoptParent(vm.LOCK_KEY).then(r => {
                    vm.setting = r.setting;
                    vm.refreshNationalTeams();
                }).finally(() => {
                    vm.sectionUpdating = false;
                });
            }
        });
        $scope.$on('overrideParent', function (event, key) {
            if (key == vm.LOCK_KEY) {
                vm.sectionUpdating = true;
                configSettingsService.overrideParent(vm.LOCK_KEY).then(r => {
                    vm.setting = r.setting;
                    vm.refreshNationalTeams();
                }).finally(() => {
                    vm.sectionUpdating = false;
                });
            }
        });
        $scope.$on('clearOverrides', function (event, key) {
            if (key == vm.LOCK_KEY) {
                vm.sectionUpdating = true;
                configSettingsService.clearOverrides(vm.LOCK_KEY).then(r => {
                    vm.setting = r.setting;
                }).finally(() => {
                    vm.sectionUpdating = false;
                });
            }
        });
    }
}());
(function () {
    angular.module('app').component('nationalTeamsModal', {
        bindings: {
            showModal: '=',
            nationalTeams: '=',
            team: '=',
            nationalTeamIds: '=',
            security: '='
        },
        controller: nationalTeamsModalCtrl,
        controllerAs: 'vm'
    });
    function nationalTeamsModalCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.showModal', () => {
            if (vm.showModal) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'national-teams-modal.tpl.html',
                // Handled by modal itself to allow intercepting close on server errors
                escapeToClose: false,
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                multiple: true,
                locals: {
                    parent: $scope,
                    team: vm.team,
                    nationalTeamIds: vm.nationalTeamIds,
                    security: vm.security
                }
            }).catch(() => {
            }).finally(() => {
                vm.showModal = false;
            });
        }
    }
    function ModalContentCtrl($mdDialog, $scope, $rootScope, entityService, fileUpload, messageService, nationalTeamsService, parent, team, nationalTeamIds, security) {
        const vm = this;
        vm.newTeamForm = false;
        vm.team = team;
        vm.editMode = !!vm.team.TeamId;
        vm.saveInProgress = false;
        vm.createInProgress = false;
        vm.showErrorMessages = false;
        vm.fieldServerErrors = {};
        vm.entityId = entityService.getOpenedEntityId();
        vm.security = security;
        vm.selectedTab = undefined;
        vm.showNationalTeamAddManager = false;
        vm.addManagerEmail = undefined;
        vm.addManagerErrors = undefined;
        nationalTeamsService.getNonEnabledNationalTeams(nationalTeamIds).then(r => {
            vm.orgNationalTeams = r;
            vm.newTeamForm = !vm.editMode && !vm.orgNationalTeams.length;
        });
        vm.nationalTeamFields = {
            TeamName: 1,
            Url: 2,
            TeamDescription: 3,
            GoalAmount: 4,
            TeamImage: 5,
            TeamType: 6
        };
        vm.popupTitle = popupTitle;
        vm.externalNationalTeamUrl = externalNationalTeamUrl;
        vm.updateSubDomain = updateSubDomain;
        vm.createNationalTeam = createNationalTeam;
        vm.removeNationalTeamManager = removeNationalTeamManager;
        vm.addNationalTeamManager = addNationalTeamManager;
        vm.addNationalTeam = addNationalTeam;
        vm.saveNationalTeamField = saveNationalTeamField;
        vm.cancel = closeModal;
        vm.save = () => closeModal(true);
        vm.entityWithSettings = entityService.getEntityWithSettings(entityService.getOpenedEntityId());
        vm.currencySymbol = vm.entityWithSettings.settings.DefaultCurrency.Symbol;
        vm.addOverlay = addOverlay;
        vm.removeOverlay = removeOverlay;
        vm.onFieldKeyUp = onFieldKeyUp;
        $scope.onUploadFile = onUploadFile;
        vm.openNewTeamForm = function () {
            vm.newTeamForm = true;
        };
        vm.informationTabDeselected = function () {
            var tabToSwitchTo = vm.selectedTab;
            var e = $rootScope.$broadcast('tabSwitched', true, function () {
                vm.selectedTab = tabToSwitchTo;
            });
            if (e.defaultPrevented) {
                vm.selectedTab = 0;
            }
        };
        function externalNationalTeamUrl() {
            if (vm.team && vm.team.TeamId && vm.entityWithSettings.entityType === 'Campaign' && vm.entityWithSettings.urls && vm.entityWithSettings.urls.primary) {
                return `https://${ vm.entityWithSettings.urls.primary }/NationalTeam/View/${ vm.team.TeamId }`;
            }
        }
        function popupTitle() {
            if (vm.newTeamForm) {
                return 'newTeam';
            }
            if (vm.team.TeamName) {
                if (vm.showNationalTeamAddManager) {
                    return 'addManager';
                }
                return 'editTeam';
            }
            return 'selectTeam';
        }
        function onUploadFile(event) {
            fileUpload.onUploadFile(event, vm.entityId, 'national-team', vm.team.TeamId);
        }
        function onFieldKeyUp($event, field, form) {
            if (field === 'TeamName' && !form.Url.$dirty) {
                vm.updateSubDomain();
            }
        }
        function updateSubDomain() {
            if (!vm.editMode && vm.team.TeamName)
                vm.team.Url = vm.team.TeamName.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9\-]+/g, '');
        }
        function removeNationalTeamManager(userAccountId) {
            nationalTeamsService.removeNationalTeamManager(vm.team.TeamId, userAccountId).then(response => {
                nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
                    parent.vm.nationalTeams = [];
                    parent.vm.nationalTeams = r.nationalTeams;
                });
                vm.team = response.nationalTeam;
            }, response => {
            });
        }
        function addNationalTeamManager() {
            nationalTeamsService.addNationalTeamManager(vm.team.TeamId, vm.addManagerEmail).then(response => {
                nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
                    parent.vm.nationalTeams = r.nationalTeams;
                });
                vm.team = response.nationalTeam;
                vm.addManagerErrors = undefined;
                vm.addManagerEmail = undefined;
                vm.showNationalTeamAddManager = false;
            }, response => {
                vm.addManagerErrors = response.data.errors;
            });
        }
        function saveNationalTeamField(fieldName, val, callback) {
            var field = vm.nationalTeamFields[fieldName];
            nationalTeamsService.editNationalTeam(vm.team.TeamId, field, val).then(response => {
                nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
                    parent.vm.nationalTeams = r.nationalTeams;
                });
                callback(response);
            }, response => {
                callback(response);
            });
        }
        function createNationalTeam(form) {
            if (form.$invalid) {
                vm.showErrorMessages = true;
                return;
            }
            vm.showErrorMessages = false;
            vm.createInProgress = true;
            nationalTeamsService.createNationalTeam(vm.team).then(newTeam => {
                parent.vm.nationalTeamIds.push(newTeam.TeamId);
                nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
                    parent.vm.nationalTeams = r.nationalTeams;
                });
                vm.cancel();
            }, r => {
                vm.fieldServerErrors = getFieldServerErrors(r.data.errors);
            }).finally(() => {
                vm.createInProgress = false;
                vm.saveInProgress = false;
            });
        }
        function getFieldServerErrors(errors) {
            var serverErrors = {};
            for (var errorKey in errors) {
                var error = errors[errorKey];
                if (error.ID && error.error) {
                    serverErrors[error.ID.toLowerCase()] = error.error;
                }
            }
            return serverErrors;
        }
        function addNationalTeam(e, team) {
            if (!team.added) {
                nationalTeamsService.addNationalTeam(team.TeamId).then(response => {
                    parent.vm.nationalTeamIds.push(team.TeamId);
                    nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
                        parent.vm.nationalTeams = r.nationalTeams;
                        team.added = true;
                    });
                });
            } else {
                nationalTeamsService.removeNationalTeam(team.TeamId).then(response => {
                    parent.vm.nationalTeamIds.splice(parent.vm.nationalTeamIds.indexOf(team.TeamId), 1);
                    nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
                        parent.vm.nationalTeams = r.nationalTeams;
                        team.added = false;
                    });
                });
            }
        }
        $scope.$on('closeModal', function (event) {
            closeModal();
        });
        function closeModal(saveBeforeClosing) {
            var e = $rootScope.$broadcast('modalClosed', saveBeforeClosing, vm.cancel);
            if (!e.defaultPrevented) {
                parent.vm.team = {};
                $mdDialog.hide();
            }
        }
        function removeOverlay() {
            document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
        }
        function addOverlay() {
            document.querySelector('.fileInput-placeholder').style.zIndex = '1';
        }
        $rootScope.$on('file.cropped', (event, data) => {
            if (data.id == vm.team.TeamId) {
                vm.team.ImageUrl = data.img;
                if (vm.editMode) {
                    vm.saveInProgress = true;
                    nationalTeamsService.editNationalTeam(vm.team.TeamId, vm.nationalTeamFields.TeamImage, vm.team.ImageUrl).then(response => {
                        nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
                            parent.vm.nationalTeams = r.nationalTeams;
                            vm.saveInProgress = false;
                        });
                    });
                }
            }
        });
    }
}());
(function () {
    var routeConfig = [
        {
            path: '/',
            name: 'PeopleRouter',
            component: 'peopleRouter',
            data: {
                dontRoute: true,
                showFunc: function () {
                    return false;
                }
            },
            useAsDefault: true
        },
        {
            path: '/contacts',
            name: 'Contacts',
            component: 'contacts',
            data: {
                resource: 'PEOPLE_CONTACTS',
                hideOnStandaloneAndTicketing: true,
                limitToCampaign: true
            }
        },
        {
            path: '/donors',
            name: 'Donors',
            component: 'donors',
            data: {
                resource: 'PEOPLE_DONORS',
                limitToCampaign: true
            }
        },
        {
            path: '/registrants',
            name: 'Registrants',
            component: 'registrants',
            data: {
                resource: 'PEOPLE_REGISTRANTS',
                hideOnStandaloneAndTicketing: true,
                limitToCampaign: true
            }
        },
        {
            path: '/teams',
            name: 'Teams',
            component: 'teams',
            data: {
                resource: 'PEOPLE_TEAMS',
                hideOnStandaloneAndTicketing: true,
                limitToCampaign: true
            }
        },
        {
            path: '/nationalTeams',
            name: 'National Teams',
            component: 'nationalTeams',
            data: {
                resource: 'PEOPLE_NATIONAL_TEAMS',
                routeFunc: function (entity) {
                    return entity.settings.NationalTeamsEnabled;
                }
            }
        }
    ];
    angular.module('app').component('people', {
        templateUrl: 'people.tpl.html',
        controllerAs: 'vm',
        bindings: { $router: '<' },
        require: { campaigns: '^campaigns' },
        controller: peopleController,
        $routeConfig: routeConfig,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    angular.module('app').constant('peopleRouteConfig', routeConfig);
    function peopleController($rootScope, routingService, entityService, orgService, extJsLoader, iframeMessageHandler, $timeout) {
        const vm = this;
        vm.loading = true;
        vm.navs = routeConfig;
        vm.showComposeModal = false;
        vm.iframeData = {};
        vm.showIframe = false;
        this.$routerOnDeactivate = function (p, n) {
            extJsLoader.extJsController.iframeCreated = false;
        };
        routingService.updateRouting().then(r => {
            vm.openEntity = r;
            let entity = entityService.getRawEntityById(r);
            if (entity) {
                vm.onGroup = entity.entityType === 'CampaignGroup';
                vm.onOrg = entity.entityType === 'Org';
                if (entity.curEventId) {
                    vm.iframeSrc = `/site/${ entity.curEventId }?type=plat&value=`;
                    if (!extJsLoader.extJsController.iframeCreated) {
                        vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'people');
                    } else {
                        vm.loading = false;
                    }
                    iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
                } else {
                    vm.loading = false;
                }
            }
        });
        $rootScope.$on('loaded', r => {
            extJsLoader.extJsController.loadInnerPage().then(r => {
                vm.loading = false;
                extJsLoader.extJsController.checkParams();
            });
        });
        $rootScope.$on('emailFromReports', ($event, targetEvent) => {
            $timeout(function () {
                vm.showComposeModal = true;
            });
        });
    }
}());
(function () {
    angular.module('app').component('peopleRouter', {
        controllerAs: 'vm',
        bindings: { $router: '<' },
        controller: peopleRouter
    });
    function peopleRouter(routingService) {
        this.$routerOnActivate = function (p, n) {
            routingService.getNextRoute(this.$router, 'people');
        };
    }
}());
(function () {
    angular.module('app').component('registrants', {
        controller: registrantsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^people' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function registrantsController(extJsLoader, iframeMessageHandler, entityService) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('People-Registrants');
            this.parentComponent.showIframe = true;
            let entityWithSettings = entityService.getEntityWithSettings(this.parentComponent.openEntity).settings;
            this.parentComponent.showCachedRegistrantsDataNotice = entityWithSettings.UseRegistrantsGridSnapshot;
            this.parentComponent.registrantsSnapshotRefreshTime = entityWithSettings.RegistrantsGridSnapshotRefreshTime;
        };
        this.$routerOnDeactivate = function (p, n) {
            this.parentComponent.showCachedRegistrantsDataNotice = false;
            this.parentComponent.registrantsSnapshotRefreshTime = 0;
        };
    }
}());
(function () {
    angular.module('app').component('teams', {
        controller: teamsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^people' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function teamsController(extJsLoader, iframeMessageHandler) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('People-Teams');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('reports', {
        templateUrl: 'reports.tpl.html',
        controllerAs: 'vm',
        bindings: { $router: '<' },
        controller: reportsController,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    function reportsController($rootScope, routingService, entityService, extJsLoader, iframeMessageHandler, $location, $timeout) {
        const vm = this;
        vm.loading = true;
        vm.showComposeModal = false;
        vm.iframeData = {};
        this.$routerOnDeactivate = function (n, p) {
            extJsLoader.extJsController.iframeCreated = false;
            $timeout(function () {
                reloadPage(n, p);
            }, 100);
        };
        function reloadPage(n, p) {
            if ($location.search()['QueuedReport']) {
                if (n.componentType === p.componentType) {
                    window.location.search = window.location.search;
                } else {
                    window.location.search = '';
                }
            }
        }
        routingService.updateRouting().then(r => {
            vm.openEntity = r;
            entityService.getEntitySettings(r).then(entity => {
                vm.onGroup = entity.entityType === 'CampaignGroup';
                vm.onOrg = entity.entityType === 'Org';
                var curEventId = entityService.getCurrentEventId(entity);
                if (curEventId) {
                    vm.iframeSrc = `/site/${ curEventId }?type=plat&value=`;
                    if (!extJsLoader.extJsController.iframeCreated) {
                        vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'reports');
                        extJsLoader.extJsController.updateInnerPage('Reports');
                    } else {
                        vm.loading = false;
                    }
                    iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
                }
            });
        });
        $rootScope.$on('loaded', r => {
            extJsLoader.extJsController.loadInnerPage().then(r => {
                vm.loading = false;
            });
        });
        $rootScope.$on('emailFromReports', ($event, targetEvent) => {
            $timeout(function () {
                vm.showComposeModal = true;
            });
        });
    }
}());
(function () {
    angular.module('app').component('advanced', { templateUrl: 'advanced.tpl.html' });
}());
(function () {
    angular.module('app').component('advancedAdmin', {
        controller: advancedAdminController,
        bindings: { $router: '<' },
        require: { parentComponent: '^settings' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function advancedAdminController(entityService) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
            let entity = entityService.getRawEntityById(this.parentComponent.openEntity);
            if (entity && entity.curEventId) {
                this.parentComponent.iframeData.src = `/site/${ entity.curEventId }/AdvancedAdmin`;
                this.parentComponent.showIframe = true;
                this.parentComponent.loading = false;
            }
        };
    }
}());
(function () {
    angular.module('app').component('globalTerms', { templateUrl: 'global-terms.tpl.html' });
}());
(function () {
    angular.module('app').component('information', {
        templateUrl: 'information.tpl.html',
        controller: infoSettingsCtrl,
        controllerAs: 'vm'
    });
    function infoSettingsCtrl($rootScope, settingsService) {
        const vm = this;
        $rootScope.$on('open.settings', data => {
            console.log(data);
        });
        vm.name = settingsService.getTargetEntityId() || 'test';
    }
}());
(function () {
    angular.module('app').component('integrations', {
        templateUrl: 'integrations.tpl.html',
        controller: integrationController,
        controllerAs: 'vm',
        require: { parentComponent: '^settings' }
    });
    function integrationController(configSettingsService, extJsLoader) {
        const vm = this;
        vm.loading = true;
        vm.sections = [];
        configSettingsService.getIntegrationSettings().then(r => {
            vm.loading = false;
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Settings-IntegrationOptions');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('liquidTemplate', {
        templateUrl: 'liquid-template.tpl.html',
        controller: liquidTemplateController,
        controllerAs: 'vm',
        require: { parentComponent: '^settings' }
    });
    function liquidTemplateController(entityContentService, securityService, routingService, entityService) {
        const vm = this;
        vm.show = false;
        vm.save = save;
        vm.showModal = showModal;
        var entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
        vm.security = securityService.getAdminPermissions(entity, 'SETTINGS_EDIT_CONTENT_ON_SITE');
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
        };
        function loadLiquidTemplates() {
            vm.liquidTemplateLoading = true;
            vm.liquidTemplates = [];
            vm.activeTemplatePageId = -1;
            vm.editRow = function (row) {
                if (!vm.saving)
                    vm.activeTemplatePageId = row.id;
            };
            vm.saveRow = function (row) {
                vm.saving = true;
                entityContentService.updateLiquidTemplatePage(vm.entityId, row.id, row.name, row.content).finally(() => {
                    vm.saving = false;
                });
            };
            entityContentService.getLiquidTemplate(vm.entityId).then(liquidTemplatePages => {
                vm.liquidTemplates = liquidTemplatePages;
            }).catch(() => {
                vm.liquidTemplates = [];
            }).finally(() => {
                vm.liquidTemplateLoading = false;
            });
        }
        routingService.updateRouting().then(r => {
            if (!r) {
                vm.groupOrCmpNotFound = true;
            } else {
                vm.entityId = r;
                let entity = entityService.getRawEntityById(r);
                if (entity) {
                    vm.onGroup = entity.entityType === 'CampaignGroup';
                    loadLiquidTemplates();
                }
            }
        });
        function showModal() {
            vm.show = true;
        }
        function save(pageName, pageType, pageExt) {
            return entityContentService.createNewLiquidTemplatePage(vm.entityId, vm.liquidTemplates[0].entityTemplateId, pageName, pageType, pageExt).then(loadLiquidTemplates);
        }
    }
}());
(function () {
    angular.module('app').component('modalAddTemplatePage', {
        bindings: {
            show: '=',
            onCreateAction: '='
        },
        controllerAs: 'vm',
        controller: ModalAddTemplatePageController
    });
    function ModalAddTemplatePageController($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-add-template-page.tpl.html',
                controller: ModalAddTemplatePageContentCtrl,
                controllerAs: 'vm',
                locals: { onCreateAction: vm.onCreateAction },
                clickOutsideToClose: true
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalAddTemplatePageContentCtrl($mdDialog, onCreateAction) {
        const vm = this;
        vm.onCreateAction = onCreateAction;
        vm.closeModal = closeModal;
        vm.validInput = validInput;
        vm.save = save;
        vm.filename = '';
        vm.filetype = '';
        vm.filetypes = [
            {
                label: 'Js',
                value: '4-js'
            },
            {
                label: 'Less',
                value: '4-less'
            },
            {
                label: 'Page',
                value: '2-liquid'
            },
            {
                label: 'Widget',
                value: '3-liquid'
            }
        ];
        function closeModal() {
            $mdDialog.hide();
        }
        function validInput() {
            return vm.filename && vm.filetype;
        }
        function save() {
            if (!vm.onCreateAction) {
                console.error('onCreateAction method not set!', vm.onCreateAction);
                return;
            }
            vm.saving = true;
            var pageTypeSplit = vm.filetype.split('-');
            vm.onCreateAction(vm.filename, pageTypeSplit[0], pageTypeSplit[1]).finally(() => {
                vm.saving = false;
                closeModal();
            });
        }
    }
}());
(function () {
    angular.module('app').component('modalAddPayPal', {
        bindings: {
            show: '=',
            partner: '='
        },
        controller: modalAddPayPal,
        controllerAs: 'vm'
    });
    function modalAddPayPal($scope, $mdDialog, payPalService) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-add-paypal.tpl.html',
                clickOutsideToClose: true,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                onComplete: payPalService.loadPayPal,
                locals: { partner: vm.partner }
            }).catch(() => {
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($timeout, $mdDialog, partner) {
        const vm = this;
        vm.partner = partner;
        vm.cancel = closeModal;
        vm.loading = true;
        doneLoading();
        function doneLoading() {
            if (typeof PAYPAL != 'undefined') {
                vm.loading = false;
            } else {
                $timeout(doneLoading, 3000);
            }
        }
        function closeModal() {
            $mdDialog.cancel();
        }
    }
}());
(function () {
    angular.module('app').component('modalConfirmPayPalChanges', {
        bindings: {
            show: '=',
            header: '=',
            body: '=',
            confirm: '=',
            func: '&'
        },
        controller: modalConfirmPayPalChanges,
        controllerAs: 'vm'
    });
    function modalConfirmPayPalChanges($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-confirm-pay-pal-changes.tpl.html',
                clickOutsideToClose: true,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    header: vm.header,
                    body: vm.body,
                    confirm: vm.confirm,
                    func: vm.func
                }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($mdDialog, header, body, confirm, func) {
        const vm = this;
        vm.header = header;
        vm.body = body;
        vm.confirm = confirm;
        vm.processClick = processClick;
        vm.cancel = closeModal;
        function closeModal() {
            $mdDialog.hide();
        }
        function processClick() {
            func().then(closeModal());
        }
    }
}());
(function () {
    angular.module('app').component('neonPay', {
        templateUrl: 'neon-pay.tpl.html',
        controller: neonPayController,
        controllerAs: 'vm',
        require: { parentComponent: '^settings' }
    });
    function neonPayController(routingService, profileService, settingsService, orgService) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
        };
        vm.createNeonLink = createNeonLink;
        vm.sandbox = location.search.indexOf('sandbox') > -1;
        vm.neonPayLogin = vm.sandbox ? 'https://sandbox.neononepay.com/login' : 'https://neononepay.com/login';
        getNeonInfo();
        routingService.updateRouting().then(r => {
            vm.openEntity = r;
        });
        function getNeonInfo() {
            return settingsService.getPageInfo('neon').then(response => {
                if (response.success) {
                    vm.link = response.data.ReferralLink;
                    vm.inherited = response.data.Inherited;
                    vm.entityName = response.data.EntityName;
                    vm.neonStatus = setNeonStatus(response.data.ReferralState, response.data.OnOtherEntity);
                }
            });
        }
        function setNeonStatus(referralState, onOtherEntity) {
            if (onOtherEntity) {
                return 'otherEntity';
            }
            switch (referralState) {
            case 1:
                return 'processing';
            case 2:
                return 'active';
            default:
                return 'new';
            }
        }
        function createNeonLink() {
            if (vm.link) {
                window.open(vm.link);
            } else {
                return settingsService.createNeonLink(vm.sandbox).then(response => {
                    if (response.success) {
                        vm.link = response.data;
                        window.open(response.data);
                    }
                });
            }
        }
    }
}());
(function () {
    angular.module('app').component('paymentProcessors', {
        controllerAs: 'vm',
        controller: paymentProcessorsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^settings' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function paymentProcessorsController(extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Settings-DonationProcessingOptions');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('paymentSettings', { templateUrl: 'payment-settings.tpl.html' });
}());
(function () {
    angular.module('app').component('payPalSetup', {
        templateUrl: 'paypal-setup.tpl.html',
        controller: payPalSetupController,
        controllerAs: 'vm'
    });
    function payPalSetupController($scope, payPalService, routingService, entityService, orgService, configSettingsService) {
        const vm = this;
        vm.disconnectPayPal = disconnectPayPal;
        vm.reconnectPayPal = reconnectPayPal;
        vm.loading = true;
        vm.settingsLoading = true;
        vm.settingsSections = [];
        vm.globalPayPalEnabledFlag = orgService.globalSettings.PayPalPartnerEnabled;
        if (vm.globalPayPalEnabledFlag) {
            getPayPalInfo();
        }
        $scope.$on('adoptParent', function (event, configId) {
            if (!configId) {
                vm.reconnectPayPal();
            }
        });
        $scope.$on('overrideParent', function (event, configId) {
            if (!configId) {
                vm.disconnectPayPal();
            }
        });
        configSettingsService.getPayPalSettings().then(function (r) {
            vm.settingsLoading = false;
            if (r.success && r.form) {
                vm.settingsSections = r.form.FormSections;
            }
        });
        vm.onSettingsChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
        vm.connectToPayPal = function () {
            if (!vm.partner) {
                payPalService.createPayPalReferral().then(r => {
                    if (r.success) {
                        vm.partner = `${ r.data.PartnerUrl }&displayMode=minibrowser`;
                        vm.showPayPalConnect = true;
                    }
                });
            } else {
                vm.showPayPalConnect = true;
            }
        };
        function getPayPalInfo() {
            return payPalService.getPayPalInfo().then(response => {
                if (response.success) {
                    setResponseData(response.data);
                    vm.loading = false;
                }
            });
        }
        function disconnectPayPal() {
            let entityTypeText = '';
            switch (vm.openEntityType) {
            case 'Org':
                entityTypeText = 'all campaigns';
                break;
            case 'CampaignGroup':
                entityTypeText = 'all campaigns in this group';
                break;
            default:
                entityTypeText = 'this campaign';
                break;
            }
            if (!vm.inherited) {
                vm.confirmHeader = 'Are you sure?';
                vm.confirmBody = `This will remove the option for donors to donate through PayPal from ${ entityTypeText }.`;
                vm.confirmButton = 'Remove';
            } else {
                vm.confirmHeader = 'Are you sure?';
                vm.confirmBody = `This will remove PayPal from ${ entityTypeText }. Additionally, any future changes made on a parent group will not affect this ${ vm.openEntityType === 'CampaignGroup' ? 'group' : 'campaign' }.`;
                vm.confirmButton = 'Override';
            }
            vm.confirmFunc = deactivatePayPal;
            vm.showConfirmDialog = true;
        }
        function deactivatePayPal() {
            return payPalService.deactivatePayPal().then(response => {
                if (response.success) {
                    setResponseData(response.data);
                }
            });
        }
        function reconnectPayPal() {
            vm.confirmHeader = 'Are you sure?';
            vm.confirmBody = `This will replace the PayPal account configured on this ${ vm.openEntityType === 'CampaignGroup' ? 'group' : 'campaign' } with the account configured on a parent group.`;
            vm.confirmButton = 'Clear override';
            vm.confirmFunc = adoptParentPayPal;
            vm.showConfirmDialog = true;
        }
        function adoptParentPayPal() {
            return payPalService.reconnectPayPal().then(response => {
                if (response.success) {
                    setResponseData(response.data);
                }
            });
        }
        function setResponseData(data) {
            vm.payPalEmail = data.PayPalEmail;
            vm.payPalMerchantId = data.PayPalMerchantID;
            vm.payPalPartnerHref = data.PartnerUrl ? `${ data.PartnerUrl }&displayMode=minibrowser` : null;
            vm.hasEnabledPayPal = data.HasEnabledPayPal;
            vm.payPalPending = data.State == 5 && data.Enabled;
            vm.inherited = data.Inherited;
            vm.isDefault = data.IsDefault;
            vm.parentName = data.OverriddenParentName;
            let entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
            vm.openEntityType = entity.entityType;
            vm.onOrg = vm.openEntityType == 'Org';
        }
    }
}());
(function () {
    angular.module('app').component('modalAddProcessor', {
        bindings: {
            show: '=',
            admins: '=',
            roles: '=',
            onProcessorAdded: '='
        },
        controller: ModalAddProcessorCtrl,
        controllerAs: 'vm'
    });
    function ModalAddProcessorCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-add-processor.tpl.html',
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: { onProcessorAdded: vm.onProcessorAdded },
                clickOutsideToClose: true
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($scope, $timeout, $q, $mdDialog, $location, $mdConstant, entityService, campaignService, orgProcessorsService, messageService, onProcessorAdded) {
        const vm = this;
        vm.processors = [];
        vm.dataLoaded = false;
        vm.selectedProcessor = undefined;
        vm.onProcessorAdded = onProcessorAdded;
        vm.selectProcessor = function (processor) {
            vm.selectedProcessor = processor;
        };
        vm.isSelected = function (processor) {
            return vm.selectedProcessor === processor;
        };
        loadProcessors();
        function loadProcessors() {
            orgProcessorsService.getAvailableProcessors().then(processors => {
                vm.dataLoaded = true;
                vm.processors = processors;
            }).catch(() => {
                vm.error = 'Unable to load processors. Please contact support';
            });
        }
        ;
        vm.addInProgress = false;
        vm.closeModal = closeModal;
        vm.addNewProcessor = addNewProcessor;
        function closeModal() {
            $mdDialog.hide();
        }
        function addNewProcessor() {
            vm.addInProgress = true;
            var data = {
                processorInstanceId: 0,
                processorId: vm.selectedProcessor.ProcessorId
            };
            orgProcessorsService.addInstance(data).then(data => {
                vm.onProcessorAdded();
                closeModal();
            }).finally(() => vm.addInProgress = false);
        }
    }
}());
(function () {
    angular.module('app').component('processors', {
        templateUrl: 'processors.tpl.html',
        controller: processorsController,
        controllerAs: 'vm'
    });
    function processorsController($scope, orgProcessorsService, routingService, entityService, orgService, configSettingsService, $mdDialog) {
        const vm = this;
        vm.loading = true;
        vm.settingsLoading = true;
        vm.settingsSections = [];
        vm.sections = [];
        vm.processors = [];
        vm.configItem = {};
        vm.processorsConfigId = 12;
        $scope.$on('adoptParent', function (event, configId) {
            vm.loading = true;
            orgProcessorsService.adoptParent().then(setResponseData).finally(() => {
                vm.loading = false;
            });
        });
        $scope.$on('overrideParent', function (event, configId) {
            vm.loading = true;
            orgProcessorsService.overrideParent().then(setResponseData).finally(() => {
                vm.loading = false;
            });
        });
        $scope.$on('clearOverrides', function (event, configId) {
            vm.loading = true;
            orgProcessorsService.clearOverrides().then(setResponseData).finally(() => {
                vm.loading = false;
            });
        });
        vm.loadProcessorsSet = function () {
            orgProcessorsService.getEntityProcessorSettings().then(data => {
                vm.settingsLoading = false;
                setResponseData(data);
                vm.loading = false;
            });
        };
        vm.updateSettingFlag = function (config) {
            return orgProcessorsService.updateProcessorInstanceSetting(config);
        };
        $scope.$on('moveUp', function (event, instanceId) {
            var data = {
                processorInstanceId: instanceId,
                processorId: 0
            };
            vm.loading = true;
            orgProcessorsService.moveInstanceUp(data).then(setResponseData).finally(() => {
                vm.loading = false;
            });
        });
        $scope.$on('moveDown', function (event, instanceId) {
            var data = {
                processorInstanceId: instanceId,
                processorId: 0
            };
            vm.loading = true;
            orgProcessorsService.moveInstanceDown(data).then(setResponseData).finally(() => {
                vm.loading = false;
            });
        });
        $scope.$on('remove', function (event, instanceId) {
            var isLastProcessor = vm.processors.length == 1;
            var entityTypeWarning = '';
            if (vm.entityType == 'Org') {
                entityTypeWarning = isLastProcessor ? 'Campaigns within this organization' : 'any campaign in this organization';
            } else if (vm.entityType == 'CampaignGroup') {
                entityTypeWarning = isLastProcessor ? 'Campaigns within this group' : 'any campaign in this group';
            } else if (vm.entityType == 'Campaign') {
                entityTypeWarning = isLastProcessor ? 'This campaign' : 'this campaign';
            }
            var warningMessage = `Transactions will no longer be processed through this processor on ${ entityTypeWarning }.`;
            if (isLastProcessor) {
                warningMessage = `${ entityTypeWarning } will no longer be able to process transactions.`;
            }
            var confirm = $mdDialog.confirm().title('Are you sure?').textContent(warningMessage).ok('Remove').cancel('Cancel');
            $mdDialog.show(confirm).then(function () {
                var data = {
                    processorInstanceId: instanceId,
                    processorId: 0
                };
                vm.loading = true;
                orgProcessorsService.removeInstance(data).then(setResponseData).finally(() => {
                    vm.loading = false;
                });
            }, function () {
            });
        });
        vm.loadProcessorsSet();
        function setResponseData(data) {
            var configItem = data.configItem;
            vm.inherited = configItem.Inherited;
            vm.isDefault = configItem.IsDefault;
            vm.parentName = configItem.OverriddenParentEntityName;
            vm.processors = data.processors;
            vm.processors.forEach(function (processor) {
                if (processor.SettingForm && processor.SettingForm.length) {
                    processor.SettingForm[0].HideHeader = true;
                }
            });
            vm.configItem = configItem;
            let entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
            vm.entityType = entity.entityType;
            vm.onOrg = vm.entityType == 'Org';
        }
    }
}());
(function () {
    var routeConfig = [
        {
            path: '/integrations',
            name: 'Integrations',
            component: 'integrations',
            useAsDefault: true,
            data: { resource: 'SETTINGS_INTEGRATION_OPTIONS' }
        },
        {
            path: '/template',
            name: 'Templates',
            component: 'liquidTemplate',
            data: {
                showFunc: function () {
                    return false;
                }
            }
        },
        {
            path: '/paymentProcessor',
            name: 'Payment Processors',
            component: 'paymentProcessors',
            data: {
                resource: 'SETTINGS_DONATION_PROCESSOR',
                limitToCampaign: true,
                hideOnNewOrgs: true,
                routeFunc: function (entity) {
                    return location.search.indexOf('pass') >= 0;
                }
            }
        },
        {
            path: '/neonPay',
            name: 'Neon Pay Account',
            component: 'neonPay',
            data: { resource: 'SETTINGS_DONATION_PROCESSOR' }
        },
        {
            path: '/processors',
            name: 'Processors',
            component: 'processors',
            data: { resource: 'SETTINGS_DONATION_PROCESSOR' }
        },
        {
            path: '/advanced',
            name: 'Advanced',
            component: 'systemAdvanced',
            data: { anyResource: ['SYSTEM_NOTIFICATIONS'] }
        },
        {
            path: '/advancedAdmin',
            name: 'Advanced Admin',
            component: 'advancedAdmin',
            data: {
                showFunc: function () {
                    return false;
                }
            }
        }
    ];
    angular.module('app').component('settings', {
        templateUrl: 'settings.tpl.html',
        controllerAs: 'vm',
        controller: settingsController,
        require: { parentComponent: '^campaigns' },
        $routeConfig: routeConfig,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    angular.module('app').constant('systemRouteConfig', routeConfig);
    function settingsController($location, routingService, entityService, orgService, $rootScope, extJsLoader, iframeMessageHandler) {
        const vm = this;
        vm.navs = routeConfig;
        vm.iframeData = {};
        vm.showIframe = false;
        this.$routerOnDeactivate = function (p, n) {
            extJsLoader.extJsController.iframeCreated = false;
        };
        routingService.updateRouting().then(r => {
            vm.openEntity = r;
            let entity = entityService.getRawEntityById(r);
            if (entity) {
                vm.onGroup = entity.entityType === 'CampaignGroup';
                vm.onOrg = entity.entityType === 'Org';
                vm.onCampaign = !vm.onGroup && !vm.onOrg;
                if (entity.curEventId) {
                    addOnSite(entity);
                    vm.iframeSrc = `/site/${ entity.curEventId }?${ location.search.indexOf('pass') > -1 ? `pass=true&` : `` }type=plat&value=`;
                    if (!extJsLoader.extJsController.iframeCreated) {
                        vm.loading = true;
                        vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'settings');
                    } else {
                        vm.loading = false;
                    }
                    iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
                }
            }
        });
        function addOnSite(entity) {
            if (entity.settings.EnableOnSite) {
                angular.element('side-nav').find('ul').append(`<li><a href="/site/${ entity.curEventId }/Here" target="_blank">On Site</a></li>`);
            }
        }
        $rootScope.$on('loaded', r => {
            extJsLoader.extJsController.loadInnerPage().then(r => vm.loading = false);
        });
        vm.$onInit = function () {
            vm.parentComponent.onSettingsTab = true;
        };
    }
}());
(function () {
    angular.module('app').component('systemAdvanced', {
        controller: systemAdvancedController,
        template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'></form-template>`,
        bindings: { $router: '<' },
        require: { parentComponent: '^settings' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function systemAdvancedController(extJsLoader, configSettingsService) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
        };
        vm.sections = [];
        configSettingsService.getSystemAdvancedSettings().then(function (r) {
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            } else {
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
    }
}());
(function () {
    angular.module('app').component('creditModal', {
        bindings: {
            showModal: '=',
            targetEvent: '<',
            creditForTransactions: '<',
            amount: '<',
            memberId: '<',
            donorCampaignId: '<',
            endurancePledgeId: '<'
        },
        controller: creditModalCtrl,
        controllerAs: 'vm'
    });
    function creditModalCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.showModal', () => {
            if (vm.showModal) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'add-edit-modal.tpl.html',
                escapeToClose: false,
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                multiple: true,
                targetEvent: vm.targetEvent,
                locals: {
                    parent: $scope,
                    creditForTransactions: vm.creditForTransactions,
                    amount: vm.amount,
                    memberId: vm.memberId,
                    donorCampaignId: vm.donorCampaignId,
                    endurancePledgeId: vm.endurancePledgeId
                }
            }).catch(() => {
            }).finally(() => {
                vm.showModal = false;
            });
        }
    }
    function ModalContentCtrl($q, $rootScope, $scope, $element, $mdDialog, $timeout, creditService, formService, helpersService, creditForTransactions, amount, memberId, donorCampaignId, endurancePledgeId) {
        const vm = this;
        vm.item = undefined;
        vm.form = {};
        vm.errors = {};
        creditService.getNewCreditForm().then(r => {
            vm.item = r.item.Card;
            vm.item.InPopup = true;
            let usersField = formService.getFieldFromItem(vm.item, 'Existing User');
            usersField.loading = true;
            creditService.getExistingUsers().then(r => {
                let memberIdsToUserAccountIds = {};
                usersField.loading = false;
                usersField.options = _.reduce(r.items, (result, value) => {
                    value = helpersService.normalizeUserPropertyNames(value);
                    result[value.UserAccountID] = `<div>${ helpersService.getUserDisplayName(value, [
                        'UserAccountId',
                        'Email'
                    ]) }</div>`;
                    memberIdsToUserAccountIds[value.MemberID] = value.UserAccountID;
                    return result;
                }, {});
                if (memberId && parseInt(memberId)) {
                    // Use timeout so that the update that shows this field have already gone through the cycle
                    $timeout(() => {
                        formService.setFieldValueOnItem(vm.item, 'Existing User', memberIdsToUserAccountIds[memberId]);
                    });
                }
            });
            let debitsField = formService.getFieldFromItem(vm.item, 'Debits');
            debitsField.change = (item, value) => {
                var amountField = formService.getFieldFromItem(item, 'Amount');
                if (!vm.form['Amount'].$dirty) {
                    amountField.value = value.totalUnfulfilledAmount;
                }
            };
            if (creditForTransactions) {
                debitsField.value = { ids: creditForTransactions };
            }
            if (amount && parseInt(amount)) {
                formService.setFieldValueOnItem(vm.item, 'Amount', parseInt(amount));
            }
            if (memberId && parseInt(memberId)) {
                formService.setFieldValueOnItem(vm.item, 'New or Existing User', 'Existing');
            }
            if (donorCampaignId && parseInt(donorCampaignId)) {
                formService.setFieldValueOnItem(vm.item, 'DonorCampaignId', donorCampaignId, true, 'NumberInt', { hidden: true });
            }
            if (endurancePledgeId && parseInt(endurancePledgeId)) {
                formService.setFieldValueOnItem(vm.item, 'EndurancePledgeId', endurancePledgeId, true, 'NumberInt', { hidden: true });
            }
        });
        vm.cancel = closeModal;
        vm.nextGroup = nextGroup;
        vm.add = addCredit;
        vm.addButtonText = 'Add Credit';
        vm.newTitleText = 'New Credit';
        function closeModal() {
            $rootScope.$broadcast('CreditModalClosed');
            $mdDialog.hide();
        }
        function nextGroup() {
            $scope.$broadcast('nextGroup');
        }
        function addCredit() {
            if (vm.validateActiveGroup()) {
                return creditService.addCredit(vm.item).then(notifyScopeOfNewCredit).then(closeModal, () => $q.reject());
            } else {
                return $q.reject();
            }
        }
        function notifyScopeOfNewCredit() {
            $rootScope.$broadcast('NewCreditAdded');
        }
        $scope.$watch('vm.form.$valid', function (valid) {
            if (!valid) {
                $scope.$broadcast('markGroupIncomplete', vm.form.activeGroup);
            }
        });
        $scope.$on('closeModal', function (event) {
            closeModal();
        });
    }
}());
(function () {
    angular.module('app').component('credits', {
        controller: creditsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^transactions' },
        templateUrl: `credits.tpl.html`,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function creditsController(agGridService, entityService, securityService, settingsService, creditService, helpersService, formService, $element, $q, $rootScope, $scope, $location) {
        const vm = this;
        var entity = entityService.getOpenEntity();
        vm.openRowBeforeChanges = {};
        vm.entityType = entity.entityType;
        vm.security = securityService.getAdminPermissions(entity, 'CREDITS');
        vm.showCreditModal = false;
        vm.hasNewCreditInRoute = false;
        $rootScope.$on('addNewSelected', ($event, targetEvent) => {
            showNewCreditModal();
            vm.creditModalTargetEvent = targetEvent;
        });
        vm.$onInit = function () {
            if (vm.entityType === 'Campaign') {
                vm.parentComponent.campaigns.updateShowAddBtn(vm.security.EDIT, 'addCredit', true, 'Add Credit');
            }
        };
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
            this.parentComponent.loading = false;
            if (next.routeData.data && next.routeData.data.showNewForm) {
                vm.hasNewCreditInRoute = true;
                showNewCreditModal(next.params.ids, next.params.amount, next.params.memberId, next.params.donorCampaignId);
            }
        };
        this.$routerOnDeactivate = function (p, n) {
            vm.parentComponent.campaigns.updateShowAddBtn(false, 'addCredit');
        };
        function showNewCreditModal(preselectCreditForTransactions, preselectCreditAmount, preselectCreditMemberId, preselectedDonorCampaignId) {
            vm.preselectCreditForTransactions = preselectCreditForTransactions;
            vm.preselectCreditAmount = preselectCreditAmount;
            vm.preselectCreditMemberId = preselectCreditMemberId;
            vm.preselectedDonorCampaignId = preselectedDonorCampaignId;
            vm.showCreditModal = true;
        }
        vm.loadRow = function (params) {
            vm.openRowBeforeChanges = _.cloneDeep(vm.grid.openRow);
        };
        vm.updateRow = function (updatedItem) {
            let updatedRow = vm.grid.api.getRowNode(updatedItem.Id);
            if (updatedRow) {
                updatedRow.setData(updatedItem);
            }
        };
        vm.cancelRow = function (updatedItem) {
            vm.updateRow(updatedItem);
        };
        vm.afterSave = function (updatedItem) {
            vm.updateRow(updatedItem);
        };
        vm.afterSaveField = function (updatedItem) {
            vm.updateRow(updatedItem);
        };
        vm.saveClosedRow = function (params, closedRow) {
            if (closedRow && closedRow.data && closedRow.data.saveIfChanges) {
                return closedRow.data.saveIfChanges(true);
            }
            return $q.resolve();
        };
        vm.deleteCredit = function (credit) {
            return creditService.deleteCredit(credit).then(() => {
                vm.grid.closeOpenRow();
                vm.refreshRows();
            });
        };
        vm.saveCredit = function (credit) {
            return creditService.saveCredit(credit).then(x => {
                vm.grid.openRow.node.setData(x.item);
                vm.setItemTitle(credit, x.item);
            });
        };
        vm.openCredit = function (credit) {
            vm.setItemTitle(credit, vm.grid.openRow.data);
        };
        vm.refreshRows = function () {
            vm.grid.api.refreshServerSideStore({ purge: vm.grid.totalResults == 0 });
        };
        vm.cardActions = [];
        vm.getRequiredData = function () {
            let data = { paymentTypes: settingsService.getPaymentTypes() };
            return $q.all(data);
        };
        vm.setItemTitle = function (credit, fullItem) {
            credit.Title = `${ fullItem.FormattedAmount } by ${ helpersService.getUserDisplayName(fullItem) }`;
        };
        vm.grid = agGridService.createNewGrid([], {
            domLayout: 'autoHeight',
            sideBar: false,
            advancedFiltering: true,
            gridElement: $element.find('.ag-master-grid'),
            expandedCardElement: $element.find('.ag-external-record-row'),
            noRowsMessage: 'Credits will show up here.',
            getRowNodeId: data => data.CreditId,
            datasource: agGridService.getDatasource('Credit', 'All'),
            defaultColDef: {
                flex: 1,
                minWidth: 160
            }
        });
        vm.getRequiredData().then(data => {
            let columnDefs = [
                {
                    field: 'CreditId',
                    headerName: 'ID',
                    filter: 'agNumberColumnFilter',
                    hide: true
                },
                {
                    field: 'Donor',
                    headerName: 'Donor',
                    valueGetter: params => helpersService.getUserDisplayName(params.data),
                    filter: false,
                    sortable: false
                },
                {
                    field: 'FirstName',
                    headerName: 'First Name',
                    filter: 'agTextColumnFilter',
                    hide: true
                },
                {
                    field: 'LastName',
                    headerName: 'Last Name',
                    filter: 'agTextColumnFilter',
                    hide: true
                },
                {
                    field: 'CompanyName',
                    headerName: 'Company Name',
                    filter: 'agTextColumnFilter',
                    hide: true
                },
                agGridService.columnDefinitions.boolean({
                    field: 'IsVerified',
                    headerName: 'Is Verified',
                    filter: true,
                    sortable: false
                }, {
                    true: 'Verified',
                    false: 'Not Verified'
                }),
                agGridService.columnDefinitions.boolean({
                    field: 'IsCompanyTransaction',
                    headerName: 'Company Transaction',
                    filter: true,
                    sortable: false,
                    hide: true
                }),
                {
                    field: 'Amount',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: function (params) {
                        if (!params.data)
                            return params.value;
                        return params.data.FormattedAmount;
                    },
                    filterParams: {
                        cellRenderer: function (params) {
                            return params.data.FormattedAmount;
                        }
                    }
                },
                {
                    field: 'PaymentType',
                    headerName: 'Payment Method',
                    filter: true,
                    hide: true,
                    valueFormatter: params => data.paymentTypes[params.value],
                    filterParams: {
                        values: Object.keys(data.paymentTypes),
                        valueFormatter: params => data.paymentTypes[params.value]
                    }
                },
                {
                    field: 'PaymentDateString',
                    headerName: 'Date',
                    hide: true,
                    filter: 'agDateColumnFilter',
                    valueFormatter: agGridService.formatters.dateTime
                }
            ];
            vm.grid.api.setColumnDefs(columnDefs);
        });
        $scope.$on('NewCreditAdded', () => {
            if (vm.hasNewCreditInRoute) {
                vm.hasNewCreditInRoute = false;
                vm.$router.navigate(['Credits']);
            } else {
                vm.refreshRows();
            }
        });
    }
}());
(function () {
    angular.module('app').component('donations', {
        templateUrl: 'donations.tpl.html',
        controller: donationsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^transactions' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function donationsController(extJsLoader, $mdDialog, $location, iframeMessageHandler) {
        const vm = this;
        var verfiDonationAlert;
        if ($location.search()['msg'] === 'VerifyDonation') {
            var actionSuccess = $location.search()['success'] && $location.search()['success'].toLowerCase() === 'true';
            var alreadyVerified = $location.search()['alreadyVerified'] && $location.search()['alreadyVerified'].toLowerCase() === 'true';
            var finalMsg = '';
            if (actionSuccess) {
                finalMsg = 'Great! The donation was successfully verified.';
            } else if (alreadyVerified) {
                finalMsg = 'This donation was already verified.';
            } else {
                finalMsg = 'Error: We could not find this donation.';
            }
            verfiDonationAlert = $mdDialog.alert({
                title: 'Verify Donation',
                htmlContent: finalMsg,
                ok: 'Close'
            });
            $mdDialog.show(verfiDonationAlert).finally(function () {
                verfiDonationAlert = undefined;
            });
        }
        if ($location.search()['msg'] === 'CompleteDafDistribution') {
            var actionSuccess = $location.search()['success'] && $location.search()['success'].toLowerCase() === 'true';
            var alreadyCompleted = $location.search()['alreadyCompleted'] && $location.search()['alreadyCompleted'].toLowerCase() === 'true';
            var finalMsg = '';
            if (actionSuccess) {
                if (alreadyCompleted) {
                    finalMsg = 'The donation was already completed.';
                } else {
                    finalMsg = 'Great! The donation was successfully completed.';
                }
            } else {
                finalMsg = 'Error completing DAF distribution.';
            }
            verfiDonationAlert = $mdDialog.alert({
                title: 'Complete DAF Donation',
                htmlContent: finalMsg,
                ok: 'Close'
            });
            $mdDialog.show(verfiDonationAlert).finally(function () {
                verfiDonationAlert = undefined;
            });
        }
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Transactions-Donations');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('donationsRecurring', {
        templateUrl: 'donationsRecurring.tpl.html',
        controller: donationsRecurringController,
        controllerAs: 'vm',
        require: { parentComponent: '^transactions' },
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function donationsRecurringController(extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Transactions-DonationsRecurring');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('donorCampaigns', {
        controllerAs: 'vm',
        controller: donorCampaignsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^transactions' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function donorCampaignsController(extJsLoader) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Transactions-DonorCampaigns');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('endurancePledgeModal', {
        bindings: {
            showModal: '=',
            targetEvent: '<'
        },
        controller: endurancePledgeModalCtrl,
        controllerAs: 'vm'
    });
    function endurancePledgeModalCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.showModal', () => {
            if (vm.showModal) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'add-edit-modal.tpl.html',
                escapeToClose: false,
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                multiple: true,
                targetEvent: vm.targetEvent,
                locals: { parent: $scope }
            }).catch(() => {
            }).finally(() => {
                vm.showModal = false;
            });
        }
    }
    function ModalContentCtrl($q, $rootScope, $scope, $element, $mdDialog, $timeout, endurancePledgeService, formService, helpersService) {
        const vm = this;
        vm.item = undefined;
        vm.form = {};
        vm.errors = {};
        endurancePledgeService.getNewPledgeForm().then(r => {
            vm.item = r.item.Card;
            vm.item.InPopup = true;
            let usersField = formService.getFieldFromItem(vm.item, 'Existing User');
            usersField.loading = true;
            endurancePledgeService.getExistingUsers().then(r => {
                usersField.loading = false;
                usersField.options = _.reduce(r.items, (result, value) => {
                    value = helpersService.normalizeUserPropertyNames(value);
                    result[value.MemberId] = `<div>${ helpersService.getUserDisplayName(value, [
                        'MemberId',
                        'Email'
                    ]) }</div>`;
                    return result;
                }, {});
            });
        });
        vm.cancel = closeModal;
        vm.nextGroup = nextGroup;
        vm.add = addPledge;
        vm.addButtonText = 'Add Endurance Pledge';
        vm.newTitleText = 'New Endurance Pledge';
        function closeModal() {
            $mdDialog.hide();
        }
        function nextGroup() {
            $scope.$broadcast('nextGroup');
        }
        function addPledge() {
            if (vm.validateActiveGroup()) {
                return endurancePledgeService.addPledge(vm.item).then(notifyScopeOfNewPledge).then(closeModal, () => $q.reject());
            } else {
                return $q.reject();
            }
        }
        function notifyScopeOfNewPledge() {
            $rootScope.$broadcast('NewEndurancePledgeAdded');
        }
        $scope.$watch('vm.form.$valid', function (valid) {
            if (!valid) {
                $scope.$broadcast('markGroupIncomplete', vm.form.activeGroup);
            }
        });
        $scope.$on('closeModal', function (event) {
            closeModal();
        });
    }
}());
(function () {
    angular.module('app').component('endurancePledges', {
        controller: endurancePledgesController,
        bindings: { $router: '<' },
        require: { parentComponent: '^transactions' },
        templateUrl: `endurance-pledges.tpl.html`,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function endurancePledgesController(agGridService, entityService, securityService, settingsService, endurancePledgeService, helpersService, formService, $element, $q, $rootScope, $scope, $mdDialog, $location) {
        const vm = this;
        var entity = entityService.getOpenEntity();
        vm.openRowBeforeChanges = {};
        vm.entityType = entity.entityType;
        vm.security = securityService.getAdminPermissions(entity, 'ENDURANCE_PLEDGES');
        vm.showPledgeModal = false;
        vm.showCreditModal = false;
        vm.creditModalTargetEvent = null;
        vm.preselectCredit = {};
        $rootScope.$on('addNewSelected', ($event, targetEvent) => {
            vm.showPledgeModal = true;
            vm.endurancePledgeModalTargetEvent = targetEvent;
        });
        $scope.$on('CreditModalClosed', () => {
            vm.creditModalTargetEvent = null;
            vm.preselectCredit = {};
            vm.showCreditModal = false;
        });
        $scope.$on('NewCreditAdded', () => {
            vm.grid.closeOpenRow();
            vm.refreshRows();
        });
        $scope.$on('NewEndurancePledgeAdded', () => vm.refreshRows());
        vm.$onInit = function () {
            if (vm.entityType === 'Campaign') {
                vm.parentComponent.campaigns.updateShowAddBtn(vm.security.EDIT, 'addPledge', true, 'Add Endurance Pledge');
            }
        };
        this.$routerOnActivate = function (next, prev) {
            this.parentComponent.showIframe = false;
            this.parentComponent.loading = false;
        };
        this.$routerOnDeactivate = function (p, n) {
            vm.parentComponent.campaigns.updateShowAddBtn(false, 'addPledge');
        };
        vm.loadRow = function (params) {
            vm.openRowBeforeChanges = _.cloneDeep(vm.grid.openRow);
        };
        vm.updateRow = function (updatedItem) {
            let updatedRow = vm.grid.api.getRowNode(updatedItem.Id);
            if (updatedRow) {
                updatedRow.setData(updatedItem);
            }
        };
        vm.cancelRow = function (updatedItem) {
            vm.updateRow(updatedItem);
        };
        vm.afterSave = function (updatedItem) {
            vm.updateRow(updatedItem);
        };
        vm.afterSaveField = function (updatedItem) {
            vm.updateRow(updatedItem);
        };
        vm.saveClosedRow = function (params, closedRow) {
            if (closedRow && closedRow.data && closedRow.data.saveIfChanges) {
                return closedRow.data.saveIfChanges(true);
            }
            return $q.resolve();
        };
        vm.deletePledge = function (pledge) {
            return endurancePledgeService.deletePledge(pledge).then(() => {
                vm.grid.closeOpenRow();
                vm.refreshRows();
            });
        };
        vm.savePledge = function (pledge) {
            return endurancePledgeService.savePledge(pledge).then(x => {
                vm.grid.openRow.node.setData(x.item);
                vm.setItemTitle(pledge, x.item);
            });
        };
        vm.endPledge = function (pledge, $event) {
            return $mdDialog.show($mdDialog.confirm().clickOutsideToClose(true).title('Confirm End Pledge').htmlContent(`Are you sure you want to end ${ pledge.Title }?`).targetEvent($event).ok('End Pledge').cancel('Cancel')).then(() => endurancePledgeService.endPledge(pledge).then(x => {
                vm.grid.openRow.node.setData(x.item);
                vm.setItemTitle(pledge, x.item);
                vm.grid.closeOpenRow();
            }));
        };
        vm.chargePledge = function (pledge, $event) {
            endurancePledgeService.chargePledge(pledge).then(response => {
                if (response.success) {
                    vm.preselectCredit = response.item;
                    vm.creditModalTargetEvent = $event;
                    vm.showCreditModal = true;
                } else {
                    messageService.showErrorToast(response.msg || 'Unknown Error Unable to retrieve Pledges');
                }
            });
        };
        vm.openPledge = function (pledge) {
            vm.setItemTitle(pledge, vm.grid.openRow.data);
        };
        vm.refreshRows = function () {
            vm.grid.api.refreshServerSideStore({ purge: vm.grid.totalResults == 0 });
        };
        vm.cardActions = [
            {
                label: 'End pledge',
                action: (item, $event) => vm.endPledge(item),
                isDisabled: item => !item.IsActive
            },
            {
                label: 'Add Payment',
                action: (item, $event) => vm.chargePledge(item, $event),
                isDisabled: item => !item.DynamicOption
            }
        ];
        vm.getRequiredData = function () {
            let data = { endurancePledgeState: endurancePledgeService.endurancePledgeState };
            return $q.all(data);
        };
        vm.setItemTitle = function (pledge, fullItem) {
            pledge.Title = `${ helpersService.getUserDisplayName(fullItem) } Pledge for ${ fullItem.ChallengeName }`;
        };
        vm.grid = agGridService.createNewGrid([], {
            domLayout: 'autoHeight',
            sideBar: false,
            advancedFiltering: true,
            gridElement: $element.find('.ag-master-grid'),
            expandedCardElement: $element.find('.ag-external-record-row'),
            noRowsMessage: 'Endurance Pledges will show up here.',
            getRowNodeId: data => data.EndurancePledgeId,
            datasource: agGridService.getDatasource('EndurancePledge', 'All'),
            defaultColDef: {
                flex: 1,
                minWidth: 160
            }
        });
        vm.getRequiredData().then(data => {
            let columnDefs = [
                {
                    field: 'EndurancePledgeId',
                    headerName: 'ID',
                    filter: 'agNumberColumnFilter',
                    hide: true
                },
                {
                    field: 'CreatedOnString',
                    headerName: 'Creation Date',
                    filter: 'agDateColumnFilter',
                    valueFormatter: agGridService.formatters.dateTime
                },
                {
                    field: 'EndurancePledgeState',
                    headerName: 'Status',
                    filter: true,
                    valueFormatter: params => data.endurancePledgeState[params.value],
                    filterParams: {
                        values: Object.keys(data.endurancePledgeState),
                        valueFormatter: params => data.endurancePledgeState[params.value]
                    }
                },
                {
                    field: 'Donor',
                    headerName: 'Donor',
                    valueGetter: params => helpersService.getUserDisplayName(params.data),
                    filter: false,
                    sortable: false
                },
                {
                    field: 'FirstName',
                    headerName: 'First Name',
                    filter: 'agTextColumnFilter',
                    hide: true
                },
                {
                    field: 'LastName',
                    headerName: 'Last Name',
                    filter: 'agTextColumnFilter',
                    hide: true
                },
                {
                    field: 'CompanyName',
                    headerName: 'Company Name',
                    filter: 'agTextColumnFilter',
                    hide: true
                },
                {
                    field: 'AmountPerMetric',
                    headerName: 'Pledge amount per metric',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: function (params) {
                        if (!params.data)
                            return params.value;
                        return params.data.FormattedAmountPerMetric;
                    },
                    filterParams: {
                        cellRenderer: function (params) {
                            return params.data.FormattedAmountPerMetric;
                        }
                    }
                },
                {
                    field: 'ChallengeName',
                    headerName: 'Pledge for',
                    filter: 'agTextColumnFilter'
                },
                {
                    field: 'TotalPledged',
                    headerName: 'Current pledge amount',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: function (params) {
                        if (!params.data)
                            return params.value;
                        return params.data.FormattedTotalPledgedAmount;
                    },
                    filterParams: {
                        cellRenderer: function (params) {
                            return params.data.FormattedCurrentPledgeAmount;
                        }
                    }
                },
                {
                    field: 'LimitToTotalAmount',
                    headerName: 'Maximum pledge amount',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: function (params) {
                        if (!params.data)
                            return params.value;
                        return params.data.FormattedLimitToTotalAmount;
                    },
                    filterParams: {
                        cellRenderer: function (params) {
                            return params.data.FormattedPledgeAmount;
                        }
                    }
                },
                {
                    field: 'LimitToAfterDateTimeString',
                    headerName: 'Start Date',
                    filter: 'agDateColumnFilter',
                    valueFormatter: agGridService.formatters.dateTime,
                    hide: true
                },
                {
                    field: 'LimitToBeforeDateTimeString',
                    headerName: 'End Date',
                    filter: 'agDateColumnFilter',
                    valueFormatter: agGridService.formatters.dateTime,
                    hide: true
                }
            ];
            vm.grid.api.setColumnDefs(columnDefs);
        });
    }
}());
(function () {
    angular.module('app').component('registrations', {
        templateUrl: 'registrations.tpl.html',
        controller: registrationsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^transactions' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function registrationsController(extJsLoader, iframeMessageHandler) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Transactions-Registration');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('tickets', {
        templateUrl: 'tickets.tpl.html',
        controller: ticketsController,
        bindings: { $router: '<' },
        require: { parentComponent: '^transactions' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function ticketsController(extJsLoader, iframeMessageHandler) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Transactions-Transtickets');
            this.parentComponent.showIframe = true;
        };
    }
}());
(function () {
    var routeConfig = [
        {
            path: '/donations',
            name: 'Donations',
            component: 'donations',
            useAsDefault: true,
            data: { resource: 'DONATION' }
        },
        {
            path: '/recurringDonations',
            name: 'Recurring Donations',
            component: 'donationsRecurring',
            data: {
                resource: 'DONATION_RECURRING',
                routeFunc: function (entity) {
                    return entity.settings.EverHadRecurring && entity.type != 'Standalone Ticketing';
                }
            }
        },
        {
            path: '/registrations',
            name: 'Registrations',
            component: 'registrations',
            data: {
                resource: 'TRANSACTION_REGISTRATIONS',
                routeFunc: function (entity) {
                    return entity.settings.HasRoleRegistration;
                }
            }
        },
        {
            path: '/matches',
            name: 'Matches',
            component: 'donorCampaigns',
            data: {
                resource: 'DONOR_CAMPAIGNS',
                routeFunc: function (entity) {
                    return entity.settings.FundraisingSurgeApplicable;
                }
            }
        },
        {
            path: '/tickets',
            name: 'Tickets',
            component: 'tickets',
            data: {
                resource: 'TRANSACTIONS_TICKETS',
                routeFunc: function (entity) {
                    return entity.settings.EventHasTickets && entity.type != 'Donation' && entity.type != 'Global Donation Page';
                }
            }
        },
        {
            path: '/credits',
            name: 'Credits',
            component: 'credits',
            data: { resource: 'CREDITS' }
        },
        {
            path: '/credits/new/:ids',
            name: 'New Credit',
            component: 'credits',
            data: {
                showNewForm: true,
                resource: 'CREDITS',
                showFunc: function () {
                    return false;
                }
            }
        },
        {
            path: '/endurancePledges',
            name: 'Endurance Pledges',
            component: 'endurancePledges',
            data: {
                resource: 'ENDURANCE_PLEDGES',
                routeFunc: function (entity) {
                    return entity.settings.EndurancePledgesEnabled;
                }
            }
        }
    ];
    angular.module('app').component('transactions', {
        templateUrl: 'transactions.tpl.html',
        controllerAs: 'vm',
        bindings: { $router: '<' },
        require: { campaigns: '^campaigns' },
        controller: transactionsController,
        $routeConfig: routeConfig,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    angular.module('app').constant('transactionRouteConfig', routeConfig);
    function transactionsController($rootScope, routingService, entityService, extJsLoader, iframeMessageHandler, $timeout, $window) {
        const vm = this;
        vm.loading = true;
        vm.navs = routeConfig;
        vm.iframeData = {};
        vm.showIframe = false;
        vm.showMoveDonationsModal = false;
        vm.showMoveRecurringDonationsModal = false;
        vm.entity = {};
        this.$routerOnDeactivate = function (p, n) {
            extJsLoader.extJsController.iframeCreated = false;
        };
        routingService.updateRouting().then(r => {
            vm.openEntity = r;
            let entity = entityService.getRawEntityById(r);
            if (entity) {
                vm.entity = entity;
                vm.onGroup = entity.entityType === 'CampaignGroup';
                vm.onOrg = entity.entityType === 'Org';
                if (entity.curEventId) {
                    vm.iframeSrc = `/site/${ entity.curEventId }?type=plat&value=`;
                    if (!extJsLoader.extJsController.iframeCreated) {
                        vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'transactions');
                    } else {
                        vm.loading = false;
                    }
                    iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
                }
            }
        });
        $rootScope.$on('moveDonations', (event, data) => {
            $timeout(function () {
                if (data.length > 0) {
                    var donationIds = [];
                    data.forEach(d => {
                        donationIds.push(d.DonationID);
                    });
                    vm.objectIds = donationIds;
                    vm.objectType = 'moveDonations';
                    vm.objectEventId = vm.entity.curEventId;
                    vm.objectEntityId = vm.entity.entityId;
                    vm.showMoveDonationsModal = true;
                }
            });
        });
        $rootScope.$on('moveRecurringDonations', (event, data) => {
            $timeout(function () {
                if (data.length > 0) {
                    var donationIds = [];
                    data.forEach(d => {
                        donationIds.push(d.DonationRecurringID);
                    });
                    vm.objectIds = donationIds;
                    vm.objectType = 'moveRecurringDonations';
                    vm.objectEventId = vm.entity.curEventId;
                    vm.objectEntityId = vm.entity.entityId;
                    vm.showMoveRecurringDonationsModal = true;
                }
            });
        });
        $rootScope.$on('fulfillDonationPledges', (event, data) => {
            let params = {};
            if (data.fullfilType == 'donation') {
                params.ids = _.map(data.payments, x => x.PaymentID);
                params.amount = _.sumBy(data.payments, 'Amount');
                if (data.payments[0] && data.payments[0].DonatorID && _.every(data.payments, [
                        'DonatorID',
                        data.payments[0].DonatorID
                    ])) {
                    params.memberId = data.payments[0].DonatorID;
                }
            } else if (data.fullfilType == 'match') {
                params.ids = data.ids;
                params.amount = data.amount;
                params.memberId = data.memberId;
                params.donorCampaignId = data.donorCampaignId;
            }
            let instruction = vm.$router.generate([
                'New Credit',
                params
            ]);
            let url = window.baseUrl + angular.stringifyInstruction(instruction);
            $window.open(url, '_blank');
        });
        $rootScope.$on('loaded', r => {
            extJsLoader.extJsController.loadInnerPage().then(r => {
                vm.loading = false;
                extJsLoader.extJsController.checkParams();
            });
        });
    }
}());
(function () {
    angular.module('app').component('blog', {
        controller: blogController,
        bindings: { $router: '<' },
        require: { website: '^website' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function blogController(extJsLoader, iframeMessageHandler) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Content-Feed');
            this.website.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('dictionary', {
        controller: dictionaryController,
        templateUrl: 'dictionary.tpl.html',
        bindings: { $router: '<' },
        require: { website: '^website' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function dictionaryController(entityContentService, orgService, entityService) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.website.showIframe = false;
            this.website.loading = false;
        };
        vm.entityType = entityService.getOpenEntity().entityType;
        vm.termType = 'Dictionary';
        vm.getData = getData;
        vm.loading = true;
        function getData() {
            dataRequest().then(items => {
                vm.grid = items;
            }).catch(() => {
            }).finally(() => vm.loading = false);
        }
        function dataRequest() {
            return entityContentService.getAllTerms(vm.website.openEntity, vm.selectedLanguage);
        }
        orgService.orgInfoLoadedPromise.then(r => {
            entityService.getEntitySettings(vm.website.openEntity).then(entity => {
                vm.languages = entity.settings.LanguagesKeyValue;
                vm.selectedLanguage = entity.settings.DefaultLanguage;
                vm.isMultiLanguage = entity.settings.IsMultiLanguage;
                getData();
            });
        });
        vm.clicked = function (item) {
            return vm.saveTerm(item, false);
        };
        vm.open = function (item) {
            entityContentService.getTemplatePlaceholders(vm.website.openEntity, item.Flags).then(items => CKEDITOR.config.macros = items);
        };
        vm.overrideParent = function (item) {
            return vm.saveTerm(item, true);
        };
        vm.saveTerm = function (item, createDuplicate) {
            return entityContentService.updateTerm(vm.website.openEntity, item, vm.termType, vm.selectedLanguage, createDuplicate);
        };
        vm.adoptParent = function (item) {
            return entityContentService.deleteTerm(vm.website.openEntity, item, vm.termType, vm.selectedLanguage).then(() => dataRequest());
        };
    }
}());
(function () {
    angular.module('app').component('faq', {
        controller: faqController,
        bindings: { $router: '<' },
        require: { website: '^website' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function faqController(extJsLoader, iframeMessageHandler) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Content-Faq');
            this.website.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('mediaLibrary', {
        controller: mediaLibraryController,
        bindings: { $router: '<' },
        require: { website: '^website' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function mediaLibraryController(extJsLoader, iframeMessageHandler) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Content-Images');
            this.website.showIframe = true;
        };
    }
}());
(function () {
    angular.module('app').component('socialShares', {
        controller: socialSharesController,
        templateUrl: 'socialShares.tpl.html',
        bindings: { $router: '<' },
        require: { website: '^website' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function socialSharesController(entityContentService, orgService, entityService) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.website.showIframe = false;
            this.website.loading = false;
        };
        vm.entityType = entityService.getOpenEntity().entityType;
        vm.termType = 'Social';
        vm.getData = getData;
        vm.loading = true;
        function getData() {
            dataRequest().then(items => {
                vm.grid = items;
            }).catch(() => {
            }).finally(() => vm.loading = false);
        }
        function dataRequest() {
            return entityContentService.getSocialShares(vm.website.openEntity, vm.selectedLanguage);
        }
        orgService.orgInfoLoadedPromise.then(r => {
            entityContentService.getTemplatePlaceholders(vm.website.openEntity, 32).then(items => {
                CKEDITOR.config.macros = items;
                entityService.getEntitySettings(vm.website.openEntity).then(entity => {
                    vm.languages = entity.settings.LanguagesKeyValue;
                    vm.selectedLanguage = entity.settings.DefaultLanguage;
                    vm.isMultiLanguage = entity.settings.IsMultiLanguage;
                    getData();
                });
            });
        });
        vm.clicked = function (item) {
            return vm.saveTerm(item, false);
        };
        vm.overrideParent = function (item) {
            return vm.saveTerm(item, true);
        };
        vm.saveTerm = function (item, createDuplicate) {
            return entityContentService.updateTerm(vm.website.openEntity, item, vm.termType, vm.selectedLanguage, createDuplicate);
        };
        vm.adoptParent = function (item) {
            return entityContentService.deleteTerm(vm.website.openEntity, item, vm.termType, vm.selectedLanguage).then(() => dataRequest());
        };
    }
}());
(function () {
    angular.module('app').component('sponsors', {
        controller: sponsorsController,
        bindings: { $router: '<' },
        require: { website: '^website' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function sponsorsController(extJsLoader, iframeMessageHandler) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            extJsLoader.extJsController.updateInnerPage('Content-Sponsors');
            this.website.showIframe = true;
        };
    }
}());
(function () {
    var routeConfig = [
        {
            path: '/',
            name: 'WebsiteRouter',
            component: 'websiteRouter',
            data: {
                dontRoute: true,
                showFunc: function () {
                    return false;
                }
            },
            useAsDefault: true
        },
        {
            path: '/faq',
            name: 'FAQs',
            component: 'faq',
            data: {
                resource: 'CONTENT_FAQ',
                limitToCampaign: true,
                hideOnStandaloneAndTicketing: true
            }
        },
        {
            path: '/sponsors',
            name: 'Sponsors',
            component: 'sponsors',
            data: {
                resource: 'CONTENT_SPONSER',
                limitToCampaign: true
            }
        },
        {
            path: '/blog',
            name: 'Blog',
            component: 'blog',
            data: {
                resource: 'FEED',
                limitToCampaign: true,
                hideOnStandaloneAndTicketing: true
            }
        },
        {
            path: '/mediaLibrary',
            name: 'Media Library',
            component: 'mediaLibrary',
            data: {
                resource: 'IMAGES',
                limitToCampaign: true
            }
        },
        {
            path: '/dictionary',
            name: 'Customize',
            component: 'dictionary',
            data: { resource: 'SETTINGS_CMP_DICTIONARY' }
        },
        {
            path: '/socialShares',
            name: 'Social Shares',
            component: 'socialShares',
            data: { resource: 'SETTINGS_SOCIAL_SHARES' }
        },
        {
            path: '/advanced',
            name: 'Advanced',
            component: 'websiteAdvanced',
            data: {
                hideOnNonStandaloneAndTicketing: true,
                anyResource: ['WEBSITE_ADVANCED']
            }
        }
    ];
    angular.module('app').component('website', {
        templateUrl: 'website.tpl.html',
        controllerAs: 'vm',
        bindings: { $router: '<' },
        controller: websiteController,
        $routeConfig: routeConfig,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateNoPendingChanges($nextInstruction);
        }
    });
    angular.module('app').constant('websiteRouteConfig', routeConfig);
    function websiteController($rootScope, routingService, entityService, orgService, extJsLoader, iframeMessageHandler, $rootRouter) {
        const vm = this;
        vm.loading = true;
        vm.navs = routeConfig;
        vm.iframeData = {};
        vm.showIframe = false;
        this.$routerOnDeactivate = function (p, n) {
            extJsLoader.extJsController.iframeCreated = false;
        };
        routingService.updateRouting().then(r => {
            vm.openEntity = r;
            let entity = entityService.getRawEntityById(r);
            if (entity) {
                vm.onGroup = entity.entityType === 'CampaignGroup';
                vm.onOrg = entity.entityType === 'Org';
                if (entity.curEventId) {
                    vm.iframeSrc = `/site/${ entity.curEventId }?type=plat&value=`;
                    if (!extJsLoader.extJsController.iframeCreated) {
                        vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'website');
                    } else {
                        vm.loading = false;
                    }
                    iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
                }
            }
        });
        $rootScope.$on('loaded', r => {
            extJsLoader.extJsController.loadInnerPage().then(r => vm.loading = false);
        });
        $rootScope.$on('routeNotFound', () => {
            vm.show404 = true;
        });
    }
}());
(function () {
    angular.module('app').component('websiteAdvanced', {
        controller: websiteAdvancedController,
        template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'></form-template>`,
        bindings: { $router: '<' },
        require: { website: '^website' },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });
    function websiteAdvancedController(extJsLoader, configSettingsService) {
        const vm = this;
        this.$routerOnActivate = function (next, prev) {
            this.website.showIframe = false;
            this.website.loading = false;
        };
        vm.sections = [];
        configSettingsService.getWebsiteAdvancedSettings().then(function (r) {
            if (r.success && r.form) {
                vm.sections = r.form.FormSections;
            } else {
            }
        });
        vm.onChange = function (config) {
            return configSettingsService.updateSetting(config);
        };
    }
}());
(function () {
    angular.module('app').component('websiteRouter', {
        controllerAs: 'vm',
        bindings: { $router: '<' },
        controller: websiteRouter
    });
    function websiteRouter(routingService, $rootScope) {
        var vm = this;
        this.$routerOnActivate = function (p, n) {
            routingService.getNextRoute(this.$router, 'website').then(routed => {
                if (!routed) {
                    $rootScope.$emit('routeNotFound');
                }
            });
        };
    }
}());
(function () {
    angular.module('app').component('globalSearch', {
        templateUrl: 'global-search.tpl.html',
        controller: GlobalSearchCtrl,
        controllerAs: 'vm'
    });
    function GlobalSearchCtrl($rootScope, $timeout, $http, orgService, $location, $window, entityService, securityService, campaignService) {
        const vm = this;
        var sortTypes = [
            'Relevant',
            'Recent',
            'Alphabetical'
        ];
        vm.sortTypes = sortTypes;
        var defaultSort = 'Relevant';
        var groupings = {
            Relevant: function (a) {
                var entity = entityService.getOpenEntity();
                if (entity && a.members && a.members.some(member => member.campaignEntityID == entity.entityId || member.campaignGroupEntityIDs && member.campaignGroupEntityIDs.includes(entity.entityId))) {
                    return {
                        order: 0,
                        title: 'In ' + entity.name
                    };
                }
                return entity && (a.campaignEntityID == entity.entityId || a.campaignGroupEntityIDs && a.campaignGroupEntityIDs.includes(entity.entityId)) ? {
                    order: 0,
                    title: 'In ' + entity.name
                } : {
                    order: 1,
                    title: 'Results From Entire Organization'
                };
            }
        };
        function recentSort(a, b) {
            return b.createdOn - a.createdOn;
        }
        function percentMatchTo(a, b) {
            var query = vm.originalQuery;
            if (!query.match(/\S{2}/))
                return recentSort(a, b);
            return percentMatch(query, b.info) - percentMatch(query, a.info);
            // determine the percent match with the original query
            // using http://stackoverflow.com/a/1663745
            // Source: http://www.catalysoft.com/articles/StrikeAMatch.html
            function percentMatch(a, b) {
                const pairs1 = wordLetterPairs(a.toUpperCase());
                const pairs2 = wordLetterPairs(b.toUpperCase());
                let intersection = 0;
                const union = pairs1.length + pairs2.length;
                for (let i = 0; i < pairs1.length; i++) {
                    for (let j = 0; j < pairs2.length; j++) {
                        if (pairs1[i] == pairs2[j]) {
                            intersection++;
                            pairs2.splice(j, 1);
                            // //Must remove the match to prevent "GGGG" from appearing to match "GG" with 100% success
                            break;
                        }
                    }
                }
                return 2 * intersection / union;
                function wordLetterPairs(string) {
                    // get each word
                    return string.split(/\s/)    // clear blank entries
.filter(word => !!word)    // get the letter pairs
.map(word => letterPairs(word))    // flatten the 2d array
.flat();
                }
                function letterPairs(string) {
                    return string    // get each letter
.split('')    // get each pair (it and the one before it) into an array
.reduce((acc, val, index, letters) => {
                        acc.push(letters[index - 1] + letters[index]);
                        return acc;
                    }, [])    // remove the first one (it is junk, undefined + first letter)
.splice(1);
                }
            }
        }
        var sorts = {
            Relevant: percentMatchTo,
            Recent: recentSort,
            Alphabetical: function (a, b) {
                if (a.info == b.info) {
                    return recentSort(a, b);
                }
                return a.info > b.info ? 1 : -1;
            }
        };
        var orgId = orgService.getOrgId();
        vm.activeSort = defaultSort;
        vm.resultCategories = [];
        vm.selectedResultCategory = null;
        vm.results = {};
        vm.groupedAndSortedResults = {};
        vm.sortedResults = null;
        vm.resultGroups = {};
        vm.searching = false;
        vm.searched = false;
        vm.failed = false;
        vm.searchTerm = '';
        vm.triggerSearch = triggerSearch;
        vm.getAppend = getAppend;
        vm.select = function (type, id, eventId, campaignEntityId, result) {
            if (type === 'Group' && id) {
                $window.open(`/org/${ orgId }/group/${ id }`, '_blank');
            } else if (type === 'Campaign' && id) {
                $window.open(orgService.getCampaignPath(campaignService.getCampaignById(id)), '_blank');
            } else if (type === 'Donation' && id) {
                donationClicked(type, id, eventId, campaignEntityId);
            } else if (eventId && type && id) {
                $window.open(orgService.getCampaignPath(campaignService.getCampaignByEventId(eventId), vm.getAppend(type, id)), '_blank');
            }
        };
        var $searchBar = $('#search-bar');
        function searchKeypress(event) {
            if (event.key == 'Enter') {
                event.preventDefault();
                triggerSearch();
            } else if (event.key == 'Escape') {
                event.preventDefault();
                vm.searched = false;
            }
        }
        ;
        vm.searchKeypress = searchKeypress;
        function triggerSearch() {
            vm.failed = false;
            $searchBar.focus();
            vm.originalQuery = vm.searchTerm;
            if (vm.originalQuery.length < 1) {
                vm.resultCategories = [];
                vm.results = {};
                return;
            }
            vm.searching = true;
            $http.post('/Platform/OrgSearchGlobal', {
                query: vm.originalQuery,
                headers: { orgId: orgId }
            }).then(response => {
                if (!response || !response.data || !response.data.success) {
                    vm.searched = true;
                    vm.searching = false;
                    vm.failed = true;
                    return;
                }
                vm.searchTerm = vm.originalQuery;
                vm.results = {};
                for (var item of response.data.items) {
                    if (!vm.results[item.type])
                        vm.results[item.type] = [];
                    vm.results[item.type].push(item);
                }
                vm.resultCategories = Object.keys(vm.results);
                if (vm.resultCategories.length && !(vm.selectedResultCategory in vm.results)) {
                    vm.selectedResultCategory = vm.resultCategories[0];
                }
                sortResults();
                vm.searched = true;
                vm.searching = false;
            }, error => {
                vm.searched = true;
                vm.searching = false;
                vm.failed = true;
            });
        }
        vm.triggerSearch = triggerSearch;
        function sortResults() {
            var sort = sorts[vm.activeSort];
            var grouping = groupings[vm.activeSort];
            if (grouping) {
                var results = {};
                var resultGroups = {};
                for (var category in vm.results) {
                    var resultGroupsInCategory = {};
                    results[category] = {};
                    vm.results[category].forEach(function (result) {
                        if (result.members && result.members.length) {
                            var memberGroups = {};
                            var membersInGroups = {};
                            for (let member of result.members) {
                                var memberGroup = grouping(member);
                                if (!membersInGroups[memberGroup.order]) {
                                    membersInGroups[memberGroup.order] = [];
                                }
                                membersInGroups[memberGroup.order].push(member);
                                memberGroups[memberGroup.order] = null;
                            }
                            var members = [];
                            for (let group of Object.keys(memberGroups).sort()) {
                                if (sort) {
                                    membersInGroups[group].sort();
                                }
                                members = members.concat(membersInGroups[group]);
                            }
                            result.members = members;
                        }
                        var group = grouping(result);
                        if (!results[category][group.title]) {
                            results[category][group.title] = [];
                        }
                        results[category][group.title].push(result);
                        resultGroupsInCategory[group.order] = group.title;
                    });
                    resultGroups[category] = Object.keys(resultGroupsInCategory).sort().map(function (key) {
                        return resultGroupsInCategory[key];
                    });
                    if (sort) {
                        for (var group in results[category]) {
                            results[category][group].sort(sort);
                        }
                    }
                }
                vm.sortedResults = null;
                vm.groupedAndSortedResults = results;
                vm.resultGroups = resultGroups;
            } else if (sort) {
                var results = {};
                for (var category in vm.results) {
                    results[category] = Array.from(vm.results[category]);
                    for (var result of results[category]) {
                        if (result.members) {
                            result.members.sort(sort);
                        }
                    }
                    results[category].sort(sort);
                }
                vm.sortedResults = results;
                vm.groupedAndSortedResults = null;
                vm.resultGroups = null;
            } else {
                vm.sortedResults = vm.results;
                vm.groupedAndSortedResults = null;
                vm.resultGroups = null;
            }
        }
        vm.sortResults = sortResults;
        function getAppend(type, id) {
            var path = '';
            if (type === 'Registrant') {
                path = '/people/registrants';
            } else if (type === 'Donor') {
                path = '/people/donors';
            } else if (type === 'Team') {
                path = '/people/teams';
            } else if (type === 'Donation') {
                path = '/transactions/donations';
            }
            return path + '?type=' + type + '&value=' + id;
        }
        function donationClicked(type, id, eventId, campaignEntityId) {
            var entity = entityService.getRawEntityById(campaignEntityId);
            var hasPermission = securityService.getAdminPermissions(entity, 'DONATION');
            if (hasPermission.DELETE) {
                vm.showObjectModal = true;
                vm.objectType = type;
                vm.objectId = id;
                vm.objectEventId = eventId;
                vm.objectEntityId = campaignEntityId;
                $('#search-bar').click();
            } else {
                window.open(orgService.getCampaignPath(campaignService.getCampaignByEventId(eventId), '/transactions/donations?type=Donation&value=' + id), '_blank');
            }
        }
        $rootScope.$on('$locationChangeSuccess', function () {
            sortResults();
        });
    }
}());
(function () {
    angular.module('app').component('modalOrgs', {
        bindings: {
            show: '=',
            orgs: '='
        },
        controller: ModalOrgsCtrl,
        controllerAs: 'vm'
    });
    function ModalOrgsCtrl($scope, $mdDialog, profileService) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            profileService.getProfileInfo().then(profileInfo => {
                var orgs = profileInfo.orgs;
                $mdDialog.show({
                    templateUrl: 'modal-orgs.tpl.html',
                    controller: ModalOrsContentCtrl,
                    controllerAs: 'vm',
                    locals: { orgs: orgs },
                    clickOutsideToClose: true
                }).finally(() => {
                    vm.show = false;
                });
            });
        }
    }
    function ModalOrsContentCtrl($rootScope, $q, $location, $mdDialog, orgService, campaignService, profileService, orgs) {
        const vm = this;
        vm.getCampaignData = getCampaignData;
        vm.orgs = orgs;
        vm.setOrg = setOrg;
        vm.closeModal = closeModal;
        function getCampaignData(entityId) {
            campaignService.openGroup(0, entityId, true, undefined).then(campaignsData => {
                $rootScope.$emit('orgs.loaded', campaignsData);
            });
        }
        function setOrg(org) {
            location.href = '/org/' + org.orgId;
        }
        function closeModal() {
            $mdDialog.cancel();
        }
    }
}());
(function () {
    angular.module('app').component('navBar', {
        bindings: { isAuthenticated: '=' },
        templateUrl: 'nav-bar.tpl.html',
        controller: NavBarCtrl,
        controllerAs: 'vm'
    });
    function NavBarCtrl($rootScope, $location, profileService, orgService, securityService, routingService, campaignService) {
        const vm = this;
        vm.showProfilePopover = false;
        vm.profile = null;
        vm.userNeonApps = [];
        vm.showOrgSelectorModal = false;
        vm.org = orgService.getOrg();
        vm.security = {};
        vm.isChampion = !!window.isChampion;
        vm.brandingLogo = window.brandingLogo;
        vm.brandingName = window.brandingName;
        vm.brandingUrl = window.brandingUrl;
        vm.getLogonUrl = getLogonUrl;
        vm.hideProfilePopover = hideProfilePopover;
        vm.isActiveTab = isActiveTab;
        vm.$onInit = function () {
            if (vm.isAuthenticated) {
                loadProfile();
                loadUserNeonApps();
                vm.security = securityService.getAdminPermissions(vm.org, 'ADMIN_USERS');
            }
        };
        //////////////////////////////////////////////
        function loadProfile() {
            profileService.getProfileInfo().then(profileInfo => {
                vm.profile = profileInfo;
            });
        }
        function loadUserNeonApps() {
            profileService.getUserNeonApps().then(userNeonApps => {
                vm.userNeonApps = userNeonApps;
            });
        }
        function getLogonUrl() {
            return `/Account/Logon?returnUrl=${ encodeURIComponent($location.url()) }`;
        }
        function hideProfilePopover() {
            vm.showProfilePopover = false;
        }
        function isActiveTab(link) {
            var path = $location.path();
            if (path.indexOf('/my/') == 0 || path.indexOf('/p/') == 0) {
                return '';
            }
            if (path.indexOf('organization') > -1) {
                if (link == 'organization') {
                    return 'active';
                }
                return '';
            }
            if (link == 'campaigns') {
                return 'active';
            }
            return '';
        }
        $rootScope.$on('$routeChangeSuccess', () => {
            vm.onCampaign = routingService.openEntityIsCampaign();
        });
    }
}());
(function () {
    angular.module('app').component('administratorsList', {
        templateUrl: 'administrators-list.tpl.html',
        controller: AdministratorsListController,
        controllerAs: 'vm'
    });
    function AdministratorsListController($q, administratorService, messageService, campaignService, $timeout, $mdDialog, orgService, securityService) {
        const vm = this;
        vm.inviteInProgress = false;
        vm.inviteExistingAdmin = inviteExistingAdmin;
        vm.focusInput = focusInput;
        vm.searchAdmin = searchAdmin;
        vm.showRemoveConfirm = showRemoveConfirm;
        vm.dataLoaded = false;
        vm.removeInProgress = false;
        vm.showModalCampaigns = false;
        vm.showModalAdmin = false;
        vm.admins = [];
        vm.roles = [];
        vm.show = false;
        vm.security = {};
        loadData();
        //////////////////////////////////////////////////////////////
        function advancedInviteErrors(currentMessageService, response) {
            var msg = '';
            response.data.data.errors.forEach(error => {
                error.errorsList.forEach(entity => {
                    msg += error.key + ' could not be invited to ' + entityService.getEntityName(entity.errorMessage) + '.<br>';
                });
            });
            msg += 'All the other invites were successful.';
            messageService.showErrorDialog(msg);
        }
        function inviteExistingAdmin($event, index) {
            vm.inviteInProgress = true;
            $event.stopPropagation();
            for (let i = 0; i < vm.admins[index].email.length; i++) {
                if (typeof vm.admins[index].email[i] === 'object') {
                    vm.admins[index].email[i] = vm.admins[index].email[i].email;
                }
            }
            let entityIds = vm.admins[index].roleById.map(role => role.entityId);
            let messageConfig = {
                advancedErrorFunction: advancedInviteErrors,
                advancedErrorEnabled: true,
                successToastEnabled: true,
                successMsg: `Successfully invited ${ vm.admins[index].email }`
            };
            administratorService.inviteAdmin(vm.admins[index].email, vm.admins[index].roleById, undefined, entityIds, '', messageConfig).then(data => {
                let newAdmins = data.data.data;
                administratorService.updateAdminsCurrentRoleIds(newAdmins[0]);
                vm.updateAdmin(newAdmins[0], index);
                $mdDialog.hide();
            }).finally(() => vm.inviteInProgress = false);
        }
        function loadData() {
            vm.dataLoaded = false;
            campaignService.openGroup(0).then(() => {
                let promises = [
                    administratorService.getAllOrgAdmins(),
                    administratorService.getAllRoles()
                ];
                $q.all(promises).then(([admins, roles]) => {
                    vm.admins = admins;
                    vm.roles = roles;
                }).finally(() => vm.dataLoaded = true);
            });
        }
        vm.security = securityService.getAdminPermissions(orgService.getOrg(), 'ADMIN_USERS');
        function searchAdmin() {
            let data = administratorService.getAdminsFromOrg();
            console.log(data);
            vm.admins = [];
            data.forEach(function (admin) {
                let query = vm.searchQuery.toLowerCase();
                if (admin.email.indexOf(query) > -1 || admin.firstName && admin.firstName.toLowerCase().indexOf(query) > -1 || admin.lastName && admin.lastName.toLowerCase().indexOf(query) > -1) {
                    vm.admins.push(admin);
                }
                ;
            });
        }
        function focusInput() {
            let input = document.getElementById('admin-searchBox');
            $timeout(function () {
                input.focus();
            }, 0);
        }
        function showRemoveConfirm(adminId, event, email) {
            event.stopPropagation();
            let confirm = $mdDialog.confirm().clickOutsideToClose(true).title('Are you sure?').htmlContent(`This action will remove admin ${ email }.`).targetEvent(event).ok('Remove Admin').cancel('Cancel');
            $mdDialog.show(confirm).then(() => removeAdmin(adminId, event));
        }
        ;
        function removeAdmin(adminId, event) {
            event.stopPropagation();
            vm.removeInProgress = true;
            administratorService.removeAdminFromOrg(adminId).then(() => {
                let newArr = vm.admins.filter(admin => {
                    return admin.id !== adminId;
                });
                vm.admins = newArr;
                vm.updateAdmin({ id: adminId }, null, true);
            }).finally(() => vm.removeInProgress = false);
        }
        ;
        vm.updateAdmin = function (admin, index, remove) {
            if (remove) {
                administratorService.updateAllAdmins(admin, index, true);
            } else {
                if (index !== undefined && index != null && index > -1) {
                    vm.admins[index] = admin;
                    //update administratorService allAdmins object
                    administratorService.updateAllAdmins(admin, index);
                } else {
                    //for some reason administratorService.addToAllAdmins() is adding it to the dom already
                    //  vm.admins.push(admin);
                    //add to administratorService allAdmins object
                    administratorService.addToAllAdmins(admin);
                }
            }
        };
    }
}());
(function () {
    angular.module('app').component('modalAddAdministrator', {
        bindings: {
            showAdmin: '=',
            admins: '=',
            roles: '=',
            onUpdateAdmin: '&'
        },
        controller: ModalAddAdminCtrl,
        controllerAs: 'vm'
    });
    function ModalAddAdminCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.showAdmin', () => {
            if (vm.showAdmin) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-add-administrator.tpl.html',
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    admins: vm.admins,
                    roles: vm.roles,
                    onUpdateAdmin: vm.onUpdateAdmin
                },
                clickOutsideToClose: true
            }).finally(() => {
                vm.showAdmin = false;
            });
        }    // $scope.$on('$destroy', openAdmin);
    }
    function ModalContentCtrl($scope, $timeout, $q, $mdDialog, $location, $mdConstant, entityService, campaignService, administratorService, messageService, admins, roles, onUpdateAdmin) {
        const vm = this;
        vm.newAdmin = {};
        vm.roles = roles;
        vm.admins = admins;
        vm.onUpdateAdmin = onUpdateAdmin;
        vm.loadInProgress = false;
        vm.saveInProgress = false;
        vm.inviteInProgress = false;
        vm.showTextArea = false;
        vm.inviteNewAdmin = inviteNewAdmin;
        vm.closeModal = closeModal;
        vm.addCampaign = addCampaign;
        vm.addedCampaigns = [];
        vm.removeCampaign = removeCampaign;
        vm.inviteDisabled = true;
        // vm.changeMdContainer = changeMdContainer;
        let campaignsData = campaignService.getRawCampaigns();
        let groupsData = campaignService.getRawGroups();
        let orgGroupsCampaigns = groupsData.concat(campaignsData);
        //chips
        vm.newAdmin.email = [];
        vm.adminSearch = adminSearch;
        vm.selectedAdmin = null;
        vm.searchAdmin = null;
        vm.selectedTags = [];
        vm.keys = [
            $mdConstant.KEY_CODE.ENTER,
            $mdConstant.KEY_CODE.COMMA
        ];
        vm.allAdmins = loadContacts();
        vm.showChipsHint = false;
        function adminSearch(query) {
            let results = query ? vm.allAdmins.filter(createAdminFilter(query)) : [];
            return results;
        }
        ;
        function createAdminFilter(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(contact) {
                return contact._loweremail.indexOf(lowercaseQuery) != -1 || contact._lowername.indexOf(lowercaseQuery) != -1;
            };
        }
        ;
        function loadContacts() {
            let contacts = administratorService.getAdminsFromOrg();
            return contacts.map(function (c, index) {
                let contact = {
                    name: c.firstName + ' ' + c.lastName,
                    email: c.email
                };
                contact.display = contact.name === ' ' ? contact.email : contact.name;
                contact.fullname = contact.name === ' ' ? contact.email : contact.name;
                contact._loweremail = contact.email.toLowerCase();
                contact._lowername = contact.name.toLowerCase();
                return contact;
            });
        }
        ;
        //watch for when the email input box is added to to check for invalid emails
        $scope.$watchCollection('vm.newAdmin.email', function () {
            if (vm.newAdmin.email && vm.newAdmin.email.length > 0) {
                let input = vm.newAdmin.email[vm.newAdmin.email.length - 1];
                //if type is not string then the chip was added by autocomplete
                if (typeof input == 'string') {
                    let pattern = /^.+@.+\..+$/;
                    let emailsRejected = '';
                    let emailsToAdd = [];
                    let invalid = false;
                    let emails = input.split(' ');
                    emails.forEach(email => {
                        if (!email.match(pattern)) {
                            invalid = true;
                            emailsRejected += ' ' + email;
                        } else {
                            emailsToAdd.push(email);
                        }
                    });
                    if (invalid || emails.length > 1) {
                        vm.newAdmin.email.splice(-1, 1);
                        vm.newAdmin.email = vm.newAdmin.email.concat(emailsToAdd);
                        if (invalid) {
                            $timeout(() => {
                                messageService.showErrorToast(`Please enter valid email`, angular.element('.add-admin-email'));
                                vm.searchAdmin = emailsRejected.trim();
                            });
                        }
                    }
                }
            }
        });
        // autocomplete campaigns
        vm.campaigns = loadCampaigns();
        vm.querySearch = querySearch;
        vm.updatedCampaignsList = [];
        function querySearch(query) {
            let results = query ? vm.campaigns.filter(createFilterFor(query)) : vm.campaigns;
            return results;
        }
        function loadCampaigns() {
            orgGroupsCampaigns = orgGroupsCampaigns.map(function (camp) {
                return {
                    value: camp.name.toLowerCase(),
                    entityId: camp.entityId,
                    entityType: camp.entityType || 'Group',
                    url: camp.url,
                    smallImage: camp.smallImage,
                    display: camp.name
                };
            });
            var entireOrgOption = {
                value: 'entire organization',
                entityId: entityService.getOrgEntityId(),
                entityType: 'Org',
                display: 'Entire Organization'
            };
            orgGroupsCampaigns.splice(0, 0, entireOrgOption);
            return orgGroupsCampaigns;
        }
        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(camp) {
                return camp.value.indexOf(lowercaseQuery) === 0;
            };
        }
        ;
        //end autocomplete
        function addCampaign() {
            let newCamp = angular.copy(vm.newAdmin);
            vm.updatedCampaignsList = angular.copy(vm.campaigns);
            vm.campaigns.forEach((camp, index) => {
                if (camp.display === vm.newAdmin.campaign.display) {
                    vm.updatedCampaignsList.splice(index, 1);
                }
            });
            vm.campaigns = vm.updatedCampaignsList;
            vm.addedCampaigns.push(newCamp);
            vm.newAdmin.campaign = null;
            vm.newAdmin.role = null;
            vm.searchText = null;
        }
        function removeCampaign(index) {
            //add this campaign to options in search
            vm.campaigns.splice(0, 0, vm.addedCampaigns[index].campaign);
            //take out of added campaigns
            vm.addedCampaigns.splice(index, 1);
        }
        function closeModal() {
            $mdDialog.hide();
        }
        // function changeMdContainer(){
        //   document.querySelector('.md-dialog-container').className = "md-dialog-container md-container-overwrite";
        // }
        function addRoleChanges(id, currentRoleId) {
            listRoles.push({
                userId: vm.admin.id,
                entityId: id,
                roleId: currentRoleId
            });
        }
        function advancedInviteErrors(currentMessageService, response) {
            var msg = '';
            response.data.data.errors.forEach(error => {
                error.errorsList.forEach(entity => {
                    msg += error.key + ' could not be invited to ' + entityService.getEntityName(entity.errorMessage) + '.<br>';
                });
            });
            msg += 'All the other invites were successful.';
            messageService.showErrorDialog(msg);
        }
        function inviteNewAdmin() {
            vm.inviteInProgress = true;
            for (let i = 0; i < vm.newAdmin.email.length; i++) {
                if (typeof vm.newAdmin.email[i] === 'object') {
                    vm.newAdmin.email[i] = vm.newAdmin.email[i].email;
                }
            }
            let rolesOnEntitys = [], emails = [], entityIds = [];
            vm.addedCampaigns.forEach(camp => {
                entityIds.push(camp.campaign.entityId);
                rolesOnEntitys.push({
                    entityId: camp.campaign.entityId,
                    roleId: camp.role.roleId
                });
            });
            let messageConfig = {
                advancedErrorFunction: advancedInviteErrors,
                advancedErrorEnabled: true,
                successToastEnabled: true,
                successMsg: `Successfully invited ${ vm.newAdmin.email }`
            };
            administratorService.inviteAdmin(vm.newAdmin.email, rolesOnEntitys, undefined, entityIds, vm.newAdmin.message, messageConfig).then(data => {
                let newAdmins = data.data.data;
                for (var i = 0; i < newAdmins.length; i++) {
                    administratorService.updateAdminsCurrentRoleIds(newAdmins[i]);
                    var foundAdmin = false;
                    //if admin already was in admins array we add the new entities
                    for (var j = 0; j < vm.admins.length; j++) {
                        if (newAdmins[i].id == vm.admins[j].id) {
                            vm.onUpdateAdmin({
                                admin: newAdmins[i],
                                index: j
                            });
                            foundAdmin = true;
                        }
                    }
                    //else add to end of admins array
                    if (!foundAdmin) {
                        vm.onUpdateAdmin({ admin: newAdmins[i] });
                    }
                }
                //if (data.data.errors.length == 0) {
                //    $mdToast.show(
                //        $mdToast.simple()
                //        .textContent(`Successfully invited ${vm.newAdmin.email}`)
                //        .position('top right')
                //        .hideDelay(5000)
                //    );
                //} else {
                //    var msg = '';
                //    data.data.errors.forEach((error) => {
                //        error.errorsList.forEach((entity) => {
                //            msg += error.key + ' could not be invited to ' + entityService.getEntityName(entity.errorMessage) + '.<br>';
                //        });
                //    });
                //    msg += "All the other invites were successful."
                //    let alert = $mdDialog.alert()
                //        .title("Errors inviting administrators!")
                //        .htmlContent(msg)
                //        .ok('Close');
                //    $mdDialog
                //        .show(alert);
                //}
                vm.newAdmin.email = vm.newAdmin.roleId = null;
                closeModal();
            }).finally(() => vm.inviteInProgress = false);
        }
    }
}());
(function () {
    angular.module('app').component('modalCampaigns', {
        bindings: {
            show: '=',
            admin: '<',
            index: '<',
            roles: '<',
            onUpdateAdmin: '&'
        },
        controller: ModalCampaignsCtrl,
        controllerAs: 'vm'
    });
    function ModalCampaignsCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-campaigns.tpl.html',
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                clickOutsideToClose: true,
                locals: {
                    admin: vm.admin,
                    indexInParent: vm.index,
                    roles: vm.roles,
                    onUpdateAdmin: vm.onUpdateAdmin
                }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($q, $mdDialog, $timeout, entityService, campaignService, administratorService, admin, indexInParent, roles, onUpdateAdmin, messageService) {
        const vm = this;
        vm.roles = roles;
        vm.onUpdateAdmin = onUpdateAdmin;
        vm.closeModal = closeModal;
        vm.addCampaign = addCampaign;
        vm.addedCampaigns = [];
        vm.removeEntity = removeEntity;
        vm.addRemoveFlag = addRemoveFlag;
        //vm.saveAdministrators = saveAdministrators;
        vm.addRoleChanges = addRoleChanges;
        vm.saveInProgress = false;
        vm.revertDeleteIcon = revertDeleteIcon;
        let listRoles = [];
        let campaignsData = campaignService.getRawCampaigns();
        let groupsData = campaignService.getRawGroups();
        let orgGroupsCampaigns = groupsData.concat(campaignsData);
        $timeout(() => removeUndefinedCmpnsAndGroups(admin).then(() => {
            // autocomplete campaigns
            vm.campaigns = loadCampaigns();
            vm.querySearch = querySearch;
            vm.selectedItemChange = selectedItemChange;
        }));
        function removeUndefinedCmpnsAndGroups(admins) {
            //to stop list from showing double for a second
            let adminWithoutUndefinedCmpns = angular.copy(admins);
            let filteredCampaigns = [];
            admins.campaigns.forEach(camp => {
                if (camp.currentRoleId != undefined) {
                    filteredCampaigns.push(camp);
                }
            });
            adminWithoutUndefinedCmpns.campaigns = filteredCampaigns;
            let filteredGroups = [];
            admins.groups.forEach(group => {
                if (group.currentRoleId != undefined) {
                    filteredGroups.push(group);
                }
            });
            adminWithoutUndefinedCmpns.groups = filteredGroups;
            vm.admin = adminWithoutUndefinedCmpns;
            return $q.resolve();
        }
        function addRoleChanges(id, currentRoleId) {
            let arg = [];
            arg.push({
                userId: vm.admin.id,
                entityId: id,
                roleId: currentRoleId
            });
            administratorService.addAdminToCompanies(vm.admin.email, id, currentRoleId).then(response => {
                //update parent admins object
                let resultAdmin = response.data.data;
                administratorService.updateAdminsCurrentRoleIds(resultAdmin);
                vm.onUpdateAdmin({
                    admin: resultAdmin,
                    index: indexInParent
                });
            }).finally(() => {
            });
        }
        function closeModal() {
            $mdDialog.hide();
        }
        function addRemoveFlag(campaign) {
            campaign.remove = true;
        }
        function revertDeleteIcon() {
            vm.admin.groups.forEach(group => {
                if (group.remove == true) {
                    group.remove = false;
                }
            });
            vm.admin.campaigns.forEach(campaign => {
                if (campaign.remove == true) {
                    campaign.remove = false;
                }
            });
            if (vm.admin.entireOrgRole != undefined) {
                vm.admin.entireOrgRole.remove = false;
            }
        }
        function removeEntity(campOrGroup) {
            administratorService.removeAdmin(vm.admin.id, campOrGroup.entityId).then(response => {
                let resultAdmin = response.data.data[0];
                //check if admin has no more groups or campaigns 
                if (resultAdmin == undefined) {
                    vm.admin.campaigns = [];
                    vm.admin.groups = [];
                    vm.admin.entireOrgRole = null;
                    vm.onUpdateAdmin({
                        admin: vm.admin,
                        index: indexInParent
                    });
                } else {
                    administratorService.updateAdminsCurrentRoleIds(resultAdmin);
                    removeUndefinedCmpnsAndGroups(resultAdmin);
                    //to fix glitch that was making some campaigns display double for a moment
                    vm.onUpdateAdmin({
                        admin: resultAdmin,
                        index: indexInParent
                    });
                }
                //add this campaign to options in search
                var campInDropDownForm = {
                    value: campOrGroup.name.toLowerCase(),
                    entityId: campOrGroup.entityId,
                    entityType: campOrGroup.entityType || 'Group',
                    url: campOrGroup.url,
                    smallImage: campOrGroup.smallImage,
                    display: campOrGroup.name
                };
                vm.campaigns.splice(0, 0, campInDropDownForm);
            });
        }
        function querySearch(query) {
            let results = query ? vm.campaigns.filter(createFilterFor(query)) : vm.campaigns;
            return results;
        }
        function selectedItemChange(item) {
            if (vm.newAdmin.campaign) {
                vm.campaigns.forEach((camp, index) => {
                    if (camp.display === vm.newAdmin.campaign.display) {
                        vm.campaigns.splice(index, 1);
                    }
                });
            }
            ;
        }
        // remove campaign from the list;
        function isNotArchived(cmp) {
            return !cmp.archived;
        }
        function loadCampaigns() {
            orgGroupsCampaigns = orgGroupsCampaigns.filter(isNotArchived);
            orgGroupsCampaigns = orgGroupsCampaigns.map(function (camp) {
                return {
                    value: camp.name.toLowerCase(),
                    entityId: camp.entityId,
                    entityType: camp.entityType || 'Group',
                    url: camp.url,
                    smallImage: camp.smallImage,
                    display: camp.name
                };
            });
            var entireOrgOption = {
                value: 'entire organization',
                entityId: entityService.getOrgEntityId(),
                entityType: 'Org',
                display: 'Entire Organization'
            };
            if (vm.admin.entireOrgRole.name == undefined) {
                orgGroupsCampaigns.splice(0, 0, entireOrgOption);
            }
            vm.admin.groups.forEach(function (element) {
                for (var i = 0; i < orgGroupsCampaigns.length; i++) {
                    if (element.currentRoleId != undefined && orgGroupsCampaigns[i].entityId == element.entityId) {
                        orgGroupsCampaigns.splice(i, 1);
                        break;
                    }
                }
            });
            vm.admin.campaigns.forEach(function (element) {
                for (var i = 0; i < orgGroupsCampaigns.length; i++) {
                    if (element.currentRoleId != undefined && orgGroupsCampaigns[i].entityId == element.entityId) {
                        orgGroupsCampaigns.splice(i, 1);
                        break;
                    }
                }
            });
            return orgGroupsCampaigns;
        }
        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(camp) {
                return camp.value.indexOf(lowercaseQuery) === 0;
            };
        }
        ;
        //end autocomplete
        function addCampaign() {
            let newCamp = angular.copy(vm.newAdmin);
            vm.saveInProgress = true;
            let arg = [];
            arg.push({
                userId: vm.admin.id,
                entityId: newCamp.campaign.entityId,
                roleId: newCamp.role
            });
            administratorService.addAdminToCompanies(vm.admin.email, newCamp.campaign.entityId, newCamp.role).then(response => {
                //add to mddialog(child) admin object so it goes at bottom of list
                if (newCamp.campaign.entityType == 'CampaignGroup') {
                    vm.admin.groups.push({
                        name: newCamp.campaign.display,
                        entityId: newCamp.campaign.entityId,
                        currentRoleId: newCamp.role,
                        entityType: newCamp.campaign.entityType
                    });
                } else if (newCamp.campaign.entityType == 'Campaign') {
                    vm.admin.campaigns.push({
                        name: newCamp.campaign.display,
                        entityId: newCamp.campaign.entityId,
                        url: newCamp.campaign.url,
                        currentRoleId: newCamp.role,
                        entityType: newCamp.campaign.entityType,
                        smallImage: newCamp.campaign.smallImage
                    });
                } else {
                    vm.admin.entireOrgRole = {
                        name: newCamp.campaign.display,
                        entityId: newCamp.campaign.entityId,
                        currentRoleId: newCamp.role,
                        entityType: 'Org'
                    };
                }
                //remove added campaign from dropdown list
                vm.updatedCampaignsList = angular.copy(vm.campaigns);
                vm.campaigns.forEach((camp, index) => {
                    if (camp.display === newCamp.campaign.display) {
                        vm.updatedCampaignsList.splice(index, 1);
                    }
                });
                vm.campaigns = vm.updatedCampaignsList;
                //update parent admins object
                let resultAdmin = response.data.data;
                administratorService.updateAdminsCurrentRoleIds(resultAdmin);
                vm.onUpdateAdmin({
                    admin: resultAdmin,
                    index: indexInParent
                });
            }).finally(() => {
                //closeModal();
                vm.saveInProgress = false;
            });
            vm.newAdmin.campaign = null;
            vm.newAdmin.role = null;
            vm.searchText = null;
        }
    }
}());
(function () {
    angular.module('app').component('automation', {
        templateUrl: 'automation.tpl.html',
        controller: AutomationController,
        controllerAs: 'vm'
    });
    function AutomationController(securityService, entityService, orgService, automationService, $mdDialog) {
        const vm = this;
        loadData();
        vm.actions = [];
        vm.show = false;
        vm.save = save;
        vm.loadData = loadData;
        vm.showModal = showModal;
        vm.formatDate = formatDate;
        vm.security = securityService.getAdminPermissions(orgService.getOrg(), 'CAMPAIGN');
        vm.getEntity = getEntity;
        vm.showDeleteConfirm = showDeleteConfirm;
        automationService.getAvailableActions().then(function (actionTypes) {
            return vm.actionNames = actionTypes.reduce(function (object, action) {
                return Object.assign({}, object, { [action.value]: action.label });
            }, {});
        });
        function save(entityId, action, scheduledFor) {
            return automationService.createEntityAction(entityId, action, scheduledFor).then(loadData);
        }
        function deleteAction(entityId, actionId) {
            return automationService.deleteEntityAction(entityId, actionId).then(loadData);
        }
        function loadData() {
            vm.loaded = false;
            var entityId = entityService.getOrgEntityId();
            automationService.getEntityActions(entityId).then(actions => {
                if (Array.isArray(actions)) {
                    vm.actions = actions;
                } else {
                    vm.unknownError = true;
                }
            }).finally(() => {
                vm.loaded = true;
            });
        }
        function getEntity(entityId) {
            return entityService.getRawEntityById(entityId);
        }
        function formatDate(date) {
            return moment(date).format('L LT');
        }
        function showModal() {
            vm.show = true;
        }
        function showDeleteConfirm(entityId, actionId, event) {
            let confirm = $mdDialog.confirm().clickOutsideToClose(true).title('Are you sure?').htmlContent(`This action will remove this automated action.`).targetEvent(event).ok('Remove Action').cancel('Cancel');
            $mdDialog.show(confirm).then(() => deleteAction(entityId, actionId));
        }
    }
}());
(function () {
    angular.module('app').component('modalAddAutomation', {
        bindings: {
            show: '=',
            onCreateAction: '='
        },
        controllerAs: 'vm',
        controller: ModalAddAutomationController
    });
    function ModalAddAutomationController($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-add-automation.tpl.html',
                controller: ModalAddAutomationContentCtrl,
                controllerAs: 'vm',
                locals: { onCreateAction: vm.onCreateAction },
                clickOutsideToClose: true
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalAddAutomationContentCtrl($mdDialog, campaignService, automationService, onCreateAction, settingsService) {
        const vm = this;
        vm.onCreateAction = onCreateAction;
        vm.timezone = {
            utcOffset: -7,
            name: 'Pacific Daylight Time'
        };
        settingsService.getTimeZoneInfo().then(tz => vm.timezone = tz);
        const defaultHour = 3;
        // 3AM
        const defaultDate = moment().utcOffset(vm.timezone.utcOffset).startOf('day').add(1, 'days').add(defaultHour, 'hours').toDate();
        // tommorow at 3AM
        // vm.currentTime = moment()
        //     .utcOffset(vm.timezone.utcOffset)
        //     .format('MMM D ha');
        automationService.getAvailableActions().then(actionTypes => vm.actions = actionTypes);
        vm.action = {
            action: null,
            entity: null,
            hour: defaultHour,
            date: defaultDate
        };
        vm.querySearch = querySearch;
        vm.hours = loadHours();
        vm.campaigns = loadCampaigns();
        // between tommorow and December of 10 years from now (e.g. 2019-2029)
        vm.minDate = moment().utcOffset(vm.timezone.utcOffset).startOf('day').add(1, 'days').toDate();
        vm.maxDate = moment().utcOffset(vm.timezone.utcOffset).add(10, 'years').endOf('year').subtract(1, 'day').toDate();
        vm.updateHour = updateHour;
        vm.validInput = validInput;
        vm.closeModal = closeModal;
        vm.save = save;
        function querySearch(query) {
            let results = query ? vm.campaigns.filter(createFilterFor(query)) : vm.campaigns;
            return results;
        }
        function closeModal() {
            $mdDialog.hide();
        }
        function validInput() {
            return vm.action.entity && vm.action.action && vm.action.date && vm.action.hour >= 0 && vm.action.date instanceof Date && vm.action.date >= vm.minDate;
        }
        function save() {
            if (!vm.onCreateAction) {
                //console.error('onCreateAction method not set!', vm.onCreateAction)
                return;
            }
            vm.saving = true;
            vm.onCreateAction(vm.action.entity.entityId, vm.action.action, moment(vm.action.date).utcOffset(vm.timezone.utcOffset).toJSON()).finally(() => {
                vm.saving = false;
                closeModal();
            });
        }
        function updateHour() {
            vm.action.date = moment(vm.action.date).utcOffset(vm.timezone.utcOffset).startOf('day').add(vm.action.hour, 'hours').toDate();
        }
        //#region helpers
        function createFilterFor(query) {
            var lowercaseQuery = angular.lowercase(query);
            return function filterFn(camp) {
                return camp.value.indexOf(lowercaseQuery) === 0;
            };
        }
        ;
        function loadCampaigns() {
            // join campaigns and groups
            var campaigns = [].concat(campaignService.getRawGroups(), campaignService.getRawCampaigns());
            return campaigns.map(campaign => ({
                value: campaign.name.toLowerCase(),
                entityId: campaign.entityId,
                entityType: campaign.entityType,
                url: campaign.url,
                smallImage: campaign.smallImage,
                display: `${ campaign.name } (${ campaign.entityType == 'Campaign' ? 'Campaign' : 'Group' })`
            }));
        }
        function loadHours() {
            let hours = [];
            for (var i = 0; i < 24; i++) {
                const label = moment().startOf('day').add(i, 'hours').format('LT');
                hours.push({
                    value: i,
                    label: label
                });
            }
            return hours;
        }    //#endregion
    }
}());
(function () {
    angular.module('app').component('modalAutomationLogs', {
        bindings: { show: '=' },
        controller: ModalAutomationLogsController,
        controllerAs: 'vm'
    });
    function ModalAutomationLogsController($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-automation-logs.tpl.html',
                clickOutsideToClose: true,
                controller: ModalAutomationLogsContentController,
                controllerAs: 'vm'
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalAutomationLogsContentController($mdDialog, entityService, automationService, profileService, agGridService) {
        const vm = this;
        vm.closeModal = closeModal;
        vm.loadData = loadData;
        vm.loading = true;
        var columnDefs = [
            {
                colId: 'EntityActionLogId',
                field: 'EntityActionLogId',
                headerName: 'LogID',
                hide: true,
                filter: false,
                enableRowGroup: false
            },
            {
                colId: 'ActionID',
                field: 'ActionID',
                headerName: 'ActionID',
                hide: true
            },
            {
                colId: 'EntityID',
                field: 'EntityID',
                headerName: 'EntityID',
                hide: true,
                filter: false,
                enableRowGroup: false
            },
            {
                colId: 'EventID',
                field: 'EventID',
                headerName: 'EventID'
            },
            {
                colId: 'Action',
                field: 'Action',
                headerName: 'Action'
            },
            {
                colId: 'Status',
                field: 'Status',
                headerName: 'Status'
            },
            {
                colId: 'CreatedOn',
                field: 'CreatedOn',
                headerName: 'Occured At',
                filter: false,
                valueFormatter: agGridService.formatters.dateTime,
                enableRowGroup: false
            },
            {
                colId: 'Message',
                field: 'Message',
                headerName: 'Message'
            }
        ];
        vm.gridOptions = agGridService.createNewGrid(columnDefs, { key: 'modal-automation-logs' }, { onGridReady: loadData });
        profileService.getProfileInfo().then(profile => {
            vm.gridOptions.csvFilename = `admin_${ profile.userName.split(' ')[0] }_${ moment().format('YYYYMMDD-HHmm') }.csv`;
        });
        function loadData() {
            vm.loading = true;
            var entityId = entityService.getOrgEntityId();
            return automationService.getEntityActionErrorLogs(entityId).then(logs => {
                vm.gridOptions.api.setRowData(logs.data);
            }).finally(() => {
                vm.loading = false;
            });
        }
        function closeModal() {
            $mdDialog.cancel();
        }
    }
}());
(function () {
    angular.module('app').component('emailDomains', {
        templateUrl: 'email-domains.tpl.html',
        controller: EmailDomainsController,
        controllerAs: 'vm'
    });
    function EmailDomainsController($mdDialog, emailDomainsService, securityService, orgService) {
        const vm = this;
        vm.securityRoles = [];
        vm.dataLoaded = false;
        vm.selectedDomain = undefined;
        vm.showDomainDetails = false;
        vm.addingNewDomain = false;
        vm.showModalAddDomain = false;
        vm.security = securityService.getAdminPermissions(orgService.getOrg(), 'EMAIL_ADVANCED_OPTIONS');
        loadData();
        function loadData() {
            emailDomainsService.getEmailDomains().then(domains => {
                vm.dataLoaded = true;
                vm.emailDomains = domains;
            });
        }
        vm.getStatusText = function (status) {
            return emailDomainsService.getDomainStatusText(status);
        };
        vm.showDomainPopup = function (domain) {
            vm.selectedDomain = domain;
            vm.showDomainDetails = true;
        };
        vm.reloadDomains = function () {
            vm.dataLoaded = false;
            loadData();
        };
        vm.addDomain = function (domain) {
            vm.addingNewDomain = true;
            return emailDomainsService.addDomain(domain).then(() => {
                vm.reloadDomains();
                vm.showDomainPopup(domain);
            }).catch(angular.noop).finally(() => {
                vm.addingNewDomain = false;
            });
        };
    }
}());
(function () {
    angular.module('app').component('modalEmailDomain', {
        bindings: {
            show: '=',
            reload: '<',
            domain: '<'
        },
        controller: ModalEmailDomainController,
        controllerAs: 'vm'
    });
    function ModalEmailDomainController($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-email-domain.tpl.html',
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    domain: vm.domain,
                    reloadParentData: vm.reload
                }
            }).then(angular.noop).catch(angular.noop).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($scope, $mdDialog, $q, emailDomainsService, domain, messageService, reloadParentData) {
        const vm = this;
        vm.domain = domain;
        vm.dataLoaded = false;
        vm.failedValidation = false;
        vm.inProgress = {};
        vm.details = undefined;
        vm.statuses = emailDomainsService.DOMAIN_STATUS_KEYS;
        function closeModal() {
            $mdDialog.hide();
        }
        function loadDetails() {
            vm.dataLoaded = false;
            vm.details = undefined;
            emailDomainsService.getEmailDomainDetails(domain).then(details => {
                vm.dataLoaded = true;
                vm.details = details;
            });
        }
        loadDetails();
        vm.closeModal = closeModal;
        vm.addDomain = function () {
            if (vm.inProgress.addDomain) {
                return;
            }
            vm.inProgress.addDomain = true;
            return emailDomainsService.addDomain(vm.domain).then(response => {
                vm.details = response.emailDomainDetails;
                reloadParentData();
            }).catch(() => $q.reject()).finally(() => {
                vm.inProgress.addDomain = false;
            });
        };
        vm.verifyDomain = function () {
            if (vm.inProgress.verifyDomain) {
                return;
            }
            vm.inProgress.verifyDomain = true;
            return emailDomainsService.verifyDomain(vm.domain).then(response => {
                vm.failedValidation = !response.success;
                reloadParentData();
                if (response.emailDomainDetails) {
                    vm.details = response.emailDomainDetails;
                }
                if (!response.success) {
                    return $q.reject();
                }
            }).finally(() => {
                vm.inProgress.verifyDomain = false;
            });
        };
        vm.removeDomain = function () {
            if (vm.inProgress.removeDomain) {
                return;
            }
            vm.inProgress.removeDomain = true;
            let confirm = $mdDialog.confirm().clickOutsideToClose(true).title('Are you sure?').htmlContent('This domain will no longer be authenticated to send email from.').multiple(true).ok('Remove').cancel('Cancel');
            return $mdDialog.show(confirm).then(() => emailDomainsService.removeDomain(vm.domain)).then(() => {
                reloadParentData();
                closeModal();
            }).finally(() => {
                vm.inProgress.removeDomain = false;
            });
        };
        vm.copyValue = function ($event) {
            var toCopy = angular.element($event.currentTarget).parent().find('input').first();
            toCopy.select();
            document.execCommand('copy');
        };
    }
}());
(function () {
    angular.module('app').component('clearviewDetails', {
        templateUrl: 'clearview-details.tpl.html',
        bindings: { username: '=' },
        controller: ClearviewController,
        controllerAs: 'vm'
    });
    function ClearviewController(clearviewService, messageService, campaignService) {
        const vm = this;
        vm.syncClearViewEvent = syncClearViewEvent;
        vm.replicableCampaigns = [{
                name: 'Syncing Existing Neon Fundraise Campaign',
                entityId: -1
            }];
        const replicableCampaigns = campaignService.getRawCampaigns().filter(elem => elem.isReplicable && new RegExp('CVT$').test(elem.name));
        vm.replicableCampaigns.push.apply(vm.replicableCampaigns, replicableCampaigns);
        function syncClearViewEvent(appealCode, cmpToReplicate) {
            if (vm.syncing)
                return;
            vm.syncing = true;
            clearviewService.syncClearView(appealCode, cmpToReplicate.entityId).then(response => {
                if (response.success) {
                    if (cmpToReplicate.entityId !== -1) {
                        campaignService.pushToRawCampaigns(response.data);
                    }
                    messageService.showSuccessToast(response.msg);
                }
                vm.syncing = false;
            }).catch(function () {
                vm.syncing = false;
            });
        }
    }
}());
(function () {
    angular.module('app').component('modalClearviewLogs', {
        bindings: {
            show: '=',
            username: '='
        },
        controller: ModalClearviewLogsCtrl,
        controllerAs: 'vm'
    });
    function ModalClearviewLogsCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-clearview-logs.tpl.html',
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: { username: vm.username }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($scope, $mdDialog, messageService, clearviewService, profileService, campaignService, agGridService, username) {
        const vm = this;
        vm.closeModal = closeModal;
        vm.resyncwithClearView = resyncwithClearView;
        vm.resyncWithCrm = resyncwithClearView;
        vm.loadClearViewLogs = loadClearViewLogs;
        vm.reloadClearViewLogs = reloadClearViewLogs;
        vm.username = username;
        vm.syncing = true;
        var columnDefs = [
            {
                colId: 'ClearViewQueueId',
                field: 'ClearViewQueueId',
                headerName: 'QueueID',
                filter: false,
                enableRowGroup: false
            },
            {
                colId: 'SyncType',
                field: 'SyncType',
                headerName: 'Queue Type',
                sortable: false
            },
            {
                colId: 'InitiatedOn',
                field: 'InitiatedOn',
                headerName: 'Initiated On',
                filter: false,
                valueFormatter: agGridService.formatters.dateTime
            },
            {
                colId: 'CompletedOn',
                field: 'CompletedOn',
                headerName: 'Completed On',
                filter: false,
                hide: true,
                valueFormatter: agGridService.formatters.dateTime
            },
            {
                colId: 'ErroredOn',
                field: 'ErroredOn',
                headerName: 'Errored On',
                filter: false,
                hide: true,
                valueFormatter: agGridService.formatters.dateTime
            },
            {
                colId: 'EventId',
                field: 'EventId',
                headerName: 'Campaign ID',
                filter: true
            },
            {
                colId: 'UserAccountId',
                field: 'UserAccountId',
                headerName: 'UserID',
                sortable: false,
                cellRenderer: agGridService.templates.userAccountId,
                enableRowGroup: false
            },
            {
                colId: 'DonationId',
                field: 'DonationId',
                headerName: 'Donation ID',
                sortable: false,
                cellRenderer: agGridService.templates.donationId,
                enableRowGroup: false
            },
            {
                colId: 'TeamId',
                field: 'TeamId',
                headerName: 'Team ID',
                sortable: false,
                cellRenderer: agGridService.templates.teamId,
                enableRowGroup: false
            },
            {
                colId: 'ErrorString',
                field: 'ErrorString',
                headerName: 'Error Message',
                filter: true
            },
            {
                colId: 'RequestString',
                field: 'RequestString',
                headerName: 'Request XML',
                filter: false,
                hide: true
            },
            {
                colId: 'ResponseString',
                field: 'ResponseString',
                headerName: 'Response XML',
                filter: false,
                hide: true
            },
            {
                colId: 'Resync',
                headerName: 'Resync',
                cellRenderer: agGridService.templates.resync,
                filter: false,
                sortable: false,
                enableRowGroup: false,
                lockVisible: true
            },
            {
                colId: 'Latest',
                field: 'Latest',
                headerName: 'Previous Sync Records',
                filter: agGridService.latestSyncsOnlyFilter(),
                hide: true,
                suppressColumnsToolPanel: true
            }
        ];
        vm.gridOptions = agGridService.createNewGrid(columnDefs, { key: 'modal-clearview-logs' }, {
            onGridReady: params => {
                loadClearViewLogs();
            }
        });
        vm.logOptions = {
            from: moment().subtract(7, 'day').startOf('day').toDate(),
            to: moment().startOf('day').toDate(),
            maxDate: new Date()    // today is the latest logs
        };
        vm.formatDate = (date, format) => moment(date).format(format);
        profileService.getProfileInfo().then(profile => vm.gridOptions.csvFilename = `admin_${ profile.userName.split(' ')[0] }_${ moment().format('YYYYMMDD-HHmm') }.csv`);
        function loadClearViewLogs(from, to) {
            vm.syncing = true;
            agGridService.runGridFilteringForLatest(vm);
            return clearviewService.getClearViewLogs(username, from || vm.logOptions.from, to || vm.logOptions.to).then(clearViewLogs => {
                clearViewLogs.forEach(f => {
                    var campaign = campaignService.getCampaignByEventId(f.EventId);
                    if (campaign) {
                        f.campaignUrl = campaign.campaignUrl;
                    }
                });
                vm.gridOptions.api.setRowData(clearViewLogs);
                vm.syncing = false;
            }).catch(() => vm.syncing = false);
        }
        function reloadClearViewLogs(from, to) {
            if (vm.syncing === false) {
                loadClearViewLogs(from, to);
            }
        }
        function resyncwithClearView(record) {
            clearviewService.reSync(record).then(response => {
                if (response.msgShown)
                    return;
                if (response.success) {
                    messageService.showMsgDialog('Done', 'We added the record to be synced.');
                    loadClearViewLogs();
                } else {
                    messageService.showErrorDialog('Oops', 'Sorry, an error occurred. <br/><b>' + response.msg + '</b>');
                }
            }).catch(response => {
                if (response.msgShown)
                    return;
                messageService.showErrorDialog('Oops', 'Sorry, an error occurred. <br/><b>' + response.msg + '</b>');
            });
        }
        function closeModal() {
            $mdDialog.cancel();
        }
    }
}());
(function () {
    angular.module('app').component('crmLogDateFilter', {
        bindings: {
            to: '=',
            from: '=',
            submit: '=',
            syncing: '=',
            maxDate: '='
        },
        controllerAs: 'vm',
        templateUrl: 'crm-log-date-filter.tpl.html'
    });
}());
(function () {
    angular.module('app').component('modalNeoncrmLogs', {
        bindings: {
            show: '=',
            username: '='
        },
        controller: ModalNeoncrmLogsCtrl,
        controllerAs: 'vm'
    });
    function ModalNeoncrmLogsCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-neoncrm-logs.tpl.html',
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: { username: vm.username }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($scope, $mdDialog, messageService, neonCrmService, profileService, campaignService, agGridService, username) {
        const vm = this;
        vm.closeModal = closeModal;
        vm.reloadNeonCrmLogs = reloadNeonCrmLogs;
        vm.resyncWithNeonCrm = resyncWithNeonCrm;
        vm.resyncWithCrm = resyncWithNeonCrm;
        vm.syncing = true;
        vm.username = username;
        vm.logOptions = {
            from: moment().subtract(7, 'day').startOf('day').toDate(),
            to: moment().startOf('day').toDate(),
            maxDate: new Date()    // today is the latest logs
        };
        vm.formatDate = (date, format) => moment(date).format(format);
        var columnDefs = [
            {
                colId: 'NeonCrmQueueId',
                field: 'NeonCrmQueueId',
                headerName: 'QueueID',
                filter: false,
                enableRowGroup: false
            },
            {
                colId: 'SyncType',
                field: 'SyncType',
                headerName: 'Queue Type',
                sortable: false
            },
            {
                colId: 'InitiatedOn',
                field: 'InitiatedOn',
                headerName: 'Initiated On',
                filter: false,
                valueFormatter: agGridService.formatters.dateTime
            },
            {
                colId: 'CompletedOn',
                field: 'CompletedOn',
                headerName: 'Completed On',
                hide: true,
                filter: false,
                valueFormatter: agGridService.formatters.dateTime
            },
            {
                colId: 'ErroredOn',
                field: 'ErroredOn',
                headerName: 'Errored On',
                hide: true,
                filter: false,
                valueFormatter: agGridService.formatters.dateTime
            },
            {
                colId: 'EventId',
                field: 'EventId',
                headerName: 'Campaign ID',
                filter: true
            },
            {
                colId: 'UserAccountId',
                field: 'UserAccountId',
                headerName: 'UserID',
                sortable: false,
                cellRenderer: agGridService.templates.userAccountId,
                enableRowGroup: false
            },
            {
                colId: 'DonationId',
                field: 'DonationId',
                headerName: 'Donation ID',
                sortable: false,
                cellRenderer: agGridService.templates.donationId,
                enableRowGroup: false
            },
            {
                colId: 'MemberRolesSubmissionId',
                field: 'MemberRolesSubmissionId',
                headerName: 'Member Roles Submission ID',
                sortable: false,
                cellRenderer: agGridService.templates.memberRolesSubmissionId,
                enableRowGroup: false,
                hide: true
            },
            {
                colId: 'RegistrationId',
                field: 'RegistrationId',
                headerName: 'Registration ID',
                sortable: false,
                cellRenderer: agGridService.templates.registrationId,
                enableRowGroup: false
            },
            {
                colId: 'ErrorString',
                field: 'ErrorString',
                headerName: 'Error Message',
                filter: true,
                hide: true
            },
            {
                colId: 'ResponseString',
                field: 'ResponseString',
                headerName: 'Response String',
                filter: true
            },
            {
                colId: 'RequestString',
                field: 'RequestString',
                headerName: 'Request String',
                filter: true,
                hide: true
            },
            {
                colId: 'Resync',
                headerName: 'Resync',
                cellRenderer: agGridService.templates.resync,
                filter: false,
                sortable: false,
                enableRowGroup: false,
                lockVisible: true
            },
            {
                colId: 'Latest',
                field: 'Latest',
                headerName: 'Previous Sync Records',
                filter: agGridService.latestSyncsOnlyFilter(),
                hide: true,
                suppressColumnsToolPanel: true
            }
        ];
        vm.gridOptions = agGridService.createNewGrid(columnDefs, { key: 'modal-neoncrm-logs' }, { onGridReady: loadNeonCrmLogs });
        profileService.getProfileInfo().then(profile => vm.gridOptions.csvFilename = `admin_${ profile.userName.split(' ')[0] }_${ moment().format('YYYYMMDD-HHmm') }.csv`);
        function loadNeonCrmLogs() {
            vm.syncing = true;
            agGridService.runGridFilteringForLatest(vm);
            return neonCrmService.getNeonCrmLogs(vm.username, vm.logOptions.from, vm.logOptions.to).then(neonCrmLogs => {
                neonCrmLogs.forEach(f => {
                    var campaign = campaignService.getCampaignByEventId(f.EventId);
                    if (campaign) {
                        f.campaignUrl = campaign.campaignUrl;
                    }
                });
                vm.gridOptions.api.setRowData(neonCrmLogs);
                vm.syncing = false;
            }).catch(() => vm.syncing = false);
        }
        function reloadNeonCrmLogs() {
            if (vm.syncing === false) {
                return loadNeonCrmLogs();
            }
        }
        function resyncWithNeonCrm(record) {
            neonCrmService.reSync(record).then(response => {
                if (response.msgShown)
                    return;
                if (response.success) {
                    messageService.showMsgDialog('Done', 'We added the record to be synced.');
                    loadNeonCrmLogs();
                } else {
                    messageService.showErrorDialog('Oops', 'Sorry, an error occurred. <br/><b>' + response.msg + '</b>');
                }
            }).catch(response => {
                if (response.msgShown)
                    return;
                messageService.showErrorDialog('Oops', 'Sorry, an error occurred. <br/><b>' + response.msg + '</b>');
            });
        }
        function closeModal() {
            $mdDialog.cancel();
        }
    }
}());
(function () {
    angular.module('app').component('modalSalesforceLogs', {
        bindings: {
            show: '=',
            sfId: '='
        },
        controller: ModalSalesforceLogsCtrl,
        controllerAs: 'vm'
    });
    function ModalSalesforceLogsCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-salesforce-logs.tpl.html',
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: { sfId: vm.sfId }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($scope, $mdDialog, messageService, salesforceService, profileService, campaignService, agGridService, sfId) {
        const vm = this;
        vm.closeModal = closeModal;
        vm.reloadSalesforceLogs = reloadSalesforceLogs;
        vm.resyncWithSalesforce = resyncWithSalesforce;
        vm.resyncWithCrm = resyncWithSalesforce;
        vm.syncing = true;
        vm.sfId = sfId;
        vm.logOptions = {
            from: moment().subtract(7, 'day').startOf('day').toDate(),
            to: moment().startOf('day').toDate(),
            maxDate: new Date()    // today is the latest logs
        };
        vm.formatDate = (date, format) => moment(date).format(format);
        var columnDefs = [
            {
                colId: 'SalesforceQueueId',
                field: 'SalesforceQueueId',
                headerName: 'Queue ID',
                filter: false,
                enableRowGroup: false
            },
            {
                colId: 'SyncType',
                field: 'SyncType',
                headerName: 'Queue Type',
                sortable: false
            },
            {
                colId: 'Status',
                field: 'Status',
                sortable: false,
                cellRenderer: agGridService.templates.status,
                valueFormatter: agGridService.formatters.capitalizeFirstLetter,
                tooltipValueGetter: agGridService.formatters.capitalizeFirstLetter
            },
            {
                colId: 'InitiatedOn',
                field: 'InitiatedOn',
                headerName: 'Initiated On',
                filter: false,
                valueFormatter: agGridService.formatters.dateTime
            },
            {
                colId: 'CompletedOn',
                field: 'CompletedOn',
                headerName: 'Completed On',
                filter: false,
                hide: true,
                valueFormatter: agGridService.formatters.dateTime
            },
            {
                colId: 'EventId',
                field: 'EventId',
                headerName: 'Campaign ID',
                filter: false,
                sortable: false
            },
            {
                colId: 'UserId',
                field: 'UserId',
                headerName: 'User ID',
                sortable: false,
                cellRenderer: agGridService.templates.userId,
                enableRowGroup: false
            },
            {
                colId: 'GoalId',
                field: 'GoalId',
                headerName: 'Goal ID',
                sortable: false,
                enableRowGroup: false
            },
            {
                colId: 'DonationId',
                field: 'DonationId',
                headerName: 'Donation ID',
                sortable: false,
                cellRenderer: agGridService.templates.donationId,
                enableRowGroup: false
            },
            {
                colId: 'MemberRoleId',
                field: 'MemberRoleId',
                headerName: 'Member Role ID',
                sortable: false,
                enableRowGroup: false
            },
            {
                colId: 'RegistrationId',
                field: 'RegistrationId',
                headerName: 'Registration ID',
                sortable: false,
                enableRowGroup: false
            },
            {
                colId: 'RbCreated',
                field: 'RbCreated',
                headerName: 'Rb Created',
                filter: false,
                sortable: false,
                hide: true,
                cellRenderer: agGridService.templates.booleanImage
            },
            {
                colId: 'SalesforceId',
                field: 'SalesforceId',
                headerName: 'Salesforce ID',
                sortable: false,
                hide: true,
                cellRenderer: agGridService.templates.salesforce,
                enableRowGroup: false
            },
            {
                colId: 'SalesforceId2',
                field: 'SalesforceId2',
                headerName: 'Salesforce ID2',
                sortable: false,
                hide: true,
                cellRenderer: agGridService.templates.salesforce,
                enableRowGroup: false
            },
            {
                colId: 'SalesforceId3',
                field: 'SalesforceId3',
                headerName: 'Salesforce ID3',
                sortable: false,
                hide: true,
                cellRenderer: agGridService.templates.salesforce,
                enableRowGroup: false
            },
            {
                colId: 'TeamId',
                field: 'TeamId',
                headerName: 'Team ID',
                hide: true,
                sortable: false,
                enableRowGroup: false
            },
            {
                colId: 'Error',
                field: 'Error',
                filter: false,
                sortable: false
            },
            {
                colId: 'IsResync',
                field: 'IsResync',
                headerName: 'Is Resync',
                filter: false,
                sortable: false,
                hide: true,
                cellRenderer: agGridService.templates.booleanImage,
                enableRowGroup: false
            },
            {
                colId: 'LastRetriedOn',
                field: 'LastRetriedOn',
                headerName: 'Last Retried On',
                filter: false,
                hide: true,
                valueFormatter: agGridService.formatters.dateTime
            },
            {
                colId: 'Resync',
                headerName: 'Resync',
                cellRenderer: agGridService.templates.resync,
                filter: false,
                sortable: false,
                enableRowGroup: false,
                lockVisible: true
            },
            {
                colId: 'Latest',
                field: 'Latest',
                headerName: 'Previous Sync Records',
                filter: agGridService.latestSyncsOnlyFilter(),
                hide: true,
                suppressColumnsToolPanel: true
            }
        ];
        vm.gridOptions = agGridService.createNewGrid(columnDefs, { key: 'modal-salesforce-logs' }, { onGridReady: loadSalesforceLogs });
        profileService.getProfileInfo().then(profile => vm.gridOptions.csvFilename = `admin_${ profile.userName.split(' ')[0] }_${ moment().format('YYYYMMDD-HHmm') }.csv`);
        function loadSalesforceLogs() {
            vm.syncing = true;
            agGridService.runGridFilteringForLatest(vm);
            return salesforceService.getSalesforceLogs(vm.sfId, vm.logOptions.from, vm.logOptions.to).then(salesforceLogs => {
                salesforceLogs.forEach(f => {
                    var campaign = campaignService.getCampaignByEventId(f.EventId);
                    if (campaign) {
                        f.campaignUrl = campaign.campaignUrl;
                    }
                });
                vm.gridOptions.api.setRowData(salesforceLogs);
                vm.syncing = false;
            }).catch(() => vm.syncing = false);
        }
        function reloadSalesforceLogs() {
            if (vm.syncing === false) {
                return loadSalesforceLogs();
            }
        }
        function resyncWithSalesforce(record) {
            salesforceService.reSync(record).then(response => {
                if (response.msgShown)
                    return;
                if (response.success) {
                    messageService.showMsgDialog('Done', 'We added the record to be synced.');
                    loadSalesforceLogs();
                } else {
                    messageService.showErrorDialog('Oops', 'Sorry, an error occurred. <br/><b>' + response.msg + '</b>');
                }
            }).catch(response => {
                if (response.msgShown)
                    return;
                messageService.showErrorDialog('Oops', 'Sorry, an error occurred. <br/><b>' + response.msg + '</b>');
            });
        }
        function closeModal() {
            $mdDialog.cancel();
        }
    }
}());
(function () {
    angular.module('app').component('salesforceDetails', {
        templateUrl: 'salesforce-details.tpl.html',
        bindings: {
            id: '=',
            $router: '<'
        },
        controller: SalesForceController,
        controllerAs: 'vm'
    });
    function SalesForceController($scope, salesforceService) {
        const vm = this;
        vm.loading = true;
        vm.isSandbox = false;
        vm.sfInfo = {
            username: undefined,
            instanceUrl: undefined,
            loginPath: undefined
        };
        vm.onReconnect = data => {
            vm.$router.navigate([
                'Integration Details',
                {
                    type: 'Salesforce',
                    typeId: data.authId.split('/').join('~~')
                }
            ]);
        };
        $scope.$watch('vm.id', () => {
            if (!vm.id) {
                return false;
            }
            vm.loading = true;
            salesforceService.getSalesforceInfo(vm.id).then(sfInfo => {
                vm.sfInfo = sfInfo;
            }).catch(() => {
                vm.$router.navigate(['Integrations']);
            }).finally(() => {
                vm.loading = false;
            });
        });
    }
}());
(function () {
    angular.module('app').component('sfConnectBtn', {
        templateUrl: 'sf-connect-btn.tpl.html',
        bindings: {
            isSandbox: '=',
            authenticationId: '=',
            buttonText: '@',
            onConnected: '<',
            newWindow: '<'
        },
        controller: SfConnectBtnComponent,
        controllerAs: 'vm'
    });
    function SfConnectBtnComponent($scope, $interval, salesforceService, messageService) {
        const vm = this;
        vm.connecting = false;
        window.addEventListener('message', completeConnection, false);
        if (window.BroadcastChannel) {
            const channel = new window.BroadcastChannel('OA_salesforce_connection');
            channel.onmessage = completeConnection;
        }
        vm.connectToSalesforce = () => {
            vm.connecting = true;
            salesforceService.getSalesforceUrl(vm.isSandbox || false, vm.authenticationId).then(data => {
                if (!data.loginPath) {
                    vm.connecting = false;
                    messageService.showErrorToast('Unable to get Salesforce login link. Please contact support.');
                    return;
                }
                // open the popup
                vm.win = window.open(data.loginPath, '_blank', 'location=no,menubar=no,status=no,titlebar=no,toolbar=no');
                vm.windowOpenTimer = $interval(function () {
                    if (!vm.win || vm.win.closed) {
                        if (!vm.win) {
                            messageService.showErrorToast('Salesforce pop-up blocked. Please unblock pop-ups in your browser settings.');
                        }
                        $interval.cancel(vm.windowOpenTimer);
                        vm.connecting = false;
                    }
                }, 500);
            }).catch(e => {
                vm.connecting = false;
            });
        };
        function completeConnection(event) {
            vm.connecting = false;
            if (event.data.success) {
                if (typeof vm.onConnected == 'function') {
                    vm.onConnected(event.data);
                }
            }
            if (event.data.redirect) {
                messageService.showSuccessToast('Starting Salesforce to Neon Fundraise Sync...', 'Show', vm.win.focus, 30000);
                window.focus();
            } else {
                vm.win.close();
            }
            if (!event.data.success && event.data.error) {
                messageService.showErrorToast(event.data.error);
            }
        }
    }
}());
(function () {
    angular.module('app').component('sfPackageLinks', {
        templateUrl: 'sf-package-links.tpl.html',
        bindings: { isSandbox: '=' },
        controllerAs: 'vm'
    });
}());
(function () {
    angular.module('app').component('integrationCard', {
        bindings: {
            integration: '<',
            $router: '<',
            showLog: '<'
        },
        templateUrl: 'integration-card.tpl.html',
        controller: IntegrationCardController,
        controllerAs: 'vm'
    });
    function IntegrationCardController(orgIntegrationsService) {
        const vm = this;
        vm.open = function () {
            vm.$router.navigate([
                'Integration Details',
                {
                    type: vm.integration.Type,
                    typeId: vm.integration.Id.split('/').join('~~')
                }
            ]);
        };
        vm.hasLogs = function () {
            return vm.integration && orgIntegrationsService.hasLogs(vm.integration.Type) >= 0;
        };
    }
}());
(function () {
    angular.module('app').component('integrationCampaigns', {
        templateUrl: 'integration-campaigns.tpl.html',
        bindings: {
            $router: '<',
            type: '=',
            typeId: '=',
            deleting: '='
        },
        controller: IntegrationCampaignsController,
        controllerAs: 'vm'
    });
    function IntegrationCampaignsController(orgIntegrationsService, campaignService, securityService, orgService, messageService) {
        const vm = this;
        vm.filteredItems = [];
        const paginationSize = 20;
        vm.scrollCount = paginationSize;
        vm.loadMore = function () {
            if (vm.campaigns.length > vm.filteredItems.length) {
                vm.scrollCount += paginationSize;
            }
        };
        vm.search = {};
        vm.getSecurity = (entity, resource) => securityService.getAdminPermissions(entity, resource);
        vm.selectCampaign = function (campaign) {
            if (!vm.deleting) {
                window.open(orgService.getCampaignPath(campaign), '_blank');
            }
        };
        vm.deleteIntegration = function () {
            vm.deleting = true;
            return orgIntegrationsService.deleteIntegration(vm.type, vm.typeId).then(() => {
                vm.$router.navigate(['Integrations']);
            }).catch(e => {
                messageService.showErrorToast(e);
            });
        };
        vm.$onInit = function () {
            orgIntegrationsService.getIntegrationCampaigns(vm.type, vm.typeId).then(campaigns => {
                vm.canDelete = campaigns.length === 0 && orgIntegrationsService.canDelete(vm.type) && vm.getSecurity(orgService.getOrg(), 'SETTINGS_INTEGRATION_OPTIONS').DELETE;
                vm.campaigns = campaignService.getRawCampaigns().filter(c => campaigns.indexOf(c.curEventId) >= 0);
                vm.hiddenCampaigns = campaigns.length != vm.campaigns.length;
            });
        };
    }
}());
(function () {
    angular.module('app').component('integrationDetails', {
        templateUrl: 'integration-details.tpl.html',
        bindings: { $router: '<' },
        controller: IntegrationController,
        controllerAs: 'vm'
    });
    function IntegrationController($location, orgIntegrationsService) {
        const vm = this;
        vm.deleting = false;
        vm.modal = {
            showing: {},
            id: null
        };
        vm.showLog = function (type, id) {
            vm.modal.showing = { [type]: true };
            vm.modal.id = id;
        };
        vm.pages = {
            INFORMATION: 'Information',
            CAMPAIGNS: 'Campaigns'
        };
        vm.openPage = function ($event, page) {
            vm.page = page;
        };
        this.$routerOnActivate = (next, previous) => {
            vm.type = next.params.type;
            vm.typeId = window.decodeURIComponent(next.params.typeId).split('~~').join('/');
            vm.hasInformation = orgIntegrationsService.hasInformation(vm.type);
            vm.hasLogs = orgIntegrationsService.hasLogs(vm.type);
            if (!vm.page) {
                vm.page = vm.hasInformation ? vm.pages.INFORMATION : vm.pages.CAMPAIGNS;
            }
        };
    }
}());
(function () {
    angular.module('app').component('modalAddOrgIntegration', {
        bindings: {
            show: '=',
            reload: '<'
        },
        controller: ModalAddOrgIntegrationController,
        controllerAs: 'vm'
    });
    function ModalAddOrgIntegrationController($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-add-org-integration.tpl.html',
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm'
            }).then(response => {
                if (response && response.reload && typeof vm.reload == 'function') {
                    vm.reload();
                }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($scope, $mdDialog) {
        const vm = this;
        vm.closeModal = () => {
            $mdDialog.cancel({ reload: false });
        };
        vm.location = null;
        vm.open = location => vm.location = location;
        vm.onConnected = () => {
            $mdDialog.hide({ reload: true });
        };
    }
}());
(function () {
    angular.module('app').component('orgIntegrations', {
        templateUrl: 'org-integrations.tpl.html',
        bindings: { $router: '<' },
        controller: IntegrationController,
        controllerAs: 'vm'
    });
    function IntegrationController(orgIntegrationsService) {
        const vm = this;
        vm.modal = {
            showing: {},
            id: null
        };
        vm.addingIntegration = false;
        vm.addIntegration = () => vm.addingIntegration = true;
        vm.showLog = function (type, id) {
            vm.modal.showing = { [type]: true };
            vm.modal.id = id;
        };
        vm.loadIntegrations = () => {
            orgIntegrationsService.getIntegrations().then(integrations => {
                vm.integrations = integrations;
            }).catch(() => {
                vm.error = 'Unable to load integrations. Please contact support';
            });
        };
        vm.loadIntegrations();
    }
}());
(function () {
    angular.module('app').component('organization', {
        templateUrl: 'organization.tpl.html',
        controllerAs: 'vm',
        controller: OrganizationController,
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        },
        bindings: { $router: '<' },
        $routeConfig: [
            {
                path: '/administrators',
                name: 'Administrator List',
                component: 'administratorsList',
                useAsDefault: true,
                authenticate: true
            },
            {
                path: '/security-roles',
                name: 'Security Roles',
                component: 'securityRoles',
                authenticate: true
            },
            {
                path: '/automation',
                name: 'Automation',
                component: 'automation',
                authenticate: true
            },
            {
                path: '/integrations',
                name: 'Integrations',
                component: 'orgIntegrations',
                authenticate: true
            },
            {
                path: '/integrations/:type/:typeId',
                name: 'Integration Details',
                component: 'integrationDetails',
                authenticate: true
            },
            {
                path: '/email-domains',
                name: 'Email Domains',
                component: 'emailDomains',
                authenticate: true
            },
            {
                path: '/payment-processors',
                name: 'Processors',
                component: 'orgProcessors',
                authenticate: true
            },
            {
                path: '/**',
                name: 'Page Not Found',
                component: 'pageNotFound',
                authenticate: true
            }
        ]
    });
    function OrganizationController() {
        const vm = this;
    }
}());
(function () {
    angular.module('app').component('orgProcessors', {
        templateUrl: 'org-processors.tpl.html',
        bindings: { $router: '<' },
        controller: OrgProcessorController,
        controllerAs: 'vm'
    });
    function OrgProcessorController(orgProcessorsService) {
        const vm = this;
        vm.dataLoaded = false;
        loadProcessors();
        function loadProcessors() {
            orgProcessorsService.getProcessors().then(processors => {
                vm.dataLoaded = true;
                vm.processors = processors;
            }).catch(() => {
                vm.error = 'Unable to load processors. Please contact support';
            });
        }
        ;
    }
}());
(function () {
    angular.module('app').component('processorCard', {
        bindings: {
            processor: '<',
            $router: '<',
            showMessage: '<',
            showOptions: '<',
            showHover: '<'
        },
        templateUrl: 'processor-card.tpl.html',
        controller: ProcessorCardController,
        controllerAs: 'vm'
    });
    function ProcessorCardController($scope) {
        const vm = this;
        vm.showImage = true;
        vm.getDefaultImage = processorName => {
            if (processorName === 'FirstPay ACH') {
                return 'FirstPay';
            } else if (processorName === 'Vantiv') {
                return 'Worldpay';
            } else {
                return processorName;
            }
        };
        vm.updateInstance = function (action) {
            $scope.$emit(action, vm.processor.PaymentProcessorInstanceId);
        };
    }
}());
(function () {
    angular.module('app').component('securityRoles', {
        templateUrl: 'security-roles.tpl.html',
        controller: SecurityRolesController,
        controllerAs: 'vm'
    });
    function SecurityRolesController($mdDialog, administratorService, securityService, orgService) {
        const vm = this;
        vm.securityRoles = [];
        vm.resources = [];
        vm.dataLoaded = false;
        vm.offEdits = offEdits;
        vm.editRole = editRole;
        vm.cancelEdit = cancelEdit;
        vm.saveRole = saveRole;
        vm.addNewRole = addNewRole;
        vm.deleteRole = deleteRole;
        vm.parentResourceChangePermission = parentResourceChangePermission;
        vm.selectAllPermissions = selectAllPermissions;
        vm.openPermissions = openPermissions;
        vm.changeRessourcePermission = changeRessourcePermission;
        vm.getPermissionName = getPermissionName;
        vm.security = {};
        let _cachedRoles = {};
        let _resources = [];
        vm.permissions = {
            NONE: 0,
            VIEW: 1,
            EDIT: 3,
            // VIEW | EDIT
            DELETE: 7,
            // VIEW | EDIT | DELETE
            VARIOUS: -1
        };
        loadData();
        /////////////////////////////////////////////////////////////////
        function loadData() {
            administratorService.getSecurityRoles().then(({roles, resources}) => {
                vm.dataLoaded = true;
                vm.securityRoles = roles;
                _resources = resources;
            });
        }
        vm.security = securityService.getAdminPermissions(orgService.getOrg(), 'ADMIN_USERS');
        function offEdits(index) {
            for (let i = 0; i < vm.securityRoles.length; i++) {
                if (i !== index && vm.securityRoles[i].isEditing && !vm.securityRoles[i].isDefault) {
                    vm.saveRole(i, vm.securityRoles[i]);
                    vm.securityRoles[i].nameIsEditing = false;
                } else if (i !== index && vm.securityRoles[i].isEditing && vm.securityRoles[i].isDefault) {
                    vm.securityRoles[i].isEditing = false;
                    vm.securityRoles[i].nameIsEditing = false;
                }
            }
        }
        function editRole($index) {
            let role = vm.securityRoles[$index];
            _cachedRoles[role.roleId] = angular.copy(role);
            role.isEditing = true;
        }
        function cancelEdit($index) {
            let roleId = vm.securityRoles[$index].roleId;
            if (roleId > 0 && _cachedRoles[roleId]) {
                vm.securityRoles[$index] = angular.copy(_cachedRoles[roleId]);
                vm.securityRoles[$index].isEditing = false;
                vm.securityRoles[$index].nameIsEditing = false;
            } else {
                // not saved role
                vm.securityRoles.splice($index, 1);
            }
        }
        function saveRole(index, role) {
            role.isSaving = true;
            const cb = () => {
                role.isEditing = false;
                role.nameIsEditing = false;
            };
            if (role.roleId) {
                // update exited
                //role.permissionId = _checkRoleChildren(role.resources);
                // role.resources.forEach((res) => {
                //      res.permissionId = _checkRoleChildren(res.childs);
                // });
                administratorService.updateSecurityRole(role).then(cb).finally(() => role.isSaving = false);
            } else {
                // create new one
                let messageConfig = {
                    successToastEnabled: true,
                    successMsg: `Successfuly created role ${ role.roleName }`
                };
                administratorService.createSecurityRole(role, messageConfig).then(response => {
                    let roleId = response.data.data.roleId;
                    role.roleId = roleId;
                    cb();
                }).finally(() => role.isSaving = false);
            }
        }
        function addNewRole() {
            let role = {};
            /* Construct role object */
            role.roleId = null;
            role.roleName = 'New Role ' + (vm.securityRoles.length + 1);
            role.permissionId = 0;
            role.isEditing = true;
            role.nameIsEditing = true;
            role.isDefault = false;
            role.resources = angular.copy(_resources);
            // set all resources and childs to NONE permission
            role.resources.forEach(r => {
                r.permissionId = 0;
                r.childs.forEach(c => c.permissionId = 0);
            });
            vm.securityRoles.push(role);
        }
        function deleteRole($index) {
            let role = vm.securityRoles[$index];
            const cb = () => {
                vm.securityRoles.splice($index, 1);
            };
            let confirm = $mdDialog.confirm().title('Confirm delete').textContent(`Are you sure you want to delete role '${ role.roleName }'?`).ariaLabel('Delete').ok('Yes').cancel('No');
            $mdDialog.show(confirm).then(() => {
                role.isDeleting = true;
                if (_cachedRoles[role.roleId]) {
                    administratorService.deleteSecurityRole(role).then(cb).finally(() => role.isDeleting = false);
                } else {
                    // not saved role
                    cb();
                    role.isDeleting = true;
                }
            });
        }
        function _applyPermission(res, role) {
            let childrenCanSet = {
                delete: true,
                edit: true,
                view: true
            };
            if (res.childs) {
                res.childs.forEach(child => {
                    childrenCanSet.delete = childrenCanSet.delete && child.canSet.Delete;
                    childrenCanSet.edit = childrenCanSet.edit && child.canSet.Edit;
                    childrenCanSet.view = childrenCanSet.view && child.canSet.View;
                });
            }
            if (!childrenCanSet.delete && role.permissionId == vm.permissions.DELETE || !childrenCanSet.edit && role.permissionId == vm.permissions.EDIT || !childrenCanSet.view && role.permissionId == vm.permissions.VIEW) {
                res.permissionId = vm.permissions.VARIOUS;
            } else {
                res.permissionId = role.permissionId;
                if (!res.canSet.Delete && res.permissionId == vm.permissions.DELETE) {
                    res.permissionId = vm.permissions.EDIT;
                }
                if (!res.canSet.Edit && res.permissionId == vm.permissions.EDIT) {
                    res.permissionId = vm.permissions.VIEW;
                }
                if (!res.canSet.View && res.permissionId == vm.permissions.VIEW) {
                    res.permissionId = vm.permissions.NONE;
                }
            }
        }
        function selectAllPermissions(role) {
            if (role.permissionId != vm.permissions.VARIOUS) {
                //hide resources if open
                role.open = false;
                role.resources.forEach(resource => {
                    _applyPermission(resource, role);
                    resource.childs.forEach(child => _applyPermission(child, role));
                });
            } else {
                role.open = true;
            }
        }
        function _checkRoleChildren(children) {
            let permissionIds = [], rolePermissionId;
            children.forEach(res => {
                permissionIds.push(Number(res.permissionId));
            });
            function checkIfEqual() {
                let first = permissionIds[0];
                return permissionIds.every(function (element) {
                    return element === first;
                });
            }
            ;
            if (checkIfEqual() == true) {
                rolePermissionId = permissionIds[0];
            } else {
                rolePermissionId = vm.permissions.VARIOUS;
            }
            return rolePermissionId;
        }
        function parentResourceChangePermission(role, resource) {
            role.permissionId = _checkRoleChildren(role.resources);
            if (resource.permissionId == vm.permissions.VARIOUS)
                return;
            // inherit permissionId from parent 'resource'
            resource.childs.forEach(child => _applyPermission(child, resource));
        }
        function openPermissions(role) {
            if (role.open === true) {
                role.open = false;
            } else {
                role.open = true;
            }
        }
        function changeRessourcePermission(role, resource) {
            resource.permissionId = _checkRoleChildren(resource.childs);
            role.permissionId = _checkRoleChildren(role.resources);
        }
        function getPermissionName(permissionId) {
            return administratorService.getSecurityPermissionName(permissionId);
        }
    }
}());
(function () {
    angular.module('app').component('notifications', {
        templateUrl: 'notifications.tpl.html',
        controller: NotificationsController,
        controllerAs: 'vm'
    });
    function NotificationsController(administratorService, profileService) {
        const vm = this;
        vm.notificationsList = [];
        vm.columnSelectedAll = [];
        // 4 columns
        vm.loadingInProgfress = false;
        vm.isDataChanged = false;
        // see if any checkbox was changed
        vm.isDataSaving = false;
        vm.saveNotifications = saveNotifications;
        vm.selectAllForCampaign = selectAllForCampaign;
        vm.selectAllForType = selectAllForType;
        vm.handleCheckboxChange = handleCheckboxChange;
        vm.getHoverMessage = getHoverMessage;
        fetchNotifications();
        /////////////////////////////////////////////////////////////////
        function fetchNotifications() {
            vm.loadingInProgfress = true;
            profileService.getNotificationsSettings().then(notificationsList => {
                vm.notificationsList = notificationsList;
                updateSelectAllCheckboxes();
            }).finally(() => vm.loadingInProgfress = false);
        }
        function saveNotifications() {
            vm.isDataSaving = true;
            profileService.saveNotifications(vm.notificationsList).then(response => vm.isDataChanged = false).finally(() => vm.isDataSaving = false);
        }
        function updateSelectAllCheckboxes() {
            vm.columnSelectedAll = [
                true,
                true,
                true,
                true
            ];
            vm.notificationsList.forEach(notificationOrg => {
                notificationOrg.campaigns.forEach(notification => {
                    /* check if every checkbox in row is selected */
                    notification.selectedAll = true;
                    notification.flags.forEach((flag, index) => {
                        if (!flag) {
                            notification.selectedAll = false;
                            vm.columnSelectedAll[index] = false;
                        }
                    });
                });
            });
        }
        function handleCheckboxChange() {
            updateSelectAllCheckboxes();
            vm.isDataChanged = true;
        }
        function selectAllForCampaign(notification) {
            let value = notification.selectedAll;
            notification.flags = notification.flags.map((flag, i) => {
                if (notification.permissionFlags[i]) {
                    return value;
                }
                return flag;
            });
            handleCheckboxChange();
        }
        function selectAllForType(typeIndex) {
            let value = vm.columnSelectedAll[typeIndex];
            vm.notificationsList.forEach(notificationOrg => {
                notificationOrg.campaigns.forEach(notification => {
                    if (notification.permissionFlags[typeIndex]) {
                        notification.flags[typeIndex] = value;
                    }
                });
            });
            handleCheckboxChange();
        }
        function getHoverMessage(notification, flagIndex) {
            return `You don't have permission to this resource`;
        }
    }
}());
(function () {
    angular.module('app').component('modalPassword', {
        bindings: {
            show: '=',
            password: '='
        },
        controller: ModalPasswordCtrl,
        controllerAs: 'vm'
    });
    function ModalPasswordCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-change-password.tpl.html',
                clickOutsideToClose: true,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: { password: vm.password }
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($mdDialog, profileService, password) {
        const vm = this;
        vm.password = password;
        vm.cancel = closeModal;
        vm.savePassword = savePassword;
        function closeModal() {
            $mdDialog.hide();
        }
        function savePassword() {
            vm.saveInProgress = true;
            let messageConfig = {
                successToastEnabled: true,
                successMsg: 'Password changed successfully.'
            };
            profileService.resetPassword(vm.currentPassword, vm.newPassword, vm.passwordConfirmation, messageConfig).then(response => {
                closeModal();
            }).finally(() => vm.saveInProgress = false);
        }
    }
}());
(function () {
    angular.module('app').component('modalCropProfileImg', {
        controller: ModalCropCtrl,
        controllerAs: 'vm'
    });
    function ModalCropCtrl($scope, $mdDialog, $rootScope) {
        const vm = this;
        let uploadHandler = $rootScope.$on('file.uploaded', (event, data) => {
            vm.img = data.img;
            showModal();
        });
        $scope.$on('$destroy', uploadHandler);
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-crop.tpl.html',
                controller: ModalCropInnerCtrl,
                controllerAs: 'vm',
                locals: { img: vm.img },
                clickOutsideToClose: false
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalCropInnerCtrl($q, $mdDialog, $rootScope, profileService, img) {
        const vm = this;
        vm.closeModal = closeModal;
        vm.applyCropped = applyCropped;
        vm.img = img;
        vm.croppedImage = '';
        function applyCropped() {
            if (vm.saveInProgress)
                return;
            vm.saveInProgress = true;
            profileService.saveProfileImage(vm.croppedImage).then(response => {
                $rootScope.$emit('file.approved', response);
                vm.saveInProgress = false;
                closeModal();
            }).finally(() => {
                vm.saveInProgress = false;
            });
        }
        function closeModal() {
            $mdDialog.hide();
        }
    }
}());
(function () {
    angular.module('app').component('profile', {
        templateUrl: 'profile.tpl.html',
        controller: ProfileController,
        controllerAs: 'vm'
    });
    function ProfileController($q, $scope, $rootScope, profileService) {
        const vm = this;
        vm.profile = null;
        vm.editFlag = {
            userName: false,
            userEmail: false,
            password: false
        };
        vm.showPasswordModal = false;
        vm.saveInProgress = false;
        vm.saveEdit = saveEdit;
        vm.cancelEdit = cancelEdit;
        vm.offEdits = offEdits;
        vm.changePassword = changePassword;
        loadProfileInfo();
        ///////////////////////////////////
        function changePassword($event) {
            $event.stopPropagation();
            if (!vm.profile)
                return;
            if (vm.profile.isNeonSsoOnlyUser) {
                window.open(vm.profile.neonSsoUrl + '/user/password');
                return;
            }
            vm.showPasswordModal = true;
        }
        function loadProfileInfo() {
            profileService.getProfileInfo().then(profileInfo => {
                vm.profile = profileInfo;
                vm.previousProfile = angular.copy(vm.profile);
            });
        }
        function offEdits(field) {
            for (let f in vm.profile) {
                if (f != field) {
                    cancelEdit(f);
                }
            }
        }
        function saveEdit(field) {
            vm.saveInProgress = true;
            let messageConfig = { successToastEnabled: true };
            if (field == 'userName') {
                messageConfig.successMsg = 'Name changed successfully.';
                profileService.setNameForUser(vm.profile.userName, messageConfig).then(response => {
                    vm.previousProfile.userName = vm.profile.userName;
                    vm.editFlag.userName = false;
                }).finally(() => vm.saveInProgress = false);
            }
            if (field == 'userEmail') {
                messageConfig.successMsg = 'Email changed successfully';
                profileService.saveEmail(vm.profile.userEmail, messageConfig).then(response => {
                    vm.previousProfile.userEmail = vm.profile.userEmail;
                    vm.editFlag.userEmail = false;
                }).finally(() => vm.saveInProgress = false);
            }
        }
        function cancelEdit(field) {
            console.log(vm.previousProfile);
            vm.profile[field] = vm.previousProfile[field];
            vm.editFlag[field] = false;
        }
        //image upload
        $scope.onUploadFile = onUploadFile;
        $scope.onDropFile = onDropFile;
        $scope.allowDrop = allowDrop;
        vm.fileUploadInput = null;
        function allowDrop(event) {
            event.preventDefault();
            event.stopPropagation();
        }
        function addOverlay() {
            document.querySelector('.fileInput-placeholder').style.zIndex = '1';
        }
        function removeOverlay() {
            document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
        }
        function onDropFile(event) {
            event.preventDefault();
            document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
            let file = event.dataTransfer.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = event => {
                $rootScope.$emit('file.uploaded', { img: event.target.result });
            };
        }
        function onUploadFile(event) {
            event.preventDefault();
            let file = event.currentTarget.files[0];
            vm.fileUploadInput = event.target;
            if (!file)
                return;
            //if open upload window, but click cancel
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = event => {
                $rootScope.$emit('file.uploaded', { img: event.target.result });
            };
        }
        $rootScope.$on('file.approved', (event, data) => {
            let images = data.data.data;
            vm.profile.userAvatarLarge = images[0];
            vm.profile.userAvatarSmall = images[1];
        });
    }
}());
(function () {
    angular.module('app').component('profileSettings', {
        templateUrl: 'profile-settings.tpl.html',
        controller: ProfileSettingsCtrl,
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        },
        $routeConfig: [
            {
                path: '/profile',
                name: 'Profile',
                component: 'profile',
                useAsDefault: true
            },
            {
                path: '/notifications',
                name: 'Notifications',
                component: 'notifications'
            }
        ]
    });
    function ProfileSettingsCtrl($q, profileService) {
        const vm = this;
        vm.profile = null;
        loadProfileInfo();
        ///////////////////////////////////
        function loadProfileInfo() {
            profileService.getProfileInfo().then(profileInfo => {
                vm.profile = profileInfo;
            });
        }
    }
}());
(function () {
    angular.module('app').component('cardItems', {
        controller: cardItems,
        templateUrl: 'card-items.tpl.html',
        bindings: {
            grid: '=?',
            item: '<?',
            save: '=',
            open: '=',
            close: '=',
            cancel: '=',
            delete: '=',
            afterSave: '=',
            afterSaveField: '=',
            overrideParent: '<',
            adoptParent: '<',
            configId: '<',
            isTerm: '<',
            entityType: '<',
            cardActions: '<',
            editorButtons: '<',
            showActivateOptions: '<',
            editInModal: '<',
            fullScreenModal: '<?',
            newItem: '<',
            alwaysShowDescription: '<',
            titlePlaceholder: '@',
            newButtonText: '@',
            newItemText: '@',
            modalClass: '@',
            security: '<',
            loading: '<',
            saveAndCloseOpenCard: '=?',
            beforeOpenCard: '<?'
        },
        controllerAs: 'vm'
    });
    function cardItems($scope, $element, $timeout, $q, messageService, $mdDialog, $document, formService, pendingChangesService) {
        const vm = this;
        vm.selectedIndex = undefined;
        vm.isCardOpen = isCardOpen;
        vm.toggleCard = toggleCard;
        vm.addNewCard = addNewCard;
        vm.cancelCard = cancelCard;
        vm.cancelNewCard = cancelNewCard;
        vm.titleEditable = titleEditable;
        vm.saveAndCloseCard = saveAndCloseCard;
        vm.saveCardIfChanges = saveCardIfChanges;
        vm.saveCard = saveCard;
        vm.activateCard = activateCard;
        vm.deactivateCard = deactivateCard;
        vm.closeCard = closeCard;
        vm.deleteCard = deleteCard;
        vm.isEditable = isEditable;
        vm.isDeletable = isDeletable;
        vm.isDirty = isDirty;
        vm.inEditMode = inEditMode;
        vm.editModalOpen = editModalOpen;
        vm.editCard = editCard;
        vm.fieldName = fieldName;
        function isCardOpen(index) {
            return index === vm.selectedIndex;
        }
        function toggleCard(item, index) {
            if (vm.beforeOpenCard) {
                vm.beforeOpenCard().then(() => hideShowCard(item, index).then(angular.noop, angular.noop)).catch(angular.noop);
            } else {
                hideShowCard(item, index).then(angular.noop, angular.noop);
            }
        }
        function addNewCard() {
            if (vm.newCardShowing)
                return;
            let newCard = _.cloneDeep(vm.newItem);
            newCard.IsNew = true;
            vm.grid.push(newCard);
            let index = vm.grid.length - 1;
            hideShowCard(newCard, index).then(() => {
                $timeout(() => {
                    if (!vm.editInModal) {
                        newCard.data.form.$setDirty();
                    }
                    editCard(newCard);
                });
                vm.newCardShowing = true;
            })    // Remove new card if unable to open it (i.e. an invalid save is in progress on a different open card)
.catch(() => vm.grid.pop());
        }
        function titleEditable(item, $index) {
            return vm.isCardOpen($index) && item.TitleEditable && inEditMode(item);
        }
        function ensureCardOpen(item) {
            return hideShowCard(item, getCardIndex(item), true);
        }
        function hideShowCard(item, index, keepOpen) {
            if (vm.updating)
                return $q.reject();
            if (vm.singleCardMode && !keepOpen) {
                return saveAndCloseCard(item);
            }
            if (vm.selectedIndex !== index) {
                return saveAndSwitchCard(item, index);
            } else if (!keepOpen) {
                return saveAndCloseCard(item);
            }
            return $q.resolve();
        }
        function saveAndSwitchCard(item, index) {
            var openItem = vm.grid[vm.selectedIndex];
            if (openItem) {
                return saveAndCloseCard(openItem, undefined, true).then(() => openCard(item, index)).catch(() => $q.reject());
            } else {
                openCard(item, index);
                return $q.resolve();
            }
        }
        function saveAndCloseCard(item, $event, externalSave) {
            if (inEditMode(item) && isDirty(item)) {
                return saveCard(item, $event, externalSave).then(() => closeCard(item)).catch(() => $q.reject());
            } else {
                closeCard(item);
                return $q.resolve();
            }
        }
        function saveAndCloseOpenCard() {
            if (vm.updating)
                return $q.reject();
            var openItem = vm.grid[vm.selectedIndex];
            if (openItem) {
                return saveAndCloseCard(openItem, undefined, true).catch(() => $q.reject());
            } else {
                return $q.resolve();
            }
        }
        function openCard(item, index) {
            vm.cardBeforeChanges = _.cloneDeep(item);
            vm.selectedIndex = index;
            item.IsEditable = isEditable(item);
            if (!item.data) {
                item.data = {};
            }
            item.saveField = (field, value) => saveField(item, field, value);
            item.saveIfChanges = externalSave => saveCardIfChanges(item, null, externalSave);
            if (inEditMode(item)) {
                addPendingChange(item);
            }
            if (!vm.singleCardMode && !(vm.editInModal && item.IsNew)) {
                scrollToItem(index);
            }
            if (vm.open) {
                vm.open(item);
            }
        }
        function closeCard(item) {
            vm.selectedIndex = undefined;
            vm.newCardShowing = false;
            item.IsNew = false;
            item.InEditMode = false;
            if (vm.close) {
                vm.close(item);
            }
            clearPendingChanges();
        }
        function deleteCard(item, $event) {
            if (vm.delete) {
                let index = getCardIndex(item);
                return $mdDialog.show($mdDialog.confirm().clickOutsideToClose(true).title('Confirm Delete').htmlContent(`Are you sure you want to delete ${ item.Title }?`).targetEvent($event).ok('Delete').cancel('Cancel')).then(() => vm.delete(item).then(r => {
                    if (!vm.singleCardMode) {
                        vm.selectedIndex = undefined;
                        vm.grid.splice(index, 1);
                    }
                    clearPendingChanges();
                }));
            }
        }
        function saveCardIfChanges(item, $event, externalSave) {
            if (inEditMode(item) && isDirty(item)) {
                return saveCard(item, $event, externalSave);
            }
            item.InEditMode = false;
            return $q.resolve();
        }
        function saveCard(item, $event, externalSave) {
            return validateCard(item).then(() => {
                vm.updating = true;
                return vm.save(item).then(() => {
                    item.data.form.$setPristine();
                    item.data.inEditorFormDirty = false;
                    item.InEditMode = false;
                    item.IsNew = false;
                    vm.newCardShowing = false;
                    if (item.data.titleForm) {
                        item.data.titleForm.$setPristine();
                    }
                    if (vm.afterSave) {
                        vm.afterSave(item);
                    }
                }).catch(response => {
                    if (response.data && response.data.error) {
                        return $q.reject(response.data.error);
                    }
                    return $q.reject();
                }).finally(() => {
                    vm.updating = false;
                });
            }).catch(error => {
                if (error) {
                    showCardError(item, error, externalSave);
                }
                return $q.reject();
            });
        }
        function showCardError(item, error, externalSave) {
            let parent = item.data.form.$$element.closest('md-card');
            if (externalSave) {
                $document.scrollToElement(parent, 15, 300);
            }
            messageService.showErrorToast(error, parent, 3000);
        }
        function activateCard(item, $event) {
            return saveActiveOrInactiveOnCard(item, true);
        }
        function deactivateCard(item, $event) {
            return saveActiveOrInactiveOnCard(item, false);
        }
        function saveActiveOrInactiveOnCard(item, active) {
            let clonedItem = _.cloneDeep(vm.cardBeforeChanges);
            clonedItem.IsActive = active;
            return vm.save(clonedItem).then(() => {
                vm.cardBeforeChanges.IsActive = active;
                item.IsActive = active;
            });
        }
        function saveField(item, fieldName, value) {
            let clonedItem = _.cloneDeep(vm.cardBeforeChanges);
            let field = formService.getFieldFromItem(clonedItem, fieldName);
            if (field) {
                field.value = value;
                return vm.save(clonedItem).then(() => {
                    formService.setFieldValueOnItem(item, fieldName, value);
                    formService.setFieldValueOnItem(vm.cardBeforeChanges, fieldName, value);
                    if (vm.afterSaveField) {
                        vm.afterSaveField(item, fieldName, value);
                    }
                });
            }
            return $q.reject();
        }
        function cancelNewCard() {
            vm.selectedIndex = undefined;
            vm.newCardShowing = false;
            vm.grid.pop();
            clearPendingChanges();
        }
        function cancelCard(item) {
            let revertedCard = revertCardFields(item);
            if (!vm.singleCardMode && !vm.editInModal) {
                closeCard(revertedCard);
            } else {
                revertedCard.InEditMode = false;
            }
            if (vm.cancel) {
                vm.cancel(revertedCard);
            }
            clearPendingChanges();
        }
        function revertCardFields(item) {
            _.forEach(item.Fields, field => {
                let cachedField = formService.getFieldFromItem(vm.cardBeforeChanges, field.name);
                if (cachedField) {
                    field.value = cachedField.value;
                    field.displayOptions = _.cloneDeep(cachedField.displayOptions);
                }
            });
            item.Title = vm.cardBeforeChanges.Title;
            return item;
        }
        function validateCard(item) {
            let errors = [];
            if (item.TitleEditable && !item.data.titleForm.$valid) {
                errors.push(`${ vm.titlePlaceholder || 'Title' } is required`);
            }
            if (!item.data.form.$valid) {
                errors = item.formErrors(true);
            }
            if (errors.length) {
                return $q.reject(_.first(errors));
            } else {
                return $q.resolve();
            }
        }
        function isEditable(item) {
            return vm.entityType == 'Org' || !(item.Inherited || item.IsDefault) && (!vm.security || vm.security.EDIT);
        }
        function isDeletable(item) {
            return vm.delete && (!vm.security || vm.security.DELETE);
        }
        function inEditMode(item) {
            return isEditable(item) && (!vm.editInModal && !vm.singleCardMode || item.InEditMode);
        }
        function editModalOpen(item) {
            return inEditMode(item) && vm.editInModal;
        }
        function editCard(item, $event) {
            vm.cardBeforeChanges = _.cloneDeep(item);
            item.InEditMode = true;
            addPendingChange(item);
            if (vm.editInModal) {
                openEditModal(item, $event);
            }
        }
        function openEditModal(item, $event) {
            $mdDialog.show({
                templateUrl: 'card-item-modal.tpl.html',
                // Handled by modal itself to allow intercepting close on server errors
                escapeToClose: false,
                clickOutsideToClose: false,
                targetEvent: $event,
                controller: editModalController,
                controllerAs: 'vm',
                multiple: true,
                locals: {
                    item: item,
                    save: vm.newCardShowing ? saveCard : saveCardIfChanges,
                    cancel: vm.newCardShowing ? cancelNewCard : cancelCard,
                    titlePlaceholder: vm.titlePlaceholder,
                    editorButtons: vm.editorButtons,
                    isNewCard: vm.newCardShowing,
                    fullScreenModal: vm.fullScreenModal,
                    modalClass: vm.modalClass
                },
                onComplete: function (scope) {
                    scope.loaded = true;
                }
            });
        }
        function editModalController($scope, $mdDialog, $timeout, item, save, cancel, editorButtons, titlePlaceholder, isNewCard, fullScreenModal, modalClass) {
            const vm = this;
            vm.item = item;
            vm.save = save;
            vm.cancel = cancel;
            vm.editorButtons = editorButtons;
            vm.titlePlaceholder = titlePlaceholder;
            vm.isNewItem = isNewCard;
            vm.steps = isNewCard;
            vm.fullScreenModal = fullScreenModal;
            vm.modalClass = modalClass;
            vm.item.InPopup = true;
            vm.item.data.$popupScope = $scope;
            vm.closeModal = function () {
                vm.item.InPopup = false;
                $mdDialog.hide();
            };
            vm.saveAndClose = function () {
                return vm.save(vm.item).then(vm.closeModal);
            };
            vm.cancelAndClose = function () {
                vm.cancel(vm.item);
                vm.closeModal();
            };
            vm.nextGroup = function () {
                $scope.$broadcast('nextGroup');
            };
            $scope.$watch('vm.item.data.form.$valid', function (valid) {
                if (!valid && vm.item.data && vm.item.data.form) {
                    $scope.$broadcast('markGroupIncomplete', vm.item.data.form.activeGroup);
                }
            });
        }
        function fieldName(field) {
            return field.name.replace();
        }
        function getCardIndex(item) {
            return vm.grid.findIndex(f => f.Id == item.Id);
        }
        function addPendingChange(item) {
            pendingChangesService.add(() => itemHasPendingChanges(item));
        }
        function clearPendingChanges() {
            pendingChangesService.clear();
        }
        function itemHasPendingChanges(item) {
            return inEditMode(item) && isDirty(item);
        }
        function isDirty(item) {
            if (!item.data.form) {
                return false;
            }
            return item.data.form.$dirty || item.data.inEditorFormDirty || item.TitleEditable && item.data.titleForm && item.data.titleForm.$dirty;
        }
        function scrollToItem(index) {
            $timeout(() => {
                let el = $element.find('.card-item-' + index);
                $document.scrollToElement(el, 15, 300);
            });
        }
        vm.$onInit = function () {
            if (vm.item) {
                vm.grid = [vm.item];
                vm.singleCardMode = true;
                openCard(_.first(vm.grid), 0);
            }
            vm.saveAndCloseOpenCard = saveAndCloseOpenCard;
        };
        $scope.$on('overrideParent', function (event, item) {
            ensureCardOpen(item).finally(() => {
                if (vm.overrideParent) {
                    vm.overrideParent(item).then(() => {
                        item.IsDefault = false;
                        item.Inherited = false;
                        vm.cardBeforeChanges = _.cloneDeep(item);
                    });
                }
            });
        });
        $scope.$on('adoptParent', function (event, item) {
            if (vm.adoptParent) {
                vm.adoptParent(item).then(newItems => {
                    let newItem = newItems.find(f => f.Id == item.Id);
                    let index = getCardIndex(item);
                    vm.grid[index] = newItem;
                    vm.cardBeforeChanges = _.cloneDeep(newItem);
                    if (vm.isCardOpen(index)) {
                        if (vm.open) {
                            vm.open(newItem);
                        }
                    }
                });
            }
        });
    }
}());
(function () {
    angular.module('app').component('customButton', {
        controller: customButtonController,
        templateUrl: 'custom-button.tpl.html',
        transclude: true,
        bindings: {
            button: '<',
            parentItem: '<'
        },
        controllerAs: 'vm'
    });
    function customButtonController(messageService, $timeout, $q) {
        const vm = this;
        vm.buttonAction = function ($event) {
            if (!vm.button.loading) {
                var action = vm.button.action(vm.parentItem, $event);
                if (vm.button.useLoader) {
                    vm.button.loading = true;
                    $q.when(action).then(() => {
                        if (!vm.button.skipComplete) {
                            vm.button.complete = true;
                            $timeout(() => vm.button.complete = false, 2000);
                        }
                    }).catch(error => {
                        if (error) {
                            messageService.showErrorToast(error.msg, error.parent, error.hideDelay);
                        }
                    }).finally(() => vm.button.loading = false);
                }
            }
        };
    }
}());
(function () {
    angular.module('app').component('editableList', {
        controller: EditableList,
        template: `<div class='editable-list list' ng-transclude></div>`,
        transclude: true,
        bindings: {
            form: '<',
            security: '<',
            saveEdit: '<',
            onKeyUp: '<',
            editMode: '<',
            showErrorMessages: '<',
            serverErrors: '='
        },
        controllerAs: 'vm'
    });
    function EditableList($scope) {
        const vm = this;
        vm.saveInProgress = false;
        vm.prevSaveValue = undefined;
        vm.fieldBeingEdited = undefined;
        vm.fieldModelBeingEdited = undefined;
        vm.currentFieldValue = undefined;
        $scope.$on('modalClosed', function (event, saveBeforeClosing, closeModal) {
            tryExitOrSaveCurrentField(event, saveBeforeClosing, closeModal);
        });
        $scope.$on('tabSwitched', function (event, saveBeforeSwitching, switchTab) {
            tryExitOrSaveCurrentField(event, saveBeforeSwitching, switchTab);
        });
        function tryExitOrSaveCurrentField(event, trySave, onSaveComplete) {
            if (vm.editMode && vm.fieldBeingEdited) {
                var $ele = getElForField(vm.fieldBeingEdited);
                var valueChanged = $ele.val() !== vm.currentFieldValue;
                if (trySave && valueChanged) {
                    event.preventDefault();
                    $ele.focus();
                    vm.saveField(vm.fieldBeingEdited, function (saveComplete) {
                        if (saveComplete) {
                            onSaveComplete();
                        } else {
                            highlightErrorField($ele);
                        }
                    });
                } else {
                    vm.exitEditMode();
                }
            }
        }
        vm.$onInit = function () {
            vm.canEdit = vm.editMode && vm.security.EDIT;
        };
        vm.editField = function (field) {
            if (!vm.editMode) {
                getElForField(field).focus();
            }
            if (vm.fieldBeingEdited !== field) {
                if (vm.fieldBeingEdited) {
                    vm.saveField(vm.fieldBeingEdited, saveComplete => {
                        if (saveComplete) {
                            startEditingField(field);
                        } else {
                            var $ele = getElForField(vm.fieldBeingEdited);
                            highlightErrorField($ele);
                        }
                    });
                } else {
                    startEditingField(field);
                }
            }
        };
        function startEditingField(field) {
            vm.fieldBeingEdited = field;
            vm.currentFieldValue = getElForField(field).val();
        }
        vm.saveField = function (field, callback) {
            if (!vm.editMode) {
                return;
            }
            var $ele = getElForField(field);
            var val = $ele.val();
            vm.prevSaveValue = val;
            // Stop saving if there are any local errors
            if ($ele.hasClass('ng-invalid')) {
                callback && callback(false);
                return;
            }
            if (val === vm.currentFieldValue) {
                // No need to save if nothing changed
                doneSaving(field, callback);
            } else {
                saveToServer(field, val, callback);
            }
        };
        function saveToServer(field, val, callback) {
            vm.saveInProgress = true;
            vm.saveEdit(field, val, response => {
                if (response.data.success) {
                    doneSaving(field, callback);
                } else {
                    errorSaving(field, response, callback);
                }
            });
        }
        function doneSaving(field, callback) {
            vm.serverErrors[field.toLowerCase()] = undefined;
            vm.saveInProgress = false;
            vm.exitEditMode();
            callback && callback(true);
        }
        function errorSaving(field, response, callback) {
            var errorMessage = 'There was an error saving, please try again';
            if (response.data && response.data.errors && response.data.errors[0]) {
                errorMessage = response.data.errors[0].error;
            }
            vm.serverErrors[field.toLowerCase()] = errorMessage;
            vm.saveInProgress = false;
            callback && callback(false);
        }
        function highlightErrorField($ele) {
            $ele.focus();
            $ele.closest('.list-item').effect('bounce', {
                direction: 'left',
                distance: '5',
                'times': 3
            }, 400);
        }
        vm.cancelEdit = function () {
            if (vm.fieldBeingEdited) {
                var $ele = getElForField(vm.fieldBeingEdited);
                $ele.val(vm.currentFieldValue).trigger('input');
                $ele.closest('md-dialog').focus();
            }
            vm.exitEditMode();
        };
        vm.exitEditMode = function () {
            if (vm.fieldBeingEdited) {
                vm.serverErrors[vm.fieldBeingEdited.toLowerCase()] = undefined;
            }
            vm.fieldBeingEdited = undefined;
            vm.currentFieldValue = undefined;
            vm.prevSaveValue = undefined;
        };
        vm.onFieldKeyUp = function ($event, field, form) {
            vm.onKeyUp($event, field, form);
            if ($event.target.value != vm.prevSaveValue) {
                vm.serverErrors[field.toLowerCase()] = undefined;
            }
        };
        vm.onFieldKeyDown = function (event, field, form) {
            vm.checkHotKey(event, field, form);
        };
        const ESC = 27, ENTER = 13, TAB = 9;
        vm.checkHotKey = function (event, field, form) {
            let key = event.keyCode;
            if (vm.editMode && vm.fieldBeingEdited) {
                if (key === ESC) {
                    event.preventDefault();
                    event.stopPropagation();
                    vm.cancelEdit();
                }
                if (key === ENTER) {
                    event.preventDefault();
                    event.stopPropagation();
                    vm.saveField(field);
                }
            }
            if (key === TAB) {
                let $ele = getElForField(field);
                if ($ele) {
                    let listItem = $ele.closest('editable-list-item');
                    let siblingEl, field;
                    if (event.shiftKey) {
                        siblingEl = listItem.prev();
                    } else {
                        siblingEl = listItem.next();
                    }
                    if (siblingEl.length) {
                        field = siblingEl.find('md-input-container input').data('field');
                    }
                    if (field) {
                        event.preventDefault();
                        vm.editField(field);
                    }
                }
            }
        };
        function getElForField(field) {
            return angular.element(`[data-field='${ field }']`);
        }
    }
}());
(function () {
    angular.module('app').component('editableListItem', {
        require: { editableListCtrl: '^editableList' },
        controller: EditableListItem,
        templateUrl: 'editable-list-item.tpl.html',
        bindings: {
            ngModel: '=',
            name: '@',
            label: '@',
            placeholder: '@',
            customValidationKey: '@',
            required: '<'
        },
        controllerAs: 'vm'
    });
    function EditableListItem($scope) {
        const vm = this;
        vm.fieldValueBeforeEdit = undefined;
        vm.$onInit = function () {
            vm.isCurrentlyBeingEdited = () => vm.editableListCtrl.fieldBeingEdited === vm.name;
            vm.serverError = () => vm.editableListCtrl.serverErrors[vm.name.toLowerCase()];
            vm.localField = () => vm.editableListCtrl.form[vm.name];
        };
    }
}());
(function () {
    angular.module('app').component('extjsIframe', {
        controller: extjsIframeComponentController,
        controllerAs: 'vm',
        template: `<iframe style="height: 872px; width: 1px !important; min-width: 100% !important" frameborder="0" allowtransparency="true" scrolling = "no" ng-src="{{vm.iframeData.src}}" name="{{vm.iframeData.name}}"></iframe>`,
        bindings: { iframeData: '=' }
    });
    function extjsIframeComponentController() {
        var vm = this;
    }
}());
(function () {
    angular.module('app').component('featherIcon', {
        controller: featherIconController,
        controllerAs: 'vm',
        template: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
        <path d="M4.254,19.567c0.307-0.982,0.77-2.364,1.391-4.362c2.707-0.429,3.827,0.341,5.546-2.729
        \tc-1.395,0.427-3.077-0.792-2.987-1.321c0.091-0.528,3.913,0.381,6.416-3.173c-3.155,0.696-4.164-0.836-3.757-1.067
        \tc0.939-0.534,3.726-0.222,5.212-1.669c0.766-0.745,1.125-2.556,0.813-3.202c-0.374-0.781-2.656-1.946-3.914-1.836
        \tc-1.258,0.109-3.231,4.79-3.817,4.754C8.573,4.925,8.454,2.864,9.476,0.949c-1.077,0.477-3.051,1.959-3.67,3.226
        \tc-1.153,2.357,0.108,7.766-0.296,7.958c-0.405,0.193-1.766-2.481-2.172-3.694c-0.555,1.859-0.568,3.721,1.053,6.194
        \tc-0.611,1.623-0.945,3.491-0.996,4.441C3.371,19.833,4.119,19.996,4.254,19.567z"/>
        </svg>`,
        bindings: { fill: '<' }
    });
    function featherIconController() {
        var vm = this;
    }
}());
(function () {
    angular.module('app').component('dateTimeItem', {
        bindings: {
            dateTime: '=',
            label: '<',
            configId: '<',
            onChange: '<',
            disabled: '<',
            sectionUpdating: '<',
            parentName: '<',
            type: '<',
            inherited: '<',
            isDefault: '<',
            tooltip: '<',
            entityType: '<',
            noLock: '<'
        },
        controllerAs: 'vm',
        controller: dateTimeItemController,
        templateUrl: 'date-time-item.tpl.html'
    });
    function dateTimeItemController(mdcDateTimeDialog) {
        var vm = this;
        vm.originalValue = vm.dateTime;
        vm.hover = false;
        vm.hoverIn = function () {
            vm.hover = true;
        };
        vm.hoverOut = function () {
            vm.hover = false;
        };
        vm.disableInput = function () {
            return vm.sectionUpdating || vm.disabled || vm.inherited || vm.isDefault && vm.entityType != 'Org';
        };
        vm.getDisplayText = function (displayFormat) {
            if (!vm.dateTime) {
                return 'Add Date';
            }
            return new moment(vm.dateTime).format(displayFormat);
        };
        vm.updateDate = function (date) {
            vm.dateTime = new moment(date).format('YYYY-MM-DDTHH:mm:ss');
            doUpdate();
        };
        vm.clearDate = function () {
            vm.dateTime = null;
            doUpdate();
        };
        function doUpdate() {
            vm.onChange(vm.configId, vm.dateTime).then(function (success) {
                if (success) {
                    vm.originalValue = vm.dateTime;
                } else {
                    vm.dateTime = vm.originalValue;
                }
            });
        }
        vm.displayDateDialog = function () {
            mdcDateTimeDialog.show({
                time: false,
                date: true,
                currentDate: vm.dateTime,
                disableParentScroll: true,
                timeZoneHeader: true
            }).then(vm.updateDate, function () {
            });
        };
        vm.displayTimeDialog = function () {
            mdcDateTimeDialog.show({
                time: true,
                date: false,
                shortTime: true,
                currentDate: vm.dateTime,
                disableParentScroll: true,
                timeZoneHeader: true
            }).then(vm.updateDate, function () {
            });
        };
    }
}());
(function () {
    angular.module('app').component('groupItem', {
        bindings: {
            fields: '=',
            label: '<',
            configId: '<',
            onChange: '<',
            sectionUpdating: '<',
            number: '<',
            inherited: '<',
            isDefault: '<',
            parentName: '<',
            disabled: '<',
            tooltip: '<',
            entityType: '<',
            noLock: '<'
        },
        controllerAs: 'vm',
        controller: groupItemController,
        templateUrl: 'group-item.tpl.html'
    });
    function groupItemController(FORM_ITEM_TYPES, $q) {
        var vm = this;
        vm.formItemTypes = FORM_ITEM_TYPES;
        vm.itemEditing = false;
        vm.itemUpdating = false;
        vm.$onInit = function () {
            // Keep a copy of local fields so that changes to a field are only committed when saved
            // If the same object is used then a change to a textarea or text field would immediately update the fields object
            // which would be saved if a different field is saved (e.g. a toggle clicked) even if the field is still in edit mode
            vm.localFields = _.cloneDeep(vm.fields);
        };
        vm.onFieldChange = function (id, value) {
            let oldValue = getFieldValue(vm.localFields, id);
            setFieldValue(vm.localFields, id, value);
            return vm.onSave().then(success => {
                if (!success) {
                    setFieldValue(vm.localFields, id, oldValue);
                }
                return success;
            });
        };
        function getFieldValue(fields, id) {
            let field = fields[id];
            if (!field)
                return;
            return field[getValuePropertyName(field)];
        }
        function setFieldValue(fields, id, value) {
            let field = fields[id];
            if (!field)
                return;
            field[getValuePropertyName(field)] = value;
        }
        function getValuePropertyName(field) {
            if (field.Type === FORM_ITEM_TYPES.TOGGLE) {
                return 'On';
            }
            return 'Value';
        }
        vm.onSave = function () {
            if (vm.inherited || vm.disabled || vm.itemUpdating) {
                vm.itemEditing = false;
                return $q.resolve(false);
            }
            vm.itemUpdating = true;
            return vm.onChange(vm.configId, vm.localFields, false, true).then(function (success) {
                vm.itemUpdating = false;
                vm.itemEditing = false;
                return success;
            });
        };
    }
}());
(function () {
    angular.module('app').component('orderableToggles', {
        controllerAs: 'vm',
        controller: orderableTogglesController,
        templateUrl: 'orderable-toggles.tpl.html',
        bindings: {
            fields: '<',
            activeOptions: '=',
            selector: '<',
            onChange: '<',
            sectionUpdating: '<',
            configId: '<',
            type: '<',
            inherited: '<',
            disabled: '<',
            tooltip: '<',
            parentName: '<',
            label: '<',
            description: '<',
            isDefault: '<',
            refresh: '=',
            entityType: '<'
        }
    });
    function orderableTogglesController($scope, $timeout, entityService) {
        const vm = this;
        vm.refresh = true;
        $timeout(setSortableState);
        $scope.$watch('vm.inherited', setSortableState);
        function setSortableState() {
            var method = vm.inherited || vm.entityType != 'Org' && vm.isDefault ? 'disable' : 'enable';
            vm.applyToSortable(method);
        }
        vm.isOn = function (optionId) {
            return vm.activeOptions.includes(optionId);
        };
        vm.HQ_TASKS = {
            None: 0,
            CreateChampionCampaign: 1,
            UploadPhoto: 4,
            ImportYourContacts: 8,
            SendFundraisingEmail: 32,
            PostSocial: 64,
            UpdateEnduranceGoal: 101,
            LogEnduranceActivity: 102,
            ConnectEnduranceIntegration: 103,
            RSVP: 128,
            TeamBuilder: 256,
            UpdateAccountDetails: 512,
            PersonalizeMyPage: 1024,
            UpdateGoal: 2048,
            CreateCustomURL: 4096,
            DonateOwnGoal: 131072,
            EnterOfflineDonation: 536870912,
            AddLivestream: 1073741824,
            ConnectFacebookFundraiser: 104,
            EnableStreamlabs: 105,
            FundraiserPdf: 107
        };
        vm.STARTUP_ITEMS = {
            UploadPhoto: 4,
            RSVP: 128,
            TeamBuilder: 256,
            UpdateAccountDetails: 512,
            UpdateGoal: 2048,
            CreateCustomURL: 4096,
            DonateToOwnGoal: 131072,
            ConnectFacebookFundraiser: 104
        };
        function removeItem(id) {
            var index = vm.fields.findIndex(x => x.Value == id);
            if (index > -1) {
                vm.fields.splice(index, 1);
            }
        }
        function setActiveFields() {
            let entity = entityService.getEntityWithSettings(entityService.getOpenedEntityId());
            //Updating? Update ConfigFileTemplateHelper.AllowedSelectionItems too!
            if (entity.entityType == 'Campaign') {
                if (vm.configId == 252) {
                    if (!entity.settings.ChampionEnabled) {
                        removeItem(vm.HQ_TASKS.CreateChampionCampaign);
                    }
                    if (!entity.settings.HasEnduranceChallenge) {
                        removeItem(vm.HQ_TASKS.UpdateEnduranceGoal);
                        removeItem(vm.HQ_TASKS.LogEnduranceActivity);
                        removeItem(vm.HQ_TASKS.ConnectEnduranceIntegration);
                    }
                    if (!entity.settings.LivestreamEnabled) {
                        removeItem(vm.HQ_TASKS.AddLivestream);
                    }
                    if (!entity.settings.FacebookFundraiserEnabled) {
                        removeItem(vm.HQ_TASKS.ConnectFacebookFundraiser);
                    }
                    if (!entity.settings.FundraiserCanConnectStreamlabs || !entity.settings.LivestreamEnabled) {
                        removeItem(vm.HQ_TASKS.EnableStreamlabs);
                    }
                    if (!entity.settings.HasFundraiserPdfEnabled) {
                        removeItem(vm.HQ_TASKS.FundraiserPdf);
                    }
                }
                if (vm.configId == 250) {
                    //startupGuide
                    if (entity.settings.HasRoleRegistration) {
                        removeItem(vm.STARTUP_ITEMS.RSVP);    //RegisterFriends aka RSVP
                    }
                    if (!entity.settings.FacebookFundraiserEnabled) {
                        removeItem(vm.STARTUP_ITEMS.ConnectFacebookFundraiser);
                    }
                }
            }
            vm.fields.forEach(field => {
                field.Value = parseInt(field.Value);
                if (vm.isOn(field.Value)) {
                    field.on = true;
                }
            });
        }
        vm.getOptions = function () {
            if (!vm.refresh) {
                return vm.fields;
            }
            setActiveFields();
            var sorted = vm.fields.sort((a, b) => {
                var aIndex = vm.activeOptions.indexOf(a.Value);
                var bIndex = vm.activeOptions.indexOf(b.Value);
                var returnVal;
                if (vm.isOn(a.Value) && vm.isOn(b.Value)) {
                    //both are set
                    returnVal = aIndex - bIndex;
                } else if (bIndex < 0 && aIndex < 0) {
                    //neither are set - order by optionID
                    returnVal = a.value - b.value;
                } else {
                    returnVal = bIndex - aIndex;
                }
                return returnVal;
            });
            return sorted;
        };
        vm.applyToSortable = function (method) {
            return angular.element(`#${ vm.selector }`).sortable(method);
        };
        vm.getAllItems = function () {
            var sortablesIds = vm.applyToSortable('toArray');
            var orderedIdStrings = sortablesIds.map(x => x.substring(vm.selector.length));
            return orderedIdStrings.map(x => parseInt(x));
        };
        vm.sortableOptions = {
            update: function (event, ui) {
                if (vm.disabled || vm.inherited)
                    return;
                vm.refresh = false;
                var oldSetting = vm.activeOptions.slice();
                vm.activeOptions = vm.getAllItems().filter(x => vm.isOn(x));
                var changed = oldSetting.length !== vm.activeOptions.length || oldSetting.some((value, index) => value !== vm.activeOptions[index]);
                if (changed) {
                    vm.doUpdateOptions().then(function (success) {
                        if (!success) {
                            vm.activeOptions = oldSetting;
                            vm.applyToSortable('cancel');
                        }
                    });
                }
            },
            axis: 'y'
        };
        vm.updateSelected = function (optionId, toggleOn) {
            vm.refresh = false;
            var oldSetting = vm.activeOptions.slice();
            if (toggleOn && !vm.isOn(optionId)) {
                vm.activeOptions.push(optionId);
            } else {
                vm.activeOptions.splice(vm.activeOptions.indexOf(optionId), 1);
            }
            vm.activeOptions = vm.getAllItems().filter(x => vm.isOn(x));
            return vm.doUpdateOptions().then(success => {
                if (!success) {
                    vm.activeOptions = oldSetting;
                }
                return success;
            });
        };
        vm.doUpdateOptions = function () {
            vm.applyToSortable('disable');
            return vm.onChange(vm.configId, vm.activeOptions.join(',')).then(function (success) {
                vm.applyToSortable('enable');
                return success;
            });
        };
    }
}());
(function () {
    angular.module('app').component('selectItem', {
        bindings: {
            options: '<',
            multiple: '<',
            requireSelection: '<',
            label: '<',
            configId: '<',
            onChange: '<',
            sectionUpdating: '<',
            inherited: '<',
            isDefault: '<',
            parentName: '<',
            disabled: '<',
            tooltip: '<',
            entityType: '<',
            noLock: '<'
        },
        controllerAs: 'vm',
        controller: selectItemController,
        templateUrl: 'select-item.tpl.html'
    });
    function selectItemController() {
        var vm = this;
        vm.OPTION_REQUIRED_TOOLTIP = 'At least one option must be selected.';
        vm.opened = false;
        vm.crmOptionTooltip = false;
        vm.selected = function (val) {
            if (angular.isDefined(val)) {
                vm._oldValue = vm._selected;
                vm._selected = val;
            } else if (!vm._selected) {
                if (vm.multiple) {
                    vm._selected = vm.options.filter(x => x.Selected);
                } else {
                    vm._selected = vm.options.find(x => x.Selected);
                }
            }
            return vm._selected;
        };
        vm.setOpened = function () {
            //workaround to not send initial setting as update
            vm.opened = true;
        };
        vm.lastOptionRequired = function (option) {
            var selected = vm.selected();
            return vm.requireSelection && selected.length == 1 && selected[0] == option;
        };
        vm.onSelected = function () {
            if (!vm.opened)
                return;
            var val = '';
            if (vm.multiple) {
                val = vm._selected.map(x => x.Value).join('|');
            } else {
                val = vm._selected.Value;
            }
            vm.onChange(vm.configId, val).then(function (success) {
                if (!success && vm._oldValue) {
                    vm._selected = vm._oldValue;
                }
                return success;
            });
        };
    }
}());
(function () {
    angular.module('app').component('textItem', {
        bindings: {
            value: '=',
            label: '<',
            configId: '<',
            onChange: '<',
            sectionUpdating: '<',
            number: '<',
            inherited: '<',
            isDefault: '<',
            parentName: '<',
            disabled: '<',
            tooltip: '<',
            entityType: '<',
            noLock: '<'
        },
        controllerAs: 'vm',
        controller: textItemController,
        templateUrl: 'text-item.tpl.html'
    });
    function textItemController() {
        var vm = this;
        vm.itemEditing = false;
        vm.itemUpdating = false;
        vm.onSave = function () {
            var unchanged = vm.originalValue == vm.value;
            if (vm.inherited || vm.disabled || vm.itemUpdating || unchanged) {
                vm.itemEditing = false;
                return;
            }
            vm.itemUpdating = true;
            vm.onChange(vm.configId, vm.value).then(function (success) {
                if (!success && vm.originalValue) {
                    vm.value = vm.originalValue;
                } else {
                    vm.originalValue = vm.value;
                }
                vm.itemUpdating = false;
                vm.itemEditing = false;
                return success;
            });
        };
        vm.cancelEdit = function () {
            vm.itemEditing = false;
            vm.value = vm.originalValue;
        };
        const ESC = 27, ENTER = 13;
        vm.checkHotKey = function (event) {
            var key = event.keyCode;
            if (key === ESC) {
                vm.cancelEdit();
            } else if (key === ENTER) {
                event.preventDefault();
                event.stopPropagation();
                vm.onSave();
            }
        };
    }
}());
(function () {
    angular.module('app').component('textareaItem', {
        bindings: {
            value: '=',
            label: '<',
            configId: '<',
            onChange: '<',
            sectionUpdating: '<',
            number: '<',
            inherited: '<',
            isDefault: '<',
            parentName: '<',
            disabled: '<',
            tooltip: '<',
            entityType: '<',
            noLock: '<',
            useEditor: '<',
            advancedEditing: '<'
        },
        controllerAs: 'vm',
        controller: textareaItemController,
        templateUrl: 'textarea-item.tpl.html'
    });
    function textareaItemController(ckEditorService, cookieService, $mdDialog, $timeout) {
        var vm = this;
        vm.editorProcessing = false;
        vm.itemEditing = false;
        vm.itemUpdating = false;
        vm.editorId = 'editor' + Math.random().toString(16).slice(2);
        vm.$onInit = function () {
            if (!vm.advancedEditing) {
                vm.advancedEditing = htmlIsInvalid(vm.value);
            }
        };
        vm.onSave = function () {
            vm.value = ckEditorService.getEditorValue(vm.editorId, vm.value);
            var unchanged = vm.originalValue == vm.value;
            if (vm.inherited || vm.disabled || vm.itemUpdating || unchanged) {
                vm.itemEditing = false;
                return;
            }
            vm.itemUpdating = true;
            vm.onChange(vm.configId, vm.value, vm.advancedEditing).then(function (success) {
                if (success) {
                    vm.originalValue = vm.value;
                    vm.itemEditing = false;
                }
                vm.itemUpdating = false;
                return success;
            });
        };
        vm.cancelEdit = function () {
            vm.itemEditing = false;
            vm.value = vm.originalValue;
        };
        function htmlIsInvalid(val) {
            if (!val)
                return;
            // To improve accuracy do some replacements that are still valid html that we expect the innerHTML to make
            var initialVal = val.toString().replace(/\s\s+/gi, ' ').replace(/\s*\/>/gi, '>');
            var doc = document.createElement('div');
            doc.innerHTML = initialVal;
            return doc.innerHTML !== initialVal;
        }
        function confirmSwitchToWysiwyg() {
            return $mdDialog.confirm().title('Are you sure?').textContent(`This will switch the editor to WYSIWYG mode. All HTML content (for example, open tags) will be sanitized and validated. This may affect your email design.`).ok('Confirm').cancel('Cancel');
        }
        vm.beforeEditorSetMode = function (evnt) {
            if (evnt.data === 'source') {
                if (!vm.itemEditing && !vm.advancedEditing) {
                    vm.editorProcessing = true;
                    $timeout(() => {
                        evnt.editor.destroy();
                        vm.editSource(false);
                    }, 300);    // Magic number ensures that the CodeMirror plugin can fully run the switch to source before destroying the instance as there is no way to cancel it and destroying the instance before it is done running will error
                }
                vm.advancedEditing = true;
            }
            if (evnt.data === 'wysiwyg') {
                let currentValue = evnt.editor.getData();
                if (vm.advancedEditing && htmlIsInvalid(currentValue)) {
                    $mdDialog.show(confirmSwitchToWysiwyg()).then(() => vm.advancedEditing = false, () => {
                        // Hack to revert editor to invalid html code
                        //   setData() on an editor validates the html code of the data
                        //   However a new editor instance can be created with invalid code already set
                        //   Therefore destroy editor (ng-if on editor textarea) and re-initialize editor 
                        //   after reverting the value
                        vm.destroyEditor = true;
                        // Set the value and "un-destroy" in timeout to run in the next digest cycle
                        $timeout(() => {
                            vm.value = currentValue;
                            vm.destroyEditor = false;
                        });
                    });
                } else {
                    vm.advancedEditing = false;
                }
            }
        };
        vm.editSource = function (editing = true) {
            vm.advancedEditing = true;
            vm.destroyEditor = true;
            vm.value = vm.originalValue;
            vm.editorProcessing = true;
            $timeout(() => {
                vm.destroyEditor = false;
                vm.itemEditing = editing;
                vm.editorProcessing = false;
            });
        };
        const ESC = 27;
        vm.checkHotKey = function (event) {
            var key = event.keyCode;
            if (key === ESC) {
                vm.cancelEdit();
            }
        };
    }
}());
(function () {
    angular.module('app').component('toggleItem', {
        bindings: {
            toggled: '<',
            type: '<',
            label: '<',
            configId: '<',
            onChange: '<',
            sectionUpdating: '<',
            inherited: '<',
            isDefault: '<',
            parentName: '<',
            disabled: '<',
            tooltip: '<',
            draggable: '<',
            entityType: '<',
            noLock: '<',
            processorInstanceId: '<'
        },
        controllerAs: 'vm',
        controller: toggleController,
        templateUrl: 'toggle-item.tpl.html'
    });
    function toggleController() {
        var vm = this;
        vm.onToggle = function () {
            vm.onChange(vm.configId, vm.toggled, false, false, vm.processorInstanceId).then(r => {
                if (!r) {
                    vm.toggled = !vm.toggled;
                }
                return r;
            });
        };
    }
}());
(function () {
    angular.module('app').component('formSection', {
        controller: formSectionController,
        controllerAs: 'vm',
        templateUrl: 'form-section.tpl.html',
        bindings: {
            section: '<',
            onUpdate: '<',
            filter: '<',
            entityType: '<',
            noLock: '<'
        }
    });
    function formSectionController($scope, configSettingsService, FORM_ITEM_TYPES) {
        var vm = this;
        vm.sectionUpdating = false;
        vm.formItemTypes = FORM_ITEM_TYPES;
        vm.sectionFilter = (value, index, array) => true;
        vm.$onInit = function () {
            if (vm.filter) {
                vm.sectionFilter = vm.filter;
            }
            var fields = vm.section.Fields;
            if (fields.length == 1 && fields[0].Type == vm.formItemTypes.ORDERABLE) {
                vm.orderableSection = fields[0];
                vm.orderableSection.refresh = true;
                vm.isOrderableSection = true;
            }
        };
        $scope.$on('adoptParent', function (event, configId) {
            vm.sectionUpdating = true;
            configSettingsService.adoptParent(configId).then(function (r) {
                if (r.success && r.setting) {
                    refreshSetting(r.setting);
                }
                vm.sectionUpdating = false;
                return r.success;
            });
        });
        $scope.$on('overrideParent', function (event, configId) {
            vm.sectionUpdating = true;
            configSettingsService.overrideParent(configId).then(function (r) {
                if (r.success && r.setting) {
                    refreshSetting(r.setting);
                }
                vm.sectionUpdating = false;
                return r.success;
            });
        });
        $scope.$on('clearOverrides', function (event, configId) {
            vm.sectionUpdating = true;
            configSettingsService.clearOverrides(configId).then(function (r) {
                if (r.success && r.setting) {
                    refreshSetting(r.setting);
                }
                vm.sectionUpdating = false;
                return r.success;
            });
        });
        function refreshSetting(setting, advancedEditing) {
            setting.AdvancedEditing = advancedEditing;
            var index = vm.section.Fields.findIndex(x => x.SettingId == setting.SettingId);
            if (index > -1) {
                if (vm.isOrderableSection) {
                    vm.orderableSection = setting;
                    vm.orderableSection.refresh = true;
                } else {
                    vm.section.Fields[index] = setting;
                }
            }
        }
        vm.onChange = function (id, value, advancedEditing, suppressRefreshSetting, processorInstanceId) {
            if (vm.sectionUpdating) {
                var promise = new $.Deferred();
                promise.reject();
                return promise;
            }
            vm.sectionUpdating = true;
            var data = {
                configId: id,
                value: value,
                processorInstanceId: processorInstanceId
            };
            return vm.onUpdate(data).then(function (r) {
                vm.sectionUpdating = false;
                if (r.setting && !suppressRefreshSetting) {
                    refreshSetting(r.setting, advancedEditing);
                }
                return true;
            }).catch(function (r) {
                vm.sectionUpdating = false;
                return false;
            });
        };
    }
}());
(function () {
    angular.module('app').component('formSingle', {
        templateUrl: 'form-single.tpl.html',
        controllerAs: 'vm',
        controller: formSingleController,
        bindings: {
            fields: '=',
            form: '=',
            id: '<',
            item: '<',
            editable: '<',
            editorButtons: '<',
            isNewItem: '<',
            modalActions: '=?',
            onChange: '<',
            steps: '<',
            inModal: '<',
            errors: '=',
            totalFormGroups: '=?',
            validateActiveGroup: '=?'
        }
    });
    function formSingleController($scope, $mdUtil, messageService, formService, $element) {
        const vm = this;
        vm.initialized = false;
        vm.groupModalActions = [];
        $scope.$on('nextGroup', function (e) {
            if (validateActiveGroup()) {
                nextGroup();
            } else {
                markActiveGroupIncomplete();
            }
        });
        $scope.$on('markGroupIncomplete', function (e, groupIndex) {
            markGroupIncomplete(groupIndex);
        });
        function markActiveGroupIncomplete(groupIndex) {
            markGroupIncomplete(vm.form.activeGroup);
        }
        function markGroupIncomplete(groupIndex) {
            vm.formGroups[groupIndex].completed = false;
        }
        function validateActiveGroup(validateSilently) {
            let errors = vm.activeGroupErrors(true);
            if (errors.length) {
                if (!validateSilently) {
                    messageService.showErrorToast(_.first(errors), $element, 2000);
                }
                return false;
            } else {
                return true;
            }
        }
        function nextGroup() {
            vm.formGroups[vm.form.activeGroup].completed = true;
            // Run in next tick to ensure digest has enabled tab before trying to switch to it
            $mdUtil.nextTick(function () {
                vm.form.activeGroup++;
            });
        }
        vm.fieldContainerClass = function (field) {
            return `field-container-type-${ field.fieldType } field-container-name-${ _.kebabCase(field.name) }`;
        };
        vm.formGroupContainerClass = function (formGroup) {
            return `form-single-inner form-single-inner-height form-tab-container-${ _.kebabCase(formGroup.name) }`;
        };
        // These directive require a string of 'false' in order be turned off
        vm.dynamicHeight = () => vm.inModal ? 'false' : true;
        vm.centerTabs = () => !vm.steps ? 'false' : true;
        vm.tabEnabled = function ($index) {
            // Always enable first tab
            if ($index === 0)
                return true;
            // Enabled if completed
            let thisTab = vm.formGroups[$index];
            if (thisTab.completed)
                return true;
            // Enabled if previous tab completed
            let previousTab = vm.formGroups[$index - 1];
            return previousTab.completed;
        };
        vm.onTabSelect = function ($index) {
            // Unload and reload fields in next tick to allow components that want to reload completely on their tab being shown to do so
            _.forEach(vm.fields, field => {
                field.unloaded = true;
            });
            $mdUtil.nextTick(function () {
                vm.modalActions = vm.groupModalActions[$index];
                _.forEach(vm.fields, field => {
                    field.unloaded = false;
                });
            });
        };
        vm.onTabDeselect = function ($index) {
            // Copy any ckeditor field to the item so source changes are not lost
            _.forEach(vm.fields, field => {
                if (field.fieldType === 'Textarea' && CKEDITOR.instances[field.name + '-' + vm.id] && CKEDITOR.instances[field.name + '-' + vm.id].mode === 'source') {
                    field.value = CKEDITOR.instances[field.name + '-' + vm.id].getData();
                }
                field.displayError = undefined;
            });
        };
        vm.fieldsForTab = function (formGroup) {
            return (field, index, fields) => {
                return !field.displayOptions.inEditor && field.displayOptions.formGroup == formGroup.name;
            };
        };
        vm.getFormForGroup = function (formGroup) {
            return vm.form['form-' + formGroup];
        };
        function getErrorMessage(errorGroupKey, field) {
            let fieldName = field.$$attr.validationName || field.$name;
            switch (errorGroupKey) {
            case 'required':
                return `${ fieldName } is required`;
            default:
                return `${ fieldName } is invalid`;
            }
        }
        function getActiveFormGroupErrors(setErrorOnField) {
            return getErrorsForForm(vm.getFormForGroup(vm.form.activeGroup), setErrorOnField);
        }
        function getErrors(setErrorOnField) {
            return getErrorsForForm(vm.form, setErrorOnField);
        }
        function getErrorsForForm(form, setErrorOnField) {
            let errors = [];
            _.forEach(form.$error, (errorGroup, errorGroupKey) => {
                _.forEach(errorGroup, error => {
                    if (error.$$attr) {
                        let msg = getErrorMessage(errorGroupKey, error);
                        errors.push(msg);
                        if (setErrorOnField) {
                            let fieldPath = JSON.parse(error.$$attr.fieldPath);
                            let formField = formService.getFieldFromItem(vm.item, fieldPath);
                            if (formField) {
                                formField.displayError = msg;
                            }
                        }
                    } else {
                        errors = errors.concat(getErrorsForForm(error, setErrorOnField));
                    }
                });
            });
            return errors;
        }
        function getFormGroups() {
            let formGroups = {};
            _.forEach(vm.fields, field => {
                if (field.displayOptions.hidden || field.hide && field.hide(vm.item) || field.displayOptions.inEditor) {
                    return;
                }
                let formGroup = field.displayOptions.formGroup;
                if (!formGroups[formGroup]) {
                    formGroups[formGroup] = {
                        name: formGroup,
                        completed: !vm.isNewItem
                    };
                }
            });
            return _.values(formGroups);
        }
        vm.$onInit = function () {
            if (!vm.form)
                vm.form = {};
            vm.form.activeGroup = 0;
            vm.formGroups = getFormGroups();
            vm.totalFormGroups = _.size(vm.formGroups);
            vm.errors = getErrors;
            vm.activeGroupErrors = getActiveFormGroupErrors;
            vm.initialized = true;
            vm.validateActiveGroup = validateActiveGroup;
        };
    }
}());
(function () {
    angular.module('app').component('formSingleField', {
        templateUrl: 'form-single-field.tpl.html',
        controllerAs: 'vm',
        controller: formSingleFieldController,
        bindings: {
            field: '<',
            id: '<',
            item: '<',
            editorButtons: '<',
            editable: '<',
            groupIndex: '<',
            groupForm: '<',
            parentFieldPath: '<',
            modalActions: '=?',
            onChange: '<'
        }
    });
    function formSingleFieldController($q, formService) {
        const vm = this;
        vm.initComplete = false;
        vm.isFieldVisible = function () {
            return !vm.field.displayOptions.hidden && !vm.fieldHide() && !vm.fieldConditionShouldHide();
        };
        vm.fieldHide = function () {
            return vm.field.hide && vm.field.hide(vm.item);
        };
        vm.fieldEditable = function () {
            return !vm.field.isReadonly && vm.editable;
        };
        vm.fieldAppend = function () {
            return vm.field.displayOptions.labels.append || vm.fieldLabelAppendCondition();
        };
        vm.fieldPrepend = function () {
            return vm.field.displayOptions.labels.prepend || vm.fieldLabelPrependCondition();
        };
        vm.fieldDisplayName = function () {
            return vm.field.displayOptions.labels.alternative || vm.field.name;
        };
        vm.fieldValidationName = function () {
            return vm.field.displayOptions.labels.validation || vm.fieldDisplayName();
        };
        vm.fieldClass = function () {
            return `field-type-${ vm.field.fieldType } field-name-${ _.kebabCase(vm.field.name) }`;
        };
        vm.fieldContainerClass = function (field) {
            return `field-container-type-${ field.fieldType } field-container-name-${ _.kebabCase(field.name) }`;
        };
        vm.formGroupContainerClass = function (formGroup) {
            return `form-single-inner form-tab-container-${ _.kebabCase(formGroup.name) }`;
        };
        vm.fieldRequired = function () {
            return vm.field.required;
        };
        vm.filteredOptions = function (options, searchText, groupName) {
            if (searchText === undefined || searchText.trim() === '') {
                return options;
            }
            searchText = searchText.trim().toLowerCase();
            if (groupName && matchesSearchText(groupName, searchText)) {
                return options;
            }
            return _.pickBy(options, (option, key) => matchesSearchText(option, searchText));
        };
        vm.filteredGroupOptions = function (groups, searchText) {
            if (searchText === undefined || searchText.trim() === '') {
                return groups;
            }
            searchText = searchText.trim().toLowerCase();
            return _.filter(groups, group => {
                if (matchesSearchText(group.name, searchText)) {
                    return group;
                }
                var matching = _.filter(group.options, option => matchesSearchText(option, searchText));
                return Object.keys(matching).length > 0;
            });
        };
        function matchesSearchText(text, search) {
            return text.toLowerCase().search(search) !== -1;
        }
        vm.fieldLabelPrependCondition = function (item) {
            if (!item) {
                item = vm.item;
            }
            if (!vm.field || !vm.field.displayOptions || !vm.field.displayOptions.labels || !vm.field.displayOptions.labels.prependConditions) {
                return '';
            }
            let prependItem = vm.field.displayOptions.labels.prependConditions.find(condition => {
                let field = formService.getFieldOrSubFieldFromItem(item, condition.field, condition.subField);
                if (!field) {
                    return false;
                }
                let valuesMatch = vm.conditionValuesMatch(condition, field);
                return condition.not ? !valuesMatch : valuesMatch;
            });
            if (!prependItem)
                return '';
            return prependItem.text;
        };
        vm.fieldLabelAppendCondition = function (item) {
            if (!item) {
                item = vm.item;
            }
            if (!vm.field || !vm.field.displayOptions || !vm.field.displayOptions.labels || !vm.field.displayOptions.labels.appendConditions) {
                return '';
            }
            let appendItem = vm.field.displayOptions.labels.appendConditions.find(condition => {
                let field = formService.getFieldOrSubFieldFromItem(item, condition.field, condition.subField);
                if (!field) {
                    return false;
                }
                let valuesMatch = vm.conditionValuesMatch(condition, field);
                return condition.not ? !valuesMatch : valuesMatch;
            });
            if (!appendItem)
                return '';
            return appendItem.text;
        };
        vm.fieldConditionShouldHide = function (item) {
            if (!item) {
                item = vm.item;
            }
            if (!vm.field || !vm.field.displayOptions || !vm.field.displayOptions.conditions)
                return false;
            let shouldShow = conditionCheckPredicate(vm.field.displayOptions.anyConditionMatches)(vm.field.displayOptions.conditions, condition => {
                let field = formService.getFieldOrSubFieldFromItem(item, condition.field, condition.subField);
                if (!field) {
                    return true;
                }
                if (field.fieldConditionShouldHide && field.fieldConditionShouldHide()) {
                    return false;
                }
                let valuesMatch = vm.conditionValuesMatch(condition, field);
                return condition.not ? !valuesMatch : valuesMatch;
            });
            return !shouldShow;
        };
        function conditionCheckPredicate(anyConditionMatches) {
            return anyConditionMatches ? _.some : _.every;
        }
        vm.conditionValuesMatch = function (condition, field) {
            if (Array.isArray(field.value)) {
                return field.value.includes(condition.value);
            }
            if (field.fieldType == 'Checkbox') {
                return !!field.value;
            }
            return condition.value == field.value;
        };
        vm.selectedOptionMetadata = function () {
            if (!vm.field || !vm.field.optionsMetadata || !vm.field.value)
                return {};
            return vm.field.optionsMetadata[vm.field.value];
        };
        vm.clearValue = function () {
            vm.previousValue = '';
            vm.field.value = '';
        };
        vm.addChangeCallback = function (callback) {
            if (!callback || typeof callback !== 'function') {
                return;
            }
            if (!vm.field.changeCallbacks) {
                vm.field.changeCallbacks = [];
            }
            vm.field.changeCallbacks.push(callback);
        };
        vm.fieldPath = function () {
            let path = vm.parentFieldPath && vm.parentFieldPath() || [];
            path.push(vm.field.name);
            return path;
        };
        vm.textareaName = function () {
            return vm.field.name + '-' + vm.id;
        };
        vm.fieldChange = function (fromPrefill) {
            let fieldName = vm.field.fieldType == 'Textarea' ? vm.textareaName() : vm.fieldValidationName();
            let formField = vm.groupForm && vm.groupForm[fieldName];
            if (formField && formField.$valid) {
                vm.field.displayError = undefined;
            }
            if (vm.field.changeCallbacks) {
                _.forEach(vm.field.changeCallbacks, callback => callback(vm.item, vm.field.value, vm.field, vm.previousValue, fromPrefill));
            }
            // Storing previous value so that it can be sent along with the next change event
            vm.previousValue = vm.field.value;
        };
        vm.addPrefillOptions = function (prefillData, fieldsToUpdate, showOverrideWarning, findItemFunc) {
            function fieldValueChanged(fieldName, currentItemFields, prefillItemFieldValues) {
                return currentItemFields[fieldName] && currentItemFields[fieldName].value && currentItemFields[fieldName].value != prefillItemFieldValues[fieldName];
            }
            function overrideWarning(isOverriding) {
                if (isOverriding && showOverrideWarning) {
                    return showOverrideWarning();
                }
                return $q.resolve();
            }
            // When any of the fields updated by this prefill are changed, clear the prefill field selection
            _.forEach(fieldsToUpdate, fieldName => {
                let field = formService.getFieldFromItem(vm.item, fieldName);
                if (field && field.addChangeCallback) {
                    field.addChangeCallback((item, value, field, previousValue, fromPrefill) => {
                        if (!fromPrefill)
                            vm.field.clearValue();
                    });
                }
            });
            vm.field.addChangeCallback((item, value, field, previousValue, fromPrefill) => {
                if (fromPrefill)
                    return;
                let prefillItem = findItemFunc(prefillData, value);
                if (!prefillItem)
                    return;
                let currentItemFields = {};
                let prefillItemFieldValues = {};
                let isOverriding = false;
                _.forEach(fieldsToUpdate, fieldName => {
                    prefillItemFieldValues[fieldName] = formService.getFieldValueFromItem(prefillItem, fieldName);
                    let currentField = currentItemFields[fieldName] = formService.getFieldFromItem(vm.item, fieldName);
                    if (!currentField) {
                        return;
                    }
                    currentItemFields[fieldName] = currentField;
                    let isBoolField = currentField.fieldType === 'Checkbox';
                    if (!previousValue && !isBoolField && fieldValueChanged(fieldName, currentItemFields, prefillItemFieldValues)) {
                        isOverriding = true;
                    }
                });
                overrideWarning(isOverriding).then(() => {
                    _.forEach(fieldsToUpdate, fieldName => {
                        let field = currentItemFields[fieldName];
                        let value = prefillItemFieldValues[fieldName];
                        if (field && !field.isReadonly) {
                            field.value = value;
                            if (field.fieldChange) {
                                field.fieldChange(true);
                            }
                        }
                    });
                }, () => {
                    vm.field.clearValue();
                });
            });
        };
        function addDefaultChangeCallbacks() {
            if (vm.field.DefaultCallbacksAdded)
                return;
            // Field specific change callback
            vm.field.addChangeCallback(vm.field.change);
            // Form change callback (when any field on entire form is changed)
            vm.field.addChangeCallback(vm.onChange);
            vm.field.DefaultCallbacksAdded = true;
        }
        vm.$onInit = function () {
            if (vm.field.fieldType === 'DateTime' && vm.field.value) {
                vm.field.value = new Date(vm.field.value);
                vm.field.value.setSeconds(0, 0);
            }
            vm.previousValue = vm.field.value;
            // Attach functions to field so that they can be called externally
            vm.field.clearValue = vm.clearValue;
            vm.field.addPrefillOptions = vm.addPrefillOptions;
            vm.field.addChangeCallback = vm.addChangeCallback;
            vm.field.fieldChange = vm.fieldChange;
            vm.field.fieldConditionShouldHide = vm.fieldConditionShouldHide;
            vm.field.fieldLabelAppendCondition = vm.fieldLabelAppendCondition;
            vm.field.fieldLabelPrependCondition = vm.fieldLabelPrependCondition;
            vm.field.isVisible = vm.isFieldVisible;
            vm.field.fieldAppend = vm.fieldAppend;
            vm.field.fieldPrepend = vm.fieldPrepend;
            vm.field.selectedOptionMetadata = vm.selectedOptionMetadata;
            addDefaultChangeCallbacks();
            vm.initComplete = true;
        };
    }
}());
(function () {
    angular.module('app').component('formTemplate', {
        templateUrl: 'form-template.tpl.html',
        controllerAs: 'vm',
        controller: formTemplateController,
        bindings: {
            formSections: '<',
            onUpdate: '<',
            loading: '<',
            filter: '<',
            noLock: '<'
        }
    });
    function formTemplateController(entityService) {
        const vm = this;
        vm.entityType = entityService.getOpenEntity().entityType;
    }
}());
(function () {
    angular.module('app').component('lockManager', {
        controller: lockManagerController,
        controllerAs: 'vm',
        templateUrl: 'lock-manager.tpl.html',
        bindings: {
            inherited: '<',
            isDefault: '<',
            parentName: '<',
            setting: '<',
            updating: '<',
            hideOptions: '<',
            disabled: '<',
            confirmAdoption: '<',
            entityType: '<',
            isTerm: '<',
            configOptionId: '<',
            dynamicOption: '<'
        }
    });
    function lockManagerController($mdDialog, $scope) {
        var vm = this;
        vm.icon = function () {
            let isOverriding = !vm.inherited && !vm.isDefault && !!vm.parentName;
            if ((vm.inherited || vm.isDefault) && vm.entityType !== 'Org') {
                return 'locked';
            } else if (vm.entityType !== 'Org' && (!vm.dynamicOption || isOverriding)) {
                return 'overriding';
            } else {
                return 'unlocked';
            }
        };
        vm.confirmAndDeleteChildOverrides = function (event, setting) {
            var confirm = $mdDialog.confirm().title('Are you sure?').textContent(`This will clear overrides on all groups and campaigns within this entity.`).targetEvent(event).ok('Clear overrides').cancel('Cancel');
            $mdDialog.show(confirm).then(function () {
                if (typeof setting === 'object') {
                    setting.configOptionId = vm.configOptionId;
                }
                clearOverrides(setting);
            }, function () {
                return;
            });
        };
        function clearOverrides(setting) {
            $scope.$emit('clearOverrides', setting);
        }
    }
}());
(function () {
    angular.module('app').component('lockedIcon', {
        controller: lockedIconController,
        controllerAs: 'vm',
        bindings: {
            parentName: '<',
            setting: '<',
            hideOptions: '<',
            disabled: '<'
        },
        templateUrl: 'locked-icon.tpl.html'
    });
    function lockedIconController($scope) {
        var vm = this;
        vm.showPullout = false;
        vm.showConfigModal = false;
        vm.togglePullout = function () {
            vm.showPullout = !vm.showPullout;
        };
        vm.hidePullout = function () {
            vm.showPullout = false;
        };
        vm.unlockItem = function () {
            $scope.$emit('overrideParent', vm.setting);
            vm.hidePullout();
        };
    }
}());
(function () {
    angular.module('app').component('overridingIcon', {
        controller: overridingIconController,
        controllerAs: 'vm',
        templateUrl: 'overriding-icon.tpl.html',
        bindings: {
            parentName: '<',
            setting: '<',
            updating: '<',
            hideOptions: '<',
            disabled: '<',
            confirmAdoption: '<',
            onCampaign: '<',
            removeOverrides: '<',
            isTerm: '<'
        }
    });
    function overridingIconController($scope, $mdDialog) {
        var vm = this;
        vm.showPullout = false;
        vm.togglePullout = function () {
            vm.showPullout = !vm.showPullout;
        };
        vm.hidePullout = function () {
            vm.showPullout = false;
        };
        function adoptParent() {
            $scope.$emit('adoptParent', vm.setting);
        }
        vm.confirmParentAdopt = function (ev) {
            if (!vm.confirmAdoption) {
                adoptParent();
                return;
            }
            var entityType = vm.onCampaign ? 'campaign' : 'group';
            var confirm = $mdDialog.confirm().title('Are you sure?').textContent(`Clearing the override will permanently delete the content currently entered on this ${ entityType }.`).targetEvent(ev).ok('Clear Override').cancel('Cancel');
            $mdDialog.show(confirm).then(function () {
                adoptParent();
            }, function () {
            });
        };
        vm.confirmDeleteChildOverride = function (ev) {
            vm.removeOverrides(ev, vm.setting);
        };
    }
}());
(function () {
    angular.module('app').component('unlockedIcon', {
        controller: unlockedIconController,
        controllerAs: 'vm',
        templateUrl: 'unlocked-icon.tpl.html',
        bindings: {
            parentName: '<',
            setting: '<',
            updating: '<',
            hideOptions: '<',
            disabled: '<',
            isTerm: '<',
            onCampaign: '<',
            removeOverrides: '<'
        }
    });
    function unlockedIconController() {
        var vm = this;
        vm.showPullout = false;
        vm.togglePullout = function () {
            vm.showPullout = !vm.showPullout;
        };
        vm.hidePullout = function () {
            vm.showPullout = false;
        };
        vm.confirmDeleteChildOverride = function (ev) {
            vm.removeOverrides(ev, vm.setting);
        };
    }
}());
(function () {
    angular.module('app').component('modalGenericObject', {
        bindings: {
            show: '=',
            type: '<',
            objectId: '<',
            objectFields: '<',
            objectEventId: '<',
            objectEntityId: '<',
            objectActivityInfo: '<',
            showViewInAdmin: '<',
            saveFunction: '<',
            objectIds: '<',
            moveDonations: '=',
            moveRecurringDonations: '='
        },
        transclude: true,
        controller: ModalObjectCtrl,
        controllerAs: 'vm'
    });
    function ModalObjectCtrl($scope, $mdDialog) {
        const vm = this;
        $scope.$watch('vm.show', () => {
            if (vm.show) {
                showModal();
            }
        });
        function showModal() {
            $mdDialog.show({
                templateUrl: 'modal-generic-object.tpl.html',
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    type: vm.type,
                    objectId: vm.objectId,
                    objectFields: vm.objectFields,
                    objectEventId: vm.objectEventId,
                    objectEntityId: vm.objectEntityId,
                    objectActivityInfo: vm.objectActivityInfo,
                    showViewInAdmin: vm.showViewInAdmin,
                    saveFunction: vm.saveFunction,
                    objectIds: vm.objectIds
                },
                clickOutsideToClose: true
            }).finally(() => {
                vm.show = false;
            });
        }
    }
    function ModalContentCtrl($http, API_PREFIX, $mdDialog, orgService, campaignService, objectFields, objectEventId, objectEntityId, objectId, type, objectActivityInfo, showViewInAdmin, saveFunction, objectIds) {
        const vm = this;
        vm.closeModal = closeModal;
        vm.objectId = objectId;
        vm.objectEventId = objectEventId;
        vm.setEditing = setEditing;
        vm.lastIndex;
        vm.index;
        vm.savedFlags = [];
        vm.editFlags = [];
        vm.initialValues = [];
        vm.fields = [];
        vm.loadInProgress = true;
        vm.showViewInAdmin = showViewInAdmin;
        vm.saveFunction = saveFunction;
        vm.viewInAdmin = viewInAdmin;
        vm.objectActivityInfo = objectActivityInfo;
        vm.objectIds = objectIds;
        vm.moveDonations = false;
        vm.moveRecurringDonations = false;
        vm.entityId = objectEntityId;
        if (type == 'Online Donation' || type == 'Donation' || type == 'Moved Donation') {
            $http({
                url: `${ API_PREFIX }Donation/GetDonationViewData`,
                headers: { entityId: objectEntityId },
                data: {
                    EventID: objectEventId,
                    DonationID: objectId
                },
                method: 'POST'
            }).then(response => {
                if (response.data.sendToOldAdmin) {
                    $mdDialog.hide();
                    window.open(orgService.getCampaignPath(campaignService.getCampaignByEventId(objectEventId), '/transactions/donations?type=Donation&value=' + objectId), '_blank');
                } else {
                    vm.fields = response.data.donationFields;
                    vm.loadInProgress = false;
                    vm.title = response.data.title;
                    vm.eventName = response.data.eventName;
                }
            }, response => {
                $mdDialog.hide();
            });
        } else if (type == 'EntityTemplatePage') {
            // we loaded modal with fields
            vm.fields = objectFields;
            vm.loadInProgress = false;
            vm.title = 'Edit';
        } else if (type == 'moveDonations') {
            vm.fields = prepareMoveDonationsViewData();
            vm.loadInProgress = false;
            vm.title = 'Move multiple donations';
            vm.moveDonations = true;
        } else if (type == 'moveRecurringDonations') {
            vm.moveRecurringDonations = true;
            vm.fields = prepareMoveDonationsViewData();
            vm.loadInProgress = false;
            vm.title = 'Move multiple recurring donations';
        }
        function setEditing(index, set, e) {
            if (vm.fields[index].isReadonly) {
                return;
            }
            if (set == 'off') {
                vm.editFlags[index] = false;
                $('.pop-up-field').eq(index).removeClass('grayBackground');
                return;
            }
            if (!vm.lastIndex && vm.lastIndex != 0) {
                vm.lastIndex = index;
            }
            if (index != vm.lastIndex) {
                $('.pop-up-field').eq(vm.lastIndex).removeClass('grayBackground');
                vm.editFlags[vm.lastIndex] = false;
                vm.displayedValue[vm.lastIndex] = angular.copy(vm.initialValues[vm.lastIndex]);
                vm.fields[vm.lastIndex].value = angular.copy(vm.initialValues[vm.lastIndex]);
            }
            $('.pop-up-field').eq(index).addClass('grayBackground');
            vm.editFlags[index] = true;
            vm.lastIndex = index;
        }
        function closeModal() {
            $mdDialog.hide();
        }
        function viewInAdmin() {
            window.open(orgService.getCampaignPath(campaignService.getCampaignByEventId(objectEventId), '/transactions/donations?type=Donation&value=' + objectId), '_blank');
        }
        function prepareMoveDonationsViewData() {
            var obj = [
                {
                    'value': 'Various',
                    'name': 'Donor',
                    'isReadonly': true,
                    'fieldType': 'Text'
                },
                {
                    'value': 'Various',
                    'name': 'Amount',
                    'isReadonly': true,
                    'fieldType': 'Text'
                },
                {
                    'value': 'Click to change',
                    'name': 'Recipient',
                    'isReadonly': false,
                    'isAutocomplete': true,
                    'fieldType': 'Text',
                    'recurring': vm.moveRecurringDonations
                },
                {
                    'value': 'Various',
                    'name': 'Method',
                    'isReadonly': true,
                    'fieldType': 'Text'
                },
                {
                    'value': 'Various',
                    'name': 'Date Received',
                    'isReadonly': true,
                    'fieldType': 'Text'
                }
            ];
            return obj;
        }
    }
}());
(function () {
    angular.module('app').component('objectContainer', {
        bindings: {
            type: '@',
            title: '@'
        },
        transclude: true,
        controller: ObjectFieldController,
        templateUrl: 'object-container.tpl.html',
        controllerAs: 'vm'
    });
    function ObjectFieldController($scope, $timeout, $q, $mdDialog, $location, $mdConstant, entityService, campaignService, administratorService, messageService) {
        const vm = this;
    }
}());
(function () {
    angular.module('app').component('fieldEditBtns', {
        bindings: {
            isEditing: '=',
            isSaveActivated: '=',
            save: '&',
            cancel: '&',
            edit: '&',
            saved: '=',
            showLoader: '='
        },
        controller: fieldEditBtnsController,
        templateUrl: 'field-edit-btns.tpl.html',
        controllerAs: 'vm'
    });
    function fieldEditBtnsController($http, API_PREFIX, $window, $scope, $rootScope, $location, $timeout, $interval, orgService, campaignService, entityService, messageService, profileService) {
        const vm = this;
    }
}());
(function () {
    angular.module('app').component('fieldName', {
        bindings: {
            type: '@',
            title: '@'
        },
        transclude: true,
        controller: FieldNameController,
        templateUrl: 'field-name.tpl.html',
        controllerAs: 'vm'
    });
    function FieldNameController() {
        const vm = this;
    }
}());
(function () {
    angular.module('app').directive('fieldValue', FieldValueController);
    function FieldValueController($http, API_PREFIX, $timeout, orgService) {
        return {
            scope: {
                inputId: '<',
                value: '=',
                name: '<',
                isSaveActivated: '=',
                objectEventId: '=',
                entityId: '=',
                eventName: '=',
                displayedValue: '=',
                isAutocomplete: '=',
                isReadonly: '=',
                index: '=',
                isEditing: '=',
                edit: '&',
                fieldType: '=',
                recurring: '='
            },
            bindToController: true,
            controller: function () {
                const vm = this;
                vm.textColor = vm.name == 'Recipient' ? 'blackText' : 'grayText';
                vm.backgroundColor = vm.isReadonly ? 'whiteBackground' : 'grayBackground';
            },
            controllerAs: 'vm',
            templateUrl: 'field-value.tpl.html',
            link: function (scope, element, attrs, ctrl) {
                const vm = ctrl;
                vm.isAutocomplete;
                vm.recurring;
                vm.loadInProgress = false;
                vm.setEditing;
                vm.selectedRecipient = false;
                vm.recipients = [];
                vm.editField = editField;
                vm.inputFieldType = vm.fieldType || 'text';
                if (vm.isAutocomplete) {
                    vm.inputPlaceholder = 'Search ' + vm.name;
                } else {
                    vm.inputValue = vm.displayedValue.value;
                }
                var $autocompleteBar;
                var orgId = orgService.getOrgId();
                $.ajaxSetup({ headers: { orgId: orgId } });
                scope.$watch('vm.isEditing', function (newValue, oldValue) {
                    if (vm.isAutocomplete) {
                        $autocompleteBar = $('#' + vm.inputId);
                        if (newValue) {
                            if (vm.recurring) {
                                getAutocompleteOptions('Cmp/GetValidRecurringDonationRecipients', undefined, vm.entityId);
                            } else {
                                getAutocompleteOptions('Donation/GetValidDonationRecipients', vm.objectEventId);
                            }
                        } else {
                            vm.selectedRecipient = false;
                            vm.isSaveActivated = false;
                            $autocompleteBar.devbridgeAutocomplete('dispose');
                            $autocompleteBar.val('');
                            vm.recipients = [];
                        }
                    }
                });
                function editField() {
                    vm.selectedRecipient = false;
                    $timeout(() => {
                        $autocompleteBar.focus();
                    });
                }
                //change to be more generic for other autocompletes
                function getAutocompleteOptions(url, donationEventID, entityID) {
                    vm.loadInProgress = true;
                    var data = { donationEventID };
                    var headers = { entityID };
                    $http({
                        url: `${ API_PREFIX }${ url }`,
                        headers: headers,
                        data: data,
                        method: 'POST'
                    }).then(data => {
                        var response = data.data;
                        if (response && response.rtrn) {
                            response.rtrn.forEach((group, i) => {
                                if (group.length > 0) {
                                    vm.recipients[i] = $.map(group, function (item) {
                                        return {
                                            value: JSON.stringify(item),
                                            data: { type: item.type }
                                        };
                                    });
                                } else {
                                    vm.recipients[i] = { suggestions: [] };
                                }
                            });
                        }
                        vm.filtered = [];
                        $autocompleteBar.on('input', function () {
                            //if user had chosen recipient but changed input text then deactivate save
                            vm.isSaveActivated = false;
                            var query = $(this).val();
                            vm.filtered = filterTo30(query, vm.recipients);
                            $autocompleteBar.devbridgeAutocomplete('setOptions', { lookup: vm.filtered });
                        });
                        initAutocomplete();
                        vm.loadInProgress = false;
                        $timeout(() => {
                            $autocompleteBar.focus();
                        });
                    });
                }
                function initAutocomplete() {
                    var initialOffsets = {};
                    var setPositions = {};
                    $autocompleteBar.devbridgeAutocomplete({
                        lookup: vm.filtered,
                        appendTo: $('#modal-container'),
                        groupBy: 'type',
                        zIndex: 9999,
                        forceFixPosition: true,
                        showNoSuggestionNotice: true,
                        preserveInput: true,
                        noSuggestionNotice: 'We haven\'t found anything for your search. Please try again.',
                        beforeRender: function (container) {
                            var groups = container.find('.autocomplete-group');
                            var thisOffsetTop = this.offsetTop;
                            groups.each(function (index, group) {
                                var $thisGroup = $(group);
                                var $nextGroup = $(groups[index + 1]);
                                $thisGroup.addClass('gray-text');
                                group.firstChild.innerText = group.firstChild.innerText + 's';
                                var groupDiv = $('<div class="autocomplete-group-inner ellipsis"></div>');
                                groupDiv.insertAfter(group);
                                var groupSuggestions = $(group).nextUntil('.autocomplete-group');
                                groupSuggestions.each(function (index, suggestion) {
                                    if (suggestion.innerText) {
                                        var suggestionValue = JSON.parse(suggestion.innerText);
                                        $(suggestion).empty();
                                        var suggestionInner = createSuggestionInner(suggestionValue);
                                        suggestionInner.appendTo(suggestion);
                                    }
                                });
                                groupSuggestions.appendTo(groupDiv);
                                var keepTypingMsgDiv = $('<div class="keep-typing-msg">Keep typing for more results...</div>');
                                keepTypingMsgDiv.insertAfter(groupDiv);
                                setPositions[index] = function () {
                                    var topMargin = 70;
                                    var $initialOffset = initialOffsets[index];
                                    var scrollTop = container.scrollTop();
                                    var offsetTop = container.offset().top;
                                    if (container.scrollTop() > $initialOffset - topMargin) {
                                        if (!$nextGroup.length || $nextGroup[0].offsetTop > container.scrollTop() + $thisGroup.children().outerHeight() - topMargin) {
                                            $thisGroup.css({
                                                position: 'fixed',
                                                top: thisOffsetTop + topMargin + 'px'
                                            });
                                        } else {
                                            $thisGroup.css({
                                                position: '',
                                                top: ''
                                            });
                                        }
                                    } else {
                                        $thisGroup.css({
                                            position: '',
                                            top: ''
                                        });
                                    }
                                };
                            });
                            $(window, container).resize(function () {
                                for (var index in setPositions) {
                                    setPositions[index]();
                                }
                            });
                            container.scroll(function () {
                                for (var index in setPositions) {
                                    setPositions[index]();
                                }
                            });
                            container.on('mousewheel DOMMouseScroll', function (e) {
                                var delta = e.wheelDelta || e.originalEvent && e.originalEvent.wheelDelta || -e.detail, bottomOverflow = this.scrollTop + $(this).outerHeight() - this.scrollHeight >= 0, topOverflow = this.scrollTop <= 0;
                                if (delta < 0 && bottomOverflow || delta > 0 && topOverflow) {
                                    e.preventDefault();
                                }
                            });
                            var previousScrollTop;
                            $(window).scroll(function () {
                                if (!previousScrollTop && $(this).scrollTop() && ($autocompleteBar.is(':focus') || container.is(':visible'))) {
                                    //for the suggestions to hide and reapear properly
                                    $autocompleteBar.blur();
                                    container.hide();
                                    $autocompleteBar.parent().click();
                                }
                                previousScrollTop = $(this).scrollTop();
                            });
                            return container;
                        },
                        onSelect: function (suggestion) {
                            vm.isSaveActivated = true;
                            var tempVal = JSON.parse(suggestion.value);
                            vm.value = {
                                goalID: tempVal.goalID,
                                entityID: tempVal.entityID
                            };
                            this.value = '';
                            vm.selectedRecipient = true;
                            vm.displayedValue.value = tempVal.info;
                            vm.displayedValue.eventName = tempVal.eventName;
                            this.click();
                        },
                        onSearchComplete: function () {
                            $('.autocomplete-group').each(function (index, thisGroup) {
                                initialOffsets[index] = thisGroup.offsetTop;
                                setPositions[index]();
                            });
                            var container = angular.element($('.autocomplete-suggestions')[$('.autocomplete-suggestions').length - 1]);
                            if (container.offset().top + container.height() > Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) {
                                container.css({ height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - container.offset().top });
                            } else {
                                container.css({ height: 'inherit' });
                            }
                        },
                        width: 600
                    });
                }
                function filterTo30(query, groups) {
                    var queryLowerCase = query.toLowerCase();
                    var allResults = [];
                    groups.forEach((group, index) => {
                        allResults[index] = [];
                    });
                    var top10OfEach = [];
                    groups.forEach((group, index) => {
                        var queryStrForExactMatch = '"' + queryLowerCase + '"';
                        var nextIndexForExactMatch = 0;
                        //search for exact matches and move them to beginning
                        for (var i = 0; i < group.length; i++) {
                            if (group[i].value.toLowerCase().indexOf(queryStrForExactMatch) !== -1) {
                                group.splice(nextIndexForExactMatch, 0, group.splice(i, 1)[0]);
                                nextIndexForExactMatch++;
                            }
                        }
                        var j = 0;
                        while (allResults[index].length < 10 && j < group.length) {
                            if (group[j].value.toLowerCase().indexOf(queryLowerCase) !== -1) {
                                allResults[index].push(group[j]);
                            }
                            j++;
                        }
                        top10OfEach = top10OfEach.concat(allResults[index].slice(0, 10));
                    });
                    return top10OfEach;
                }
                function createSuggestionInner(suggestionValue) {
                    return $('<div class="suggestion-left"><ul><li class="suggestion-info">' + (suggestionValue.info || '') + '</li><li class="suggestion-secondary-info gray-text">' + (suggestionValue.secondaryInfo || '') + '</li></ul></div><div class="suggestion-right gray-text"><ul><li>' + (suggestionValue.eventName || '') + '</li><li>' + (suggestionValue.eventID ? 'CampaignID: ' + suggestionValue.eventID : '') + '</li></ul></div>');
                }
            }
        };
    }
}());
(function () {
    angular.module('app').component('objectField', {
        bindings: {
            id: '=',
            entityId: '=',
            objectEventId: '=',
            objectActivityInfo: '<',
            eventName: '=',
            name: '<',
            value: '=',
            initialValue: '=',
            displayedValue: '=',
            isReadonly: '<',
            isAutocomplete: '<',
            recurring: '=',
            isEditing: '=',
            setEditing: '&',
            index: '<',
            saved: '=',
            saveFunction: '=',
            fieldType: '=',
            objectIds: '=',
            moveDonations: '=',
            moveRecurringDonations: '='
        },
        transclude: true,
        controller: ObjectFieldController,
        templateUrl: 'object-field.tpl.html',
        controllerAs: 'vm'
    });
    function ObjectFieldController($rootScope, $http, API_PREFIX, extJsLoader) {
        const vm = this;
        vm.id;
        vm.name;
        vm.isAutocomplete;
        vm.recurring;
        vm.isReadonly;
        vm.save = save;
        vm.cancel = cancel;
        vm.edit = edit;
        vm.orgValue;
        vm.editFlags;
        vm.isSaveActivated = false;
        vm.saveFunction;
        vm.fieldType;
        vm.moveDonations;
        vm.objectIds;
        vm.showLoader = false;
        vm.$onInit = function () {
            vm.isEditing = false;
            vm.saved = false;
            vm.displayedValue = {
                value: vm.value,
                eventName: vm.eventName
            };
            vm.initialValue = {
                value: vm.value,
                eventName: vm.eventName
            };
            vm.inputId = 'value-bar-' + vm.id + '-' + vm.name;
        };
        function save() {
            if (!vm.isSaveActivated) {
                return;
            }
            vm.showLoader = true;
            if (vm.saveFunction) {
                var newVal = $('#' + vm.inputId).val();
                vm.saveFunction(vm.entityId, vm.id, vm.name, newVal).then(function successCallback(response) {
                    vm.value = newVal;
                    vm.displayedValue.value = newVal;
                    postSaveSuccess(response);
                }, function errorCallback(response) {
                    hideLoader();
                });
            } else if (vm.moveDonations) {
                $http({
                    url: `/PlatformDonation/DonationsChangedRecipient`,
                    headers: {
                        fromEntityId: vm.entityId,
                        toEntityId: vm.value.entityID
                    },
                    data: {
                        donationIds: vm.objectIds,
                        toGoalId: vm.value.goalID
                    },
                    method: 'POST'
                }).then(function successCallback(response) {
                    postSaveSuccess();
                }, function errorCallback(response) {
                    hideLoader();
                });
            } else if (vm.moveRecurringDonations) {
                $http({
                    url: `/PlatformCmp/RecurringDonationsChangedRecipient`,
                    headers: {
                        entityId: vm.entityId,
                        transferRecurringToEntityId: vm.value.entityID
                    },
                    data: {
                        toGoalId: vm.value.goalID,
                        recurringDonationIds: vm.objectIds
                    },
                    method: 'POST'
                }).then(function successCallback(response) {
                    postSaveSuccess();
                    extJsLoader.extJsController.refreshInnerPage();
                }, function errorCallback(response) {
                    hideLoader();
                });
            } else {
                $http({
                    url: `/PlatformDonation/DonationChangedRecipient`,
                    headers: {
                        fromEntityId: vm.entityId,
                        toEntityId: vm.value.entityID
                    },
                    data: {
                        donationId: vm.id,
                        toGoalId: vm.value.goalID
                    },
                    method: 'POST'
                }).then(function successCallback(response) {
                    postSaveSuccess(response);
                    $rootScope.$broadcast('updateActivityStream', {
                        activityInfo: vm.objectActivityInfo,
                        newId: response.data.newId,
                        newEntityId: vm.value.entityID
                    });
                }, function errorCallback(response) {
                    hideLoader();
                });
            }
        }
        function hideLoader() {
            vm.showLoader = false;
        }
        function postSaveSuccess(response) {
            vm.initialValue = angular.copy(vm.displayedValue);
            vm.setEditing({
                index: vm.index,
                set: 'off'
            });
            vm.saved = true;
            vm.showLoader = false;
        }
        function cancel() {
            vm.isSaveActivated = false;
            vm.displayedValue = angular.copy(vm.initialValue);
            vm.setEditing({
                index: vm.index,
                set: 'off'
            });
        }
        function edit() {
            vm.setEditing({ index: vm.index });
            if (!vm.isAutocomplete) {
                vm.isSaveActivated = true;
            }
        }
    }
}());
(function () {
    angular.module('app').component('serviceCard', {
        bindings: {
            image: '@',
            name: '@'
        },
        transclude: true,
        templateUrl: 'service-card.tpl.html',
        controllerAs: 'vm'
    });
}());
(function () {
    angular.module('app').component('siteBuilderIcon', {
        controller: siteBuilderIconController,
        controllerAs: 'vm',
        template: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 94"><g id="Layer_2" data-name="Layer 2"><g id="Layer_3" data-name="Layer 3"><path fill={{vm.fill}} d="M70.42,55.63c.85,1.09-.27,1.42-.78,1.68A23.89,23.89,0,0,0,63.09,62a23.39,23.39,0,0,0-4.79,6.44c-.26.5-.61,1.6-1.71.77A88.88,88.88,0,0,1,44.28,59C31.48,46.39-3.58,4.11.3.29S47.19,30.94,60,43.53A87.86,87.86,0,0,1,70.42,55.63Z"/><path fill={{vm.fill}} d="M65.22,83.46c-4.88-4.8-3.5-11.8,1.73-16.95S79.3,60,84.18,64.81c7.57,7.44,1.18,15.08,11.24,26.41C100,96.33,76.73,94.77,65.22,83.46Z"/></g></g></svg>`,
        bindings: { fill: '<' }
    });
    function siteBuilderIconController() {
        var vm = this;
    }
}());
(function () {
    angular.module('app').component('superAdminIcon', {
        controller: superAdminIconController,
        controllerAs: 'vm',
        template: `<svg version="1.1" id="SuperAdmin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" enable-background="new 0 0 20 20" xml:space="preserve">
    <path fill={{vm.fill}} d="M3.135,6.89c0.933-0.725,1.707-0.225,2.74,0.971c0.116,0.135,0.272-0.023,0.361-0.1c0.088-0.078,1.451-1.305,1.518-1.361
    C7.82,6.341,7.9,6.231,7.795,6.108C7.688,5.985,7.301,5.483,7.052,5.157c-1.808-2.365,4.946-3.969,3.909-3.994
    c-0.528-0.014-2.646-0.039-2.963-0.004C6.715,1.294,5.104,2.493,4.293,3.052C3.232,3.778,2.836,4.204,2.771,4.263
    c-0.3,0.262-0.048,0.867-0.592,1.344C1.604,6.11,1.245,5.729,0.912,6.021C0.747,6.167,0.285,6.513,0.153,6.628
    C0.02,6.745-0.004,6.942,0.132,7.099c0,0,1.264,1.396,1.37,1.52C1.607,8.741,1.893,8.847,2.069,8.69
    c0.177-0.156,0.632-0.553,0.708-0.623C2.855,8.001,2.727,7.206,3.135,6.89z M8.843,7.407c-0.12-0.139-0.269-0.143-0.397-0.029
    L7.012,8.63c-0.113,0.1-0.129,0.283-0.027,0.4l8.294,9.439c0.194,0.223,0.53,0.246,0.751,0.053L17,17.709
    c0.222-0.195,0.245-0.533,0.052-0.758L8.843,7.407z M19.902,3.39c-0.074-0.494-0.33-0.391-0.463-0.182
    c-0.133,0.211-0.721,1.102-0.963,1.506c-0.24,0.4-0.832,1.191-1.934,0.41c-1.148-0.811-0.749-1.377-0.549-1.758
    c0.201-0.383,0.818-1.457,0.907-1.59c0.089-0.135-0.015-0.527-0.371-0.363c-0.357,0.164-2.523,1.025-2.823,2.26
    c-0.307,1.256,0.257,2.379-0.85,3.494l-1.343,1.4l1.349,1.566l1.654-1.57c0.394-0.396,1.236-0.781,1.998-0.607
    c1.633,0.369,2.524-0.244,3.061-1.258C20.057,5.792,19.977,3.884,19.902,3.39z M2.739,17.053c-0.208,0.209-0.208,0.549,0,0.758
    l0.951,0.93c0.208,0.209,0.538,0.121,0.746-0.088l4.907-4.824L7.84,12.115L2.739,17.053z"/>
</svg>`,
        bindings: { fill: '<' }
    });
    function superAdminIconController() {
        var vm = this;
    }
}());
(function () {
    angular.module('app').component('splashNotifications', {
        bindings: {
            splashNotificationsData: '<',
            splashLastFinishedNotificationId: '<'
        },
        controllerAs: 'vm',
        controller: modalAddSplashNotificationCtrl
    });
    function modalAddSplashNotificationCtrl($scope, $rootScope, $mdDialog, cookieService) {
        const vm = this;
        function showModal() {
            $mdDialog.show({
                templateUrl: 'splash-notifications.tpl.html',
                clickOutsideToClose: true,
                controller: SplashNotificationsCtrl,
                controllerAs: 'vm',
                locals: {
                    splashNotificationsData: vm.splashNotificationsData,
                    activeNotificationIds: vm.activeNotificationIds
                }
            }).finally(() => {
            });
        }
        vm.$onInit = function () {
            vm.splashNotificationsData = vm.splashNotificationsData.filter(s => s.notificationId > vm.splashLastFinishedNotificationId || s.persistent);
            if (vm.splashNotificationsData.length) {
                showModal();
            }
        };
    }
    function SplashNotificationsCtrl($mdDialog, $http, API_PREFIX, splashNotificationsData, activeNotificationIds, cookieService) {
        const vm = this;
        vm.close = close;
        vm.slickConfig = {
            enabled: true,
            infinite: false,
            draggable: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            event: {
                afterChange: (event, slick, currentSlide, nextSlide) => {
                    vm.currentSlide = currentSlide + 1;
                }
            },
            method: {}
        };
        vm.splashNotificationsData = splashNotificationsData;
        vm.totalSlides = vm.splashNotificationsData.length;
        vm.currentSlide = 1;
        function close(markAsViewed) {
            if (markAsViewed) {
                dontShowAgain();
            }
            $mdDialog.hide();
        }
        function dontShowAgain() {
            vm.lastNotificationId = vm.splashNotificationsData[vm.totalSlides - 1].notificationId;
            $http({
                url: API_PREFIX + 'Profile/UpdateUserSettings',
                data: { list: { lastFinishedSplashNotificationId: vm.lastNotificationId } },
                method: 'POST'
            });
        }
    }
}());
(function () {
    angular.module('app').component('static', {
        templateUrl: 'static.tpl.html',
        controller: StaticCtrl,
        controllerAs: 'vm',
        $routeConfig: [
            {
                path: '/salesforce',
                name: 'Salesforce',
                component: 'staticSalesforce'
            },
            {
                path: '/dropbox',
                name: 'Dropbox',
                component: 'staticDropbox'
            },
            {
                path: '/nationbuilder',
                name: 'NationBuilder',
                component: 'staticNationbuilder'
            },
            {
                path: '/mailchimp',
                name: 'Mailchimp',
                component: 'staticMailchimp'
            },
            {
                path: '/stripe',
                name: 'Stripe',
                component: 'staticStripe'
            },
            {
                path: '/constantcontact',
                name: 'ConstantContact',
                component: 'staticConstantcontact'
            },
            {
                path: '/loading',
                name: 'Loading',
                component: 'staticLoading'
            },
            {
                path: '/**',
                name: 'Page Not Found',
                component: 'pageNotFound'
            }
        ]
    });
    function StaticCtrl() {
        const vm = this;
    }
}());
(function () {
    angular.module('app').component('staticConstantcontact', {
        templateUrl: 'static-constantcontact.tpl.html',
        controller: StaticConstantcontactCtrl,
        controllerAs: 'vm'
    });
    function StaticConstantcontactCtrl($location) {
        const vm = this;
        vm.errCode = $location.$$search.err;
        if (vm.errCode) {
            vm.isSuccess = false;
            vm.errorDescription = $location.$$search.error_description;
        } else {
            vm.isSuccess = true;
        }
    }
}());
(function () {
    angular.module('app').component('staticDropbox', {
        templateUrl: 'static-dropbox.tpl.html',
        controller: StaticDropboxCtrl,
        controllerAs: 'vm'
    });
    function StaticDropboxCtrl($location) {
        const vm = this;
        vm.errCode = $location.$$search.err;
        if (vm.errCode) {
            vm.isSuccess = false;
            vm.errorDescription = $location.$$search.error_description;
        } else {
            vm.isSuccess = true;
        }
    }
}());
(function () {
    angular.module('app').component('staticLoading', {
        template: `<div style="padding-top: 50px;"><loader size="80"></loader></div>`,
        controller: StaticLoading,
        controllerAs: 'vm'
    });
    function StaticLoading($location) {
        const vm = this;
    }
}());
(function () {
    angular.module('app').component('staticMailchimp', {
        templateUrl: 'static-mailchimp.tpl.html',
        controller: StaticMailchimpCtrl,
        controllerAs: 'vm'
    });
    function StaticMailchimpCtrl($location) {
        const vm = this;
        vm.errCode = $location.$$search.err;
        if (vm.errCode) {
            vm.isSuccess = false;
            vm.errorDescription = $location.$$search.error_description;
        } else {
            vm.isSuccess = true;
        }
    }
}());
(function () {
    angular.module('app').component('staticNationbuilder', {
        templateUrl: 'static-nationbuilder.tpl.html',
        controller: StaticNationbuilderCtrl,
        controllerAs: 'vm'
    });
    function StaticNationbuilderCtrl($location) {
        const vm = this;
        vm.errCode = $location.$$search.err;
        if (vm.errCode) {
            vm.isSuccess = false;
            vm.errorDescription = $location.$$search.error_description;
        } else {
            vm.isSuccess = true;
        }
    }
}());
(function () {
    angular.module('app').component('staticSalesforce', {
        templateUrl: 'static-salesforce.tpl.html',
        controller: StaticSalesforceCtrl,
        controllerAs: 'vm'
    });
    function StaticSalesforceCtrl($location) {
        const vm = this;
        vm.errCode = $location.$$search.err;
        vm.authId = $location.$$search.auth_id;
        vm.orgId = $location.$$search.org_id;
        if (vm.errCode) {
            vm.isSuccess = false;
            vm.errorDescription = $location.$$search.error_description;
        } else {
            vm.isSuccess = true;
        }
        const msg = {
            success: vm.isSuccess,
            error: vm.errCode + ': ' + vm.errorDescription,
            authId: vm.authId,
            orgId: vm.orgId
        };
        if (window.opener) {
            window.opener.postMessage(msg);
        } else if (window.BroadcastChannel) {
            const channel = new window.BroadcastChannel('OA_salesforce_connection');
            channel.postMessage(msg);
        }
    }
}());
(function () {
    angular.module('app').component('staticStripe', {
        templateUrl: 'static-stripe.tpl.html',
        controller: StaticStripeCtrl,
        controllerAs: 'vm'
    });
    function StaticStripeCtrl($location) {
        const vm = this;
        vm.errCode = $location.$$search.err;
        if (vm.errCode) {
            vm.isSuccess = false;
            vm.errorDescription = $location.$$search.error_description;
        } else {
            vm.isSuccess = true;
        }
    }
}());
(function () {
    angular.module('app').directive('activeLink', ActiveLink);
    function ActiveLink($location, $timeout) {
        return {
            restrict: 'A',
            link($scope, $element) {
                const updateClasses = () => {
                    let nextHref = $location.path();
                    $scope.$parent.vm.activeLink = undefined;
                    angular.forEach($element.find('a'), a => {
                        a = angular.element(a);
                        let href = a.attr('href') || a.attr('ng-href');
                        let activeLinkMatch = a.data('activeLink');
                        if (href) {
                            href = href.split('?')[0];
                            if (href.indexOf('.') == 0) {
                                href = href.substring(1);
                            }
                            if (nextHref == href || nextHref.indexOf(href) > -1 || activeLinkMatch && nextHref.indexOf(activeLinkMatch) > -1) {
                                a.parent().addClass('active');
                                a.closest('[top-nav-menu-item]').addClass('active');
                                $scope.$parent.vm.activeLink = a.attr('ng-link');
                            } else {
                                a.parent().removeClass('active');
                            }
                        }
                    });
                };
                updateClasses();
                $scope.$on('$locationChangeStart', () => {
                    updateClasses();
                });
            }
        };
    }
}());
(function () {
    angular.module('app').directive('affix', Affix);
    function Affix($window, $timeout) {
        return {
            restrict: 'AE',
            scope: {
                affixTopMargin: '@',
                affixedClass: '@',
                unaffixedClass: '@'
            },
            link($scope, $element, $attrs) {
                const marginTop = Number($scope.affixTopMargin) || 0;
                $timeout(() => {
                    const initialOffsetTop = $element.offset().top;
                    const changeElementPosition = () => {
                        if ($window.scrollY >= initialOffsetTop - marginTop) {
                            $element.css({
                                'position': 'fixed',
                                top: marginTop + 'px'
                            });
                            $element.addClass($scope.affixedClass);
                            $element.removeClass($scope.unaffixedClass);
                        } else {
                            $element.css({
                                'position': '',
                                top: ''
                            });
                            $element.addClass($scope.unaffixedClass);
                            $element.removeClass($scope.affixedClass);
                        }
                    };
                    angular.element($window).on('scroll', changeElementPosition);
                    changeElementPosition();
                });
            }
        };
    }
}());
(function () {
    angular.module('app').directive('affixParent', AffixParent);
    function AffixParent() {
        return {
            restrict: 'AE',
            transclude: true,
            scope: true,
            template: `<ng-transclude></ng-transclude>
                <div class="affixSpace" ng-style="affixSpaceStyle"></div>`,
            link($scope, $element, $attrs) {
                let unwatch = $scope.$watch(() => $element.find('[affix], affix').length, (newValue, oldValue) => {
                    if (newValue) {
                        run();
                    }
                });
                function run() {
                    unwatch();
                    let $affixElm = $element.find('[affix], affix');
                    $scope.affixSpaceStyle = {
                        height: $affixElm.outerHeight(true) + 'px',
                        width: $affixElm.outerWidth(true) + 'px'
                    };
                    $scope.$watch(() => {
                        return {
                            h: $affixElm.outerHeight(true),
                            w: $affixElm.outerWidth(true)
                        };
                    }, (newValue, oldValue) => {
                        $scope.affixSpaceStyle = {
                            height: newValue.h + 'px',
                            width: newValue.w + 'px'
                        };
                    }, true);
                }
            }
        };
    }
}());
(function () {
    angular.module('app').directive('ckEditor', ckEditor);
    function ckEditor(settingsService, $timeout) {
        return {
            require: '?ngModel',
            restrict: 'A',
            scope: {
                ckEditor: '=',
                ckEditorReadonly: '<',
                ckEditorMini: '<',
                ckEditorButtons: '<',
                ckEditorButtonsTargetItem: '<',
                ckEditorStartInSource: '<',
                ckEditorBeforeSetMode: '<',
                ckEditorItemEditable: '<',
                ckEditorDisplayFrame: '<'
            },
            link: function (scope, elm, attr, ngModel) {
                elm[0].name = attr.name;
                var ckEditor = scope.ckEditor;
                if (scope.ckEditorMini) {
                    ckEditor = CKEDITOR.getMiniAdminConfig();
                }
                var config = angular.extend({}, ckEditor, { readOnly: scope.ckEditorReadonly || false });
                if (scope.ckEditorStartInSource) {
                    config.startupMode = 'source';
                }
                if (scope.ckEditorButtons) {
                    config.extraButtons = [];
                    _.forEach(scope.ckEditorButtons, button => {
                        if (!button.hide || !button.hide(scope.ckEditorButtonsTargetItem)) {
                            button.command = evnt => {
                                button.action(scope.ckEditorButtonsTargetItem, evnt, scope.ckEditorItemEditable);
                            };
                            config.extraButtons.push(button);
                        }
                    });
                    if (config.extraPlugins) {
                        config.extraPlugins += ',';
                    } else {
                        config.extraPlugins = '';
                    }
                    config.extraPlugins += 'extraButtons';
                }
                if (scope.ckEditorDisplayFrame) {
                    config.sharedSpaces = {
                        top: 'ckeditorTop' + attr.name,
                        bottom: 'ckeditorBottom' + attr.name
                    };
                    if (config.extraPlugins) {
                        config.extraPlugins += ',';
                    } else {
                        config.extraPlugins = '';
                    }
                    config.extraPlugins += 'sharedspace,autogrow';
                    config.removePlugins = 'maximize,resize';
                    config.height = 200;
                    config.autoGrow_minHeight = 200;
                }
                var ck = location.search.indexOf('fullCk') > -1 ? CKEDITOR.replace(elm[0], { extraPlugins: 'placeholder' }) : CKEDITOR.replace(elm[0], ckEditor ? config : CKEDITOR.getMiniAdminConfig());
                if (!ngModel)
                    return;
                settingsService.getHelpLinks().then(r => window.ckediterHelpBtn = r.liquidTemplator);
                function updateModel() {
                    $timeout(function () {
                        ngModel.$setViewValue(ck.getData());
                    });
                }
                ck.on('change', updateModel);
                ck.on('key', updateModel);
                ck.on('beforeSetMode', evnt => scope.ckEditorBeforeSetMode && scope.ckEditorBeforeSetMode(evnt));
                scope.$watch('ckEditorReadonly', function (value) {
                    value !== undefined && ck && ck.editable() && ck.setReadOnly(value || false);
                });
                ngModel.$render = function (value) {
                    ck.setData(ngModel.$viewValue);
                };
            }
        };
    }
}());
(function () {
    angular.module('app').directive('dynamicFieldTag', function ($compile) {
        return {
            restrict: 'E',
            require: '?ngModel',
            scope: {
                component: '&',
                item: '&',
                ngModel: '<',
                ngDisabled: '<'
            },
            link: function ($scope, $element, attrs, ngModel) {
                $scope.$on('updateValue', function (event, value) {
                    ngModel.$setViewValue(value);
                }, true);
                var component = angular.isFunction($scope.component) ? $scope.component() : '';
                var item = angular.isFunction($scope.item) ? $scope.item() : {};
                var domElement = angular.element('<' + component + ' ng-disabled="ngDisabled" ng-model="ngModel" id="' + item.Id + '"></' + component + '>');
                $compile(domElement)($scope);
                $element.replaceWith(domElement);
            }
        };
    });
}());
(function () {
    angular.module('app').directive('emailPreview', function () {
        function link(scope, element) {
            var iframe = document.createElement('iframe');
            var element0 = element[0];
            element0.appendChild(iframe);
            var baseStyling = `
            <style>
            html {
                font-family: sans-serif;
                padding: 15px;
            }
            </style>`;
            scope.$watch('content', function () {
                var body = iframe.contentDocument.body;
                if (scope.content) {
                    if (navigator.userAgent.includes('Firefox')) {
                        iframe.onload = function () {
                            iframe.contentDocument.body.innerHTML = baseStyling + scope.content;
                        };
                    } else {
                        body.innerHTML = baseStyling + scope.content;
                    }
                }
            });
        }
        return {
            link: link,
            template: `<div class="preview-title" ng-if="title">{{title}}</div><div ng-transclude></div>`,
            restrict: 'E',
            transclude: true,
            scope: {
                content: '=',
                title: '@'
            }
        };
    });
}());
(function () {
    angular.module('app').directive('escKey', function () {
        return function (scope, element, attrs) {
            element.bind('keydown keypress', function (event) {
                if (event.which === 27) {
                    // 27 = esc key
                    scope.$apply(function () {
                        scope.$eval(attrs.escKey);
                    });
                    event.preventDefault();
                    event.stopPropagation();
                }
            });
            scope.$on('$destroy', function () {
                element.unbind('keydown keypress');
            });
        };
    });
}());
(function () {
    angular.module('app').directive('imageAltText', ImageAltText);
    function ImageAltText() {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                element.on('error', function () {
                    scope.vm.showImage = false;
                    scope.$apply();
                });
            }
        };
    }
}());
(function () {
    angular.module('app').directive('languageSelect', languageSelect);
    function languageSelect() {
        return {
            restrict: 'E',
            template: `<md-input-container ng-if="vm.isMultiLanguage">
                        <label>Language</label>
                        <md-select ng-model="vm.selectedLanguage" ng-change="vm.getData()">
                            <md-option ng-repeat="(key, value) in vm.languages" value="{{key}}">{{value}}</md-option>
                        </md-select>
                    </md-input-container>`,
            link($scope) {
            }
        };
    }
}());
(function () {
    angular.module('app').directive('loader', Loader);
    function Loader() {
        return {
            restrict: 'E',
            scope: { size: '@' },
            template: `
        <div layout="row" layout-sm="column" layout-align="space-around">
            <md-progress-circular md-diameter="{{size}}"></md-progress-circular>
        </div>
        `
        };
    }
}());
(function () {
    angular.module('app').directive('mdCheckboxGroup', function () {
        function link(scope, element, attrs, ngModel) {
            scope.values = {};
            ngModel.$render = function () {
                if (scope.ngModel) {
                    _.forEach(scope.options, (label, option) => {
                        scope.values[option] = scope.ngModel.includes(option);
                    });
                }
            };
            scope.$watch('values', function (checkboxValues) {
                let value = _.keys(_.pickBy(checkboxValues));
                if (!_.isEqual(value, ngModel.$viewValue)) {
                    ngModel.$setViewValue(value);
                }
            }, true);
            ngModel.$isEmpty = function (value) {
                return !value || value.length === 0;
            };
        }
        return {
            link: link,
            template: `
                <div ng-repeat="(option,label) in options">
                    <md-checkbox ng-model="values[option]" ng-disabled="ngDisabled">{{ label }}</md-checkbox>
                </div>`,
            restrict: 'E',
            transclude: true,
            require: '?ngModel',
            scope: {
                ngModel: '<',
                ngDisabled: '<',
                options: '<'
            }
        };
    });
}());
(function () {
    angular.module('app').directive('mdDialogHeader', mdDialogHeader);
    function mdDialogHeader() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                title: '@',
                onClose: '&',
                externalLink: '@',
                actions: '='
            },
            template: `
            <md-toolbar class="md-default">
                <div class="md-toolbar-tools">
                    <span class="popup__title">{{title || ''}}</span>
                    <div ng-transclude></div>
                    <span flex></span>
                    <custom-button ng-repeat="action in actions" button="action"></custom-button>
                    <md-button class="no-horizontal-margins md-icon-button" ng-if="externalLink" target="_blank" href="{{externalLink}}">
                        <md-icon>
                            <i class="zmdi zmdi-arrow-right-top"></i>
                        </md-icon>
                    </md-button>
                    <custom-button button="{class: 'md-icon-button', action: onClose, useLoader: true, skipComplete: true }">
                        <md-icon>
                            <i class="zmdi zmdi-close"></i>
                        </md-icon>
                    </custom-button>
                </div>
            </md-toolbar>
        `
        };
    }
}());
(function () {
    angular.module('app').directive('mdTabsHideSingle', function () {
        return {
            restrict: 'A',
            require: [
                'mdTabs',
                'mdTabsHideSingle'
            ],
            link: function ($scope, $element, attrs, controllers) {
                var tabsController = controllers[0];
                var thisController = controllers[1];
                thisController.init($element, tabsController);
            },
            controller: function ($scope, $mdUtil) {
                this.init = function ($element, tabsController) {
                    $mdUtil.nextTick(function () {
                        var $tabsContainer = $element.find('md-tabs-canvas');
                        if (tabsController.tabs.length <= 1) {
                            $tabsContainer.hide();
                            $element.addClass('tabs-hidden');
                        } else {
                            $tabsContainer.show();
                            $element.addClass('tabs-visible');
                        }
                    });
                };
            }
        };
    });
}());
(function () {
    angular.module('app').directive('ngModelFilter', ngModelFilter);
    function ngModelFilter() {
        return {
            restrict: 'A',
            priority: 10,
            //Run after ngModel
            require: 'ngModel',
            link($scope, $element, $attrs, ngModelController) {
                let filter = $scope.$eval($attrs.ngModelFilter);
                let filterUpdateOn = $attrs.ngModelFilterUpdateOn;
                ngModelController.$parsers.push(value => {
                    //convert data from view format to model format
                    return filter.unfilter(value);
                });
                ngModelController.$formatters.push(value => {
                    //convert data from model format to view format
                    return filter.filter(value);
                });
                if (filterUpdateOn) {
                    $element.on(filterUpdateOn, () => {
                        ngModelController.$viewValue = ngModelController.$formatters.reduceRight((prev, fn) => fn(prev), ngModelController.$modelValue);
                        ngModelController.$render();
                    });
                }
            }
        };
    }
}());
(function () {
    angular.module('app').directive('outsideClick', OutsideClick);
    function OutsideClick($document, $timeout) {
        return {
            restrict: 'A',
            link($scope, $element, $attrs) {
                const scopeExpression = $attrs.outsideClick;
                const onDocumentClick = event => {
                    let isChild = $element[0].contains(event.target);
                    if (!isChild || event.target.parentNode.tagName === 'A') {
                        $scope.$apply(scopeExpression);
                    }
                };
                $timeout(() => {
                    $document.on('click', onDocumentClick);
                });
                $element.on('$destroy', () => {
                    $document.off('click', onDocumentClick);
                });
            }
        };
    }
}());
(function () {
    angular.module('app').directive('selectField', SelectField);
    function SelectField() {
        return {
            restrict: 'A',
            link($scope, $element, $attrs) {
                let $input = $element.is('input, md-select, md-checkbox') ? $element : $element.find('input, md-select, md-checkbox').first();
                $element.click(() => {
                    $input.focus();
                    $input.select();
                });
            }
        };
    }
}());
(function () {
    angular.module('app').directive('contactsList', () => ({
        controller: contactsList,
        controllerAs: 'vm',
        link: (scope, element, attrs, ngModel) => {
            scope.$on('updateValue', function (event, value) {
                // Check members and contacts separately (instead of checking if value equals $viewValue)
                // because the value may sometimes have extra metadata (e.g. totalSelected) 
                if (!_.isEqual(_.sortBy(value.members), _.sortBy(ngModel.$viewValue.members)) || !_.isEqual(_.sortBy(value.contacts), _.sortBy(ngModel.$viewValue.contacts))) {
                    ngModel.$setViewValue(value);
                }
            }, true);
            ngModel.$isEmpty = function (value) {
                return !value || !value.totalSelected || value.totalSelected === 0;
            };
        },
        templateUrl: 'selectable-grid-container.tpl.html',
        restrict: 'E',
        transclude: true,
        require: '?ngModel',
        scope: {
            ngModel: '<',
            ngDisabled: '<'
        }
    }));
    function contactsList($scope, $timeout, agGridService) {
        const vm = this;
        vm.itemName = 'recipient';
        vm.itemNamePlural = 'recipients';
        vm.gridFunctions = {};
        vm.gridFunctions.getColumnDefs = function (editable) {
            return [
                {
                    field: 'firstName',
                    headerName: 'First Name',
                    filter: 'agTextColumnFilter',
                    checkboxSelection: editable
                },
                {
                    field: 'lastName',
                    headerName: 'Last Name',
                    filter: 'agTextColumnFilter'
                },
                {
                    field: 'Email',
                    filter: 'agTextColumnFilter'
                },
                agGridService.columnDefinitions.boolean({
                    field: 'IsDonor',
                    headerName: 'Donor',
                    filter: editable
                }),
                agGridService.columnDefinitions.boolean({
                    field: 'IsRegistrant',
                    headerName: 'Registrant',
                    filter: editable
                }),
                agGridService.columnDefinitions.boolean({
                    field: 'Fundraiser',
                    filter: editable
                }),
                agGridService.columnDefinitions.boolean({
                    field: 'HasTeam',
                    headerName: 'Has Team',
                    filter: editable
                })
            ];
        };
        vm.gridFunctions.getDatasource = function (editable, ngModel) {
            return editable ? agGridService.getDatasource('Contact', 'AllMembers') : agGridService.getDatasource('Contact', 'MembersById', () => {
                return {
                    memberIds: ngModel.members,
                    contactIds: ngModel.contacts
                };
            });
        };
        vm.gridFunctions.isNodeSelected = function (data, ngModel) {
            return ngModel.members && ngModel.members.includes(data.MemberID) || ngModel.contacts && ngModel.contacts.includes(data.ContactID);
        };
        vm.gridFunctions.updateSelectedRows = function (selectedNodes) {
            let recipients = {
                members: [],
                contacts: [],
                totalSelected: 0
            };
            _.forEach(selectedNodes, node => {
                if (node.data.MemberID) {
                    recipients.members.push(node.data.MemberID);
                } else if (node.data.ContactID) {
                    recipients.contacts.push(node.data.ContactID);
                }
            });
            recipients.totalSelected = selectedNodes.length;
            $scope.$broadcast('updateValue', recipients);
        };
        vm.gridFunctions.additionalProperties = function (editable) {
            return {
                getRowNodeId: data => {
                    if (data.MemberID)
                        return `m-${ data.MemberID }`;
                    if (data.ContactID)
                        return `c-${ data.ContactID }`;
                    if (data.UserAccountID)
                        return `u-${ data.UserAccountID }`;
                    return `n-${ data.firstName }-${ data.lastName }-${ data.Email }`;
                }
            };
        };
    }
}());
(function () {
    angular.module('app').directive('creditTransactionsGrid', () => ({
        controller: creditTransactionsGrid,
        controllerAs: 'vm',
        link: (scope, element, attrs, ngModel) => {
            scope.$on('updateValue', function (event, value) {
                if (!ngModel.$viewValue || !_.isEqual(_.sortBy(value.ids), _.sortBy(ngModel.$viewValue.ids))) {
                    ngModel.$setViewValue(value);
                    scope.$parent.$broadcast('updateValue', value);
                }
            }, true);
            ngModel.$isEmpty = function (value) {
                return !value || !value.totalSelected || value.totalSelected === 0;
            };
        },
        templateUrl: 'selectable-grid-container.tpl.html',
        restrict: 'E',
        transclude: true,
        require: '?ngModel',
        scope: {
            ngModel: '<',
            ngDisabled: '<',
            id: '<'
        }
    }));
    function creditTransactionsGrid($scope, $timeout, agGridService, campaignService) {
        const vm = this;
        vm.itemName = 'transaction';
        vm.itemNamePlural = 'transactions';
        vm.gridFunctions = {};
        vm.gridFunctions.getColumnDefs = function (editable) {
            let cols = [{
                    field: 'PaymentId',
                    headerName: 'Payment ID',
                    checkboxSelection: editable,
                    filter: 'agNumberColumnFilter'
                }];
            if (!editable) {
                cols.push({
                    field: 'Amount',
                    filter: false,
                    sortable: false,
                    cellRenderer: function (params) {
                        if (!params.data)
                            return params.value;
                        return params.data.FormattedAmount;
                    }
                });
            } else {
                cols.push({
                    field: 'TransactionAmount',
                    headerName: 'Amount',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: function (params) {
                        if (!params.data)
                            return params.value;
                        return params.data.FormattedTransactionAmount;
                    },
                    filterParams: {
                        cellRenderer: function (params) {
                            return params.data.FormattedTransactionAmount;
                        },
                        filterOptions: agGridService.defaultFilterOptions.number
                    }
                }, {
                    field: 'FirstName',
                    headerName: 'First Name',
                    filter: 'agTextColumnFilter'
                }, {
                    field: 'LastName',
                    headerName: 'Last Name',
                    filter: 'agTextColumnFilter'
                });
            }
            cols.push({
                field: 'RecordId',
                headerName: 'Record ID',
                cellRenderer: agGridService.templates.transactionId,
                filter: 'agNumberColumnFilter'
            }, agGridService.columnDefinitions.boolean({
                field: 'IsDonation',
                headerName: 'Is Donation',
                filter: true,
                sortable: false
            }), agGridService.columnDefinitions.boolean({
                field: 'IsRegistration',
                headerName: 'Is Registration',
                filter: true,
                sortable: false
            }));
            return cols;
        };
        vm.gridFunctions.getDatasource = function (editable, ngModel) {
            let action = editable ? 'AvailableCreditForTransactions' : 'CreditForTransactions';
            return agGridService.getDatasource('Credit', action, () => ({ creditId: $scope.id }), vm.filterData);
        };
        vm.gridFunctions.isNodeSelected = function (data, ngModel) {
            return ngModel && ngModel.ids && ngModel.ids.includes(data.PaymentId);
        };
        vm.gridFunctions.updateSelectedRows = function (selectedNodes) {
            let value = {
                ids: [],
                totalSelected: 0,
                totalTransactionsAmount: 0,
                totalUnfulfilledAmount: 0
            };
            _.forEach(selectedNodes, node => {
                value.ids.push(node.data.PaymentId);
                value.totalTransactionsAmount += node.data.TransactionAmount;
                value.totalUnfulfilledAmount += node.data.UnfulfilledAmount;
            });
            value.totalSelected = selectedNodes.length;
            $scope.$broadcast('updateValue', value);
        };
        vm.gridFunctions.additionalProperties = function (editable) {
            return {
                advancedFiltering: true,
                getRowNodeId: data => data.PaymentId
            };
        };
        vm.filterData = function (items) {
            items.forEach(f => {
                var campaign = campaignService.getCampaignByEventId(f.EventId);
                if (campaign) {
                    f.campaignUrl = campaign.campaignUrl;
                }
            });
            return items;
        };
    }
}());
(function () {
    angular.module('app').directive('goalsGrid', () => ({
        controller: goalsGrid,
        controllerAs: 'vm',
        link: (scope, element, attrs, ngModel) => {
            scope.$on('updateValue', function (event, value) {
                if (!ngModel.$viewValue || !_.isEqual(_.sortBy(value.ids), _.sortBy(ngModel.$viewValue.ids))) {
                    ngModel.$setViewValue(value);
                    scope.$parent.$broadcast('updateValue', value);
                }
            }, true);
            ngModel.$isEmpty = function (value) {
                return !value || !value.totalSelected || value.totalSelected === 0;
            };
        },
        templateUrl: 'selectable-grid-container.tpl.html',
        restrict: 'E',
        transclude: true,
        require: '?ngModel',
        scope: {
            ngModel: '<',
            ngDisabled: '<',
            id: '<'
        }
    }));
    function goalsGrid($scope, $timeout, agGridService, campaignService) {
        const vm = this;
        vm.itemName = 'goal';
        vm.itemNamePlural = vm.itemName + 's';
        vm.gridFunctions = {};
        vm.gridFunctions.getColumnDefs = function (editable) {
            return [
                {
                    field: 'GoalId',
                    headerName: 'Goal ID',
                    checkboxSelection: editable,
                    filter: 'agNumberColumnFilter'
                },
                {
                    field: 'Name',
                    headerName: 'Name',
                    filter: 'agTextColumnFilter'
                },
                {
                    field: 'GoalType',
                    headerName: 'Type',
                    filter: 'agSetColumnFilter',
                    filterParams: {
                        values: [
                            'Member',
                            'Team'
                        ]
                    }
                },
                {
                    field: 'MemberId',
                    headerName: 'Member Id',
                    filter: 'agNumberColumnFilter',
                    hide: true
                },
                {
                    field: 'TeamId',
                    headerName: 'Team Id',
                    filter: 'agNumberColumnFilter',
                    hide: true
                }
            ];
        };
        vm.gridFunctions.getDatasource = function (editable, ngModel) {
            const action = editable ? 'AllGoals' : 'GoalsById';
            const data = {};
            if (!editable) {
                data.goalIds = ngModel.ids;
            }
            return agGridService.getDatasource('Contact', action, () => data, vm.filterData);
        };
        vm.gridFunctions.isNodeSelected = function (data, ngModel) {
            return ngModel && ngModel.ids && ngModel.ids.includes(data.GoalId);
        };
        vm.gridFunctions.updateSelectedRows = function (selectedNodes) {
            let value = {
                ids: [],
                totalSelected: 0
            };
            _.forEach(selectedNodes, node => {
                value.ids.push(node.data.GoalId);
            });
            value.totalSelected = selectedNodes.length;
            $scope.$broadcast('updateValue', value);
        };
        vm.gridFunctions.additionalProperties = function (editable) {
            return {
                advancedFiltering: true,
                getRowNodeId: data => data.GoalId
            };
        };
        vm.gridFunctions.getServerSideStoreType = function (editable) {
            return 'full';
        };
        vm.filterData = function (items) {
            return items;
        };
    }
}());
(function () {
    angular.module('app').directive('selectableGrid', () => ({
        controller: selectableGrid,
        controllerAs: 'vm',
        link: (scope, element, attrs, ngModel) => {
            ngModel.$isEmpty = function (value) {
                return !value || !value.totalSelected || value.totalSelected === 0;
            };
        },
        templateUrl: 'selectable-grid.tpl.html',
        restrict: 'E',
        transclude: true,
        require: '?ngModel',
        scope: {
            ngModel: '<',
            ngDisabled: '<',
            gridFunctions: '<',
            itemName: '@',
            itemNamePlural: '@'
        }
    }));
    function selectableGrid($scope, $timeout, agGridService) {
        const vm = this;
        function getColumnDefs(editable) {
            return $scope.gridFunctions.getColumnDefs(editable);
        }
        function getDatasource(editable) {
            return $scope.gridFunctions.getDatasource(editable, $scope.ngModel);
        }
        function getServerSideStoreType(editable) {
            if ($scope.gridFunctions.getServerSideStoreType) {
                return $scope.gridFunctions.getServerSideStoreType(editable);
            }
            return editable ? 'full' : 'partial';
        }
        function updateSelectedRows(event, editable) {
            if (editable) {
                let selectedNodes = event.api.getSelectedNodes();
                $scope.gridFunctions.updateSelectedRows(selectedNodes);
                let selectedNotDisplayed = false;
                _.forEach(selectedNodes, node => {
                    if (!node.displayed) {
                        selectedNotDisplayed = true;
                    }
                });
                $scope.$apply(() => {
                    vm.selectedNotDisplayed = selectedNotDisplayed;
                    vm.selectedCount = selectedNodes.length;
                    vm.filterApplied = event.api.isAnyFilterPresent();
                });
            }
        }
        function getGrid(editable) {
            var grid = agGridService.createNewGrid(getColumnDefs(editable), {
                sideBar: false,
                datasource: getDatasource(editable),
                pagination: !editable,
                paginationAutoPageSize: true,
                serverSideStoreType: getServerSideStoreType(editable),
                autoSizeOnLoad: true,
                rowSelection: 'multiple',
                rowMultiSelectWithClick: editable
            }, {
                onSelectionChanged: event => updateSelectedRows(event, editable),
                onFilterChanged: event => updateSelectedRows(event, editable),
                onModelUpdated: event => {
                    if (editable) {
                        event.api.forEachNode(node => {
                            if (node.data) {
                                if ($scope.gridFunctions.isNodeSelected(node.data, $scope.ngModel)) {
                                    node.setSelected(true);
                                }
                            }
                        });
                    }
                }
            });
            return _.merge(grid, $scope.gridFunctions.additionalProperties ? $scope.gridFunctions.additionalProperties(editable) : {});
        }
        vm.selectedCount = 0;
        vm.selectedNotDisplayed = false;
        vm.filterApplied = false;
        vm.nonEditableGrid = getGrid(false);
        vm.editableGrid = getGrid(true);
    }
}());
(function () {
    angular.module('app').directive('sideNav', SideNav);
    function SideNav($location, routingService, entityService, configRouteService) {
        return {
            restrict: 'E',
            template: `<ul class="side-nav-list">   
                        <li class="side-nav" ng-repeat="nav in vm.navs" ng-if="canShow(nav)" ng-class="{ active: isActive(nav.path)}">
                            <a ng-link="[nav.name]">
                                {{nav.name}}
                            </a>
                        </li>
                    </ul>`,
            link($scope) {
                $scope.canShow = function (nav) {
                    return configRouteService.hasPermission(nav.path, nav.data) && configRouteService.shouldShow(nav.data);
                };
                $scope.isActive = function (path) {
                    return configRouteService.isActive(path);
                };
            }
        };
    }
}());
(function () {
    angular.module('app').directive('sortable', Sortable);
    function Sortable() {
        return {
            restrict: 'A',
            scope: { sortable: '=' },
            link: function (scope, element, attr) {
                element.sortable(scope.sortable);
            }
        };
    }
}());
(function () {
    angular.module('app').directive('topNavMenu', topNavMenu);
    function topNavMenu() {
        return {
            restrict: 'A',
            link($scope, $element) {
                $element.superfish({
                    cssArrows: false,
                    delay: 0,
                    speed: 'fast'
                });
            }
        };
    }
}());
(function () {
    angular.module('app').directive('topNavMenuItem', TopNavMenuItem);
    function TopNavMenuItem(configRouteService) {
        return {
            restrict: 'A',
            template: `
        <a ng-link="['{{topNavMenuItem}}']">{{topNavMenuItem}}</a>
        <ul ng-click="onTopNavClick($event)">
          <li ng-repeat="item in items" class="top-nav-menu-items"><a ng-link="['{{topNavMenuItem}}', '{{item.name}}']">{{item.name}}</a></li>
        </ul>
      `,
            scope: { topNavMenuItem: '@' },
            link($scope, $document) {
                var topNavConfig = configRouteService.getRouteConfig($scope.topNavMenuItem.toLowerCase());
                $scope.items = topNavConfig && topNavConfig.filter(i => configRouteService.hasPermission(i.path, i.data) && configRouteService.shouldShow(i.data) === true);
                $scope.onTopNavClick = function (element) {
                    angular.element(element.currentTarget).hide();
                };
            }
        };
    }
}());
(function () {
    angular.module('app').directive('touch', Touch);
    function Touch() {
        return function (scope, elem, attrs) {
            var isLongTouch = false;
            let isTap = false;
            let isMove = false;
            var timer;
            var longTouchDuration = 500;
            var touchStartEvent;
            elem.on('touchstart', function (e) {
                isLongTouch = false;
                isTap = false;
                isMove = false;
                timer = setTimeout(onLongTouch, longTouchDuration);
                touchStartEvent = e;
            });
            var onLongTouch = function () {
                if (!isTap && !isMove) {
                    isLongTouch = true;
                    scope.evnt = touchStartEvent;
                    scope.$apply(attrs['touchHold']);
                }
            };
            elem.on('touchend', function (e) {
                //stop touchend event from double-clicking on long touch and next page
                e.preventDefault();
                e.stopPropagation();
                if (timer && !isMove && !isLongTouch) {
                    isTap = true;
                    scope.$apply(attrs['touch']);
                    clearTimeout(timer);
                }
            });
            elem.on('touchmove', function (e) {
                isMove = true;
            });
        };
    }
}());
(function () {
    angular.module('app').directive('customValidation', function () {
        return {
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl) {
                if (attrs.customValidation === 'goalUrl') {
                    ctrl.$validators.goalUrl = function (modelValue, viewValue) {
                        if (ctrl.$isEmpty(modelValue)) {
                            return true;
                        }
                        let regex = /^[a-zA-Z0-9\-]*$/;
                        return String(viewValue).match(regex);
                    };
                }
                if (attrs.customValidation === 'decimal') {
                    ctrl.$validators.decimal = function (modelValue, viewValue) {
                        if (ctrl.$isEmpty(modelValue)) {
                            return true;
                        }
                        let regex = /^\d+(\.\d+)?$/;
                        return String(viewValue).match(regex);
                    };
                }
            }
        };
    });
}());
(function () {
    angular.module('app').filter('activityFilter', ActivityFilter);
    function ActivityFilter() {
        return function (daysLogs, filterParams) {
            let filteredActivities = [], {amountMin, amountMax, types} = filterParams;
            daysLogs.forEach(item => {
                let result = {};
                result.amount = true;
                if (types.size) {
                    result.type = types.has(item.type);
                } else {
                    result.type = false;    //if no types -> hide all except ended
                }
                if (item.type == 'Transactions' || item.type == 'Recurring Transactions') {
                    if (amountMin > item.transactionAmount || amountMax < item.transactionAmount) {
                        result.amount = false;
                    }
                }
                if (result.type && result.amount) {
                    filteredActivities.push(item);
                }
            });
            return filteredActivities;
        };
    }
}());
(function () {
    angular.module('app').filter('campaignsFilter', CampaignsFilter);
    function CampaignsFilter() {
        //multiplying filters for campaigns list
        return function (campaigns, filterParams) {
            let filteredCampaigns = [], {search, types, delisted, ended} = filterParams;
            campaigns.forEach(item => {
                let result = {};
                if (search) {
                    let searchRequest = filterParams.search.toLowerCase(), campaignData = [
                            item.name,
                            item.description,
                            item.url
                        ];
                    let searchResults = campaignData.map(item => {
                        if (item != null) {
                            return item.toLowerCase().includes(searchRequest);
                        }
                    });
                    result.search = searchResults[0] || searchResults[1] || searchResults[2];
                } else {
                    result.search = true;
                }
                if (types.size) {
                    result.type = types.has(item.type);
                } else {
                    result.type = false;    //if no types -> hide all except ended
                }
                if (delisted.size) {
                    result.delisted = delisted.has(item.delisted);
                } else {
                    result.delisted = false;    //if no delisted/published -> hide all except ended
                }
                result.ended = item.archived;
                if (result.type && result.delisted && result.search && !result.ended || result.type && result.search && ended && result.ended) {
                    filteredCampaigns.push(item);
                }
            });
            return filteredCampaigns;
        };
    }
}());
(function () {
    angular.module('app').filter('chunkArray', ChunkArray);
    function ChunkArray() {
        /* Create 2d array with %chunkSize% columns from flat array */
        return (inputArray, chunkSize) => {
            let result = [];
            let currentRow = 0;
            for (let i = 0; i < inputArray.length;) {
                result[currentRow] = [];
                for (let j = 0; j < chunkSize && i < inputArray.length; j++, i++) {
                    result[currentRow][j] = inputArray[i];
                }
                currentRow++;
            }
            return result;
        };
    }
}());
(function () {
    angular.module('app').filter('groupsFilter', GroupsFilter);
    function GroupsFilter() {
        //multiplying filters for groups list
        return function (groups, filterParams) {
            let filteredGroups = [], {search, types, delisted, ended} = filterParams;
            groups.forEach(item => {
                let result = {}, filteredCampaigns = [];
                if (search) {
                    result.search = item.name.toLowerCase().includes(filterParams.search.toLowerCase());
                } else {
                    result.search = true;
                }
                if (result.search) {
                    filteredGroups.push(item);
                }
            });
            return filteredGroups;
        };
    }
}());
(function () {
    angular.module('app').filter('hasFlag', HasFlagFilter);
    function HasFlagFilter() {
        return function (value, flag) {
            return (value & flag) === flag;
        };
    }
}());
(function () {
    angular.module('app').filter('loadMoreFilter', LoadMoreFilter);
    function LoadMoreFilter() {
        return function (inputArray, filterParamCount) {
            return inputArray.slice(0, filterParamCount);
        };
    }
}());
(function () {
    angular.module('app').filter('objectEmpty', ObjectEmptyFilter);
    angular.module('app').filter('objectNotEmpty', ObjectNotEmptyFilter);
    function ObjectEmptyFilter() {
        return function (o) {
            return isObjectEmpty(o);
        };
    }
    function ObjectNotEmptyFilter() {
        return function (o) {
            return !isObjectEmpty(o);
        };
    }
    function isObjectEmpty(o) {
        return o && Object.keys(o).length === 0;
    }
}());
(function () {
    angular.module('app').filter('recurringPeriodFilter', RecurringPeriodFilter);
    function RecurringPeriodFilter() {
        var recurringPeriodStrings = {
            0: '',
            1: 'daily',
            2: 'weekly',
            3: 'monthly',
            4: 'quarterly',
            5: 'annually'
        };
        return function (recurringPeriod) {
            return recurringPeriodStrings[recurringPeriod];
        };
    }
}());
(function () {
    angular.module('app').factory('administratorService', administratorService);
    function administratorService($http, $q, API_PREFIX, entityService, campaignService) {
        let allAdmins = [];
        let betaOptions = [
            46,
            42,
            41,
            10002
        ];
        return {
            getAdmins,
            getAdminsFromOrg,
            updateAllAdmins,
            addToAllAdmins,
            getAllRoles,
            getAllOrgAdmins,
            getAllOrgAdminsNamesEmails,
            updateAdminsCurrentRoleIds,
            inviteAdmin,
            removeAdmin,
            getSecurityRoles,
            updateSecurityRole,
            createSecurityRole,
            deleteSecurityRole,
            getSecurityPermissionName,
            removeAdminFromOrg,
            removeAdminFromEntity,
            addAdminToCompanies
        };
        function getAdmins(curEntityId) {
            let orgEntityId = entityService.getOrgEntityId(), entityId = curEntityId || orgEntityId;
            return $http({
                url: API_PREFIX + 'Admins/GetAdminsOnEntity',
                headers: { entityId: entityId },
                method: 'POST'
            }).then(response => {
                if ('msg' in response.data && response.data.success)
                    return [];
                //when no admins this is ok
                return _buildAdditionalRoles(response.data.data, orgEntityId, entityId);
            });
        }
        function _buildAdditionalRoles(admins, orgEntityId, entityId) {
            return admins.map(admin => {
                let groupsPath = campaignService.getGroupsPath().map(g => g.entityId);
                admin.roleByEntity = admin.roleById.filter(role => {
                    if (orgEntityId == entityId)
                        return false;
                    return role.entityId !== entityId && // remove current role from list
                    _.includes(groupsPath, role.entityId) || role.entityId == orgEntityId    // only parent groups and org
;
                }).map(role => ({
                    roleId: role.roleId,
                    entityId: role.entityId,
                    entityName: entityService.getEntityName(role.entityId)
                }));
                return admin;
            });
        }
        function getAllOrgAdmins() {
            let orgEntityId = entityService.getOrgEntityId();
            return $http({
                url: API_PREFIX + 'Admins/GetAdminsOnOrg',
                headers: { orgId: entityService.getOrgId() },
                method: 'POST'
            }).then(response => {
                let admins = response.data.data || [];
                allAdmins = admins;
                admins.forEach(admin => {
                    admin = updateAdminsCurrentRoleIds(admin);
                });
                return admins;
            });
        }
        function getAllOrgAdminsNamesEmails(curEntityId) {
            return $http({
                url: API_PREFIX + 'Admins/GetOrgAdminsNamesAndEmails',
                headers: { entityId: curEntityId },
                method: 'POST'
            }).then(response => {
                let admins = response.data.data || [];
                return admins;
            });
        }
        function updateAdminsCurrentRoleIds(admin) {
            let orgEntityId = entityService.getOrgEntityId();
            let rolesMap = admin.roleById.reduce((map, role) => {
                map[role.entityId] = role.roleId;
                return map;
            }, {});
            admin.campaigns = admin.campaigns.map(campaign => {
                return Object.assign({}, campaignService.getCampaignByEntityId(campaign.entityId), { currentRoleId: rolesMap[campaign.entityId] });
            });
            admin.groups = admin.groups.map(group => {
                return Object.assign({}, campaignService.getGroupByEntityId(group.entityId), { currentRoleId: rolesMap[group.entityId] });
            });
            admin.entireOrgRole = {};
            if (rolesMap[orgEntityId]) {
                admin.entireOrgRole = {
                    name: 'Entire Organization',
                    entityType: 'Org',
                    entityId: orgEntityId,
                    currentRoleId: rolesMap[orgEntityId]
                };
            }
            return admin;
        }
        function getAdminsFromOrg() {
            return allAdmins;
        }
        function updateAllAdmins(admin, index, remove) {
            if (remove) {
                for (var i = 0; i < allAdmins.length; i++) {
                    if (allAdmins[i].id == admin.id) {
                        allAdmins.splice(i, 1);
                        break;
                    }
                }
            } else {
                //if list on admins page didn't update from a search the index will be the same
                if (allAdmins[index].id == admin.id) {
                    allAdmins[i] = admin;
                } else {
                    //have to loop through all
                    for (var i = 0; i < allAdmins.length; i++) {
                        if (allAdmins[i].id == admin.id) {
                            allAdmins[i] = admin;
                            break;
                        }
                    }
                }
            }
        }
        function addToAllAdmins(admin) {
            allAdmins.push(admin);
        }
        function getAllRoles(curEntityId) {
            if (!curEntityId) {
                curEntityId = entityService.getOrgEntityId();
            }
            return $http({
                url: API_PREFIX + 'Admins/GetAllRoles',
                headers: {
                    orgId: entityService.getOrgId(),
                    entityId: curEntityId
                },
                method: 'POST'
            })    //method is sometimes called without org in URL
.then(response => response.data.data);
        }
        function inviteAdmin(emails, roles, curEntityId, entityIds, mailMessage, messageConfig) {
            let orgEntityId = entityService.getOrgEntityId(), entityId = curEntityId || orgEntityId;
            if (!entityIds || !entityIds.length) {
                entityIds = [entityId];
            }
            return $http({
                url: API_PREFIX + 'Admins/InviteNewAdmin',
                data: {
                    emails: emails,
                    userRoleToEntity: roles,
                    mailMessage
                },
                headers: { entityIds },
                method: 'POST',
                messageConfig
            }).then(response => {
                //if (entityIds.length == 1 && entityIds[0] == entityId) {
                //	return _buildAdditionalRoles(response.data.data.data[0], orgEntityId, entityId)
                //}
                return response.data;
            });
        }
        //todo what's this vs removeAdminFromEntity
        //and not passing in any entityId?
        function removeAdmin(userId, curEntityId) {
            let orgEntityId = entityService.getOrgEntityId(), entityId = curEntityId || orgEntityId;
            return $http({
                url: API_PREFIX + 'Admins/RemoveAdmin',
                data: { userId },
                headers: {
                    entityId: entityId,
                    orgId: entityService.getOrgId()
                },
                method: 'POST'
            });
        }
        //there was a backend method removeAdminFromEntity. todo
        function removeAdminFromEntity(userId, curEntityId) {
            let orgEntityId = entityService.getOrgEntityId(), entityId = curEntityId || orgEntityId;
            return $http({
                url: API_PREFIX + 'Admins/RemoveAdminFromEntity',
                data: { userId },
                headers: {
                    entityId: entityId,
                    orgId: entityService.getOrgId()
                },
                method: 'POST'
            }).then(response => {
                return _buildAdditionalRoles(response.data.data, orgEntityId, entityId);
            });
        }
        //todo why not use removeAdmin here too
        function removeAdminFromOrg(userId) {
            return $http({
                url: API_PREFIX + 'Admins/RemoveAdminFromOrg',
                data: { userId },
                headers: { orgId: entityService.getOrgId() },
                method: 'POST'
            });
        }
        function addAdminToCompanies(email, entityId, roleId) {
            let orgEntityId = entityService.getOrgEntityId();
            return $http({
                url: API_PREFIX + 'Admins/SetListRoleToAdmin',
                data: {
                    email: email,
                    roleId: roleId
                },
                headers: { entityId: entityId },
                method: 'POST'
            })    //SECURITY
.then(response => {
                return response;
            });
        }
        function isNotBetaResource(value) {
            return $.inArray(value.resourceId, betaOptions) == -1;
        }
        function getSecurityRoles() {
            return $http({
                url: API_PREFIX + 'Admins/GetSecurityRoles',
                headers: { orgId: entityService.getOrgId() },
                method: 'POST'
            }).then(response => {
                let {resources, roles} = response.data.data;
                //filter beta resources
                resources = resources.filter(isNotBetaResource);
                resources.forEach(res => {
                    res.childs = res.childs.filter(isNotBetaResource);
                });
                roles = roles.map(role => {
                    let resourcePermissionsMap = role.securityPermissions.reduce((map, permission) => {
                        //return Object.assign(map, {
                        //  [permission.resourceId]: permission.permissionId
                        //});
                        map[permission.resourceId] = permission.permissionId;
                        return map;
                    }, {});
                    role.resources = resources.map(parentResource => {
                        const resourceMapper = resource => {
                            return Object.assign({}, resource, { permissionId: resourcePermissionsMap[resource.resourceId] || 0 });
                        };
                        parentResource = resourceMapper(parentResource);
                        parentResource.childs = parentResource.childs.map(resourceMapper);
                        // has childs
                        if (parentResource.childs.length) {
                            // any of the child's permissionsId differs from first child
                            if (parentResource.childs.some(childResource => childResource.permissionId !== parentResource.childs[0].permissionId)) {
                                parentResource.permissionId = -1;    // 'Specific...'
                            } else {
                                parentResource.permissionId = parentResource.childs[0].permissionId;
                            }
                        }
                        return parentResource;
                    });
                    let permissionIds = [], rolePermissionId;
                    role.resources.forEach(parentResource => {
                        permissionIds.push(parentResource.permissionId);
                        function checkIfEqual() {
                            let first = permissionIds[0];
                            return permissionIds.every(function (element) {
                                return element === first;
                            });
                        }
                        ;
                        if (checkIfEqual() == true) {
                            rolePermissionId = permissionIds[0];
                        } else {
                            rolePermissionId = -1;
                        }
                    });
                    role.permissionId = rolePermissionId;
                    return role;
                });
                return {
                    resources,
                    roles
                };
            });
        }
        ;
        function updateSecurityRole(role) {
            let reqData = _mapRoleToSaveModel(role);
            return $http({
                url: API_PREFIX + 'Admins/UpdateSecurityRole',
                data: reqData,
                method: 'POST'
            });
        }
        function createSecurityRole(role) {
            let reqData = _mapRoleToSaveModel(role);
            return $http({
                url: API_PREFIX + 'Admins/CreateSecurityRole',
                data: reqData,
                method: 'POST'
            });
        }
        function deleteSecurityRole(role) {
            return $http({
                url: API_PREFIX + 'Admins/DeleteSecurityRole',
                data: { roleId: role.roleId },
                method: 'POST'
            });
        }
        function getSecurityPermissionName(permissionId) {
            let id = permissionId.toString();
            const map = {
                '-1': 'Various',
                '0': 'None',
                '1': 'Can view',
                '3': 'Can edit',
                '7': 'Can delete'
            };
            return map[id];
        }
        function _mapRoleToSaveModel(role) {
            let securityPermissions = [];
            const mapResourceToServerModel = resource => ({
                securityRoleId: role.roleId,
                securityResourceId: resource.resourceId,
                permissionId: resource.permissionId == -1 ? 0 : resource.permissionId
            });
            role.resources.forEach(parentResource => {
                securityPermissions.push(mapResourceToServerModel(parentResource));
                parentResource.childs.forEach(childResource => {
                    securityPermissions.push(mapResourceToServerModel(childResource));
                });
            });
            return {
                roleName: role.roleName,
                securityPermissions
            };
        }
    }
}());
(function () {
    agGrid.initialiseAgGridWithAngular1(angular);
    agGrid.LicenseManager.setLicenseKey('CompanyName=Rallybound,LicensedApplication=Rallybound,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-007910,ExpiryDate=27_April_2021_[v2]_MTYxOTQ3ODAwMDAwMA==e94e54430d82e29e50ab9d7af9fa43f8');
    angular.module('app').service('agGridService', agGridService);
    function agGridService($http, $q, API_PREFIX, $templateCache, entityService, $timeout, $compile, $rootScope, profileService) {
        const vm = this;
        this.createNewGrid = function (columnDefs, options, callbacks) {
            var gridOptions = {
                openRow: undefined,
                columnStateLoaded: false,
                firstDataLoaded: false
            };
            // Callbacks do not correspond directly to agGrid callbacks
            //   For example agGrid has a single callback for opened/closed groups: onRowGroupOpened
            //   Here the callback is split onRowGroupOpened and onRowGroupClosed
            if (!callbacks)
                callbacks = {};
            var defaults = {
                angularCompileRows: true,
                columnDefs: columnDefs,
                tooltipShowDelay: 400,
                enableRangeSelection: true,
                suppressPropertyNamesCheck: true,
                onGridReady: params => {
                    let gridOptions = params.api.gridOptionsWrapper.gridOptions;
                    if (gridOptions.key) {
                        params.api.showLoadingOverlay();
                        profileService.getAgGridSetting(gridOptions.key).then(setting => {
                            if (setting) {
                                let state = JSON.parse(setting);
                                params.columnApi.applyColumnState({
                                    state,
                                    applyOrder: true
                                });
                                gridOptions.appliedColumnState = state;
                            }
                            if (gridOptions.firstDataLoaded && gridOptions.totalResults == 0) {
                                params.api.showNoRowsOverlay();
                            } else {
                                params.api.hideOverlay();
                            }
                            gridOptions.columnStateLoaded = true;
                        });
                    } else {
                        gridOptions.columnStateLoaded = true;
                    }
                    callbacks.onGridReady && callbacks.onGridReady(params);
                },
                onFirstDataRendered: params => {
                    gridOptions.firstDataLoaded = true;
                    callbacks.onFirstDataRendered && callbacks.onFirstDataRendered(params);
                },
                onRowGroupOpened: params => {
                    if (params.node.expanded) {
                        // Use a timeout to ensure the openRow is set in the next angular digest
                        //  This ensures that openRow is reset to undefined for the previous row being closed
                        //  in case any components need to deconstruct when a row is closed
                        $timeout(() => {
                            gridOptions.openRow = params;
                            if (gridOptions.expandedCardElement) {
                                let gridRow = gridOptions.getRowDomElement(params.rowIndex);
                                gridOptions.expandedCardElement.css('transform', gridRow.get(0).style.transform);
                            }
                            callbacks.onRowGroupOpened && callbacks.onRowGroupOpened(params);
                        });
                    } else {
                        gridOptions.openRow = undefined;
                        callbacks.onRowGroupClosed && callbacks.onRowGroupClosed(params);
                    }
                },
                onDisplayedColumnsChanged: event => {
                    vm.updateSavedColumnState(event);
                },
                onColumnResized: event => {
                    if (event.source === 'uiColumnDragged' && event.finished) {
                        vm.updateSavedColumnState(event);
                    }
                },
                onSortChanged: event => {
                    gridOptions.closeOpenRow();
                    vm.updateSavedColumnState(event);
                    callbacks.onSortChanged && callbacks.onSortChanged(event);
                },
                onFilterChanged: params => {
                    gridOptions.closeOpenRow();
                    callbacks.onFilterChanged && callbacks.onFilterChanged(params);
                },
                onSelectionChanged: params => {
                    callbacks.onSelectionChanged && callbacks.onSelectionChanged(params);
                },
                onModelUpdated: params => {
                    if (gridOptions.openRow) {
                        let id = gridOptions.openRow.node.id;
                        let node = gridOptions.api.getRowNode(id);
                        if (node) {
                            node.setExpanded(true);
                        }
                    }
                    if (gridOptions.api.gridOptionsWrapper.gridOptions.totalServerRows == 0) {
                        gridOptions.api.showNoRowsOverlay();
                    } else if (gridOptions.columnStateLoaded) {
                        gridOptions.api.hideOverlay();
                    }
                    if (gridOptions.api.gridOptionsWrapper.gridOptions.totalServerRows != undefined) {
                        gridOptions.totalResults = gridOptions.api.gridOptionsWrapper.gridOptions.totalServerRows;
                    }
                    if (gridOptions.autoSizeOnLoad) {
                        gridOptions.autoSizeAll();
                    }
                    callbacks.onModelUpdated && callbacks.onModelUpdated(params);
                },
                onRowDataChanged: params => {
                    if (!gridOptions.api.gridOptionsWrapper.gridOptions.datasource) {
                        gridOptions.totalResults = gridOptions.api.getDisplayedRowCount();
                    }
                    callbacks.onRowDataChanged && callbacks.onRowDataChanged(params);
                },
                onRowClicked: function (params) {
                    if (params.api.gridOptionsWrapper.gridOptions.expandOnClick) {
                        let shouldCloseOpenRow = false;
                        let openRowClosedCallbackPromise = $q.resolve();
                        if (params.node.detail) {
                            return;
                        }
                        if (gridOptions.openRow && gridOptions.openRow.node.id !== params.node.id) {
                            shouldCloseOpenRow = true;
                            if (callbacks.onOpenRowClosed) {
                                openRowClosedCallbackPromise = callbacks.onOpenRowClosed(params, gridOptions.openRow);
                            }
                        }
                        openRowClosedCallbackPromise.then(() => {
                            if (shouldCloseOpenRow) {
                                gridOptions.openRow.node.setExpanded(false);
                            }
                            params.node.setExpanded(true);
                        }).catch(angular.noop);
                    }
                    callbacks.onRowClicked && callbacks.onRowClicked(params);
                },
                closeOpenRow: function () {
                    if (gridOptions.openRow) {
                        gridOptions.openRow.node.setExpanded(false);
                        gridOptions.openRow = undefined;
                    }
                    callbacks.onOpenRowClosed && callbacks.onOpenRowClosed();
                },
                getRowDomElement: function (rowIndex) {
                    if (!gridOptions.gridElement)
                        return;
                    return gridOptions.gridElement.find(`.ag-row[row-index="${ rowIndex }"]`).first();
                },
                sideBar: {
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                            toolPanelParams: {
                                suppressRowGroups: false,
                                suppressValues: true,
                                suppressPivotMode: true
                            }
                        },
                        {
                            id: 'filters',
                            labelDefault: 'Filters',
                            labelKey: 'filters',
                            iconKey: 'filter',
                            toolPanel: 'agFiltersToolPanel'
                        }
                    ]
                },
                autoSizeAll: function (skipHeader) {
                    var allColumnIds = [];
                    gridOptions.columnApi.getAllColumns().forEach(function (column) {
                        allColumnIds.push(column.colId);
                    });
                    gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
                },
                clearFilters: function () {
                    gridOptions.api.setFilterModel(null);
                },
                selectAll: function () {
                    gridOptions.api.forEachNode(node => node.setSelected(true));
                },
                selectOnlyVisible: function () {
                    gridOptions.api.forEachNode(node => node.setSelected(node.displayed));
                },
                selectAllVisible: function () {
                    gridOptions.api.forEachNode(node => {
                        if (node.displayed) {
                            node.setSelected(true);
                        }
                    });
                },
                deselectAll: function () {
                    gridOptions.api.forEachNode(node => node.setSelected(false));
                },
                deselectAllVisible: function () {
                    gridOptions.api.forEachNode(node => {
                        if (node.displayed) {
                            node.setSelected(false);
                        }
                    });
                },
                deselectNonVisible: function () {
                    gridOptions.api.forEachNode(node => {
                        if (!node.displayed) {
                            node.setSelected(false);
                        }
                    });
                },
                saveColumnState: _.debounce(vm.saveColumnState, 400),
                components: { noRowsOverlay: vm.noRowsOverlay() },
                noRowsOverlayComponent: 'noRowsOverlay',
                noRowsOverlayComponentParams: { extraText: options.noRowsMessage || '' },
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    autoHeight: true,
                    filter: true,
                    enableRowGroup: true,
                    tooltipValueGetter: params => {
                        return params.value;
                    },
                    filterParams: {}
                },
                totalResults: 0
            };
            if (options.expandedCardTemplate || options.expandedCardElement) {
                _.merge(defaults, {
                    masterDetail: true,
                    detailCellRenderer: 'expandedCardRenderer',
                    detailRowAutoHeight: true,
                    components: { expandedCardRenderer: vm.genericTemplateCellRenderer(options.expandedCardTemplate, options.expandedCardElement) },
                    expandOnClick: true
                });
            }
            if (options.datasource) {
                _.merge(defaults, {
                    rowModelType: 'serverSide',
                    serverSideStoreType: 'partial',
                    pagination: true,
                    paginationPageSize: 15,
                    cacheBlockSize: 30,
                    serverSideDatasource: options.datasource,
                    maxConcurrentDatasourceRequests: 1,
                    defaultColDef: {
                        filter: false,
                        enableRowGroup: false,
                        filterParams: { suppressAndOrCondition: true }
                    }
                });
                if (!options.serverSideStoreType || options.serverSideStoreType === 'partial') {
                    _.merge(defaults, {
                        defaultColDef: {
                            filterParams: {
                                buttons: [
                                    'apply',
                                    'reset'
                                ],
                                closeOnApply: true
                            }
                        }
                    });
                }
                if (!options.advancedFiltering) {
                    _.merge(defaults, {
                        suppressMultiSort: true,
                        defaultColDef: { filterParams: { filterOptions: ['contains'] } }
                    });
                }
            }
            if (options.pagination || options.datasource && options.serverSideStoreType !== 'full') {
                _.merge(defaults, {
                    components: { paginationStatusBar: vm.paginationStatusBar() },
                    statusBar: { statusPanels: [{ statusPanel: 'paginationStatusBar' }] }
                });
            }
            return _.merge(gridOptions, defaults, options);
        };
        this.defaultFilterOptions = {
            text: [
                'contains',
                'notContains',
                'equals',
                'notEqual',
                'startsWith',
                'endsWith'
            ],
            number: [
                'equals',
                'notEqual',
                'lessThan',
                'lessThanOrEqual',
                'greaterThan',
                'greaterThanOrEqual',
                'inRange'
            ],
            date: [
                'equals',
                'lessThan',
                'greaterThan',
                'notEqual',
                'inRange'
            ]
        };
        this.formatters = {
            dateTime: function (params) {
                var date = moment(params.value);
                return date.isValid() ? date.format('MM/D/YYYY hh:mm a') : '';
            },
            capitalizeFirstLetter: function (params) {
                return params.value ? params.value.charAt(0).toUpperCase() + params.value.slice(1) : '';
            },
            emailProcessedMessage: function (params) {
                switch (params.value.toString()) {
                case '100':
                    return 'Successful';
                case '200':
                    return 'Unsuccessful';
                case '201':
                    return 'Network Failure (Rescheduled)';
                case '300':
                    return 'Opted Out';
                case '301':
                    return 'Template Disabled';
                default:
                    return '';
                }
            }
        };
        this.templates = {
            boolean: function (params) {
                return `
                <i class="text-medium ag-icon ag-icon-${ params.value && params.value !== 'No' ? 'tick text-green' : 'not-allowed text-red' }"></i>
            `;
            },
            booleanImage: function (params) {
                return `
                <img ng-src="/Content/Admin/extjs/resources/themes/images/default/dd/drop-${ params.value ? 'yes' : 'no' }.gif" />
            `;
            },
            salesforce: function (params) {
                const field = params.colDef.colId;
                return `
                <a ng-if="data.${ field }" ng-href="https://login.salesforce.com/{{ data.${ field } }}" ng-bind="data.${ field }" target="_blank"></a>
            `;
            },
            donationId: function (params) {
                return `
                <span ng-bind="!data.DonationId ? '0' : ''"></span>
                <a ng-if="data.DonationId" ng-href="{{ data.campaignUrl }}/transactions/donations?type=Donation&value={{ data.DonationId }}" ng-bind="data.DonationId" target="_blank"></a>
            `;
            },
            transactionId: function (params) {
                return `
                <a ng-if="data.DonationId != 0" ng-href="{{ data.campaignUrl }}/transactions/donations?type=Donation&value={{ data.DonationId }}" ng-bind="data.DonationId" target="_blank"></a>                
                <a ng-if="data.RegistrationId != 0" ng-href="{{ data.campaignUrl}}/people/registrants?type=Registrant&value={{ data.DonorId }}" target="_blank" ng-bind="data.RegistrationId"></a>
                <a ng-if="data.MemberRoleId != 0" ng-href="{{ data.campaignUrl }}/people/registrants?type=Registrant&value={{ data.DonorId }}" target="_blank" ng-bind="data.MemberRoleId"></a>
            `;
            },
            status: function (params) {
                return `
                <span ng-if="data.Status === 'retrying'">Retrying</span>
                <img ng-if="data.Status !== 'retrying'" ng-src="/Content/Admin/extjs/resources/themes/images/default/dd/drop-{{ data.Status === 'successful' ? 'yes' : 'no' }}.gif" />
            `;
            },
            userAccountId: function (params) {
                return `
                <a ng-if="data.SyncType === 'Registration' && data.UserAccountId" ng-href="{{ data.campaignUrl}}/people/registrants?type=Registrant&value={{ data.UserAccountId }}" target="_blank" ng-bind="data.UserAccountId"></a>
                <a ng-if="data.SyncType === 'MemberRolesSubmission' && data.UserAccountId" ng-href="{{ data.campaignUrl }}/people/registrants?type=Registrant&value={{ data.MemberId }}" target="_blank" ng-bind="data.UserAccountId"></a>
                <a ng-if="data.SyncType === 'Donation' && data.UserAccountId" ng-href="{{ data.campaignUrl }}/people/donors?type=Donor&value={{ data.MemberId }}" target="_blank" ng-bind="data.UserAccountId"></a>

                <span ng-bind="!data.UserAccountId || (data.SyncType !== 'Registration' && data.SyncType !== 'Donation' && data.SyncType !== 'MemberRolesSubmission') ? (data.UserAccountId || 0) : ''"></span>
            `;
            },
            userId: function (params) {
                return `
                <a ng-if="data.SyncType === 'Registration' && data.UserId" ng-href="{{ data.campaignUrl }}/people/registrants?type=Registrant&value={{ data.UserId }}" target="_blank" ng-bind="data.UserId"></a>

                <a ng-if="data.SyncType === 'Donation' && data.UserId" ng-href="{{ data.campaignUrl }}/people/donors?type=Donor&value={{ data.MemberId }}" target="_blank ng-bind="data.UserId"></a>

                <span ng-bind="!data.UserId || (data.SyncType !== 'Registration' && data.SyncType !== 'Donor') ? (data.UserId || 0) : ''"></span>
            `;
            },
            teamId: function (params) {
                return `
                <span ng-bind="!data.TeamId ? '0' : ''"></span>
                <a ng-if="data.TeamId" ng-href="{{ data.campaignUrl }}/people/teams?type=Team&value={{ data.TeamId }}" ng-bind="data.TeamId" target="_blank"></a>
            `;
            },
            memberRolesSubmissionId: function (params) {
                return `
                <span ng-bind="!data.MemberRolesSubmissionId ? '0' : ''"></span>
                <div ng-if="data.MemberRolesSubmissionId" ng-bind="data.MemberRolesSubmissionId"></div>
            `;
            },
            registrationId: function (params) {
                return `
                <span ng-bind="!data.MemberId ? '0' : ''"></span>
                <div ng-if="data.MemberId" ng-bind="data.MemberId"></div>
            `;
            },
            resync: function (params) {
                return `
                <div ng-if="data.canResync" ng-click="vm.resyncWithCrm(data)">
                    <img src="/Content/Admin/images/icons/Resync_16x16.png" />
                </div>
            `;
            },
            resendEmail: function (params) {
                return `
                <div ng-click="vm.resendEmail(data)">
                    <img src="/Content/Admin/images/icons/Resync_16x16.png" />
                </div>
            `;
            }
        };
        this.columnDefinitions = {
            boolean: (properties = {}, strings = {
                true: 'Yes',
                false: 'No'
            }) => {
                var definition = {
                    cellRenderer: this.templates.boolean,
                    tooltipValueGetter: params => params.data[params.colDef.field] ? strings.true : strings.false,
                    filterParams: {
                        suppressMiniFilter: true,
                        suppressSelectAll: true,
                        defaultToNothingSelected: true,
                        values: [
                            true,
                            false
                        ],
                        valueFormatter: params => params.value === 'true' ? strings.true : strings.false
                    }
                };
                return _.merge(definition, properties);
            }
        };
        this.latestSyncsOnlyFilter = function () {
            return class LatestSyncsOnlyFilter {
                constructor() {
                }
                init(params) {
                    this.params = params;
                    this.checked = false;
                    // default false: not showing all syncs (only Latest)
                    this.setupGui(params);
                }
                // not called by ag-Grid, just for us to help setup
                setupGui(params) {
                    this.gui = document.createElement('div');
                    this.gui.innerHTML = `<label class="ag-set-filter-item">
                        <!--AG-CHECKBOX-->
                        <div role="presentation" ref="eCheckbox" class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                            <label ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-checkbox-label" for="latestSyncsOnlyCheckbox"></label>
                            <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" role="presentation">
                                <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox" id="latestSyncsOnlyCheckbox">
                            </div>
                        </div>
                        <span class="ag-set-filter-item-value">Show All Attempts</span>
                    </label>`;
                    this.checkbox = this.gui.querySelector('#latestSyncsOnlyCheckbox');
                    this.checkbox.addEventListener('change', checkboxListener);
                    var that = this;
                    function checkboxListener(event) {
                        that.checked = event.target.checked;
                        that.checkbox.parentElement.classList.toggle('ag-checked');
                        params.filterChangedCallback();
                    }
                }
                getGui() {
                    return this.gui;
                }
                doesFilterPass(params) {
                    return params.data.Latest || this.checked;
                }
                isFilterActive() {
                    return this.checked != null;
                }
                getModel() {
                    return { value: this.checked };
                }
                setModel(model) {
                    this.checked = model;
                }
            };
        };
        this.runGridFilteringForLatest = function (vm) {
            vm.gridOptions.api.getFilterInstance('Latest');
            vm.gridOptions.api.onFilterChanged();
        };
        this.getDatasource = function (controller, endpoint, data, filterData) {
            return {
                getRows: function (params) {
                    let entityId = entityService.getOpenedEntityId();
                    let requestData = {};
                    _.merge(requestData, typeof data === 'function' ? data() : data);
                    requestData.gridParams = params.request;
                    $http({
                        url: `${ API_PREFIX }${ controller }/${ endpoint }`,
                        headers: { entityId },
                        data: requestData,
                        method: 'POST'
                    }).then(response => {
                        params.api.gridOptionsWrapper.setProperty('totalServerRows', response.data.totalCount);
                        if (filterData) {
                            response.data.items = filterData(response.data.items);
                        }
                        params.success({
                            rowData: response.data.items,
                            rowCount: response.data.totalCount
                        });
                    }).catch(() => {
                        params.fail();
                    });
                }
            };
        };
        this.updateSavedColumnState = function (event) {
            let gridOptions = event.api.gridOptionsWrapper.gridOptions;
            if (!gridOptions.key) {
                return;
            }
            if (!gridOptions.columnStateLoaded) {
                return;
            }
            gridOptions.saveColumnState(event);
        };
        this.saveColumnState = function (event) {
            let gridOptions = event.api.gridOptionsWrapper.gridOptions;
            let newState = event.columnApi.getColumnState();
            if (!gridOptions.appliedColumnState || !_.isEqual(gridOptions.appliedColumnState, newState)) {
                profileService.updateAgGridSetting(gridOptions.key, newState);
                gridOptions.appliedColumnState = newState;
            }
        };
        this.noRowsOverlay = function () {
            return class CustomNoRowsOverlay {
                constructor() {
                }
                init(params) {
                    function clearFilter() {
                    }
                    this.eGui = document.createElement('div');
                    this.eGui.className = 'ag-overlay-loading-center ';
                    if (params.api.isAnyFilterPresent()) {
                        this.eGui.innerHTML = `No results match the currently selected filters. <button class="btn btn-secondary">Clear Filter</button>`;
                        this.eGui.className += 'no-filters-match';
                        this.button = this.eGui.querySelector('button');
                        this.button.addEventListener('click', () => {
                            params.api.setFilterModel(null);
                            params.api.onFilterChanged();
                        });
                    } else {
                        this.eGui.innerHTML = `There's nothing here yet. <span>${ params.extraText }</span>`;
                        this.eGui.className += 'no-results';
                    }
                }
                getGui() {
                    return this.eGui;
                }
            };
        };
        this.genericTemplateCellRenderer = function (template, element) {
            return class GenericTemplateCellRenderer {
                constructor() {
                }
                init(params) {
                    this.eGui = document.createElement('div');
                    // Use a template rendered inline
                    if (template) {
                        this.eGui.innerHTML = $templateCache.get(`${ template }.tpl.html`);
                    }    // Track external element
                    else if (element && element[0]) {
                        this.dummyRow = document.createElement('div');
                        this.dummyRow.className = 'ag-record-row-dummy';
                        this.eGui.append(this.dummyRow);
                        new ResizeObserver(() => {
                            this.dummyRow.style.height = element.height() + 'px';
                        }).observe(element[0]);
                    }
                }
                getGui() {
                    return this.eGui;
                }
            };
        };
        this.paginationStatusBar = function () {
            return class ClickableStatusBarComponent {
                constructor() {
                }
                init(params) {
                    this.scope = $rootScope.$new();
                    this.scope.api = params.api;
                    this.scope.pagination = {
                        currentPage: () => params.api.paginationGetCurrentPage(),
                        totalPages: () => params.api.paginationGetTotalPages(),
                        isFirstPage: () => params.api.paginationGetCurrentPage() === 0,
                        isLastPage: () => params.api.paginationGetCurrentPage() + 1 === params.api.paginationGetTotalPages(),
                        firstRecordShown: () => _.clamp(params.api.paginationGetPageSize() * params.api.paginationGetCurrentPage() + 1, 0, params.api.paginationGetRowCount()),
                        lastRecordShown: () => _.clamp(params.api.paginationGetPageSize() * (params.api.paginationGetCurrentPage() + 1), 0, params.api.paginationGetRowCount()),
                        totalRecords: () => params.api.paginationGetRowCount(),
                        hasNoRecords: () => params.api.paginationGetRowCount() === 0
                    };
                    this.eGui = $compile($templateCache.get(`pagination.tpl.html`))(this.scope);
                }
                getGui() {
                    return this.eGui.get(0);
                }
                destroy() {
                    this.scope.$destroy();
                }
            };
        };
        this.valueFromFields = function (params) {
            return getFieldValue(params.data.Fields, params.colDef.field);
        };
        this.getFieldValue = function (fields, fieldName) {
            return getFieldValue(fields, fieldName);
        };
        function getFieldValue(fields, fieldName) {
            let field = getField(fields, fieldName);
            return field ? field.value : undefined;
        }
        function getField(fields, fieldName) {
            if (!fields)
                return;
            return fields.find(x => x.name === fieldName);
        }
    }
}());
(function () {
    angular.module('app').factory('authenticationService', authenticationService);
    function authenticationService($http, API_PREFIX) {
        let authenticationPromise = fetchIsAuthenticated();
        return { checkAuthentication };
        function checkAuthentication() {
            return authenticationPromise;
        }
        function fetchIsAuthenticated() {
            return $http({
                url: `${ API_PREFIX }Unauthenticated/IsAuthenticated`,
                method: 'POST'
            }).then(response => response.data.IsAuthenticated);
        }
    }
}());
(function () {
    angular.module('app').factory('automationService', automationService);
    function automationService($http, API_PREFIX, $q) {
        return {
            getEntityActions,
            createEntityAction,
            deleteEntityAction,
            getAvailableActions,
            getEntityActionErrorLogs
        };
        function getEntityActions(entityId) {
            return $http({
                url: `${ API_PREFIX }Admins/GetEntityActions`,
                headers: { entityId },
                method: 'POST'
            }).then(response => response.data.data);
        }
        function createEntityAction(entityId, action, scheduledFor) {
            const body = {
                action: action,
                scheduledFor: scheduledFor
            };
            return $http({
                url: `${ API_PREFIX }Admins/CreateEntityAction`,
                headers: { entityId },
                data: body,
                method: 'POST'
            }).then(response => response.data);
        }
        function deleteEntityAction(entityId, actionId) {
            return $http({
                url: `${ API_PREFIX }Admins/DeleteEntityAction`,
                headers: { entityId },
                data: { actionId },
                method: 'POST'
            }).then(response => response.data);
        }
        function getEntityActionErrorLogs(entityId) {
            return $http({
                url: `${ API_PREFIX }Admins/GetEntityActionErrorLogs`,
                headers: { entityId },
                method: 'POST'
            }).then(response => response.data);
        }
        // cache the action types as they only change on rebuilds
        let actionTypes = undefined;
        function getAvailableActions() {
            if (actionTypes) {
                return $q.resolve(actionTypes);
            }
            return $http({
                url: `${ API_PREFIX }Admins/GetEntityActionTypes`,
                method: 'POST'
            }).then(response => response.data.data).then(types => {
                actionTypes = types;
                return actionTypes;
            });
        }
    }
}());
(function () {
    angular.module('app').factory('campaignService', campaignService);
    function campaignService($http, $q, $location, $timeout, API_PREFIX, orgService, securityService, rbTransformRequests) {
        let groupsMap = null;
        let rawCampaigns = null;
        let rawGroups = null;
        let openedGroup = null;
        let openedGroupMove = null;
        let campaignDomains = null;
        let orgId = orgService.getUrlOrgId();
        let redirectedFrom = null;
        return {
            openGroup,
            getOpenedGroup,
            getOpenedGroupMove,
            getGroupsPath,
            getRawCampaigns,
            updateRawCampaigns,
            pushToRawCampaigns,
            moveCampaignCache,
            getRawGroups,
            getGroupById,
            getGroupByEntityId,
            pushToRawGroups,
            pushMultipleToRawCampaigns,
            replaceRawGroups,
            moveGroupCache,
            updateGroupName,
            getCampaignDomains,
            getCampaignByEntityId,
            getCampaignById,
            getCampaignByUrl,
            delistCampaign,
            archiveCampaign,
            validateArchiveOrRelaunchCmp,
            validateArchiveOrRelaunchCmps,
            unarchiveCmp,
            relaunchCampaign,
            replicateCampaign,
            bulkReplicateCampaignsValidateCsv,
            bulkReplicateCampaigns,
            delistCampaigns,
            archiveCampaigns,
            addCampaignUrl,
            updateCampaignUrl,
            removeCampaignUrl,
            saveEditCampaign,
            saveEditCampaignCustomField,
            uploadCmpImage,
            getBulkReplicateCsvHref,
            checkUpdateRouting,
            getCampaignByEventId,
            replaceRawCampaign,
            replaceRawGroup,
            shouldUpdateRoutingForGroup,
            updateRoutingForGroup,
            addCampaignWhitelistedUrl,
            removeCampaignWhitelistedUrl
        };
        // open group and return items of this group
        function openGroup(groupId, curEntityId, hardReset, moving) {
            let curGroupId = groupId || 0;
            if (groupsMap && !hardReset) {
                //load cached data
                groupsMap = _buildCampaignsGroupsTree(rawCampaigns, rawGroups);
                //rebuild tree for moved/added campaigns/groups
                if (moving) {
                    openedGroupMove = groupsMap[curGroupId];
                    //orgService.updatePathToGroup(openedGroupMove);
                    return new $q.resolve(openedGroupMove.subItems);
                } else {
                    openedGroup = groupsMap[curGroupId];
                    if (openedGroup) {
                        //orgService.updatePathToGroup(openedGroup);
                        return new $q.resolve(openedGroup.subItems);
                    }
                }
            }
            return $http({
                url: API_PREFIX + '/GetOrgInfo',
                headers: { orgId: orgService.getOrgId() },
                //keep here since might be first call prior to having orgId in url
                method: 'POST'
            }).then(response => {
                rawCampaigns = response.data.data.campaigns;
                rawCampaigns.forEach(c => {
                    c.campaignUrl = orgService.getCampaignPath(c);
                    if (c.masterEventId) {
                        c.masterCampaignUrl = orgService.getCampaignPath(getCampaignByEventId(c.masterEventId));
                    }
                });
                rawGroups = response.data.data.groups;
                campaignDomains = response.data.data.domains;
                orgService.globalSettings = response.data.globalSettings;
                groupsMap = _buildCampaignsGroupsTree(rawCampaigns, rawGroups);
                orgService.resolveOrgInfoLoadedPromise(response.data);
                if (moving) {
                    openedGroupMove = groupsMap[curGroupId];
                    return new $q.resolve(openedGroupMove.subItems);
                }
                openedGroup = groupsMap[curGroupId];
                if (openedGroup == undefined) {
                    return new $q.reject();
                }
                return new $q.resolve(openedGroup.subItems);
            });
        }
        function delistCampaign(entityId, delist) {
            //true -> delist, false -> publish
            return $http({
                url: `${ API_PREFIX }Cmp/DelistCmp`,
                data: { delist },
                headers: { entityId },
                method: 'POST'
            }).then(response => response.data.success);
        }
        function delistCampaigns(entityIds, delist, messageConfig) {
            //true -> delist, false -> publish
            return $http({
                url: `${ API_PREFIX }Cmp/DelistCmps`,
                data: { delist },
                headers: { entityIds: entityIds },
                method: 'POST',
                messageConfig
            }).then(response => response.data.success);
        }
        function archiveCampaign(entityId, transferRecurring, recurringPlanDestination) {
            let destEntityId = recurringPlanDestination ? recurringPlanDestination.entityID : null;
            return $http({
                url: `${ API_PREFIX }Cmp/ArchiveCmp`,
                data: { transferRecurring },
                headers: {
                    entityId,
                    transferRecurringToEntityId: destEntityId
                },
                method: 'POST'
            }).then(response => response.data.success);
        }
        function archiveCampaigns(entityIds, transferRecurring, recurringPlanDestination, messageConfig) {
            let destEntityId = recurringPlanDestination ? recurringPlanDestination.entityID : null;
            return $http({
                url: `${ API_PREFIX }Cmp/ArchiveCmps`,
                data: { transferRecurring },
                headers: {
                    entityIds,
                    transferRecurringToEntityId: destEntityId
                },
                method: 'POST',
                messageConfig
            }).then(response => response.data.success);
        }
        function validateArchiveOrRelaunchCmp(entityId, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/ValidateArchiveOrRelaunchCmp`,
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function validateArchiveOrRelaunchCmps(entityIds, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/ValidateArchiveOrRelaunchCmps`,
                headers: { entityIds },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function unarchiveCmp(entityId, urlPath, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/UnarchiveCmp`,
                data: urlPath,
                headers: { entityId },
                method: 'POST',
                messageConfig
            });
        }
        function replicateCampaign(entityId, replicateInfo, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/ReplicateCmp`,
                data: replicateInfo,
                headers: { entityId },
                method: 'POST',
                messageConfig
            });
        }
        function getBulkReplicateCsvHref(entityId) {
            return `/Org/${ orgId }${ API_PREFIX }Cmp/DownloadBulkUploadCsv?entityId=${ entityId }`;
        }
        function bulkReplicateCampaignsValidateCsv(entityId, guid, eventId, file, cloneCmpSettings, publishCampaign, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/ValidateBulkReplicateCmpCsv`,
                data: {
                    entityId: entityId,
                    csvFile: file,
                    uploadInstance: guid,
                    cloneCmpSettings: cloneCmpSettings,
                    publishCampaign: publishCampaign
                },
                headers: {
                    entityId,
                    'Content-Type': undefined
                },
                method: 'POST',
                transformRequest: rbTransformRequests.transformFormData,
                messageConfig
            }).then(response => response.data);
        }
        function bulkReplicateCampaigns(entityId, guid, eventId, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/BulkReplicateCmp`,
                data: { uploadInstance: guid },
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function relaunchCampaign(entityIds, transferRecurring, recurringPlanDestination, messageConfig) {
            let destEntityId = recurringPlanDestination ? recurringPlanDestination.entityID : null;
            return $http({
                url: `${ API_PREFIX }Cmp/RelaunchCmp`,
                data: { transferRecurring },
                headers: {
                    entityIds: entityIds,
                    transferRecurringToEntityId: destEntityId
                },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function saveEditCampaign(entityId, field, values, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/UpdateCampaignInfo`,
                data: {
                    field: field,
                    value: values[0],
                    value2: values[1],
                    value3: values[2],
                    value4: values[3],
                    value5: values[4]
                },
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => {
                let index = rawCampaigns.findIndex(item => item.entityId == entityId);
                rawCampaigns[index][field] = values[0];
                return response.data.success;
            });
        }
        function saveEditCampaignCustomField(entityId, formFieldId, value) {
            return $http({
                url: `${ API_PREFIX }Cmp/UpdateCustomField`,
                data: {
                    formFieldId,
                    value
                },
                headers: { entityId },
                method: 'POST'
            }).then(response => {
                // let index = rawCampaigns.findIndex(item => item.entityId == entityId);
                // rawCampaigns[index][field] = values[0];
                return response.data.success;
            });
        }
        function addCampaignUrl(entityId, subdomain, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/AddHost`,
                data: { subdomain },
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function updateCampaignUrl(entityId, url, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/UpdateDefaultHost`,
                data: { url },
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function removeCampaignUrl(entityId, url, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/RemoveHost`,
                data: { url },
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function addCampaignWhitelistedUrl(entityId, url, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/AddWhitelistedUrl`,
                data: { url },
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function removeCampaignWhitelistedUrl(entityId, url, messageConfig) {
            return $http({
                url: `${ API_PREFIX }Cmp/RemoveWhitelistedUrl`,
                data: { url },
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function uploadCmpImage(entityId, image) {
            return $http({
                url: `${ API_PREFIX }Cmp/UploadCmpImage`,
                data: { file: image },
                headers: {
                    entityId,
                    'Content-Type': undefined
                },
                method: 'POST',
                transformRequest: rbTransformRequests.transformFormData
            }).then(response => response.data.data);
        }
        function getOpenedGroup() {
            return openedGroup;
        }
        function getOpenedGroupMove() {
            return openedGroupMove;
        }
        function getRawCampaigns() {
            return rawCampaigns;
        }
        function updateRawCampaigns(campaign) {
            let index = rawCampaigns.findIndex(item => item.entityId == campaign.entityId);
            Object.keys(campaign).forEach(key => rawCampaigns[index][key] = campaign[key]);
        }
        function replaceRawCampaign(campaign) {
            let index = rawCampaigns.findIndex(item => item.entityId == campaign.entityId);
            campaign.settings.loaded = true;
            rawCampaigns[index] = campaign;
        }
        function replaceRawGroup(group) {
            let index = rawGroups.findIndex(item => item.entityId == group.entityId);
            group.settings.loaded = true;
            rawGroups[index] = group;
        }
        function pushToRawCampaigns(item) {
            rawCampaigns.push(item);
        }
        function pushMultipleToRawCampaigns(items) {
            items.forEach(pushToRawCampaigns);
        }
        function moveCampaignCache(targets, parent) {
            //update structure without getting new data from server
            targets.forEach(item => {
                let index = rawCampaigns.findIndex(campaign => campaign.campaignId == item);
                rawCampaigns[index].groupId = parent;
            });
        }
        function getRawGroups() {
            return rawGroups;
        }
        function getGroupById(groupId) {
            return rawGroups.find(group => group.groupId == groupId);
        }
        function getGroupByEntityId(entityId) {
            return rawGroups.find(group => group.entityId == entityId);
        }
        function pushToRawGroups(item) {
            rawGroups.push(item);
        }
        function updateGroupName(groupId, name) {
            let index = rawGroups.findIndex(item => item.groupId == groupId);
            if (index !== -1) {
                rawGroups[index].name = name;
            }
        }
        function replaceRawGroups(newRawGroups) {
            rawGroups = newRawGroups;
        }
        function moveGroupCache(targets, parent) {
            //update structure without getting new data from server
            targets.forEach(item => {
                let index = rawGroups.findIndex(group => group.groupId == item);
                rawGroups[index].parentGroupId = parent;
            });
        }
        function getCampaignDomains() {
            return campaignDomains;
        }
        function getCampaignByEntityId(entityId) {
            return rawCampaigns.find(campaign => campaign.entityId == entityId);
        }
        function getCampaignById(campaignId) {
            return rawCampaigns.find(campaign => campaign.campaignId == campaignId);
        }
        function getCampaignByEventId(eventId) {
            return rawCampaigns.find(campaign => campaign.curEventId == eventId);
        }
        function getCampaignByUrl(url) {
            return rawCampaigns.find(campaign => campaign.urls.primary == url);
        }
        // GroupName -> GroupName -> GroupName
        function getGroupsPath() {
            let path = [];
            let currentNode = openedGroup;
            // build path from tree
            while (currentNode.parentGroupId !== undefined) {
                path.push(currentNode);
                currentNode = groupsMap[currentNode.parentGroupId];
            }
            return path.reverse();    // last should be last visited
        }
        function _buildCampaignsGroupsTree(campaigns, groups) {
            // map groupId : group object
            let groupsMap = _.keyBy(groups, 'groupId');
            groupsMap[0] = {};
            // holder for root campaigns
            _.each(groupsMap, group => {
                group.amountRaised = 0;
                group.members = 0;
                group.subItems = {
                    groups: [],
                    campaigns: []
                };
            });
            campaigns.forEach(campaign => {
                if (!campaign.type)
                    campaign.type = 'Ticketing';
                //temp fix until next release
                let campaignGroup = groupsMap[campaign.groupId];
                campaignGroup.members += campaign.members;
                campaignGroup.amountRaised += campaign.amountWithoutSubgoals;
                campaignGroup.subItems.campaigns.push(campaign);
            });
            groups.forEach(group => {
                groupsMap[group.parentGroupId].subItems.groups.push(group);
            });
            groupsMap[0].subItems.groups.forEach(group => {
                const countRecursive = (subGroup, field) => {
                    subGroup.subItems.groups.forEach(subSubGroup => {
                        subGroup[field] += countRecursive(subSubGroup, field);
                    });
                    return subGroup[field];
                };
                group.amountRaised = countRecursive(group, 'amountRaised');
                group.members = countRecursive(group, 'members');
            });
            return groupsMap;
        }
        function updateRoutingForGroup(campaign) {
            redirectedFrom = campaign.campaignId;
            $timeout(function () {
                var fullPath = $location.path();
                var match = fullPath.match(/.*?\/campaign\/[0-9]*(\/?.*)/i);
                var pathRemainder = match ? match[1] : '';
                orgService.updatePathToCampaign(campaign, pathRemainder);
                $location.replace();
            });
        }
        function shouldUpdateRoutingForGroup($nextInstruction, entityInfo) {
            let campaignId = $nextInstruction.params.campaignId;
            let groupFromUrl = $nextInstruction.params.groupId;
            let groupId = entityInfo.groupId;
            if (!campaignId) {
                redirectedFrom = null;
                return false;
            }
            // Prevent infinite loop of redirects
            if (redirectedFrom === campaignId) {
                return false;
            }
            if (!groupFromUrl && !groupId) {
                redirectedFrom = null;
                return false;
            }
            if (groupFromUrl == groupId) {
                redirectedFrom = null;
                return false;
            }
            return true;
        }
        function checkUpdateRouting() {
            let campaignAndGroup = {};
            const campaignPath = orgService.getUrlCampaignPath();
            if (campaignPath) {
                const campaignHost = orgService.getUrlHost();
                let campaign = getCampaignByUrl(campaignHost + '/' + campaignPath);
                if (!campaign)
                    return $q.reject();
                let groupId = campaign.groupId;
                let group = getGroupById(groupId);
                campaignAndGroup.campaign = campaign;
                campaignAndGroup.group = group;
                return $q.resolve(campaignAndGroup);
            }
            let campaignId = orgService.getUrlCampaignId();
            if (campaignId) {
                let campaign = getCampaignById(campaignId);
                if (!campaign)
                    return $q.reject();
                let groupId = campaign.groupId;
                let group = getGroupById(groupId);
                campaignAndGroup.campaign = campaign;
                campaignAndGroup.group = group;
                return $q.resolve(campaignAndGroup);
            }
            let groupId = orgService.getUrlGroupId();
            if (groupId) {
                let group = getGroupById(groupId);
                if (!group)
                    return $q.reject();
                campaignAndGroup.group = group;
                return $q.resolve(campaignAndGroup);
            }
            return $q.resolve(campaignAndGroup);
        }
    }
}());
(function () {
    angular.module('app').factory('ckEditorService', ckEditorService);
    function ckEditorService() {
        return {
            configureCkEditor,
            getEditorValue
        };
        function configureCkEditor() {
            // set global plugins
            CKEDITOR.plugins.add('maxlength', {
                init: function (editor) {
                    var $editorName = $('#' + editor.name);
                    var maxLimit = $editorName.attr('maxlength') ? $editorName.attr('maxlength') : $editorName.attr('ng-maxlength') ? $editorName.attr('ng-maxlength') : 0;
                    ;
                    if (maxLimit > 0) {
                        editor.on('afterCommandExec', function (evt) {
                            var charCount = evt.editor.getData().length;
                            if (charCount >= maxLimit) {
                                editor.execCommand('undo');
                            }
                        });
                        editor.on('paste', function (evt) {
                            var charCount = evt.editor.getData().length + evt.data.dataValue.length;
                            if (charCount >= maxLimit) {
                                return false;
                            } else {
                                return true;
                            }
                        });
                        editor.on('key', function (evt) {
                            var charCount = evt.editor.getData().length;
                            if (charCount >= maxLimit) {
                                if (evt.data.keyCode === 8 || evt.data.keyCode === 46) {
                                    return true;
                                }
                                return false;
                            } else {
                                return true;
                            }
                        });
                    }
                }
            });
            CKEDITOR.plugins.add('applyAtrr', {
                init: function (editor) {
                    var edtr = editor;
                    var $editorName = $('#' + edtr.name);
                    if ($editorName.attr('ck-editor-attr')) {
                        var ckEditorAttr = JSON.parse($editorName.attr('ck-editor-attr'));
                        CKEDITOR.on('instanceReady', function (evt) {
                            for (var attribute in ckEditorAttr) {
                                $('#cke_' + edtr.name).attr(attribute, ckEditorAttr[attribute]);
                            }
                        });
                    }
                }
            });
            CKEDITOR.plugins.add('extraButtons', {
                init: function (editor) {
                    if (editor.config.extraButtons) {
                        var floatingToolbarAdded = false;
                        _.forEach(editor.config.extraButtons, button => {
                            if (button.toolbar === 'floating' && !floatingToolbarAdded) {
                                editor.ui.addToolbarGroup('floating', 0);
                                editor.element.getParent().getParent().addClass('hasFloatingToolbar');
                                floatingToolbarAdded = true;
                            }
                            let buttonName = _.startCase(button.label).replace(/ /g, '');
                            let commandName = _.camelCase(button.label);
                            editor.ui.addButton(buttonName, {
                                label: button.label,
                                title: button.label,
                                icon: button.icon,
                                command: commandName,
                                toolbar: button.toolbar
                            });
                            editor.addCommand(commandName, {
                                modes: {
                                    wysiwyg: 1,
                                    source: 1
                                },
                                readOnly: 1,
                                exec: button.command
                            });
                        });
                    }
                }
            });
            CKEDITOR.on('instanceReady', function (ev) {
                ev.editor.editable().addClass('cke_enable_context_menu');
            });
            CKEDITOR.getInlineConfig = function () {
                return {
                    plugins: 'basicstyles,clipboard,list,indent,enterkey,entities,link,pastetext,toolbar,undo,wysiwygarea,font,codemirror',
                    forcePasteAsPlainText: true,
                    removeButtons: 'Anchor,Underline,Strike,Subscript,Superscript',
                    toolbarGroups: [
                        { name: 'cstm' },
                        {
                            name: 'document',
                            groups: [
                                'mode',
                                'document',
                                'doctools'
                            ]
                        },
                        {
                            name: 'editing',
                            groups: [
                                'find',
                                'selection'
                            ]
                        },
                        { name: 'forms' },
                        {
                            name: 'basicstyles',
                            groups: [
                                'basicstyles',
                                'cleanup',
                                'undo'
                            ]
                        },
                        {
                            name: 'paragraph',
                            groups: [
                                'list',
                                'indent',
                                'blocks',
                                'align'
                            ]
                        },
                        { name: 'links' },
                        { name: 'insert' },
                        { name: 'styles' },
                        { name: 'colors' },
                        { name: 'tools' },
                        { name: 'others' },
                        { name: 'about' }
                    ],
                    entities: false,
                    entities_additional: 'trade,loz',
                    fontSize_sizes: ';8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;',
                    font_names: 'Arial/Arial, Helvetica, sans-serif;' + 'Calibri, Candara, Segoe, Optima, Arial, sans-serif;' + 'Comic Sans MS/Comic Sans MS, cursive;' + 'Courier New/Courier New, Courier, monospace;' + 'Georgia/Georgia, serif;' + 'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' + 'Tahoma/Tahoma, Geneva, sans-serif;' + 'Times New Roman/Times New Roman, Times, serif;' + 'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' + 'Verdana/Verdana, Geneva, sans-serif;',
                    enterMode: CKEDITOR.ENTER_BR,
                    fillEmptyBlocks: false,
                    coreStyles_italic: {
                        element: 'i',
                        overrides: 'em'
                    },
                    coreStyles_bold: {
                        element: 'b',
                        overrides: 'strong'
                    },
                    skin: 'office2013',
                    //http://stackoverflow.com/a/24425037
                    extraAllowedContent: 'img[width,height]',
                    disallowedContent: 'img{width,height}',
                    disableNativeSpellChecker: false
                };
            };
            CKEDITOR.getInlineConfigAngular = function () {
                return {
                    plugins: 'basicstyles,clipboard,list,enterkey,entities,link,pastetext,toolbar,undo,wysiwygarea,maxlength,applyAtrr,font,codemirror',
                    forcePasteAsPlainText: true,
                    extraPlugins: '',
                    removeButtons: 'Anchor,Underline,Strike,Subscript,Superscript',
                    height: '100px',
                    toolbarGroups: [
                        { name: 'cstm' },
                        {
                            name: 'document',
                            groups: [
                                'mode',
                                'document',
                                'doctools'
                            ]
                        },
                        {
                            name: 'editing',
                            groups: [
                                'find',
                                'selection'
                            ]
                        },
                        { name: 'forms' },
                        {
                            name: 'basicstyles',
                            groups: [
                                'basicstyles',
                                'cleanup',
                                'undo'
                            ]
                        },
                        {
                            name: 'paragraph',
                            groups: [
                                'list',
                                'blocks',
                                'align'
                            ]
                        },
                        { name: 'links' },
                        { name: 'insert' },
                        { name: 'colors' },
                        { name: 'tools' },
                        { name: 'others' },
                        { name: 'about' }
                    ]
                };
            };
            CKEDITOR.getInlineConfigAngularDescriptionField = function () {
                return {
                    plugins: 'basicstyles,clipboard,enterkey,entities,link,pastetext,toolbar,undo,wysiwygarea,maxlength,applyAtrr,font,codemirror',
                    forcePasteAsPlainText: true,
                    extraPlugins: '',
                    removeButtons: 'Anchor,Underline,Strike,Subscript,Superscript',
                    height: '100px',
                    toolbarGroups: [
                        { name: 'cstm' },
                        {
                            name: 'document',
                            groups: [
                                'mode',
                                'document',
                                'doctools'
                            ]
                        },
                        {
                            name: 'editing',
                            groups: [
                                'find',
                                'selection'
                            ]
                        },
                        { name: 'forms' },
                        {
                            name: 'basicstyles',
                            groups: [
                                'basicstyles',
                                'cleanup',
                                'undo'
                            ]
                        },
                        {
                            name: 'paragraph',
                            groups: [
                                'blocks',
                                'align'
                            ]
                        },
                        { name: 'links' },
                        { name: 'insert' },
                        { name: 'colors' },
                        { name: 'tools' },
                        { name: 'others' },
                        { name: 'about' }
                    ]
                };
            };
            CKEDITOR.getMiniAdminConfig = function () {
                return {
                    plugins: 'basicstyles,clipboard,list,indent,enterkey,entities,link,pastetext,toolbar,undo,wysiwygarea,font,codemirror,image',
                    forcePasteAsPlainText: true,
                    removeButtons: 'Anchor,Underline,Strike,Subscript,Superscript',
                    extraPlugins: 'placeholder,filebrowser,imagebrowser,pdfembed',
                    toolbarGroups: [
                        {
                            name: 'document',
                            groups: [
                                'mode',
                                'document',
                                'doctools'
                            ]
                        },
                        {
                            name: 'editing',
                            groups: [
                                'find',
                                'selection'
                            ]
                        },
                        { name: 'forms' },
                        {
                            name: 'basicstyles',
                            groups: [
                                'basicstyles',
                                'cleanup',
                                'undo'
                            ]
                        },
                        { name: 'paragraph' },
                        { name: 'links' },
                        { name: 'insert' },
                        { name: 'styles' },
                        { name: 'colors' },
                        { name: 'tools' },
                        { name: 'others' },
                        { name: 'about' }
                    ]    // entities: false,
                         // fontSize_sizes: ';8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;',
                         // enterMode: 'CKEDITOR.ENTER_BR',
                         // fillEmptyBlocks: false,
                         // coreStyles_italic: { element : 'i', overrides : 'em' },
                         // coreStyles_bold: { element : 'b', overrides : 'strong' }
                };
            };
            // TODO: Fix filebrowser,imagebrowser plugins at the end
            CKEDITOR.config.plugins = 'dialogui,dialog,dialogadvtab,basicstyles,bidi,blockquote,specialchar,clipboard' + ',panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,resize,toolbar,elementspath,list,indent,' + ',entities,popup,find,fakeobjects,floatingspace,listblock,richcombo,font,format,htmlwriter,horizontalrule' + ',wysiwygarea,image,justify,link,liststyle,magicline,pastetext,pastefromword,removeformat,selectall' + ',showborders,sourcearea,menubutton,stylescombo,tab,table,tabletools,undo,codemirror,filebrowser,imagebrowser';
            CKEDITOR.config.removePlugins = 'liststyle,tabletools,contextmenu';
            CKEDITOR.config.codemirror = {
                theme: 'default',
                lineNumbers: true,
                lineWrapping: true,
                matchBrackets: true,
                autoCloseTags: true,
                autoCloseBrackets: true,
                enableSearchTools: false,
                enableCodeFolding: true,
                enableCodeFormatting: true,
                autoFormatOnStart: true,
                autoFormatOnUncomment: true,
                highlightActiveLine: true,
                highlightMatches: true,
                showFormatButton: false,
                showCommentButton: false,
                showUncommentButton: false
            };
            CKEDITOR.config.allowedContent = true;
            CKEDITOR.config.removeDialogTabs = 'image:advanced;image:Link;link:advanced;table:advanced';
            CKEDITOR.config.entities = false;
            CKEDITOR.config.entities_additional = 'trade,loz';
            CKEDITOR.config.fontSize_sizes = ';8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;';
            CKEDITOR.config.font_names = 'Arial/Arial, Helvetica, sans-serif;' + 'Calibri/Calibri, Candara, Segoe, Optima, Arial, sans-serif;' + 'Comic Sans MS/Comic Sans MS, cursive;' + 'Courier New/Courier New, Courier, monospace;' + 'Georgia/Georgia, serif;' + 'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' + 'Tahoma/Tahoma, Geneva, sans-serif;' + 'Times New Roman/Times New Roman, Times, serif;' + 'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' + 'Verdana/Verdana, Geneva, sans-serif;';
            CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR;
            CKEDITOR.config.fillEmptyBlocks = false;
            CKEDITOR.config.coreStyles_italic = {
                element: 'i',
                overrides: 'em'
            };
            CKEDITOR.config.coreStyles_bold = {
                element: 'b',
                overrides: 'strong'
            };
            CKEDITOR.config.skin = 'office2013';
            //http://stackoverflow.com/a/24425037
            CKEDITOR.config.extraAllowedContent = 'img[width,height]';
            CKEDITOR.config.disallowedContent = 'img{width,height}';
            CKEDITOR.config.disableNativeSpellChecker = false;
            CKEDITOR.config.imageBrowser_isAngularAdmin = true;
        }
        function getEditorValue(id, knownValue) {
            return CKEDITOR.instances[id] ? CKEDITOR.instances[id].getData() : knownValue;
        }
    }
}());
(function () {
    angular.module('app').factory('clearviewService', clearviewService);
    function clearviewService($http, $q, API_PREFIX, messageService, crmSyncsService) {
        return {
            getClearViewLogs,
            reSync,
            syncClearView
        };
        /**
     * Load clear view logs from server
     * @param {Date} date date to load records for
     */
        function getClearViewLogs(id, from, to) {
            return crmSyncsService.getCrmLogsForDates('CvSyncs', id, from, to);
        }
        function reSync(record) {
            // Don't send other fields back to server in case they have html
            let recordToSync = {
                ClearViewQueueId: record.ClearViewQueueId,
                EventId: record.EventId
            };
            return crmSyncsService.reSync(recordToSync, 'CvResync');
        }
        function syncClearView(appealCode, entityId) {
            const url = entityId === -1 ? 'SyncTicketsUsingAppealCode' : 'SyncEventAndTicketsUsingAppealCode';
            const messageConfig = {
                advancedErrorFunction: messageService.advancedErrorsDialog,
                advancedErrorEnabled: true
            };
            return $http({
                url: `${ API_PREFIX }Cmp/${ url }`,
                data: { appealCode: appealCode },
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
    }
}());
(function () {
    angular.module('app').factory('configSettingsService', configSettingsService);
    function configSettingsService($http, API_PREFIX, entityService, emailService) {
        const CONFIG_FORM_LOCATIONS = {
            //Forms tab
            DONATION_SETTINGS: 1,
            REGISTRATION_SETTINGS: 2,
            REGISTRATION_BUILDER: 3,
            TICKET_BUILDER: 4,
            FORM_BUILDER: 5,
            CUSTOM_SETTINGS: 6,
            ADVANCED_SETTINGS: 7,
            FORMS_ADVANCED: 10,
            WEBSITE_ADVANCED: 20,
            //Fundraising tab
            FUNDRAISING_ADVANCED: 11,
            FUNDRAISING_OPTIONS: 12,
            STARTUP_GUIDE: 40,
            HQ_TASKS: 41,
            FUNDRAISER_PDF: 43,
            EMAIL_ADVANCED: 45,
            EMAIL_PDF_RECEIPTS: 46,
            EMAIL_PDF_RECEIPTS_ADVANCED: 47,
            EMAIL_HEADER_FOOTER: 48,
            SYSTEM_ADVANCED: 50,
            INTEGRATION: 55,
            PAY_PAL: 70,
            NATIONAL_TEAMS: 80
        };
        return {
            //Forms tab
            getDonationSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.DONATION_SETTINGS),
            getPayPalSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.PAY_PAL),
            getRegistrationSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.REGISTRATION_SETTINGS),
            getRegistrationBuilder: () => getSettingsForm(CONFIG_FORM_LOCATIONS.REGISTRATION_BUILDER),
            getTicketBuilder: () => getSettingsForm(CONFIG_FORM_LOCATIONS.TICKET_BUILDER),
            getFormBuilder: () => getSettingsForm(CONFIG_FORM_LOCATIONS.FORM_BUILDER),
            getCustomSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.CUSTOM_SETTINGS),
            getFormsAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.FORMS_ADVANCED),
            getWebsiteAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.WEBSITE_ADVANCED),
            //Fundraising tab
            getFundraisingAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.FUNDRAISING_ADVANCED),
            getFundraisingOptions: () => getSettingsForm(CONFIG_FORM_LOCATIONS.FUNDRAISING_OPTIONS),
            getStartupGuideSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.STARTUP_GUIDE),
            getHqTaskSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.HQ_TASKS),
            //Email
            getEmailAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.EMAIL_ADVANCED),
            getEmailPdfReceiptSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.EMAIL_PDF_RECEIPTS),
            getEmailPdfReceiptAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.EMAIL_PDF_RECEIPTS_ADVANCED),
            getEmailHeaderFooterSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.EMAIL_HEADER_FOOTER),
            //System
            getIntegrationSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.INTEGRATION),
            getSystemAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.SYSTEM_ADVANCED),
            //People
            getNationalTeamSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.NATIONAL_TEAMS),
            //General updates
            updateSetting: settingObj => sendConfigRequest(settingObj, 'UpdateSetting'),
            adoptParent: settingID => sendConfigRequest({ settingID }, 'AdoptParent'),
            overrideParent: settingID => sendConfigRequest({ settingID }, 'OverrideParent'),
            clearOverrides: settingID => sendConfigRequest({ settingID }, 'DeleteConfigsOfChildren')
        };
        function getSettingsForm(formID) {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: `${ API_PREFIX }Cmp/GetSettingsForm`,
                headers: { entityId },
                data: { formID },
                method: 'POST'
            }).then(response => response.data);
        }
        function sendConfigRequest(data, actionName) {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: `${ API_PREFIX }Cmp/${ actionName }`,
                data,
                headers: { entityId },
                method: 'POST'
            }).then(response => response.data);
        }
    }
}());
(function () {
    angular.module('app').factory('configRouteService', configRouteService);
    function configRouteService(transactionRouteConfig, peopleRouteConfig, emailRouteConfig, formsRouteConfig, websiteRouteConfig, fundraisingRouteConfig, systemRouteConfig, entityService, routingService, $location) {
        const vm = this;
        vm.routeConfigs = {
            'transactions': transactionRouteConfig,
            'people': peopleRouteConfig,
            'email': emailRouteConfig,
            'forms': formsRouteConfig,
            'website': websiteRouteConfig,
            'fundraising': fundraisingRouteConfig,
            'system': systemRouteConfig
        };
        function getRouteConfig(name) {
            return vm.routeConfigs[name];
        }
        function isActive(path) {
            return $location.path().endsWith(path);
        }
        function hasPermission(path, data) {
            if (!data.resource) {
                return true;
            }
            return routingService.validateRoutePermission(data, entityService.getEntityWithSettings(entityService.getOpenedEntityId()));
        }
        function shouldShow(data) {
            if (!routingService.willRoute(data, entityService.getEntityWithSettings(entityService.getOpenedEntityId()))) {
                return false;
            }
            if (data.showFunc) {
                if (!data.showFunc(entityService.getEntityWithSettings(entityService.getOpenedEntityId()))) {
                    return false;
                }
            }
            return true;
        }
        return {
            getRouteConfig,
            isActive,
            hasPermission,
            shouldShow
        };
    }
}());
(function () {
    angular.module('app').factory('cookieService', cookieService);
    function cookieService() {
        return {
            createCookie,
            readCookie,
            eraseCookie
        };
        function createCookie(name, value, days) {
            var expires = '';
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = '; expires=' + date.toUTCString();
            }
            document.cookie = name + '=' + value + expires + '; path=/';
        }
        function readCookie(name) {
            var nameEQ = name + '=';
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ')
                    c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0)
                    return c.substring(nameEQ.length, c.length);
            }
            return null;
        }
        function eraseCookie(name) {
            createCookie(name, '', -1);
        }
    }
}());
(function () {
    angular.module('app').service('creditService', creditService);
    function creditService(requestHelperService, formService) {
        const vm = this;
        let rh = requestHelperService.getInstance('Credit');
        vm.api = {
            getNewCreditForm: () => rh.sendEntityRequest('AddForm'),
            saveCredit: item => rh.sendCampaignRequest('Edit', { item: formService.getBaseFormItem(item) }),
            addCredit: item => rh.sendCampaignRequest('Add', { item: formService.getBaseFormItem(item) }, rh.successToastMessage('Credit has been added.')),
            deleteCredit: item => rh.sendCampaignRequest('Delete', { item: formService.getBaseFormItem(item) }),
            getExistingUsers: () => rh.sendExtJsRequest('Member/AllMembersSimple', {
                limit: 10,
                page: 0,
                start: 0,
                noPaging: true
            })
        };
        return vm.api;
    }
}());
(function () {
    angular.module('app').factory('crmSyncsService', crmSyncsService);
    function crmSyncsService($http, $q, API_PREFIX) {
        return {
            getCrmLogs,
            getCrmLogsForDates,
            reSync
        };
        function getCrmLogs(crmAction, id, start = 0, limit = 99999) {
            return getCrmLogsRaw(crmAction, {
                id,
                start,
                limit
            });
        }
        function getCrmLogsForDates(crmAction, id, from, to) {
            return getCrmLogsRaw(crmAction, {
                id,
                to: moment(to).format('YYYY-MM-DD'),
                from: moment(from).format('YYYY-MM-DD')
            });
        }
        function getCrmLogsRaw(crmAction, data) {
            return $http.post(`${ API_PREFIX }Integration/${ crmAction }`, data).then(response => {
                return response.data.items.map(log => {
                    log.InitiatedOn = log.InitiatedOn && new Date(log.InitiatedOn);
                    log.LastRetriedOn = log.LastRetriedOn && new Date(log.LastRetriedOn);
                    log.canResync = !!(log.ErroredOn || log.CompletedOn);
                    if (log.CompletedOn) {
                        log.Status = 'successful';    // TODO: Standardize retrying state logic to work with all CRM syncs on server and client side.
                    } else if (log.ErroredOn && log.LastRetriedOn && !log.FirstRetriedOn) {
                        log.Status = 'retrying';
                    } else {
                        log.Status = 'failed';
                    }
                    if (log.CompletedOn) {
                        log.CompletedOn = new Date(log.CompletedOn);
                    } else if (log.ErroredOn) {
                        log.CompletedOn = new Date(log.ErroredOn);
                    }
                    return log;
                });
            });
        }
        function reSync(record, reSyncAction) {
            return $http.post(`${ API_PREFIX }Integration/${ reSyncAction }`, record).then(response => response.data);
        }
    }
}());
(function () {
    angular.module('app').service('emailService', emailService);
    function emailService(entityService, messageService, formService, requestHelperService, $http, $q, API_PREFIX, $mdDialog, $timeout, $mdPanel) {
        const vm = this;
        let rh = requestHelperService.getInstance('Email');
        const EMAIL_CONFIG_OPTIONS = {
            transactionEmails: 600,
            adminTemplates: 601,
            fundraisingEmails: 602,
            triggers: 603
        };
        vm.api = {
            EMAIL_CONFIG_OPTIONS,
            openEmail,
            getEmailEditorButtons,
            getEmailEditorOptionsButton,
            addComposeEmailLogic,
            showSaveAsEmailTemplateDialog,
            showActivateOptions,
            // Macros
            getTemplateMacros: item => rh.sendEntityRequest('TemplateMacros', { item: formService.getBaseFormItem(item) }),
            // Sent Emails
            getSystemEmailStats: id => rh.sendCampaignRequest('SentSystemEmailResult', { emailProcessedId: id }),
            getEmailStats: id => rh.sendCampaignRequest('BlastsResult', { id: id }),
            getRecipients: id => rh.sendCampaignRequest('BlastsRecipients', { id: id }),
            getPendingRecipients: id => rh.sendCampaignRequest('PendingBlastsRecipients', { id: id }),
            resendEmail: (id, useUpdatedTemplate) => rh.sendCampaignRequest('ResendEmail', {
                ProcessedEmailID: id,
                UseUpdatedTemplate: useUpdatedTemplate
            }, rh.successToastMessage('Email queued to be sent.')),
            // Get Email Templates
            getAdminEmailTemplates: () => rh.sendEntityRequest('AllAdminEmailTemplates'),
            getFundraiserEmailTemplates: () => rh.sendEntityRequest('AllFundraiserEmailTemplates'),
            getTriggerEmailTemplates: () => rh.sendEntityRequest('AllTriggerEmailTemplates'),
            getTransactionalEmailTemplates: () => rh.sendEntityRequest('AllTransactionalEmailTemplates'),
            // Email Template Modifications
            createOrUpdateEmailTemplate: item => rh.sendEntityRequest('CreateOrUpdateEmailTemplate', { item: formService.getBaseFormItem(item) }, { advancedErrorEnabled: false }),
            deleteEmailTemplate: item => rh.sendEntityRequest('DeleteEmailTemplate', { item: formService.getBaseFormItem(item) }),
            clearEmailTemplateOverrides: item => rh.sendEntityRequest('DeleteEmailTemplateOfChildren', {
                settingId: item.configOptionId,
                recordId: item.Id
            }),
            // Trigger Email Modifications
            createOrUpdateTriggerEmailTemplate: item => rh.sendEntityRequest('CreateOrUpdateTriggerEmailTemplate', { item: formService.getBaseFormItem(item) }, { advancedErrorEnabled: false }),
            // Email Blasts
            deletePendingEmailBlast: id => rh.sendCampaignRequest('DeletePendingBlast', { id }),
            createBlast: (item, scheduled) => rh.sendCampaignRequest('CreateBlast', { item: formService.getBaseFormItem(item) }, rh.successToastMessage(scheduled ? 'Email has been scheduled.' : 'Email queued to be sent.')),
            updateBlast: item => rh.sendCampaignRequest('UpdatePendingBlast', { item: formService.getBaseFormItem(item) }),
            saveBlastAsEmailTemplate: (templateName, item) => rh.sendEntityRequest('SaveAsTemplate', {
                templateName,
                item: formService.getBaseFormItem(item)
            }),
            getComposeEmailForm: () => rh.sendCampaignRequest('GetComposeEmailForm'),
            // Previews/Tests
            sendTestEmail: (item, testIds, campaignId, adminEmail) => rh.sendCampaignRequest('SendTestEmail', {
                item,
                testIds
            }, rh.successToastMessage(`A test email has been sent to you ${ adminEmail ? 'at ' + adminEmail : '' }.`), campaignId),
            getEmailPreview: (item, testIds, campaignId) => rh.sendCampaignRequest('GetEmailPreview', {
                item,
                testIds
            }, { errorToastEnabled: false }, campaignId),
            getEmailSampleObjects: (endpoint, eventId, filter, sort, dir) => rh.sendExtJsRequest(endpoint, {
                page: 1,
                start: 0,
                limit: 10,
                filter,
                sort,
                dir
            }, eventId),
            // Header/Footer
            updateHeaderOrFooter: config => rh.sendEntityRequest('UpdateEmailHeaderOrFooter', config)
        };
        return vm.api;
        function openEmail(item) {
            if (!item.data) {
                item.data = {};
            }
            if (item.data.macros) {
                CKEDITOR.config.macros = item.data.macros;
            } else {
                vm.api.getTemplateMacros(item).then(r => {
                    item.data.macros = r.items;
                    CKEDITOR.config.macros = r.items;
                });
            }
            let emailPreviewField = formService.getFieldFromItem(item, 'Preview');
            if (emailPreviewField) {
                emailPreviewField.hide = i => !i.InPopup;
            }
            let disableHeaderField = formService.getSubFieldFromItem(item, 'Options', 'Disable Header');
            if (disableHeaderField) {
                disableHeaderField.change = (i, value) => formService.getFieldFromItem(i, 'Body').displayOptions.editorFrame.displayPrepend = !value;
            }
            let disableFooterField = formService.getSubFieldFromItem(item, 'Options', 'Disable Footer');
            if (disableFooterField) {
                disableFooterField.change = (i, value) => formService.getFieldFromItem(i, 'Body').displayOptions.editorFrame.displayAppend = !value;
            }
            createEditorOptionsMenu(item);
        }
        function showActivateOptions(item, forTextDisplay) {
            // Always show Active/Inactive text for all emails
            if (forTextDisplay) {
                return true;
            }
            return !shouldHideDisable(item);
        }
        function shouldHideDisable(item) {
            return (item.Flags & 2) === 2;    // EmailTemplateFlags.HideDisable
        }
        function getEmailEditorOptionsButton() {
            return {
                label: 'Options',
                toolbar: 'floating',
                action: (item, evnt, isEditable) => showEditorOptionsMenu(item, evnt, isEditable),
                hide: item => _.every(item.Fields, x => !x.displayOptions || (!x.displayOptions.inEditor || x.displayOptions.hidden))
            };
        }
        function getEmailEditorPreviewPopupButton() {
            return {
                label: 'Preview',
                icon: 'preview',
                toolbar: 'floating',
                action: (item, evnt) => item.InPopup ? goToNextTabGroup(item) : showEmailPreviewDialog(item, evnt.element.$).catch(error => messageService.showErrorToast(error.msg, error.parent, error.hideDelay))
            };
        }
        function getEmailEditorButtons() {
            return [
                getEmailEditorOptionsButton(),
                getEmailEditorPreviewPopupButton()
            ];
        }
        function goToNextTabGroup(item) {
            item.data.$popupScope.$broadcast('nextGroup');
        }
        function createEditorOptionsMenu(email) {
            if (!email.data) {
                email.data = {};
            }
            email.data.inEditorFormDirty = false;
            email.data.editorOptionsPanel = $mdPanel.create({
                attachTo: angular.element(document.body),
                controller: $scope => {
                    $scope.$watch('vm.item.data.inEditorForm.$dirty', (newVal, oldVal) => {
                        // Only act on changes done by a user, not the initial state update
                        // If oldVal == undefined that indicates that this component was just initialized in which
                        // case do not set this property because it will be changed by the "on open" event
                        if (newVal != oldVal && newVal !== undefined && oldVal !== undefined) {
                            email.data.inEditorFormDirty = newVal;
                        }
                    });
                },
                controllerAs: 'vm',
                locals: {
                    fields: email.Fields,
                    id: email.Id,
                    item: email
                },
                template: `<md-card class="editor-options-card">
                        <md-card-content>
                            <form name="vm.item.data.inEditorForm">
                                <form-single-field ng-repeat="field in vm.fields"
                                       field="field"
                                       id="vm.id"
                                       item="vm.item"
                                       editable="vm.editable"
                                       form-group-active="true"
                                       ng-if="field.displayOptions.inEditor"
                                >
                                </form-single-field>
                            </form>
                        </md-card-content>
                    </md-card>`,
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: true
            });
            // Attach to the dom so that the field components are created so that any change handlers can
            // function even before the options panel is opened by the user
            email.data.editorOptionsPanel.attach().then(angular.noop, angular.noop);
        }
        function showEditorOptionsMenu(email, evnt, isEditable) {
            if (!email.data) {
                email.data = {};
            }
            let wasDirty = email.data.inEditorFormDirty;
            // Detach and re-attach the panel
            // Ensures positioning is correct and so that controller locals can be updated (e.g. editable)
            email.data.editorOptionsPanel.detach().then(() => {
                let targetElement = evnt.container.getParent().getParent().findOne('.cke_button__options').$;
                email.data.editorOptionsPanel.config.locals.editable = isEditable;
                email.data.editorOptionsPanel.updatePosition($mdPanel.newPanelPosition().relativeTo(targetElement).addPanelPosition($mdPanel.xPosition.CENTER, $mdPanel.yPosition.BELOW));
                email.data.editorOptionsPanel.updateAnimation($mdPanel.newPanelAnimation().openFrom(targetElement).duration(200).closeTo(targetElement).withAnimation($mdPanel.animation.SCALE));
                email.data.editorOptionsPanel.open().then(() => {
                    if (wasDirty) {
                        email.data.inEditorForm.$setDirty();
                    }
                });
            });
        }
        function showSaveAsEmailTemplateDialog(item) {
            var prompt = $mdDialog.prompt({
                bindToController: true,
                locals: { required: true }
            }).multiple(true).title('Save as Template').htmlContent(`Please enter the Template Name for the new template`).ok('Save').cancel('Cancel');
            return $mdDialog.show(prompt).then(templateName => {
                return vm.api.saveBlastAsEmailTemplate(templateName, item);
            }, $q.reject());
        }
        function showEmailPreviewDialog(email, targetElement, $event) {
            let parent = targetElement.closest('md-card');
            if (!formService.getFieldValueFromItem(email, 'Subject')) {
                return $q.reject({
                    msg: 'Email subject is required',
                    parent,
                    hideDelay: 3000
                });
            }
            if (!formService.getFieldValueFromItem(email, 'Body')) {
                return $q.reject({
                    msg: 'Email body is required',
                    parent,
                    hideDelay: 3000
                });
            }
            return $mdDialog.show({
                multiple: true,
                clickOutsideToClose: true,
                templateUrl: `email-preview-dialog.tpl.html`,
                controllerAs: 'vm',
                bindToController: true,
                targetEvent: $event,
                locals: {
                    email,
                    title: 'Email Preview'
                },
                controller: emailPreviewDialogController
            });
            function emailPreviewDialogController($mdDialog) {
                this.cancel = function () {
                    $mdDialog.hide();
                };
            }
        }
        function overrideEmailConfirm() {
            return $mdDialog.show($mdDialog.confirm().multiple(true).clickOutsideToClose(true).title('Unsaved Changes').htmlContent(`Changing templates will lose any unsaved changes. Do you want to continue?`).targetEvent(event).ok('Change Template').cancel('Cancel'));
        }
        function addComposeEmailLogic(email) {
            if (!email)
                return;
            let templateField = formService.getFieldFromItem(email, 'Template');
            templateField.loading = true;
            vm.api.getAdminEmailTemplates().then(r => {
                let templates = r.items;
                templateField.loading = false;
                templateField.options = _.reduce(templates, (result, value) => {
                    result[formService.getFieldValueFromItem(value, 'Id')] = value.Title;
                    return result;
                }, {});
                let fieldsToUpdate = [
                    'Subject',
                    'Body',
                    [
                        'Options',
                        'Disable Header'
                    ],
                    [
                        'Options',
                        'Disable Footer'
                    ]
                ];
                templateField.addPrefillOptions && templateField.addPrefillOptions(templates, fieldsToUpdate, overrideEmailConfirm, formService.getItemById);
            });
        }
    }
}());
(function () {
    angular.module('app').factory('emailDomainsService', emailDomainsService);
    function emailDomainsService($http, $q, API_PREFIX) {
        const url = `${ API_PREFIX }DomainAuthentication`;
        var DOMAIN_STATUSES = {
            0: 'Unverified',
            1: 'Pending',
            2: 'Verified'
        };
        var DOMAIN_STATUS_KEYS = swapArrayForKeys(DOMAIN_STATUSES);
        return {
            getEmailDomains,
            getEmailDomainDetails,
            getEmailDomainStatus,
            getDomainStatusText,
            isVerified,
            verifyDomain,
            addDomain,
            removeDomain,
            DOMAIN_STATUSES,
            DOMAIN_STATUS_KEYS
        };
        function getEmailDomainDetails(domain) {
            return $http.post(`${ url }/GetEmailDomainDetails`, { domain }).then(response => response.data.emailDomainDetails);
        }
        function getEmailDomains() {
            return $http.post(`${ url }/GetAllEmailDomains`).then(response => response.data.allEmailDomains);
        }
        function getEmailDomainStatus(domain) {
            return $http.post(`${ url }/GetEmailDomainStatus`, { domain }).then(response => response.data.verified);
        }
        function addDomain(domain) {
            return $http.post(`${ url }/AddDomain`, { domain }).then(response => response.data);
        }
        function verifyDomain(domain) {
            const messageConfig = { suppressErrorHandling: true };
            return $http({
                url: `${ url }/VerifyDomain`,
                data: { domain },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function removeDomain(domain) {
            return $http.post(`${ url }/RemoveDomain`, { domain }).then(response => response.data.success);
        }
        function getDomainStatusText(status) {
            return DOMAIN_STATUSES[status];
        }
        function isVerified(status) {
            return status == DOMAIN_STATUS_KEYS.VERIFIED;
        }
        function swapArrayForKeys(arr) {
            var ret = {};
            for (var key in arr) {
                ret[arr[key].toUpperCase()] = key;
            }
            return ret;
        }
    }
}());
(function () {
    angular.module('app').factory('enduranceChallengeService', enduranceChallengeService);
    function enduranceChallengeService($http, API_PREFIX, entityService, messageService) {
        const ENDURANCE_ACTIONS = {
            CREATE: 1,
            EDIT: 2,
            REMOVE: 3,
            ADOPT_PARENT: 4,
            OVERRIDE_PARENT: 5,
            DELETE_CHILD_OVERRIDES: 6
        };
        var errorHandler = { advancedErrorFunction: (currentMessageService, r) => messageService.showErrorDialogFromErrorsObj(r.data.errors) };
        return {
            getEnduranceForm: () => sendRequest('GetEnduranceForm'),
            updateEnduranceChallenge: data => sendRequest('UpdateEnduranceChallenge', data, errorHandler),
            createEnduranceChallenge: data => sendCreateDeleteRequest(ENDURANCE_ACTIONS.CREATE, data, errorHandler),
            removeEnduranceChallenge: data => sendCreateDeleteRequest(ENDURANCE_ACTIONS.REMOVE, data),
            adoptParent: () => sendCreateDeleteRequest(ENDURANCE_ACTIONS.ADOPT_PARENT),
            overrideParent: () => sendCreateDeleteRequest(ENDURANCE_ACTIONS.OVERRIDE_PARENT),
            clearOverrides: () => sendCreateDeleteRequest(ENDURANCE_ACTIONS.DELETE_CHILD_OVERRIDES)
        };
        function sendCreateDeleteRequest(actionType, data, errorHandler) {
            data = data || {};
            data.actionType = actionType;
            return sendRequest('CreateDeleteEnduranceChallenge', data, errorHandler);
        }
        function sendRequest(actionName, data, messageConfig) {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: `${ API_PREFIX }Cmp/${ actionName }`,
                data,
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
    }
}());
(function () {
    angular.module('app').service('endurancePledgeService', endurancePledgeService);
    function endurancePledgeService(requestHelperService, formService) {
        const vm = this;
        const rh = requestHelperService.getInstance('EndurancePledge');
        const endurancePledgeState = {
            1: 'Active',
            2: 'Suspended',
            3: 'Ended',
            4: 'Deleted',
            5: 'Charge Failed',
            6: 'Unfulfilled'
        };
        return {
            endurancePledgeState,
            getNewPledgeForm: () => rh.sendEntityRequest('AddForm'),
            addPledge: item => rh.sendCampaignRequest('Add', { item: formService.getBaseFormItem(item) }, rh.successToastMessage('Endurance Pledge has been added.')),
            savePledge: item => rh.sendCampaignRequest('Edit', { item: formService.getBaseFormItem(item) }),
            deletePledge: item => rh.sendCampaignRequest('Delete', { item: formService.getBaseFormItem(item) }),
            endPledge: item => rh.sendCampaignRequest('End', { item: formService.getBaseFormItem(item) }),
            chargePledge: item => rh.sendCampaignRequest('Charge', { item: formService.getBaseFormItem(item) }),
            getExistingUsers: () => rh.sendExtJsRequest('Member/AllMembersSimple', {
                limit: 10,
                page: 0,
                start: 0,
                noPaging: true
            })
        };
    }
}());
(function () {
    angular.module('app').factory('entityService', entityService);
    function entityService($http, campaignService, orgService, API_PREFIX, $q, $location, $rootRouter, $timeout) {
        let allEntities = getEntitiesMap();
        let entityCampaignsCache = {};
        return {
            updateAllEntities,
            getOrgEntityId,
            getOrgId,
            getInfo,
            getEntityName,
            getOpenedEntityId,
            getOpenedEntityIdMove,
            getOpenedGroupId,
            getOpenedCampaignId,
            moveGroups,
            moveCampaignsToGroup,
            getEntityCampaigns,
            createNewCampaignGroup,
            renameGroup,
            removeGroup,
            newCmpDonation,
            newCmpTicket,
            newCmpFromTemplate,
            getRawEntityById,
            getEntityFromCmpId,
            getEntitySettings,
            getEntityWithSettings,
            getEntityFromInstanceId,
            getCurrentEventId,
            getOpenEntity,
            getOpenEventId,
            refreshEntityAndComponent
        };
        function updateAllEntities() {
            allEntities = getEntitiesMap();
        }
        function getOrgEntityId() {
            return orgService.getOrg().entityId;
        }
        function getOrgId() {
            return orgService.getOrg().orgId;
        }
        function getOpenedCampaignId() {
            const campaignPath = orgService.getUrlCampaignPath();
            if (campaignPath) {
                const campaignHost = orgService.getUrlHost();
                const campaign = campaignService.getCampaignByUrl(campaignHost + '/' + campaignPath);
                return campaign && campaign.campaignId;
            }
            return orgService.getUrlCampaignId();    //should probably be getting a different way
        }
        function getOpenedEntityId() {
            let openedCampaignId = getOpenedCampaignId();
            if (openedCampaignId) {
                let openedCampaign = campaignService.getCampaignById(openedCampaignId);
                if (openedCampaign) {
                    return campaignService.getCampaignById(openedCampaignId).entityId;
                } else {
                    return -1;
                }
            }
            let openedGroup = campaignService.getOpenedGroup();
            let openedGroupEntityId = openedGroup ? openedGroup.entityId : null;
            return openedGroupEntityId || getOrgEntityId();
        }
        function getOpenedEntityIdMove() {
            let openedGroupEntityId = campaignService.getOpenedGroupMove().entityId;
            return openedGroupEntityId || getOrgEntityId();
        }
        function getOpenedGroupId() {
            return campaignService.getOpenedGroup().groupId || 0;
        }
        function getEntitiesMap() {
            let campaigns = campaignService.getRawCampaigns();
            let groups = campaignService.getRawGroups();
            return Object.assign({}, _.keyBy(groups, 'entityId'), _.keyBy(campaigns, 'entityId'), _.keyBy([orgService.getOrg()], 'entityId'));
        }
        function getEntityIdFromUrl() {
            const campaignPath = orgService.getUrlCampaignPath();
            if (campaignPath) {
                const campaignHost = orgService.getUrlHost();
                const campaign = campaignService.getCampaignByUrl(campaignHost + '/' + campaignPath);
                return campaign && campaign.entityId;
            }
            var pathSplit = location.pathname.split('/');
            var campaignIndex = pathSplit.indexOf('campaign');
            if (campaignIndex > -1 && pathSplit.length > campaignIndex) {
                return campaignService.getCampaignById(pathSplit[campaignIndex + 1]).entityId;
            }
            var groupIndex = pathSplit.indexOf('group');
            if (groupIndex > -1 && pathSplit.length > groupIndex) {
                return campaignService.getGroupById(pathSplit[groupIndex + 1]).entityId;
            }
            return orgService.getOrg().entityId;
        }
        function getOpenEntity() {
            var entityId = getEntityIdFromUrl();
            return getRawEntityById(entityId);
        }
        function getRawEntityById(entityId) {
            if (!allEntities[entityId]) {
                allEntities = getEntitiesMap();
            }
            return allEntities[entityId];
        }
        function getEntitySettings(entityId) {
            var id = entityId || getEntityIdFromUrl();
            var entity = getEntityWithSettings(id);
            if (entity.entityType === 'Org' || entity.settings.loaded) {
                return $q.resolve(entity);
            } else {
                return refreshEntitySettings(id);
            }
        }
        function getEntityWithSettings(entityId) {
            var entity = getRawEntityById(entityId);
            if (entity.entityType === 'Campaign') {
                return campaignService.getCampaignByEntityId(entityId);
            } else if (entity.entityType === 'CampaignGroup') {
                return campaignService.getGroupByEntityId(entityId);
            } else {
                return entity;
            }
        }
        function refreshEntitySettings(entityId) {
            return getInfo(entityId).then(r => {
                if (r.entityType === 'Campaign') {
                    r.campaignUrl = orgService.getCampaignPath(r);
                    if (r.masterEventId) {
                        r.masterCampaignUrl = orgService.getCampaignPath(campaignService.getCampaignByEventId(r.masterEventId));
                    }
                    campaignService.replaceRawCampaign(r);
                    return r;
                } else if (r.entityType === 'CampaignGroup') {
                    campaignService.replaceRawGroup(r);
                    return r;
                }
            });
        }
        function getEntityName(entityId) {
            return entityId == getOrgEntityId() ? orgService.getOrg().name : allEntities[entityId].name;
        }
        function getInfo(entityId) {
            return $http({
                url: API_PREFIX + '/GetInfoByEntity',
                headers: { entityId },
                method: 'POST'
            }).then(response => response.data.data);
        }
        function getEntityCampaigns(entityId, useCache) {
            if (useCache && entityCampaignsCache[entityId]) {
                return $q.resolve(entityCampaignsCache[entityId]);
            } else {
                return $http({
                    url: API_PREFIX + '/GetCampaignsOnEntity',
                    headers: { entityId },
                    method: 'POST'
                }).then(response => {
                    entityCampaignsCache[entityId] = response.data.campaigns;
                    return response.data.campaigns;
                });
            }
        }
        function moveGroups(fromEntityId, entityIds, toEntityId, messageConfig) {
            return $http({
                url: API_PREFIX + '/MoveGroup',
                headers: {
                    fromEntityId: fromEntityId,
                    entityIds: entityIds,
                    toEntityId: toEntityId
                },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function moveCampaignsToGroup(fromEntityId, entityIds, toEntityId, messageConfig) {
            return $http({
                url: API_PREFIX + '/MoveCampaignToGroup',
                headers: {
                    fromEntityId: fromEntityId,
                    entityIds: entityIds,
                    toEntityId: toEntityId
                },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function createNewCampaignGroup(entityIdParentGroup, name, messageConfig) {
            return $http({
                url: API_PREFIX + '/NewCampaignGroup',
                data: { name: name },
                headers: { parentEntityId: entityIdParentGroup },
                method: 'POST',
                messageConfig
            }).then(response => {
                return response;
            });
        }
        function renameGroup(groupId, name) {
            return $http({
                url: API_PREFIX + '/RenameGroup',
                data: { name: name },
                headers: { groupId: groupId },
                method: 'POST'
            }).then(response => response.data.success);
        }
        function removeGroup(entityId) {
            return $http({
                url: `${ API_PREFIX }/RemoveGroup`,
                headers: { entityId: entityId },
                method: 'POST'
            }).then(response => {
                _removeGroupCache(entityId);
                return response.data.success;
            });
        }
        function _removeGroupCache(entityId) {
            let groups = campaignService.getRawGroups(), index = groups.findIndex(item => item.entityId == entityId);
            groups.splice(index, 1);
            campaignService.replaceRawGroups(groups);
        }
        function newCmpDonation(parentEntityId, campaignData, messageConfig) {
            return $http({
                url: API_PREFIX + '/NewCmpDonation',
                data: campaignData,
                headers: { parentEntityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data.cmp);
        }
        function newCmpTicket(parentEntityId, campaignData, messageConfig) {
            return $http({
                url: API_PREFIX + '/NewCmpTicket',
                data: campaignData,
                headers: { parentEntityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data.cmp);
        }
        function newCmpFromTemplate(parentEntityId, campaignData, messageConfig) {
            return $http({
                url: API_PREFIX + '/NewCmpFromTemplate',
                data: campaignData,
                headers: { parentEntityId },
                method: 'POST',
                messageConfig
            }).then(response => response.data.cmp);
        }
        function getEntityFromCmpId(cmpId) {
            var cmp = campaignService.getCampaignById(cmpId);
            var entity = getRawEntityById(cmp.entityId);
            return entity;
        }
        function getEntityFromInstanceId(entity, cmpInstanceId) {
            return cmpInstanceId && entity.oldInstancesWithYrAndEvntId.find(c => c.cmpInstanceId == cmpInstanceId);
        }
        function getOpenEventId() {
            let entity = getOpenEntity();
            return entity ? getCurrentEventId(entity) : undefined;
        }
        function getCurrentEventId(entity) {
            if (entity.entityType == 'Campaign') {
                var instanceId = orgService.getUrlCampaignInstanceId();
                if (instanceId) {
                    return getEntityFromInstanceId(entity, instanceId).eventId;
                }
                return entity.curEventId;
            }
            return undefined;
        }
        function refreshEntityAndComponent(entityId) {
            let url = $location.path();
            refreshEntitySettings(entityId).then(() => {
                updateAllEntities();
                $rootRouter.navigateByUrl('/p/loading', { skipLocationChange: true }).then(() => {
                    $timeout(() => {
                        $rootRouter.navigateByUrl(url);
                    });
                });
            });
        }
    }
}());
(function () {
    angular.module('app').factory('entityContentService', entityContentService);
    function entityContentService($http, $q, API_PREFIX, messageService) {
        const messageConfig = {
            advancedErrorFunction: messageService.advancedErrorsDialog,
            advancedErrorEnabled: true
        };
        return {
            getLiquidTemplate,
            updateLiquidTemplatePage,
            getTemplatePlaceholders,
            createNewLiquidTemplatePage,
            getSocialShares,
            updateTerm,
            deleteTerm,
            getAllTerms,
            getFormTerms,
            getFundraisingTerms
        };
        function processDataOnServer(url, data, entityId) {
            return $http({
                url: url,
                data: data,
                headers: { entityId },
                method: 'POST',
                messageConfig
            }).then(response => response);
        }
        function getLiquidTemplate(entityId) {
            return processDataOnServer(`${ API_PREFIX }/GetLiquidTemplate`, {}, entityId).then(response => response.data.items);
        }
        function getSocialShares(entityId, language) {
            return processDataOnServer(`${ API_PREFIX }/AllSocialTerms`, { language: language }, entityId).then(response => response.data.items);
        }
        function getAllTerms(entityId, language) {
            return processDataOnServer(`${ API_PREFIX }/AllDictionaryTerms`, { language: language }, entityId).then(response => response.data.items);
        }
        function getFormTerms(entityId, language) {
            return processDataOnServer(`${ API_PREFIX }/CustomFormTerms`, { language: language }, entityId).then(response => response.data.items);
        }
        function getFundraisingTerms(entityId, language) {
            return processDataOnServer(`${ API_PREFIX }/CustomFundraisingTerms`, { language: language }, entityId).then(response => response.data.items);
        }
        function getTemplatePlaceholders(entityId, flag, activityType) {
            let data = {
                flag,
                activityType
            };
            return processDataOnServer(`${ API_PREFIX }/GetTemplatePlaceholder`, data, entityId).then(response => response.data.items);
        }
        function updateLiquidTemplatePage(entityId, id, fieldName, fieldValue) {
            let data = {
                //EntityTemplatePage object
                EntityTemplatePageID: id,
                PageName: fieldName,
                PageContent: fieldValue
            };
            return processDataOnServer(`${ API_PREFIX }/UpdateLiquidTemplatePage`, data, entityId).then(response => response.data.items);
        }
        function createNewLiquidTemplatePage(entityId, id, pageName, pageType, pageExt) {
            let data = {
                //EntityTemplatePage object
                EntityTemplateID: id,
                PageName: pageName,
                PageType: pageType,
                PageExt: pageExt,
                PageContent: ''
            };
            return processDataOnServer(`${ API_PREFIX }/CreateNewLiquidTemplatePage`, data, entityId).then(response => response.data.items);
        }
        function updateTerm(entityId, item, termType, language, createOverrideTerm) {
            item = mapTermItem(item, language);
            var url = `${ API_PREFIX }/Update${ termType }Term`;
            return processDataOnServer(url, {
                submittedTermDefinition: item,
                createOverrideTerm: createOverrideTerm
            }, entityId).then(response => response.data.items);
        }
        function deleteTerm(entityId, item, termType, language) {
            item = mapTermItem(item, language);
            var url = `${ API_PREFIX }/Delete${ termType }Term`;
            return processDataOnServer(url, { submittedTermDefinition: item }, entityId).then(response => response.data.items);
        }
        function mapTermItem(item, language) {
            if ((item.Flags & 1024) === 1024) {
                //1024: IsFileEntry
                language = 'file';
            }
            return {
                termId: item.Id,
                definition: CKEDITOR.instances[item.Id] && CKEDITOR.instances[item.Id].mode === 'source' ? CKEDITOR.instances[item.Id].getData() : item.Fields.find(r => r.name === 'Definition').value,
                language: language
            };
        }
    }
}());
(function () {
    angular.module('app').factory('extJsLoader', extJsLoader);
    function extJsLoader($rootScope, iframeMessageHandler, $q, $location) {
        var ExtJsIframeController = function (id) {
            this.id = id;
            this.iframeCreated = false;
            this.iframeLoaded = false;
            this.page;
            this.parentDivId;
            this.processMessage = function (message) {
                if (message.messageType === 'loaded') {
                    this.iframeLoaded = true;
                    $rootScope.$emit(message.messageType, []);
                }
                if (message.messageType === 'updateHeight') {
                    this.updateIframeSize(message.data);
                }
                if (message.messageType === 'moveDonations' || message.messageType === 'moveRecurringDonations' || message.messageType === 'fulfillDonationPledges') {
                    $rootScope.$emit(message.messageType, message.data);
                }
                if (message.messageType == 'emailFromReports') {
                    $rootScope.$emit(message.messageType, []);
                }
            };
            this.messageToExtJs = function (message) {
                message.pageSource = this.id;
                var iframe = document.querySelectorAll('iframe[name="' + this.id + '"]');
                if (iframe[0]) {
                    iframe[0].contentWindow.postMessage(JSON.stringify(message), location.origin);
                }
            };
            this.messageToExtJsWithResponse = function (message) {
                var def = $q.defer();
                var responderId = new Date().valueOf();
                iframeMessageHandler.rbPlatIframeController.addPromise(responderId, def);
                message.respondToMessage = true;
                message.promiseId = responderId;
                this.messageToExtJs(message);
                return def.promise;
            }, this.createAndLoadIframe = function (iframeSrc, parentDivId) {
                this.page = '';
                this.iframeCreated = true;
                this.parentDivId = parentDivId;
                return {
                    src: iframeSrc + this.id,
                    name: this.id
                };
            };
            this.refreshInnerPage = function () {
                return this.messageToExtJsWithResponse({ messageType: 'refresh' });
            };
            this.loadInnerPage = function () {
                if (!this.page) {
                    return $q.resolve('no page');
                }
                return this.messageToExtJsWithResponse({
                    messageType: 'update',
                    value: this.page
                });
            };
            this.updateInnerPage = function (page) {
                this.page = page;
                if (this.iframeLoaded) {
                    return this.loadInnerPage();
                }
            };
            this.updateIframeSize = function (data) {
                angular.element(`#${ this.parentDivId }-iframe`).find('iframe').height(data.height);
            };
            this.checkParams = function () {
                var params = $location.search();
                if (params.type) {
                    // safely remove the type key from the path
                    setTimeout(function () {
                        var newSearch = Object.assign({}, params);
                        delete newSearch.type;
                        window.history.pushState({}, document.title, window.location.pathname + '?' + new URLSearchParams(newSearch).toString());
                    }, 1000);
                    this.messageToExtJs({
                        messageType: 'openMini',
                        params: params
                    });
                }
            };
        };
        let extJsController = new ExtJsIframeController(new Date().valueOf());
        return { extJsController };
    }
}());
(function () {
    angular.module('app').factory('fileUpload', fileUpload);
    function fileUpload($rootScope) {
        return { onUploadFile };
        function onUploadFile(event, entityId, imgType, objectId) {
            event.preventDefault();
            let file = event.currentTarget.files[0];
            if (!file)
                return;
            //if open upload window, but click cancel
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = event => {
                $rootScope.$emit('file.uploaded', {
                    img: event.target.result,
                    entityId: entityId,
                    imgType: imgType,
                    objectId: objectId
                });
            };
        }
    }
}());
(function () {
    angular.module('app').service('formService', formService);
    function formService() {
        const vm = this;
        return {
            getBaseFormItem,
            getItemById,
            getItemByRecordId,
            getFieldFromItem,
            getFieldValueFromItem,
            getSubFieldFromItem,
            getSubFieldValueFromItem,
            getFieldOrSubFieldFromItem,
            setFieldValueOnItem
        };
        function getBaseFormItem(item) {
            let baseItem = _.cloneDeep(item);
            if (baseItem.Fields) {
                _.forEach(baseItem.Fields, field => {
                    if (field.fieldType === 'Textarea' && CKEDITOR.instances[field.name + '-' + item.Id] && CKEDITOR.instances[field.name + '-' + item.Id].mode === 'source') {
                        field.value = CKEDITOR.instances[field.name + '-' + item.Id].getData();
                    }
                    if (field.fieldConditionShouldHide && field.fieldConditionShouldHide(baseItem)) {
                        field.value = null;
                    }
                    // Remove view data - no need to send back to server
                    delete field.options;
                    delete field.groupedOptions;
                    delete field.optionsMetadata;
                    delete field.displayOptions;
                    delete field.grid;
                });
            }
            delete baseItem.data;
            return baseItem;
        }
        function getItemById(items, id) {
            return items.find(x => getFieldValueFromItem(x, 'Id') == id);
        }
        function getItemByRecordId(items, id) {
            return items.find(x => x.Id === id);
        }
        function getFieldFromFields(fields, field) {
            return fields.find(x => x.name === field);
        }
        function getFieldFromItem(item, field) {
            if (Array.isArray(field)) {
                if (field.length === 1) {
                    field = field[0];
                } else {
                    return getSubFieldFromItem(item, field[0], field[1]);
                }
            }
            return getFieldFromFields(item.Fields, field);
        }
        function getFieldValueFromItem(item, field) {
            if (Array.isArray(field)) {
                return getSubFieldValueFromItem(item, field[0], field[1]);
            }
            let itemField = getFieldFromItem(item, field);
            return itemField ? itemField.value : undefined;
        }
        function getSubFieldFromItem(item, field, subField) {
            let itemField = getFieldFromItem(item, field);
            return itemField ? getFieldFromFields(itemField.value, subField) : undefined;
        }
        function getSubFieldValueFromItem(item, field, subField) {
            let itemSubField = getSubFieldFromItem(item, field, subField);
            return itemSubField ? itemSubField.value : undefined;
        }
        function getFieldOrSubFieldFromItem(item, field, subField) {
            if (subField) {
                return getSubFieldFromItem(item, field, subField);
            }
            return getFieldFromItem(item, field);
        }
        function setFieldValueOnItem(item, field, value, addIfNotFound, fieldType, displayOptions) {
            let itemField = getFieldFromItem(item, field);
            if (itemField) {
                itemField.value = value;
            } else if (addIfNotFound) {
                itemField = {
                    name: field,
                    value,
                    displayOptions: displayOptions || {},
                    fieldType: fieldType
                };
                item.Fields.push(itemField);
            }
        }
    }
}());
(function () {
    angular.module('app').factory('fundraiserPdfService', fundraiserPdfService);
    function fundraiserPdfService($http, API_PREFIX, entityService) {
        return {
            getTemplate,
            updateTemplate
        };
        function getTemplate() {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: `${ API_PREFIX }Cmp/GetFundraiserPdfTemplate`,
                headers: { entityId },
                method: 'POST'
            }).then(response => response.data);
        }
        function updateTemplate(template) {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: `${ API_PREFIX }Cmp/UpdateFundraiserPdfTemplate`,
                headers: { entityId },
                data: { template },
                method: 'POST'
            }).then(response => response.data);
        }
    }
}());
(function () {
    angular.module('app').service('helpersService', helpersService);
    function helpersService() {
        const vm = this;
        vm.api = {
            getUserDisplayName: (value, includedParts = []) => {
                var wholeName = `${ value['FirstName'] || '' } ${ value['LastName'] || '' }`.trim();
                if (!wholeName.length) {
                    wholeName += value['CompanyName'];
                }
                if (includedParts.length) {
                    wholeName += '<br>';
                    if (includedParts.includes('MemberId')) {
                        wholeName += `<small>#${ value['MemberId'] }</small> `;
                    }
                    if (includedParts.includes('UserAccountId')) {
                        wholeName += `<small>#${ value['UserAccountId'] }</small> `;
                    }
                    if (includedParts.includes('Email') && value['Email']) {
                        wholeName += `<small>${ value['Email'] }</small> `;
                    }
                }
                return wholeName;
            },
            normalizeUserPropertyNames: value => {
                value.FirstName = value.firstName;
                value.LastName = value.lastName;
                value.CompanyName = value.companyName;
                value.UserAccountId = value.UserAccountID;
                value.MemberId = value.MemberID;
                return value;
            }
        };
        return vm.api;
    }
}());
(function () {
    angular.module('app').factory('iframeMessageHandler', iframeMessageHandler);
    function iframeMessageHandler($q) {
        let rbPlatIframeController = {
            rbMessagePrefix: '_rb',
            hasMessageEventListener: false,
            messageListeningHandlers: {},
            promiseCollection: {},
            trustedSources: [],
            loadMessageListener: function (trustedUrl) {
                if (this.trustedSources.indexOf(trustedUrl) < 0) {
                    this.trustedSources.push(trustedUrl);
                }
                if (this.hasMessageEventListener) {
                    return;
                }
                window.addEventListener('message', this.processMessage, false);
                this.hasMessageEventListener = true;
            },
            processMessage: function (event) {
                var me = rbPlatIframeController;
                if (!event.origin || me.trustedSources.indexOf(event.origin) < 0) {
                    return;
                }
                var message;
                try {
                    message = JSON.parse(event.data);
                } catch (e) {
                    // can be CKEditor, in any case it is not an iframe message (see ckeditor-cors-fix.js)
                    return;
                }
                var pageSource = message.pageSource;
                if (!pageSource || !pageSource.indexOf(me.rbMessagePrefix) === 0) {
                    return;
                }
                var handler = me.messageListeningHandlers[pageSource.replace(me.rbMessagePrefix, '')];
                if (handler) {
                    handler.processMessage(message);
                    if (message.respondToMessage) {
                        var messagePromise = me.promiseCollection[message.promiseId];
                        if (messagePromise) {
                            messagePromise.resolve(message);
                        }
                    }
                }
            },
            removeListener: function () {
                window.removeEventListener('message', this.processMessage, false);
                this.hasMessageEventListener = false;
                this.messageListeningHandlers = {};
            },
            addHandler: function (handlerId, handler) {
                this.messageListeningHandlers[handlerId] = handler;
            },
            addPromise: function (id, promise) {
                this.promiseCollection[id] = promise;
            }
        };
        return {
            loadMessageListenerAndSetHandler,
            rbPlatIframeController
        };
        function loadMessageListenerAndSetHandler(url, handler) {
            rbPlatIframeController.loadMessageListener(url);
            rbPlatIframeController.addHandler(handler.id, handler);
        }
    }
}());
(function () {
    angular.module('app').factory('messageService', messageService);
    function messageService($mdToast, $mdDialog, $q) {
        let defaultConfig = {
            errorToastEnabled: true,
            successToastEnabled: false,
            requestInProgressToastEnabled: false,
            advancedErrorEnabled: true,
            fallbackErrorMsg: 'An unexpected error has occurred, please try again.',
            overrideErrorMsg: undefined,
            accessDeniedMsg: 'You do not have sufficient permission to access this resource.',
            successMsg: 'Action completed successfully.',
            requestInProgressMsg: 'Request in progress.',
            advancedErrorFunction: undefined,
            successActionFunction: undefined,
            successActionTitle: undefined,
            suppressErrorHandling: false
        };
        let pendingRequests = {};
        function checkSetPendingRequest(url, data) {
            if (pendingRequests[requestIdentifier(url, data)])
                return true;
            pendingRequests[requestIdentifier(url, data)] = true;
            return false;
        }
        function resetPendingRequest(url, data) {
            delete pendingRequests[requestIdentifier(url, data)];
        }
        function requestIdentifier(url, data) {
            return JSON.stringify([
                url,
                data
            ]);
        }
        function showErrorToast(msg, parentElm, hideDelay) {
            return $mdToast.show($mdToast.simple().textContent(msg || defaultConfig.fallbackErrorMsg).position('top right').action('\u2716').hideDelay(hideDelay).parent(parentElm).toastClass('toast-error')).then(response => {
                if (response == 'ok')
                    return $mdToast.hide();
            }, angular.noop);
        }
        function showSuccessToast(msg, actionTitle, actionFunction) {
            return $mdToast.show($mdToast.simple().textContent(msg || defaultConfig.successMsg).action(actionTitle).position('top right').hideDelay(3000).toastClass('toast-success')).then(response => {
                if (response == 'ok' && actionFunction)
                    return actionFunction();
            }, angular.noop);
        }
        function showMsgDialog(title, htmlContent) {
            return $mdDialog.show($mdDialog.alert({
                title: title,
                htmlContent: htmlContent,
                ok: 'Close',
                // skipHide: true
                //http://stackoverflow.com/questions/30724239/mddialog-stacked-nested-dialogs-is-it-possible
                multiple: true
            }));
        }
        function showAccessDeniedDialog(msg) {
            if (angular.element(document).find('md-dialog').length > 0) {
                var dialogs = angular.element(document).find('md-dialog');
                for (var i = 0; i < dialogs.length; i++) {
                    if (dialogs[i].innerText.startsWith('Access Denied')) {
                        return;
                    }
                }
            }
            return showMsgDialog('Access Denied', msg || defaultConfig.accessDeniedMsg);
        }
        function showErrorDialog(htmlContent) {
            return showMsgDialog('Error', htmlContent);
        }
        function showErrorDialogWithFirstError(currentMessageService, response) {
            let errorStr = 'An error occured';
            if (response && response.data && response.data.errors && response.data.errors[0]) {
                errorStr = response.data.errors[0].error;
            }
            return showErrorDialog(errorStr);
        }
        function showErrorDialogFromErrorsObj(errors, introMsg) {
            let errorStr = (introMsg || 'The following errors occurred:') + '<br>';
            angular.forEach(errors, error => {
                errorStr += '<br><b>' + error.ID + ': </b>' + error.error;
            });
            return showErrorDialog(errorStr);
        }
        function advancedErrorsDialog(currentMessageService, response) {
            return showErrorDialogFromErrorsObj(response.data.errors);
        }
        messageService.showErrorToast = showErrorToast;
        messageService.showSuccessToast = showSuccessToast;
        messageService.showAccessDeniedDialog = showAccessDeniedDialog;
        messageService.checkSetPendingRequest = checkSetPendingRequest;
        messageService.resetPendingRequest = resetPendingRequest;
        messageService.advancedErrorsDialog = advancedErrorsDialog;
        messageService.showErrorDialogFromErrorsObj = showErrorDialogFromErrorsObj;
        messageService.showErrorDialogWithFirstError = showErrorDialogWithFirstError;
        messageService.showErrorDialog = showErrorDialog;
        messageService.showMsgDialog = showMsgDialog;
        function messageService(config) {
            //TODO: Return new if not used with new keyword
            config = angular.extend({}, defaultConfig, config);
            this.config = config;
            this.showErrorToast = msg => {
                if (config.errorToastEnabled)
                    showErrorToast(config.overrideErrorMsg || msg || config.fallbackErrorMsg);
            };
            this.showSuccessToast = msg => {
                if (config.successToastEnabled)
                    showSuccessToast(msg || config.successMsg, config.successActionTitle, config.successActionFunction);
            };
            this.showAccessDeniedDialog = msg => showAccessDeniedDialog(config.accessDeniedMsg || msg);
            this.showRequestInProgressToast = msg => {
                if (config.requestInProgressToastEnabled)
                    showErrorToast(msg || config.requestInProgressMsg);
            };
            this.showAdvancedError = response => {
                if (config.advancedErrorEnabled) {
                    config.advancedErrorFunction ? config.advancedErrorFunction(this, response) : this.showErrorToast();
                }
            };
            this.checkSetPendingRequest = checkSetPendingRequest;
            this.resetPendingRequest = resetPendingRequest;
        }
        return messageService;
    }
}());
(function () {
    angular.module('app').factory('nationalTeamsService', nationalTeamsService);
    function nationalTeamsService($q, $http, API_PREFIX, entityService, configSettingsService, messageService) {
        let nationalTeams = null;
        const NT_CONFIG_ID = 506;
        const messageConfig = {
            advancedErrorFunction: messageService.advancedErrorsDialog,
            advancedErrorEnabled: true
        };
        return {
            getEnabledNationalTeams,
            getEntityNationalTeams,
            getNonEnabledNationalTeams,
            addNationalTeamManager,
            removeNationalTeamManager,
            createNationalTeam,
            removeNationalTeam,
            editNationalTeam,
            addNationalTeam,
            NT_CONFIG_ID
        };
        function getNationalTeams(entityId) {
            if (nationalTeams) {
                return $q.resolve(nationalTeams);
            }
            return $http({
                url: API_PREFIX + 'Cmp/GetOrgNationalTeams',
                method: 'POST',
                headers: { entityId }
            }).then(response => {
                nationalTeams = response.data.nationalTeams;
                return nationalTeams;
            });
        }
        function getNonEnabledNationalTeams(ids) {
            return getNationalTeams(entityService.getOpenedEntityId()).then(r => {
                return r.filter(x => !ids.includes(x.TeamId));
            });
        }
        function getEntityNationalTeams(ids, setting) {
            return getNationalTeams(entityService.getOpenedEntityId()).then(r => {
                return {
                    nationalTeams: r.filter(x => ids.includes(x.TeamId)),
                    setting: setting
                };
            });
        }
        function getEnabledNationalTeams() {
            return configSettingsService.getNationalTeamSettings().then(res => {
                //for now we only care for the ids setting. we may have to loop through more settings of more configs are exposed
                if (!res.form.FormSections.length) {
                    return getEntityNationalTeams([], { Disabled: true });
                }
                let setting = res.form.FormSections.find(x => x.Label = 'National Teams').Fields.find(x => x.ConfigOptionID == NT_CONFIG_ID);
                let ids = setting.IntValues;
                return getEntityNationalTeams(ids, setting);
            });
        }
        function createNationalTeam(data) {
            return processDataOnServer(`${ API_PREFIX }Cmp/CreateNationalTeam`, data, true).then(response => {
                nationalTeams.push(response.data.nationalTeam);
                nationalTeams = sortNationalTeamsByName();
                return response.data.nationalTeam;
            });
        }
        function removeNationalTeam(nationalTeamId) {
            var data = { nationalTeamId: nationalTeamId };
            return processDataOnServer(`${ API_PREFIX }Cmp/RemoveNationalTeam`, data).then(response => response.data);
        }
        function addNationalTeamManager(nationalTeamId, email) {
            var data = {
                nationalTeamId: nationalTeamId,
                email: email
            };
            return processDataOnServer(`${ API_PREFIX }Cmp/AddNationalTeamManager`, data, true).then(response => {
                updateCachedNationalTeam(nationalTeamId, response.data.nationalTeam);
                return response.data;
            });
        }
        function removeNationalTeamManager(nationalTeamId, userAccountId) {
            var data = {
                nationalTeamId: nationalTeamId,
                userAccountId: userAccountId
            };
            return processDataOnServer(`${ API_PREFIX }Cmp/RemoveNationalTeamManager`, data).then(response => {
                updateCachedNationalTeam(nationalTeamId, response.data.nationalTeam);
                return response.data;
            });
        }
        function editNationalTeam(nationalTeamId, column, val) {
            var data = {
                nationalTeamId: nationalTeamId,
                val: val,
                column: column
            };
            return processDataOnServer(`${ API_PREFIX }Cmp/UpdateNationalTeam`, data, true).then(response => {
                updateCachedNationalTeam(nationalTeamId, response.data.nationalTeam);
                return response;
            });
        }
        function addNationalTeam(nationalTeamId) {
            var data = { nationalTeamId: nationalTeamId };
            return processDataOnServer(`${ API_PREFIX }Cmp/AddNationalTeam`, data).then(response => response.data);
        }
        function updateCachedNationalTeam(nationalTeamId, nationalTeam) {
            nationalTeams = nationalTeams.filter(t => t.TeamId != nationalTeamId);
            nationalTeams.push(nationalTeam);
            nationalTeams = sortNationalTeamsByName();
        }
        function processDataOnServer(url, data, handleErrorsLocally) {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: url,
                data: data,
                headers: { entityId },
                method: 'POST',
                messageConfig: handleErrorsLocally ? { errorToastEnabled: false } : messageConfig
            }).then(response => response);
        }
        function sortNationalTeamsByName() {
            return nationalTeams.sort((a, b) => {
                var nameOfa = a.TeamName.toLowerCase();
                var nameOfb = b.TeamName.toLowerCase();
                if (nameOfa < nameOfb)
                    return -1;
                else if (nameOfa > nameOfb)
                    return 1;
                return 0;
            });
        }
    }
}());
(function () {
    angular.module('app').factory('neonCrmService', neonCrmService);
    function neonCrmService($http, $q, API_PREFIX, crmSyncsService) {
        return {
            getNeonCrmLogs,
            reSync
        };
        function getNeonCrmLogs(neonOrgId, from, to) {
            return crmSyncsService.getCrmLogsForDates('NeonSyncs', neonOrgId, from, to);
        }
        function reSync(record) {
            // Don't send other fields back to server in case they have html
            let recordToSync = {
                NeonCrmQueueId: record.NeonCrmQueueId,
                EventId: record.EventId
            };
            return crmSyncsService.reSync(recordToSync, 'NeonCrmResync');
        }
    }
}());
(function () {
    angular.module('app').factory('ngModelFiltersService', ngModelFiltersService);
    function ngModelFiltersService($filter, entityService) {
        let currencyFilter = {
            filter: value => {
                if (isNaN(value)) {
                    return value;
                } else {
                    const currencyKey = entityService.getEntityWithSettings(entityService.getOpenedEntityId()).settings.DefaultCurrency.Symbol;
                    return $filter('currency')(value, currencyKey, 0);
                }
            },
            unfilter: value => {
                let newValue = value.replace(/[,$]/g, '');
                if (isNaN(newValue)) {
                    return value;
                } else {
                    return newValue;
                }
            }
        };
        let dateFilter = {
            filter: value => new Date(value),
            unfilter: value => value ? value.toISOString().substring(0, 10) : value
        };
        return {
            currencyFilter,
            dateFilter
        };
    }
}());
(function () {
    angular.module('app').factory('orgService', orgService);
    function orgService($q, $http, $location, $rootRouter, API_PREFIX, profileService) {
        let orgsList = null;
        let selectedOrg = null;
        let org;
        let globalSettings = {};
        let orgInfoLoadedDefer = $q.defer();
        let orgInfoLoadedPromise = orgInfoLoadedDefer.promise;
        return {
            setOrg,
            getOrg,
            saveEditOrg,
            getOrgInfo,
            getCachedOrg,
            getOrgId,
            updatePathToOrg,
            updatePathToGroup,
            updatePathToCampaign,
            getUrlOrgId,
            getUrlGroupId,
            getUrlCampaignId,
            getUrlCampaignInstanceId,
            orgInfoLoadedPromise,
            resolveOrgInfoLoadedPromise,
            getCampaignPath,
            getUrlHost,
            getUrlCampaignPath,
            getAvailableEventSeries
        };
        function getOrgInfo() {
            return $http({
                url: API_PREFIX + '/GetInfoByCurrentOrg',
                method: 'POST'
            }).then(response => {
                org = response.data.data;
                return org;
            });
        }
        function resolveOrgInfoLoadedPromise(orgInfo) {
            orgInfoLoadedDefer.resolve(orgInfo);
        }
        function getCachedOrg() {
            return org;
        }
        function setOrg(org) {
            selectedOrg = org;
            document.title = (window.brandingName || 'Neon Fundraise') + ' Administration - ' + org.name;
        }
        function getOrg() {
            return selectedOrg;
        }
        function getOrgId() {
            return selectedOrg ? selectedOrg.orgId : getUrlOrgId();
        }
        function saveEditOrg(orgId, field, value) {
            return $http({
                url: API_PREFIX + '/UpdateOrgInfo',
                data: {
                    field: field,
                    value: value
                },
                headers: { orgId: orgId },
                method: 'POST'
            });
        }
        function updatePathToOrg(append) {
            $location.path(getBaseUrl() + (append || ''));
        }
        function updatePathToGroup(group, append) {
            $location.path(getBaseUrl() + '/group/' + group.groupId + (append || ''));
        }
        function getCampaignPath(campaign, append) {
            if (!campaign)
                return;
            if (window.isChampion) {
                if (campaign.campaignId == window.championCampaignId) {
                    return getBaseUrl();
                }
                return '';
            }
            var instanceId = getUrlCampaignInstanceId();
            var instancePath = instanceId ? '/instance/' + instanceId : '';
            append = instancePath + (append || '');
            if (campaign.groupId) {
                return getBaseUrl() + '/group/' + campaign.groupId + '/campaign/' + campaign.campaignId + append;
            } else {
                return getBaseUrl() + '/campaign/' + campaign.campaignId + append;
            }
        }
        function updatePathToCampaign(campaign, append) {
            $location.path(getCampaignPath(campaign, append));
        }
        function getUrlOrgId() {
            return getUrlPathParam('orgId') || getOrgIdFromPath();
        }
        function getUrlGroupId() {
            return getUrlPathParam('groupId');
        }
        function getUrlCampaignId() {
            return getUrlPathParam('campaignId');
        }
        function getUrlCampaignInstanceId() {
            return getUrlPathParam('instanceId');
        }
        function getUrlCampaignPath() {
            return getUrlPathParam('campaignPath') || getCampaignPathFromPath();
        }
        function getUrlHost() {
            return $location.host();
        }
        function getAvailableEventSeries() {
            return $http({
                url: API_PREFIX + '/GetEventSeriesAvailable',
                method: 'POST'
            }).then(response => {
                return response.data.data;
            });
        }
        //private
        function getBaseUrl(orgId) {
            return window.isChampion ? window.championPrefix : '/org/' + (orgId || getOrgId());
        }
        function getUrlPathParam(key) {
            return $rootRouter._currentInstruction && $rootRouter._currentInstruction.component.params[key];
        }
    }
}());
(function () {
    angular.module('app').factory('orgIntegrationsService', orgIntegrationsService);
    function orgIntegrationsService($http, $q, API_PREFIX) {
        const url = `${ API_PREFIX }Integration`;
        return {
            getIntegrations,
            deleteIntegration,
            getIntegrationCampaigns,
            hasLogs,
            canDelete,
            hasInformation
        };
        function getIntegrations() {
            return $http.post(`${ url }/Integrations`).then(response => response.data.integrations);
        }
        function getIntegrationCampaigns(type, id) {
            return $http.post(`${ url }/IntegrationCampaigns`, {
                type,
                id
            }).then(response => response.data.campaigns);
        }
        function deleteIntegration(type, id) {
            if (!canDelete(type)) {
                return $q.reject('Unable to delete this type of integration. Please contact support');
            }
            return $http.post(`${ url }/RemoveIntegration`, {
                type,
                id
            }).then(response => response.data);
        }
        function hasLogs(type) {
            return [
                'Salesforce',
                'ClearView',
                'Neon'
            ].indexOf(type) >= 0;
        }
        function canDelete(type) {
            return ['Salesforce'].indexOf(type) >= 0;
        }
        function hasInformation(type) {
            return [
                'Salesforce',
                'ClearView'
            ].indexOf(type) >= 0;
        }
    }
}());
(function () {
    angular.module('app').factory('orgProcessorsService', orgProcessorsService);
    function orgProcessorsService($http, $q, API_PREFIX, entityService) {
        const url = `${ API_PREFIX }PaymentProcessor`;
        const PROCESSOR_CONFIG_ACTIONS = {
            CREATE: 1,
            EDIT: 2,
            REMOVE: 3,
            ADOPT_PARENT: 4,
            OVERRIDE_PARENT: 5,
            DELETE_CHILD_OVERRIDES: 6
        };
        const PROCESSOR_INSTANCE_ACTIONS = {
            MOVEUP: 1,
            MOMVEDOWN: 2,
            REMOVE: 3,
            ADD: 4
        };
        return {
            getProcessors,
            getEntityProcessorSettings,
            updateProcessorInstanceSetting,
            getAvailableProcessors,
            adoptParent: () => sendCreateDeleteRequest(PROCESSOR_CONFIG_ACTIONS.ADOPT_PARENT),
            overrideParent: () => sendCreateDeleteRequest(PROCESSOR_CONFIG_ACTIONS.OVERRIDE_PARENT),
            clearOverrides: () => sendCreateDeleteRequest(PROCESSOR_CONFIG_ACTIONS.DELETE_CHILD_OVERRIDES),
            moveInstanceUp: data => sendUpdateDeleteInstanceRequest(PROCESSOR_INSTANCE_ACTIONS.MOVEUP, data),
            moveInstanceDown: data => sendUpdateDeleteInstanceRequest(PROCESSOR_INSTANCE_ACTIONS.MOMVEDOWN, data),
            removeInstance: data => sendUpdateDeleteInstanceRequest(PROCESSOR_INSTANCE_ACTIONS.REMOVE, data),
            addInstance: data => sendUpdateDeleteInstanceRequest(PROCESSOR_INSTANCE_ACTIONS.ADD, data)
        };
        function getProcessors() {
            return $http.post(`${ url }/GetAllProcessors`).then(response => response.data.allProcessors);
        }
        function getAvailableProcessors() {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: `${ url }/GetAvailableProcessors`,
                headers: { entityId },
                method: 'POST'
            }).then(response => response.data.availableProcessors);
        }
        function getEntityProcessorSettings() {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: `${ url }/GetEntityProcessorSettings`,
                headers: { entityId },
                method: 'POST'
            }).then(response => response.data);
        }
        function updateProcessorInstanceSetting(data) {
            return $http({
                url: `${ url }/UpdateProcessorInstanceSetting`,
                data,
                method: 'POST'
            }).then(response => response.data);
        }
        function sendCreateDeleteRequest(actionType, data) {
            data = data || {};
            data.actionType = actionType;
            return sendRequest('CreateDeleteProcessorSettings', data);
        }
        function sendUpdateDeleteInstanceRequest(actionType, data) {
            data = data || {};
            data.actionType = actionType;
            return sendRequest('UpdateDeleteProcessorInstance', data);
        }
        function sendRequest(actionName, data) {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: `${ url }/${ actionName }`,
                data,
                headers: { entityId },
                method: 'POST'
            }).then(response => response.data);
        }
    }
}());
(function () {
    angular.module('app').factory('payPalService', payPalService);
    function payPalService($http, API_PREFIX, entityService, messageService) {
        const messageConfig = {
            advancedErrorFunction: messageService.advancedErrorsDialog,
            advancedErrorEnabled: true
        };
        return {
            loadPayPal,
            getPayPalInfo,
            createPayPalReferral,
            deactivatePayPal,
            reconnectPayPal
        };
        function loadPayPal() {
            var callback = function () {
                PAYPAL.apps.Signup.loadScripts(document, 'scripts');
                PAYPAL.apps.Signup.MiniBrowser.init();
            };
            if (!window.PAYPAL) {
                rbLoadScript('https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js', callback);
            } else {
                callback();
            }
        }
        function getPayPalInfo() {
            return $http({
                url: `${ API_PREFIX }Cmp/GetPayPalInfo`,
                headers: { entityId: entityService.getOpenedEntityId() },
                method: 'POST'
            }).then(response => response.data);
        }
        function createPayPalReferral() {
            return $http({
                url: `${ API_PREFIX }Cmp/CreatePayPalReferralLink`,
                headers: { entityId: entityService.getOpenedEntityId() },
                data: { returnUrl: location.href },
                method: 'POST',
                messageConfig
            }).then(response => response.data).catch(response => response);
        }
        function deactivatePayPal() {
            var mConfig = messageConfig;
            mConfig.successToastEnabled = true;
            return $http({
                url: `${ API_PREFIX }Cmp/DisconnectPayPal`,
                headers: { entityId: entityService.getOpenedEntityId() },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
        function reconnectPayPal() {
            var mConfig = messageConfig;
            mConfig.successToastEnabled = true;
            return $http({
                url: `${ API_PREFIX }Cmp/AdoptParentEntityPayPal`,
                headers: { entityId: entityService.getOpenedEntityId() },
                method: 'POST',
                messageConfig
            }).then(response => response.data);
        }
    }
}());
(function () {
    angular.module('app').service('pendingChangesService', pendingChangesService);
    function pendingChangesService($q, $mdDialog, $window) {
        const vm = this;
        let pendingChange = undefined;
        vm.add = function (change) {
            pendingChange = change;
            vm.addWindowEvent();
        };
        vm.clear = function () {
            pendingChange = undefined;
        };
        vm.confirm = function () {
            if (vm.hasPendingChanges()) {
                let confirm = $mdDialog.confirm().multiple(true).title('Discard unsaved changes?').htmlContent(`You have pending unsaved changes, do you want to discard them?`).ok('Discard Changes').cancel('Cancel');
                return $mdDialog.show(confirm).then(() => vm.clear());
            }
            vm.clear();
            return $q.resolve();
        };
        vm.hasPendingChanges = function () {
            return pendingChange && (typeof pendingChange !== 'function' || pendingChange());
        };
        vm.addWindowEvent = function () {
            $window.onbeforeunload = function () {
                if (vm.hasPendingChanges()) {
                    return 'You have unsaved pending changes, are you sure you want to leave?';
                }
            };
        };
    }
}());
(function () {
    angular.module('app').service('placeholdersService', placeholdersService);
    function placeholdersService() {
        const vm = this;
        /**
     * @typedef {Object} PlaceholderCondition
     * @property {string}                       tag         the opening tag as it appears in the source code.
     * @property {string}                       label       the descriptive label of the tag, shown to the user.
     * @property {boolean}                      [checked]   user chose this tag to be rendered as true/false (undefined uses default).
     * @property {string}                       keyword     the liquid keyword for the tag, e.g. if, elsif etc.
     * @property {boolean}                      [isGroup]   the tag is a group of related tags e.g. if and elsif tags together.
     * @property {array[PlaceholderCondition]}  [items]     sub items belonging in this group.
     */
        /**
     * Gets conditional liquid placeholders from source code.
     * @param  {string}          sourceCode   Source code with liquid tags.
     * @return {array[PlaceholderCondition]}  List of placeholder conditions from the source code.
     */
        vm.getConditionsFromPlaceholders = function (sourceCode) {
            var placeholderConditions = [];
            let previousGroupTag = undefined;
            /*
            Matches an opening/closing tags for liquid conditionals:
            
            Examples:
               {% if condition == true %}
                - matches[0] = Tag:       {% if condition == true %}
                - matches[1] = Keyword:   if
                - matches[2] = label:     condition == true
                
               {% endif %}
                - matches[0] = Tag:       {% endif %}
                - matches[1] = Keyword:   endif
                - matches[2] = label:     undefined
        */
            const regex = new RegExp('{% ?(if|unless|elsif|endif|endunless) ?(.*?)?? ?%}', 'mig');
            let m;
            while ((m = regex.exec(sourceCode)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                let condition = {
                    tag: m[0],
                    label: formatLabel(m[2]),
                    checked: undefined,
                    keyword: m[1]
                };
                /*
               When reaching an endif or endunless reset the previous group tag start
               This is to prevent a elsif being grouped with a nested if statement withing the starting if statement
            
               For example:
               {% if outerCondition %} {% if innerCondition } {% endif %} {% elseif otherOuterCondition %} {% endif %}
               Without this, otherOuterCondition would be grouped with innerCondition, with this no groupings are created
            */
                if (condition.keyword === 'endif' || condition.keyword === 'endunless') {
                    previousGroupTag = undefined;
                    continue;
                }
                // Only show the tag as an option once
                // Necessary because for duplicate tags we do not have a way to replace only a specific occurrence when recompiling the body
                if (placeholderConditions[condition.tag])
                    continue;
                // If possible (i.e. obvious which if statement it belongs to),
                // consolidate elseif conditions into single group with original if statement
                if (condition.keyword === 'elsif' && previousGroupTag && placeholderConditions[previousGroupTag]) {
                    var previousItem = placeholderConditions[previousGroupTag];
                    if (!previousItem.items) {
                        var firstInGroup = _.clone(previousItem);
                        previousItem.items = [];
                        previousItem.items.push(firstInGroup);
                        previousItem.isGroup = true;
                        previousItem.label = '';
                    }
                    previousItem.items.push(condition);
                } else {
                    placeholderConditions[condition.tag] = condition;
                    if (condition.keyword === 'if' || condition.keyword === 'unless') {
                        previousGroupTag = condition.tag;
                    }
                }
            }
            return _(placeholderConditions).values().forEach(generalizeGroup);
        };
        function generalizeGroup(condition) {
            /*
            If all the conditions in the group follow a similar pattern then generalize
            the labels and give the entire group a label
            
            Pattern matched is:
             GroupName == 'IndividualCondition'
            
            For example a group with the following sub conditions:
             - MyCondition == 'Option1'
             - MyCondition == 'Option2' 
             - MyCondition == 'Option3'
            
            Would be converted to:
             Group Name: MyCondition
             Options:
              - Option1
              - Option2
              - Option3          
        */
            if (condition.isGroup) {
                let useGeneralizedItems = true;
                let groupName = '';
                let generalizedItems = [];
                _.forEach(condition.items, item => {
                    let match = item.label.match(/(.*) ?== ? '(.*)'/i);
                    if (match) {
                        let thisGroupName = match[1].trim();
                        if (!groupName) {
                            groupName = thisGroupName;
                        }
                        if (groupName !== thisGroupName) {
                            useGeneralizedItems = false;
                        } else {
                            let generalizedItem = _.clone(item);
                            let label = match[2];
                            if (!label)
                                label = 'is blank';
                            generalizedItem.label = label;
                            generalizedItems.push(generalizedItem);
                        }
                    } else {
                        useGeneralizedItems = false;
                    }
                });
                if (useGeneralizedItems) {
                    condition.label = groupName;
                    condition.items = generalizedItems;
                }
            }
        }
        function formatLabel(label) {
            if (!label || !label.toString) {
                return '';
            }
            label = label.toString();
            label = label    // Trim spaces, dashes, parentheses and braces
.replace(/^\(+|\)+$/g, '').replace(/^{{+|}}+$/g, '').replace(/^-+|-+$/g, '').trim()    // Allow line breaks in labels at . or _
.replace(/\./g, '.\u200B').replace(/_/g, '_\u200B');
            return label;
        }
        /**
     *  Get source code with conditional tags replaced per user overrides.
     *  @param  {string}                        sourceCode      Source code with liquid tags.
     *  @param  {array[PlaceholderCondition]}   placeholders    List of placeholder conditions.
     *  @return {string}    source code with conditional tags replaced.
     */
        vm.replaceConditionalPlaceholders = function (sourceCode, placeholders) {
            let sourceCodeReplaced = sourceCode;
            _.forEach(placeholders, value => {
                if (value.checked !== undefined) {
                    if (value.isGroup) {
                        var selected = value.checked.tag;
                        _.forEach(value.items, subItem => {
                            var checked = selected === subItem.tag;
                            sourceCodeReplaced = replaceTag(sourceCodeReplaced, subItem, checked);
                        });
                    } else {
                        sourceCodeReplaced = replaceTag(sourceCodeReplaced, value, value.checked);
                    }
                }
            });
            return sourceCodeReplaced;
        };
        function replaceTag(sourceCode, value, checked) {
            let oldTagPattern = new RegExp(escapeRegExp(value.tag), 'gi');
            // uses regex for case insensitive AND replace all instead of only first occurence
            let newTag = `{% ${ value.keyword } ${ checked ? 'true' : 'false' } %}`;
            return sourceCode.replace(oldTagPattern, newTag);
        }
        function escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|\[\]\\]/g, '\\$&');
        }
        /**
     * @typedef {Object} PlaceholderSampleObject
     * @property {string}           type        The type of object this is a sample of.
     * @property {string}           label       The descriptive label of the object, shown to the user.
     * @property {boolean}          loading     If the preview items are currently loading.
     * @property {array[Object]}    items       Preview items selectable for this object.
     * @property {Object}           [selected]  Populated with the item selected by the user from items.
     */
        /**
     * Load sample objects for placeholder types.
     * @param   {Function}           request         Request promise to load the sample objects.
     * @param   {array[string]}      placeholders    Sample object placeholders.
     * @param   {Function}           mapItemsFunc    Function to use to map the response from the request to a list of items.
     * @param   {string}             sourceCode      Source code to check for placeholders.
     * @return  {Object[PlaceholderSampleObject]} Sample object placeholders with items being loaded.
     */
        vm.loadPlaceholderSampleObjects = function (request, placeholders, mapItemsFunc, sourceCode) {
            let placeholderSampleObjects = {};
            let shouldShowAny = false;
            _.forEach(placeholders, placeholder => {
                if (hasPlaceholdersInSourceCode(sourceCode, placeholder)) {
                    placeholderSampleObjects[placeholder] = {
                        selected: undefined,
                        placeholder,
                        label: placeholder.replace(/_/, ' '),
                        items: [],
                        loaded: false
                    };
                    shouldShowAny = true;
                }
            });
            if (shouldShowAny) {
                request().then(r => {
                    let items = mapItemsFunc(r);
                    _.forEach(placeholders, placeholder => {
                        let sampleObject = placeholderSampleObjects[placeholder];
                        if (sampleObject) {
                            sampleObject.loaded = true;
                            sampleObject.items = items;
                        }
                    });
                }).catch(angular.noop);
            }
            return placeholderSampleObjects;
        };
        function hasPlaceholdersInSourceCode(sourceCode, placeholder) {
            if (!sourceCode)
                return false;
            let regex = new RegExp('{{ ?' + placeholder + '.* ?}}|{% ?' + placeholder + '.* ?%}', 'gmi');
            return sourceCode.match(regex);
        }
        /**
     * Load sample objects for placeholder types.
     * @param   {Object[PlaceholderSampleObject]} placeholderSampleObjects  Sample object placeholders.
     * @return  {Object} Object with placeholders as key and selected sample object id as value.
     */
        vm.getPlaceholderSampleObjectsSelection = function (placeholderSampleObjects) {
            return _.reduce(placeholderSampleObjects, (obj, sampleObject) => {
                if (sampleObject.selected) {
                    obj[sampleObject.placeholder] = sampleObject.selected.id;
                }
                return obj;
            }, {});
        };
    }
}());
(function () {
    angular.module('app').factory('profileService', profileService);
    function profileService($q, $http, API_PREFIX, rbTransformRequests) {
        let profileInfo = null;
        let userNeonApps = null;
        const agGridSettingPrefix = 'agGridSetting-';
        return {
            getProfileInfo,
            getNotificationsSettings,
            saveNotifications,
            setProfileInfoCampView,
            updateFilters,
            updateSort,
            saveEmail,
            saveProfileImage,
            resetPassword,
            setNameForUser,
            getAgGridSetting,
            updateAgGridSetting,
            getUserNeonApps
        };
        function getProfileInfo() {
            if (profileInfo) {
                return $q.resolve(profileInfo);
            }
            return $http({
                url: API_PREFIX + 'Profile/GetMyInfo',
                method: 'POST'
            }).then(response => {
                profileInfo = response.data.data;
                if (!profileInfo.userSettings) {
                    profileInfo.userSettings = {};
                }
                if (!profileInfo.userSettings.campaignView) {
                    profileInfo.userSettings.campaignView = 'listView';
                }
                if (!profileInfo.userSettings.adminView) {
                    profileInfo.userSettings.adminView = 'tileView';
                }
                if (!profileInfo.userFilterSettings) {
                    profileInfo.userFilterSettings = {};
                }
                if (!profileInfo.userFilterSettings.filterSettings) {
                    profileInfo.userFilterSettings.filterSettings = {
                        FundraisingPlatforms: true,
                        // 1, //    1  
                        DonationPages: true,
                        // 2, //    2  
                        TicketingPages: true,
                        // 4, //    3 
                        FundraisingCampaigns: true,
                        // 8, //    4
                        PublishedCampaigns: true,
                        // 16, //    5 
                        DelistedCampaigns: true,
                        // 32, //    6
                        EndedCampaigns: false,
                        // 64,  //    7
                        MemorialCampaigns: true    // 128, //    8
                    };
                }
                if (!profileInfo.userSettings.sortSettings) {
                    profileInfo.userSettings.sortSettings = 'Recent-0';
                }
                return profileInfo;
            });
        }
        function getUserNeonApps() {
            if (userNeonApps) {
                return $q.resolve(userNeonApps);
            }
            return $http({
                url: API_PREFIX + 'Profile/GetMyNeonApps',
                method: 'POST'
            }).then(response => {
                userNeonApps = response.data.data ? response.data.data.applications : [];
                return userNeonApps;
            });
        }
        function setProfileInfoCampView(view) {
            profileInfo.userSettings.campaignView = view;
            return $http({
                url: API_PREFIX + 'Profile/UpdateUserSettings',
                data: { list: { campaignView: view } },
                method: 'POST'
            });
        }
        function updateSort(sorts) {
            profileInfo.userSettings.sortSettings = sorts;
            return $http({
                url: API_PREFIX + 'Profile/UpdateUserSettings',
                data: { list: { sortSettings: sorts } },
                method: 'POST'
            });
        }
        function updateFilters(filters) {
            profileInfo.userFilterSettings.filterSettings = filters;
            return $http({
                url: API_PREFIX + 'Profile/UpdateUserFilterSettings',
                data: { list: filters },
                method: 'POST'
            });
        }
        function getNotificationsSettings() {
            return $http({
                url: API_PREFIX + 'Profile/GetNotifySettings',
                method: 'POST'
            }).then(response => {
                const columnsCount = response.data.columnsCount;
                return response.data.data.map(notificationOrg => {
                    return {
                        orgName: notificationOrg.orgName,
                        campaigns: notificationOrg.campaigns.map(entry => {
                            // Checkboxes values
                            // 0 - checked, 1 - unchecked
                            let flags = _getBitmask(entry.flags, columnsCount);
                            // Checkboxes states
                            let permissionFlags = _getBitmask(entry.permissionFlags, columnsCount);
                            return {
                                orgId: entry.orgId,
                                entityId: entry.entityId,
                                campaignName: entry.campaignName,
                                flags,
                                permissionFlags
                            };
                        })
                    };
                });
            });
        }
        function getAgGridSetting(key) {
            let fullKey = agGridSettingPrefix + key;
            return getProfileInfo().then(profileInfo => {
                return profileInfo.userSettings[fullKey];
            });
        }
        function updateAgGridSetting(key, setting) {
            let fullKey = agGridSettingPrefix + key;
            profileInfo.userSettings[fullKey] = JSON.stringify(setting);
            $http({
                url: `${ API_PREFIX }Profile/UpdateUserSettings`,
                data: { list: { [fullKey]: JSON.stringify(setting) } },
                method: 'POST'
            });
        }
        function saveEmail(email, messageConfig) {
            return $http({
                url: API_PREFIX + 'Profile/ChangeMyEmail',
                data: { newEmail: email },
                method: 'POST',
                messageConfig
            });
        }
        function resetPassword(oldPass, newPass, secondPass, messageConfig) {
            return $http({
                url: API_PREFIX + 'Profile/ResetMyPassword',
                data: {
                    oldPass: oldPass,
                    newPass: newPass,
                    secondPass: secondPass
                },
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                method: 'POST',
                transformRequest: rbTransformRequests.transformForm,
                messageConfig
            });
        }
        function setNameForUser(name, messageConfig) {
            return $http({
                url: API_PREFIX + 'Profile/SetMyName',
                data: { fullName: name },
                method: 'POST',
                messageConfig
            });
        }
        function saveProfileImage(img, messageConfig) {
            return $http({
                url: API_PREFIX + 'Profile/ChangeMyProfileImage',
                data: { file: img },
                headers: { 'Content-Type': undefined },
                method: 'POST',
                transformRequest: rbTransformRequests.transformFormData,
                messageConfig
            });
        }
        function saveNotifications(notificationsList) {
            var settings = [];
            notificationsList.forEach(notificationOrg => {
                notificationOrg.campaigns.forEach(notification => {
                    let flags = notification.flags.slice();
                    flags.reverse();
                    settings.push({
                        entityId: notification.entityId,
                        orgId: notification.orgId,
                        flags: parseInt(// boolean array => bitmask => decimal value
                        flags.map(flag => flag ? '1' : '0').join(''), 2)
                    });
                });
            });
            return $http({
                url: API_PREFIX + 'Profile/UpdateNotifySettings',
                data: { settings },
                method: 'POST'
            });
        }
        function _getBitmask(number, size) {
            let mask = [];
            let binaryNumber = number.toString(2).split('');
            for (let i = 0; i < size; i++) {
                mask[i] = binaryNumber[i - (size - binaryNumber.length)] == 1;
            }
            return mask.reverse();
        }
    }
}());
(function () {
    angular.module('app').service('requestHelperService', requestHelperService);
    function requestHelperService(entityService, messageService, formService, $http, $q, API_PREFIX) {
        function RequestHelper(controllerName) {
            this.successToastMessage = function (message) {
                return {
                    successToastEnabled: true,
                    successMsg: message
                };
            };
            this.sendEntityRequest = function (actionName, data, messageConfig, entityId = entityService.getOpenedEntityId()) {
                return this.sendRequest(actionName, data, messageConfig, entityId);
            };
            this.sendCampaignRequest = function (actionName, data, messageConfig, campaignId = entityService.getOpenedCampaignId()) {
                return this.sendRequest(actionName, data, messageConfig, campaignId, true);
            };
            this.sendRequest = function (actionName, data, messageConfig = {}, id, campaignRequest) {
                var requestOptions = {
                    url: `${ API_PREFIX }${ controllerName }/${ actionName }`,
                    data,
                    headers: {},
                    method: 'POST',
                    messageConfig
                };
                if (campaignRequest) {
                    requestOptions.headers.campaignId = id;
                } else {
                    requestOptions.headers.entityId = id;
                }
                return $http(requestOptions).then(response => response.data);
            };
            this.sendExtJsRequest = function (endpoint, data, eventId = entityService.getOpenEventId()) {
                var requestOptions = {
                    url: `/Admin/${ endpoint }`,
                    data,
                    headers: { '__rbhost': eventId },
                    method: 'POST',
                    messageConfig: { suppressErrorHandling: true }
                };
                return $http(requestOptions).then(response => response.data);
            };
        }
        return { getInstance: controllerName => new RequestHelper(controllerName) };
    }
}());
(function () {
    angular.module('app').factory('routingService', routingService);
    function routingService($rootScope, $window, campaignService, entityService, authenticationService, securityService, orgService, $location, pendingChangesService, $q) {
        return {
            updateRouting,
            validateRoute,
            validateRoutePermission,
            openEntityIsCampaign,
            validateNoPendingChanges,
            openEntityIsOrg,
            getNextRoute,
            isStandaloneOrTicketing,
            willRoute,
            anyResourcePermission
        };
        function updateRouting() {
            return campaignService.checkUpdateRouting().then(setCampaignAndGroup).catch(setNotFound);
        }
        function validateNoPendingChanges($nextInstruction) {
            if (pendingChangesService.hasPendingChanges()) {
                return pendingChangesService.confirm().then(() => true, () => false);
            }
            return true;
        }
        function validateRoute($nextInstruction) {
            return pendingChangesService.confirm().then(() => {
                let routeData = $nextInstruction.routeData.data;
                return (routeData.authenticate ? authenticationService.checkAuthentication() : $q.resolve(true)).then(isAuthenticated => {
                    if (!isAuthenticated) {
                        $window.location.href = `/Account/Logon?returnUrl=${ encodeURIComponent($nextInstruction.urlPath) }`;
                        return false;
                    } else {
                        return orgService.orgInfoLoadedPromise.then(r => {
                            return entityService.getEntitySettings().then(entity => {
                                if (!willRoute(routeData, entity)) {
                                    return false;
                                }
                                if (campaignService.shouldUpdateRoutingForGroup($nextInstruction, entity)) {
                                    campaignService.updateRoutingForGroup(entity);
                                    return false;
                                }
                                return true;
                            });
                        });
                    }
                });
            }).catch(() => false);
        }
        function willRoute(routeData, entity) {
            if (routeData.dontRoute) {
                return false;
            }
            if (routeData.limitToOrg) {
                if (!openEntityIsOrg(entity)) {
                    return false;
                }
            }
            if (routeData.limitToCampaign) {
                if (!openEntityIsCampaign(entity)) {
                    return false;
                }
            }
            if (openEntityIsCampaign(entity) && entity.activeEventIsArchived && !routeData.allowOnInstance) {
                return false;
            }
            if (orgService.getUrlCampaignInstanceId() && !routeData.allowOnInstance) {
                return false;
            }
            if (!validateRoutePermission(routeData, entity)) {
                return false;
            }
            if (routeData.hideOnStandaloneAndTicketing) {
                if (isStandaloneOrTicketing(entity)) {
                    return false;
                }
            }
            if (routeData.hideOnNonStandaloneAndTicketing && openEntityIsCampaign(entity) && !isStandaloneOrTicketing(entity)) {
                return false;
            }
            if (routeData.routeFunc) {
                if (!routeData.routeFunc(entity)) {
                    return false;
                }
            }
            if (routeData.hideOnNewOrgs) {
                if (location.search.indexOf('pass') < 0 && orgService.getOrgId() > 424) {
                    return false;
                }
            }
            return true;
        }
        function getNextRoute(router, component) {
            var rules = router.registry._rules.get(component);
            return orgService.orgInfoLoadedPromise.then(r => {
                var entity = entityService.getOpenEntity();
                var routing = false;
                rules.rulesByName.forEach((v, k) => {
                    var data = v.handler.data.data;
                    if (!routing && willRoute(data, entity)) {
                        router.navigate([k]);
                        routing = true;
                    }
                });
                return routing;
            });
        }
        function isStandaloneOrTicketing(entity) {
            return [
                'Donation',
                'Global Donation Page',
                'Standalone Ticketing',
                'Ticketing'
            ].includes(entity.type);
        }
        function openEntityIsCampaign(entity) {
            entity = entity || entityService.getOpenEntity();
            return entity.entityType == 'Campaign';
        }
        function openEntityIsOrg(entity) {
            entity = entity || entityService.getOpenEntity();
            return entity.entityType == 'Org';
        }
        function validateRoutePermission(data, entity) {
            if (data.resource) {
                return securityService.getAdminPermissions(entity, data.resource).VIEW;
            } else if (data.allResources) {
                return routePermissionMultipleCheck(data.allResources, entity);
            } else if (data.anyResource) {
                return anyResourcePermission(data.anyResource, entity);
            }
            return true;
        }
        function routePermissionMultipleCheck(resourceArray, entity) {
            var hasPermission = true;
            resourceArray.forEach(element => {
                if (hasPermission) {
                    var perm = securityService.getAdminPermissions(entity, element);
                    if (!perm.VIEW) {
                        hasPermission = false;
                    }
                }
            });
            return hasPermission;
        }
        function anyResourcePermission(anyResource, entity) {
            for (let perm of anyResource) {
                var hasPermission = securityService.getAdminPermissions(entity, perm);
                if (hasPermission.VIEW) {
                    return true;
                }
            }
            return false;
        }
        function setCampaignAndGroup(campaignAndGroup) {
            let openEntity = null;
            if (campaignAndGroup.campaign) {
                $rootScope.$emit('onCampaignPage');
                $rootScope.$emit('campaign.opened', {
                    name: campaignAndGroup.campaign.name,
                    campId: campaignAndGroup.campaign.campaignId
                });
                openEntity = campaignAndGroup.campaign.entityId;
            }
            if (campaignAndGroup.group) {
                var groupId = campaignAndGroup.group.groupId;
                openEntity = openEntity || campaignAndGroup.group.entityId;
            }
            openEntity = openEntity || entityService.getOrgEntityId();
            openGroup(groupId);
            return openEntity;
        }
        function openGroup(groupId) {
            campaignService.openGroup(groupId).then(campaignsData => {
                $rootScope.$emit('group.opened');
            });
        }
        function setNotFound() {
            $rootScope.$emit('groupOrCampaignNotFound');
        }
    }
}());
(function () {
    angular.module('app').factory('salesforceService', salesforceService);
    function salesforceService($http, $q, API_PREFIX) {
        return {
            getSalesforceLogs,
            getSalesforceInfo,
            getSalesforceUrl,
            reSync
        };
        function getSalesforceInfo(id) {
            return $http.post(`${ API_PREFIX }Integration/SfInfo`, { id }).then(response => response.data);
        }
        /**
     * Get the URL to connect to salesforce for authentication
     * @param {boolean} sandbox weather or not to use Sandbox
     * @param {number} authenticationId optional existing authentication id
     * @returns {*}
     */
        function getSalesforceUrl(sandbox, authenticationId) {
            return $http.post(`${ API_PREFIX }Integration/SfConnectUrl`, {
                sandbox,
                authenticationId
            }).then(response => response.data);
        }
        function getSalesforceLogs(id, from, to) {
            return $http.post(`${ API_PREFIX }Integration/SfSyncs`, {
                id,
                to: moment(to).format('YYYY-MM-DD'),
                from: moment(from).format('YYYY-MM-DD')
            }).then(response => {
                return response.data.items.map(log => {
                    log.InitiatedOn = log.InitiatedOn && new Date(log.InitiatedOn);
                    log.LastRetriedOn = log.LastRetriedOn && new Date(log.LastRetriedOn);
                    log.canResync = !!(log.ErroredOn || log.CompletedOn);
                    if (log.CompletedOn) {
                        log.Status = 'successful';
                    } else if (log.ErroredOn && log.LastRetriedOn && !log.FirstRetriedOn) {
                        log.Status = 'retrying';
                    } else {
                        log.Status = 'failed';
                    }
                    if (log.CompletedOn) {
                        log.CompletedOn = new Date(log.CompletedOn);
                    } else if (log.ErroredOn) {
                        log.CompletedOn = new Date(log.ErroredOn);
                    }
                    return log;
                });
            });
        }
        function reSync(record) {
            // Don't send other fields back to server in case they have html
            let recordToSync = {
                SalesforceQueueId: record.SalesforceQueueId,
                EventId: record.EventId
            };
            return $http.post(`${ API_PREFIX }Integration/SfResync`, recordToSync).then(response => response.data);
        }
    }
}());
(function () {
    angular.module('app').factory('securityService', function ($http, $q, API_PREFIX) {
        let permissionType = {
            VIEW: 1,
            EDIT: 2,
            DELETE: 4
        };
        let navPermissions = {
            activity: ['CAMPAIGN'],
            statistics: ['CAMPAIGN'],
            transactions: [
                'DONATION',
                'DONATION_RECURRING',
                'TRANSACTION_REGISTRATIONS',
                'TRANSACTIONS_TICKETS',
                'DONOR_CAMPAIGNS'
            ],
            people: [
                'PEOPLE_NATIONAL_TEAMS',
                'PEOPLE_CONTACTS',
                'PEOPLE_DONORS',
                'PEOPLE_REGISTRANTS',
                'PEOPLE_TEAMS'
            ],
            email: [
                'EMAIL_SEND_EMAILS',
                'EMAIL_SENT_EMAIL_BLAST',
                'EMAIL_TEMPLATES',
                'EMAIL_SENT_EMAIL_SYSTEM',
                'EMAIL_PENDING_EMAILS',
                'FUNDRAISER_EMAIL_TEMPLATES',
                'AUTO_RESPONDERS',
                'PDF_RECEIPT_TEMPLATE',
                'EMAIL_ADVANCED_OPTIONS'
            ],
            forms: [
                'DONATION_SETTINGS',
                'PROCESSING_FEES',
                'POST_DONATION_OPTIONS',
                'MATCH_SETTINGS',
                'PAY_PAL',
                'REGISTRATION_SETTINGS',
                'REGISTRATION_FIELDS',
                'TICKETS',
                'DISCOUNT_CODES',
                'FORMS',
                'FORMS_CUSTOMIZE',
                'SETTINGS_ADVANCED'
            ],
            website: [
                'CONTENT_FAQ',
                'CONTENT_SPONSER',
                'FEED',
                'IMAGES',
                'SETTINGS_CMP_DICTIONARY',
                'SETTINGS_SOCIAL_SHARES',
                'WEBSITE_ADVANCED'
            ],
            fundraising: [
                'SETTINGS_HQ_TASKS',
                'FUNDRAISING_OPTIONS',
                'FUNDRAISER_TASK_LIST',
                'FUNDRAISER_TASK_LIST_OPTIONS',
                'FUNDRAISER_EMAIL_TEMPLATES',
                'FUNDRAISING_CUSTOMIZE',
                'FUNDRAISING_ADVANCED',
                'ENDURANCE_CHALLENGES',
                'LIVESTREAMS'
            ],
            reports: ['REPORTS'],
            system: [
                'SETTINGS_DONATION_PROCESSOR',
                'SYSTEM_NOTIFICATIONS',
                'SETTINGS_INTEGRATION_OPTIONS'
            ]
        };
        return {
            getAdminPermissions,
            navPermissions
        };
        // function hasPermission(resourcePermissions, resource, permission) {
        //   if(!resourcePermissions) {
        //     return false;
        //   }
        //   return checkPermission(resourcePermissions[resource], permission)
        // }
        function checkPermission(resourcePermissions, permission) {
            return (resourcePermissions & permissionType[permission]) == permissionType[permission];
        }
        function getPermissions(resourcePermissions, resource) {
            if (resourcePermissions) {
                var permissionsForResource = resourcePermissions[resource];
                if (!permissionsForResource) {
                    return {};
                }
                return {
                    VIEW: checkPermission(permissionsForResource, 'VIEW'),
                    EDIT: checkPermission(permissionsForResource, 'EDIT'),
                    DELETE: checkPermission(permissionsForResource, 'DELETE')
                };
            }
            return {};
        }
        function getAdminPermissions(entity, resource) {
            if (!entity) {
                return {};
            }
            return getPermissions(entity.permissions, resource);
        }
    });
}());
(function () {
    angular.module('app').factory('settingsService', settingsService);
    function settingsService($http, $q, API_PREFIX, entityService, messageService) {
        let target = {};
        let cache = null;
        let inFlightRequest = null;
        return {
            setTargetEntityId,
            getTargetEntityId,
            createNeonLink,
            getPageInfo,
            getTimeZoneInfo,
            getIanaTimeZones,
            getCountries,
            getCDN,
            getHelpLinks,
            getPaymentTypes,
            loadStaticInfo
        };
        //#region Static Info
        function getTimeZoneInfo() {
            return getStaticInfo().then(info => info.timezone);
        }
        function getIanaTimeZones() {
            return getStaticInfo().then(info => info.ianaTimeZones);
        }
        function getCountries() {
            return getStaticInfo().then(info => info.countries);
        }
        function getCDN() {
            return getStaticInfo().then(info => info.cdn);
        }
        function getHelpLinks() {
            return getStaticInfo().then(info => info.helpLinks);
        }
        function getPaymentTypes() {
            return getStaticInfo().then(info => info.paymentTypes);
        }
        function loadStaticInfo() {
            return getStaticInfo();
        }
        function getStaticInfo() {
            if (cache) {
                return $q.resolve(cache);
            }
            if (inFlightRequest) {
                return inFlightRequest;
            }
            inFlightRequest = $http({
                url: `${ API_PREFIX }/GetStaticInfo`,
                headers: { orgId: entityService.getOrgId() },
                method: 'POST'
            }).then(response => {
                cache = response.data.data;
                cache.ianaTimeZones = cache.ianaTimeZones.map(x => ({
                    display: x.replace('/', ' - ').replace('_', ' '),
                    value: x
                }));
                if (cache.retently && !document.querySelector('#retently-survey-embed')) {
                    const div = document.createElement('div');
                    div.id = 'retently-survey-embed';
                    div.setAttribute('data-href', cache.retently.retentlyHref);
                    div.setAttribute('data-rel', 'dialog');
                    div.setAttribute('data-campaign', 'regular');
                    div.setAttribute('data-email', cache.retently.email);
                    div.setAttribute('data-firstname', cache.retently.firstName);
                    div.setAttribute('data-lastname', cache.retently.lastName);
                    div.setAttribute('data-company', cache.retently.companyName);
                    div.setAttribute('data-tags', `orgid_${ cache.retently.neonOrgId },neon_fundraise,contactid_${ cache.retently.neonOrgId }:${ cache.retently.neonUserId }`);
                    document.body.appendChild(div);
                    (function (d, s, id) {
                        var js, rjs = d.getElementsByTagName(s)[0];
                        if (d.getElementById(id))
                            return;
                        js = d.createElement(s);
                        js.id = id;
                        js.src = 'https://cdn.retently.com/public/components/embed/sdk.min.js';
                        rjs.parentNode.insertBefore(js, rjs);
                    }(document, 'script', 'retently-jssdk'));
                }
                return cache;
            }).catch(err => {
                inFlightRequest = null;
                cache = null;
                return $q.reject(err);
            });
            return inFlightRequest;
        }
        //#endregion
        function setTargetEntityId(entityId) {
            target.entityId = entityId;
        }
        function getTargetEntityId() {
            return target.entityId;
        }
        function getPageInfo(pageId) {
            var endpoint = getEndpointFromPageId(pageId);
            return $http({
                url: `${ API_PREFIX }Cmp/${ endpoint }`,
                headers: { entityId: entityService.getOpenedEntityId() },
                method: 'POST'
            }).then(response => response.data);
        }
        function getEndpointFromPageId(pageId) {
            switch (pageId) {
            case 'neon':
                return 'GetNeonPayInfo';
            default:
                break;
            }
        }
        function createNeonLink(sandbox) {
            const messageConfig = {
                advancedErrorFunction: messageService.advancedErrorsDialog,
                advancedErrorEnabled: true
            };
            return $http({
                url: `${ API_PREFIX }Cmp/CreateNeonPayReferralLink`,
                headers: { entityId: entityService.getOpenedEntityId() },
                data: { sandbox: sandbox },
                messageConfig,
                method: 'POST'
            }).then(response => response.data);
        }
    }
}());
(function () {
    angular.module('app').factory('taxReceiptService', taxReceiptService);
    function taxReceiptService($http, API_PREFIX, entityService) {
        return {
            getTemplate,
            updateTemplate
        };
        function getTemplate() {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: `${ API_PREFIX }Cmp/GetPdfReceiptTemplate`,
                headers: { entityId },
                method: 'POST'
            }).then(response => response.data);
        }
        function updateTemplate(template) {
            let entityId = entityService.getOpenedEntityId();
            return $http({
                url: `${ API_PREFIX }Cmp/UpdatePdfReceiptTemplate`,
                headers: { entityId },
                data: { template },
                method: 'POST'
            }).then(response => response.data);
        }
    }
}());