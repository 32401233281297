angular
  .module('app')
  .factory('administratorService', administratorService);

function administratorService($http, $q, API_PREFIX, entityService, campaignService) {
    let allAdmins = [];
    let betaOptions = [46, 42, 41, 10002];

  return {
    getAdmins,
    getAdminsFromOrg,
    updateAllAdmins,
    addToAllAdmins,
    getAllRoles,
    getAllOrgAdmins,
    getAllOrgAdminsNamesEmails,
    updateAdminsCurrentRoleIds,
    inviteAdmin,
    removeAdmin,
    getSecurityRoles,
    updateSecurityRole,
    createSecurityRole,
    deleteSecurityRole,
    getSecurityPermissionName,
    removeAdminFromOrg,
    removeAdminFromEntity,
    addAdminToCompanies
  };

  function getAdmins(curEntityId) {
    let orgEntityId = entityService.getOrgEntityId(),
        entityId = curEntityId || orgEntityId;

    return $http({ url: API_PREFIX + 'Admins/GetAdminsOnEntity', headers: { entityId: entityId }, method: 'POST' })
      .then((response) => {

        if ('msg' in response.data && response.data.success) return [] //when no admins this is ok

        return _buildAdditionalRoles(response.data.data, orgEntityId, entityId)
      });
  }

  function _buildAdditionalRoles(admins, orgEntityId, entityId) {

    return admins.map((admin) => {
      let groupsPath = campaignService.getGroupsPath().map((g) => g.entityId);

      admin.roleByEntity = admin.roleById
        .filter(role => {

            if (orgEntityId == entityId) return false

            return role.entityId !== entityId && // remove current role from list
              _.includes(groupsPath, role.entityId) || role.entityId == orgEntityId // only parent groups and org
          }
        )
        .map((role) => ({
          roleId: role.roleId,
          entityId: role.entityId,
          entityName: entityService.getEntityName(role.entityId)
        }));
      return admin;
    });

  }

  function getAllOrgAdmins() {
    let orgEntityId = entityService.getOrgEntityId();

    return $http({ url: API_PREFIX + 'Admins/GetAdminsOnOrg', headers: { orgId: entityService.getOrgId() }, method: 'POST' })
      .then((response) => {

        let admins = response.data.data || [];
        allAdmins = admins;

        admins.forEach((admin) => {
            admin = updateAdminsCurrentRoleIds(admin);
        });
       
        return admins;
      });
  }

    function getAllOrgAdminsNamesEmails(curEntityId) {
        return $http({ url: API_PREFIX + 'Admins/GetOrgAdminsNamesAndEmails', headers: { entityId: curEntityId }, method: 'POST' })
      .then((response) => {

          let admins = response.data.data || [];
       
          return admins;
      });
    }

  function updateAdminsCurrentRoleIds(admin) {
        let orgEntityId = entityService.getOrgEntityId();
        let rolesMap = admin.roleById.reduce((map, role) => {
            map[role.entityId] = role.roleId;
            return map;
        }, {});

        admin.campaigns = admin.campaigns.map(campaign => {
            return Object.assign(
            {},
            campaignService.getCampaignByEntityId(campaign.entityId),
            { currentRoleId: rolesMap[campaign.entityId] }
            );
        });
          
        admin.groups = admin.groups.map(group => {
            return Object.assign(
            {},
            campaignService.getGroupByEntityId(group.entityId),
            { currentRoleId: rolesMap[group.entityId] }
            );
        });

        admin.entireOrgRole = {};

        if (rolesMap[orgEntityId]) {
            admin.entireOrgRole = {name: "Entire Organization", entityType:"Org", entityId: orgEntityId, currentRoleId: rolesMap[orgEntityId] };
        }

        return admin;
  }

  function getAdminsFromOrg(){
    return allAdmins;
  }
    
  function updateAllAdmins(admin, index, remove){
      if (remove) {
          for (var i = 0; i < allAdmins.length; i++) {
              if (allAdmins[i].id == admin.id) {
                  allAdmins.splice(i, 1);
                  break;
              }
          }
      } else {
          //if list on admins page didn't update from a search the index will be the same
          if (allAdmins[index].id == admin.id) {
              allAdmins[i] = admin;
          } else {
              //have to loop through all
              for (var i = 0; i < allAdmins.length; i++) {
                  if (allAdmins[i].id == admin.id) {
                      allAdmins[i] = admin;
                      break;
                  }
              }
          }
      }
  }

    function addToAllAdmins(admin){
        allAdmins.push(admin);
    }

    function getAllRoles(curEntityId) {
      if (!curEntityId) {
          curEntityId = entityService.getOrgEntityId();
      }
      return $http({ url: API_PREFIX + 'Admins/GetAllRoles', headers: { orgId: entityService.getOrgId(), entityId: curEntityId }, method: 'POST' }) //method is sometimes called without org in URL
      .then(response => response.data.data);
  }

  function inviteAdmin(emails, roles, curEntityId, entityIds, mailMessage, messageConfig) {
     let orgEntityId = entityService.getOrgEntityId(),
        entityId = curEntityId || orgEntityId;
		
		if (!entityIds || !entityIds.length) {
			entityIds = [entityId]
		}

		return $http({ url: API_PREFIX + 'Admins/InviteNewAdmin', data: { emails: emails, userRoleToEntity: roles, mailMessage }, headers: { entityIds }, method: 'POST', messageConfig })
      .then(response => {

        //if (entityIds.length == 1 && entityIds[0] == entityId) {
		//	return _buildAdditionalRoles(response.data.data.data[0], orgEntityId, entityId)
		//}

        return response.data
      });
  }

  //todo what's this vs removeAdminFromEntity
  //and not passing in any entityId?
  function removeAdmin(userId, curEntityId) {
    let orgEntityId = entityService.getOrgEntityId(),
        entityId = curEntityId || orgEntityId;
    return $http({ url: API_PREFIX + 'Admins/RemoveAdmin', data: { userId }, headers: { entityId: entityId, orgId: entityService.getOrgId() }, method: 'POST' });
  }

  //there was a backend method removeAdminFromEntity. todo
  function removeAdminFromEntity(userId, curEntityId) {
    let orgEntityId = entityService.getOrgEntityId(),
        entityId = curEntityId || orgEntityId;
    return $http({ url: API_PREFIX + 'Admins/RemoveAdminFromEntity', data: { userId }, headers: { entityId: entityId, orgId: entityService.getOrgId() }, method: 'POST' })
        .then(
          (response) => {
           return _buildAdditionalRoles(response.data.data, orgEntityId, entityId);
         });
  }

	//todo why not use removeAdmin here too
  function removeAdminFromOrg(userId) {
    return $http({ url: API_PREFIX + 'Admins/RemoveAdminFromOrg', data: { userId }, headers: { orgId: entityService.getOrgId()  }, method: 'POST' })
  }


  function addAdminToCompanies (email, entityId, roleId) {
    let orgEntityId = entityService.getOrgEntityId();
   
    return $http({ url: API_PREFIX + 'Admins/SetListRoleToAdmin', data: {email: email, roleId: roleId}, headers: { entityId: entityId }, method: 'POST' }) //SECURITY
	  .then(
          (response) => {
            return response;
        });
  }
  
  
  function isNotBetaResource(value) {
      return $.inArray(value.resourceId, betaOptions) == -1;
  }

  function getSecurityRoles() {
    return $http({ url: API_PREFIX + 'Admins/GetSecurityRoles', headers: { orgId: entityService.getOrgId()  }, method: 'POST' })
      .then((response) => {
        let { resources, roles } = response.data.data;

        //filter beta resources
        resources = resources.filter(isNotBetaResource);
        resources.forEach((res) => {
            res.childs = res.childs.filter(isNotBetaResource);
        });

        roles = roles.map((role) => {
          let resourcePermissionsMap = role.securityPermissions.reduce((map, permission) => {
            //return Object.assign(map, {
            //  [permission.resourceId]: permission.permissionId
              //});
              map[permission.resourceId] = permission.permissionId;
              return map;
          }, {});

          role.resources = resources.map(parentResource => {
            const resourceMapper = resource => {
              return Object.assign({}, resource, {
                permissionId: resourcePermissionsMap[resource.resourceId] || 0
              });
            };

            parentResource = resourceMapper(parentResource);
            parentResource.childs = parentResource.childs.map(resourceMapper);

            // has childs
            if (parentResource.childs.length) {
              // any of the child's permissionsId differs from first child
              if (parentResource.childs.some((childResource) => childResource.permissionId !== parentResource.childs[0].permissionId)) {
                parentResource.permissionId = -1; // 'Specific...'
              } else {
                parentResource.permissionId = parentResource.childs[0].permissionId;
              }
            }

            return parentResource;
          });

          let permissionIds = [], 
              rolePermissionId;

          role.resources.forEach(parentResource => {

           permissionIds.push(parentResource.permissionId);

           function checkIfEqual(){
              let first = permissionIds[0];
              return permissionIds.every(function(element) {
                 return element === first;
             });
           };
          
            if (checkIfEqual() == true ){
              rolePermissionId = permissionIds[0];
            } else{
              rolePermissionId = -1;
            }

          });

          role.permissionId = rolePermissionId;

          return role;
        });

        return { resources, roles }
      });
  };

  function updateSecurityRole(role) {
    let reqData = _mapRoleToSaveModel(role);

    return $http({ url: API_PREFIX + 'Admins/UpdateSecurityRole', data: reqData, method: 'POST' });
  }

  function createSecurityRole(role) {
    let reqData = _mapRoleToSaveModel(role);

    return $http({ url: API_PREFIX + 'Admins/CreateSecurityRole', data: reqData, method: 'POST' });
  }

  function deleteSecurityRole(role) {
	return $http({ url: API_PREFIX + 'Admins/DeleteSecurityRole', data: { roleId: role.roleId }, method: 'POST' });
 }

  function getSecurityPermissionName(permissionId) {
    let id = permissionId.toString();
    const map = {
     '-1': 'Various',
      '0': 'None',
      '1': 'Can view',
      '3': 'Can edit',
      '7': 'Can delete'
    };

    return map[id];
  }

  function _mapRoleToSaveModel(role) {
    let securityPermissions = [];

    const mapResourceToServerModel = resource => ({
      securityRoleId: role.roleId,
      securityResourceId: resource.resourceId,
      permissionId: (resource.permissionId == -1 ? 0 : resource.permissionId)
    });

    role.resources.forEach(parentResource => {
      securityPermissions.push(mapResourceToServerModel(parentResource));

      parentResource.childs.forEach(childResource => {
        securityPermissions.push(mapResourceToServerModel(childResource));
      });
    });

    return {
      roleName: role.roleName,
      securityPermissions
    };
  }
}