angular
  .module('app')
  .component('campaigns', {
    templateUrl: 'campaigns.tpl.html',
    controller: CampaignsController,
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    },
    bindings: { $router: '<' },
    $routeConfig: [
      {
        path: '/',
        name: 'Campaigns View',
        component: 'campaignsView',
        useAsDefault: true
      },
      {
          path: '/activity',
          name: 'Activity',
          component: 'activity'
      },
      {
        path: '/statistics',
        name: 'Statistics',
        component: 'statistics',
        data: {
          limitToOrg: true
        }
      },
      {
        path: '/transactions/...',
        name: 'Transactions',
        component: 'transactions',
        data: {
          limitToCampaign: true
        }
      },
      {
        path: '/people/...',
        name: 'People',
        component: 'people'
      },
      {
        path: '/email/...',
        name: 'Email',
        component: 'email'
      },
      {
        path: '/website/...',
        name: 'Website',
        component: 'website'
      },
      {
        path: '/forms/...',
        name: 'Forms',
        component: 'formsTab'
      },
      {
        path: '/fundraising/...',
        name: 'Fundraising',
        component: 'fundraising',
        data: {
          hideOnStandaloneAndTicketing: true
        }
      },
      {
        path: '/reports',
        name: 'Reports',
        component: 'reports',
        data: {
          limitToCampaign: true,
          resource: 'REPORTS'
        }
      },
      {
        path: '/system/...',
        name: 'System',
        component: 'settings'
      },
      {
        path: '/**',
        name: 'Page Not Found',
        component: 'pageNotFound'
      }
    ]
  }).directive('onFinishRender', function ($rootScope) {
    return {
      restrict: 'A',
      link: function (scope, element, attr) {
        if (scope.$last === true) {
          element.ready(function () {
            $rootScope.$emit('isOverflow');
          });
        }
      }
    };
});

function CampaignsController($rootScope, $location, $document, entityService,
    orgService, campaignService, administratorService, securityService, routingService)
{
  const vm = this;
  vm.showGroupInfoModal = false;
  vm.selectedInfoEntityId = null;
  vm.org = orgService.getOrg();
  vm.overlay = false;
  vm.newCampaignType = '';
  vm.collapsePath = isOverflowed(document.querySelector('.nav-breadcrumb'));
  vm.downActive = false;
  vm.openEntityInfoModal = openEntityInfoModal;
  vm.setStyle = setStyle;
  vm.toggleActive= toggleActive;
  vm.setOverlay = setOverlay;
  vm.overlayVisible = false;
  vm.security = {};
  vm.parentSecurity = {};
  vm.adminSecurity = {};
  vm.openEntityId = null;
  vm.openedCampaignId = null;
  vm.hasFundraising = true;
  vm.groupOrCampaignFound = true;
  vm.isOrgOpen = isOrgOpen();
  vm.onCampaignPage = false;
  vm.orgHasCreateLicense = vm.org.hasCreateLicense;
  vm.isSupervisor = orgService.getOrg().supervisor;
  vm.isChampion = !!window.isChampion;
  vm.openSupervisor = openSupervisor;
  vm.openCampaignSite = openCampaignSite;
  vm.openSiteBuilder = openSiteBuilder;
  vm.entityOverrideEventId = null;
  vm.showGivingTuesdayBtn = false;
  vm.updateShowAddBtn = updateShowAddBtn;
  vm.addButtonType = 'campaign';
  vm.onSettingsTab = false;

  vm.campaignId = orgService.getUrlCampaignId();
  
  vm.getCurrentEntityId = function(){
    return entityService.getOpenedEntityId();
  };
  refreshShowTabs();


  $rootScope.$on('isOverflow', testOverflow);
  $rootScope.$on('group.opened', refreshGroupPath);
  $rootScope.$on('groupOrCampaignNotFound', () => { vm.groupOrCampaignFound = false; });
  $rootScope.$on('onCampaignPage', () => { 
    vm.onCampaignPage = true; 
    refreshShowTabs(); 
  });
  $rootScope.$on('orgs.loaded', () => {
    vm.org = orgService.getOrg();
  });

  $rootScope.$on('orgs.permissionsLoaded', (data) => {
      loadAdminPermissions();
  });
  $rootScope.$on('campaign.opened', (event, data) => {
      vm.openedCampaign = data.name;
      vm.openedCampaignId = data.campId;
      
      var entity = entityService.getRawEntityById(vm.getCurrentEntityId());
      vm.primaryUrl = entity.urls && entity.urls.primary;
      vm.campaignEnded = entity.archived;
      vm.hasFundraising = !routingService.isStandaloneOrTicketing(entity);
      if (vm.campaignInstanceId && entity.oldInstancesWithYrAndEvntId) {
        var currentInstance = entity.oldInstancesWithYrAndEvntId.find(x => x.cmpInstanceId == vm.campaignInstanceId);
        if (currentInstance) {
          vm.campaignInstanceDisplay = currentInstance.year || vm.openedCampaign;
          vm.campaignInstanceEnded = currentInstance.eventArchived;
          vm.primaryUrl = currentInstance.urls && currentInstance.urls.primary;
        }
      }
      vm.siteBuilderUrl = siteBuilderUrl;
  });
  $rootScope.$on('campaign.name.changed', (event, data) => {
    vm.openedCampaign = data;
  });
  $rootScope.$on('admin.invite', (event, data) => {
    vm.showAdminModal = true;
    let entityIds = [];
    data.forEach(item => entityIds.push(item.entityId));
    vm.entityIds = entityIds;
  });
  $rootScope.$on('replicate', (event, data) => {
    vm.showAddNewCmpModal = true;
    vm.isReplicate = true;
    vm.newCampaignType = data.type;
  });
  $rootScope.$on('group.should.rename', (event, data) => {
      vm.clickedGroupId = data.groupId;
      vm.clickedGroupEntityId = data.entityId;
      vm.isRenameGroup = true;
      vm.showAddNewGroupModal = true;
  })
  $rootScope.$on('group.should.remove', (event, data) => {
    vm.showRemoveModal = true;
    vm.removeGroupId = data.groupId;
  })
  $rootScope.$on('unarchive', (event, data) => {
    vm.showUnarchiveCmpModal = true;
    vm.eventGroupHost = data.eventGroupHost;
  });

  loadAdminPermissions();
  
  /*if($location.search().groupId === undefined){
    loadData(); 
  } 

  function loadData() {

    let allAdmins =  administratorService.getAdminsFromOrg();

    if(allAdmins[0] != undefined){
      return allAdmins;
    }
    else{
       administratorService.getAllOrgAdmins()
      .then((admins) => {
        return admins;
      })
    } 
  };
  */

  function loadAdminPermissions() {
    var entity = entityService.getRawEntityById(vm.getCurrentEntityId());
    var parentEntityId = (campaignService.getOpenedGroup() && campaignService.getOpenedGroup().entityId) || entityService.getOrgEntityId();
    var parentEntity = entityService.getRawEntityById(parentEntityId);
    
    vm.security = securityService.getAdminPermissions(entity, 'CAMPAIGN');
    vm.parentSecurity = securityService.getAdminPermissions(parentEntity, 'CAMPAIGN');
    vm.adminSecurity = securityService.getAdminPermissions(entity, 'ADMIN_USERS');
  }
  
  function setOverlay($event){
    var toggle = !vm.overlayVisible;
    vm.overlayVisible = toggle;
    vm.overlay = toggle;
    
    if (vm.overlayVisible) {
      $rootScope.$emit('addNewSelected', $event, setOverlay);
      
      if (vm.suppressOverlayForAddBtn) {
        vm.overlayVisible = false;
        vm.overlay = false;
      }
    }
  }

  function updateShowAddBtn(on, btnType, suppressOverlay, tooltip, icon) {
    vm.showAddBtn = on;
    vm.addButtonType = btnType;
    vm.suppressOverlayForAddBtn = suppressOverlay;
    vm.addButtonTooltip = tooltip;
    vm.addButtonCustomIcon = icon;
  }

  /////////////////////////////////////////////////////////////////////////////////////


  function isOrgOpen() {
    return !orgService.getUrlCampaignPath() && !orgService.getUrlCampaignId() && !orgService.getUrlGroupId();
  }

  function refreshGroupPath() {
    vm.entityOverrideEventId = entityService.getRawEntityById(entityService.getOpenedEntityId()).entityOverrideEventId;

    vm.groupsPath = campaignService.getGroupsPath();

    let last = vm.groupsPath.length - 1;
    vm.lastGroup = vm.groupsPath[last];

    if (!vm.campaignId && vm.lastGroup){
      const regex = /group\/[^/]+\/$/;  // URL does not have more than ".../group/{groupid}/"
      if (regex.test(window.location.href)) {
        vm.showAddBtn = true;
      }
    }
    vm.beforeLastGroup = vm.groupsPath[last -1];

    let groupReverse = vm.groupsPath.reverse();
    vm.groupsShiftOne =  groupReverse.slice(1);

    loadAdminPermissions();
  }

  function openEntityInfoModal() {
	if(vm.campaignId){
		return;
	}	
	let groupId = orgService.getUrlGroupId();
    if(groupId){
		vm.showAddNewGroupModal = true;
		vm.isRenameGroup = true;
		vm.clickedGroupId = groupId;
		vm.clickedGroupEntityId = campaignService.getGroupById(groupId);
    } else{
        vm.showGroupInfoModal = true;
        vm.selectedInfoEntityId = orgService.getOrg().entityId;      
    }
  } 

  function setStyle() {
    // 100 is bottom block, 60 is offset at top
    return {'height' : ($('.overlay-height').height() + 160) + 'px'}
  }

  function isOverflowed(element){
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  function testOverflow() {
    vm.collapsePath = isOverflowed(document.querySelector('.nav-breadcrumb'));
  }

  function toggleActive(toBool) {
    if (typeof toBool !== 'undefined') {
      vm.downActive = toBool;
    } else {
      vm.downActive = !vm.downActive;
    }
  }

  function openSupervisor() {
    const entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
    const orgId = orgService.getOrgId();
	
	var entityId = entity.entityId;
	let openedCampaignInstanceId = orgService.getUrlCampaignInstanceId();
	if (openedCampaignInstanceId) {
		let campaignEntity = entityService.getRawEntityById(entity.entityId);
		let campaignInstanceEntity = entityService.getEntityFromInstanceId(campaignEntity, openedCampaignInstanceId);
		if(campaignInstanceEntity){
			entityId = campaignInstanceEntity.entityId;
		}
	}    
	
    openInNewTab(`/supervisor/${orgId}/entity/${entityId}`);
  }

  function openCampaignSite() {
    return openInNewTab(`https://${vm.primaryUrl}`);
  }

  function openSiteBuilder() {
    return openInNewTab(vm.siteBuilderUrl + vm.primaryUrl);
  }

  function openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  
  function refreshShowTabs() {
    vm.campaignInstanceId = orgService.getUrlCampaignInstanceId();
    let entity = entityService.getRawEntityById(vm.getCurrentEntityId());
    vm.showTabs = {
      activity: !vm.campaignInstanceId && !entity.activeEventIsArchived && routingService.anyResourcePermission(['CAMPAIGN'], entity),
      statistics: vm.isOrgOpen && routingService.anyResourcePermission(['CAMPAIGN'], entity),
      transactions: vm.onCampaignPage && !entity.activeEventIsArchived && !vm.campaignInstanceId && routingService.anyResourcePermission(securityService.navPermissions.transactions, entity),
      people: (vm.onCampaignPage || (routingService.anyResourcePermission(['PEOPLE_NATIONAL_TEAMS'], entity) && entity.settings.NationalTeamsEnabled)) && !entity.activeEventIsArchived && !vm.campaignInstanceId && routingService.anyResourcePermission(securityService.navPermissions.people, entity),
      email: routingService.anyResourcePermission(securityService.navPermissions.email, entity),
      forms: !vm.campaignInstanceId && !entity.activeEventIsArchived && routingService.anyResourcePermission(securityService.navPermissions.forms, entity),
      website: !vm.campaignInstanceId && !entity.activeEventIsArchived && routingService.anyResourcePermission(securityService.navPermissions.website, entity),
      fundraising: vm.hasFundraising && !entity.activeEventIsArchived && !vm.campaignInstanceId && routingService.anyResourcePermission(securityService.navPermissions.fundraising, entity),
      reports: vm.onCampaignPage && routingService.anyResourcePermission(securityService.navPermissions.reports, entity),
      system: !vm.campaignInstanceId && !entity.activeEventIsArchived && routingService.anyResourcePermission(securityService.navPermissions.system, entity)
    };
    vm.canEditContent = securityService.getAdminPermissions(entity, 'SETTINGS_EDIT_CONTENT_ON_SITE').EDIT;
  }
}