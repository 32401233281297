angular
  .module('app')
  .directive('loader', Loader);

function Loader() {
  return {
    restrict: 'E',
    scope: {
      size: "@"
    },
    template: `
        <div layout="row" layout-sm="column" layout-align="space-around">
            <md-progress-circular md-diameter="{{size}}"></md-progress-circular>
        </div>
        `
  }
}