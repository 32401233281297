angular
  .module('app')
  .component('securityRoles', {
    templateUrl: 'security-roles.tpl.html',
    controller: SecurityRolesController,
    controllerAs: 'vm'
  });

function SecurityRolesController($mdDialog, administratorService, securityService, orgService) {
  const vm = this;

  vm.securityRoles = [];
  vm.resources = [];
  vm.dataLoaded = false;
  vm.offEdits = offEdits;
  vm.editRole = editRole;
  vm.cancelEdit = cancelEdit;
  vm.saveRole = saveRole;
  vm.addNewRole = addNewRole;
  vm.deleteRole = deleteRole;
  vm.parentResourceChangePermission = parentResourceChangePermission;
  vm.selectAllPermissions = selectAllPermissions;
  vm.openPermissions = openPermissions;
  vm.changeRessourcePermission = changeRessourcePermission;
  vm.getPermissionName = getPermissionName;
  vm.security = {};

  let _cachedRoles = {};
  let _resources = [];
  
  vm.permissions = {
      NONE: 0,
      VIEW: 1,
      EDIT: 3,      // VIEW | EDIT
      DELETE: 7,    // VIEW | EDIT | DELETE
      VARIOUS: -1,
  }

  loadData();

  /////////////////////////////////////////////////////////////////

  function loadData() {
    administratorService
      .getSecurityRoles()
      .then(({roles, resources}) => {
        vm.dataLoaded = true;
        vm.securityRoles = roles;
        _resources = resources;
      });
  }

  vm.security = securityService.getAdminPermissions(orgService.getOrg(), 'ADMIN_USERS');

  function offEdits(index) {
    for (let i=0; i < vm.securityRoles.length; i++){
      if (i !== index && vm.securityRoles[i].isEditing && !vm.securityRoles[i].isDefault) {
        vm.saveRole(i, vm.securityRoles[i]);
        vm.securityRoles[i].nameIsEditing = false;
      }
      else if (i !== index && vm.securityRoles[i].isEditing && vm.securityRoles[i].isDefault){
         vm.securityRoles[i].isEditing = false;
         vm.securityRoles[i].nameIsEditing = false;
      }
    }
  }

  function editRole($index) {
    let role = vm.securityRoles[$index];
    _cachedRoles[role.roleId] = angular.copy(role);
    role.isEditing = true;
  }

  function cancelEdit($index) {
    let roleId = vm.securityRoles[$index].roleId;

    if (roleId > 0 && _cachedRoles[roleId]) {
      vm.securityRoles[$index] = angular.copy(_cachedRoles[roleId]);
      vm.securityRoles[$index].isEditing = false;
      vm.securityRoles[$index].nameIsEditing = false;
    } else { // not saved role
      vm.securityRoles.splice($index, 1);
    }
  }

  function saveRole(index, role) {
    role.isSaving = true;
    const cb = () => {
      role.isEditing = false;
      role.nameIsEditing = false;
    };

    if (role.roleId) { // update exited
      //role.permissionId = _checkRoleChildren(role.resources);
      // role.resources.forEach((res) => {
      //      res.permissionId = _checkRoleChildren(res.childs);
      // });
      administratorService.updateSecurityRole(role)
        .then(cb)
        .finally(() => role.isSaving = false);
    } else { // create new one
      let messageConfig = {
        successToastEnabled: true,
        successMsg: `Successfuly created role ${role.roleName}`
      };
      administratorService.createSecurityRole(role, messageConfig)
        .then((response) => {
          let roleId = response.data.data.roleId;
          role.roleId = roleId;
          cb();
        })
        .finally(() => role.isSaving = false);
    }
  }

  function addNewRole() {
    let role = {};
    /* Construct role object */
    role.roleId = null;
    role.roleName = 'New Role ' + (vm.securityRoles.length + 1);
    role.permissionId = 0;
    role.isEditing = true;
    role.nameIsEditing = true;
    role.isDefault = false;
    role.resources = angular.copy(_resources);

    // set all resources and childs to NONE permission
    role.resources.forEach(r => {
      r.permissionId = 0;
      r.childs.forEach(c => c.permissionId = 0);
    });

    vm.securityRoles.push(role);
  }

  function deleteRole($index) {
    let role = vm.securityRoles[$index];

    const cb = () => {
      vm.securityRoles.splice($index, 1);
    };

    let confirm = $mdDialog.confirm()
      .title('Confirm delete')
      .textContent(`Are you sure you want to delete role '${role.roleName}'?`)
      .ariaLabel('Delete')
      .ok('Yes')
      .cancel('No');

    $mdDialog
      .show(confirm).then(() => {
      role.isDeleting = true;

      if (_cachedRoles[role.roleId]) {
          administratorService
            .deleteSecurityRole(role)
            .then(cb)
            .finally(() => role.isDeleting = false);
        } else { // not saved role
          cb();
          role.isDeleting = true;
      }
      });
  }

  function _applyPermission(res, role){
      
    let childrenCanSet = {
        delete: true,
        edit: true,
        view: true,
    }
    if (res.childs){
        res.childs.forEach((child) => {
            childrenCanSet.delete = childrenCanSet.delete && child.canSet.Delete;
            childrenCanSet.edit = childrenCanSet.edit && child.canSet.Edit;
            childrenCanSet.view = childrenCanSet.view && child.canSet.View;
        })
    }
    
    if (
        (!childrenCanSet.delete && role.permissionId == vm.permissions.DELETE) ||
        (!childrenCanSet.edit && role.permissionId == vm.permissions.EDIT) ||
        (!childrenCanSet.view && role.permissionId == vm.permissions.VIEW)
    ) {
        res.permissionId = vm.permissions.VARIOUS;
        
    } else {
        res.permissionId = role.permissionId;
        
        if (!res.canSet.Delete && res.permissionId == vm.permissions.DELETE) {
            res.permissionId = vm.permissions.EDIT;
        }
        
        if (!res.canSet.Edit && res.permissionId == vm.permissions.EDIT) {
            res.permissionId = vm.permissions.VIEW;
        }
        
        if (!res.canSet.View && res.permissionId == vm.permissions.VIEW) {
            res.permissionId = vm.permissions.NONE;
        }
    }
  }

  function selectAllPermissions(role) {
      if (role.permissionId != vm.permissions.VARIOUS) {
          //hide resources if open
          role.open = false;
          role.resources.forEach(resource => {
              _applyPermission(resource, role);
              resource.childs.forEach(child => _applyPermission(child, role));
          });
      } else {
          role.open = true;
      }

  }


  function _checkRoleChildren(children){
     let permissionIds = [], rolePermissionId;
     children.forEach((res) =>{
       permissionIds.push(Number(res.permissionId));
     });

    function checkIfEqual(){
              let first = permissionIds[0];
              return permissionIds.every(function(element) {
                 return element === first;
             });
           };

      if (checkIfEqual() == true ){
            rolePermissionId = permissionIds[0];
          } else{
            rolePermissionId = vm.permissions.VARIOUS;
     }
        return rolePermissionId;
  }

  function parentResourceChangePermission(role, resource) {
    
   role.permissionId = _checkRoleChildren(role.resources);
    if (resource.permissionId == vm.permissions.VARIOUS) return;
    // inherit permissionId from parent 'resource'
    resource.childs.forEach(child => _applyPermission(child, resource));
  }

  

  function openPermissions(role){
      if (role.open === true) {
          role.open = false
      } else {
          role.open = true;
      }
  }

  function changeRessourcePermission(role, resource){
    resource.permissionId = _checkRoleChildren(resource.childs);
    role.permissionId = _checkRoleChildren(role.resources);
  }

  function getPermissionName(permissionId){
    return administratorService.getSecurityPermissionName(permissionId);
  }

}