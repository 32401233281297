angular.module('app').factory('entityContentService', entityContentService);

function entityContentService($http, $q, API_PREFIX, messageService) {
	const messageConfig = {
        advancedErrorFunction: messageService.advancedErrorsDialog,
        advancedErrorEnabled: true
	};
	
	return {
        getLiquidTemplate,
		updateLiquidTemplatePage,
		getTemplatePlaceholders,
		createNewLiquidTemplatePage,
		getSocialShares,
		updateTerm,
		deleteTerm,
		getAllTerms,
		getFormTerms,
		getFundraisingTerms
	};
	
	function processDataOnServer(url, data, entityId) {
		return $http({
			url: url,
			data: data,
			headers: { entityId },
			method: 'POST',
			messageConfig
		}).then(response => response);
	}

    function getLiquidTemplate(entityId) {
		return processDataOnServer( `${API_PREFIX}/GetLiquidTemplate`, {}, entityId)
		.then(response => response.data.items);
	}
	
	function getSocialShares(entityId, language) {
		return processDataOnServer(`${API_PREFIX}/AllSocialTerms`, { language: language }, entityId)
		.then(response => response.data.items);
	}

	function getAllTerms(entityId, language) {
		return processDataOnServer(`${API_PREFIX}/AllDictionaryTerms`, { language: language }, entityId)
		.then(response => response.data.items);
	}

	function getFormTerms(entityId, language) {
		return processDataOnServer(`${API_PREFIX}/CustomFormTerms`, { language: language }, entityId)
		.then(response => response.data.items);
	}

	function getFundraisingTerms(entityId, language) {
		return processDataOnServer(`${API_PREFIX}/CustomFundraisingTerms`, { language: language }, entityId)
		.then(response => response.data.items);
	}
	
	function getTemplatePlaceholders(entityId, flag, activityType) {
		let data = { flag,
				activityType };
		return processDataOnServer(`${API_PREFIX}/GetTemplatePlaceholder`, data, entityId)
		.then(response => response.data.items);
	}

    function updateLiquidTemplatePage(entityId, id, fieldName, fieldValue) {
		let data = { //EntityTemplatePage object
			EntityTemplatePageID: id,
			PageName: fieldName,
			PageContent: fieldValue
		};
		return processDataOnServer(`${API_PREFIX}/UpdateLiquidTemplatePage`, data, entityId)
		.then(response => response.data.items);
	}
	
	function createNewLiquidTemplatePage(entityId, id, pageName, pageType, pageExt) {
		let data = { //EntityTemplatePage object
			EntityTemplateID: id,
			PageName: pageName,
			PageType: pageType,
			PageExt: pageExt,
			PageContent: ''
		};
		return processDataOnServer(`${API_PREFIX}/CreateNewLiquidTemplatePage`, data, entityId)
		.then(response => response.data.items);
	}
	
	function updateTerm(entityId, item, termType, language, createOverrideTerm) {
		item = mapTermItem(item, language);
		var url = `${API_PREFIX}/Update${termType}Term`;
		return processDataOnServer(url, { submittedTermDefinition: item, createOverrideTerm: createOverrideTerm }, entityId)
		.then(response => response.data.items);
	}

	function deleteTerm(entityId, item, termType, language) {
		item = mapTermItem(item, language);
		var url = `${API_PREFIX}/Delete${termType}Term`;
		return processDataOnServer(url, { submittedTermDefinition: item }, entityId)
		.then(response => response.data.items);
	}

	function mapTermItem (item, language) {
		if ((item.Flags & 1024) === 1024) {//1024: IsFileEntry
			language = 'file';
		}
		return {
		  termId: item.Id,
		  definition: CKEDITOR.instances[item.Id] && CKEDITOR.instances[item.Id].mode === 'source' ? CKEDITOR.instances[item.Id].getData() : item.Fields.find(r => r.name === 'Definition').value,
		  language: language
		};
	}
}
