angular
  .module('app')
  .directive('topNavMenuItem', TopNavMenuItem);

function TopNavMenuItem(configRouteService) {
  return {
    restrict: 'A',
    template: `
        <a ng-link="['{{topNavMenuItem}}']">{{topNavMenuItem}}</a>
        <ul ng-click="onTopNavClick($event)">
          <li ng-repeat="item in items" class="top-nav-menu-items"><a ng-link="['{{topNavMenuItem}}', '{{item.name}}']">{{item.name}}</a></li>
        </ul>
      `,
    scope: {
      topNavMenuItem: '@'
    },
    link($scope, $document) {
      var topNavConfig = configRouteService.getRouteConfig($scope.topNavMenuItem.toLowerCase());
      $scope.items = topNavConfig && topNavConfig.filter((i) => configRouteService.hasPermission(i.path, i.data) && configRouteService.shouldShow(i.data) === true);
      $scope.onTopNavClick=function(element){
        angular.element(element.currentTarget).hide();
      }
    }
  }
}