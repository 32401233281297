angular
    .module('app')
    .component('emailTemplates', {
      controller: emailTemplatesController,
      bindings: {
        $router: '<'
      },
      require: {
        parentComponent: '^fundraising'
      },
      templateUrl: 'email-templates.tpl.html',
      controllerAs: 'vm',
      $canActivate: function($nextInstruction, routingService) {
        return routingService.validateRoute($nextInstruction);
      }
    });

function emailTemplatesController($mdDialog, $controller, $q, $scope, orgService, entityService, emailService, securityService) {
  const vm = this;
  var entity = entityService.getOpenEntity();
  vm.configOptionId = emailService.EMAIL_CONFIG_OPTIONS.fundraisingEmails;
  vm.entityType = entity.entityType;
  vm.security = securityService.getAdminPermissions(entity, 'FUNDRAISER_EMAIL_TEMPLATES');
  vm.loading = true;

  this.$routerOnActivate = function(next, prev) {
    this.parentComponent.showIframe = false;
    this.parentComponent.loading = false;
  }

  vm.getData = function() {
    vm.dataRequest()
        .then(r => {
          vm.grid = r.items;
          vm.newItem = r.newItem;
        }).catch(() => {

    }).finally(() => vm.loading = false);
  }

  vm.dataRequest = function() {
    return emailService.getFundraiserEmailTemplates()
  }

  orgService.orgInfoLoadedPromise.then(r => {
    vm.getData();
  });

  vm.showActivateOptions = (item, forTextDisplay) => emailService.showActivateOptions(item, forTextDisplay);
  vm.editorButtons = emailService.getEmailEditorButtons();

  vm.clicked = function(item) {
    return vm.saveEmail(item).then(r => {
        item.Id = r.item.Id;
    });
  }

  vm.open = function(item) {
    emailService.openEmail(item);
  }

  vm.overrideParent = function(item) {
      return vm.saveEmail(item).then(r => {
          return r.item;
      });
  }
  
  vm.delete = function(item) {
    return emailService.deleteEmailTemplate(item);
  }

  vm.saveEmail = function(item) {
    return emailService.createOrUpdateEmailTemplate(item);
  }

  vm.adoptParent = function(item) {
      return emailService.deleteEmailTemplate(item).then(r => {
          return r.item;
      });
  }

  $scope.$on('clearOverrides', function (event, item) {
      if (item.configOptionId === vm.configOptionId) {
          return emailService.clearEmailTemplateOverrides(item);
      }
  });
}