angular.module('app').component('campaignsList', {
  bindings: {
    campaigns: '=',
    mode: '@',
    filter: '=',
    sort: '=',
    showHeader: '=',
    onCampaignClick: '&',
    onCampaignDbclick: '&',
    onOutsideClick: '&',
    turnOffFilters: '&'
  },
  templateUrl: 'campaigns-list.tpl.html',
  controller: CampaignsListController,
  controllerAs: 'vm',
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateNoPendingChanges($nextInstruction);
  },
});

function CampaignsListController(securityService, orgService, $scope, $window, $timeout) {
  const vm = this;
  vm.showToolTip = showToolTip;
  vm.getSecurity = getSecurity;
  vm.loadMore = loadMore;
  vm.filteredItems = [];
  vm.filterUpdatesInProgress = 0;
  vm.siteBuilderUrl = siteBuilderUrl;
  vm.humanizeTimeZone = humanizeTimeZone;
  vm.scrollCount = 20;
  vm.loader = true;
  $timeout(function () {
      loadMore();
  });

  vm.isSupervisor = orgService.getOrg().supervisor;
  vm.orgId = orgService.getOrgId();

  function showToolTip(campaign){
      if (campaign.archived){
        return '(ended)';
      }
      else if (campaign.delisted){
         return '(delisted)';
      }
  }

  function humanizeTimeZone(timezone){
    return timezone.replace('/', ' - ').replace('_', ' ');
  }

  function getSecurity(entity, resource) {
    return securityService.getAdminPermissions(entity, resource);
  }

  $scope.$on('beforeFilterOrSortUpdated', function () {
      $window.scrollTo(0, 0); //scroll to top because infinite-scroll was triggering a bunch of times and made it look glitchy
      vm.filterUpdatesInProgress++;
      vm.loader = true;
      vm.filteredItems.length = 0;
      vm.scrollCount = 20;
      $timeout(function () { 
          loadMore();
          vm.filterUpdatesInProgress--;
      }, 500);
  });

  function loadMore() {
      //if last loadMore didn't add any new campaigns to vm.filteredItems that means we're at the end of the scroll
      var addedLessThan20 = vm.filteredItems.length < vm.scrollCount;
      vm.loader = !addedLessThan20;

      vm.scrollCount += 20;
  }; 
}