angular
    .module('app')
    .directive("contactsList", () => ({
      controller: contactsList,
      controllerAs: 'vm',
      link: (scope, element, attrs, ngModel) => {
        scope.$on('updateValue', function(event, value) {
          // Check members and contacts separately (instead of checking if value equals $viewValue)
          // because the value may sometimes have extra metadata (e.g. totalSelected) 
          if (!_.isEqual(_.sortBy(value.members), _.sortBy(ngModel.$viewValue.members)) ||
              !_.isEqual(_.sortBy(value.contacts), _.sortBy(ngModel.$viewValue.contacts))) {
            ngModel.$setViewValue(value)
          }
        }, true );

        ngModel.$isEmpty = function(value) {
          return !value || !value.totalSelected || value.totalSelected === 0;
        };
      },
      templateUrl: 'selectable-grid-container.tpl.html',
      restrict: 'E',
      transclude: true,
      require: '?ngModel',
      scope: {
        ngModel: '<',
        ngDisabled: '<',
      }
    }));

function contactsList($scope, $timeout, agGridService) {
  const vm = this;

  vm.itemName = 'recipient';
  vm.itemNamePlural = 'recipients';
  
  vm.gridFunctions = {};

  vm.gridFunctions.getColumnDefs = function(editable) {
    return [
      {
        field: 'firstName',
        headerName: 'First Name',
        filter: 'agTextColumnFilter',
        checkboxSelection: editable,
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        filter: 'agTextColumnFilter',
      },
      {
        field: 'Email',
        filter: 'agTextColumnFilter',
      },
      agGridService.columnDefinitions.boolean({
        field: 'IsDonor',
        headerName: 'Donor',
        filter: editable,
      }),
      agGridService.columnDefinitions.boolean({
        field: 'IsRegistrant',
        headerName: 'Registrant',
        filter: editable,
      }),
      agGridService.columnDefinitions.boolean({
        field: 'Fundraiser',
        filter: editable,
      }),
      agGridService.columnDefinitions.boolean({
        field: 'HasTeam',
        headerName: 'Has Team',
        filter: editable,
      }),
    ];
  }

  vm.gridFunctions.getDatasource = function(editable, ngModel) {
    return editable ?
        agGridService.getDatasource('Contact', 'AllMembers') :
        agGridService.getDatasource('Contact', 'MembersById', () => {
          return {
            memberIds: ngModel.members,
            contactIds: ngModel.contacts,
          }
        }
    );
  }

  vm.gridFunctions.isNodeSelected = function(data, ngModel) {
    return (ngModel.members && ngModel.members.includes(data.MemberID)) || (ngModel.contacts && ngModel.contacts.includes(data.ContactID));
  }
  
  vm.gridFunctions.updateSelectedRows = function(selectedNodes) {
    let recipients = { members: [], contacts: [], totalSelected: 0};
    
    _.forEach(selectedNodes, (node) => {
      if (node.data.MemberID) {
        recipients.members.push(node.data.MemberID);
      } else if (node.data.ContactID) {
        recipients.contacts.push(node.data.ContactID);
      }
    });
      
    recipients.totalSelected = selectedNodes.length;

    $scope.$broadcast('updateValue', recipients);
  }

  vm.gridFunctions.additionalProperties = function(editable) {
    return {
      getRowNodeId: (data) => {
        if (data.MemberID) return `m-${data.MemberID}`
        if (data.ContactID) return `c-${data.ContactID}`
        if (data.UserAccountID) return `u-${data.UserAccountID}`
        return `n-${data.firstName}-${data.lastName}-${data.Email}`;
      },
    }
  }
}



