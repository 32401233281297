angular
  .module('app')
  .component('nationalTeamsModal', {
    bindings: {
      showModal: '=',
      nationalTeams: '=',
      team: '=',
      nationalTeamIds: '=',
      security: '='
    },
    controller: nationalTeamsModalCtrl,
    controllerAs: 'vm'
  });

function nationalTeamsModalCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.showModal', () => {
    if (vm.showModal) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'national-teams-modal.tpl.html',
        
        // Handled by modal itself to allow intercepting close on server errors
        escapeToClose: false,
        clickOutsideToClose: false,
        
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        multiple: true,
        locals: {
          parent: $scope,
          team: vm.team,
          nationalTeamIds: vm.nationalTeamIds,
          security: vm.security
        }
      }).catch(() => {})
      .finally(() => {
        vm.showModal = false;
      });
  }
}

function ModalContentCtrl($mdDialog, $scope, $rootScope, entityService,
                          fileUpload, messageService, nationalTeamsService, parent, team, nationalTeamIds, security) {
  const vm = this;
  vm.newTeamForm = false;
  vm.team = team;
  vm.editMode = !!vm.team.TeamId;
  vm.saveInProgress = false;
  vm.createInProgress = false;
  vm.showErrorMessages = false;
  vm.fieldServerErrors = {}
  vm.entityId = entityService.getOpenedEntityId();
  vm.security = security;
  vm.selectedTab = undefined;
  vm.showNationalTeamAddManager = false;
  vm.addManagerEmail = undefined;
  vm.addManagerErrors = undefined;
  
  nationalTeamsService.getNonEnabledNationalTeams(nationalTeamIds).then(r => {
    vm.orgNationalTeams = r;
    vm.newTeamForm = !vm.editMode && !vm.orgNationalTeams.length;
  });

  vm.nationalTeamFields = {
    TeamName: 1,
    Url: 2,
    TeamDescription: 3,
    GoalAmount: 4,
    TeamImage: 5,
    TeamType: 6,
  }
  vm.popupTitle = popupTitle;
  vm.externalNationalTeamUrl = externalNationalTeamUrl;
  vm.updateSubDomain = updateSubDomain;
  vm.createNationalTeam = createNationalTeam;
  vm.removeNationalTeamManager = removeNationalTeamManager;
  vm.addNationalTeamManager = addNationalTeamManager;
  vm.addNationalTeam = addNationalTeam;
  vm.saveNationalTeamField = saveNationalTeamField;
  vm.cancel = closeModal;
  vm.save = () => closeModal(true);
  vm.entityWithSettings = entityService
      .getEntityWithSettings(entityService.getOpenedEntityId())
  vm.currencySymbol = vm.entityWithSettings
    .settings.DefaultCurrency
    .Symbol;
  vm.addOverlay = addOverlay;
  vm.removeOverlay = removeOverlay;
  vm.onFieldKeyUp = onFieldKeyUp;
  $scope.onUploadFile = onUploadFile;
  
  vm.openNewTeamForm = function() {
    vm.newTeamForm = true;
  }
  
  vm.informationTabDeselected = function() {
    var tabToSwitchTo = vm.selectedTab;
    
    var e = $rootScope.$broadcast('tabSwitched', true, function() {
      vm.selectedTab = tabToSwitchTo;
    });

    if(e.defaultPrevented) {
      vm.selectedTab = 0;
    }
  }
  
  function externalNationalTeamUrl() {
    if(vm.team && vm.team.TeamId && vm.entityWithSettings.entityType === "Campaign" && vm.entityWithSettings.urls && vm.entityWithSettings.urls.primary) {
      return `https://${vm.entityWithSettings.urls.primary}/NationalTeam/View/${vm.team.TeamId}`
    }
  }
  
  function popupTitle() {
    if (vm.newTeamForm) {
      return 'newTeam';
    }
    
    if (vm.team.TeamName) {
      if (vm.showNationalTeamAddManager) {
        return 'addManager';
      }
      return 'editTeam';
    }
    
    return 'selectTeam';
  }

  function onUploadFile(event) {
    fileUpload.onUploadFile(event, vm.entityId, 'national-team', vm.team.TeamId);
  }

  function onFieldKeyUp($event, field, form) {
    if(field === 'TeamName' && !form.Url.$dirty) {
        vm.updateSubDomain()
    }
  }

  function updateSubDomain() {
    if (!vm.editMode && vm.team.TeamName) vm.team.Url = vm.team.TeamName.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9\-]+/g, '')
  }

  function removeNationalTeamManager(userAccountId) {

    nationalTeamsService.removeNationalTeamManager(vm.team.TeamId, userAccountId).then(response => {
      nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
        parent.vm.nationalTeams = [];
        parent.vm.nationalTeams = r.nationalTeams;
      });

      vm.team = response.nationalTeam;
    }, response => {
    })
  }

  function addNationalTeamManager() {

    nationalTeamsService.addNationalTeamManager(vm.team.TeamId, vm.addManagerEmail).then(response => {
      nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
        parent.vm.nationalTeams = r.nationalTeams;
      });

      vm.team = response.nationalTeam;
      vm.addManagerErrors = undefined;
      vm.addManagerEmail = undefined;
      vm.showNationalTeamAddManager = false;
    }, response => {
      vm.addManagerErrors = response.data.errors;
    })
  }
  
  function saveNationalTeamField(fieldName, val, callback) {
    var field = vm.nationalTeamFields[fieldName];
    
    nationalTeamsService.editNationalTeam(vm.team.TeamId, field, val).then(response => {

      nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
        parent.vm.nationalTeams = r.nationalTeams;
      });

      callback(response);
    }, response => {
      callback(response);
    })
  }

  function createNationalTeam(form) {
    if (form.$invalid) {
      vm.showErrorMessages = true;
      return;
    }

    vm.showErrorMessages = false;
    vm.createInProgress = true;

    nationalTeamsService.createNationalTeam(vm.team)
    .then(newTeam => {
      parent.vm.nationalTeamIds.push(newTeam.TeamId);
      nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
        parent.vm.nationalTeams = r.nationalTeams;
      });

      vm.cancel();
    }, r => {
      vm.fieldServerErrors = getFieldServerErrors(r.data.errors);
    })
    .finally(() => {
      vm.createInProgress = false;
      vm.saveInProgress = false;
    });
  }
  
  function getFieldServerErrors(errors) {
    var serverErrors = {};
    
    for(var errorKey in errors) {
      var error = errors[errorKey];

      if(error.ID && error.error) {
        serverErrors[error.ID.toLowerCase()] = error.error;
      }
    }
    
    return serverErrors;
  }

  function addNationalTeam(e, team) {
    if (!team.added) {
      nationalTeamsService.addNationalTeam(team.TeamId)
      .then(response => {
        parent.vm.nationalTeamIds.push(team.TeamId);
        nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
          parent.vm.nationalTeams = r.nationalTeams;
          team.added = true;
        });
      });
    } else {
      nationalTeamsService.removeNationalTeam(team.TeamId)
      .then(response => {
        parent.vm.nationalTeamIds.splice(parent.vm.nationalTeamIds.indexOf(team.TeamId), 1);
        nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
          parent.vm.nationalTeams = r.nationalTeams;
          team.added = false;
        });
      });
    }
  }

  $scope.$on('closeModal', function(event) {
    closeModal();
  })
    
  function closeModal(saveBeforeClosing) {
    var e = $rootScope.$broadcast('modalClosed', saveBeforeClosing, vm.cancel);
    
    if(!e.defaultPrevented) {
      parent.vm.team = {};
      $mdDialog.hide();
    }
  }
  
  function removeOverlay(){     
     document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
  }

  function addOverlay(){
    document.querySelector('.fileInput-placeholder').style.zIndex = '1';
  }

  $rootScope.$on('file.cropped', (event, data) => {
    if(data.id == vm.team.TeamId) {
      vm.team.ImageUrl = data.img;

      if (vm.editMode) {
        vm.saveInProgress = true;
        nationalTeamsService.editNationalTeam(vm.team.TeamId, vm.nationalTeamFields.TeamImage, vm.team.ImageUrl).then(response => {
          nationalTeamsService.getEntityNationalTeams(parent.vm.nationalTeamIds).then(r => {
            parent.vm.nationalTeams = r.nationalTeams;
            vm.saveInProgress = false;
          });
        });
      }
    }
  });
}
