angular
  .module('app')
  .component('mediaLibrary', {
    controller: mediaLibraryController,
    bindings: { 
      $router: '<'
    },
    require: {
      website: '^website'
    },
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function mediaLibraryController(extJsLoader, iframeMessageHandler) {
  const vm = this;
  this.$routerOnActivate = function(next, prev) {
    extJsLoader.extJsController.updateInnerPage('Content-Images');
    this.website.showIframe = true;
  }
}