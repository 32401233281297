angular
    .module('app')
    .directive("selectableGrid", () => ({
      controller: selectableGrid,
      controllerAs: 'vm',
      link: (scope, element, attrs, ngModel) => {
        ngModel.$isEmpty = function(value) {
          return !value || !value.totalSelected || value.totalSelected === 0;
        };
      },
      templateUrl: 'selectable-grid.tpl.html',
      restrict: 'E',
      transclude: true,
      require: '?ngModel',
      scope: {
        ngModel: '<',
        ngDisabled: '<',
        gridFunctions: '<',
        itemName: '@',
        itemNamePlural: '@',
      }
    }));

function selectableGrid($scope, $timeout, agGridService) {
  const vm = this;

  function getColumnDefs(editable) {
    return $scope.gridFunctions.getColumnDefs(editable);
  }
  
  function getDatasource(editable) {
    return $scope.gridFunctions.getDatasource(editable, $scope.ngModel)
  }
  
  function getServerSideStoreType(editable) {
    if ($scope.gridFunctions.getServerSideStoreType) {
      return $scope.gridFunctions.getServerSideStoreType(editable)
    }
    return editable ? 'full' : 'partial';
  }
  
  function updateSelectedRows(event, editable) {
    if (editable) {
      let selectedNodes = event.api.getSelectedNodes();
      $scope.gridFunctions.updateSelectedRows(selectedNodes);

      let selectedNotDisplayed = false;

      _.forEach(selectedNodes, (node) => {
        if (!node.displayed) {
          selectedNotDisplayed = true;
        }
      });
      
      $scope.$apply(() => {
        vm.selectedNotDisplayed = selectedNotDisplayed;
        vm.selectedCount = selectedNodes.length;
        vm.filterApplied = event.api.isAnyFilterPresent();
      })
    }
  }
  
  function getGrid(editable) {
    var grid =  agGridService.createNewGrid(getColumnDefs(editable), {
      sideBar: false,
      datasource: getDatasource(editable),
      pagination: !editable,
      paginationAutoPageSize: true,
      serverSideStoreType: getServerSideStoreType(editable),
      autoSizeOnLoad: true,
      rowSelection: 'multiple',
      rowMultiSelectWithClick: editable,
    }, {
      onSelectionChanged: (event) => updateSelectedRows(event, editable),
      onFilterChanged: (event) => updateSelectedRows(event, editable),
      onModelUpdated: (event) => {
        if (editable) {
          event.api.forEachNode(node => {
            if (node.data) {
              if ($scope.gridFunctions.isNodeSelected(node.data, $scope.ngModel)) {
                node.setSelected(true)
              }
            }
          });
        }
      }
    });
    
    return _.merge(grid, $scope.gridFunctions.additionalProperties ? $scope.gridFunctions.additionalProperties(editable) : {});
  }

  vm.selectedCount = 0;
  vm.selectedNotDisplayed = false;
  vm.filterApplied = false;

  vm.nonEditableGrid = getGrid(false);
  vm.editableGrid = getGrid(true);
}



