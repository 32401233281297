
var routeConfig = [ 
  {
    path: '/',
    name: 'EmailRouter',
    component: 'emailRouter',
    data: { dontRoute: true,
           showFunc: function() {return false;} },
    useAsDefault: true
  },
  {
    path: '/automatedEmails',
    name: 'Automated Emails',
    component: 'autoEmail',
    data: {
      resource: 'AUTO_RESPONDERS',
    }
  },
  {
    path: '/customEmails',
    name: 'Custom Emails',
    component: 'triggerEmails',
    data: {
      resource:'TRIGGERS',
    }
  },
  {
    path: '/adminTemplates',
    name: 'Admin Templates',
    component: 'adminTemplates',
    data: {
      resource: 'EMAIL_TEMPLATES',
    }
  },
  {
    path: '/outbox',
    name: 'Outbox',
    component: 'outbox',
    data: {
      resource: 'EMAIL_PENDING_EMAILS',
      limitToCampaign: true
    }
  },
  {
    path: '/sentAutoEmail',
    name: 'Sent Automated Emails',
    component: 'sentAutoEmail',
    data: {
      resource: 'EMAIL_SENT_EMAIL_SYSTEM',
      limitToCampaign: true,
      allowOnInstance: true,
    }
  },
  {
    path: '/sentAdminEmail',
    name: 'Sent Admin Emails',
    component: 'sentAdminEmail',
    data: {
      resource: 'EMAIL_SENT_EMAIL_BLAST',
      limitToCampaign: true
    }
  },
  {
    path: '/emailDesigner',
    name: 'Email Designer',
    component: 'emailDesigner',
    data: {
      resource: 'EMAIL_HEADER_FOOTER',
    }
  },
  {
    path: '/pdfAttachment',
    name: 'PDF Receipt',
    component: 'pdfAttachment',
    data: {
      resource: 'PDF_RECEIPT_TEMPLATE',
      routeFunc: function(entity) {
        return entity.settings.HasPdfReceiptingEnabled;
      }
    }
  },
  {
    path: '/emailAdvanced',
    name: 'Advanced',
    component: 'emailAdvanced',
    data: {
      anyResource: ['EMAIL_ADVANCED_OPTIONS'],
    }
  }
];

angular
  .module('app')
  .component('email', {
    templateUrl: 'email.tpl.html',
    controllerAs: 'vm',
    require: {
      campaigns: '^campaigns'
    },
    bindings: { $router: '<' },
    controller: emailController,
    $routeConfig: routeConfig,
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateNoPendingChanges($nextInstruction);
    }
  });
  
  angular.module('app').constant('emailRouteConfig',routeConfig);

function emailController($rootScope, routingService, entityService, orgService, extJsLoader, iframeMessageHandler, $rootRouter, securityService) {
  const vm = this;
  var entity = entityService.getOpenEntity();
  vm.entityType = entity.entityType;
  vm.loading = true;
  vm.navs = routeConfig;
  vm.showComposeModal = false;
  
  $rootScope.$on('addNewSelected', ($event, targetEvent) => {
    vm.showComposeModal = true;
    vm.composeModalTargetEvent = targetEvent;
  });

  vm.$onInit = function() {
    if (vm.entityType === 'Campaign') {
      let security = securityService.getAdminPermissions(entity, 'EMAIL_SEND_EMAILS');
      vm.campaigns.updateShowAddBtn(security.EDIT, 'composeEmail', true, 'Compose Email', 'feather');
    }
  };

  this.$routerOnDeactivate = function(p, n) {
    vm.campaigns.updateShowAddBtn(false, 'campaign');
  }

  routingService.updateRouting()
    .then(r => {
      vm.openEntity = r;
      entityService.getEntitySettings(r).then(entity => {
        vm.onGroup = entity.entityType === 'CampaignGroup';
        vm.onOrg = entity.entityType === 'Org';
        vm.loading = false;
      });
    });
}
