angular
  .module('app')
  .component('integrationCard', {
    bindings: {
      integration: '<',
      $router: '<',
      showLog: '<',
    },
    templateUrl: 'integration-card.tpl.html',
    controller: IntegrationCardController,
    controllerAs: 'vm'
  });

function IntegrationCardController(orgIntegrationsService) {
  const vm = this;

  vm.open = function() {
    vm.$router.navigate(['Integration Details', {
        type: vm.integration.Type,
        typeId: vm.integration.Id.split('/').join('~~')
    }]);
  };

  vm.hasLogs = function() {
    return vm.integration && orgIntegrationsService.hasLogs(vm.integration.Type) >= 0;
  };
}