angular.module('app').component('formSingle', {
  templateUrl: 'form-single.tpl.html',
  controllerAs: 'vm',
  controller: formSingleController,
  bindings: {
    fields: '=',
    form: '=',
    id: '<',
    item: '<',
    editable: '<',
    editorButtons: '<',
    isNewItem: '<',
    modalActions: '=?',
    onChange: '<',
    steps: '<',
    inModal: '<',
    errors: '=',
    totalFormGroups: '=?',
    validateActiveGroup: '=?',
  }
});

function formSingleController($scope, $mdUtil, messageService, formService, $element) {
  const vm = this;
  vm.initialized = false;
  vm.groupModalActions = [];

  $scope.$on('nextGroup', function(e) {
    if (validateActiveGroup()) {
      nextGroup();
    } else {
      markActiveGroupIncomplete();
    }
  });

  $scope.$on('markGroupIncomplete', function(e, groupIndex) {
    markGroupIncomplete(groupIndex);
  });

  function markActiveGroupIncomplete(groupIndex) {
    markGroupIncomplete(vm.form.activeGroup);
  }
  
  function markGroupIncomplete(groupIndex) {
    vm.formGroups[groupIndex].completed = false;
  }
  
  function validateActiveGroup(validateSilently) {
    let errors = vm.activeGroupErrors(true);

    if(errors.length) {
      if (!validateSilently) {
        messageService.showErrorToast(_.first(errors), $element, 2000);
      }

      return false;
    } else {
      return true;
    }
  }

  function nextGroup() {
    vm.formGroups[vm.form.activeGroup].completed = true;

    // Run in next tick to ensure digest has enabled tab before trying to switch to it
    $mdUtil.nextTick(function() {
      vm.form.activeGroup++;
    });
  }

  vm.fieldContainerClass = function(field) {
    return `field-container-type-${field.fieldType} field-container-name-${_.kebabCase(field.name)}`;
  }

  vm.formGroupContainerClass = function(formGroup) {
    return `form-single-inner form-single-inner-height form-tab-container-${_.kebabCase(formGroup.name)}`;
  }

  // These directive require a string of 'false' in order be turned off
  vm.dynamicHeight = () => vm.inModal ? 'false' : true;
  vm.centerTabs = () => !vm.steps ? 'false' : true;

  vm.tabEnabled = function($index) {
    // Always enable first tab
    if ($index === 0) return true;
    
    // Enabled if completed
    let thisTab = vm.formGroups[$index];
    if (thisTab.completed) return true;

    // Enabled if previous tab completed
    let previousTab = vm.formGroups[$index - 1];
    return previousTab.completed;
  }

  vm.onTabSelect = function($index) {
    
    // Unload and reload fields in next tick to allow components that want to reload completely on their tab being shown to do so
    _.forEach(vm.fields, (field) => {
      field.unloaded = true
    });
    
    $mdUtil.nextTick(function() {
      vm.modalActions = vm.groupModalActions[$index];

      _.forEach(vm.fields, (field) => {
        field.unloaded = false
      });
    });
  }
  
  vm.onTabDeselect = function($index) {
    // Copy any ckeditor field to the item so source changes are not lost
    _.forEach(vm.fields, (field) => {
      if (field.fieldType === 'Textarea' && CKEDITOR.instances[field.name+'-'+vm.id] && CKEDITOR.instances[field.name+'-'+vm.id].mode === 'source') {
        field.value = CKEDITOR.instances[field.name+'-'+vm.id].getData();
      }
      field.displayError = undefined;
    });
  }
  
  vm.fieldsForTab = function(formGroup) {
    return (field, index, fields) => {
      return !field.displayOptions.inEditor && field.displayOptions.formGroup == formGroup.name;
    }
  }
  
  vm.getFormForGroup = function(formGroup) {
    return vm.form['form-' + formGroup];
  }
  
  function getErrorMessage(errorGroupKey, field) {
    let fieldName = field.$$attr.validationName || field.$name;

    switch (errorGroupKey) {
      case 'required':
        return `${fieldName} is required`;
      default:
        return `${fieldName} is invalid`;
    }
  }

  function getActiveFormGroupErrors(setErrorOnField) {
    return getErrorsForForm(vm.getFormForGroup(vm.form.activeGroup), setErrorOnField)
  }
  
  function getErrors(setErrorOnField) {
    return getErrorsForForm(vm.form, setErrorOnField)
  }
  
  function getErrorsForForm(form, setErrorOnField) {
    let errors = [];

    _.forEach(form.$error, (errorGroup, errorGroupKey) => {
      _.forEach(errorGroup, (error) => {
        if (error.$$attr) {
          let msg = getErrorMessage(errorGroupKey, error);
          errors.push(msg);

          if (setErrorOnField) {
            let fieldPath = JSON.parse(error.$$attr.fieldPath);
            let formField = formService.getFieldFromItem(vm.item, fieldPath);
            if (formField) {
              formField.displayError = msg;
            }
          }
        } else {
          errors = errors.concat(getErrorsForForm(error, setErrorOnField));
        }
      });
    });

    return errors;
  }
  
  function getFormGroups() {
    let formGroups = {};

    _.forEach(vm.fields, (field) => {
      if (field.displayOptions.hidden || (field.hide && field.hide(vm.item)) || field.displayOptions.inEditor) {
        return;
      }

      let formGroup = field.displayOptions.formGroup;

      if (!formGroups[formGroup]) {
        formGroups[formGroup] = {
          name: formGroup,
          completed: !vm.isNewItem,
        }
      }
    })
    return _.values(formGroups);
  }

  vm.$onInit = function() {
    if (!vm.form) vm.form = {};
    vm.form.activeGroup = 0;
    vm.formGroups = getFormGroups();
    vm.totalFormGroups = _.size(vm.formGroups);
    vm.errors = getErrors;
    vm.activeGroupErrors = getActiveFormGroupErrors;
    vm.initialized = true;
    vm.validateActiveGroup = validateActiveGroup;
  }
}
