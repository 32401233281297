angular
  .module('app')
  .component('profileSettings', {
    templateUrl: 'profile-settings.tpl.html',
    controller: ProfileSettingsCtrl,
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    },
    $routeConfig: [
      {
        path: '/profile',
        name: 'Profile',
        component: 'profile',
        useAsDefault: true
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: 'notifications'
      }
    ]
  });

function ProfileSettingsCtrl($q, profileService) {
    const vm = this;

    vm.profile = null;

    loadProfileInfo();

    ///////////////////////////////////

    function loadProfileInfo() {
        profileService
            .getProfileInfo()
            .then((profileInfo) => {
                vm.profile = profileInfo;
            });
    }
}