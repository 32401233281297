  angular
    .module('app')
    .component('emailRouter', {      
      controllerAs: 'vm',
      bindings: { $router: '<' },
      controller: emailRouter
    });
  
    function emailRouter(routingService) {
      this.$routerOnActivate = function(p, n) {
        routingService.getNextRoute(this.$router, 'email');
      }
    }