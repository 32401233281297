angular
  .module('app')
  .filter('campaignsFilter', CampaignsFilter);

function CampaignsFilter() {

  //multiplying filters for campaigns list

  return function(campaigns, filterParams) {

    let filteredCampaigns = [],
      {search, types, delisted, ended} = filterParams;

    campaigns.forEach(item => {

      let result = {};

      if (search) {
        let searchRequest = filterParams.search.toLowerCase(),
         campaignData = [item.name, item.description, item.url];

        let searchResults = campaignData.map((item) =>{
            if (item != null) {
                return item.toLowerCase().includes(searchRequest);
            }
        })

        result.search = searchResults [0] || searchResults [1] || searchResults [2];
                         
      } else {
        result.search = true;
      }

      if (types.size) {
        result.type = types.has(item.type);
      } else {
        result.type = false;//if no types -> hide all except ended
      }

      if (delisted.size) {
        result.delisted = delisted.has(item.delisted);
      } else {
        result.delisted = false;//if no delisted/published -> hide all except ended
      }

      result.ended = item.archived;

      if (result.type && result.delisted && result.search && !result.ended || result.type && result.search && ended && result.ended) {
        filteredCampaigns.push(item)
      }

    });

    return filteredCampaigns;
  }
}