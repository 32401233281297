angular
  .module('app')
  .component('customButton', {
    controller: customButtonController,
    templateUrl: 'custom-button.tpl.html',
    transclude: true,
    bindings: {
      button: '<',
      parentItem: '<',
    },
    controllerAs: 'vm'
  });


function customButtonController(messageService, $timeout, $q) {
  const vm = this;
  
  vm.buttonAction = function($event) {
      if (!vm.button.loading) {
          var action = vm.button.action(vm.parentItem, $event)

          if (vm.button.useLoader) {
              vm.button.loading = true;
              $q.when(action)
                  .then(() => {
                      if (!vm.button.skipComplete) {
                          vm.button.complete = true
                          $timeout(() => vm.button.complete = false, 2000);
                      }
                  })
                  .catch((error) => {
                      if (error) {
                          messageService.showErrorToast(error.msg, error.parent, error.hideDelay);
                      }
                  })
                  .finally(() => vm.button.loading = false);
          }
      }
  }
}
