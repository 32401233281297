angular
    .module('app')
    .component('modalMoveRecurring', {
        bindings: {
            show: '=',
            type: '<',
            onComplete: '&',
            unendedRecurringCount: '<',
            destinationOptions: '<',
            targetEvent: '<'
        },
        controller: ModalMoveRecurringCtrl,
        controllerAs: 'vm'
    });


function ModalMoveRecurringCtrl($scope, $mdDialog, $rootScope) {
    const vm = this;
    vm.selectedDestinationCont = {};
  
    $scope.$watch('vm.show', () => {
        if (vm.show) {
            showModal();
        }
    });

    function showModal() {
        $mdDialog
            .show({
                templateUrl: 'modal-move-recurring.tpl.html',
                controller: ModalMoveRecurringInnerCtrl,
                controllerAs: 'vm',
                openFrom: vm.targetEvent.currentTarget,
                closeTo: vm.targetEvent.currentTarget,
                locals: {
                    type: vm.type,
                    unendedRecurringCount: vm.unendedRecurringCount,
                    destinationOptions: vm.destinationOptions,
                    selectedDestinationCont: vm.selectedDestinationCont,
                    onComplete: vm.onComplete,
                    targetEvent: vm.targetEvent
                },
                clickOutsideToClose: false
            })
            .then(() => 
                vm.onComplete({ endClickEvent: vm.targetEvent, recurringPlanDestination: vm.selectedDestinationCont.selectedDestination }))
            .finally(() => {
                vm.show = false;
            });
    }
}

function ModalMoveRecurringInnerCtrl($q, $mdDialog, $rootScope, campaignService, 
                            type, unendedRecurringCount, destinationOptions, selectedDestinationCont) {

    const vm = this;
    vm.type = type;
    vm.unendedRecurringCount = unendedRecurringCount;
    vm.destinationOptions = destinationOptions;
    vm.selectedDestinationCont = selectedDestinationCont;
    vm.showChangeOptions = showChangeOptions;
    vm.closeChangeOptions = closeChangeOptions;
    vm.next = next;
    vm.querySearch = querySearch;
    vm.closeModal = closeModal;
    
    vm.changing = false;

    function showChangeOptions() {
        vm.changing = true;
    }

    function closeChangeOptions() {
        vm.selectedDestinationCont.selectedDestination = null;
        vm.changing = false;
    }

    function next() {
        $mdDialog.hide();
    }

    function querySearch(query) {
        let results = query ? vm.destinationOptions.filter(createFilterFor(query)) : vm.destinationOptions;
        return results;
    }

    function createFilterFor(query) {
        var lowercaseQuery = angular.lowercase(query);

        return function filterFn(item) {
            return (angular.lowercase(item.eventName).indexOf(lowercaseQuery) === 0 || item.eventID.toString().indexOf(lowercaseQuery) === 0 || (angular.lowercase(item.eventName) + ' (' + item.eventID + ')').indexOf(lowercaseQuery) === 0);
        };
    };

    function closeModal() {
        $mdDialog.cancel();
    }
}