angular
  .module('app')
  .component('clearviewDetails', {
    templateUrl: 'clearview-details.tpl.html',
    bindings: {
      username: '='
    },
    controller: ClearviewController,
    controllerAs: 'vm'
  });

function ClearviewController(clearviewService, messageService, campaignService) {
  const vm = this;

  vm.syncClearViewEvent = syncClearViewEvent;
  vm.replicableCampaigns = [{ name: 'Syncing Existing Neon Fundraise Campaign', entityId: -1 }];

  const replicableCampaigns = campaignService.getRawCampaigns()
    .filter((elem) => elem.isReplicable && new RegExp('CVT$').test(elem.name));

  vm.replicableCampaigns.push.apply(vm.replicableCampaigns, replicableCampaigns);

  function syncClearViewEvent(appealCode, cmpToReplicate) {
    if (vm.syncing)
      return;
    vm.syncing = true;
    clearviewService.syncClearView(appealCode, cmpToReplicate.entityId)
      .then(response => {
        if (response.success) {
          if (cmpToReplicate.entityId !== -1) {
            campaignService.pushToRawCampaigns(response.data);
          }
          messageService.showSuccessToast(response.msg);
        }
        vm.syncing = false;
      }).catch(function(){
        vm.syncing = false;
      });
  }
}
