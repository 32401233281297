angular
    .module('app').directive("emailPreview", function () {
    function link(scope, element) {
        var iframe = document.createElement('iframe');
        var element0 = element[0];
        element0.appendChild(iframe);
        
        var baseStyling = `
            <style>
            html {
                font-family: sans-serif;
                padding: 15px;
            }
            </style>`;

        scope.$watch('content', function () {
            var body = iframe.contentDocument.body;
            if (scope.content) {
                if(navigator.userAgent.includes('Firefox')){
                    iframe.onload = function(){
                        iframe.contentDocument.body.innerHTML = baseStyling + scope.content;
                    }
                }
                else{
                    body.innerHTML = baseStyling + scope.content;
                }
            }
        });
    }

    return {
        link: link,
        template: `<div class="preview-title" ng-if="title">{{title}}</div><div ng-transclude></div>`,
        restrict: 'E',
        transclude: true,
        scope: {
            content: '=',
            title: '@'
        }
    };
});