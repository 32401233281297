angular
    .module('app')
    .component('emailPreviewOptions', {
        controller: emailPreviewOptionsController,
        bindings: {
            email: '<',
            actions: '=',
            canShowPreview: '=?',
        },
        templateUrl: 'email-preview-options.tpl.html',
        controllerAs: 'vm',
    });

function emailPreviewOptionsController(emailService, placeholdersService, profileService, entityService, formService) {
    const vm = this;
    vm.updatePreviewOnChange = true;
    vm.loading = true;
    vm.campaignId = undefined;
    vm.actions = [];
    vm.placeholderSampleObjects = {};
    vm.placeholderConditions = {
        Subject: {},
        Body: {},
    };
    vm.previewOptions = [];

    vm.getEmail = function() {
        let email = _.cloneDeep(vm.email)

        vm.replaceConditionalPlaceholders(email);

        return email;
    }

    vm.hasPreviewOptions = () => vm.hasAnyConditionalPlaceholders() || vm.hasAnyPlaceholderSampleObjects() || vm.campaignsLength > 1;

    vm.hasAnyConditionalPlaceholders = () => _.every(vm.placeholderConditions, x => x.length === 0) === false

    vm.hasAnyPlaceholderSampleObjects = () => _.size(vm.placeholderSampleObjects) !== 0;

    vm.replaceConditionalPlaceholders = function(email) {
        _.forEach(vm.placeholderConditions, (placeholderCondition, fieldName) => {
            let field = formService.getFieldFromItem(email, fieldName);

            if (field) {
                field.value = placeholdersService.replaceConditionalPlaceholders(field.value, placeholderCondition);
            }
        });
    }

    vm.loadPreview = function() {
        vm.loading = true;

        return emailService.getEmailPreview(vm.getEmail(), vm.getPlaceholderSampleObjectsSelection(), vm.campaignId)
            .then(r => {
                vm.errors = undefined;
                vm.previewBody = r.body;
                vm.previewSubject = r.subject;

                if (vm.previewOptions && vm.previewOptions.$setPristine) {
                    vm.previewOptions.$setPristine();
                }
            })
            .catch(r => {
                if (r.data) {
                    vm.errors = r.data.errors;
                } else {
                    vm.errors = [{ error: 'Could not load preview, please try again.' }];
                }
            })
            .finally(() => {
                vm.loading = false
            })
    }

    vm.sendTest = function(adminEmail) {
        return emailService.sendTestEmail(vm.getEmail(), vm.getPlaceholderSampleObjectsSelection(), vm.campaignId, adminEmail);
    }

    vm.getPlaceholderSampleObjectsSelection = function() {
        return placeholdersService.getPlaceholderSampleObjectsSelection(vm.placeholderSampleObjects);
    }

    vm.previewOptionsChanged = function() {
        if (vm.updatePreviewOnChange) {
            vm.loadPreview();
        }
    }

    vm.resetCondition = function(condition) {
        condition.checked = undefined;
        vm.previewOptionsChanged();
    }

    vm.loadAllPlaceholdersSampleObjects = function() {
        vm.placeholderSampleObjects = {};

        let eventId = vm.eventId();
        let emailContent = formService.getFieldValueFromItem(vm.email, 'Body') + formService.getFieldValueFromItem(vm.email, 'Subject');

        let donationLabel = (donation, date) => `\$${numberWithCommas(donation.Amount)} by ${donation.DonorFirstName} ${donation.DonorLastName} on ${date.split(' ')[0]}`;
        let memberMap = (r) => _.map(r.items, member => ({
            id: member.MemberID,
            label: `${member.firstName} ${member.lastName}`,
        }));

        let sampleObjectConfig = [
            {
                placeholder: 'Donation',
                endpoint: 'Donation/AllTransaction',
                map: (r) => _.map(r.donations, donation => ({
                    id: donation.DonationID,
                    label: donationLabel(donation, donation.DonationDate),
                })),
            },
            {
                placeholder: 'Recurring_Donation',
                endpoint: 'Donation/AllRecurringTransaction',
                map: (r) => _.map(r.recurringDonations, recurringDonation => ({
                    id: recurringDonation.DonationRecurringID,
                    label: donationLabel(recurringDonation, recurringDonation.DonationRecurringDate),
                })),
            },
            {
                placeholder: 'Team',
                endpoint: 'Team/AllTeams',
                map: (r) => _.map(r.items, team => ({
                    id: team.TeamID,
                    label: team.TeamName,
                })),
                sort: 'Established',
                sortDir: 'DESC',
            },
            {
                placeholders: ['Registrant', 'Primary_Registrant', 'Fundraiser', 'User', 'Registered_By'],
                endpoint: 'Member/AllRegistrants',
                map: memberMap,
                sort: 'RegisteredOnAsString',
                sortDir: 'DESC',
            },
            {
                placeholder: 'Captain',
                endpoint: 'Member/AllMembersSimple',
                map: memberMap,
                filters: [{ value: true, field: "IsTeamCaptain" }],
            },
            {
                placeholder: 'Donor',
                endpoint: 'Member/AllDonors',
                map: memberMap,
            },
        ];

        _.forEach(sampleObjectConfig, (config) => {

            if (config.placeholder) {
                config.placeholders = [config.placeholder];
            }

            let req = () => emailService.getEmailSampleObjects(config.endpoint, eventId, config.filters, config.sort, config.sortDir);
            let sampleObjects = placeholdersService.loadPlaceholderSampleObjects(req, config.placeholders, config.map, emailContent);

            _.assign(vm.placeholderSampleObjects, sampleObjects);
        })
    }

    vm.campaignChanged = function() {
        vm.previewOptionsChanged();
        vm.loadAllPlaceholdersSampleObjects();
    }

    vm.eventId = function() {
        return vm.campaigns.find(v=>v.CampaignID = vm.campaignId).EventID;
    }

    vm.getConditionsFromPlaceholders = function() {
        vm.placeholderConditions = _.mapValues(vm.placeholderConditions, (_, fieldName) => {
            let source = formService.getFieldValueFromItem(vm.email, fieldName);
            return placeholdersService.getConditionsFromPlaceholders(source);
        });
    }

    vm.$onInit = function() {
        return entityService.getEntityCampaigns(entityService.getOpenedEntityId(), true).then(campaigns => {
            vm.campaigns = campaigns;
            vm.campaignsLength = vm.campaigns.length;
            vm.canShowPreview = vm.campaignsLength > 0;
    
            vm.email = formService.getBaseFormItem(vm.email); // Ensure email object is ready to send to server and has latest changes from source editor
    
            if (vm.canShowPreview) {
                vm.campaignId = vm.campaigns[0].CampaignID;
    
                vm.loadPreview();
                vm.getConditionsFromPlaceholders();
                vm.loadAllPlaceholdersSampleObjects();
    
                profileService.getProfileInfo()
                    .then((profileInfo) => {
                        let adminEmail = profileInfo.userEmail;
                        vm.actions = [
                            {
                                label: 'Send Test',
                                action: (item, $event) => vm.sendTest(adminEmail),
                                useLoader: true,
                                tooltip: `Send test email to ${adminEmail}. Dynamic content in the test email will be populated as selected and displayed here.`,
                            },
                        ];
                    });
            }
        })
    }
}