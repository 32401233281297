angular
.module('app')
.component('formSection', {
  controller: formSectionController,
  controllerAs: 'vm',
  templateUrl: 'form-section.tpl.html',
  bindings: {
      section: '<',
      onUpdate: '<',
      filter: '<',
      entityType: '<',
      noLock: '<'
  }
});

function formSectionController($scope, configSettingsService, FORM_ITEM_TYPES) {
  var vm = this;  
  vm.sectionUpdating = false;
  vm.formItemTypes = FORM_ITEM_TYPES;
  vm.sectionFilter = (value, index, array) => true;


  vm.$onInit = function() {
    if (vm.filter) {
      vm.sectionFilter = vm.filter;
    }

    var fields = vm.section.Fields;
    if (fields.length == 1 && fields[0].Type == vm.formItemTypes.ORDERABLE) {
      vm.orderableSection = fields[0];
      vm.orderableSection.refresh = true;
      vm.isOrderableSection = true;
    }
  }
  $scope.$on('adoptParent', function(event, configId) {
    vm.sectionUpdating = true;

    configSettingsService.adoptParent(configId).then(function(r) {
      if (r.success && r.setting) {
        refreshSetting(r.setting);
      }

      vm.sectionUpdating = false;
      return r.success;
    });
  });

  $scope.$on('overrideParent', function(event, configId) {
    vm.sectionUpdating = true;

    configSettingsService.overrideParent(configId).then(function(r) {
      if (r.success && r.setting) {
        refreshSetting(r.setting);
      }

      vm.sectionUpdating = false;
      return r.success;
    });
  });

  $scope.$on('clearOverrides', function(event, configId) {
    vm.sectionUpdating = true;

    configSettingsService.clearOverrides(configId).then(function(r) {
      if (r.success && r.setting) {
        refreshSetting(r.setting);
      }

      vm.sectionUpdating = false;
      return r.success;
    });
  });

  function refreshSetting(setting, advancedEditing) {
    setting.AdvancedEditing = advancedEditing;
    var index = vm.section.Fields.findIndex(
      x => x.SettingId == setting.SettingId
    );
    if (index > -1) {
      if (vm.isOrderableSection) {
        vm.orderableSection = setting;
        vm.orderableSection.refresh = true;
      } else {
        vm.section.Fields[index] = setting;
      }
    }
  }
  
  vm.onChange = function(id, value, advancedEditing, suppressRefreshSetting, processorInstanceId) {
    if (vm.sectionUpdating) {
      var promise = new $.Deferred();
      promise.reject();
      return promise;
    }
    vm.sectionUpdating = true;

    var data = {
      configId: id,
      value: value,
      processorInstanceId: processorInstanceId
    };
    
    return vm.onUpdate(data)
      .then(function(r) {
        vm.sectionUpdating = false;
        if (r.setting && !suppressRefreshSetting) {
          refreshSetting(r.setting, advancedEditing);
        }
        return true;
      })
      .catch(function(r) {
        vm.sectionUpdating = false;
        return false;
      });
  };
}