angular
    .module('app')
    .component('staticSalesforce', {
        templateUrl: 'static-salesforce.tpl.html',
        controller: StaticSalesforceCtrl,
        controllerAs: 'vm'
    });

function StaticSalesforceCtrl($location) {
    const vm = this;
    vm.errCode = $location.$$search.err;
    vm.authId = $location.$$search.auth_id;
    vm.orgId = $location.$$search.org_id;
    if(vm.errCode){
        vm.isSuccess = false;
        vm.errorDescription = $location.$$search.error_description;
    } else {
        vm.isSuccess = true;
    }

    const msg = { success: vm.isSuccess, error: vm.errCode + ': ' + vm.errorDescription, authId: vm.authId, orgId: vm.orgId };

    if (window.opener) {
        window.opener.postMessage(msg);
    } else if (window.BroadcastChannel) {
        const channel = new window.BroadcastChannel("OA_salesforce_connection");
        channel.postMessage(msg);
    }
}