angular
    .module('app')
    .filter('recurringPeriodFilter', RecurringPeriodFilter);

function RecurringPeriodFilter() {

    var recurringPeriodStrings = {
        0: '',
        1: 'daily',
        2: 'weekly',
        3: 'monthly',
        4: 'quarterly',
        5: 'annually',
    }

    return function (recurringPeriod) {
        return recurringPeriodStrings[recurringPeriod];
    }
}