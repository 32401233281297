angular
  .module('app')
  .factory('entityService', entityService);

function entityService($http, campaignService, orgService, API_PREFIX, $q, $location, $rootRouter, $timeout) {
  let allEntities = getEntitiesMap();
  let entityCampaignsCache = {};

  return  {
    updateAllEntities, getOrgEntityId, getOrgId, getInfo, getEntityName, getOpenedEntityId,
    getOpenedEntityIdMove, getOpenedGroupId, getOpenedCampaignId,
    moveGroups, moveCampaignsToGroup, getEntityCampaigns,
    createNewCampaignGroup, renameGroup, removeGroup,
    newCmpDonation, newCmpTicket, newCmpFromTemplate, getRawEntityById, getEntityFromCmpId,
    getEntitySettings, getEntityWithSettings, getEntityFromInstanceId, getCurrentEventId, getOpenEntity,
    getOpenEventId, refreshEntityAndComponent
  };
  
  function updateAllEntities() {
      allEntities = getEntitiesMap();
  }

  function getOrgEntityId() {
    return orgService.getOrg().entityId;
  }
  
  function getOrgId() {
    return orgService.getOrg().orgId;
  }
  
  function getOpenedCampaignId() {
    const campaignPath = orgService.getUrlCampaignPath();
    if (campaignPath) {
      const campaignHost = orgService.getUrlHost();
      const campaign = campaignService.getCampaignByUrl(campaignHost + '/' + campaignPath);
      return campaign && campaign.campaignId;
    }
    return orgService.getUrlCampaignId(); //should probably be getting a different way
  }

  function getOpenedEntityId() {
    let openedCampaignId = getOpenedCampaignId();
    if (openedCampaignId) {
        let openedCampaign = campaignService.getCampaignById(openedCampaignId);
        if (openedCampaign) {
            return campaignService.getCampaignById(openedCampaignId).entityId;
        } else {
            return -1;
        }
    }
    let openedGroup = campaignService.getOpenedGroup();
    let openedGroupEntityId = openedGroup ? openedGroup.entityId : null;
    return openedGroupEntityId || getOrgEntityId();
  }

  function getOpenedEntityIdMove() {
      let openedGroupEntityId = campaignService.getOpenedGroupMove().entityId;

      return openedGroupEntityId || getOrgEntityId();
  }

  function getOpenedGroupId() {
    return campaignService.getOpenedGroup().groupId || 0;
  }


  function getEntitiesMap() {
    let campaigns = campaignService.getRawCampaigns();
    let groups = campaignService.getRawGroups();

    return Object.assign({},
      _.keyBy(groups, 'entityId'),
      _.keyBy(campaigns, 'entityId'),
      _.keyBy([orgService.getOrg()], 'entityId')
    );
  }

  function getEntityIdFromUrl() {
    const campaignPath = orgService.getUrlCampaignPath();
    if (campaignPath) {
      const campaignHost = orgService.getUrlHost();
      const campaign = campaignService.getCampaignByUrl(campaignHost + '/' + campaignPath);
      return campaign && campaign.entityId;
    }
    
    var pathSplit = location.pathname.split('/');
    var campaignIndex = pathSplit.indexOf('campaign');
    if (campaignIndex > -1 && pathSplit.length > campaignIndex) {
        return campaignService.getCampaignById(pathSplit[campaignIndex + 1]).entityId;
    }
    var groupIndex = pathSplit.indexOf('group');
    if (groupIndex > -1 && pathSplit.length > groupIndex) {
        return campaignService.getGroupById(pathSplit[groupIndex + 1]).entityId;
    }
    return orgService.getOrg().entityId;
}

  function getOpenEntity() {
    var entityId = getEntityIdFromUrl();
    return getRawEntityById(entityId);

  }
  function getRawEntityById(entityId) {
    if (!allEntities[entityId]) {
      allEntities = getEntitiesMap();
    }
    return allEntities[entityId];
  }

  function getEntitySettings(entityId) {
    var id = entityId || getEntityIdFromUrl()
    var entity = getEntityWithSettings(id);
    if (entity.entityType === 'Org' || entity.settings.loaded) {
      return $q.resolve(entity);
    } else {
      return refreshEntitySettings(id);
    }
  }

  function getEntityWithSettings(entityId) {
    var entity = getRawEntityById(entityId);
    if (entity.entityType === 'Campaign') {
      return campaignService.getCampaignByEntityId(entityId)
    } else if (entity.entityType === 'CampaignGroup') {
      return campaignService.getGroupByEntityId(entityId);
    } else {
      return entity;
    }
  }

  function refreshEntitySettings(entityId) {
    return getInfo(entityId).then(r => {
      if (r.entityType === 'Campaign') {
        r.campaignUrl = orgService.getCampaignPath(r);
        if (r.masterEventId) {
          r.masterCampaignUrl = orgService.getCampaignPath(campaignService.getCampaignByEventId(r.masterEventId));
        }
        campaignService.replaceRawCampaign(r);
        return r;
      } else if (r.entityType === 'CampaignGroup') {
        campaignService.replaceRawGroup(r);
        return r;
      }
    });
  }

  function getEntityName(entityId) {
    return entityId == getOrgEntityId() ? orgService.getOrg().name : allEntities[entityId].name
  }

  function getInfo(entityId) {
    return $http({ url: API_PREFIX + '/GetInfoByEntity', headers: { entityId }, method: 'POST' })
      .then((response) => response.data.data);
  }
  
  function getEntityCampaigns(entityId, useCache) {
    if (useCache && entityCampaignsCache[entityId]) {
      return $q.resolve(entityCampaignsCache[entityId]);
    } else {
      return $http({ url: API_PREFIX + '/GetCampaignsOnEntity', headers: { entityId }, method: 'POST' })
          .then((response) => {
            entityCampaignsCache[entityId] = response.data.campaigns;
            return response.data.campaigns
          });
    }
  }

  function moveGroups(fromEntityId, entityIds, toEntityId, messageConfig) {
    return $http({ url: API_PREFIX + '/MoveGroup', headers: { fromEntityId: fromEntityId, entityIds: entityIds, toEntityId: toEntityId }, method: 'POST', messageConfig })
      .then((response) => response.data);
  }

  function moveCampaignsToGroup(fromEntityId, entityIds, toEntityId, messageConfig) {
    return $http({ url: API_PREFIX + '/MoveCampaignToGroup', headers: { fromEntityId: fromEntityId, entityIds: entityIds, toEntityId: toEntityId }, method: 'POST', messageConfig})
      .then((response) => response.data);
  }

  function createNewCampaignGroup(entityIdParentGroup, name, messageConfig) {
    return $http({ url: API_PREFIX + '/NewCampaignGroup', data: { name: name }, headers: { parentEntityId: entityIdParentGroup }, method: 'POST', messageConfig })
    .then((response) => {
          return response;
      })
  }
  
  function renameGroup(groupId, name) {
    return $http({ url: API_PREFIX + '/RenameGroup', data: { name: name }, headers: { groupId: groupId }, method: 'POST'})
      .then((response) => response.data.success);
  }

  function removeGroup(entityId) {
    return $http({ url: `${API_PREFIX}/RemoveGroup`, headers: { entityId: entityId }, method: 'POST' })
      .then(response => {

        _removeGroupCache(entityId);

        return response.data.success
      })
  }

  function _removeGroupCache(entityId) {

    let groups = campaignService.getRawGroups(),
        index = groups.findIndex(item => item.entityId == entityId);

    groups.splice(index,1);
    campaignService.replaceRawGroups(groups)
  }

  function newCmpDonation(parentEntityId, campaignData, messageConfig) {
    return $http({ url: API_PREFIX + '/NewCmpDonation', data: campaignData, headers: { parentEntityId }, method: 'POST', messageConfig })
      .then((response) => response.data.cmp);
  }

  function newCmpTicket(parentEntityId, campaignData, messageConfig) {
    return $http({ url: API_PREFIX + '/NewCmpTicket', data: campaignData, headers: { parentEntityId }, method: 'POST', messageConfig })
      .then((response) => response.data.cmp);
  }

  function newCmpFromTemplate(parentEntityId, campaignData, messageConfig) {
    return $http({ url: API_PREFIX + '/NewCmpFromTemplate', data: campaignData, headers: { parentEntityId }, method: 'POST', messageConfig })
      .then((response) => response.data.cmp);
  }

  function getEntityFromCmpId(cmpId) {
      var cmp = campaignService.getCampaignById(cmpId);
      var entity = getRawEntityById(cmp.entityId);
      return entity;
  }
  
  function getEntityFromInstanceId(entity, cmpInstanceId) {
	return cmpInstanceId && entity.oldInstancesWithYrAndEvntId.find(c => c.cmpInstanceId == cmpInstanceId);
  }
  
  function getOpenEventId() {
    let entity = getOpenEntity();
    return entity ? getCurrentEventId(entity) : undefined;
  }

  function getCurrentEventId(entity) {
    if (entity.entityType == 'Campaign') {
      var instanceId = orgService.getUrlCampaignInstanceId();
      if (instanceId) {
		return getEntityFromInstanceId(entity, instanceId).eventId;
      }
      return entity.curEventId;
    }
    return undefined;
  }

  function refreshEntityAndComponent(entityId) {
    let url = $location.path();
    refreshEntitySettings(entityId)
        .then(() => {
            updateAllEntities();

            $rootRouter.navigateByUrl('/p/loading', { skipLocationChange: true }).then(() => {
                $timeout(() => {
                    $rootRouter.navigateByUrl(url);
                });
            });
        })
  }
}