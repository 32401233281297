angular
    .module('app')
    .service('helpersService', helpersService);

function helpersService() {
    const vm = this;
    
    vm.api = {
        getUserDisplayName: (value, includedParts = []) => {
            var wholeName = `${value['FirstName'] || ''} ${value['LastName'] || ''}`.trim();
            
            if(!wholeName.length){
                wholeName += value['CompanyName'];
            }
            
            if (includedParts.length) {
                wholeName += "<br>";
                
                if (includedParts.includes('MemberId')) {
                    wholeName += `<small>#${value['MemberId']}</small> `;
                }
                if (includedParts.includes('UserAccountId')) {
                    wholeName += `<small>#${value['UserAccountId']}</small> `;
                }
                if (includedParts.includes('Email') && value['Email']) {
                    wholeName += `<small>${value['Email']}</small> `;
                }
            }

            return wholeName;
        },
        normalizeUserPropertyNames: (value) => {
            value.FirstName = value.firstName;
            value.LastName = value.lastName;
            value.CompanyName = value.companyName;
            value.UserAccountId = value.UserAccountID;
            value.MemberId = value.MemberID;
            return value;
        }
    }
    
    return vm.api;
    
}