angular.module('app').component('donationSettings', {
  controllerAs: 'vm',
  controller: donationSettingsController,
  require: {
    parentComponent: '^formsTab'
  },
  bindings: { 
    $router: '<'
  },
  template: `<form-template
              form-sections='vm.sections'
              on-update='vm.onChange'
              loading='vm.loading'></form-template>
            <div class='custom-setting-item'>
              <pay-pal-setup ng-if="vm.hasPayPalPermission"></pay-pal-setup>
            </div>`,
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateNoPendingChanges($nextInstruction);
  }
});

function donationSettingsController(configSettingsService, extJsLoader, securityService, entityService) {
  const vm = this;
  this.$routerOnActivate = function (next, prev) {
    this.parentComponent.showIframe = false;
    this.parentComponent.loading = false;
    var permission = securityService.getAdminPermissions(entityService.getRawEntityById(this.parentComponent.openEntity), 'PAY_PAL');
    vm.hasPayPalPermission = permission.VIEW;
  }  
  vm.loading = true;
  vm.sections = [];
  configSettingsService.getDonationSettings().then(function(r) {
    vm.loading = false;
    if (r.success && r.form) {
      vm.sections = r.form.FormSections;
    } else {
      
    }
  });

  vm.onChange = function(config) {
    return configSettingsService.updateSetting(config);
  };
}
