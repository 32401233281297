angular
    .module('app')
    .component('editableListItem', {
        require: {
            editableListCtrl: '^editableList'
        },
        controller: EditableListItem,
        templateUrl: 'editable-list-item.tpl.html',
        bindings: {
            ngModel: '=',
            name: '@',
            label: '@',
            placeholder: '@',
            customValidationKey: '@',
            required: '<',
        },
        controllerAs: 'vm'
    });

function EditableListItem($scope) {
    const vm = this;
    vm.fieldValueBeforeEdit = undefined;
    
    vm.$onInit = function() {
        vm.isCurrentlyBeingEdited = () => vm.editableListCtrl.fieldBeingEdited === vm.name;
        vm.serverError = () => vm.editableListCtrl.serverErrors[vm.name.toLowerCase()];
        vm.localField = () => vm.editableListCtrl.form[vm.name];
    }
}