angular
    .module('app')
    .component('composeEmailModal', {
        bindings: {
            showModal: '=',
            targetEvent: '<',
        },
        controller: composeEmailModalCtrl,
        controllerAs: 'vm'
    });

function composeEmailModalCtrl($scope, $mdDialog) {
    const vm = this;

    $scope.$watch('vm.showModal', () => {
        if (vm.showModal) {
            showModal();
        }
    });

    function showModal() {
        $mdDialog
            .show({
                templateUrl: 'compose-email-modal.tpl.html',
                escapeToClose: false,
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                multiple: true,
                targetEvent: vm.targetEvent,
                locals: {
                    parent: $scope,
                }
            }).catch(() => {})
            .finally(() => {
                vm.showModal = false;
            });
    }
}

function ModalContentCtrl($rootScope, $scope, $element, $mdDialog, emailService, messageService, parent, formService, entityService, securityService) {

    const vm = this;
    vm.email = undefined;
    vm.form = { };
    vm.security = { };
    vm.entity = entityService.getOpenEntity();
    vm.security.emailTemplates = securityService.getAdminPermissions(vm.entity, 'EMAIL_TEMPLATES');

    vm.groups = {
        RECIPIENTS : 0,
        CONTENT : 1,
        PREVIEW : 2,
    }
    
    emailService.getComposeEmailForm().then(r => {
        vm.email = r.item;
        vm.email.InPopup = true;
        emailService.openEmail(vm.email);
        emailService.addComposeEmailLogic(vm.email)

        let contactsField = formService.getFieldFromItem(vm.email, 'Individual Recipients');
        contactsField.value = {
            members: [],
            contacts: [],
        }
        if (window.sessionStorage['selectedContacts']) {
             //TODO: set contact session and member session separately. 
             //Pre this build, contacts would not be selected in overlapping id case, currently both will be selected
            contactsField.value.members = window.sessionStorage['selectedContacts'];
            contactsField.value.contacts = window.sessionStorage['selectedContacts'];
            formService.setFieldValueOnItem(vm.email, 'Recipients', 'Individual');
            window.sessionStorage.removeItem('selectedContacts');
        }

        contactsField.loaded = true;
    })

    vm.editorButtons = [
        emailService.getEmailEditorOptionsButton(),
        {
            label: 'Preview',
            icon: 'preview',
            toolbar: 'floating',
            action: (item, evnt) => nextGroup(),
        },
    ];
    vm.cancel = closeModal;
    vm.saveAsTemplate = saveAsTemplate;
    vm.sendEmailNow = sendEmailNow;
    vm.scheduleEmail = scheduleEmail;
    vm.nextGroup = nextGroup;
    
    function saveAsTemplate() {
        emailService.showSaveAsEmailTemplateDialog(vm.email);
    }

    function sendEmailNow() {
        return sendEmail(undefined).then(closeModal, angular.noop); // Pass no date to send now
    }

    function sendEmail(date, scheduled) {
        formService.setFieldValueOnItem(vm.email, 'Scheduled For', date);
        return emailService.createBlast(vm.email, scheduled).then(notifyScopeOfNewEmail);
    }
    
    function notifyScopeOfNewEmail() {
        $rootScope.$broadcast('NewEmailBlastComposed');
    }

    function scheduleEmail() {
        var prompt = $mdDialog
            .prompt({
                bindToController: true,
                locals: {
                    inputType: 'datetime-local',
                    required: true,
                },
            })
            .multiple(true)
            .title('Schedule Email')
            .htmlContent(`Enter a date and time to send out the email:`)
            .targetEvent(event)
            .ok('Schedule')
            .cancel('Cancel');

        $mdDialog
            .show(prompt)
            .then(newDate =>
                sendEmail(newDate, true)
                    .then(closeModal)
                    .catch(angular.noop)
            )
            .catch(angular.noop);
    }

    function closeModal() {
        $mdDialog.hide();
    }
    
    function nextGroup() {
        $scope.$broadcast('nextGroup');
    }

    $scope.$watch('vm.form.$valid', function (valid) {
        if (!valid) {
            $scope.$broadcast('markGroupIncomplete', vm.form.activeGroup)
        }
    });

    $scope.$on('closeModal', function(event) {
        closeModal();
    })

}