angular
  .module('app')
  .component('fieldName', {
      bindings: {
          type: '@',
          title: '@',
      },
      transclude: true,
      controller: FieldNameController,
      templateUrl: 'field-name.tpl.html',
      controllerAs: 'vm'
  });

function FieldNameController() {
    const vm = this;
}