angular
    .module('app')
    .component('donations', {
        templateUrl: 'donations.tpl.html',
        controller: donationsController,
        bindings: {
            $router: '<'
        },
        require: {
            parentComponent: '^transactions'
        },
        controllerAs: 'vm',
        $canActivate: function ($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });

function donationsController(extJsLoader, $mdDialog, $location, iframeMessageHandler) {
    const vm = this;
    var verfiDonationAlert;

    if ($location.search()['msg'] === 'VerifyDonation') {
        var actionSuccess = $location.search()['success'] && $location.search()['success'].toLowerCase() === 'true';
        var alreadyVerified = $location.search()['alreadyVerified'] && $location.search()['alreadyVerified'].toLowerCase() === 'true';

        var finalMsg = '';
        if (actionSuccess) {
            finalMsg = "Great! The donation was successfully verified.";
        } else if (alreadyVerified) {
            finalMsg = "This donation was already verified.";
        } else{
            finalMsg = "Error: We could not find this donation.";
        }

        verfiDonationAlert = $mdDialog.alert({
            title: 'Verify Donation',
            htmlContent: finalMsg,
            ok: 'Close',
        });
        $mdDialog
            .show(verfiDonationAlert)
            .finally(function () {
                verfiDonationAlert = undefined;
            });
    }

    if ($location.search()['msg'] === 'CompleteDafDistribution') {
        var actionSuccess = $location.search()['success'] && $location.search()['success'].toLowerCase() === 'true';
        var alreadyCompleted = $location.search()['alreadyCompleted'] && $location.search()['alreadyCompleted'].toLowerCase() === 'true';

        var finalMsg = '';
        if (actionSuccess) {
            if(alreadyCompleted) {
                finalMsg = "The donation was already completed.";
            } else {
                finalMsg = "Great! The donation was successfully completed.";
            }
        } else{
            finalMsg = "Error completing DAF distribution.";
        }

        verfiDonationAlert = $mdDialog.alert({
            title: 'Complete DAF Donation',
            htmlContent: finalMsg,
            ok: 'Close',
        });
        $mdDialog
            .show(verfiDonationAlert)
            .finally(function () {
                verfiDonationAlert = undefined;
            });
    }

    this.$routerOnActivate = function(next, prev) {
        extJsLoader.extJsController.updateInnerPage('Transactions-Donations');
        this.parentComponent.showIframe = true;
    }
}