angular
  .module('app')
  .component('neonPay', {
    templateUrl: 'neon-pay.tpl.html',
    controller: neonPayController,
    controllerAs: 'vm',
    require: {
        parentComponent: '^settings'
    }
});

function neonPayController(routingService, profileService, settingsService, orgService) {
    const vm = this;
    this.$routerOnActivate = function (next, prev) {
        this.parentComponent.showIframe = false;
    }
    vm.createNeonLink = createNeonLink;
    vm.sandbox = location.search.indexOf('sandbox') > -1;

    vm.neonPayLogin = vm.sandbox ? 'https://sandbox.neononepay.com/login' : 'https://neononepay.com/login';
    
    getNeonInfo();

    routingService.updateRouting()
    .then(r => {
        vm.openEntity = r;        
    });

    function getNeonInfo() {
        return settingsService.getPageInfo('neon').then(response => {
            if (response.success) {
                vm.link = response.data.ReferralLink;
                vm.inherited = response.data.Inherited;
                vm.entityName = response.data.EntityName;
                vm.neonStatus = setNeonStatus(response.data.ReferralState, response.data.OnOtherEntity)
            }
        });
    }

    function setNeonStatus(referralState, onOtherEntity) {
        if (onOtherEntity) {
            return 'otherEntity';
        }
        
        switch (referralState) {
            case 1:
                return 'processing';
            case 2:
                return 'active';        
            default:
                return 'new';
        }
    }

    function createNeonLink() {
        if (vm.link) {
            window.open(vm.link);
        } else {
            return settingsService.createNeonLink(vm.sandbox).then(response => {
                if (response.success) {
                    vm.link = response.data;
                    window.open(response.data);
                }
            });
        }
    }
}