angular
  .module('app')
  .component('advancedAdmin', {
    controller: advancedAdminController,
    bindings: { 
      $router: '<'
    },
    require: {
        parentComponent: '^settings'
    },
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function advancedAdminController(entityService) {
  const vm = this;
  this.$routerOnActivate = function(next, prev) {
    this.parentComponent.showIframe = false;
    let entity = entityService.getRawEntityById(this.parentComponent.openEntity);
    if (entity && entity.curEventId) {
        this.parentComponent.iframeData.src = `/site/${entity.curEventId}/AdvancedAdmin`;
        this.parentComponent.showIframe = true;
        this.parentComponent.loading = false;
    }
  };
}