angular
    .module('app')
    .service('formService', formService);

function formService() {
    const vm = this;
    
    return {
        getBaseFormItem,
        getItemById,
        getItemByRecordId,
        getFieldFromItem,
        getFieldValueFromItem,
        getSubFieldFromItem,
        getSubFieldValueFromItem,
        getFieldOrSubFieldFromItem,
        setFieldValueOnItem,
    }
    
    function getBaseFormItem(item) {
        let baseItem = _.cloneDeep(item)

        if (baseItem.Fields) {
            _.forEach(baseItem.Fields, field => {
                if (field.fieldType === 'Textarea' && CKEDITOR.instances[field.name+'-'+item.Id] && CKEDITOR.instances[field.name+'-'+item.Id].mode === 'source') {
                    field.value = CKEDITOR.instances[field.name+'-'+item.Id].getData();
                }

                if (field.fieldConditionShouldHide && field.fieldConditionShouldHide(baseItem)) {
                    field.value = null;
                }
                
                // Remove view data - no need to send back to server
                delete field.options;
                delete field.groupedOptions;
                delete field.optionsMetadata;
                delete field.displayOptions;
                delete field.grid;
            })
        }

        delete baseItem.data;
        return baseItem;
    }
    
    function getItemById(items, id) {
        return items.find(x => getFieldValueFromItem(x, 'Id') == id);
    }

    function getItemByRecordId(items, id) {
        return items.find(x => x.Id === id);
    }

    function getFieldFromFields(fields, field) {
        return fields.find(x => x.name === field);
    }
    
    function getFieldFromItem(item, field) {
        if (Array.isArray(field)) {
            if (field.length === 1) {
                field = field[0];
            } else {
                return getSubFieldFromItem(item, field[0], field[1])
            }
        }
        return getFieldFromFields(item.Fields, field);
    }
    
    function getFieldValueFromItem(item, field) {
        if (Array.isArray(field)) {
            return getSubFieldValueFromItem(item, field[0], field[1])
        }
        let itemField = getFieldFromItem(item, field);
        return itemField ? itemField.value : undefined;
    }

    function getSubFieldFromItem(item, field, subField) {
        let itemField = getFieldFromItem(item, field);
        return itemField ? getFieldFromFields(itemField.value, subField) : undefined;
    }

    function getSubFieldValueFromItem(item, field, subField) {
        let itemSubField = getSubFieldFromItem(item, field, subField);
        return itemSubField ? itemSubField.value : undefined;
    }

    function getFieldOrSubFieldFromItem(item, field, subField) {
        if (subField) {
            return getSubFieldFromItem(item, field, subField)
        }
        return getFieldFromItem(item, field);
    }

    function setFieldValueOnItem(item, field, value, addIfNotFound, fieldType, displayOptions) {
        let itemField = getFieldFromItem(item, field);
        
        if (itemField) {
            itemField.value = value;
        } else if (addIfNotFound) {
            itemField = {
                name: field,
                value,
                displayOptions: displayOptions || {},
                fieldType: fieldType
            }
            item.Fields.push(itemField);
        }
    }
}