angular
  .module('app')
  .component('orgProcessors', {
    templateUrl: 'org-processors.tpl.html',
    bindings: {
      $router: '<'
    },
    controller: OrgProcessorController,
    controllerAs: 'vm'
  });

function OrgProcessorController(orgProcessorsService) {
  const vm = this;
  vm.dataLoaded = false;
  loadProcessors();

  function loadProcessors (){
    orgProcessorsService.getProcessors()
      .then(processors => {
        vm.dataLoaded = true;
        vm.processors = processors;
      })
      .catch(() => {
        vm.error = "Unable to load processors. Please contact support"
      });
  };
}
