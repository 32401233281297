angular.module('app').component('fundraisingSettings', {
  controllerAs: 'vm',
  controller: fundraisingSettingsController,
  template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'
               loading='vm.loading'></form-template>`,
  require: {
    parentComponent: '^fundraising'
  },
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateNoPendingChanges($nextInstruction);
  }
});

function fundraisingSettingsController($rootScope, configSettingsService) {
  const vm = this;
  vm.loading = true; 

  this.$routerOnActivate = function(next, prev) {
    this.parentComponent.loading = false;
    this.parentComponent.showIframe = false;
  };

  vm.sections = [];
  configSettingsService.getFundraisingOptions().then(function(r) {
    vm.loading = false;
    if (r.success && r.form) {
      vm.sections = r.form.FormSections;
    } else {
    }
  });

  vm.onChange = function(config) {
    return configSettingsService.updateSetting(config);
  };
}