angular
  .module('app')
  .component('bulkReplicate', {
    bindings: {
      show: '=',
      entityId: '=',
      eventId: '='
    },
    controller: bulkReplicateCtrl,
    controllerAs: 'vm'
  });

function bulkReplicateCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'bulk-replicate.tpl.html',
        clickOutsideToClose: true,
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          entityId: vm.entityId,
          eventId: vm.eventId
        }
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($mdDialog, $rootScope, $window,
                          campaignService, messageService, entityService,
                          entityId, eventId) {
  const vm = this;

  vm.uploadView = true;
  vm.approveView = false;
  vm.errorsView = false;

  vm.rowErrors = false;
  vm.headerErrors = false;
  vm.finalErrors = false;

  vm.bulkReplicateCsvHref = campaignService.getBulkReplicateCsvHref(entityId);

  vm.cloneCmpSettings = {
      cmpSettings: {
          value: 1,
          checked: false
      },
      paymentProcessor: {
          value: 2,
          checked: false
      },
      templates: {
          value: 4,
          checked: false
      },
      defaults: {
          value: 8,
          checked: false
      },
      siteContent: {
          value: 16,
          checked: false
      },
      adminUsers: {
          value: 32,
          checked: false
      }
  };

  vm.closeModal = closeModal;
  vm.buttonHandler = buttonHandler;

  vm.fileUploadInput = null;

  let guid = createGuid();


  function buttonHandler() {

    if (vm.errorsView) {
      vm.rowErrors = false;
      vm.headerErrors = false;
      vm.finalErrors = false;

      vm.uploadView = true;
      vm.errorsView = false;

    } else if (vm.approveView) {
        _approveBulkReplication();
    } else if (vm.uploadView) {
        _validateBulkFile()
    }
  }

  function _approveBulkReplication() {

    vm.approveView = false;

    let messageConfig = {
        successToastEnabled: true,
        successMsg: 'Bulk replication completed successfully!',
        advancedErrorEnabled: false
    };
    campaignService.bulkReplicateCampaigns(entityId, guid, eventId, messageConfig)
     .then(response => {
         closeModal();

         //TODO: Fix this to go to the parent group upon completion
         //$rootScope.$emit('tree.clear');

         campaignService.pushMultipleToRawCampaigns(response.cmps);

         let parentGroupId = entityService.getOpenedGroupId();
         campaignService.openGroup(parentGroupId)
             .then((campaignsData) => {

                 //$rootScope.$emit('tree.changed', campaignsData);
             })
     }).catch(response => {
        if(response.data) {
            campaignService.pushMultipleToRawCampaigns(response.data.cmps);

            let errors = response.data.errors;
            if (errors) {
                vm.errors = errors;
                vm.errorsView = true;
                vm.finalErrors = true;
                vm.response = response.data;
                return;
            }
        }

        vm.approveView = true;
    })
  }



  function _validateBulkFile() {

      let fileInput = angular.element(document.querySelector('#bulkFile'));

      if (fileInput[0].files.length) {
        var file = fileInput[0].files[0];
      } else {
          messageService.showErrorToast('Please choose file');
        return
      }

      let copyCmpSettings = Object
          .values(vm.cloneCmpSettings)
          .filter(cmpSetting => cmpSetting.checked)
          .reduce((cmpSettingsTotal, cmpSetting) => cmpSettingsTotal | cmpSetting.value, 0);

      vm.uploadView = false;

      let messageConfig = { advancedErrorEnabled: false };
      campaignService.bulkReplicateCampaignsValidateCsv(entityId, guid, eventId, file, copyCmpSettings, !!vm.autoPublish, messageConfig)
        .then(response => {
            vm.campaignsCsv = response.campaignRequestDatas;
            vm.approveView = true;
        })
        .catch(response => {
            if(response.data) {
                let earlyErrors = response.data.earlyErrors;
                if (earlyErrors) {
                    vm.errors = earlyErrors;
                    vm.errorsView = true;
                    vm.headerErrors = true;
                    return;
                }

                let errors = response.data.errors;
                if (errors) {
                    vm.errors = errors;
                    vm.errorsView = true;
                    vm.rowErrors = true;
                    return;
                }
            }

          vm.uploadView = true;
        });

  }

  function closeModal() {
    $mdDialog.hide();
  }

  function createGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      let r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }

  vm.currentSlide = 1;

  vm.slickConfig = {
    enabled: true,
    infinite: true,
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    event: {
      afterChange: (event, slick, currentSlide, nextSlide) => {
        vm.currentSlide = currentSlide + 1;
      }
    },
    method: {}
  }



}
