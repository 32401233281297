angular
  .module('app')
  .component('fundraiserPdfTemplate', {
    templateUrl: 'fundraiser-pdf-template.tpl.html',
    controller: fundraiserPdfTemplateController,
    controllerAs: 'vm'
  });

function fundraiserPdfTemplateController(
  $scope, fundraiserPdfService, entityService, configSettingsService, messageService
) {
  const vm = this;
  vm.loading = true;
  vm.editing = false;
  vm.saving = false;
  
  vm.edit = () => {
    vm.editing = true;
  }
  vm.save = () => {
    vm.editing = false;
    
    if (vm.initialTemplate === vm.template) {
      return;
    }
    
    vm.saving = true;
    fundraiserPdfService.updateTemplate(vm.template)
      .then(response => {
        if (response.success) {
          setResponseData(response.data)
        } else {
          messageService.showErrorToast(response.msg || 'Unknown Error. Unable to update Fundraiser PDF.');
        }
      })
      .finally(() => {
        vm.saving = false;
      });
  };


  vm.editable = () => {
    return !vm.disabled && (!(vm.inherited || vm.isDefault) || vm.entityType === 'Org')
  };

  getInfo();

  $scope.$on('adoptParent', function(event, configId) {
    vm.loading = true;
    configSettingsService.adoptParent(configId).then(getInfo);
  });

  $scope.$on('overrideParent', function(event, configId) {
    vm.loading = true;
    configSettingsService.overrideParent(configId).then(getInfo);
  });

  $scope.$on('clearOverrides', function(event, configId) {
    vm.loading = true;
    configSettingsService.clearOverrides(configId).then(getInfo);
  });
  
  function getInfo() {
    fundraiserPdfService.getTemplate().then(response => {
      if (response.success) {
        vm.editing = false;
        setResponseData(response.data);
      } else {
        messageService.showErrorToast(response.msg || 'Unknown Error Unable to retrieve PDF template');
      }
    });
  }

  function setResponseData(data) {
    vm.inherited = data.Inherited;
    vm.isDefault = data.IsDefault;
    vm.parentName = data.OverriddenParentEntityName;
    vm.configId = data.ConfigOptionID;
    vm.editing = false;
    vm.disabled = data.Disabled;

    CKEDITOR.config.macros = data.macros;
    vm.template = data.template;
    vm.initialTemplate = data.template;

    let entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
    vm.entityType = entity.entityType;

    vm.loading = false;
  }
}
