angular
  .module('app')
  .filter('groupsFilter', GroupsFilter);

function GroupsFilter() {

  //multiplying filters for groups list

  return function(groups, filterParams) {

    let filteredGroups = [],
      {search, types, delisted, ended} = filterParams;

    groups.forEach(item => {

      let result = {},
      filteredCampaigns = [];

      if (search) {
        result.search = item.name.toLowerCase().includes(filterParams.search.toLowerCase());
      } else {
        result.search = true;
      }

      if(result.search){
          filteredGroups.push(item);
      }
    });

    return filteredGroups;
  }
}
