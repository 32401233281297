angular
  .module('app')
  .directive('affixParent', AffixParent);

function AffixParent() {
  return {
    restrict: 'AE',
    transclude: true,
    scope: true,
    template: `<ng-transclude></ng-transclude>
                <div class="affixSpace" ng-style="affixSpaceStyle"></div>`,
    link($scope, $element, $attrs) {

        let unwatch = $scope.$watch(() => $element.find('[affix], affix').length, (newValue, oldValue) => {
            if(newValue) {
                run();
            }
        });

        function run() {
            unwatch();

            let $affixElm = $element.find('[affix], affix');
            $scope.affixSpaceStyle = { height: $affixElm.outerHeight(true) + 'px', width: $affixElm.outerWidth(true) + 'px' };

            $scope.$watch(() => {
                return {
                    h: $affixElm.outerHeight(true),
                    w: $affixElm.outerWidth(true)
                }
            }, (newValue, oldValue) => {
                $scope.affixSpaceStyle = { height: newValue.h + 'px', width: newValue.w + 'px' };
            }, true)
        }
    }
  }
}