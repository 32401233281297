angular.module('app').component('modalEmailDomain', {
  bindings: {
    show: '=',
    reload: '<',
    domain: '<',
  },
  controller: ModalEmailDomainController,
  controllerAs: 'vm'
});

function ModalEmailDomainController($scope, $mdDialog) {
    const vm = this;
    
    $scope.$watch('vm.show', () => {
        if (vm.show) {
            showModal();
        }
    });
    
    function showModal() {
        $mdDialog
            .show({
                templateUrl: 'modal-email-domain.tpl.html',
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    domain: vm.domain,
                    reloadParentData: vm.reload,
                }
            })
            .then(angular.noop)
            .catch(angular.noop)
            .finally(() => {
                vm.show = false;
            });
    }
}

function ModalContentCtrl($scope, $mdDialog, $q, emailDomainsService, domain, messageService, reloadParentData) {
    const vm = this;
    vm.domain = domain;
    vm.dataLoaded = false;
    vm.failedValidation = false;
    vm.inProgress = {};
    vm.details = undefined;
    vm.statuses = emailDomainsService.DOMAIN_STATUS_KEYS;
    function closeModal() {
        $mdDialog.hide();
    }
    function loadDetails() {
        vm.dataLoaded = false;
        vm.details = undefined;
        
        emailDomainsService
            .getEmailDomainDetails(domain)
            .then(details => {
                vm.dataLoaded = true;
                vm.details = details;
            });
    } 
    
    loadDetails();

    vm.closeModal = closeModal;

    vm.addDomain = function() {
        if (vm.inProgress.addDomain) {
            return;
        }
        
        vm.inProgress.addDomain = true
        
        return emailDomainsService
            .addDomain(vm.domain)
            .then(response => {
                vm.details = response.emailDomainDetails;
                reloadParentData();
            })
            .catch(() => $q.reject())
            .finally(() => {
                vm.inProgress.addDomain = false
            });
    }
    
    vm.verifyDomain = function() {
        if (vm.inProgress.verifyDomain) {
            return;
        }
        
        vm.inProgress.verifyDomain = true

        return emailDomainsService
            .verifyDomain(vm.domain)
            .then(response => {
                vm.failedValidation = !response.success;                
                reloadParentData();
                
                if (response.emailDomainDetails) {
                    vm.details = response.emailDomainDetails;
                }
                
                if (!response.success) {
                    return $q.reject()
                }
            })
            .finally(() => {
                vm.inProgress.verifyDomain = false
            });
    }
    vm.removeDomain = function() {
        if (vm.inProgress.removeDomain) {
            return;
        }
        
        vm.inProgress.removeDomain = true
        
        let confirm = $mdDialog.confirm()
            .clickOutsideToClose(true)
            .title('Are you sure?')
            .htmlContent('This domain will no longer be authenticated to send email from.')
            .multiple(true)
            .ok('Remove')
            .cancel('Cancel');

        return $mdDialog.show(confirm)
            .then(() => emailDomainsService.removeDomain(vm.domain))
            .then(() =>  {
                reloadParentData()
                closeModal()
            })
            .finally(() => {
                vm.inProgress.removeDomain = false
            });
    }

    vm.copyValue = function($event) {
        var toCopy = angular.element($event.currentTarget).parent().find('input').first();
        toCopy.select();
        document.execCommand('copy');
    }
}