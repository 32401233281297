angular
  .module('app')
  .factory('campaignService', campaignService);

function campaignService($http, $q, $location, $timeout, API_PREFIX, orgService, securityService, rbTransformRequests) {
  let groupsMap = null;
  let rawCampaigns = null;
  let rawGroups = null;
  let openedGroup = null;
  let openedGroupMove = null;
  let campaignDomains = null;
  let orgId = orgService.getUrlOrgId();
  let redirectedFrom = null;

  return {
    openGroup, getOpenedGroup, getOpenedGroupMove, getGroupsPath,
    getRawCampaigns, updateRawCampaigns, pushToRawCampaigns, moveCampaignCache,
    getRawGroups, getGroupById, getGroupByEntityId, pushToRawGroups,
    pushMultipleToRawCampaigns, replaceRawGroups, moveGroupCache, updateGroupName,
    getCampaignDomains, getCampaignByEntityId, getCampaignById, getCampaignByUrl,
    delistCampaign, archiveCampaign, validateArchiveOrRelaunchCmp, validateArchiveOrRelaunchCmps,
    unarchiveCmp, relaunchCampaign, replicateCampaign, bulkReplicateCampaignsValidateCsv, bulkReplicateCampaigns,
    delistCampaigns, archiveCampaigns, addCampaignUrl, updateCampaignUrl, removeCampaignUrl,
    saveEditCampaign, saveEditCampaignCustomField, uploadCmpImage, getBulkReplicateCsvHref, checkUpdateRouting,
    getCampaignByEventId, replaceRawCampaign, replaceRawGroup, shouldUpdateRoutingForGroup, updateRoutingForGroup,
    addCampaignWhitelistedUrl, removeCampaignWhitelistedUrl
  };
  
  // open group and return items of this group
  function openGroup(groupId, curEntityId, hardReset, moving) {

    let curGroupId = groupId || 0;

    if (groupsMap && !hardReset) { //load cached data


      groupsMap = _buildCampaignsGroupsTree(rawCampaigns, rawGroups);//rebuild tree for moved/added campaigns/groups

      if (moving) {
        openedGroupMove = groupsMap[curGroupId];
		//orgService.updatePathToGroup(openedGroupMove);
        return new $q.resolve(openedGroupMove.subItems);

      } else {
        openedGroup = groupsMap[curGroupId];
        if(openedGroup){
			//orgService.updatePathToGroup(openedGroup);
			return new $q.resolve(openedGroup.subItems);
       }
      }
    }
	
	return $http({
      url: API_PREFIX + '/GetOrgInfo',
      headers: { orgId: orgService.getOrgId() }, //keep here since might be first call prior to having orgId in url
      method: 'POST'
    }).then((response) => {
        rawCampaigns = response.data.data.campaigns;
        rawCampaigns.forEach(c => {
          c.campaignUrl = orgService.getCampaignPath(c);
          if (c.masterEventId) {
            c.masterCampaignUrl = orgService.getCampaignPath(getCampaignByEventId(c.masterEventId));
          }
        });
        rawGroups = response.data.data.groups;
        campaignDomains = response.data.data.domains;
        orgService.globalSettings = response.data.globalSettings;
        groupsMap = _buildCampaignsGroupsTree(rawCampaigns, rawGroups);
        orgService.resolveOrgInfoLoadedPromise(response.data);
        if (moving) {
          openedGroupMove = groupsMap[curGroupId];
          return new $q.resolve(openedGroupMove.subItems);
		}
		openedGroup = groupsMap[curGroupId];
		if (openedGroup == undefined) {
		  return new $q.reject();
		}
		return new $q.resolve(openedGroup.subItems);
	}); 
	
  }

  function delistCampaign(entityId, delist) {//true -> delist, false -> publish
    return $http({ url: `${API_PREFIX}Cmp/DelistCmp`, data: { delist }, headers: { entityId }, method: 'POST' })
      .then(response => response.data.success);
  }

  function delistCampaigns(entityIds, delist, messageConfig) {//true -> delist, false -> publish
    return $http({ url: `${API_PREFIX}Cmp/DelistCmps`, data: { delist }, headers: { entityIds: entityIds }, method: 'POST', messageConfig })
      .then(response => response.data.success);
  }

  function archiveCampaign(entityId, transferRecurring, recurringPlanDestination) {
    let destEntityId = recurringPlanDestination ? recurringPlanDestination.entityID : null;
    return $http({ url: `${API_PREFIX}Cmp/ArchiveCmp`, data: { transferRecurring }, headers: { entityId, transferRecurringToEntityId: destEntityId }, method: 'POST' })
      .then(response => response.data.success);
  }

  function archiveCampaigns(entityIds, transferRecurring, recurringPlanDestination, messageConfig) {
    let destEntityId = recurringPlanDestination ? recurringPlanDestination.entityID : null;
    return $http({ url: `${API_PREFIX}Cmp/ArchiveCmps`, data: { transferRecurring }, headers: { entityIds, transferRecurringToEntityId: destEntityId }, method: 'POST', messageConfig })
      .then(response => response.data.success);
  }

  function validateArchiveOrRelaunchCmp(entityId, messageConfig) {
    return $http({ url: `${API_PREFIX}Cmp/ValidateArchiveOrRelaunchCmp`, headers: { entityId }, method: 'POST', messageConfig })
      .then(response => response.data);
  }

  function validateArchiveOrRelaunchCmps(entityIds, messageConfig) {
    return $http({ url: `${API_PREFIX}Cmp/ValidateArchiveOrRelaunchCmps`, headers: { entityIds }, method: 'POST', messageConfig })
      .then(response => response.data);
  }

  function unarchiveCmp(entityId, urlPath, messageConfig) {
    return $http({ url: `${API_PREFIX}Cmp/UnarchiveCmp`, data: urlPath , headers: { entityId }, method: 'POST', messageConfig });
  }

  function replicateCampaign(entityId, replicateInfo, messageConfig) {
    return $http({ url: `${API_PREFIX}Cmp/ReplicateCmp`, data: replicateInfo , headers: { entityId }, method: 'POST', messageConfig });
  }

  function getBulkReplicateCsvHref(entityId){
	  
    return `/Org/${orgId}${API_PREFIX}Cmp/DownloadBulkUploadCsv?entityId=${entityId}`
  }

  function bulkReplicateCampaignsValidateCsv(entityId, guid, eventId, file, cloneCmpSettings, publishCampaign, messageConfig) {
    return $http({ 
        url: `${API_PREFIX}Cmp/ValidateBulkReplicateCmpCsv`,
        data: {
            entityId: entityId,
            csvFile: file,
            uploadInstance: guid,
            cloneCmpSettings: cloneCmpSettings,
            publishCampaign: publishCampaign
        }, 
        headers: { entityId, 'Content-Type': undefined }, 
        method: 'POST',
        transformRequest: rbTransformRequests.transformFormData,
        messageConfig
      }).then(response => response.data)
  }
  
  function bulkReplicateCampaigns(entityId, guid, eventId, messageConfig) {
    return $http({ url: `${API_PREFIX}Cmp/BulkReplicateCmp`, data: { uploadInstance: guid }, headers: { entityId }, method: 'POST', messageConfig })
      .then(response => response.data);
  }

  function relaunchCampaign(entityIds, transferRecurring, recurringPlanDestination, messageConfig) {
    let destEntityId = recurringPlanDestination ? recurringPlanDestination.entityID : null;
    return $http({ url: `${API_PREFIX}Cmp/RelaunchCmp`, data: { transferRecurring }, headers: { entityIds: entityIds, transferRecurringToEntityId: destEntityId }, method: 'POST', messageConfig })
      .then(response => response.data);
  }

  function saveEditCampaign(entityId, field, values, messageConfig) {
      return $http({ url: `${API_PREFIX}Cmp/UpdateCampaignInfo`, data: { field: field, value: values[0], value2: values[1], value3: values[2], value4: values[3], value5: values[4]}, headers: { entityId }, method: 'POST', messageConfig })
      .then(response => {

        let index = rawCampaigns.findIndex(item => item.entityId == entityId);
        rawCampaigns[index][field] = values[0];

        return response.data.success
      });
  }

  function saveEditCampaignCustomField(entityId, formFieldId, value) {
    return $http({ url: `${API_PREFIX}Cmp/UpdateCustomField`, data: { formFieldId, value }, headers: { entityId }, method: 'POST' })
      .then(response => {

        // let index = rawCampaigns.findIndex(item => item.entityId == entityId);
        // rawCampaigns[index][field] = values[0];

        return response.data.success
      });
  }
  
  function addCampaignUrl(entityId, subdomain, messageConfig){
    return $http({
      url: `${API_PREFIX}Cmp/AddHost`,
      data: { subdomain },
      headers: { entityId },
      method: 'POST',
      messageConfig
    })
    .then( response => response.data );
  }

  function updateCampaignUrl(entityId, url, messageConfig){
    return $http({
      url: `${API_PREFIX}Cmp/UpdateDefaultHost`,
      data: { url },
      headers: { entityId },
      method: 'POST',
      messageConfig
    })
    .then( response => response.data );
  }

  function removeCampaignUrl(entityId, url, messageConfig){
    return $http({
      url: `${API_PREFIX}Cmp/RemoveHost`,
      data: { url },
      headers: { entityId },
      method: 'POST',
      messageConfig
    })
    .then( response => response.data );
  }

  function addCampaignWhitelistedUrl(entityId, url, messageConfig){
    return $http({
      url: `${API_PREFIX}Cmp/AddWhitelistedUrl`,
      data: { url },
      headers: { entityId },
      method: 'POST',
      messageConfig
    })
        .then( response => response.data );
  }

  function removeCampaignWhitelistedUrl(entityId, url, messageConfig){
    return $http({
      url: `${API_PREFIX}Cmp/RemoveWhitelistedUrl`,
      data: { url },
      headers: { entityId },
      method: 'POST',
      messageConfig
    })
        .then( response => response.data );
  }
  
  function uploadCmpImage(entityId, image) {
    return $http({ 
        url: `${API_PREFIX}Cmp/UploadCmpImage`,
        data: {
            file: image
        }, 
        headers: { entityId, 'Content-Type': undefined }, 
        method: 'POST',
        transformRequest: rbTransformRequests.transformFormData,
      }).then(response => response.data.data);
  }

  function getOpenedGroup() {
    return openedGroup;
  }

  function getOpenedGroupMove() {
    return openedGroupMove;
  }

  function getRawCampaigns() {
    return rawCampaigns;
  }

  function updateRawCampaigns(campaign) {

    let index = rawCampaigns.findIndex(item => item.entityId == campaign.entityId);

    Object.keys(campaign).forEach(key => rawCampaigns[index][key] = campaign[key]);
  }

  function replaceRawCampaign(campaign) {
    let index = rawCampaigns.findIndex(item => item.entityId == campaign.entityId);
    campaign.settings.loaded = true;
    rawCampaigns[index] = campaign;
  }

  function replaceRawGroup(group) {
    let index = rawGroups.findIndex(item => item.entityId == group.entityId);
    group.settings.loaded = true;
    rawGroups[index] = group;
  }

  function pushToRawCampaigns(item) {
    rawCampaigns.push(item);
  }

  function pushMultipleToRawCampaigns(items) {
    items.forEach(pushToRawCampaigns)
  }

  function moveCampaignCache(targets, parent) {//update structure without getting new data from server

    targets.forEach(item => {

      let index = rawCampaigns.findIndex(campaign => campaign.campaignId == item);

      rawCampaigns[index].groupId = parent;

    });

  }


  function getRawGroups() {
    return rawGroups;
  }

  function getGroupById(groupId) {
    return rawGroups.find(group => group.groupId == groupId);
  }

  function getGroupByEntityId(entityId) {
    return rawGroups.find(group => group.entityId == entityId);
  }

  function pushToRawGroups(item) {
    rawGroups.push(item);
  }

  function updateGroupName(groupId, name) {
    let index = rawGroups.findIndex(item => item.groupId == groupId);

    if (index !== -1) {
      rawGroups[index].name = name;
    }
  }

  function replaceRawGroups(newRawGroups) {
    rawGroups = newRawGroups
  }

  function moveGroupCache(targets, parent) {//update structure without getting new data from server

    targets.forEach(item => {

      let index = rawGroups.findIndex(group => group.groupId == item);

      rawGroups[index].parentGroupId = parent;

    });
  }

  function getCampaignDomains() {
    return campaignDomains;
  }

  function getCampaignByEntityId(entityId) {
    return rawCampaigns.find((campaign) => campaign.entityId == entityId);
  }

  function getCampaignById(campaignId) {
    return rawCampaigns.find((campaign) => campaign.campaignId == campaignId);
  }

  function getCampaignByEventId(eventId) {
    return rawCampaigns.find((campaign) => campaign.curEventId == eventId);
  }

	function getCampaignByUrl(url) {
    return rawCampaigns.find(campaign => campaign.urls.primary == url);
	}

  // GroupName -> GroupName -> GroupName
  function getGroupsPath() {
    let path = [];
    let currentNode = openedGroup;

    // build path from tree
    while (currentNode.parentGroupId !== undefined) {
      path.push(currentNode);
      currentNode = groupsMap[currentNode.parentGroupId];
    }

    return path.reverse(); // last should be last visited
  }

  function _buildCampaignsGroupsTree(campaigns, groups) {
    // map groupId : group object
    let groupsMap = _.keyBy(groups, 'groupId');
    groupsMap[0] = {}; // holder for root campaigns

    _.each(groupsMap, (group) => {
      group.amountRaised = 0;
      group.members = 0;
      group.subItems = {
        groups: [],
        campaigns: []
      }
    });

    campaigns.forEach((campaign) => {
		if (!campaign.type) campaign.type = 'Ticketing'; //temp fix until next release
		let campaignGroup = groupsMap[campaign.groupId];
		campaignGroup.members += campaign.members;
    campaignGroup.amountRaised += campaign.amountWithoutSubgoals;
		campaignGroup.subItems.campaigns.push(campaign);
    });

    groups.forEach((group) => {
      groupsMap[group.parentGroupId].subItems.groups.push(group);
    });

    groupsMap[0].subItems.groups.forEach((group) => {
      const countRecursive = (subGroup, field) => {
        subGroup.subItems.groups.forEach((subSubGroup) => {
          subGroup[field] += countRecursive(subSubGroup, field);
        });

        return subGroup[field];
      };

      group.amountRaised = countRecursive(group, 'amountRaised');
      group.members = countRecursive(group, 'members');
    });


    return groupsMap;
  }
  
  function updateRoutingForGroup(campaign) {
    redirectedFrom = campaign.campaignId;

    $timeout(function () {
          var fullPath = $location.path();
          var match = fullPath.match(/.*?\/campaign\/[0-9]*(\/?.*)/i);
          var pathRemainder = match ? match[1] : '';

          orgService.updatePathToCampaign(campaign, pathRemainder);
          $location.replace();
      });
  }

  function shouldUpdateRoutingForGroup($nextInstruction, entityInfo) {
    let campaignId = $nextInstruction.params.campaignId;
    let groupFromUrl = $nextInstruction.params.groupId;
    let groupId = entityInfo.groupId;
    
    if (!campaignId) {
      redirectedFrom = null;
      return false;
    }
    
    // Prevent infinite loop of redirects
    if (redirectedFrom === campaignId) {
      return false;
    }
    
    if (!groupFromUrl && !groupId) {
      redirectedFrom = null;
      return false;
    }
    
    if (groupFromUrl == groupId) {
      redirectedFrom = null;
      return false;
    }

    return true;
  }

  function checkUpdateRouting() {
      let campaignAndGroup = {};

      const campaignPath = orgService.getUrlCampaignPath();

      if (campaignPath) {
        const campaignHost = orgService.getUrlHost();
        let campaign = getCampaignByUrl(campaignHost + '/' + campaignPath);
        if (!campaign) return $q.reject();

        let groupId = campaign.groupId;
        let group = getGroupById(groupId);

        campaignAndGroup.campaign = campaign;
        campaignAndGroup.group = group;

        return $q.resolve(campaignAndGroup);
      }

      let campaignId = orgService.getUrlCampaignId();

      if (campaignId) {
          let campaign = getCampaignById(campaignId);
          if (!campaign) return $q.reject();

          let groupId = campaign.groupId;
          let group = getGroupById(groupId);

          campaignAndGroup.campaign = campaign;
          campaignAndGroup.group = group;

          return $q.resolve(campaignAndGroup);
      }

      let groupId = orgService.getUrlGroupId();
      if (groupId) {
          let group = getGroupById(groupId);
          if(!group) return $q.reject();

          campaignAndGroup.group = group;

          return $q.resolve(campaignAndGroup);
      }

      return $q.resolve(campaignAndGroup);
  }
}