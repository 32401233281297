angular
    .module('app')
    .component('processors', {
        templateUrl: 'processors.tpl.html',
        controller: processorsController,
        controllerAs: 'vm'
    });

function processorsController($scope, orgProcessorsService, routingService, entityService, orgService, configSettingsService, $mdDialog) {
    const vm = this;
    vm.loading = true;
    vm.settingsLoading = true;
    vm.settingsSections = [];
    vm.sections = [];
    vm.processors = [];
    vm.configItem = {};
    vm.processorsConfigId = 12;

    $scope.$on('adoptParent', function(event, configId) {
        vm.loading = true;
        orgProcessorsService
            .adoptParent()
            .then(setResponseData)
            .finally(() => {
                vm.loading = false;
            });
    });

    $scope.$on('overrideParent', function(event, configId) {
        vm.loading = true;
        orgProcessorsService
            .overrideParent()
            .then(setResponseData)
            .finally(() => {
                vm.loading = false;
            });
    });

    $scope.$on('clearOverrides', function(event, configId) {
        vm.loading = true;
        orgProcessorsService
            .clearOverrides()
            .then(setResponseData)
            .finally(() => {
                vm.loading = false;
            });
    });

    vm.loadProcessorsSet = function() {
        orgProcessorsService.getEntityProcessorSettings().then(data => {
            vm.settingsLoading = false;
            setResponseData(data);
            vm.loading = false;
        });
    }

    vm.updateSettingFlag = function(config) {
        return orgProcessorsService.updateProcessorInstanceSetting(config);
    };

    $scope.$on('moveUp', function(event, instanceId) {
        var data = {
            processorInstanceId: instanceId,
            processorId: 0
        }
        vm.loading = true;
        orgProcessorsService
            .moveInstanceUp(data)
            .then(setResponseData)
            .finally(() => {
                vm.loading = false;
            });
    });

    $scope.$on('moveDown', function(event, instanceId) {
        var data = {
            processorInstanceId: instanceId,
            processorId: 0
        }
        vm.loading = true;
        orgProcessorsService
            .moveInstanceDown(data)
            .then(setResponseData)
            .finally(() => {
                vm.loading = false;
            });
    });

    $scope.$on('remove', function(event, instanceId) {
        var isLastProcessor = vm.processors.length == 1;
        
        var entityTypeWarning = '';
        if (vm.entityType == 'Org') {
            entityTypeWarning = isLastProcessor ? 'Campaigns within this organization' : 'any campaign in this organization'
        } else if (vm.entityType == 'CampaignGroup') {
            entityTypeWarning = isLastProcessor ? 'Campaigns within this group' : 'any campaign in this group'
        } else if (vm.entityType == 'Campaign') {
            entityTypeWarning = isLastProcessor ? 'This campaign' : 'this campaign'
        }

        var warningMessage = `Transactions will no longer be processed through this processor on ${entityTypeWarning}.`;
        if (isLastProcessor) {
            warningMessage = `${entityTypeWarning} will no longer be able to process transactions.`;
        }
        
        var confirm = $mdDialog
        .confirm()
        .title('Are you sure?')
        .textContent(warningMessage)
        .ok('Remove')
        .cancel('Cancel');
    
        $mdDialog.show(confirm).then(
          function() {
            var data = {
                processorInstanceId: instanceId,
                processorId: 0
            }
            vm.loading = true;
            orgProcessorsService
            .removeInstance(data)
            .then(setResponseData)
            .finally(() => {
                vm.loading = false;
            });
          },
          function() {}
        );
    });

    vm.loadProcessorsSet();

    function setResponseData(data) {
        var configItem = data.configItem;
        vm.inherited = configItem.Inherited;
        vm.isDefault = configItem.IsDefault;
        vm.parentName = configItem.OverriddenParentEntityName;

        vm.processors = data.processors;
        vm.processors.forEach(function(processor) {
            if (processor.SettingForm && processor.SettingForm.length) {
                processor.SettingForm[0].HideHeader = true;
            }
        });

        vm.configItem = configItem;

        let entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
        vm.entityType = entity.entityType;
        vm.onOrg = vm.entityType == 'Org';
    }
}