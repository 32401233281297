angular
    .module('app')
    .component('modalAddUrl', {
        bindings: {
            show: '=',
            onAddUrl: '=',
            groupUrl: '=',
            editableAtEnd: '=',
            fullUrlEditable: '=',
            emailDomain: '=',
            modalTitle: '@',
        },
        controllerAs: 'vm',
        controller: ModalAddUrlController
    });


function ModalAddUrlController($scope, $mdDialog) {
    const vm = this;

    $scope.$watch('vm.show', () => {
        if ( vm.show ) {
            $mdDialog.show({
                templateUrl: 'modal-add-url.tpl.html',
                controller: ModalAddUrlContentController,
                controllerAs: 'vm',
                locals: {
                    onAddUrl: vm.onAddUrl,
                    groupUrl: vm.groupUrl,
                    editableAtEnd: vm.editableAtEnd,
                    fullUrlEditable: vm.fullUrlEditable,
                    emailDomain: vm.emailDomain,
                    modalTitle: vm.modalTitle
                }
            })
            .finally(() => {
                vm.show = false;
            })
        }
    });
}

function ModalAddUrlContentController($scope, $mdDialog, onAddUrl, groupUrl, editableAtEnd, fullUrlEditable, emailDomain, modalTitle) {
    const vm = this;

    vm.onAddUrl = onAddUrl;
    vm.groupUrl = groupUrl;
    vm.editableAtEnd = editableAtEnd;
    vm.fullUrlEditable = fullUrlEditable;
    vm.emailDomain = emailDomain;
    vm.modalTitle = modalTitle || 'Add URL';

    vm.maxLength = vm.fullUrlEditable ? 255 : 255 - vm.groupUrl.length;
    vm.pattern = vm.fullUrlEditable ? '^(?:[\\w-]+\\.)+[a-z]{2,10}$' : vm.editableAtEnd ? '^[a-zA-Z0-9-]*$' : '^[^\.|\/|\\s]*$';
    vm.placeholder = vm.fullUrlEditable ? 'Domain' : vm.editableAtEnd ? 'Path' : 'Subdomain';
    
    vm.url = "";

    vm.close = function() {
        $mdDialog.hide();
    };

    vm.save = function() {
        vm.saving = true;

        vm.onAddUrl(vm.url)
            .finally(() => {
                vm.close();
                vm.saving = false;
            })
    };
}
