angular.module('app').component('orderableToggles', {
  controllerAs: 'vm',
  controller: orderableTogglesController,
  templateUrl: 'orderable-toggles.tpl.html',
  bindings: {
    fields: '<',
    activeOptions: '=',
    selector: '<',
    onChange: '<',
    sectionUpdating: '<',
    configId: '<',
    type: '<',
    inherited: '<',
    disabled: '<',
    tooltip: '<',
    parentName: '<',
    label: '<',
    description: '<',
    isDefault: '<',
    refresh: '=',
    entityType: '<'
  }
});

function orderableTogglesController($scope, $timeout, entityService) {
  const vm = this;
  vm.refresh = true;

  $timeout(setSortableState);
  $scope.$watch('vm.inherited', setSortableState);

  function setSortableState() {
    var method = vm.inherited || (vm.entityType != 'Org' && vm.isDefault) ? 'disable' : 'enable';
    vm.applyToSortable(method);
  }
  
  vm.isOn = function(optionId) {
    return vm.activeOptions.includes(optionId);
  };
  
  vm.HQ_TASKS = {
    None: 0,
    CreateChampionCampaign: 1,
    UploadPhoto: 4,
    ImportYourContacts: 8,
    SendFundraisingEmail: 32,
    PostSocial: 64,
    UpdateEnduranceGoal: 101,
    LogEnduranceActivity: 102,
    ConnectEnduranceIntegration: 103,
    RSVP: 128,
    TeamBuilder: 256,
    UpdateAccountDetails: 512,
    PersonalizeMyPage: 1024,
    UpdateGoal: 2048,
    CreateCustomURL: 4096,
    DonateOwnGoal: 131072,
    EnterOfflineDonation: 536870912,
    AddLivestream: 1073741824,
    ConnectFacebookFundraiser: 104,
    EnableStreamlabs: 105,
    FundraiserPdf: 107,
  };

  vm.STARTUP_ITEMS = {
    UploadPhoto: 4,
    RSVP: 128,
    TeamBuilder: 256,
    UpdateAccountDetails: 512,
    UpdateGoal: 2048,
    CreateCustomURL: 4096,
    DonateToOwnGoal: 131072,
    ConnectFacebookFundraiser: 104,
  };

  function removeItem(id) {
    var index = vm.fields.findIndex(x => x.Value == id);
    if (index > -1) {
      vm.fields.splice(index, 1);
    }
  }
  
  function setActiveFields() {
    let entity = entityService.getEntityWithSettings(entityService.getOpenedEntityId());
    //Updating? Update ConfigFileTemplateHelper.AllowedSelectionItems too!
    if (entity.entityType == 'Campaign') {
      if (vm.configId == 252) {
        if (!entity.settings.ChampionEnabled) {
          removeItem(vm.HQ_TASKS.CreateChampionCampaign);
        }
        if (!entity.settings.HasEnduranceChallenge) {
          removeItem(vm.HQ_TASKS.UpdateEnduranceGoal);
          removeItem(vm.HQ_TASKS.LogEnduranceActivity);
          removeItem(vm.HQ_TASKS.ConnectEnduranceIntegration);
        }
        if (!entity.settings.LivestreamEnabled) {
          removeItem(vm.HQ_TASKS.AddLivestream);
        }
        if (!entity.settings.FacebookFundraiserEnabled) {
          removeItem(vm.HQ_TASKS.ConnectFacebookFundraiser);
        }
        if (!entity.settings.FundraiserCanConnectStreamlabs || !entity.settings.LivestreamEnabled) {
          removeItem(vm.HQ_TASKS.EnableStreamlabs);
        }
        if(!entity.settings.HasFundraiserPdfEnabled) {
          removeItem(vm.HQ_TASKS.FundraiserPdf);
        }
      }
      
      if (vm.configId == 250) {  //startupGuide
        if (entity.settings.HasRoleRegistration) {
          removeItem(vm.STARTUP_ITEMS.RSVP); //RegisterFriends aka RSVP
        }
        if (!entity.settings.FacebookFundraiserEnabled) {
          removeItem(vm.STARTUP_ITEMS.ConnectFacebookFundraiser);
        }
      }
    }

    vm.fields.forEach(field => {
      field.Value = parseInt(field.Value);
      if (vm.isOn(field.Value)) {
        field.on = true;
      }
    });
  }
      
  vm.getOptions = function() {
    if (!vm.refresh) {
      return vm.fields;
    }
    
    setActiveFields(); 

    var sorted = vm.fields.sort((a, b) => {
      var aIndex = vm.activeOptions.indexOf(a.Value);
      var bIndex = vm.activeOptions.indexOf(b.Value);

      var returnVal;
      if (vm.isOn(a.Value) && vm.isOn(b.Value)) { //both are set
          returnVal = aIndex - bIndex;
      } else if (bIndex < 0 && aIndex < 0) { //neither are set - order by optionID
          returnVal = a.value - b.value;
      } else {
          returnVal = bIndex - aIndex;
      }
      return returnVal;
    });
    return sorted;
  };

  vm.applyToSortable = function(method) {
    return angular.element(`#${vm.selector}`).sortable(method);
  }

  vm.getAllItems = function () {
    var sortablesIds = vm.applyToSortable('toArray');
    var orderedIdStrings = sortablesIds.map(x => x.substring(vm.selector.length));
    return orderedIdStrings.map(x => parseInt(x));
  }

  vm.sortableOptions = {
    update: function(event, ui) {
      if (vm.disabled || vm.inherited) return;
      vm.refresh = false;

      var oldSetting = vm.activeOptions.slice();
      vm.activeOptions = vm.getAllItems().filter(x => vm.isOn(x));
      var changed = oldSetting.length !== vm.activeOptions.length
        || oldSetting.some((value, index) => value !== vm.activeOptions[index]);

      if (changed)  {
        vm.doUpdateOptions().then(function(success) {
          if (!success) {
            vm.activeOptions = oldSetting;
            vm.applyToSortable('cancel');
          }
        });
      }
    },
    axis: 'y'
  };

  vm.updateSelected = function(optionId, toggleOn) {
    vm.refresh = false;
    var oldSetting = vm.activeOptions.slice();
    if (toggleOn && !vm.isOn(optionId)) {
      vm.activeOptions.push(optionId);
    } else {
      vm.activeOptions.splice(vm.activeOptions.indexOf(optionId), 1);
    }

    vm.activeOptions = vm.getAllItems().filter(x => vm.isOn(x));
    return vm.doUpdateOptions().then(success => {
      if (!success) {
        vm.activeOptions = oldSetting;
      }
      return success;
    });
  };

  vm.doUpdateOptions = function() {
    vm.applyToSortable('disable');
    return vm
      .onChange(vm.configId, vm.activeOptions.join(','))
      .then(function(success)  {
         vm.applyToSortable('enable');
         return success;
      });
  }
}
