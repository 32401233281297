angular
  .module('app')
  .component('modalAddAdministrator', {
    bindings: {
      showAdmin: '=',
      admins: '=',
      roles: '=',
      onUpdateAdmin: '&'
    },
    controller: ModalAddAdminCtrl,
    controllerAs: 'vm'
  });


function ModalAddAdminCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.showAdmin', () => {
    if (vm.showAdmin) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-add-administrator.tpl.html',
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          admins: vm.admins,
          roles: vm.roles,
          onUpdateAdmin: vm.onUpdateAdmin
        },
        clickOutsideToClose: true
      })
      .finally(() => {
        vm.showAdmin = false;
      });
  }

  // $scope.$on('$destroy', openAdmin);
}

function ModalContentCtrl($scope, $timeout, $q, $mdDialog, $location, $mdConstant, entityService,
                          campaignService, administratorService, messageService, admins, roles, onUpdateAdmin) {
  const vm = this;
  vm.newAdmin = {};
  vm.roles = roles;
  vm.admins = admins;
  vm.onUpdateAdmin = onUpdateAdmin;
  vm.loadInProgress = false;
  vm.saveInProgress = false;
  vm.inviteInProgress = false;
  vm.showTextArea = false;
  vm.inviteNewAdmin = inviteNewAdmin;
  vm.closeModal = closeModal;
  vm.addCampaign = addCampaign;
  vm.addedCampaigns = [];
  vm.removeCampaign = removeCampaign;
  vm.inviteDisabled = true;

  // vm.changeMdContainer = changeMdContainer;
  let campaignsData = campaignService.getRawCampaigns();
  let groupsData = campaignService.getRawGroups();
  let orgGroupsCampaigns = groupsData.concat(campaignsData);

 //chips
  vm.newAdmin.email = [];
  vm.adminSearch = adminSearch;
  vm.selectedAdmin = null;
  vm.searchAdmin = null;
  vm.selectedTags = [];
  vm.keys = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.COMMA];
  vm.allAdmins = loadContacts();
  vm.showChipsHint = false;

  function adminSearch(query){
    let results = query ? vm.allAdmins.filter(createAdminFilter(query)) : [];
        return results;
  };

   function createAdminFilter(query) {
      var lowercaseQuery = angular.lowercase(query);
          return function filterFn(contact) {
            return ((contact._loweremail.indexOf(lowercaseQuery) != -1)
              || (contact._lowername.indexOf(lowercaseQuery) != -1));
      };
  };

  function loadContacts() {
        let contacts = administratorService.getAdminsFromOrg();
           return contacts.map(function (c, index) {
                let contact = {
                        name: c.firstName + ' ' + c.lastName,
                        email: c.email
                };
                contact.display = (contact.name === ' ') ? contact.email : contact.name;
                contact.fullname = (contact.name === " ") ? contact.email : contact.name;
                contact._loweremail = contact.email.toLowerCase();
                contact._lowername = contact.name.toLowerCase();
                return contact;
          });   
      };

    //watch for when the email input box is added to to check for invalid emails
    $scope.$watchCollection('vm.newAdmin.email', function () {
        if (vm.newAdmin.email && vm.newAdmin.email.length > 0) {
            let input = vm.newAdmin.email[vm.newAdmin.email.length - 1];
            //if type is not string then the chip was added by autocomplete
            if (typeof input == "string") {
                let pattern = /^.+@.+\..+$/;
                let emailsRejected = '';
                let emailsToAdd = [];
                let invalid = false;
                let emails = input.split(' ');
                emails.forEach((email) => {
                    if (!email.match(pattern)) {
                        invalid = true;
                        emailsRejected += " " + email;
                    } else {
                        emailsToAdd.push(email);
                    }
                })
                if (invalid || emails.length > 1) {
                    vm.newAdmin.email.splice(-1, 1);
                    vm.newAdmin.email = vm.newAdmin.email.concat(emailsToAdd);
                    if (invalid) {
                        $timeout(() => {
                            messageService.showErrorToast(`Please enter valid email`, angular.element('.add-admin-email'));
                            vm.searchAdmin = emailsRejected.trim();
                        })
                    }
                }
            }
        }
    });
    
 // autocomplete campaigns
  vm.campaigns = loadCampaigns();
  vm.querySearch   = querySearch;
  vm.updatedCampaignsList = [];


  function querySearch(query) {
      let results = query ? vm.campaigns.filter( createFilterFor(query) ) : vm.campaigns;
        return results;
  }


  function loadCampaigns() {
      orgGroupsCampaigns = orgGroupsCampaigns.map( function (camp) {
        return {
          value: camp.name.toLowerCase(),
          entityId: camp.entityId,
          entityType: camp.entityType || 'Group',
          url: camp.url,
          smallImage: camp.smallImage,
          display: camp.name
        };
      });

      var entireOrgOption = {
          value: "entire organization",
          entityId: entityService.getOrgEntityId(),
          entityType: 'Org',
          display: "Entire Organization"
      };

      orgGroupsCampaigns.splice(0, 0, entireOrgOption);

      return orgGroupsCampaigns;
  }

  function createFilterFor(query) {
      var lowercaseQuery = angular.lowercase(query);

      return function filterFn(camp) {
        return (camp.value.indexOf(lowercaseQuery) === 0);
      };
    };

 //end autocomplete

    function addCampaign(){
        let newCamp = angular.copy(vm.newAdmin);

        vm.updatedCampaignsList = angular.copy(vm.campaigns);
        vm.campaigns.forEach((camp, index) => {
            if (camp.display === vm.newAdmin.campaign.display) {
                vm.updatedCampaignsList.splice(index, 1);
            }
        });

        vm.campaigns = vm.updatedCampaignsList;

        vm.addedCampaigns.push(newCamp);
        vm.newAdmin.campaign = null;
        vm.newAdmin.role = null;
        vm.searchText = null;
    }

    function removeCampaign(index) {
        //add this campaign to options in search
        vm.campaigns.splice(0, 0, vm.addedCampaigns[index].campaign);
        //take out of added campaigns
        vm.addedCampaigns.splice(index, 1);
    }

    function closeModal() {
        $mdDialog.hide();
    }

    // function changeMdContainer(){
    //   document.querySelector('.md-dialog-container').className = "md-dialog-container md-container-overwrite";
    // }

    function addRoleChanges(id, currentRoleId) {
        listRoles.push({
            userId: vm.admin.id,
            entityId: id,
            roleId: currentRoleId
        });
    }

    function advancedInviteErrors(currentMessageService, response) {
        var msg = '';
        response.data.data.errors.forEach((error) => {
            error.errorsList.forEach((entity) => {
                msg += error.key + ' could not be invited to ' + entityService.getEntityName(entity.errorMessage) + '.<br>';
            });
        });
        msg += "All the other invites were successful."
        messageService.showErrorDialog(msg);
    }

    function inviteNewAdmin() {
        vm.inviteInProgress = true;

        for (let i = 0; i < vm.newAdmin.email.length; i++) {
            if (typeof vm.newAdmin.email[i] === 'object') {
                vm.newAdmin.email[i] = vm.newAdmin.email[i].email;
            }
        }

        let rolesOnEntitys = [], 
            emails = [],
            entityIds = [];
        vm.addedCampaigns.forEach((camp) => {
            entityIds.push(camp.campaign.entityId);
            rolesOnEntitys.push({ entityId: camp.campaign.entityId, roleId: camp.role.roleId })
        });

        let messageConfig = {
            advancedErrorFunction: advancedInviteErrors,
            advancedErrorEnabled: true,
            successToastEnabled: true,
            successMsg: `Successfully invited ${vm.newAdmin.email}`
        };

        administratorService
        .inviteAdmin(vm.newAdmin.email, rolesOnEntitys, undefined, entityIds, vm.newAdmin.message, messageConfig)
        .then(data => {
            let newAdmins = data.data.data;

            for (var i = 0; i < newAdmins.length; i++) {
                administratorService.updateAdminsCurrentRoleIds(newAdmins[i]);
                var foundAdmin = false;
                //if admin already was in admins array we add the new entities
                for (var j = 0; j < vm.admins.length; j++) {
                    if (newAdmins[i].id == vm.admins[j].id) {
                        vm.onUpdateAdmin({ admin: newAdmins[i], index: j });
                        foundAdmin = true;
                    }
                }
                //else add to end of admins array
                if (!foundAdmin) {
                    vm.onUpdateAdmin({ admin: newAdmins[i] });
                }
            }
            //if (data.data.errors.length == 0) {
            //    $mdToast.show(
            //        $mdToast.simple()
            //        .textContent(`Successfully invited ${vm.newAdmin.email}`)
            //        .position('top right')
            //        .hideDelay(5000)
            //    );
            //} else {
            //    var msg = '';
            //    data.data.errors.forEach((error) => {
            //        error.errorsList.forEach((entity) => {
            //            msg += error.key + ' could not be invited to ' + entityService.getEntityName(entity.errorMessage) + '.<br>';
            //        });
            //    });
            //    msg += "All the other invites were successful."
            //    let alert = $mdDialog.alert()
            //        .title("Errors inviting administrators!")
            //        .htmlContent(msg)
            //        .ok('Close');

            //    $mdDialog
            //        .show(alert);
            //}
            
            vm.newAdmin.email = vm.newAdmin.roleId = null;
            closeModal();
        })
        .finally(() =>  vm.inviteInProgress = false);
    }
}
