angular
  .module('app')
  .factory('taxReceiptService', taxReceiptService);

function taxReceiptService($http, API_PREFIX, entityService) {

  return {
    getTemplate,
    updateTemplate
  };

  function getTemplate() {
    let entityId = entityService.getOpenedEntityId();

    return $http({
      url: `${API_PREFIX}Cmp/GetPdfReceiptTemplate`,
      headers: { entityId },
      method: 'POST'
    }).then(response => response.data);
  }

  function updateTemplate(template) {
    let entityId = entityService.getOpenedEntityId();

    return $http({
      url: `${API_PREFIX}Cmp/UpdatePdfReceiptTemplate`,
      headers: { entityId },
      data: { template },
      method: 'POST'
    }).then(response => response.data);
  }
}
