angular
  .module('app')
  .component('modalUnarchiveCampaign', {
    bindings: {
      show: '=',
      unarchive: '=',
      eventGroupHost: '<',
    },
    controller: modalUnarchiveCampaignCtrl,
    controllerAs: 'vm'
  });

function modalUnarchiveCampaignCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-unarchive-campaign.tpl.html',
        clickOutsideToClose: true,
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          eventGroupHost: vm.eventGroupHost
        }
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($mdDialog, $route, $rootScope, $timeout,
                          entityService, campaignService, orgService,
                          messageService, eventGroupHost) {
  const vm = this;
  vm.saveInProgress = false;
  vm.entityId = orgService.getOrg().entityId;
  vm.entityIdParent = entityService.getOpenedEntityId();
  vm.eventGroupHost = eventGroupHost;

  vm.unarchiveCampaign = unarchiveCampaign
  vm.cancel = closeModal;
  vm.validateSubdomain = validateSubdomain;
  vm.validSubdomain = false;
  vm.campaign = campaignService
  .getCampaignByEntityId(entityService.getOpenedEntityId())

  function unarchiveCampaign() {
    vm.validateSubdomain();
    if (!vm.validSubdomain) {
        document.getElementById('subDomainInput').focus();
        return;
    }
    vm.saveInProgress = true;

    let data = {
      urlPath: vm.subDomain,
    };

    processCampaign(data);
  }

  function processCampaign(data) {

      let messageConfig = {
          advancedErrorFunction: messageService.advancedErrorsDialog,
          advancedErrorEnabled: true,
          successToastEnabled: !vm.unarchive,
          successMsg: 'Campaign reactivated successfully!'
      };

      let campaignId = orgService.getUrlCampaignId();
      let campaignEntityId = campaignService.getCampaignById(campaignId).entityId;

      campaignService.unarchiveCmp(campaignEntityId, data, messageConfig)
        .then(() => {
          closeModal();
          entityService.refreshEntityAndComponent(campaignEntityId);
        })
        .finally(function() {
          vm.saveInProgress = false;
        })
  }

  function closeModal() {
    $mdDialog.hide();
  }

  function validateSubdomain() {
      let regex = /.*(\.|\/|\s).*/;
      if (String(vm.subDomain).match(regex)) {
          vm.validSubdomain = false;
          messageService.showErrorToast('Please do not use the "/", ".", or " " characters.');
          return;
      }

      regex = /^[a-zA-Z0-9-]*$/;
      if (!String(vm.subDomain).match(regex)) {
          vm.validSubdomain = false;
          messageService.showErrorToast('Only letters, numbers, and hyphens are valid for the url path.');
          return;
      }

      vm.validSubdomain = true;
  }

}
