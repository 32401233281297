angular
  .module('app')
  .directive('imageAltText', ImageAltText);

function ImageAltText() {
 return {
		restrict: 'A',
		link: function (scope, element, attr) {
			element.on('error', function(){
                scope.vm.showImage = false;
                scope.$apply();
            })
		}
	};
}
