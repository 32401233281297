angular
  .module('app')
  .component('emailDomains', {
    templateUrl: 'email-domains.tpl.html',
    controller: EmailDomainsController,
    controllerAs: 'vm'
  });

function EmailDomainsController($mdDialog, emailDomainsService, securityService, orgService) {
    const vm = this;
    
    vm.securityRoles = [];
    vm.dataLoaded = false;
    vm.selectedDomain = undefined;
    vm.showDomainDetails = false;
    vm.addingNewDomain = false;
    vm.showModalAddDomain = false;
    vm.security = securityService.getAdminPermissions(orgService.getOrg(), 'EMAIL_ADVANCED_OPTIONS');
    
    loadData();
    
    function loadData() {
        emailDomainsService.getEmailDomains()
            .then(domains => {
                vm.dataLoaded = true;
                vm.emailDomains = domains;
            });
    }
    
    vm.getStatusText = function(status) {
        return emailDomainsService.getDomainStatusText(status);
    }

    vm.showDomainPopup = function(domain) {
        vm.selectedDomain = domain;
        vm.showDomainDetails = true;
    }
    
    vm.reloadDomains = function() {
        vm.dataLoaded = false;
        loadData();
    }

    vm.addDomain = function(domain) {
        vm.addingNewDomain = true
        
        return emailDomainsService.addDomain(domain)
            .then(() => {
                vm.reloadDomains()
                vm.showDomainPopup(domain)
            })
            .catch(angular.noop)
            .finally(() => {
                vm.addingNewDomain = false
            });
    }

}