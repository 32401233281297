angular.module('app').component('campaignPage', {
  templateUrl: 'campaign-page.tpl.html',
  controller: CampaignPageController,
  controllerAs: 'vm',
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateRoute($nextInstruction).then(r => r ? routingService.validateNoPendingChanges($nextInstruction) : false);
  },
  bindings: { $router: '<' },
  $routeConfig: [{
    path: '/**',
    name: 'Page Not Found',
    component: 'pageNotFound'
  }]
})
.directive('syncFocusWith', function($timeout, $rootScope, $compile) {
  return {
    restrict: 'A',
    link: function($scope, $element, attrs) {
      let $input = $element.is('input, md-select, md-checkbox') ? $element : $element.find('input, md-select, md-checkbox').first();
      $scope.$watch(attrs.syncFocusWith, function(currentValue, previousValue) {

        if (currentValue === true && previousValue !== true) {
            $timeout(() => {
                $input.focus();
                $input.select();
            })

        } else if (currentValue === false && previousValue) {
            $input.blur();
        }
      })
    }
  }
});

function CampaignPageController($location, $rootScope, $mdDialog, $filter, $scope, $sce, $q, $timeout, mdcDateTimeDialog, settingsService,
                                entityService, campaignService, securityService, orgService, messageService, 
                                ngModelFiltersService, fileUpload) {
  const formFieldTypes = {
    Text: 1,
    Select: 2,
    Date: 3,
    Textarea: 4,
    Checkbox: 5,
    Multiselect: 6,
  };

  const vm = this;

  vm.delistCampaign = delistCampaign;
  vm.replicateCampaign = replicateCampaign;
  vm.archiveOrRelaunchClick = archiveOrRelaunchClick;
  vm.unarchiveClick = unarchiveClick;
  vm.loaders = {};
  vm.showDelistConfirm = showDelistConfirm;
  vm.addOverlay = addOverlay;
  vm.removeOverlay = removeOverlay;
  vm.security = {};
  vm.enableEditField = enableEditField;
  vm.settingsForm = {};
  vm.campaignNotFound = false;
  vm.formFieldTypes = formFieldTypes;
  vm.filters = ngModelFiltersService;
  vm.isSupervisor = orgService.getOrg().supervisor;
  vm.orgId = orgService.getOrgId();
  vm.displayDateDialog = displayDateDialog;
  vm.toggleYearsDropdown = toggleYearsDropdown;
  vm.toggleSupervisorYearsDropdown = toggleSupervisorYearsDropdown;
  vm.displayTimeDialog = displayTimeDialog;
  vm.timeZoneSearch = timeZoneSearch;
  vm.showEmbedCode = showEmbedCode;
  vm.copyEmbedCode = copyEmbedCode;
  vm.getEditableUrl = getEditableUrl;
  vm.campaignInstanceId = orgService.getUrlCampaignInstanceId();
  vm.siteBuilderUrl = siteBuilderUrl;
  vm.customCampaignFieldSecurity ={};
  vm.isChampion = !!window.isChampion;

  vm.cdn = "cdn3.rallybound.com";
  settingsService.getCDN().then(cdn => vm.cdn = cdn);

  function displayDateDialog(dateField) {
      mdcDateTimeDialog.show({
          time: false,
          date: true,
          currentDate: vm.info[dateField].dateTime,
          disableParentScroll: true
      })
        .then(function (date) {
            vm.info[dateField].dateTime = new moment.utc(date);
        }, function() {
        });
  };
  
  function displayTimeDialog(dateField) {
      mdcDateTimeDialog.show({
          time: true,
          date: false,
          shortTime: true,
          currentDate: vm.info[dateField].dateTime,
          disableParentScroll: true
      })
        .then(function (date) {
            vm.info[dateField].dateTime = new moment.utc(date);
        }, function() {
        });
  };
  
  function timeZoneSearch(query) {
      const lowerCaseQuery = query.toLowerCase();

      return settingsService.getIanaTimeZones()
          .then(zones => zones.filter(zone => zone.display.toLowerCase().includes(lowerCaseQuery)));
  }
  

  vm.campaignId = orgService.getUrlCampaignId();
  if (vm.campaignId) {
      vm.campaign = campaignService.getCampaignById(vm.campaignId);
  } else {
    let campaignHostFromUrl = orgService.getUrlHost();
    let campaignPathFromUrl = orgService.getUrlCampaignPath();
    vm.campaign = campaignService.getCampaignByUrl(campaignHostFromUrl + '/' + campaignPathFromUrl);
    vm.campaignId = vm.campaign.campaignId;
  }
  vm.orgUrl = orgService.getOrg().orgUrl;
  let previousDate = null;

  vm.showSupervisorYears = false;
  function toggleSupervisorYearsDropdown () {
      vm.showSupervisorYears = !vm.showSupervisorYears;
  }

  vm.showYears = false;
  function toggleYearsDropdown () {
      vm.showYears = !vm.showYears;
  }

    vm.security = securityService.getAdminPermissions(vm.campaign, 'CAMPAIGN');
  vm.canEdit = vm.security.EDIT && !vm.campaignInstanceId;
  vm.canEditContent = !!securityService.getAdminPermissions(vm.campaign, 'SETTINGS_EDIT_CONTENT_ON_SITE').EDIT;
  vm.customCampaignFieldSecurity = securityService.getAdminPermissions(vm.campaign, 'CUSTOM_CAMPAIGN_FIELDS');

  getCampaignInfo();
  $rootScope.$emit('campaign.opened', {name: vm.campaign.name, campId: vm.campaignId});

  function showEmbedCode() {
      vm.displayEmbedCode = vm.displayEmbedCode == true ? false : true;
  };

  function copyEmbedCode() {
      var codeToCopy = angular.element('#embedCode');
      codeToCopy.select();
      document.execCommand('copy');
  }

  function getCampaignInfo() {

    vm.campaignInfoFetching = true; //Move this to the beginning of the fetching process?

    entityService.getInfo(getCurEntityId())
      .then(updateInfo)
      .finally(error => {
        vm.campaignInfoFetching = false;
      });
  }

  function getCurEntityId() {
    if (vm.campaignInstanceId) {
      return vm.campaign.oldInstancesWithYrAndEvntId.find(c => c.cmpInstanceId == vm.campaignInstanceId).entityId;
    }
    return vm.campaign.entityId;
  }

  function updateInfo(info){
    vm.info = info;
    vm.info.eventDate = {
        dateTime: new moment.utc(vm.info.eventDate),
        timeZone: getTimezone()
    };
    vm.info.cmpStartDate = {
      dateTime: vm.info.cmpStartDate ? new moment.utc(vm.info.cmpStartDate) : undefined,
      timeZone: getTimezone()
    };

   vm.info.cmpEndDate = {
     dateTime: vm.info.cmpEndDate ? new moment.utc(vm.info.cmpEndDate) : undefined,
     timeZone: getTimezone()
   };
    vm.onInactiveCampaignInstance = vm.campaignInstanceId && vm.info.archived;
    if (vm.info.isReplicable) {
        vm.info.editableAtEnd = vm.info.urls.subdomainIsPath == undefined
          ? vm.info.groupUrl.charAt(0) != '.'
          : vm.info.urls.subdomainIsPath;
        vm.info.groupUrl = vm.info.editableAtEnd ? vm.info.groupUrl + '/' : vm.info.groupUrl;
    }
    vm.info.settingsForm && vm.info.settingsForm.fields && vm.info.settingsForm.fields.forEach((field) => {
        field.isCustom = true;
        fields.push(field);
        if(field.type == formFieldTypes.Date && field.value) {//fieldType date
        field.value = new Date(field.value);
        }
        field.previousValue = angular.copy(field.value);
    });
    vm.settingsForm = vm.info.settingsForm;
    vm.previousInfo = angular.copy(vm.info);
    vm.eventSeriesEnabled = info.settings.EventSeriesEnabled;
    if(vm.eventSeriesEnabled) {
      $scope.$watch(function() {
        return vm.editFlag.eventSeriesID;
      }, function(newValue, oldValue) {
          if (newValue === true) {
            if (vm.info.eventSeries) {
              vm.info.eventSeriesID = vm.info.eventSeries.EventSeriesID;
            } else {
              vm.info.eventSeriesID = null;
            }
            orgService.getAvailableEventSeries().then( response => {
              vm.availableEventSeries = response;
            });
          }
      });
    }
  }

  function updateEventSeriesDescription() {
    if(vm.availableEventSeries !== 'undefined') {
      vm.info.eventSeries = vm.availableEventSeries[vm.info.eventSeriesID];
    }
  }

  function getTimezone(){
    return {
      display: vm.info.ianaTimeZone.replace('/', ' - ').replace('_', ' '),
      value: vm.info.ianaTimeZone,
  };
  }

  function delistCampaign() {
    vm.delistInProgress = true;
    campaignService.delistCampaign(vm.campaign.entityId, !vm.campaign.delisted)
      .then(response => {
      vm.campaign.delisted = !vm.campaign.delisted;
      }).finally(() => vm.delistInProgress = false);
  }

  function archiveCampaign(recurringPlanDestination) {
    vm.loaders.archive = true;
    campaignService.archiveCampaign(vm.campaign.entityId, Boolean(vm.liveDonationRecurringCount), recurringPlanDestination)
      .then(response => {
        vm.campaign.archived = !vm.campaign.archived;
        vm.campaign.delisted = true;
        entityService.refreshEntityAndComponent(vm.campaign.entityId);
      }).finally(() => {
        vm.liveDonationRecurringCount = null;
        vm.loaders.archive = false;
      });
  }

  function relaunchCampaign(recurringPlanDestination) {

    vm.loaders.relaunch = true;

    function advancedErrors(currentMessageService, response) {
        currentMessageService.showErrorToast(response.data.errors[0].error)
    }

    let messageConfig = {
      successToastEnabled: true,
      successMsg: 'Relaunch successful!',
      advancedErrorFunction: advancedErrors
    };
    campaignService.relaunchCampaign([vm.campaign.entityId], Boolean(vm.liveDonationRecurringCount), recurringPlanDestination, messageConfig)
      .then(response => {

        let data = response.data[0];

        //update admin url in reason of new eventId
        if (data.archived || !data.eventHostReal) {
          data.adminUrl = data.curEventId;
        } else {
          data.adminUrl = data.eventHostReal.replace("/", "~");
        }

        vm.campaign = data;
        updateInfo(vm.campaign);
      })
      .finally(() => {
        vm.liveDonationRecurringCount = null;
        vm.loaders.relaunch = false;
      });
  }


  function replicateCampaign() {
    $rootScope.$emit('replicate', vm.campaign);
  }

  function unarchiveClick() {
    if (vm.loaders.unarchive) {
      return;
    }
    vm.loaders.unarchive = true;
    let eventGroupHost = vm.campaign.groupUrl;
    $rootScope.$emit('unarchive', { eventGroupHost });

    vm.loaders.unarchive = false;
  }

    function archiveOrRelaunchInvalid(currentMessageService, response, endClickEvent, endType){
        response = response.data;
        if(response.liveDonationRecurringCount && (response.destinationOptions || endType === 'relaunch')){
            showMoveRecurrigModal(response, endClickEvent, endType);
        } else {
            showUnableToEndModal();
        }
    }

    function archiveOrRelaunchClick(endClickEvent, endType){
        if(vm.loaders.relaunch || vm.loaders.archive){
            return;
        }
        vm.loaders[endType] = true;

        let messageConfig = {
            advancedErrorFunction: (currentMessageService, response) => archiveOrRelaunchInvalid(currentMessageService, response, endClickEvent, endType),
            advancedErrorEnabled: true
        };

        campaignService.validateArchiveOrRelaunchCmp(vm.campaign.entityId, messageConfig)
            .then(response => {
                vm.hasActiveFacebookFundraiser = response.hasActiveFacebookFundraiser;
                endType === 'relaunch' ? showRelaunchConfirm(endClickEvent) : showArchiveConfirm(endClickEvent);
            }) //validation errors handled in message service advancedErrorFunction
            .finally(() => {
                vm.loaders[endType] = false;
            });
    }

    function showMoveRecurrigModal(response, endClickEvent, endType){
        vm.liveDonationRecurringCount = response.liveDonationRecurringCount;
        vm.destinationOptions = response.destinationOptions;
        vm.hasActiveFacebookFundraiser = response.hasActiveFacebookFundraiser;
        vm.moveTrigger = endType;
        vm.targetEvent = endClickEvent;
        vm.confirmationCallback = endType === 'relaunch' ? showRelaunchConfirm : showArchiveConfirm;
        vm.showModalMoveRecurring = true;
    }

    function showUnableToEndModal(){
        messageService.showErrorDialog(`Campaigns cannot be ended while they have active recurring donation plans or donation matches. <br>Please end your active recurring plan(s) and/or match(es), after which you will be able to end the selected campaign(s).`)
    }

  function showRelaunchConfirm(endClickEvent, recurringPlanDestination) {
    
    let unendedPlansMsg = ``;
    if(vm.liveDonationRecurringCount){
        let recurringDestinationStr = recurringPlanDestination ? recurringPlanDestination.eventName : `the newly relaunched campaign`;
        unendedPlansMsg = `<br><br>${vm.liveDonationRecurringCount} active recurring plan(s) will be transferred to ${recurringDestinationStr}.`;
    }

    let unendedFacebookPlansMsg = ``;
    if (vm.hasActiveFacebookFundraiser) {
        unendedFacebookPlansMsg = ` Additionally, all active Facebook Fundraiser pages will be ended.`;
    }

    let confirm = $mdDialog.confirm()
      .clickOutsideToClose(true)
      .title('Are you sure?')
      .htmlContent(`This will end your current campaign and relaunch a new iteration of it on the same URL with all the same information.<br><br>Reports on the ended campaign will still be available.${unendedFacebookPlansMsg}${unendedPlansMsg}<br><br>This action cannot be undone.`)
      .openFrom(endClickEvent.currentTarget)
      .closeTo(endClickEvent.currentTarget)
      .ok('Relaunch')
      .cancel('Cancel');

    $mdDialog.show(confirm).then(() => relaunchCampaign(recurringPlanDestination));
  };

  function showArchiveConfirm(endClickEvent, recurringPlanDestination) {
    
    let unendedFacebookPlansMsg = vm.hasActiveFacebookFundraiser ? ` Additionally, all active Facebook Fundraiser pages will be ended.` : ``;
    
    let unendedPlansMsg = vm.liveDonationRecurringCount ? `<br><br>${vm.liveDonationRecurringCount} active recurring plan(s) will be transferred to ${recurringPlanDestination.eventName}.` : ``;
    
    // Ending the Facebook Fundraiser pages and transferring recurring plans are not reversible even by reactivating the campaign.
    let nonReversibleMsg = (vm.hasActiveFacebookFundraiser || vm.liveDonationRecurringCount) ? `<br><br>This action cannot be undone.` : ``;
    
    let confirm = $mdDialog.confirm()
      .clickOutsideToClose(true)
      .title('Are you sure?')
      .htmlContent(`This will end your campaign, deactivating the website and admin panel. Admin reports will still be accessible.${unendedPlansMsg}${unendedFacebookPlansMsg}${nonReversibleMsg}`)
      .openFrom(endClickEvent.currentTarget)
      .closeTo(endClickEvent.currentTarget)
      .ok('End Campaign')
      .cancel('Cancel');

    $mdDialog.show(confirm).then(() => archiveCampaign(recurringPlanDestination));
  }

  function showDelistConfirm(event) {

      //if vm.campaign.delisted that means it is being published now
      if(vm.campaign.delisted) {
          delistCampaign();
          return;
      }

      let confirm = $mdDialog.confirm()
        .clickOutsideToClose(true)
        .title('Are you sure?')
        .htmlContent(`This will delist your campaign, removing it from appearing on the platform.`)
        .targetEvent(event)
        .ok('Delist')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(() => delistCampaign());
  }


  /* Editing */
  

  vm.editFlag = {};
  vm.saveInProgress = {};

  vm.addUrl = addUrl;
  vm.removeHost = removeHost;
  vm.areUrlsEditable = areUrlsEditable;
  vm.updateDefaultUrl = updateDefaultUrl;

  vm.addWhitelistedUrl = addWhitelistedUrl;
  vm.removeWhitelistedUrl = removeWhitelistedUrl;
  
  vm.fieldClicked = fieldClicked;
  vm.saveEdit = saveEdit;
  vm.cancelEdit = cancelEdit;
  vm.cancelEditCustomField = cancelEditCustomField;
  vm.offEdits = offEdits;
  vm.checkHotKey = checkHotKey;
  vm.saveCustomFieldEdit = saveCustomFieldEdit;
  vm.showCampaignDates = false;
  
  let fields = [{id: 'name'}, {id: 'slang'}, {id: 'description'}, {id: 'goalAmount'}, {id: 'eventDate'}, {id: 'cmpStartDate'}, {id: 'cmpEndDate'}, {id: 'eventYear'}, {id: 'location'}];

  function refocusField(fieldId){
      vm.editFlag[fieldId] = 'refocus';
      $timeout(() => {
          vm.editFlag[fieldId] = true;
      });
  }

  function getEditableUrl(fullUrl) {
      return (fullUrl || '').replace(vm.info.groupUrl, '')
      }

  function areUrlsEditable() {
    return vm.info && vm.info.isReplicable && vm.campaign && !vm.campaign.archived;
  }

  function addUrl(url) {
    if (vm.saveInProgress.url){
      return $q.reject("existing url save currently in progress");
    }

    vm.saveInProgress.url = true;
    const subdomain = getEditableUrl(url);

    let messageConfig = {
      advancedErrorFunction: messageService.advancedErrorsDialog,
      advancedErrorEnabled: true
    };

    return validateUrl(subdomain)
    .catch(error => {
      messageService.showErrorToast(error);
      return $q.reject();
    })
    .then(subdomain => campaignService.addCampaignUrl(vm.info.entityId, subdomain, messageConfig))
    .then(response => response.data)
    .then(urls => vm.info.urls = urls)
    .finally(() => {
      vm.showSecondaryUrls = true;
      vm.saveInProgress.url = false
    });
  }

  function updateDefaultUrl(url) {
    let confirm = $mdDialog.confirm()
    .clickOutsideToClose(true)
    .title('Are you sure?')
    .htmlContent(`You are changing the primary URL of this campaign.`)
    .ok('Change Primary URL')
    .cancel('Cancel');

    if (vm.saveInProgress.url){
      return $q.reject("existing url save currently in progress");
    }
    vm.saveInProgress.url = true;

    let messageConfig = {
      advancedErrorFunction: messageService.advancedErrorsDialog,
      advancedErrorEnabled: true
    };

    return $mdDialog.show(confirm)
    .then(() => campaignService.updateCampaignUrl(vm.info.entityId, url, messageConfig))
    .then(response => response.data)
    .then(urls => vm.info.urls = urls)
    .finally(() => {
      vm.saveInProgress.url = false
    });
  }

  function removeHost(host) {
    if (host.hadUnsafeRedirect) {
      const confirm = $mdDialog.confirm()
        .clickOutsideToClose(true)
        .title('Warning')
        .htmlContent('Users who have already visited this site using this URL will continue to be directed to this site when entering this URL, even after it has been removed, until the user’s browser cache is cleared. Click “I understand” below to continue with removing this URL.')
        .ok('I understand')
        .cancel('Cancel');
      return $mdDialog.show(confirm)
        .then(() => removeUrl(host.url, 'This URL will no longer direct new users to this campaign site.'));
    }
    else {
      return removeUrl(host.url);
    }
  }

  function removeUrl(url, message) {
    if (vm.saveInProgress.url){
      return $q.reject("existing url save currently in progress");
    }
    vm.saveInProgress.url = true;

    let confirm = $mdDialog.confirm()
      .clickOutsideToClose(true)
      .title('Are you sure?')
      .htmlContent(message || 'This campaign will no longer be accessible at this URL.')
      .ok('Remove')
      .cancel('Cancel');

    let messageConfig = {
      advancedErrorFunction: messageService.advancedErrorsDialog,
      advancedErrorEnabled: true
    };

    return $mdDialog.show(confirm)
    .then(() => campaignService.removeCampaignUrl(vm.info.entityId, url, messageConfig))
    .then(response => response.data)
    .then(urls => vm.info.urls = urls)
    .finally(() => {
      vm.saveInProgress.url = false
    });
  }

  /**
   * Validates subdomain and returns full path
   * @param {string} path subdomain or path to validate
   */
  function validateUrl(path) {
    // validate for no . or /
    let regex = /.*(\.|\/|\s).*/;
    if (path.match(regex)) {
      return $q.reject('URL cannot contain / . or whitespace characters ')
    }

    const fullUrl = getFullUrl(path);

    if (!vm.info.editableAtEnd) {
      //taken from http://stackoverflow.com/questions/9208814/validate-ipv4-ipv6-and-hostname/9221063#9221063
      regex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/;
      if (!regex.test(String(fullUrl))) {
        return $q.reject('Please only enter valid URL characters');
      }
    } else {
      regex = /^[a-zA-Z0-9-]*$/;
      if(!String(path).match(regex)){
          return $q.reject('Only letters, numbers, and hyphens are valid for the url path.');
      }
    }

    return $q.resolve(path);
  }

  function getFullUrl(editableUrl){
    if (!vm.info.isReplicable) {
        return vm.info.urls.primary;
    }
    return vm.info.editableAtEnd ? vm.info.groupUrl + editableUrl : editableUrl + vm.info.groupUrl;
  }

  function fieldClicked(fieldId, $event) {
      if (vm.editFlag[fieldId]) return;
      offEdits(fieldId)
          .then(() => enableEditField(fieldId));

      // $event.stopPropagation();
  }

  function offEdits(fieldId) {
      let field = fields.find(field => field.id != fieldId && vm.editFlag[field.id]);
      if (!field) return  $q.resolve();

      if(field.isCustom){
          return saveCustomFieldEdit(field);
      } else {
          return saveEdit(field.id);
      }
  }

  function enableEditField(fieldId) {
      if (!vm.canEdit)
      {
        return false;
      }

      vm.editFlag[fieldId] = true;
      return true;
  }

    function disableEditField(fieldId){
        vm.editFlag[fieldId] = false;
    }

    function onFieldSaved(fieldId) {
      if(fieldId == 'eventSeriesID') {
        updateEventSeriesDescription();
      }
    }

  function saveEditField(fieldId){
      let field = fields.find(field => field.id == fieldId);
      if(field.isCustom){
          return saveCustomFieldEdit(field);
      } else {
          return saveEdit(field.id);
      }
  }

  function cancelEditField(fieldId){
      let field = fields.find(field => field.id == fieldId);
      if(field.isCustom){
          return cancelEditCustomField(field);
      } else {
          return cancelEdit(field.id);
      }
  }

  function saveEdit(fieldId) {
      if(vm.saveInProgress[fieldId]) {
          return $q.reject();
      }
      vm.saveInProgress[fieldId] = true;

      return saveProcess()
          .finally(() => vm.saveInProgress[fieldId] = false);

      function saveProcess(){
          if (angular.equals(vm.info[fieldId], vm.previousInfo[fieldId])) {
              disableEditField(fieldId);
              return $q.resolve();
          }

          return beginSave(fieldId)
              .then(values => {
                  return completeSave(fieldId, values)
                      .then(saveSuccess)
              })
              .catch(saveFailure);
      }

      function saveSuccess(){
          vm.previousInfo = angular.copy(vm.info);
          disableEditField(fieldId);
          onFieldSaved(fieldId);
          return $q.resolve();
      }

      function saveFailure(){
          refocusField(fieldId);
          return $q.reject();
      }
  }

  function beginSave(fieldId){
      return validateSave(fieldId)
          .catch(error => {
              messageService.showErrorToast(error);
              return $q.reject();
          })
  }

  function validateSave(fieldId){
      if (fieldId == 'goalAmount') {

          //validate for non-letters values;
          let regex=/^[a-zA-Z]+$/;
          if(String(vm.info.goalAmount).match(regex)){
              return $q.reject('Please enter valid goal amount');
          }

          //validate for positive values;
          if(vm.info.goalAmount <= 0){
              return $q.reject('Please enter positive goal amount');
          }
      }

      if (fieldId == 'eventDate') {
          if (!vm.info[fieldId] || !vm.info[fieldId].dateTime || !vm.info.eventDate.timeZone) {
              return $q.reject('Please enter valid date');
          }
          
          return $q.resolve([vm.info[fieldId].dateTime, vm.info.eventDate.timeZone.value]);
      }

      if(fieldId == 'cmpStartDate' || fieldId == 'cmpEndDate'){
        return $q.resolve([vm.info[fieldId].dateTime, ""]);
      }

      if (fieldId == 'name') {
          $rootScope.$emit('campaign.name.changed', vm.info[fieldId]);
      }

      if (fieldId == 'location') {
          var deferred = $q.defer();
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address: vm.info.location }, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                  var newlocation = results[0].geometry.location;
                  vm.info.locValues = [vm.info.location];
                  vm.info.locValues[1] = newlocation.lat();
                  vm.info.locValues[2] = newlocation.lng();
                  var stateCountry = getGeocodeStateCountry(results[0]);
                  vm.info.locValues[3] = stateCountry.state;
                  vm.info.locValues[4] = stateCountry.country;
                  
              } else {
                  vm.info.locValues = [vm.info.location];
              }
              deferred.resolve(vm.info.locValues);
          });
          return deferred.promise;
      }

      let values = [vm.info[fieldId]];
      return $q.resolve(values);
  }

    function getGeocodeStateCountry(data) {
        let loc = {};
        data.address_components.forEach(function(addr) {
            if (addr.types.includes("administrative_area_level_1")) {
                loc.state = addr.short_name;
            } else if (addr.types.includes("country")) {
                loc.country = addr.short_name;
            }
        });
        return loc;
    }
  
  function completeSave(field, values){
      let messageConfig = {
          advancedErrorFunction: messageService.advancedErrorsDialog,
          advancedErrorEnabled: true
      };

      return campaignService.saveEditCampaign(vm.info.entityId, field, values, messageConfig);
  }

  function saveCustomFieldEdit(field) {
    if(vm.saveInProgress[field.id]) {
      return $q.reject();
    }
    vm.saveInProgress[field.id] = true;

    if (angular.equals(field.value, field.previousValue)){
        vm.editFlag[field.id] = false;
        vm.saveInProgress[field.id] = false;
        return $q.resolve();
    }

    let value = [];
    if(field.type == vm.formFieldTypes.Select) {
      field.options.forEach((option) => {
        if(option.label == field.value && value.length <= 0) {
          value.push(option.id);
        }
      });
    } else if(field.type == vm.formFieldTypes.Multiselect) {
      if (field.value) {
        field.value.forEach((multiSelectValue) => {
          field.options.forEach((option) => {
            if(option.label == multiSelectValue) {
              value.push(option.id);
            }
          })
        })
      }
    } else if(field.type == vm.formFieldTypes.Date) {
      value.push(field.value ? field.value.toLocaleDateString() : field.value);
    } else {
      value.push(field.value);
    }

    return campaignService.saveEditCampaignCustomField(vm.info.entityId, field.id, value.join(',')).then(res => {
      field.previousValue = angular.copy(field.value);
      vm.editFlag[field.id] = false;
    }).catch(error => {
      refocusField(field.id);
      return $q.reject();
    }).finally(() => vm.saveInProgress[field.id] = false)
  }

  
  function cancelEdit(fieldId) {
    vm.info[fieldId] = angular.copy(vm.previousInfo[fieldId]);
      disableEditField(fieldId);
  }

  function cancelEditCustomField(field) {
    if(field.type == formFieldTypes.Multiselect) {
      field.value = [];
      if(field.previousValue){
        field.previousValue.forEach((value) => {
          field.value.push(value);
        });
      }
    } else {
      field.value = field.previousValue;
    }

    vm.editFlag[field.id] = false;
  }

  const ESC = 27,
        ENTER = 13,
        TAB = 9;

  function checkHotKey(event, fieldId) {

    let key = event.keyCode;

    if (key === ESC) cancelEditField(fieldId);

    if (key === ENTER) {
        //TODO: Some custom fields don't work with enter well.
        event.preventDefault();
        event.stopPropagation();
        saveEditField(fieldId);
    }

    if (key === TAB) {

      event.preventDefault();

      let curIndex = fields.findIndex(item => item.id == fieldId);

      saveEditField(fieldId).then(() => {
          let max = fields.length - 1;
          let min = 0;

          let fieldEnabled = false;
          let startIndex = curIndex;

          if (event.shiftKey) {

              while (!fieldEnabled/* && curIndex != startIndex*/){
                  curIndex--;
                  if (curIndex < min) curIndex = max;
                  fieldEnabled = enableEditField(fields[curIndex].id);
              }

          } else {

              while (!fieldEnabled/* && curIndex != startIndex*/){
                  curIndex++;
                  if (curIndex > max) curIndex = min;
                  fieldEnabled = enableEditField(fields[curIndex].id);
              }
          }
      });
    }
  }

  //image upload

  $scope.onUploadFile = onUploadFile;
  $scope.onDropFile = onDropFile;
  $scope.allowDrop = allowDrop;
  vm.fileUploadInput = null;
  
  function allowDrop(event){
    event.preventDefault();
    event.stopPropagation();
  }

  function addOverlay(){
     document.querySelector('.fileInput-placeholder').style.zIndex = '1';
  }

  function removeOverlay(){
     document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
  }

  function onDropFile (event){
    event.preventDefault();
    if (!vm.canEdit) {
      return;
    }
    document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
    let file = event.dataTransfer.files[0];

    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = event => {
    
      $rootScope.$emit('file.uploaded', {
        img: event.target.result,
        entityId: vm.info.entityId,
        imgType: 'campaign'
      });
    }
  }

  function onUploadFile(event) {
    fileUpload.onUploadFile(event, vm.info.entityId, 'campaign');
  }

  $rootScope.$on('file.approved', (event, data) => {

    let {entityId, smallImage, largeImage} = data;

    campaignService.updateRawCampaigns({
      entityId,
      smallImage,
      largeImage
    })
    
  });

    function addWhitelistedUrl(url) {
        if (vm.saveInProgress.whitelistedUrl){
            return $q.reject("existing whitelist url save currently in progress");
        }

        vm.saveInProgress.whitelistedUrl = true;

        let messageConfig = {
            advancedErrorFunction: messageService.showErrorDialogWithFirstError,
            advancedErrorEnabled: true
        };

        return campaignService.addCampaignWhitelistedUrl(vm.info.entityId, url, messageConfig)
            .then(response => response.data)
            .then(urls => vm.info.whitelistedUrls = urls)
            .finally(() => {
                vm.saveInProgress.whitelistedUrl = false
            });
    }

    function removeWhitelistedUrl(url) {
        if (vm.saveInProgress.whitelistedUrl){
            return $q.reject("existing whitelist url save currently in progress");
        }
        vm.saveInProgress.whitelistedUrl = true;

        let confirm = $mdDialog.confirm()
            .clickOutsideToClose(true)
            .title('Are you sure?')
            .htmlContent('This campaign will no longer be embeddable at this URL.')
            .ok('Remove')
            .cancel('Cancel');

        let messageConfig = {
            advancedErrorFunction: messageService.showErrorDialogWithFirstError,
            advancedErrorEnabled: true
        };

        return $mdDialog.show(confirm)
            .then(() => campaignService.removeCampaignWhitelistedUrl(vm.info.entityId, url, messageConfig))
            .then(response => response.data)
            .then(urls => vm.info.whitelistedUrls = urls)
            .finally(() => {
                vm.saveInProgress.whitelistedUrl = false
            });
    }
}

