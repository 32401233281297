angular
  .module('app')
  .component('sentAdminEmail', {
    controller: sentAdminEmailController,
    bindings: { 
      $router: '<'
    },
    require: {
      parentComponent: '^email'
    },
    template: `<div ag-grid="vm.grid" class="ag-theme-platform grid"></div>`,
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function sentAdminEmailController($scope, agGridService, emailService) {
    const vm = this;
    
    vm.openRow = undefined;

    var columnDefs = [
        {
            field: 'DisplayName',
            headerName: 'Template Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'Subject',
            headerName: 'Email Subject',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'ScheduledDeliveryDate',
            headerName: 'Sent',
            filter: 'agDateColumnFilter',
            valueFormatter: agGridService.formatters.dateTime,
            filterParams: {
                filterOptions: ['equals', 'greaterThanOrEqual', 'lessThanOrEqual'],
            },
        },
    ]
    
    vm.setupStatsGraph = function(stats) {
        agCharts.AgChart.create({
            theme: 'material',
            container: document.getElementById('emailStatsSentChartContainer'),
            data: [
                { label: 'Delivered', value: stats.DeliveredCount },
                { label: 'Suppressed', value: stats.InvalidCount },
            ],
            legend: {
                enabled: false,
            },
            series: [
                {
                    type: 'pie',
                    angleKey: 'value',
                    labelKey: 'label',
                    innerRadiusOffset: -35,
                    fills: ['#00698C', '#66A5BA'],
                    strokeWidth: 0,
                    label: {
                        enabled: false,
                    },
                    highlightStyle: {
                        fill: '#ff0041',
                    },
                    tooltip: {
                        renderer: (params) => '<div class="ag-chart-tooltip-title" ' + 'style="background-color:' + params.color + '">' + params.datum[params.labelKey] + '</div>' + '<div class="ag-chart-tooltip-content">' + params.angleValue + '</div>',
                    }
                },
            ],
        });
    }

    vm.loadRow = function(params) {
        emailService.getEmailStats(params.data.BlastID)
            .then(r => {
                vm.grid.openRow.data.stats = r.emailStats
                vm.setupStatsGraph(r.emailStats);
            })
            .finally(r => vm.grid.openRow.data.statsLoaded = true)
            
        emailService.getRecipients(params.data.BlastID)
            .then(r => {
                if (!vm.grid.openRow.data) {
                    vm.grid.openRow.data = {};
                }
                vm.grid.openRow.data.recipients = {};
                vm.grid.openRow.data.recipients.members = r.items[0].Members;
                vm.grid.openRow.data.recipients.contacts = r.items[0].Contacts;
                vm.grid.openRow.data.recipientsLoaded = true;
            })
    }
    
    vm.grid = agGridService.createNewGrid(columnDefs, {
        domLayout: 'autoHeight',
        sideBar: false,
        key: 'sentAdminEmail',
        expandedCardTemplate: 'sentAdminEmailRecord',
        noRowsMessage: 'Sent emails will show up here.',
        datasource: agGridService.getDatasource('Email', 'SentBlasts'),
        defaultColDef: {
            flex: 1,
            minWidth: 160,
        },
    }, {
        onRowGroupOpened: vm.loadRow,
    });
    
}