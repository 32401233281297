angular.module('app').component('formSingleField', {
  templateUrl: 'form-single-field.tpl.html',
  controllerAs: 'vm',
  controller: formSingleFieldController,
  bindings: {
    field: '<',
    id: '<',
    item: '<',
    editorButtons: '<',
    editable: '<',
    groupIndex: '<',
    groupForm: '<',
    parentFieldPath: '<',
    modalActions: '=?',
    onChange: '<',
  }
});

function formSingleFieldController($q, formService) {
  const vm = this;
  vm.initComplete = false;
  
  vm.isFieldVisible = function() {
    return !vm.field.displayOptions.hidden && !vm.fieldHide() && !vm.fieldConditionShouldHide();
  }
  
  vm.fieldHide = function() {
    return vm.field.hide && vm.field.hide(vm.item);
  }
  
  vm.fieldEditable = function() {
    return !vm.field.isReadonly && vm.editable;
  }
  
  vm.fieldAppend = function() {
    return vm.field.displayOptions.labels.append || vm.fieldLabelAppendCondition();
  }

  vm.fieldPrepend = function() {
    return vm.field.displayOptions.labels.prepend || vm.fieldLabelPrependCondition();
  }

  vm.fieldDisplayName = function() {
    return vm.field.displayOptions.labels.alternative || vm.field.name;
  }
  
  vm.fieldValidationName = function() {
    return vm.field.displayOptions.labels.validation || vm.fieldDisplayName();
  }
  
  vm.fieldClass = function() {
    return `field-type-${vm.field.fieldType} field-name-${_.kebabCase(vm.field.name)}`;
  }

  vm.fieldContainerClass = function(field) {
    return `field-container-type-${field.fieldType} field-container-name-${_.kebabCase(field.name)}`;
  }

  vm.formGroupContainerClass = function(formGroup) {
    return `form-single-inner form-tab-container-${_.kebabCase(formGroup.name)}`;
  }
  
  vm.fieldRequired = function() {
    return vm.field.required;
  }

  vm.filteredOptions = function(options, searchText, groupName) {
    if (searchText === undefined || searchText.trim() === '') {
      return options;
    }
    
    searchText = searchText.trim().toLowerCase();
    
    if (groupName && matchesSearchText(groupName, searchText)) {
      return options;
    }
    
    return _.pickBy(options, (option, key) => matchesSearchText(option, searchText));
  }

  vm.filteredGroupOptions = function(groups, searchText) {
    
    if (searchText === undefined || searchText.trim() === '') {
      return groups;
    }
    
    searchText = searchText.trim().toLowerCase();
    
    return _.filter(groups, group => {
      if (matchesSearchText(group.name, searchText)) {
        return group;
      }
      
      var matching = _.filter(group.options, option => matchesSearchText(option, searchText));
      return Object.keys(matching).length > 0;
    });
  }
  
  function matchesSearchText(text, search) {
    return text.toLowerCase().search(search) !== -1;
  }
  
  vm.fieldLabelPrependCondition = function(item) {
    if (!item) {
      item = vm.item;
    }

    if (!vm.field || !vm.field.displayOptions || !vm.field.displayOptions.labels || !vm.field.displayOptions.labels.prependConditions) {
      return '';
    }
    let prependItem = vm.field.displayOptions.labels.prependConditions.find(condition => {
      let field = formService.getFieldOrSubFieldFromItem(item, condition.field, condition.subField);
      if (!field) {
        return false;
      }

      let valuesMatch = vm.conditionValuesMatch(condition, field);

      return condition.not ? !valuesMatch : valuesMatch;
    });

    if (!prependItem) return '';

    return prependItem.text;
  }

  vm.fieldLabelAppendCondition = function(item) {
    if (!item) {
      item = vm.item;
    }

    if (!vm.field || !vm.field.displayOptions || !vm.field.displayOptions.labels || !vm.field.displayOptions.labels.appendConditions) {
      return '';
    }
    let appendItem = vm.field.displayOptions.labels.appendConditions.find(condition => {
      let field = formService.getFieldOrSubFieldFromItem(item, condition.field, condition.subField);
      if (!field) {
        return false;
      }

      let valuesMatch = vm.conditionValuesMatch(condition, field);

      return condition.not ? !valuesMatch : valuesMatch;
    });

    if (!appendItem) return '';

    return appendItem.text;
  }

  vm.fieldConditionShouldHide = function(item) {
    if (!item) {
      item = vm.item;
    }
    
    if (!vm.field || !vm.field.displayOptions || !vm.field.displayOptions.conditions)
      return false;
    
    let shouldShow = conditionCheckPredicate(vm.field.displayOptions.anyConditionMatches)(vm.field.displayOptions.conditions, condition => {
      let field = formService.getFieldOrSubFieldFromItem(item, condition.field, condition.subField);
      
      if (!field) {
        return true;
      }
      
      if (field.fieldConditionShouldHide && field.fieldConditionShouldHide()) {
        return false;
      }
      
      let valuesMatch = vm.conditionValuesMatch(condition, field);
      
      return condition.not ? !valuesMatch : valuesMatch;
    });
    
    return !shouldShow;
  }
  
  function conditionCheckPredicate(anyConditionMatches) {
    return anyConditionMatches ? _.some : _.every;
  }
  
  vm.conditionValuesMatch = function(condition, field) {
    if (Array.isArray(field.value)) {
      return field.value.includes(condition.value);
    }
    
    if (field.fieldType == 'Checkbox') {
      return !!field.value;
    }
    
    return condition.value == field.value
  }
  
  vm.selectedOptionMetadata = function() {
    if (!vm.field || !vm.field.optionsMetadata || !vm.field.value)
      return {};
    
    return vm.field.optionsMetadata[vm.field.value];
  }
  
  vm.clearValue = function() {
    vm.previousValue = '';
    vm.field.value = '';
  }
  
  vm.addChangeCallback = function(callback) {
    if (!callback || typeof callback !== 'function') {
      return;
    }
    
    if (!vm.field.changeCallbacks) {
      vm.field.changeCallbacks = [];
    }
    
    vm.field.changeCallbacks.push(callback);
  }
  
  vm.fieldPath = function() {
    let path = (vm.parentFieldPath && vm.parentFieldPath()) || [];
    path.push(vm.field.name);
    return path;
  }
  
  vm.textareaName = function() {
    return vm.field.name + '-' +vm.id;
  }
  
  vm.fieldChange = function(fromPrefill) {
    
    let fieldName = vm.field.fieldType == 'Textarea' ? vm.textareaName() : vm.fieldValidationName();
    let formField = vm.groupForm && vm.groupForm[fieldName]
    if (formField && formField.$valid) {
      vm.field.displayError = undefined;
    }
    
    if (vm.field.changeCallbacks) {
      _.forEach(vm.field.changeCallbacks, callback => callback(vm.item, vm.field.value, vm.field, vm.previousValue, fromPrefill))
    }

    // Storing previous value so that it can be sent along with the next change event
    vm.previousValue = vm.field.value;
  }
  
  vm.addPrefillOptions = function(prefillData, fieldsToUpdate, showOverrideWarning, findItemFunc) {
    function fieldValueChanged(fieldName, currentItemFields, prefillItemFieldValues) {
      return currentItemFields[fieldName] && currentItemFields[fieldName].value && currentItemFields[fieldName].value != prefillItemFieldValues[fieldName];
    }

    function overrideWarning(isOverriding) {
      if (isOverriding && showOverrideWarning) {
        return showOverrideWarning();
      }
      return $q.resolve();
    }

    // When any of the fields updated by this prefill are changed, clear the prefill field selection
    _.forEach(fieldsToUpdate, (fieldName) => {
      let field = formService.getFieldFromItem(vm.item, fieldName);
      if (field && field.addChangeCallback) {
        field.addChangeCallback((item, value, field, previousValue, fromPrefill) => {
          if (!fromPrefill) vm.field.clearValue();
        });
      }
    })

    vm.field.addChangeCallback((item, value, field, previousValue, fromPrefill) => {
      if (fromPrefill) return;
      
      let prefillItem = findItemFunc(prefillData, value);
      if (!prefillItem) return;

      let currentItemFields = {};
      let prefillItemFieldValues = {};
      let isOverriding = false;

      _.forEach(fieldsToUpdate, (fieldName) => {
        prefillItemFieldValues[fieldName] = formService.getFieldValueFromItem(prefillItem, fieldName);
        let currentField = currentItemFields[fieldName] = formService.getFieldFromItem(vm.item, fieldName);

        if (!currentField) {
          return;
        }

        currentItemFields[fieldName] = currentField;
        let isBoolField = currentField.fieldType === 'Checkbox';

        if (!previousValue && !isBoolField && fieldValueChanged(fieldName, currentItemFields, prefillItemFieldValues)) {
          isOverriding = true;
        }
      })

      overrideWarning(isOverriding).then(() => {
        _.forEach(fieldsToUpdate, (fieldName) => {
          
          let field = currentItemFields[fieldName];
          let value = prefillItemFieldValues[fieldName];
          
          if (field && !field.isReadonly) {
            field.value = value;
            if (field.fieldChange) {
              field.fieldChange(true);
            }
          }
        })
      }, () => {
        vm.field.clearValue();
      });
    });
  }
  
  function addDefaultChangeCallbacks() {
    if (vm.field.DefaultCallbacksAdded) return;

    // Field specific change callback
    vm.field.addChangeCallback(vm.field.change);

    // Form change callback (when any field on entire form is changed)
    vm.field.addChangeCallback(vm.onChange);
    
    vm.field.DefaultCallbacksAdded = true;
  }

  vm.$onInit = function() {
    if (vm.field.fieldType === 'DateTime' && vm.field.value) {
      vm.field.value = new Date(vm.field.value);
      vm.field.value.setSeconds(0,0);
    }

    vm.previousValue = vm.field.value;
    
    // Attach functions to field so that they can be called externally
    vm.field.clearValue = vm.clearValue;
    vm.field.addPrefillOptions = vm.addPrefillOptions;
    vm.field.addChangeCallback = vm.addChangeCallback;
    vm.field.fieldChange = vm.fieldChange;
    vm.field.fieldConditionShouldHide = vm.fieldConditionShouldHide;
    vm.field.fieldLabelAppendCondition = vm.fieldLabelAppendCondition;
    vm.field.fieldLabelPrependCondition = vm.fieldLabelPrependCondition;
    vm.field.isVisible = vm.isFieldVisible;
    vm.field.fieldAppend = vm.fieldAppend;
    vm.field.fieldPrepend = vm.fieldPrepend;
    vm.field.selectedOptionMetadata = vm.selectedOptionMetadata;

    addDefaultChangeCallbacks();

    vm.initComplete = true;
  }
}
