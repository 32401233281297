angular
  .module('app')
  .directive('fieldValue', FieldValueController
      
  );

function FieldValueController($http, API_PREFIX, $timeout, orgService) {
    return {
        scope: {
            inputId: '<',
            value: '=',
            name: '<',
            isSaveActivated: '=',
            objectEventId: '=',
            entityId: '=',
            eventName: '=',
            displayedValue: '=',
            isAutocomplete: '=',
            isReadonly: '=',
            index: '=',
            isEditing: '=',
            edit: '&',
			fieldType: '=',
            recurring: '='
        },
        bindToController: true,
        controller: function(){
            const vm = this;
            vm.textColor = vm.name == 'Recipient' ? 'blackText' : 'grayText';
            vm.backgroundColor = vm.isReadonly ? 'whiteBackground' : 'grayBackground';
        },
        controllerAs: 'vm',

        templateUrl: 'field-value.tpl.html',
        link: function (scope, element, attrs, ctrl) {
            const vm = ctrl;
            vm.isAutocomplete;
            vm.recurring;
            vm.loadInProgress = false;
            vm.setEditing;
            vm.selectedRecipient = false;
            vm.recipients = [];
            vm.editField = editField;
			
			vm.inputFieldType = vm.fieldType || 'text';
			if(vm.isAutocomplete){
				vm.inputPlaceholder = 'Search ' + vm.name;
			}else{				
				vm.inputValue = vm.displayedValue.value;
			}
			
            var $autocompleteBar;

            var orgId = orgService.getOrgId();

            $.ajaxSetup({
                headers: {
                    orgId: orgId
                }
            });

            scope.$watch('vm.isEditing', function (newValue, oldValue) {
				if (vm.isAutocomplete) {
                    $autocompleteBar = $('#' + vm.inputId);
                    if (newValue) {
                        if (vm.recurring) {
                            getAutocompleteOptions('Cmp/GetValidRecurringDonationRecipients', undefined, vm.entityId);
                        } else {
                            getAutocompleteOptions('Donation/GetValidDonationRecipients', vm.objectEventId);
                        }
					} else {
                        vm.selectedRecipient = false;
                        vm.isSaveActivated = false;
                        $autocompleteBar.devbridgeAutocomplete('dispose');
                        $autocompleteBar.val("");
                        vm.recipients = [];
                    }
                }
            });

            function editField() {
                vm.selectedRecipient = false;
                $timeout(() => {
                    $autocompleteBar.focus();
                });
            }

            //change to be more generic for other autocompletes

            function getAutocompleteOptions(url, donationEventID, entityID) {
                vm.loadInProgress = true;
                var data = {
                    donationEventID
                };
                var headers = {
                    entityID
                };

                $http({ url: `${API_PREFIX}${url}`, headers: headers, data: data, method: 'POST' })
                .then(data => {
                    var response = data.data;
                    if(response && response.rtrn)
                    {
                        response.rtrn.forEach((group, i) => {
                            if (group.length > 0) {
                                vm.recipients[i] = $.map(group, function (item) {
                                    return {
                                        value: JSON.stringify(item),
                                        data: { type: item.type }
                                    };
                                });
                            }
                            else {
                                vm.recipients[i] = { suggestions: [] };
                            }
                        });
                    }
                    vm.filtered = [];
                    $autocompleteBar.on('input', function () {
                        //if user had chosen recipient but changed input text then deactivate save
                        vm.isSaveActivated = false;
                        var query = $(this).val(); 
                        vm.filtered = filterTo30(query, vm.recipients);
                        $autocompleteBar.devbridgeAutocomplete('setOptions', { lookup: vm.filtered });
                    });
                    initAutocomplete();
                    vm.loadInProgress = false;
                    $timeout(() => {
                        $autocompleteBar.focus();
                    });
                });
            }

            function initAutocomplete() {
                var initialOffsets = {};
                var setPositions = {};
                $autocompleteBar.devbridgeAutocomplete({
                    lookup: vm.filtered,
                    appendTo: $('#modal-container'),
                    groupBy: 'type',
                    zIndex: 9999,
                    forceFixPosition: true,
                    showNoSuggestionNotice: true,
                    preserveInput: true,
                    noSuggestionNotice: 'We haven\'t found anything for your search. Please try again.',
                    beforeRender: function (container) {
                        var groups = container.find('.autocomplete-group');
                        var thisOffsetTop = this.offsetTop;
                        groups.each(function (index, group) {
                            var $thisGroup = $(group);
                            var $nextGroup = $(groups[index + 1]);
                            $thisGroup.addClass('gray-text');
                            group.firstChild.innerText = group.firstChild.innerText + 's';
                            var groupDiv = $('<div class="autocomplete-group-inner ellipsis"></div>');
                            groupDiv.insertAfter(group);
                            var groupSuggestions = $(group).nextUntil('.autocomplete-group');
                            groupSuggestions.each(function (index, suggestion) {
                                if (suggestion.innerText) {
                                    var suggestionValue = JSON.parse(suggestion.innerText);
                                    $(suggestion).empty();
                                    var suggestionInner = createSuggestionInner(suggestionValue);
                                    suggestionInner.appendTo(suggestion);
                                }
                            });
                            groupSuggestions.appendTo(groupDiv);

                            var keepTypingMsgDiv = $('<div class="keep-typing-msg">Keep typing for more results...</div>');
                            keepTypingMsgDiv.insertAfter(groupDiv);
                            
                            setPositions[index] = function () {
                                var topMargin = 70;
                                var $initialOffset = initialOffsets[index];
                                var scrollTop = container.scrollTop();
                                var offsetTop = container.offset().top;
                                if (container.scrollTop() > ($initialOffset - topMargin)) {
                                    if (!$nextGroup.length || ($nextGroup[0].offsetTop) > (container.scrollTop() + $thisGroup.children().outerHeight() - topMargin)) {
                                        $thisGroup.css({ position: 'fixed', top: (thisOffsetTop + topMargin) + 'px' });

                                    } else {
                                        $thisGroup.css({ position: '', top: '' });
                                    }
                                } else {
                                    $thisGroup.css({ position: '', top: '' });
                                }
                            };
                        });
                        $(window, container).resize(function () {
                            for (var index in setPositions) {
                                setPositions[index]();
                            }
                        });
                        container.scroll(function () {
                            for (var index in setPositions) {
                                setPositions[index]();
                            }
                        });
                        container.on('mousewheel DOMMouseScroll', function (e) {
                            var delta = e.wheelDelta || (e.originalEvent && e.originalEvent.wheelDelta) || -e.detail,
                                bottomOverflow = this.scrollTop + $(this).outerHeight() - this.scrollHeight >= 0,
                                topOverflow = this.scrollTop <= 0;

                            if ((delta < 0 && bottomOverflow) || (delta > 0 && topOverflow)) {
                                e.preventDefault();
                            }
                        });
                        var previousScrollTop;
                        $(window).scroll(function () {
                            if (!previousScrollTop && $(this).scrollTop() && ($autocompleteBar.is(':focus') || container.is(':visible'))) {
                                //for the suggestions to hide and reapear properly
                                $autocompleteBar.blur();
                                container.hide();
                                $autocompleteBar.parent().click();
                            }
                            previousScrollTop = $(this).scrollTop();
                        });

                        return container;
                    },
                    onSelect: function (suggestion) {
                        vm.isSaveActivated = true;
                        var tempVal = JSON.parse(suggestion.value);
                        vm.value = {
                            goalID: tempVal.goalID,
                            entityID: tempVal.entityID
                        };
                        this.value = '';
                        vm.selectedRecipient = true;
                        vm.displayedValue.value = tempVal.info;
                        vm.displayedValue.eventName = tempVal.eventName;
                        this.click();
                    },
                    onSearchComplete: function () {
                        $('.autocomplete-group').each(function (index, thisGroup) {
                            initialOffsets[index] = thisGroup.offsetTop;
                            setPositions[index]();
                        });
                        var container = angular.element($('.autocomplete-suggestions')[$('.autocomplete-suggestions').length - 1]);
                        if ((container.offset().top + container.height()) > Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) {
                            container.css({ height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - container.offset().top });
                        } else {
                            container.css({ height: "inherit" });
                        }
                    },
                    width: 600,
                });
            }

            function filterTo30(query, groups) {
                var queryLowerCase = query.toLowerCase();
                var allResults = [];
                groups.forEach((group, index) => {
                    allResults[index] = [];
                });
                var top10OfEach = [];
                groups.forEach((group, index) => {
                    var queryStrForExactMatch = '"' + queryLowerCase + '"';
                    var nextIndexForExactMatch = 0;
                    //search for exact matches and move them to beginning
                    for (var i = 0; i < group.length; i++){
                        if (group[i].value.toLowerCase().indexOf(queryStrForExactMatch) !== -1) {
                            group.splice(nextIndexForExactMatch, 0, group.splice(i, 1)[0]);
                            nextIndexForExactMatch++;
                        }
                    }
                    var j = 0;
                    while (allResults[index].length < 10 && j < group.length) {
                        if (group[j].value.toLowerCase().indexOf(queryLowerCase) !== -1) {
                            allResults[index].push(group[j]);
                        }
                        j++;
                    }
                    top10OfEach = top10OfEach.concat(allResults[index].slice(0, 10));
                });
                return top10OfEach;
            }

            function createSuggestionInner(suggestionValue) {
                return $('<div class="suggestion-left"><ul><li class="suggestion-info">' + (suggestionValue.info || '') + '</li><li class="suggestion-secondary-info gray-text">' + (suggestionValue.secondaryInfo || '') + '</li></ul></div><div class="suggestion-right gray-text"><ul><li>' + (suggestionValue.eventName || '') + '</li><li>' + (suggestionValue.eventID ? 'CampaignID: ' + suggestionValue.eventID : '') + '</li></ul></div>');
            }
        }
    }
}