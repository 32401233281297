angular.module('app').component('navBar', {
  bindings: {
    isAuthenticated: '='
  },
  templateUrl: 'nav-bar.tpl.html',
  controller: NavBarCtrl,
  controllerAs: 'vm'
});

function NavBarCtrl($rootScope, $location, profileService, orgService, securityService, routingService, campaignService) {
  const vm = this;

  vm.showProfilePopover = false;
  vm.profile = null;
  vm.userNeonApps = [];
  vm.showOrgSelectorModal = false;
  vm.org = orgService.getOrg();
  vm.security = {};

  vm.isChampion = !!window.isChampion;
  vm.brandingLogo = window.brandingLogo;
  vm.brandingName = window.brandingName;
  vm.brandingUrl = window.brandingUrl;

  vm.getLogonUrl = getLogonUrl;
  vm.hideProfilePopover = hideProfilePopover;
  vm.isActiveTab = isActiveTab;

  vm.$onInit = function () {
      if (vm.isAuthenticated) {
          loadProfile();
          loadUserNeonApps();
          vm.security = securityService.getAdminPermissions(vm.org, 'ADMIN_USERS');
      }
  };

  //////////////////////////////////////////////

  function loadProfile() {
    profileService
      .getProfileInfo()
      .then((profileInfo) => {
        vm.profile = profileInfo;
      });
  }

  function loadUserNeonApps() {
    profileService
        .getUserNeonApps()
        .then((userNeonApps) => {
          vm.userNeonApps = userNeonApps;
        });
  }

  function getLogonUrl() {
      return `/Account/Logon?returnUrl=${encodeURIComponent($location.url())}`;
  }

  function hideProfilePopover() {
    vm.showProfilePopover = false
  }

  function isActiveTab(link) {
    var path = $location.path();
    if(path.indexOf('/my/') == 0 || path.indexOf('/p/') == 0){
      return '';
    }
    if(path.indexOf('organization') > -1){
      if(link == 'organization'){
        return 'active';
      }
      return '';
    }
    if(link == 'campaigns'){
      return 'active';
    }
    return '';
  }

  $rootScope.$on('$routeChangeSuccess', () => {
     vm.onCampaign = routingService.openEntityIsCampaign();
  });
}