angular
  .module('app')
  .component('pageNotFound', {
      templateUrl: 'page-not-found.tpl.html',
      controller: pageNotFoundController,
    controllerAs: 'vm'
  })

function pageNotFoundController($rootScope, messageService) {
    const vm = this;
    $rootScope.$emit('groupOrCampaignNotFound');


}