angular.module('app').component('movingTree', {
  bindings: {
    items: '=',
    onMoveClick: '&'
  },
  templateUrl: 'moving-tree.tpl.html',
  controller: MovingTreeController,
  controllerAs: 'vm'
});

function MovingTreeController($rootScope, campaignService, orgService, entityService) {
  const vm = this;

  vm.tree = {
    groups: [],
    campaigns: []
  };

  vm.highlight = null;
  vm.disableMove = true;

  vm.highlightItem = highlightItem;
  vm.getGroups = getGroups;
  vm.goBack = goBack;
  vm.close = close;
  vm.isRoot = isRoot;
  vm.isCurrent = isCurrent;
  vm.getOpenedName = getOpenedName;
  vm.changeName = changeName;

  getGroups(campaignService.getOpenedGroup().groupId);

  function changeName(name){
      if (name.length > 20 ){
          name = name.substring(0, 20) + '...';
         return name;
      }
      else {
        return name;
      }
  }

  function highlightItem(group, event) {

    let groupId = group.groupId;
    let nope = false;

    if (isCurrent()) vm.disableMove = false;

    if (!vm.items.isCampaign) {

      if (vm.items.some(item => item.groupId == groupId)) {
        nope = true;
        vm.disableMove = true;
      }

      vm.disableMoveSame = vm.items.some(item => item.parentGroupId == groupId)

    }

    let target = event.target;
    vm.highlight = {
        entityId: group.entityId,
        groupId: group.groupId
    };

    let h = 'highlight',
        li = target.closest('.checks-list-item');

    if (li.classList.contains(h)) {
      if (!nope) getGroups(groupId); //can't go down inside selected folder
    } else {
      let ul = Array.from(li.parentNode.querySelectorAll('.checks-list-item'));

      ul.forEach(item => item.classList.remove(h));
      li.classList.add(h);
    }

  }
  
  
  function getGroups(groupId) {

    campaignService.openGroup(groupId, null, false, true)
      .then(response => {
        vm.tree.groups = response.groups;
        vm.totalCampaignsCount = response.campaigns.length;
        vm.tree.campaigns = vm.totalCampaignsCount > 25 ? response.campaigns.slice(0, 25) : response.campaigns;
      });

  }

  function goBack() {
    getGroups(campaignService.getOpenedGroupMove().parentGroupId);

    var groupId = campaignService.getOpenedGroupMove().groupId;
    var entityId = entityService.getOpenedEntityIdMove();

    vm.highlight = {
        entityId: entityId,
        groupId: groupId
    };

    vm.disableMove = true;
  }

  function getOpenedName() {
    return campaignService.getOpenedGroupMove().name || orgService.getOrg().name
  }

  function isRoot() {
    return !!(campaignService.getOpenedGroupMove().groupId || 0)
  }
  
  function close() {
    $rootScope.$emit('move.close');
  }

  function isCurrent() {

    let currentLevel = campaignService.getOpenedGroup().groupId || 0,
        currentTreeLevel = campaignService.getOpenedGroupMove().groupId || 0;

    return currentLevel == currentTreeLevel

  }

}