
angular.module('app').component('lockedIcon', {
  controller: lockedIconController,
  controllerAs: 'vm',
  bindings: {
    parentName: '<',
    setting: '<',
    hideOptions: '<',
    disabled: '<'
  },
  templateUrl: 'locked-icon.tpl.html'
});

function lockedIconController($scope) {
  var vm = this;

  vm.showPullout = false;
  vm.showConfigModal = false;

  vm.togglePullout = function() {
    vm.showPullout = !vm.showPullout;
  };

  vm.hidePullout = function() {
    vm.showPullout = false;
  };

  vm.unlockItem = function() {
    $scope.$emit('overrideParent', vm.setting);
    vm.hidePullout();
  }
}
