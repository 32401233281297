angular.module('app').factory('crmSyncsService', crmSyncsService);

function crmSyncsService($http, $q, API_PREFIX) {
    return {
        getCrmLogs,
        getCrmLogsForDates,
        reSync
    };

    function getCrmLogs(crmAction, id, start = 0, limit = 99999) {        
        return getCrmLogsRaw(crmAction, { id, start, limit });
    }

    function getCrmLogsForDates(crmAction, id,  from, to) {
        return getCrmLogsRaw(crmAction, {
            id,
            to: moment(to).format('YYYY-MM-DD'),
            from: moment(from).format('YYYY-MM-DD')
        });
    }

    function getCrmLogsRaw(crmAction, data) {
        return $http.post(`${API_PREFIX}Integration/${crmAction}`, data).then(response => {
            return response.data.items.map(log => {
                log.InitiatedOn = log.InitiatedOn && new Date(log.InitiatedOn);
                log.LastRetriedOn = log.LastRetriedOn && new Date(log.LastRetriedOn);
                log.canResync = !!(log.ErroredOn || log.CompletedOn);


                if (log.CompletedOn) {
                    log.Status = 'successful';
                    // TODO: Standardize retrying state logic to work with all CRM syncs on server and client side.
                } else if (log.ErroredOn && log.LastRetriedOn && !log.FirstRetriedOn) {
                    log.Status = 'retrying';
                } else {
                    log.Status = 'failed';
                }

                if (log.CompletedOn) {
                    log.CompletedOn = new Date(log.CompletedOn);
                } else if (log.ErroredOn) {
                    log.CompletedOn = new Date(log.ErroredOn);
                }

                return log;
            });
        });
    }

    function reSync(record, reSyncAction) {
        return $http.post(`${API_PREFIX}Integration/${reSyncAction}`, record).then(response => response.data);
    }
}
