angular
    .module('app')
    .component('triggerEmails', {
        controller: triggerEmailsController,
        bindings: {
            $router: '<'
        },
        require: {
            parentComponent: '^email'
        },
        templateUrl: 'trigger-emails.tpl.html',
        controllerAs: 'vm',
        $canActivate: function($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });

function triggerEmailsController($mdDialog, $controller, $q, $scope, orgService, entityService, emailService, securityService, formService) {
    const vm = this;
    var entity = entityService.getOpenEntity();
    vm.configOptionId = emailService.EMAIL_CONFIG_OPTIONS.triggers;
    vm.entityType = entity.entityType;
    vm.security = securityService.getAdminPermissions(entity, 'TRIGGERS');
    vm.loading = true;

    vm.getData = function() {
        vm.dataRequest()
            .then(r => {
                vm.grid = r.items;
                vm.newItem = r.newItem;
            }).catch(() => {

        }).finally(() => vm.loading = false);
    }

    vm.dataRequest = function() {
        return emailService.getTriggerEmailTemplates()
    }

    orgService.orgInfoLoadedPromise.then(r => {
        vm.getData();
    });

    vm.showActivateOptions = (item, forTextDisplay) => emailService.showActivateOptions(item, forTextDisplay);
    vm.editorButtons = emailService.getEmailEditorButtons();

    vm.clicked = function(item) {
        return vm.saveEmail(item).then(r => {
            item.Id = r.item.Id;
        });
    }

    vm.open = function(item) {
        emailService.openEmail(item);
        let itemFields  = {
            decimal: {
                group: formService.getFieldFromItem(item, 'Decimal'),
                amount: formService.getSubFieldFromItem(item, 'Decimal', 'Amount'),
                setMaximum: formService.getSubFieldFromItem(item, 'Decimal', 'Set Maximum'),
                maximum: formService.getSubFieldFromItem(item, 'Decimal', 'Maximum'),
            },
            
            int: {
                group: formService.getFieldFromItem(item, 'Int'),
                amount: formService.getSubFieldFromItem(item, 'Int', 'Amount'),
                setMaximum: formService.getSubFieldFromItem(item, 'Int', 'Set Maximum'),
                maximum: formService.getSubFieldFromItem(item, 'Int', 'Maximum'),
            },

            initiatorDecimal: {
                group: formService.getFieldFromItem(item, 'InitiatorDecimal'),
                amount: formService.getSubFieldFromItem(item, 'InitiatorDecimal', 'Amount'),
            },
            
            trigger: formService.getFieldFromItem(item, 'Trigger'),
            enduranceChallenge: formService.getFieldFromItem(item, 'Endurance Challenge'),
        }
        
        if (itemFields.trigger) {
            itemFields.trigger.change = () => {
                updateIntLabel(itemFields);
                updateDecimalLabel(itemFields);
                updateInitiatorDecimalLabel(itemFields);
            };
        }
        
        if (itemFields.enduranceChallenge) {
            itemFields.enduranceChallenge.change = () => {
                updateDecimalLabel(itemFields);
            };
        }

        updateIntLabel(itemFields);
        updateDecimalLabel(itemFields);
        updateInitiatorDecimalLabel(itemFields);
    }

    function updateIntLabel(itemFields) {
        var labels = getIntLabels(itemFields);
        updateLabelsForGroup(labels, itemFields.int);
    }

    function updateDecimalLabel(itemFields) {
        var labels = getDecimalLabels(itemFields);
        updateLabelsForGroup(labels, itemFields.decimal);
    }

    function updateInitiatorDecimalLabel(itemFields) {
        var labels = getInitiatorDecimalLabels(itemFields);
        updateLabelsForGroup(labels, itemFields.initiatorDecimal);
    }
    
    function updateLabelsForGroup(labels, groupItemFields) {
        if (groupItemFields.group) {
            groupItemFields.group.displayOptions.labels.alternative = labels.alternative;
        }
        if (groupItemFields.amount) {
            groupItemFields.amount.displayOptions.labels.prepend = labels.prepend;
            groupItemFields.amount.displayOptions.labels.append = labels.append;
            groupItemFields.amount.displayOptions.labels.validation = labels.alternative;
        }
        if (groupItemFields.setMaximum) {
            groupItemFields.setMaximum.displayOptions.labels.inline = "Set Maximum " + labels.alternative;
        }
        if (groupItemFields.maximum) {
            groupItemFields.maximum.displayOptions.labels.prepend = labels.prepend;
            groupItemFields.maximum.displayOptions.labels.append = labels.append;
            groupItemFields.maximum.displayOptions.labels.validation = labels.alternative + " Maximum";
            groupItemFields.maximum.displayOptions.labels.alternative = "Maximum " + labels.alternative;
        }
    }

    function getIntLabels(itemFields) {
        if (!itemFields.trigger) {
            return {};
        }
        
        switch (itemFields.trigger.value) {
            case 'DonorCount':
            case 'TeamDonorCount':
                return {
                    alternative: 'Number of Donors',
                    append: 'donors'
                };
            case 'DonationCount':
                return {
                    alternative: 'Number of Donations',
                    append: 'donations'
                };
            case 'ActivitiesLoggedToEnduranceChallenge':
                return {
                    alternative: 'Activities Logged',
                    append: 'activities'
                };
            case 'ReceivedNumberOfEndurancePledges':
                return {
                    alternative: 'Number of pledges',
                    append: 'pledges'
                };
        }
        
        return {};
    }

    function getDecimalLabels(itemFields) {
        if (!itemFields.trigger) {
            return {};
        }
        
        switch (itemFields.trigger.value) {
            case 'RaisedDollars':
            case 'TeamRaisedDollars':
            case 'RaisedEndurancePledgeAmount':
                return {
                    alternative: 'Amount Raised',
                    prepend: '$',
                };
            case 'CompletedProgressTowardEnduranceChallenge':
                return {
                    alternative: 'Completed Progress',
                    append: getEnduranceUnitFromEnduranceChallengeField(itemFields),
                };
        }

        return {};
    }
    
    function getInitiatorDecimalLabels(itemFields) {
        if (!itemFields.trigger) {
            return {};
        }

        switch (itemFields.trigger.value) {
            case 'ReceivesDonation':
                return {
                    alternative: 'Donation Amount',
                    prepend: '$'
                };
        }

        return {};
    }
    
    function getEnduranceUnitFromEnduranceChallengeField(itemFields) {
        if (!itemFields.enduranceChallenge || !itemFields.enduranceChallenge.optionsMetadata) {
            return '';
        }
        
        return itemFields.enduranceChallenge.optionsMetadata[itemFields.enduranceChallenge.value].Unit;
    }
    
    vm.overrideParent = function(item) {
        return vm.saveEmail(item).then(r => {
            return r.item;
        });
    }

    vm.delete = function(item) {
        return emailService.deleteEmailTemplate(item);
    }

    vm.saveEmail = function(item) {
        return emailService.createOrUpdateTriggerEmailTemplate(item);
    }

    vm.adoptParent = function(item) {
        return emailService.deleteEmailTemplate(item).then(r => {
            return r.item;
        });
    }

    $scope.$on('clearOverrides', function (event, item) {
        if (item.configOptionId === vm.configOptionId) {
            return emailService.clearEmailTemplateOverrides(item);
        }
    });
}