angular
  .module('app')
  .factory('ckEditorService', ckEditorService);

function ckEditorService() {
    return {
        configureCkEditor,
        getEditorValue,
    }

    function configureCkEditor() {
        // set global plugins
        CKEDITOR.plugins.add('maxlength', {
            init: function (editor) {
                var $editorName = $("#" + editor.name);
                var maxLimit = $editorName.attr("maxlength") ?  $editorName.attr("maxlength") : ($editorName.attr('ng-maxlength') ? $editorName.attr('ng-maxlength') : 0);;
                if (maxLimit > 0) {
                    editor.on('afterCommandExec',  function(evt) {
                        var charCount = evt.editor.getData().length;
                        if (charCount >= maxLimit) {
                            editor.execCommand('undo');
                        }
                    });
                    editor.on('paste', function(evt) {
                        var charCount = evt.editor.getData().length + evt.data.dataValue.length;
                        if (charCount >= maxLimit) {
                            return false;
                        } else {
                            return true;
                        }
                    });

                    editor.on('key', function(evt) {
                        var charCount = evt.editor.getData().length;
                        if (charCount >= maxLimit) {
                            if (evt.data.keyCode === 8 || evt.data.keyCode === 46) {
                                return true;
                            }
                            return false;
                        } else {
                            return true;
                        }
                    });
                }
            }
        });
        CKEDITOR.plugins.add('applyAtrr', {
            init: function (editor) {
                var edtr = editor;
                var $editorName = $('#' + edtr.name);
                if ($editorName.attr("ck-editor-attr")) {
                    var ckEditorAttr = JSON.parse($editorName.attr("ck-editor-attr"));
                    CKEDITOR.on('instanceReady', function(evt) {
                        for (var attribute in ckEditorAttr) {
                            $('#cke_' + edtr.name).attr(attribute, ckEditorAttr[attribute]);
                        }
                    });
                }
            }
        });

        CKEDITOR.plugins.add('extraButtons', {
            init: function (editor) {
                if (editor.config.extraButtons) {
                    var floatingToolbarAdded = false;
                    
                    _.forEach(editor.config.extraButtons, button => {

                        if (button.toolbar === 'floating' && !floatingToolbarAdded) {
                            editor.ui.addToolbarGroup('floating', 0)
                            editor.element.getParent().getParent().addClass('hasFloatingToolbar');
                            floatingToolbarAdded = true;
                        }

                        let buttonName = _.startCase(button.label).replace(/ /g, '');
                        let commandName = _.camelCase(button.label);
                        
                        editor.ui.addButton(buttonName,
                            {
                                label: button.label,
                                title: button.label,
                                icon: button.icon,
                                command: commandName,
                                toolbar: button.toolbar,
                            });
                        editor.addCommand(commandName, { modes : { wysiwyg: 1, source: 1 }, readOnly: 1, exec: button.command });
                    });
                }
            },
        });

        CKEDITOR.on('instanceReady', function(ev) {
            ev.editor.editable().addClass('cke_enable_context_menu')
        });

        CKEDITOR.getInlineConfig = function() {
            return {
                plugins: 'basicstyles,clipboard,list,indent,enterkey,entities,link,pastetext,toolbar,undo,wysiwygarea,font,codemirror',
                forcePasteAsPlainText : true,
                removeButtons: 'Anchor,Underline,Strike,Subscript,Superscript',
                toolbarGroups: [
                    { name: 'cstm' },
                    { name: 'document',groups: [ 'mode', 'document', 'doctools' ] },
                    { name: 'editing',groups: [ 'find', 'selection' ] },
                    { name: 'forms' },
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup', 'undo' ] },
                    { name: 'paragraph',groups: [ 'list', 'indent', 'blocks', 'align' ] },
                    { name: 'links' },
                    { name: 'insert' },
                    { name: 'styles' },
                    { name: 'colors' },
                    { name: 'tools' },
                    { name: 'others' },
                    { name: 'about' }
                ],
                entities: false,
                entities_additional: 'trade,loz',
                fontSize_sizes: ';8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;',
                font_names:
                    'Arial/Arial, Helvetica, sans-serif;' + 
                    'Calibri, Candara, Segoe, Optima, Arial, sans-serif;' +
                    'Comic Sans MS/Comic Sans MS, cursive;' + 
                    'Courier New/Courier New, Courier, monospace;' + 
                    'Georgia/Georgia, serif;' + 
                    'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' + 
                    'Tahoma/Tahoma, Geneva, sans-serif;' + 
                    'Times New Roman/Times New Roman, Times, serif;' + 
                    'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' + 
                    'Verdana/Verdana, Geneva, sans-serif;',
                enterMode: CKEDITOR.ENTER_BR,
                fillEmptyBlocks: false,
                coreStyles_italic: { element : 'i', overrides : 'em' },
                coreStyles_bold: { element : 'b', overrides : 'strong' },

                skin: 'office2013',

                //http://stackoverflow.com/a/24425037
                extraAllowedContent: 'img[width,height]',
                disallowedContent: 'img{width,height}',
                disableNativeSpellChecker: false
            }
        }

        CKEDITOR.getInlineConfigAngular = function(){
            return {
                plugins: 'basicstyles,clipboard,list,enterkey,entities,link,pastetext,toolbar,undo,wysiwygarea,maxlength,applyAtrr,font,codemirror',
                forcePasteAsPlainText : true,
                extraPlugins: '',
                removeButtons: 'Anchor,Underline,Strike,Subscript,Superscript',
                height: '100px',
                toolbarGroups: [
                    { name: 'cstm' },
                    { name: 'document',groups: [ 'mode', 'document', 'doctools' ] },
                    { name: 'editing',groups: [ 'find', 'selection' ] },
                    { name: 'forms' },
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup', 'undo' ] },
                    { name: 'paragraph',groups: [ 'list', 'blocks', 'align' ] },
                    { name: 'links' },
                    { name: 'insert' },
                    { name: 'colors' },
                    { name: 'tools' },
                    { name: 'others' },
                    { name: 'about' }
                ]
            }
        };

        CKEDITOR.getInlineConfigAngularDescriptionField = function() {
            return {
                plugins: 'basicstyles,clipboard,enterkey,entities,link,pastetext,toolbar,undo,wysiwygarea,maxlength,applyAtrr,font,codemirror',
                forcePasteAsPlainText : true,
                extraPlugins: '',
                removeButtons: 'Anchor,Underline,Strike,Subscript,Superscript',
                height: '100px',
                toolbarGroups: [
                    { name: 'cstm' },
                    { name: 'document',groups: [ 'mode', 'document', 'doctools' ] },
                    { name: 'editing',groups: [ 'find', 'selection' ] },
                    { name: 'forms' },
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup', 'undo' ] },
                    { name: 'paragraph',groups: [ 'blocks', 'align' ] },
                    { name: 'links' },
                    { name: 'insert' },
                    { name: 'colors' },
                    { name: 'tools' },
                    { name: 'others' },
                    { name: 'about' }
                ]
            }
        };

        CKEDITOR.getMiniAdminConfig = function () {
            return {
                plugins: 'basicstyles,clipboard,list,indent,enterkey,entities,link,pastetext,toolbar,undo,wysiwygarea,font,codemirror,image',
                forcePasteAsPlainText : true,
                removeButtons: 'Anchor,Underline,Strike,Subscript,Superscript',
                extraPlugins: 'placeholder,filebrowser,imagebrowser,pdfembed',
                toolbarGroups: [
                    { name: 'document',groups: [ 'mode', 'document', 'doctools' ] },
                    { name: 'editing',groups: [ 'find', 'selection' ] },
                    { name: 'forms' },
                    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup', 'undo' ] },
                    { name: 'paragraph'},
                    { name: 'links' },
                    { name: 'insert' },
                    { name: 'styles' },
                    { name: 'colors' },
                    { name: 'tools' },
                    { name: 'others' },
                    { name: 'about' }
                ]
                // entities: false,
                // fontSize_sizes: ';8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;',
                // enterMode: 'CKEDITOR.ENTER_BR',
                // fillEmptyBlocks: false,
                // coreStyles_italic: { element : 'i', overrides : 'em' },
                // coreStyles_bold: { element : 'b', overrides : 'strong' }
            };
        }

        // TODO: Fix filebrowser,imagebrowser plugins at the end
        CKEDITOR.config.plugins = 'dialogui,dialog,dialogadvtab,basicstyles,bidi,blockquote,specialchar,clipboard' +
          ',panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,resize,toolbar,elementspath,list,indent,' +
          ',entities,popup,find,fakeobjects,floatingspace,listblock,richcombo,font,format,htmlwriter,horizontalrule' +
          ',wysiwygarea,image,justify,link,liststyle,magicline,pastetext,pastefromword,removeformat,selectall' +
          ',showborders,sourcearea,menubutton,stylescombo,tab,table,tabletools,undo,codemirror,filebrowser,imagebrowser';
        CKEDITOR.config.removePlugins = 'liststyle,tabletools,contextmenu';

        CKEDITOR.config.codemirror = {
            theme: 'default',
            lineNumbers: true,
            lineWrapping: true,
            matchBrackets: true,
            autoCloseTags: true,
            autoCloseBrackets: true,
            enableSearchTools: false,
            enableCodeFolding: true,
            enableCodeFormatting: true,
            autoFormatOnStart: true,
            autoFormatOnUncomment: true,
            highlightActiveLine: true,
            highlightMatches: true,
            showFormatButton: false,
            showCommentButton: false,
            showUncommentButton: false
        };
        CKEDITOR.config.allowedContent = true;
        CKEDITOR.config.removeDialogTabs = 'image:advanced;image:Link;link:advanced;table:advanced';
        CKEDITOR.config.entities = false;
        CKEDITOR.config.entities_additional = 'trade,loz';
        CKEDITOR.config.fontSize_sizes = ';8/8px;9/9px;10/10px;11/11px;12/12px;14/14px;16/16px;18/18px;20/20px;22/22px;24/24px;26/26px;28/28px;';
        CKEDITOR.config.font_names =
            'Arial/Arial, Helvetica, sans-serif;' + 
            'Calibri/Calibri, Candara, Segoe, Optima, Arial, sans-serif;' +
            'Comic Sans MS/Comic Sans MS, cursive;' + 
            'Courier New/Courier New, Courier, monospace;' + 
            'Georgia/Georgia, serif;' + 
            'Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;' + 
            'Tahoma/Tahoma, Geneva, sans-serif;' + 
            'Times New Roman/Times New Roman, Times, serif;' + 
            'Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;' + 
            'Verdana/Verdana, Geneva, sans-serif;';
        CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR;
        CKEDITOR.config.fillEmptyBlocks = false;
        CKEDITOR.config.coreStyles_italic = { element : 'i', overrides : 'em' };
        CKEDITOR.config.coreStyles_bold = { element : 'b', overrides : 'strong' };

        CKEDITOR.config.skin = 'office2013';

        //http://stackoverflow.com/a/24425037
        CKEDITOR.config.extraAllowedContent = 'img[width,height]';
        CKEDITOR.config.disallowedContent = 'img{width,height}';
        CKEDITOR.config.disableNativeSpellChecker = false;
        CKEDITOR.config.imageBrowser_isAngularAdmin = true;
    }
    
    function getEditorValue(id, knownValue) {
        return CKEDITOR.instances[id] ? CKEDITOR.instances[id].getData() : knownValue;
    }
}