angular
  .module('app')
  .directive('touch', Touch);

function Touch() {
    return function (scope, elem, attrs) {

        var isLongTouch = false;
        let isTap = false;
        let isMove = false;
        var timer;
        var longTouchDuration = 500;
        var touchStartEvent;

        elem.on("touchstart", function (e) {
            isLongTouch = false;
            isTap = false;
            isMove = false;
            timer = setTimeout(onLongTouch, longTouchDuration);
            touchStartEvent = e;
        });

        var onLongTouch = function () {
            if (!isTap && !isMove) {
                isLongTouch = true;
                scope.evnt = touchStartEvent;
                scope.$apply(attrs["touchHold"]);
            }
        }

        elem.on("touchend", function (e) {
            //stop touchend event from double-clicking on long touch and next page
            e.preventDefault();
            e.stopPropagation();
            if (timer && !isMove && !isLongTouch) {
                isTap = true;
                scope.$apply(attrs["touch"]);
                clearTimeout(timer);
            }

        });

        elem.on("touchmove", function (e) {
            isMove = true;
        });
    }
}