angular
.module('app')
.component('fieldEditBtns', {
    bindings: {
        isEditing: '=',
        isSaveActivated : '=',
        save: '&',
        cancel: '&',
        edit: '&',
        saved: '=',
        showLoader: '='
    },
    controller: fieldEditBtnsController,
    templateUrl: 'field-edit-btns.tpl.html',
    controllerAs: 'vm'
});

function fieldEditBtnsController($http, API_PREFIX, $window, $scope, $rootScope, $location, $timeout, $interval, orgService, campaignService, entityService, messageService, profileService) {
    const vm = this;
}