angular
  .module('app')
  .component('statistics', {
    templateUrl: 'statistics.tpl.html',
    controller: StatisticsController,
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
  })

function StatisticsController($http, API_PREFIX, campaignService, orgService, messageService) {
    const vm = this;

    vm.orgId = orgService.getUrlOrgId();
    vm.csvDownloadPath = `/Org/${vm.orgId}${API_PREFIX}/GetGraphCsv`;
    vm.updatePlots = updatePlots;

    let allCampaigns = campaignService.getRawCampaigns();
    var statisticsIsLoaded = false;

    loadStatisticsToDOM();

    $('body').on('click', '#statisticsMainOuter', function(e) {
        var $target = $(e.target);
        var $clickedElm = $(e.currentTarget);
        if($target.closest('.smallChartOuter').length) {
            switchReport($target.closest('.smallChartOuter'));
        }
    });

    function loadStatisticsToDOM() {
        if (statisticsIsLoaded) {
            return;
        }
        statisticsIsLoaded = true;
        var statisticsAllCampaignsDrop = $('#statisticsAllCampaignsDrop').empty().multiselect('destroy');
        allCampaigns.forEach(campaign => {
            statisticsAllCampaignsDrop.append('<option value="' + campaign.curEventId + '">' + campaign.name + (campaign.CampaignYear ? ' (' + campaign.CampaignYear + ')' : '') + '</option>');
        });

        $('#statisticsAllCampaignsDrop').multiselect({
            numberDisplayed: 1,
            buttonText: function () {
                return 'Select campaigns'
            },
            buttonWidth: '100%'
        });
        loadPlot();
    }

    function removeActiveClass() {
        $('#globalNavMenu > li').removeClass('active');
    }


//will update the plot to the selected date
    function updatePlots(returnCsv, reportType) {
        if (returnCsv) {
            messageService.showMsgDialog('Downloading...', '<b>Important!</b> Clicking stop or doing more admin work may interrupt the download.<br/>Hang on a minute while we do the heavy lifting.')
        } else {
            showSpinner($('#mainGraphholder'), true);
        }
        var data = {};
        var $graphReportCsvForm;
        if (returnCsv) {
            $graphReportCsvForm = $('#graphReportCsvForm').empty();
            $graphReportCsvForm.append('<input name="returnCsv" value="true"/>');
            $graphReportCsvForm.append('<input name="reports" value="' + reportType + '"/>');
            //$graphReportCsvForm.append('<input name="org" value="' + orgService.getUrlOrgId() + '"/>');
        }
        var $datePickerContainer = $('#datePickerContainer');

        // startDate
        if (vm.startDate) {
            data.startDate = $.datepicker.formatDate('yy-mm-dd', vm.startDate);
            if (returnCsv) {
                $graphReportCsvForm.append('<input name="startDate" value="' + data.startDate + '"/>');
            }
        }

        if (vm.endDate) {
            var endDateTime = vm.endDate;
            //endDateTime.setTime(new Date().getTime());

            data.endDate = $.datepicker.formatDate('yy-mm-dd', endDateTime) + ' 23:59:59';
            //console.log(endDateTimeElm.val())
            if (returnCsv) {
                $graphReportCsvForm.append('<input name="endDate" value="' + data.endDate + '"/>');
            }
        }

        var events = '';

        var selectedElms = $('#statisticsAllCampaignsDrop').data('multiselect').getSelected();
        for (var i = 0; i < selectedElms.length; i++) {
            var selectedElm = selectedElms[i];
            events += ',' + selectedElm.value;
        }
        ;

        if (events.length > 1) {
            data.events = events.substring(1);
            if (returnCsv) {
                $graphReportCsvForm.append('<input name="events" value="' + data.events + '" />');
            }

        }
        if (returnCsv) {
            downloadCsv($graphReportCsvForm);
        } else {
            loadPlot(data);
        }
    }

//gets called when want to download the report as a csv
    function downloadCsv($form) {
        if (!$('#graphReportCsv').length) $('body').append('<iframe id="graphReportCsv" name="graphReportCsv" src="' + vm.csvDownloadPath + '" style="display: none;"></iframe>');
        $form.submit();
    }

    var allMetaNames = {};

//maps the meta names for the reports
    function mapMetaNamesToIndex(reportDataObj) {
        for (var metaNamesObjKey in reportDataObj) {
            var metaNamesObj = JSON.parse(reportDataObj[metaNamesObjKey]);
            reportDataObj[metaNamesObjKey] = metaNamesObj;

            var names = metaNamesObj.metaNames;
            var metaData = {};
            for (var i = 0; i < names.length; i++) {
                metaData[names[i]] = i;
            }
            ;
            allMetaNames[metaNamesObjKey] = metaData;
        }
    }

//reformat the server data for plots
    function readyPeopleDataForPlot(metaNames, data) {
        var donor = [],
            walker = [],
            fundraiser = [];

        var totalDonerCount = 0,
            totalWalkersCount = 0,
            totalFundraiserCount = 0;

        metaNames = metaNames.people;
        data = data.people.data;
        for (var i = 0; i < data.length; i++) {
            var row = data[i]
            //We must use the $.datepicker instead of new Date in order for the graphes to work correctly in fireFox
            var date = $.datepicker.parseDate('yy-mm-dd', row[metaNames.Date]).getTime();

            var currentDonerCount = parseInt(row[metaNames.DonorCount]);
            donor.push([date, currentDonerCount]);
            totalDonerCount += currentDonerCount;

            var currentWalkersCount = parseInt(row[metaNames.WalkerCount]);
            walker.push([date, currentWalkersCount]);
            totalWalkersCount += currentWalkersCount;

            var currentFundraiserCount = parseInt(row[metaNames.FundraiserCount]);
            fundraiser.push([date, currentFundraiserCount]);
            totalFundraiserCount += currentFundraiserCount;
        }
        ;

        return {
            reportInfo: {donor: donor, walker: walker, fundraiser: fundraiser},
            counts: {
                totalDonerCount: totalDonerCount,
                totalWalkersCount: totalWalkersCount,
                totalFundraiserCount: totalFundraiserCount
            }
        };
    }

//reformat the server data for plots
    function readyFundsDataForPlot(metaNames, data, totalAmountBeforeStartDate) {
        var donationAmount = [],
            donationCount = [],
            runningSumDonation = [];

        metaNames = metaNames.funds;
        data = data.funds.data;
        var currentTotal = totalAmountBeforeStartDate;
        for (var i = 0; i < data.length; i++) {
            var row = data[i];
            //We must use the $.datepicker instead of new Date in order for the graphes to work correctly in fireFox
            var date = $.datepicker.parseDate('yy-mm-dd', row[metaNames.Date]).getTime();
            var todaysTotal = parseFloat(row[metaNames.DonationsAmount]);
            donationAmount.push([date, todaysTotal]);
            donationCount.push([date, parseInt(row[metaNames.DonationsCount])]);
            currentTotal = Math.round((currentTotal + todaysTotal) * 100) / 100;
            runningSumDonation.push([date, currentTotal]);
        }
        ;
        return {
            reportInfo: {
                donationAmount: donationAmount,
                donationCount: donationCount,
                runningSumDonation: runningSumDonation
            }
        };
    }

//plot config
    var FlotConfig = function () {
        this.legend = {
            show: true,
            noColumns: 0
        };
        this.xaxis = {mode: "time"};
        this.yaxis = {};
        this.grid = {borderWidth: 0, hoverable: true, clickable: true};
        // this.zoom = { interactive: true };
        this.series = {};
    };

//plot pie config
    var FlotPieConfig = function () {
        this.legend = {show: true};
        this.xaxis = {mode: "time"};
        this.yaxis = {};
        this.grid = {borderWidth: 0};
        this.series = {
            pie: {
                show: true,
                radius: 1,
                lable: {
                    radius: 2 / 3,
                    formatter: function (label, series) {
                        return '<div style="font-size: 8pt; text-align: center; padding: 2px; color: #fff ;">' + label + '<br/>' + series.data[0][1] + '</div>';
                    },
                    threshold: 0.1
                }
            }
        };
        this.legend = {show: false};
    };
//write/drow plot
    function loadPeoplePlots(peopleData) {

        var peopleChartData = [{
            label: "Donors",
            data: peopleData.reportInfo.donor,
            color: "#edc487"
        }, {
            label: "Attendees",
            data: peopleData.reportInfo.walker,
            color: "#3a97cb"
        }, {
            label: "Fundraisers",
            data: peopleData.reportInfo.fundraiser,
            color: "#79ceba"
        }];
        var peopleOptions = new FlotConfig();
        var chartElms = createReportContainer(reportTypes.PlatformPeople, peopleData);

        peopleOptions.series = {
            stack: 0,
            lines: {
                show: true,
                fill: true,
                steps: false
            },
            bars: {
                show: false,
                align: "center",
                barWidth: 12 * 24 * 60 * 60 * 450,
                order: 1,
                series_spread: true
            }
        }
        peopleOptions.xaxis = {
            mode: "time",
        }

        peopleOptions.tooltip = true;
        peopleOptions.tooltipOpts = {
            content: "%s: %y",
            shifts: {
                x: -60,
                y: 25
            }
        }
        chartElms.$bigChartOuter.show();
        $.plot(chartElms.$bigChartOuter.children('.bigChart'), peopleChartData, peopleOptions);

        chartElms.$bigChartOuter.hide();

        peopleOptions.xaxis = {show: false};
        peopleOptions.yaxis = {show: false};
        peopleOptions.legend = {show: false};
        peopleOptions.tooltip = false;
        $.plot(chartElms.$smallChartOuter.children('.smallChart'), peopleChartData, peopleOptions);

        var peoplePieData = [{
            label: "Donors",
            data: peopleData.counts.totalDonerCount
        }, {
            label: "Attendees",
            data: peopleData.counts.totalWalkersCount
        }, {
            label: "Fundraisers",
            data: peopleData.counts.totalFundraiserCount
        }];

        var peoplePieOptions = new FlotPieConfig();
        //plot = $.plot( $peopleChartOuter.children('.pieChart'), peoplePieData, peoplePieOptions);
    }

    function loadFundsPlots(fundsData) {
        var fundsChartData = [{
            label: "Donation count",
            data: fundsData.reportInfo.donationCount,
            yaxis: 1,
            lines: {
                show: true,
            }
        }, {
            label: "Donation running total",
            data: fundsData.reportInfo.runningSumDonation,
            yaxis: 2,
            lines: {
                show: true,
                fill: true,
            }
        }, {
            label: "Donation amount",
            data: fundsData.reportInfo.donationAmount,
            yaxis: 2,
            lines: {
                show: true
            }
        }];

        var fundsOptions = new FlotConfig();
        fundsOptions.yaxes = [{
            min: 0,
            position: 'right'
        }, {
            min: 0,
            position: 'left',
            tickFormatter: function (val, axis) {
                return '$' + numberWithCommas(val, true);
            },
        }]

        fundsOptions.tooltip = true;
        fundsOptions.tooltipOpts = {
            content: "%s: %y",
            shifts: {
                x: -60,
                y: 25
            }
        }
        var chartElms = createReportContainer(reportTypes.PlatformDonations, fundsData);

        chartElms.$bigChartOuter.show();

        $.plot(chartElms.$bigChartOuter.children('.bigChart'), fundsChartData, fundsOptions);
        chartElms.$bigChartOuter.hide();

        fundsOptions.xaxis = {show: false};
        fundsOptions.yaxis = {show: false};
        fundsOptions.legend = {show: false};
        fundsOptions.tooltip = false;
        $.plot(chartElms.$smallChartOuter.children('.smallChart'), fundsChartData, fundsOptions);
    }

    var reportTypes = {PlatformPeople: 1, PlatformDonations: 2};
    var allReportsElms = {};

    var reportNames = {'1': 'People', '2': 'Donations'}
    var openReport;

//will create the report elms big and small and the btm sections.
    function createReportContainer(reportType, reportData) {
        var bottomItms;
        if (reportData && reportData.counts) {
            var $bottomAllCounts = $('#bottomAllCounts');

            $bottomAllCounts.empty();
            createAndLoadReportBottom($bottomAllCounts, reportData.counts, reportType);
            bottomItms = $bottomAllCounts.children();
        }

        //if we already created this report just return the outer if not create a new one
        if (allReportsElms[reportType]) {
            if (bottomItms) allReportsElms[reportType].$bottomItems = bottomItms;
            return allReportsElms[reportType];
        }

        var $mainGraphholder = $('#mainGraphholder'),
            $graphSidePanel = $('#graphSidePanel'),
            chartElms = {};

        chartElms.$bottomItems = bottomItms;
        if (reportData.reportInfo) {
            // $reportOuter.append('<div class="legend" />');
            chartElms.$bigChartOuter = $('<div id="reportBigChartOuter-' + reportType + '" class="bigChartOuter"/>');
            var $nameDownloadRow = $('<div class="container-fluid row"/>').appendTo(chartElms.$bigChartOuter);
            $('<div class="bigChartName col-xs-6 text-left"/>').html(reportNames[reportType]).appendTo($nameDownloadRow);
            $('<div class="bigDownLoad-' + reportType + ' col-xs-6 text-right"/>').html('<a href="#" onclick="angular.element(event.target).scope().vm.updatePlots(true, '+ reportType +')" class="btn btn-download btn-sm downloadCsv">Download</a>').appendTo($nameDownloadRow);
            $('<div class="bigChart"/>').appendTo(chartElms.$bigChartOuter);
            chartElms.$bigChartOuter.appendTo($mainGraphholder);

            chartElms.$smallChartOuter = $('<div id="reportsmallChartOuter-' + reportType + '" class="smallChartOuter"/>');
            var $smallChartHover = $('<div class="smallChartHover graphHoverImage"/>')
            $smallChartHover.appendTo(chartElms.$smallChartOuter);
            $('<div class="smallReportsName"/>').html(reportNames[reportType]).appendTo(chartElms.$smallChartOuter);
            $('<div class="smallChart"/>').appendTo(chartElms.$smallChartOuter);
            chartElms.$smallChartOuter.appendTo($graphSidePanel);
        }
        allReportsElms[reportType] = chartElms;
        return chartElms;
    }

    var reportCountsName = {}
    reportCountsName[reportTypes.PlatformPeople] = {
        totalDonerCount: 'Donors',
        totalWalkersCount: 'Attendees',
        totalFundraiserCount: 'Fundraisers'
    };

//load the report btm
    function createAndLoadReportBottom($outerElm, countsDict, reportType) {
        var countNames = reportCountsName[reportType];
        var $reportCount = $('<div class="reportCount graphBottom" />');
        for (var countKey in countsDict) {
            var $newReportCount = $reportCount.clone();
            $('<div class="reportCountsName smallReportsName"/>').html(numberWithCommas(countNames[countKey])).appendTo($newReportCount);
            $('<div class="ReportCountsCount" />').html(numberWithCommas(countsDict[countKey])).appendTo($newReportCount);
            $outerElm.append($newReportCount);
        }
    }

// once the report is loaded we switch the main report to the one clicked
    function switchReport($clickedElm) {
        var id = $clickedElm.attr('id');
        var reportType = id.substring(id.indexOf('-') + 1);
        if (reportType == getOpenReport()) {
            return;
        }
        var chartElms = createReportContainer(reportType);
        var $mainGraphholder = $('#mainGraphholder').children('#reportBigChartOuter-' + getOpenReport()).stop(true, true).fadeOut(function () {
            chartElms.$bigChartOuter.stop(true, true).fadeIn();
            var $bottomAllCounts = $('#bottomAllCounts');
            $bottomAllCounts.children().detach();
            if (chartElms.$bottomItems) {
                $bottomAllCounts.append(chartElms.$bottomItems);
            }
            openReport = reportType;
        });
    }

// returns the report thats open
    function getOpenReport() {
        return (openReport ? openReport : reportTypes.PlatformPeople);
    }

// makes the request to the server to get flot data.
    function loadPlot(data) {
        if (!data) {
            data = {};
        }

        $http({
            url: API_PREFIX + '/GetGraphReports',
            data: data,
            method: 'POST'
        }).then(response => {
            let resp = response.data;
            if (resp.reportData) {
                mapMetaNamesToIndex(resp.reportData);

                //prepare server from data.
                var peopleData = readyPeopleDataForPlot(allMetaNames, resp.reportData);
                var fundsData = readyFundsDataForPlot(allMetaNames, resp.reportData, resp.fundsTotalBeforeStartDate);

                //load prepared data.
                loadPeoplePlots(peopleData);
                loadFundsPlots(fundsData);

                //show the correct report.
                var reportToShow = openReport ? allReportsElms[openReport] : allReportsElms[reportTypes.PlatformPeople];
                reportToShow.$bigChartOuter.show();
                return;
            }
        }).finally(() => hideSpinner());
    }

    //will show spinner. depending on the background pass in color
    function showSpinner($elmForSpinner, white) {
        // $elmForSpinner.append('<div id="showingSpinner" class="loader' + (white ? '' : 'Not' ) + 'White" />');
        $elmForSpinner.append('<div id="showingSpinner" class="loading-indicator"><div class="loader"><div class="dot1"></div><div class="dot2"></div><div class="dot3"></div></div></div>');
        $('body').addClass('backdrop');
    }

    //will hide the open spinner
    function hideSpinner() {
        $('#showingSpinner').remove();
        $('body').removeClass('backdrop');
    }
}