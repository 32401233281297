angular.module('app').factory('extJsLoader', extJsLoader);

function extJsLoader($rootScope, iframeMessageHandler, $q, $location) {     
    
    var ExtJsIframeController = function(id) {
        this.id = id;
        this.iframeCreated = false;
        this.iframeLoaded = false;
        this.page;
        this.parentDivId;
    
        this.processMessage = function(message) {
            if (message.messageType === 'loaded') {
                this.iframeLoaded = true;
                $rootScope.$emit(message.messageType, [] );
            }
            if (message.messageType === 'updateHeight') {
                this.updateIframeSize(message.data);
            }
            if (message.messageType === 'moveDonations' || message.messageType === "moveRecurringDonations" || message.messageType === 'fulfillDonationPledges') {
                $rootScope.$emit(message.messageType, message.data);
            }
            if (message.messageType == 'emailFromReports') {
                $rootScope.$emit(message.messageType, [] );
            }
        }
    
        this.messageToExtJs = function(message) {
            message.pageSource = this.id;
            var iframe = document.querySelectorAll('iframe[name="'+this.id+'"]');
            if (iframe[0]) {
                iframe[0].contentWindow.postMessage(JSON.stringify(message), location.origin);
            }
        }
    
        this.messageToExtJsWithResponse = function(message) {
            var def = $q.defer();
            var responderId = new Date().valueOf();
            iframeMessageHandler.rbPlatIframeController.addPromise(responderId, def);
            message.respondToMessage = true;
            message.promiseId = responderId;
            this.messageToExtJs(message);
            return def.promise;
        },

        this.createAndLoadIframe = function (iframeSrc, parentDivId) {
            this.page = '';
            this.iframeCreated = true;
            this.parentDivId = parentDivId;
            
            return {
                src: iframeSrc + this.id,
                name: this.id,
            };
        }

        this.refreshInnerPage = function () {
            return this.messageToExtJsWithResponse({ messageType: 'refresh' });
        }

        this.loadInnerPage = function() {
            if (!this.page) {
                return $q.resolve('no page');
            }
            return this.messageToExtJsWithResponse({messageType: 'update', value: this.page});
        }

        this.updateInnerPage = function(page) {
            this.page = page;
            if (this.iframeLoaded) {
                return this.loadInnerPage();
            }
        }
        this.updateIframeSize = function(data) {
            angular.element(`#${this.parentDivId}-iframe`).find('iframe').height(data.height);
        }
        this.checkParams = function() {
            var params = $location.search();
            if (params.type) {
              // safely remove the type key from the path
              setTimeout(function() {
                var newSearch = Object.assign({}, params);
                delete newSearch.type;
                window.history.pushState({}, document.title, window.location.pathname + "?" + new URLSearchParams(newSearch).toString());
              }, 1000);

              this.messageToExtJs({
                messageType: 'openMini', 
                params: params
              });
            }
        }
    }

    let extJsController = new ExtJsIframeController(new Date().valueOf());
    return {
        extJsController
    };
}