angular.module('app').factory('orgProcessorsService', orgProcessorsService);

function orgProcessorsService($http, $q, API_PREFIX, entityService){
  const url = `${API_PREFIX}PaymentProcessor`;

  const PROCESSOR_CONFIG_ACTIONS = {
    CREATE: 1,
    EDIT: 2,
    REMOVE: 3,
    ADOPT_PARENT: 4,
    OVERRIDE_PARENT: 5,
    DELETE_CHILD_OVERRIDES: 6,
  };

  const PROCESSOR_INSTANCE_ACTIONS = {
    MOVEUP: 1,
    MOMVEDOWN: 2,
    REMOVE: 3,
    ADD: 4,
  };

  return {
    getProcessors,
    getEntityProcessorSettings,
    updateProcessorInstanceSetting,
    getAvailableProcessors,

    adoptParent: () => sendCreateDeleteRequest(PROCESSOR_CONFIG_ACTIONS.ADOPT_PARENT),
    overrideParent: () => sendCreateDeleteRequest(PROCESSOR_CONFIG_ACTIONS.OVERRIDE_PARENT),
    clearOverrides: () => sendCreateDeleteRequest(PROCESSOR_CONFIG_ACTIONS.DELETE_CHILD_OVERRIDES),

    moveInstanceUp: data => sendUpdateDeleteInstanceRequest(PROCESSOR_INSTANCE_ACTIONS.MOVEUP, data),
    moveInstanceDown: data => sendUpdateDeleteInstanceRequest(PROCESSOR_INSTANCE_ACTIONS.MOMVEDOWN, data),
    removeInstance: data => sendUpdateDeleteInstanceRequest(PROCESSOR_INSTANCE_ACTIONS.REMOVE, data),
    addInstance: data => sendUpdateDeleteInstanceRequest(PROCESSOR_INSTANCE_ACTIONS.ADD, data)
  };

  function getProcessors() {
    return $http.post(`${url}/GetAllProcessors`)
      .then(response => response.data.allProcessors)
  }  
  
  function getAvailableProcessors() {
    let entityId = entityService.getOpenedEntityId();
    
    return $http({
      url: `${url}/GetAvailableProcessors`,
      headers: { entityId },
      method: 'POST'
    }).then(response => response.data.availableProcessors)
  }

  function getEntityProcessorSettings() {
    let entityId = entityService.getOpenedEntityId();
    
    return $http({
      url: `${url}/GetEntityProcessorSettings`,
      headers: { entityId },
      method: 'POST'
    }).then(response => response.data);
  }

  function updateProcessorInstanceSetting(data) {
    return $http({
      url: `${url}/UpdateProcessorInstanceSetting`,
      data,
      method: 'POST'
    }).then(response => response.data);
  }

  function sendCreateDeleteRequest(actionType, data) {
    data = data || {};
    data.actionType = actionType;
    return sendRequest('CreateDeleteProcessorSettings', data);
  }

  function sendUpdateDeleteInstanceRequest(actionType, data) {
    data = data || {};
    data.actionType = actionType;
    return sendRequest('UpdateDeleteProcessorInstance', data);
  }

  function sendRequest(actionName, data) {
    let entityId = entityService.getOpenedEntityId();
    return $http({
      url: `${url}/${actionName}`,
      data,
      headers: { entityId },
      method: 'POST'
    }).then(response => response.data);
  }
}
