angular
    .module('app')
    .directive('customValidation', function() {
      return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {

          if(attrs.customValidation === 'goalUrl') {
            ctrl.$validators.goalUrl = function(modelValue, viewValue) {
              if (ctrl.$isEmpty(modelValue)) {
                return true;
              }

              let regex = /^[a-zA-Z0-9\-]*$/;
              return String(viewValue).match(regex);
            };
          }

          if(attrs.customValidation === 'decimal') {
            ctrl.$validators.decimal = function(modelValue, viewValue) {
              if (ctrl.$isEmpty(modelValue)) {
                return true;
              }

              let regex = /^\d+(\.\d+)?$/;
              return String(viewValue).match(regex);
            };
          }
        }
      };
    })
