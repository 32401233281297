angular
    .module('app')
    .service('emailService', emailService);

function emailService(entityService, messageService, formService, requestHelperService, $http, $q, API_PREFIX, $mdDialog, $timeout, $mdPanel) {
    const vm = this;
    let rh = requestHelperService.getInstance('Email');
    
    const EMAIL_CONFIG_OPTIONS = {
        transactionEmails: 600,
        adminTemplates: 601,
        fundraisingEmails: 602,
        triggers: 603,
    };
    
    vm.api = {
        EMAIL_CONFIG_OPTIONS,
        openEmail,
        getEmailEditorButtons,
        getEmailEditorOptionsButton,
        addComposeEmailLogic,
        showSaveAsEmailTemplateDialog,
        showActivateOptions,
        
        // Macros
        getTemplateMacros: (item) => rh.sendEntityRequest('TemplateMacros', { item: formService.getBaseFormItem(item) } ),

        // Sent Emails
        getSystemEmailStats: (id) => rh.sendCampaignRequest('SentSystemEmailResult', { emailProcessedId: id }),
        getEmailStats: (id) => rh.sendCampaignRequest('BlastsResult', { id: id }),
        getRecipients: (id) => rh.sendCampaignRequest('BlastsRecipients', { id: id }),
        getPendingRecipients: (id) => rh.sendCampaignRequest('PendingBlastsRecipients', { id: id }),
        resendEmail: (id, useUpdatedTemplate) => rh.sendCampaignRequest('ResendEmail', { ProcessedEmailID: id, UseUpdatedTemplate: useUpdatedTemplate }, rh.successToastMessage('Email queued to be sent.')),
        
        // Get Email Templates
        getAdminEmailTemplates: () => rh.sendEntityRequest('AllAdminEmailTemplates'),
        getFundraiserEmailTemplates: () => rh.sendEntityRequest('AllFundraiserEmailTemplates'),
        getTriggerEmailTemplates: () => rh.sendEntityRequest('AllTriggerEmailTemplates'),
        getTransactionalEmailTemplates: () => rh.sendEntityRequest('AllTransactionalEmailTemplates'),

        // Email Template Modifications
        createOrUpdateEmailTemplate: (item) => rh.sendEntityRequest('CreateOrUpdateEmailTemplate', { item: formService.getBaseFormItem(item) }, { advancedErrorEnabled: false } ),
        deleteEmailTemplate: (item) => rh.sendEntityRequest('DeleteEmailTemplate', { item: formService.getBaseFormItem(item) } ),
        clearEmailTemplateOverrides: (item) => rh.sendEntityRequest('DeleteEmailTemplateOfChildren', { settingId: item.configOptionId, recordId: item.Id }),

        // Trigger Email Modifications
        createOrUpdateTriggerEmailTemplate: (item) => rh.sendEntityRequest('CreateOrUpdateTriggerEmailTemplate', { item: formService.getBaseFormItem(item) }, { advancedErrorEnabled: false } ),

        // Email Blasts
        deletePendingEmailBlast: (id) => rh.sendCampaignRequest('DeletePendingBlast', { id } ),
        createBlast: (item, scheduled) => rh.sendCampaignRequest('CreateBlast', { item: formService.getBaseFormItem(item) }, rh.successToastMessage(scheduled ? 'Email has been scheduled.' : 'Email queued to be sent.') ),
        updateBlast: (item) => rh.sendCampaignRequest('UpdatePendingBlast', { item: formService.getBaseFormItem(item) } ),
        saveBlastAsEmailTemplate: (templateName, item) => rh.sendEntityRequest('SaveAsTemplate', { templateName, item: formService.getBaseFormItem(item) } ),
        getComposeEmailForm: () => rh.sendCampaignRequest('GetComposeEmailForm' ),
        
        // Previews/Tests
        sendTestEmail: (item, testIds, campaignId, adminEmail) => rh.sendCampaignRequest('SendTestEmail', { item, testIds }, rh.successToastMessage(`A test email has been sent to you ${adminEmail ? 'at ' + adminEmail : ''}.`), campaignId),
        getEmailPreview: (item, testIds, campaignId) => rh.sendCampaignRequest('GetEmailPreview', { item, testIds }, { errorToastEnabled: false }, campaignId),
        getEmailSampleObjects: (endpoint, eventId, filter, sort, dir) => rh.sendExtJsRequest(endpoint, { page: 1, start: 0, limit: 10, filter, sort, dir }, eventId),
        
        // Header/Footer
        updateHeaderOrFooter: (config) => rh.sendEntityRequest('UpdateEmailHeaderOrFooter', config),
    }
    
    return vm.api;
    
    function openEmail(item) {
        if (!item.data) {
            item.data = {};
        }
        
        if (item.data.macros) {
            CKEDITOR.config.macros = item.data.macros;
        } else {
            vm.api.getTemplateMacros(item).then(r => {
                item.data.macros = r.items;
                CKEDITOR.config.macros = r.items
            });
        }
        
        let emailPreviewField = formService.getFieldFromItem(item, 'Preview');
        if (emailPreviewField) {
            emailPreviewField.hide = (i) => !i.InPopup
        }
        
        let disableHeaderField = formService.getSubFieldFromItem(item, 'Options', 'Disable Header');
        if (disableHeaderField) {
            disableHeaderField.change = (i, value) => formService.getFieldFromItem(i, 'Body').displayOptions.editorFrame.displayPrepend = !value;
        }
        let disableFooterField = formService.getSubFieldFromItem(item, 'Options', 'Disable Footer');
        if (disableFooterField) {
            disableFooterField.change = (i, value) => formService.getFieldFromItem(i, 'Body').displayOptions.editorFrame.displayAppend = !value;
        }
        
        createEditorOptionsMenu(item);
    }
    
    function showActivateOptions(item, forTextDisplay) {
        // Always show Active/Inactive text for all emails
        if (forTextDisplay) {
            return true;
        }
        
        return !shouldHideDisable(item);
    }
    
    function shouldHideDisable(item) {
        return (item.Flags & 2) === 2; // EmailTemplateFlags.HideDisable
    }
    
    function getEmailEditorOptionsButton() {
        return {
            label: 'Options',
            toolbar: 'floating',
            action: (item, evnt, isEditable) => showEditorOptionsMenu(item, evnt, isEditable),
            hide: (item) => _.every(item.Fields, x => !x.displayOptions || (!x.displayOptions.inEditor || x.displayOptions.hidden)),
        };
    }
    
    function getEmailEditorPreviewPopupButton() {
        return {
            label: 'Preview',
            icon: 'preview',
            toolbar: 'floating',
            action: (item, evnt) => item.InPopup ? goToNextTabGroup(item) : showEmailPreviewDialog(item, evnt.element.$).catch(error => messageService.showErrorToast(error.msg, error.parent, error.hideDelay)),
        };
    }

    function getEmailEditorButtons() {
        return [
            getEmailEditorOptionsButton(),
            getEmailEditorPreviewPopupButton(),
        ];
    }
    
    function goToNextTabGroup(item) {
        item.data.$popupScope.$broadcast('nextGroup');
    }
    
    function createEditorOptionsMenu(email) {
        
        if (!email.data) {
            email.data = {};
        }

        email.data.inEditorFormDirty = false;
        
        email.data.editorOptionsPanel = $mdPanel.create({
            attachTo: angular.element(document.body),
            controller: ($scope) => {
                $scope.$watch('vm.item.data.inEditorForm.$dirty', (newVal, oldVal) => {
                    // Only act on changes done by a user, not the initial state update
                    // If oldVal == undefined that indicates that this component was just initialized in which
                    // case do not set this property because it will be changed by the "on open" event
                    if (newVal != oldVal && newVal !== undefined && oldVal !== undefined) {
                        email.data.inEditorFormDirty = newVal;
                    }
                })
            },
            controllerAs: 'vm',
            locals: {
                fields: email.Fields,
                id: email.Id,
                item: email,
            },
            template: `<md-card class="editor-options-card">
                        <md-card-content>
                            <form name="vm.item.data.inEditorForm">
                                <form-single-field ng-repeat="field in vm.fields"
                                       field="field"
                                       id="vm.id"
                                       item="vm.item"
                                       editable="vm.editable"
                                       form-group-active="true"
                                       ng-if="field.displayOptions.inEditor"
                                >
                                </form-single-field>
                            </form>
                        </md-card-content>
                    </md-card>`,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true
        });
        
        // Attach to the dom so that the field components are created so that any change handlers can
        // function even before the options panel is opened by the user
        email.data.editorOptionsPanel.attach().then(angular.noop, angular.noop);
    }
    
    function showEditorOptionsMenu(email, evnt, isEditable) {
        
        if (!email.data) {
            email.data = {};
        }
        
        let wasDirty = email.data.inEditorFormDirty;
        
        // Detach and re-attach the panel
        // Ensures positioning is correct and so that controller locals can be updated (e.g. editable)
        email.data.editorOptionsPanel.detach().then(() => {
            let targetElement = evnt.container.getParent().getParent().findOne('.cke_button__options').$;

            email.data.editorOptionsPanel.config.locals.editable = isEditable;

            email.data.editorOptionsPanel.updatePosition($mdPanel.newPanelPosition()
                .relativeTo(targetElement)
                .addPanelPosition(
                    $mdPanel.xPosition.CENTER,
                    $mdPanel.yPosition.BELOW
                ));

            email.data.editorOptionsPanel.updateAnimation($mdPanel.newPanelAnimation()
                .openFrom(targetElement)
                .duration(200)
                .closeTo(targetElement)
                .withAnimation($mdPanel.animation.SCALE));
    
            email.data.editorOptionsPanel.open().then(() => {
                if (wasDirty) {
                    email.data.inEditorForm.$setDirty();
                }
            });
        });
    }
    
    function showSaveAsEmailTemplateDialog(item) {
        var prompt = $mdDialog
            .prompt({
                bindToController: true,
                locals: {
                    required: true,
                },
            })
            .multiple(true)
            .title('Save as Template')
            .htmlContent(`Please enter the Template Name for the new template`)
            .ok('Save')
            .cancel('Cancel');

        return $mdDialog.show(prompt).then(templateName => {
            return vm.api.saveBlastAsEmailTemplate(templateName, item);
        }, $q.reject());
    }

    function showEmailPreviewDialog(email, targetElement, $event) {
        
        let parent = targetElement.closest('md-card');
        
        if (!formService.getFieldValueFromItem(email, 'Subject')) {
            return $q.reject({msg: 'Email subject is required', parent, hideDelay: 3000});
        }

        if (!formService.getFieldValueFromItem(email, 'Body')) {
            return $q.reject({msg: 'Email body is required', parent, hideDelay: 3000});
        }
        
        return $mdDialog
            .show({
                multiple: true,
                clickOutsideToClose: true,
                templateUrl: `email-preview-dialog.tpl.html`,
                controllerAs: 'vm',
                bindToController: true,
                targetEvent: $event,
                locals: {
                    email,
                    title: 'Email Preview',
                },
                controller: emailPreviewDialogController,
            })

        function emailPreviewDialogController($mdDialog) {
            this.cancel = function() {
                $mdDialog.hide();
            }
        }
    }
    
    function overrideEmailConfirm() {
        return $mdDialog.show(
            $mdDialog.confirm()
                .multiple(true)
                .clickOutsideToClose(true)
                .title('Unsaved Changes')
                .htmlContent(`Changing templates will lose any unsaved changes. Do you want to continue?`)
                .targetEvent(event)
                .ok('Change Template')
                .cancel('Cancel')
        );
    }

    function addComposeEmailLogic(email) {
        if (!email) return;

        let templateField = formService.getFieldFromItem(email, 'Template');
        templateField.loading = true;

        vm.api.getAdminEmailTemplates().then((r) => {
            let templates = r.items;
            templateField.loading = false;

            templateField.options = _.reduce(templates, (result, value) => {
                result[formService.getFieldValueFromItem(value, 'Id')] = value.Title;
                return result;
            }, {})
            
            let fieldsToUpdate = [
                'Subject',
                'Body',
                ['Options', 'Disable Header'],
                ['Options', 'Disable Footer']
            ];

            templateField.addPrefillOptions && templateField.addPrefillOptions(templates, fieldsToUpdate, overrideEmailConfirm, formService.getItemById);
        });
    }

}