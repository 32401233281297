angular.module('app').component('enduranceChallengeTemplate', {
  controllerAs: 'vm',
  controller: enduranceChallengeTemplateController,
  templateUrl: 'endurance-challenge-template.tpl.html',
  bindings: {
    defaultActivityTypes: '<',
    unitOptions: '<',
    durationFormats: '<',
    distanceFormats: '<',
    integrationProviders: '<',
    isOpen: '<',
    showForm: '=',
    disabled: '<',
    existingChallenge: '<',
    permission: '<'
  }
});

function enduranceChallengeTemplateController($scope, $q, $timeout, $location, $mdDialog, $mdConstant, mdcDateTimeDialog, enduranceChallengeService, settingsService,entityService) {
  const vm = this;
  vm.wasOpenOnInit = false;
  vm.timeZoneSearch = timeZoneSearch;
  vm.displayDateDialog = displayDateDialog;
  vm.displayTimeDialog = displayTimeDialog;
  vm.showChallengeSettings =  false;
  vm.endurancePledgeSettings =  false;
  vm.separatorKeys = [
    $mdConstant.KEY_CODE.ENTER,
    $mdConstant.KEY_CODE.COMMA,
    $mdConstant.KEY_CODE.TAB,
  ];

  vm.PROGRESS_TO = {
    DURATION: 1,
    DISTANCE: 2,
    OTHER: 3
  };
    vm.$onInit = function() {
        if (vm.isOpen) {
            vm.wasOpenOnInit = true;
        }
        showPledgeSettings();
        var showParam = $location.search()['advanced'];
        vm.showChallengeSettings = showParam && showParam.toLowerCase() === 'true'  ;
    };
  
    vm.unitOptionsIntegrationsFilter = function (selectedIntegrations) {
    return function (e) {
      var thisSupportedIntegrations = e.IntegrationProviders || [];
      var matches = true;

      thisSupportedIntegrations = thisSupportedIntegrations.map(value => value.toString());

      if (selectedIntegrations !== undefined) {
        angular.forEach(selectedIntegrations, function (integration) {
          if (thisSupportedIntegrations.indexOf(integration.toString()) === -1) {
            matches = false;
          }
        });
      }

      return matches;
    }
  }

    vm.goalPlaceholderOrUnit = function(challengeForm, returnPlaceholder) {
        var unit;
        var placeholder;
        if (challengeForm && challengeForm.ProgressTo) {
            switch(challengeForm.ProgressTo.UnitType) {
                case vm.PROGRESS_TO.DURATION:
                    if(challengeForm.DurationFormat) {
                        var durationFormat = vm.durationFormats.filter(x => x.Value === challengeForm.DurationFormat)[0];

                        if(durationFormat.Placeholder) {
                            placeholder = durationFormat.Placeholder;
                        } else {
                            unit = durationFormat.Display
                        }
                    }
                    break;
                case vm.PROGRESS_TO.DISTANCE:
                    if(challengeForm.DistanceFormat) {
                        var distanceFormat = vm.distanceFormats.filter(x => x.Value === challengeForm.DistanceFormat)[0];
                        unit = distanceFormat.DisplayShort;
                    }
                    break;
                case vm.PROGRESS_TO.OTHER:
                    unit = challengeForm.ChallengeUnit;
                    break;
                default:
                    if(challengeForm.ProgressTo.OtherValue) {
                        unit = challengeForm.ProgressTo.OtherValue;
                    }
                    break;
            }
        }
        
        if (returnPlaceholder && placeholder) {
            return placeholder;
        } else if(unit) {
            return unit; //Unit abbreviations are plural by default
        }

        return '';
    }
    
  $timeout(setForm);
    function setForm() {
        vm.challengeForm = prepareChallengeForDisplay(vm.existingChallenge) || { AllowedActivityTypes : [], UseImperialSystem: true };
        vm.challengeForm.EndDateTime = {
          dateTime: vm.challengeForm.EndDateTime ? new moment.utc(vm.challengeForm.EndDateTime) : undefined,
          timeZone: vm.challengeForm.EndDateTimeZoneValue ? getTimezone(): ''
      };
      
      vm.editing = !vm.existingChallenge;
    }

    function getTimezone(){
      return {
        display: vm.challengeForm.EndDateTimeZoneValue? vm.challengeForm.EndDateTimeZoneValue.replace('/', ' - ').replace('_', ' '): '',
        value: vm.challengeForm.EndDateTimeZoneValue,
    };
    }

  vm.onCancel = function() {
      vm.showForm = false;
      vm.editing = false;
      
      if (!vm.wasOpenOnInit) {
          vm.isOpen = false;
      }
  }
    function refreshChallenges(response) {
        $scope.$emit('refreshChallenges', response);
    }
    vm.onSave = function () {
        var challenge = prepareChallengeForSave(vm.challengeForm);

        var saveFunc;
        if (vm.existingChallenge) {
            saveFunc = enduranceChallengeService.updateEnduranceChallenge
        } else {
            saveFunc = enduranceChallengeService.createEnduranceChallenge
        }
        saveFunc(challenge).then(function (r) {
          if (r.success && r.challenges) {
            refreshChallenges(r);
            vm.showForm = false;
          }
        });
    };

    function showPledgeSettings() {
      entityService.getEntitySettings(entityService.getOpenedEntityId()).then(entity => {
        vm.endurancePledgeSettings =  entity.settings.EndurancePledgesEnabled;
      });
    }
    vm.enableEdit = function () {
        vm.editing = true;
        vm.isOpen = true;
    }
    
    vm.open = function() {
        vm.isOpen = true;
    }

    vm.close = function() {
        vm.isOpen = false;
    }

    vm.confirmDeletion = function(event) {
      var confirm = $mdDialog
        .confirm()
        .title('Are you sure?')
        .textContent(
          `This endurance challenge will be deleted. All related endurance progress will be removed from campaign sites and fundraisers will no longer be able to log activities towards this challenge. This action cannot be undone.`
        )
        .targetEvent(event)
        .ok('Delete')
        .cancel('Cancel');

        $mdDialog.show(confirm).then(
          function() {
            enduranceChallengeService
              .removeEnduranceChallenge(vm.existingChallenge)
              .then(refreshChallenges);
          },
          function() {
            return;
          }
        );
    }

    function timeZoneSearch(query) {
      const lowerCaseQuery = query.toLowerCase();

      return settingsService.getIanaTimeZones()
          .then(zones => zones.filter(zone => zone.display.toLowerCase().includes(lowerCaseQuery)));
  }

  function displayDateDialog(dateField) {
      var dateVal = vm.challengeForm[dateField] ? vm.challengeForm[dateField].dateTime : moment().utc();
      
      mdcDateTimeDialog.show({
        time: false,
        date: true,
        currentDate: dateVal,
        disableParentScroll: true
    })
      .then(function (date) {
          vm.challengeForm[dateField].dateTime = new moment.utc(date);
      }, function() {
      });
};

function displayTimeDialog(dateField) {
  mdcDateTimeDialog.show({
      time: true,
      date: false,
      shortTime: true,
      currentDate: vm.challengeForm[dateField].dateTime,
      disableParentScroll: true
  })
    .then(function (date) {
        vm.challengeForm[dateField].dateTime = new moment.utc(date);
    }, function() {
    });
};

    function prepareChallengeForDisplay(challenge) {
      if (!challenge) return;

      var displayChallenge = JSON.parse(angular.toJson(challenge));
      
      if(vm.unitOptions) {
        var triedGettingCustomUnit = false;

        if (challenge.ProgressTo == vm.PROGRESS_TO.OTHER) {
          triedGettingCustomUnit = true;
          displayChallenge.ProgressTo = vm.unitOptions.filter(x => x.OtherValue == challenge.ChallengeUnit)[0];
        }
        if (!triedGettingCustomUnit || !displayChallenge.ProgressTo) {
          displayChallenge.ProgressTo = vm.unitOptions.filter(x => x.UnitType == challenge.ProgressTo)[0];
        }
      }

      return displayChallenge;
    }

    function prepareChallengeForSave(challenge) {
      var challengePrepared = JSON.parse(angular.toJson(challenge)); // chips API has no track-by, need to remove angular $$hashkey
        

      if(!vm.challengeForm.RequiresEndDate){
        challengePrepared.EndDate = undefined;
        challengePrepared.EndDateTimeZoneValue = undefined;
        challengePrepared.EndDateTimeZone = undefined;
        challengePrepared.EndDateTime = undefined;
      } else {
        challengePrepared.EndDateTimeZoneValue = challengePrepared.EndDateTime.timeZone ? challengePrepared.EndDateTime.timeZone.value : undefined;
        challengePrepared.EndDateTime = challengePrepared.EndDateTime.dateTime ? challengePrepared.EndDateTime.dateTime.replace('Z', ''): challengePrepared.EndDateTime.dateTime;
      }
      
      if (challenge.ProgressTo) {
        if (challenge.ProgressTo.OtherValue) {
          challengePrepared.ChallengeUnit = challenge.ProgressTo.OtherValue;
          challengePrepared.ProgressTo = vm.PROGRESS_TO.OTHER;
        } else {
          challengePrepared.ProgressTo = challenge.ProgressTo.UnitType;
        }
      }

      return challengePrepared;
    }

    function addedActivityNames() {
      return vm.challengeForm.AllowedActivityTypes.map(x => (x.NamePresent || x.Name).toLowerCase()) || [];
    }

    function remainingDefaults() {
      return vm.defaultActivityTypes.filter(x => !addedActivityNames().includes(x.Name.toLowerCase())) || [];
    }

    vm.activityFilter = function(query) {
      var remainingOptions = remainingDefaults();
        if (query) {
            return remainingOptions.filter((x) => {
                return x.Name.toLowerCase().indexOf(query.toLowerCase()) === 0;
            });
        }
        return remainingOptions;
    }

    vm.transformActivityChip = function(chip) {
        // If it is an object, it's already a known chip
        if (angular.isObject(chip)) {
            return chip;
        }

        var matchingDefault = remainingDefaults().find(x => x.Name.toLowerCase() == chip.toLowerCase());
        if (matchingDefault) {
            return matchingDefault;
        }

        var typeAdded = vm.challengeForm.AllowedActivityTypes.find( x=> (x.NamePresent || x.Name).toLowerCase() == chip.toLowerCase());
        if (typeAdded) return null;

        var newChip = { Name: chip };
        // var prompt = $mdDialog
        //     .prompt()
        //     .title('Add Past-Tense')
        //     .textContent(`Add a past-tense version of '${chip}'` )
        //     .placeholder('Past-tense')
        //     .cancel('Cancel')
        //     .ok('Save');

        //  $mdDialog.show(prompt)
        // .then(function(input) {
        //     if(!input) {
        //         vm.challengeForm.AllowedActivityTypes.pop();
        //     } else {
        //         newChip.pastTense = input;
        //     }
        // },
        // function() {
        //     vm.challengeForm.AllowedActivityTypes.pop();
        // });

        return newChip;
    }
}