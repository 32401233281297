angular.module('app').component('donorCampaigns', {
    controllerAs: 'vm',
    controller: donorCampaignsController,
    bindings: { 
      $router: '<'
    },
    require: {
        parentComponent: '^transactions'
    },
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
  });
  
  function donorCampaignsController(extJsLoader) {
    const vm = this;
    this.$routerOnActivate = function(next, prev) {
      extJsLoader.extJsController.updateInnerPage('Transactions-DonorCampaigns');
      this.parentComponent.showIframe = true;
    }
  }
  