angular
  .module('app')
  .component('orgIntegrations', {
    templateUrl: 'org-integrations.tpl.html',
    bindings: {
      $router: '<'
    },
    controller: IntegrationController,
    controllerAs: 'vm'
  });

function IntegrationController(orgIntegrationsService) {
  const vm = this;

  vm.modal = {
    showing: {},
    id: null
  };
  vm.addingIntegration = false;
  vm.addIntegration = () => vm.addingIntegration = true;

  vm.showLog = function(type, id) {
    vm.modal.showing = {[type]: true}
    vm.modal.id = id;
  };

  vm.loadIntegrations = () => {
    orgIntegrationsService.getIntegrations()
      .then(integrations => {
        vm.integrations = integrations;
      })
      .catch(() => {
        vm.error = "Unable to load integrations. Please contact support"
      });
  };

  vm.loadIntegrations();
}
