angular
  .module('app')
  .component('systemAdvanced', {
    controller: systemAdvancedController,
    template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'></form-template>`,
    bindings: { 
      $router: '<'
    },
    require: {
        parentComponent: '^settings'
    },
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function systemAdvancedController(extJsLoader, configSettingsService) {
  const vm = this;
  this.$routerOnActivate = function(next, prev) {
    this.parentComponent.showIframe = false;
  };

  vm.sections = [];
  configSettingsService.getSystemAdvancedSettings().then(function(r) {
    if (r.success && r.form) {
      vm.sections = r.form.FormSections;
    } else {
      
    }
  });

  vm.onChange = function(config) {
    return configSettingsService.updateSetting(config);
  };
}