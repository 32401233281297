angular.module('app').factory('orgIntegrationsService', orgIntegrationsService);

function orgIntegrationsService($http, $q, API_PREFIX){
  const url = `${API_PREFIX}Integration`;

  return {
    getIntegrations,
    deleteIntegration,
    getIntegrationCampaigns,
    
    hasLogs,
    canDelete,
    hasInformation
  };

  function getIntegrations() {
    return $http.post(`${url}/Integrations`)
      .then(response => response.data.integrations)
  }
  
  function getIntegrationCampaigns(type, id) {
    return $http.post(`${url}/IntegrationCampaigns`, { type, id })
      .then(response => response.data.campaigns);
  }

  function deleteIntegration(type, id) {
    if (!canDelete(type)) {
      return $q.reject("Unable to delete this type of integration. Please contact support");
    }

    return $http.post(`${url}/RemoveIntegration`, { type, id })
      .then(response => response.data);
  }

  function hasLogs(type) {
    return ['Salesforce', 'ClearView', 'Neon'].indexOf(type) >= 0;
  }

  function canDelete(type) {
    return ['Salesforce'].indexOf(type) >= 0;
  }

  function hasInformation(type) {
    return ['Salesforce', 'ClearView'].indexOf(type) >= 0;
  }
}
