  angular
    .module('app')
    .component('formsRouter', {      
      controllerAs: 'vm',
      bindings: { $router: '<' },
      controller: formsRouter
    });
  
    function formsRouter(routingService) {
      this.$routerOnActivate = function(p, n) {
        routingService.getNextRoute(this.$router, 'formsTab');
      }
    }