angular
  .module('app')
  .directive('sortable', Sortable);

function Sortable() {
 return {
		restrict: 'A',
		scope: {
			sortable: '='
        },
		link: function (scope, element, attr) {
			element.sortable(scope.sortable);
		}
	};
}