angular.module('app').component('modalAutomationLogs', {
    bindings: {
        show: '='
    },
    controller: ModalAutomationLogsController,
    controllerAs: 'vm'
});

function ModalAutomationLogsController($scope, $mdDialog) {
    const vm = this;

    $scope.$watch('vm.show', () => {
        if (vm.show) {
            showModal();
        }
    });

    function showModal() {
        $mdDialog
            .show({
                templateUrl: 'modal-automation-logs.tpl.html',
                clickOutsideToClose: true,
                controller: ModalAutomationLogsContentController,
                controllerAs: 'vm'
            })
            .finally(() => {
                vm.show = false;
            });
    }
}

function ModalAutomationLogsContentController(
    $mdDialog, entityService, automationService, profileService, agGridService)
{
    const vm = this;
    vm.closeModal = closeModal;
    vm.loadData = loadData;
    vm.loading = true;
    
    var columnDefs = [
        {
            colId: 'EntityActionLogId',
            field: 'EntityActionLogId',
            headerName: 'LogID',
            hide: true,
            filter: false,
            enableRowGroup: false
        },
        {
            colId: 'ActionID',
            field: 'ActionID',
            headerName: 'ActionID',
            hide: true
        },
        {
            colId: 'EntityID',
            field: 'EntityID',
            headerName: 'EntityID',
            hide: true,
            filter: false,
            enableRowGroup: false
        },
        {
            colId: 'EventID',
            field: 'EventID',
            headerName: 'EventID'
        },
        {
            colId: 'Action',
            field: 'Action',
            headerName: 'Action'
        },
        {
            colId: 'Status',
            field: 'Status',
            headerName: 'Status'
        },
        {
            colId: 'CreatedOn',
            field: 'CreatedOn',
            headerName: 'Occured At',
            filter: false,
            valueFormatter: agGridService.formatters.dateTime,
            enableRowGroup: false,
        },
        {
            colId: 'Message',
            field: 'Message',
            headerName: 'Message'
        },
    ]

    vm.gridOptions = agGridService.createNewGrid(columnDefs, {key:'modal-automation-logs'}, { onGridReady: loadData } );
    
    profileService.getProfileInfo().then(profile => {
        vm.gridOptions.csvFilename = `admin_${profile.userName.split(' ')[0]}_${moment().format('YYYYMMDD-HHmm')}.csv`
    });

    function loadData() {
        vm.loading = true;
        var entityId = entityService.getOrgEntityId();
        return automationService
            .getEntityActionErrorLogs(entityId)
            .then(logs => {
                vm.gridOptions.api.setRowData(logs.data);
            })
            .finally(() => {
                vm.loading = false
            });
    }

    function closeModal() {
        $mdDialog.cancel();
    }
}
