angular
  .module('app')
  .component('modalOrgs', {
    bindings: {
      show: '=',
      orgs: '='
    },
    controller: ModalOrgsCtrl,
    controllerAs: 'vm'
  });


function ModalOrgsCtrl($scope, $mdDialog, profileService) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {

	profileService.getProfileInfo()
	.then((profileInfo) => {
		var orgs =  profileInfo.orgs;
		
		$mdDialog
		  .show({
			templateUrl: 'modal-orgs.tpl.html',
			controller: ModalOrsContentCtrl,
			controllerAs: 'vm',
			locals: {
			  orgs: orgs
			},
			clickOutsideToClose: true
		  })
		  .finally(() => {
			vm.show = false;
		  });
	});

    
  }
}

function ModalOrsContentCtrl($rootScope, $q, $location, $mdDialog, orgService, campaignService, profileService, orgs) {
  const vm = this;
  vm.getCampaignData = getCampaignData;
  vm.orgs = orgs;
  vm.setOrg = setOrg;
  vm.closeModal = closeModal;

  function getCampaignData(entityId) {
    campaignService
      .openGroup(0, entityId, true, undefined)
      .then((campaignsData) => {
        $rootScope.$emit('orgs.loaded', campaignsData);
      })
  }

  function setOrg(org) {
    location.href = '/org/' + org.orgId;
  }

  function closeModal() {
    $mdDialog.cancel();
  }
}
