angular.module('app').factory('fileUpload', fileUpload);

function fileUpload($rootScope) {     
    

    return {
        onUploadFile
    };

    function onUploadFile(event, entityId, imgType, objectId) {

        event.preventDefault();
    
        let file = event.currentTarget.files[0];
    
        if(!file) return; //if open upload window, but click cancel
    
        let reader = new FileReader();
    
        reader.readAsDataURL(file);
        reader.onload = event => {
    
          $rootScope.$emit('file.uploaded', {
            img: event.target.result,
            entityId: entityId,
            imgType: imgType,
            objectId: objectId
          });
    
        }
    }
}