var routeConfig = [      
  {
    path: '/donations',
    name: 'Donations',
    component: 'donations',
    useAsDefault: true,
    data: {
      resource: 'DONATION'
    }
  },
  {
    path: '/recurringDonations',
    name: 'Recurring Donations',
    component: 'donationsRecurring',
    data: {
      resource: 'DONATION_RECURRING',
      routeFunc: function(entity) {
        return entity.settings.EverHadRecurring && entity.type != 'Standalone Ticketing';
      }
    }
  },
  {
    path: '/registrations',
    name: 'Registrations',
    component: 'registrations',
    data: {
      resource: 'TRANSACTION_REGISTRATIONS',
      routeFunc: function(entity) {
        return entity.settings.HasRoleRegistration;
      }
    }
  },
  {
    path: '/matches',
    name: 'Matches',
    component: 'donorCampaigns', 
    data: {
      resource: 'DONOR_CAMPAIGNS',
      routeFunc: function(entity) {
        return entity.settings.FundraisingSurgeApplicable;
      }
    }
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: 'tickets',
    data: {
      resource: 'TRANSACTIONS_TICKETS',
      routeFunc: function(entity) {
        return entity.settings.EventHasTickets && entity.type != 'Donation' && entity.type != 'Global Donation Page';
      }
    }
  },
  {
    path: '/credits',
    name: 'Credits',
    component: 'credits',
    data: {
      resource: 'CREDITS',
    }
  },
  {
    path: '/credits/new/:ids',
    name: 'New Credit',
    component: 'credits',
    data: {
      showNewForm: true,
      resource: 'CREDITS',
      showFunc: function() {return false;},
    }
  },
  {
    path: '/endurancePledges',
    name: 'Endurance Pledges',
    component: 'endurancePledges',
    data: {
      resource: 'ENDURANCE_PLEDGES',
      routeFunc: function(entity) {
        return entity.settings.EndurancePledgesEnabled;
      }
    }
  },
];


angular
  .module('app')
  .component('transactions', {
    templateUrl: 'transactions.tpl.html',
    controllerAs: 'vm',
    bindings: { $router: '<' },
    require: {
      campaigns: '^campaigns'
    },
    controller: transactionsController,
    $routeConfig: routeConfig,
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
  });

  angular.module('app').constant('transactionRouteConfig',routeConfig);

  function transactionsController($rootScope, routingService, entityService, extJsLoader, iframeMessageHandler, $timeout, $window ) {
    const vm = this;
    vm.loading = true;
    vm.navs = routeConfig;
    vm.iframeData = {};
    vm.showIframe = false;
    vm.showMoveDonationsModal = false;
    vm.showMoveRecurringDonationsModal = false;
    vm.entity = {};

    this.$routerOnDeactivate = function(p, n) {
      extJsLoader.extJsController.iframeCreated = false;
    }

    routingService.updateRouting()
    .then(r => {
        vm.openEntity = r;
        let entity = entityService.getRawEntityById(r);
        if (entity) {
          vm.entity = entity;
          vm.onGroup = entity.entityType === 'CampaignGroup';
          vm.onOrg = entity.entityType === 'Org';
          if (entity.curEventId) {
            vm.iframeSrc = `/site/${entity.curEventId}?type=plat&value=`;
            if (!extJsLoader.extJsController.iframeCreated) {
              vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'transactions');
            } else {
              vm.loading = false;
            }
                     
            iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
          }
        }
    });
    $rootScope.$on('moveDonations', (event, data) => {
      $timeout(function(){
             if(data.length > 0){
               var donationIds = [];
               
               data.forEach((d)=> {
                donationIds.push(d.DonationID);
               });
               vm.objectIds = donationIds;
               vm.objectType = "moveDonations";
               vm.objectEventId = vm.entity.curEventId;
               vm.objectEntityId = vm.entity.entityId;
              vm.showMoveDonationsModal = true;
             }
            
      });
    });

      $rootScope.$on('moveRecurringDonations', (event, data) => {
        $timeout(function(){
               if(data.length > 0){
                 var donationIds = [];
                 
                 data.forEach((d)=> {
                  donationIds.push(d.DonationRecurringID);
                 });
                 vm.objectIds = donationIds;
                 vm.objectType = "moveRecurringDonations";
                 vm.objectEventId = vm.entity.curEventId;
                 vm.objectEntityId = vm.entity.entityId;
                vm.showMoveRecurringDonationsModal = true;
               }
              
        });
     
  });

    $rootScope.$on('fulfillDonationPledges', (event, data) => {
      let params = {}
      if (data.fullfilType == 'donation') {
          params.ids = _.map(data.payments, x => x.PaymentID);
          params.amount = _.sumBy(data.payments, 'Amount');
          
        if (data.payments[0] && data.payments[0].DonatorID && _.every(data.payments, ['DonatorID', data.payments[0].DonatorID])) {
          params.memberId = data.payments[0].DonatorID;
        }
      } else if (data.fullfilType == 'match') {
        params.ids = data.ids;
        params.amount = data.amount;
        params.memberId = data.memberId;
        params.donorCampaignId = data.donorCampaignId
      }
      

      let instruction = vm.$router.generate(['New Credit', params]);
      let url = window.baseUrl + angular.stringifyInstruction(instruction);
      $window.open(url,'_blank');
    });

    $rootScope.$on('loaded', r => {
    extJsLoader.extJsController.loadInnerPage().then(r => {
      vm.loading = false;
      extJsLoader.extJsController.checkParams();
    });
  });

    }