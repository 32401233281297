angular.module('app').component('formBuilder', {
  controllerAs: 'vm',
  controller: formBuilderController,
  bindings: { 
    $router: '<'
  },
  require: {
    parentComponent: '^formsTab'
  },
  controllerAs: 'vm',
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateRoute($nextInstruction);
  }
});

function formBuilderController(extJsLoader) {
  const vm = this;
  this.$routerOnActivate = function(next, prev) {
    extJsLoader.extJsController.updateInnerPage('Forms');
    this.parentComponent.showIframe = true;
  }
}
