angular.module('app').component('adminTour', {
  controllerAs: 'vm',
  controller: adminTourController,
  templateUrl: 'admin-tour.tpl.html',
  bindings: {
      endTour: '<'
  }
});

function adminTourController(securityService, entityService, $window, $timeout) {
  const vm = this;
  vm.steps = [
    {
      id: 0,
      image: '/content/platform/img/admin-tour/step-0.png',
      title: '',
      body: `<ul>
            <li>This is now the only admin interface! All data and settings have been migrated from the Admin Panel to this interface.</li>
            <li>Settings have been moved around. </li>
            <li> Settings and dictionary entries can now be configured on the organization or group level and apply to campaigns within them.</li>
        </ul>
        <br/>
        Click "Take Tour" to learn more about these changes.`
    },
    {
      id: 1,
      image: '/content/platform/img/admin-tour/step-1.png',
      title: 'Top Level Navigation',
      body: `<ul>
            <li>Access your campaigns' data and settings under "Campaigns".</li>
            <li>Access and modify settings and libraries related to the organization under "Organization". These include administrators, security roles, and automation.</li>
        </ul>`,
      attachTo: function() {
        return $('.nav-top-menu')
          .children()
          .first();
      },
      highlight: function() {
        return $('.nav-top-menu').parent();
      }
    },
    {
      id: 2,
      image: '/content/platform/img/admin-tour/step-2.png',
      title: 'Entity Navigation',
      body: `On this level, you can navigate between groups and campaigns. Within each, you will be able to access data and settings. 
       To return to the campaigns list of a group, click the group name.`,
      attachTo: function() {
        return $('.sub-nav-firstline')
          .find('ul')
          .children()
          .last()
          .find('a');
      },
      highlight: function() {
        return $('.sub-nav-firstline')
          .children()
          .first();
      }
    },
    {
      id: 3,
      image: '/content/platform/img/admin-tour/step-3.png',
      title: 'Settings Navigation',
      body: `Use these tabs to access particular data or settings within a group or campaign. 
        <br/>
        Take note of your location by looking at the group or campaign name above these tabs — that's the location your configurations will apply to. 
        <br/>
        Any setting configured on a group will be applied to campaigns within it. 
        To indicate when you're on a campaign level, the header background will be blue.`,
      attachTo: function() {
        return $('#secondary-navs')
          .children()
          .first();
      },
      highlight: function() {
        return $('#secondary-navs').parent();
      }
    },
    {
      id: 4,
      image: '/content/platform/img/admin-tour/step-4.png',
      title: 'Locating Your Settings',
      body: `Many settings and dictionary entries have been moved. Most notably: 
        <ul>
            <li>Settings and dictionary entries related to donations and registrations have been moved to the "Forms" tab.</li><li>Pages previously accessible in the "Site Content" section are available in the "Website" tab.</li>
            <li>Settings and dictionary entries related to fundraising have been moved to the "Fundraising" tab.</li>
            <li>Processor and integration settings have been moved to the "System" tab.</li>
        </ul>
        Additionally, the permission structure has been updated to reflect these changes. No action is required on your part — administrators will continue to have the same permissions they had before the migration.`,
      attachTo: function() {
        return $('#secondary-navs')
          .children()
          .last();
      },
      highlight: function() {
        return $('#secondary-navs').parent();
      },
      center: true
    },
    {
      id: 5,
      image: '/content/platform/img/admin-tour/step-5.png',
      title: 'Configuring Your Settings',
      body: `Any setting configured on a group will apply to all groups and campaigns within that group. Additionally, groups and campaigns can override their inherited configuration.
        <br/>
        <br/>
        <div class="body-detail">
            <img src="/content/platform/img/admin-tour/lock.svg">
            <div>Look for this icon to indicate when a setting is inherited. Click the icon to override the parent configuration.</div>
        </div>
        <br/>
        <div class="body-detail">
            <img src="/content/platform/img/admin-tour/overriding.svg"> 
            <div>This icon indicates when a group or campaign is overriding its parent configuration. Click the icon to clear the override and inherit the parent configuration.</div>
        </div>
        <br/>
        <div class="body-detail">
            <img src="/content/platform/img/admin-tour/unlocked.svg"> 
            <div>This icon indicates that a setting is configured for all children of the present entity. Click the icon to clear all child overrides and force all children of the entity to inherit the parent configuration.</div>
        </div>`
    }
  ];
  vm.curStep = 0;
  vm.previousStep = function() {
    handleHighlight(false);
    vm.curStep--;
    handleChange();
  };

  vm.nextStep = function() {
    handleHighlight(false);
    vm.curStep++;
    if (vm.curStep == vm.steps.length) {
      vm.endTour();
    }
    handleChange();
  };

  vm.setStep = function(step) {
    handleHighlight(false);
    vm.curStep = step;
    handleChange();
  };
  angular.element($window).on('resize', function() {
    $('#tour-overlay').height($(document).height());
  });
  handleChange();


  function handleChange(current, next) {
    positionTour();
    handleHighlight(true);
  }

  function handleHighlight(show) {
    if (vm.steps[vm.curStep] && vm.steps[vm.curStep].highlight) {
      vm.steps[vm.curStep].highlight().css('zIndex', show ? 45 : 'inherit');
    }
  }

  function positionTour() {
    var step = vm.steps[vm.curStep];
    var container = $('#step-container');
    var centerX = $(document).width() / 2 - container.width() / 2;
    if (step && step.attachTo && step.attachTo().length == 1) {
      var target = step.attachTo();
      var targetHeight = target.outerHeight();
      var targetPosition = target.offset();
      var targetBottom = targetPosition.top + targetHeight;
      container.offset({
        top: targetBottom + 12,
        left: step.center ? centerX : 30
      });
      $timeout(() =>$('#tour-tip').offset({top: targetBottom, left: targetPosition.left + target.width() / 2})
      );
      $('#tour-tip').show();
    } else {
      container.offset({ top: 150, left: centerX });
      $('#tour-tip').hide();
    }
  }
}
