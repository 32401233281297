angular
  .module('app')
  .component('donationsRecurring', {
    templateUrl: 'donationsRecurring.tpl.html',
    controller: donationsRecurringController,
    controllerAs: 'vm',
    require: {
      parentComponent: '^transactions'
    },
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function donationsRecurringController(extJsLoader) {
  const vm = this;
  this.$routerOnActivate = function(next, prev) {
    extJsLoader.extJsController.updateInnerPage('Transactions-DonationsRecurring');
    this.parentComponent.showIframe = true;
  }
}