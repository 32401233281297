
var routeConfig = [
  {
    path: '/fundraisingSettings',
    name: 'Fundraising Settings',
    component: 'fundraisingSettings',
    useAsDefault: true,
    data: {
      anyResource: ['FUNDRAISING_OPTIONS', 'LIVESTREAMS']
    }
  },
  {
    path: '/startupGuide',
    name: 'Startup Guide',
    component: 'startupGuide',
    data: {
      anyResource: ['SETTINGS_HQ_TASKS', 'FUNDRAISING_OPTIONS']
    }
  },
  {
    path: '/taskList',
    name: 'Task List',
    component: 'taskList',
    data: {
      anyResource: ['FUNDRAISER_TASK_LIST', 'FUNDRAISER_TASK_LIST_OPTIONS']
    }
  },
    {
    path: '/templates',
    name: 'Email Templates',
    component: 'emailTemplates',
    data: {
        resource: 'FUNDRAISER_EMAIL_TEMPLATES',
    }
  },
  {
    path: '/enduranceChallenges',
    name: 'Endurance Challenges',
    component: 'enduranceChallenges',
    data: {
        resource: 'ENDURANCE_CHALLENGES',
    }
  },
  {
    path: '/fundraiserPdf',
    name: 'Fundraiser PDF',
    component: 'fundraiserPdf',
    data: {
      anyResource: ['FUNDRAISING_CUSTOMIZE'],
      routeFunc: function(entity) {
        return entity.settings.HasFundraiserPdfEnabled;
      }
    }
  },
  {
    path: '/customize',
    name: 'Customize',
    component: 'fundraisingCustomize',
    data: {
        resource: 'FUNDRAISING_CUSTOMIZE'
    }
  },
  {
    path: '/advanced',
    name: 'Advanced',
    component: 'fundraisingAdvanced',
    data: {
      anyResource: ['FUNDRAISING_ADVANCED']
    }
  }
];

angular
  .module('app')
  .component('fundraising', {
    templateUrl: 'fundraising.tpl.html',
    controllerAs: 'vm',
    bindings: { $router: '<' },
    controller: fundraisingController,
    $routeConfig: routeConfig,
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
  });

  angular.module('app').constant('fundraisingRouteConfig',routeConfig);

function fundraisingController($rootScope, routingService, entityService, orgService, extJsLoader, iframeMessageHandler) {
  const vm = this;
  vm.navs = routeConfig;
  vm.iframeData = {};
  vm.loading = true;
  vm.showIframe = false;

  this.$routerOnDeactivate = function(p, n) {
    extJsLoader.extJsController.iframeCreated = false;
  }

  routingService.updateRouting().then(r => {
    vm.openEntity = r;
    let entity = entityService.getRawEntityById(r);
    if (entity) {
      vm.onGroup = entity.entityType === 'CampaignGroup';
      vm.onOrg = entity.entityType === 'Org';
      if (entity.curEventId) {
        vm.iframeSrc = `/site/${entity.curEventId}?type=plat&value=`;
        if (!extJsLoader.extJsController.iframeCreated) {
          vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'fundraising');
        } else {
          vm.loading = false;
        }
        
        iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
      }
    }
  });

  $rootScope.$on('loaded', r => {
    extJsLoader.extJsController.loadInnerPage().then(r => vm.loading = false);
  });
}
