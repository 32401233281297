angular
  .module('app')
  .component('notifications', {
    templateUrl: 'notifications.tpl.html',
    controller: NotificationsController,
    controllerAs: 'vm'
  });

function NotificationsController(administratorService, profileService) {
  const vm = this;

  vm.notificationsList = [];
  vm.columnSelectedAll = []; // 4 columns
  vm.loadingInProgfress = false;
  vm.isDataChanged = false; // see if any checkbox was changed
  vm.isDataSaving = false;

  vm.saveNotifications = saveNotifications;
  vm.selectAllForCampaign = selectAllForCampaign;
  vm.selectAllForType = selectAllForType;
  vm.handleCheckboxChange = handleCheckboxChange;
  vm.getHoverMessage = getHoverMessage;

  fetchNotifications();

  /////////////////////////////////////////////////////////////////

  function fetchNotifications() {
    vm.loadingInProgfress = true;

    profileService
      .getNotificationsSettings()
      .then(notificationsList => {
        vm.notificationsList = notificationsList;
          
        updateSelectAllCheckboxes();
      })
      .finally(() => vm.loadingInProgfress = false);
  }

  function saveNotifications() {
    vm.isDataSaving = true;

    profileService
      .saveNotifications(vm.notificationsList)
      .then(response => vm.isDataChanged = false)
      .finally(() => vm.isDataSaving = false);
  }

  function updateSelectAllCheckboxes() {
    vm.columnSelectedAll = [true, true, true, true];

    vm.notificationsList.forEach(notificationOrg => {
		
		notificationOrg.campaigns.forEach(notification => {
		  /* check if every checkbox in row is selected */
		  notification.selectedAll = true;

		  notification.flags.forEach((flag, index) => {
			if (!flag) {
			  notification.selectedAll = false;
			  vm.columnSelectedAll[index] = false;
			}
		  });
		});
    });
  }

  function handleCheckboxChange() {
    updateSelectAllCheckboxes();
    vm.isDataChanged = true;
  }

  function selectAllForCampaign(notification) {
    let value = notification.selectedAll;

    notification.flags = notification.flags.map((flag, i) => {
      if (notification.permissionFlags[i]) {
        return value;
      }

      return flag;
    });

    handleCheckboxChange();
  }

  function selectAllForType(typeIndex) {
    let value = vm.columnSelectedAll[typeIndex];

	vm.notificationsList.forEach(notificationOrg => {
		notificationOrg.campaigns.forEach(notification => {
		  if (notification.permissionFlags[typeIndex]) {
			notification.flags[typeIndex] = value;
		  }
		});
	});

    handleCheckboxChange();
  }

  function getHoverMessage(notification, flagIndex) {
    return `You don't have permission to this resource`;
  }
}