angular.module("app").component("siteBuilderIcon", {
    controller: siteBuilderIconController,
    controllerAs: "vm",
    template: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 94"><g id="Layer_2" data-name="Layer 2"><g id="Layer_3" data-name="Layer 3"><path fill={{vm.fill}} d="M70.42,55.63c.85,1.09-.27,1.42-.78,1.68A23.89,23.89,0,0,0,63.09,62a23.39,23.39,0,0,0-4.79,6.44c-.26.5-.61,1.6-1.71.77A88.88,88.88,0,0,1,44.28,59C31.48,46.39-3.58,4.11.3.29S47.19,30.94,60,43.53A87.86,87.86,0,0,1,70.42,55.63Z"/><path fill={{vm.fill}} d="M65.22,83.46c-4.88-4.8-3.5-11.8,1.73-16.95S79.3,60,84.18,64.81c7.57,7.44,1.18,15.08,11.24,26.41C100,96.33,76.73,94.77,65.22,83.46Z"/></g></g></svg>`,
    bindings: {
      fill: '<'
    }
  });
  
  function siteBuilderIconController() {
    var vm = this;
  }