angular
  .module('app')
  .component('emailDesigner', {
    controller: emailDesignerController,
    require: {
      parentComponent: '^email'
    },
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    },
    template: `<form-template
                 loading="vm.loading"
                 form-sections='vm.sections'
                 on-update='vm.onChange'
                 on-field-init="vm.onFieldInit"></form-template>`,
});

function emailDesignerController(configSettingsService, emailService) {
    const vm = this;
    vm.sections = [];
    vm.loading = true;
    vm.advanced = false;
    
    configSettingsService.getEmailHeaderFooterSettings().then(function(r) {
        vm.loading = false;
        
        if (r.success && r.form) {
            vm.sections = r.form.FormSections;
            vm.sections[0].HideHeader = true;
        }
    });

    vm.onChange = function(config) {
        config.enabled = (config.value.find(x => x.Label === 'Enable Header') || config.value.find(x => x.Label === 'Enable Footer')).On;
        config.content = (config.value.find(x => x.Label === 'Header Content') || config.value.find(x => x.Label === 'Footer Content')).Value;
        
        return emailService.updateHeaderOrFooter(config);
    };
}