angular.module('app').component('overridingIcon', {
  controller: overridingIconController,
  controllerAs: 'vm',
  templateUrl: 'overriding-icon.tpl.html',
  bindings: {
    parentName: '<',
    setting: '<',
    updating: '<',
    hideOptions: '<',
    disabled: '<',
    confirmAdoption: '<',
    onCampaign: '<',
    removeOverrides: '<',
    isTerm: '<'
  }
});

function overridingIconController($scope, $mdDialog) {
  var vm = this;
  vm.showPullout = false;

  vm.togglePullout = function() {
    vm.showPullout = !vm.showPullout;
  };

  vm.hidePullout = function() {
    vm.showPullout = false;
  };

  function adoptParent() {
      $scope.$emit('adoptParent', vm.setting);

  }
  vm.confirmParentAdopt = function(ev) {
    if (!vm.confirmAdoption) {
      adoptParent();
      return;
    }

    var entityType = vm.onCampaign ? 'campaign' : 'group';
    var confirm = $mdDialog
      .confirm()
      .title('Are you sure?')
      .textContent(`Clearing the override will permanently delete the content currently entered on this ${entityType}.`)
      .targetEvent(ev)
      .ok('Clear Override')
      .cancel('Cancel');

    $mdDialog.show(confirm).then(
      function() {
        adoptParent();
      },
      function() {}
    );
  };

  vm.confirmDeleteChildOverride = function(ev) {
    vm.removeOverrides(ev, vm.setting);
  };
}