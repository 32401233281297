angular
    .module('app')
    .directive('mdTabsHideSingle', function() {
        return {
            restrict: 'A',
            require: ['mdTabs', 'mdTabsHideSingle'],
            link: function($scope, $element, attrs, controllers) {
                var tabsController = controllers[0];
                var thisController = controllers[1]
                thisController.init($element, tabsController);
            },
            controller: function($scope, $mdUtil) {
                this.init = function($element, tabsController) {
                    $mdUtil.nextTick(function () {
                        var $tabsContainer = $element.find('md-tabs-canvas');
                        if(tabsController.tabs.length <= 1) {
                            $tabsContainer.hide();
                            $element.addClass('tabs-hidden');
                        } else {
                            $tabsContainer.show();
                            $element.addClass('tabs-visible');
                        }
                    });
                }
            },
        }
    });