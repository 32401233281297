angular.module('app').component('customizeSettings', {
  templateUrl: 'customize-settings.tpl.html',
  controllerAs: 'vm',
  controller: customizeSettingsController,
  require: {
    parentComponent: '^formsTab'
  },
  bindings: { 
    $router: '<'
  },
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateRoute($nextInstruction);
  }
});

function customizeSettingsController(entityContentService, orgService, entityService) {
  const vm = this;
  this.$routerOnActivate = function (next, prev) {
    this.parentComponent.showIframe = false;
    this.parentComponent.loading = false;
  }
  vm.entityType = entityService.getOpenEntity().entityType;
  vm.termType = 'Forms';
  vm.getData = getData;

  function getData() {
    dataRequest()
      .then(items => {
        vm.grid = items;
      }).catch(() => {

      });
  }
  
  function dataRequest() {
    return entityContentService.getFormTerms(vm.parentComponent.openEntity, vm.selectedLanguage)
  }

  orgService.orgInfoLoadedPromise.then(r => {
    entityService.getEntitySettings(vm.parentComponent.openEntity).then(entity => {
      vm.languages = entity.settings.LanguagesKeyValue;
      vm.selectedLanguage = entity.settings.DefaultLanguage;
      vm.isMultiLanguage = entity.settings.IsMultiLanguage;
      getData();
    });
  });

  vm.clicked = function(item) {
    return vm.saveTerm(item, false);
  }
  
  vm.open = function(item) {
    entityContentService.getTemplatePlaceholders(vm.parentComponent.openEntity, item.Flags).then(items =>
      CKEDITOR.config.macros = items
    );
  }

  vm.overrideParent = function(item) {
    return vm.saveTerm(item, true);
  }

  vm.saveTerm = function(item, createDuplicate) {
    return entityContentService.updateTerm(vm.parentComponent.openEntity, item, vm.termType, vm.selectedLanguage, createDuplicate);
  }

  vm.adoptParent = function(item) {
    return entityContentService.deleteTerm(vm.parentComponent.openEntity, item, vm.termType, vm.selectedLanguage).then(() => dataRequest());
  }
}
