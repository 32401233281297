angular
    .module('app')
    .directive("creditTransactionsGrid", () => ({
        controller: creditTransactionsGrid,
        controllerAs: 'vm',
        link: (scope, element, attrs, ngModel) => {
            scope.$on('updateValue', function(event, value) {
                
                if (!ngModel.$viewValue || !_.isEqual(_.sortBy(value.ids), _.sortBy(ngModel.$viewValue.ids))) {
                    ngModel.$setViewValue(value);
                    scope.$parent.$broadcast('updateValue', value);
                }
            }, true );

            ngModel.$isEmpty = function(value) {
                return !value || !value.totalSelected || value.totalSelected === 0;
            };
        },
        templateUrl: 'selectable-grid-container.tpl.html',
        restrict: 'E',
        transclude: true,
        require: '?ngModel',
        scope: {
            ngModel: '<',
            ngDisabled: '<',
            id: '<',
        }
    }));

function creditTransactionsGrid($scope, $timeout, agGridService, campaignService) {
    const vm = this;
    
    vm.itemName = 'transaction';
    vm.itemNamePlural = 'transactions';

    vm.gridFunctions = {};

    vm.gridFunctions.getColumnDefs = function(editable) {
        let cols = [
            {
                field: 'PaymentId',
                headerName: 'Payment ID',
                checkboxSelection: editable,
                filter: 'agNumberColumnFilter',
            }
        ];

        if (!editable) {
            cols.push({
                field: 'Amount',
                filter: false,
                sortable: false,
                cellRenderer: function (params) {
                    if (!params.data) return params.value;
                    return params.data.FormattedAmount;
                },

            });
        } else {
            cols.push(
                {
                    field: 'TransactionAmount',
                    headerName: 'Amount',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: function (params) {
                        if (!params.data) return params.value;
                        return params.data.FormattedTransactionAmount;
                    },
                    filterParams: {
                        cellRenderer: function (params) {
                            return params.data.FormattedTransactionAmount;
                        },
                        filterOptions: agGridService.defaultFilterOptions.number,
                    },
                },
                {
                    field: 'FirstName',
                    headerName: 'First Name',
                    filter: 'agTextColumnFilter',
                },
                {
                    field: 'LastName',
                    headerName: 'Last Name',
                    filter: 'agTextColumnFilter',
                }
            )
        }

        cols.push(
            {
                field: 'RecordId',
                headerName: 'Record ID',
                cellRenderer: agGridService.templates.transactionId,
                filter: 'agNumberColumnFilter',
            },
            agGridService.columnDefinitions.boolean({
                field: 'IsDonation',
                headerName: 'Is Donation',
                filter: true,
                sortable: false,
            }),
            agGridService.columnDefinitions.boolean({
                field: 'IsRegistration',
                headerName: 'Is Registration',
                filter: true,
                sortable: false,
            })
        );
        
        return cols;
    }

    vm.gridFunctions.getDatasource = function(editable, ngModel) {
        let action = editable ? 'AvailableCreditForTransactions' : 'CreditForTransactions';    
        return agGridService.getDatasource('Credit', action, () => ({ creditId: $scope.id }), vm.filterData);
    }

    vm.gridFunctions.isNodeSelected = function(data, ngModel) {
        return ngModel && ngModel.ids && ngModel.ids.includes(data.PaymentId);
    }

    vm.gridFunctions.updateSelectedRows = function(selectedNodes) {
        let value = { ids: [], totalSelected: 0, totalTransactionsAmount: 0, totalUnfulfilledAmount: 0 };

        _.forEach(selectedNodes, (node) => {
            value.ids.push(node.data.PaymentId);
            value.totalTransactionsAmount += node.data.TransactionAmount;
            value.totalUnfulfilledAmount += node.data.UnfulfilledAmount;
        });

        value.totalSelected = selectedNodes.length;

        $scope.$broadcast('updateValue', value);
    }
    
    vm.gridFunctions.additionalProperties = function(editable) {
        return {
            advancedFiltering: true,
            getRowNodeId: (data) => data.PaymentId,
        }
    }
    
    vm.filterData = function(items) {

        items.forEach(f => {
            var campaign = campaignService.getCampaignByEventId(f.EventId);
            if (campaign) {
                f.campaignUrl = campaign.campaignUrl;
            }
        });
        
        return items;
    }
}



