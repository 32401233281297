angular
  .module('app')
  .directive('languageSelect', languageSelect);


  function languageSelect() {
    return {
        restrict: 'E',
        template: `<md-input-container ng-if="vm.isMultiLanguage">
                        <label>Language</label>
                        <md-select ng-model="vm.selectedLanguage" ng-change="vm.getData()">
                            <md-option ng-repeat="(key, value) in vm.languages" value="{{key}}">{{value}}</md-option>
                        </md-select>
                    </md-input-container>`,
        link($scope) {
            
        }
    }
  }