angular
  .module('app')
  .component('modalAdministrators', {
    bindings: {
      show: '=',
      entityIds: '=',
      openEntityId: '<',
      org: '=',
      groups: '=',
      campaign: '='
    },
    controller: ModalAdministratorsCtrl,
    controllerAs: 'vm'
  });


function ModalAdministratorsCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-administrators.tpl.html',
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          entityIds: vm.entityIds,
          openEntityId: vm.openEntityId,
          org: vm.org,
          groups: vm.groups,
          campaign: vm.campaign
        },
        clickOutsideToClose: true
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($q, $mdDialog, $timeout,
                          campaignService, administratorService, entityService, orgService, messageService, securityService,
                          entityIds, openEntityId, org, groups, campaign, $scope, $mdConstant) {
  const vm = this;

  vm.inviteOnly = entityIds && entityIds.length;

  vm.newAdmin = {};
  vm.roles = [];
  vm.roleIdsToRolesMap = [];
  vm.admins = [];
  vm.loadInProgress = false;
  vm.saveInProgress = false;
  vm.inviteInProgress = false;
  vm.removeInProgress = {
    index: null
  };
  vm.adminSecurity = {};
  vm.addRemoveFlag = addRemoveFlag;
  vm.inviteNewAdmin = inviteNewAdmin;
  vm.removeAdmin = removeAdmin;
  vm.addRoleChanges = addRoleChanges;
  vm.closeModal = closeModal;
  vm.getTitle = getTitle;
  vm.findRole = findRole;
  vm.showList = false;
  vm.showTextArea = false;
  vm.allEmails = [];
  vm.showChipsHint = false;
  vm.revertDeleteIcon = revertDeleteIcon;
    // vm.changeMdContainer = changeMdContainer;
  loadAdminPermissions();
  fetchData();


  function loadAdminPermissions() {
      if (!vm.inviteOnly) {
          var entity = entityService.getRawEntityById(openEntityId);
          vm.adminSecurity = securityService.getAdminPermissions(entity, 'ADMIN_USERS');
      } else {
          vm.adminSecurity.DELETE = 4;
      }
  }
  // function changeMdContainer(){
  //   document.querySelector('.md-dialog-container').className = "md-dialog-container md-container-overwrite";
  // }

  function revertDeleteIcon(){
      if (vm.admins != undefined) {
          vm.admins.forEach((admin) => {
              if (admin.remove == true) {
                  admin.remove = false;
              }
          })
      }
  }
    
  function fetchData() {

    vm.loadInProgress = true;
    let curEntity = vm.inviteOnly > 0 ? entityIds[0] : openEntityId;
    let promises = [
      administratorService.getAllRoles(curEntity)
    ];

    if(!vm.inviteOnly) {
        promises.push(administratorService.getAdmins(openEntityId))
    }

      $q.all(promises)
          .then(([roles, admins]) => {
              vm.roles = roles;
              vm.roleIdsToRolesMap;
              roles.forEach((role) => {
                  vm.roleIdsToRolesMap[role.roleId] = role;
              });
              vm.admins = admins;
              if (vm.admins) {
                  vm.admins.forEach((admin) => {
                      vm.allEmails.push(admin.email);
                  });
              }
              loadContacts();
          })
          .catch(() => {
                  closeModal();
          })
          .finally(() => {
            vm.loadInProgress = false;
          });
  }

  function findRole(id){
    let role = vm.roles.filter(function(role){
        return role.roleId == id;
    });
      return role[0].rolename;
  }

  //chips
  
 
  vm.adminSearch = adminSearch;
  vm.newAdmin.email = [];
  vm.selectedItem = null;
  vm.searchText = null;
  vm.selectedTags = [];
  vm.keys = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.COMMA, $mdConstant.KEY_CODE.TAB];


  function adminSearch(query){
    let results = query ? vm.allAdmins.filter(createFilterFor(query)) : [];
        return results;
  };

   function createFilterFor(query) {
      var lowercaseQuery = angular.lowercase(query);
          return function filterFn(contact) {
            return ((contact._loweremail.indexOf(lowercaseQuery) != -1)
              || (contact._lowername.indexOf(lowercaseQuery) != -1));
      };
  };

  function loadContacts() {
      let contacts = administratorService.getAdminsFromOrg();
      //in case it wasn't already loaded by org home page
      if (contacts.length == 0) {
          let curEntity = vm.inviteOnly > 0 ? entityIds[0] : openEntityId;
          contacts = administratorService.getAllOrgAdminsNamesEmails(curEntity)
              .then((response) => {
              //   contacts = administratorService.getAdminsFromOrg();
              vm.allAdmins = filterContactsWithRoles(response);
          });
      //    vm.allAdmins = filterContactsWithRoles(contacts);
              
          //administratorService.getAllOrgAdmins()
          //    .then(() => {
          //        contacts = administratorService.getAdminsFromOrg();
          //        vm.allAdmins = filterContactsWithRoles(contacts);
          //    });
      } else {
          vm.allAdmins = filterContactsWithRoles(contacts);
      }      
  };

    function filterContactsWithRoles(contacts) {
        for(let i = 0; i < vm.allEmails.length; i++){
            for(let j = 0; j < contacts.length; j++){
                if (contacts[j].email == vm.allEmails[i]){
                    contacts.splice(j, 1);  
                }
            }
        }
        return contacts.map(function (c, index) {
            let contact = {
                name: c.firstName + ' ' + c.lastName,
                email: c.email
            };
            contact.display = (contact.name === ' ') ? contact.email : contact.name;
            contact.fullname = (contact.name === " ") ? contact.email : contact.name;
            contact._loweremail = contact.email.toLowerCase();
            contact._lowername = contact.name.toLowerCase();
            return contact;
        }); 
    }

    //watch for when the email input box is added to to check for invalid emails
    $scope.$watchCollection('vm.newAdmin.email', function() {
        if (vm.newAdmin.email && vm.newAdmin.email.length > 0) {
            let input = vm.newAdmin.email[vm.newAdmin.email.length - 1];
            //if type is not string then the chip was added by autocomplete
            if (typeof input == "string") {
                let pattern = /^.+@.+\..+$/;
                let emailsRejected = '';
                let emailsToAdd = [];
                let invalid = false;
                let emails = input.split(' ');
                emails.forEach((email) => {
                    if (!email.match(pattern)) {
                        invalid = true;
                        emailsRejected += " " + email;
                    } else {
                        emailsToAdd.push(email);
                    }
                })
                if (invalid || emails.length > 1) {
                    vm.newAdmin.email.splice(-1, 1);
                    vm.newAdmin.email = vm.newAdmin.email.concat(emailsToAdd);
                    if (invalid) {
                        $timeout(() => {
                            messageService.showErrorToast(`Please enter valid email`,angular.element('.invite-form_admin'));
                            vm.searchText = emailsRejected.trim();
                        })
                    }
                }
            }
        }
    });

    //function validateEmail(chip){
    //    let pattern = /^.+@.+\..+$/;
    //    vm.newAdmin.invalidEmail = undefined;
    //    if (chip && (typeof chip == 'string')){
    //        let match = chip.match(pattern);
    //        if (chip.match(pattern)){
    //            return match[0];
    //        }
    //        else {
    //            messageService.showErrorToast(`Please enter valid email`, angular.element('.invite-form_admin'));
    //            vm.newAdmin.invalidEmail = chip
    //            return null;
    //            // event.stopPropagation();
    //        }
    //    } else if (chip && (typeof chip == 'object')){
    //        return chip;
    //    }
    //};

// end chips

  function getTitle(){
    let last = groups.length - 1;
    if ((groups.length === 0)  && (campaign === undefined)){
      return `${org}  Administrators`;
    }

    else if ((groups.length > 0) && (campaign === undefined)){
      return `${groups[0].name}  Administrators`;
    }

    else if(campaign !== undefined){
      return ` ${campaign}  Administrators`;
    }
  }

    function advancedInviteErrors(currentMessageService, response) {
        var msg = '';
        response.data.data.errors.forEach((error) => {
            error.errorsList.forEach((entity) => {
                msg += error.key + ' could not be invited to ' + entityService.getEntityName(entity.errorMessage) + '.<br>';
            });
        });
        msg += "All the other invites were successful."
        messageService.showErrorDialog(msg);
    }

    function inviteNewAdmin() {
        vm.inviteInProgress = true;

        for(let i = 0; i < vm.newAdmin.email.length; i++){
            if(typeof vm.newAdmin.email[i] === 'object'){
                vm.newAdmin.email[i] = vm.newAdmin.email[i].email;
            }
        }
        let rolesOnEntitys = [];
        let currentEntity = undefined;
        //if entityIds.length is 0 - then showAdminModal event was called from campaigns.tpl.html for the entity that was open
        //else - it was called from activity.component.js for any amount of selected entities
        if (!entityIds.length) {
            currentEntity = openEntityId;
            rolesOnEntitys.push({ entityId: currentEntity, roleId: vm.newAdmin.roleId });
        } else {
            entityIds.forEach((entityId) => {
                rolesOnEntitys.push({ entityId: entityId, roleId: vm.newAdmin.roleId })
            });
        }

        let messageConfig = {
            advancedErrorFunction: advancedInviteErrors,
            advancedErrorEnabled: true,
            successToastEnabled: true,
            successMsg: `Successfully invited ${vm.newAdmin.email}`
        };

        administratorService
        .inviteAdmin(vm.newAdmin.email, rolesOnEntitys, currentEntity, entityIds, vm.newAdmin.message, messageConfig)
        .then(data => {
            if (!vm.inviteOnly) {
                let newAdmins = data.data.data;
                newAdmins.forEach((a) => {
                    a.currentRoleId = vm.newAdmin.roleId;
                    vm.admins.push(a);
                    //for reloading contacts to exclude these emails
                    vm.allEmails.push(a.email);
                })
                //scroll to bottom of list to show new additions
                $timeout(() => { 
                    var listDiv = document.getElementById("adminList");
                    listDiv.scrollTop = listDiv.scrollHeight;
                })

                loadContacts();
            }
            vm.newAdmin.email = [];
            vm.newAdmin.roleId = null;
        })
        .finally((msg) => vm.inviteInProgress = false);
  }

  function removeAdmin(adminId, index) {
    vm.removeInProgress.index = index;

      administratorService.removeAdminFromEntity(adminId, openEntityId)
        .then((admins) => {
          vm.admins = admins;
        })
        .finally(() => vm.removeInProgress.index = null);
  }


  function addRemoveFlag(admin) {
    admin.remove = true;
  }

  function addRoleChanges(admin, currentRoleId){
      let arg = [];
      arg.push({ userId: admin.id, entityId: openEntityId, roleId: currentRoleId });
      administratorService.addAdminToCompanies(admin.email, openEntityId, currentRoleId)
      .then((response) => {
      })
      .finally(() => {
          //closeModal();
          //vm.saveInProgress = false;
      });
  }

  function closeModal() {
    $mdDialog.hide();
  }

  function getCurrentEntity() {

    let openedCampaignId = orgService.getUrlCampaignId();

    let entityId = openedCampaignId ? campaignService.getCampaignById(openedCampaignId).entityId : entityService.getOpenedEntityId()

    return entityId;
  }

}
