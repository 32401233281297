angular
    .module('app')
    .component('endurancePledges', {
        controller: endurancePledgesController,
        bindings: {
            $router: '<'
        },
        require: {
            parentComponent: '^transactions'
        },
        templateUrl: `endurance-pledges.tpl.html`,
        controllerAs: 'vm',
        $canActivate: function($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });

function endurancePledgesController(agGridService, entityService, securityService, settingsService, endurancePledgeService, helpersService, formService, $element, $q, $rootScope, $scope, $mdDialog, $location) {
    const vm = this;
    var entity = entityService.getOpenEntity();
    vm.openRowBeforeChanges = {};
    vm.entityType = entity.entityType;
    vm.security = securityService.getAdminPermissions(entity, 'ENDURANCE_PLEDGES');
    vm.showPledgeModal = false;
    vm.showCreditModal = false;
    vm.creditModalTargetEvent = null;
    vm.preselectCredit = {};

    $rootScope.$on('addNewSelected', ($event, targetEvent) => {
        vm.showPledgeModal = true;
        vm.endurancePledgeModalTargetEvent = targetEvent;
    });

    $scope.$on('CreditModalClosed', () => {
        vm.creditModalTargetEvent = null;
        vm.preselectCredit = {};
        vm.showCreditModal = false;
    });

    $scope.$on('NewCreditAdded', () => {
        vm.grid.closeOpenRow();
        vm.refreshRows();
    });

    $scope.$on('NewEndurancePledgeAdded', () => vm.refreshRows());

    vm.$onInit = function() {
        if (vm.entityType === 'Campaign') {
            vm.parentComponent.campaigns.updateShowAddBtn(vm.security.EDIT, 'addPledge', true, 'Add Endurance Pledge');
        }
    };

    this.$routerOnActivate = function(next, prev) {
        this.parentComponent.showIframe = false;
        this.parentComponent.loading = false;
        
    };

    this.$routerOnDeactivate = function(p, n) {
        vm.parentComponent.campaigns.updateShowAddBtn(false, 'addPledge');
    }

    vm.loadRow = function(params) {
        vm.openRowBeforeChanges = _.cloneDeep(vm.grid.openRow);
    }

    vm.updateRow = function(updatedItem) {
        let updatedRow = vm.grid.api.getRowNode(updatedItem.Id);
        if (updatedRow) {
            updatedRow.setData(updatedItem);
        }
    }

    vm.cancelRow = function(updatedItem) {
        vm.updateRow(updatedItem);
    }

    vm.afterSave = function(updatedItem) {
        vm.updateRow(updatedItem);
    }

    vm.afterSaveField = function(updatedItem) {
        vm.updateRow(updatedItem);
    }

    vm.saveClosedRow = function(params, closedRow) {
        if (closedRow && closedRow.data && closedRow.data.saveIfChanges) {
            return closedRow.data.saveIfChanges(true);
        }

        return $q.resolve();
    }

    vm.deletePledge = function(pledge) {
        return endurancePledgeService.deletePledge(pledge).then(() => {
            vm.grid.closeOpenRow();
            vm.refreshRows();
        });
    }

    vm.savePledge = function(pledge) {
        return endurancePledgeService.savePledge(pledge).then(x => {
            vm.grid.openRow.node.setData(x.item);
            vm.setItemTitle(pledge, x.item);
        });
    }

    vm.endPledge = function(pledge, $event) {
        return $mdDialog.show(
            $mdDialog.confirm()
            .clickOutsideToClose(true)
            .title('Confirm End Pledge')
            .htmlContent(`Are you sure you want to end ${pledge.Title}?`)
            .targetEvent($event)
            .ok('End Pledge')
            .cancel('Cancel')
        ).then(() =>
            endurancePledgeService.endPledge(pledge).then(x => {
                vm.grid.openRow.node.setData(x.item);
                vm.setItemTitle(pledge, x.item);
                vm.grid.closeOpenRow();
            })
        )
    }

    vm.chargePledge = function(pledge, $event) {
        endurancePledgeService.chargePledge(pledge).then(response => {
            if (response.success) {
               vm.preselectCredit = response.item;
                vm.creditModalTargetEvent = $event;
                vm.showCreditModal = true;
            } else {
                messageService.showErrorToast(response.msg || 'Unknown Error Unable to retrieve Pledges');
            }
        })
    }

    vm.openPledge = function(pledge) {
        vm.setItemTitle(pledge, vm.grid.openRow.data);
    }

    vm.refreshRows = function() {
        vm.grid.api.refreshServerSideStore({ purge: vm.grid.totalResults == 0 })
    }
    
    vm.cardActions = [
        {
            label: 'End pledge',
            action: (item, $event) => vm.endPledge(item),
            isDisabled: item => !item.IsActive
        },
        {
            label: 'Add Payment',
            action: (item, $event) => vm.chargePledge(item, $event),
            isDisabled: item => !item.DynamicOption
        }
    ]

    vm.getRequiredData = function() {

        let data = {
            endurancePledgeState: endurancePledgeService.endurancePledgeState,
        };

        return $q.all(data);
    }

    vm.setItemTitle = function(pledge, fullItem) {
        pledge.Title = `${helpersService.getUserDisplayName(fullItem)} Pledge for ${fullItem.ChallengeName}`;
    }

    vm.grid = agGridService.createNewGrid([], {
        domLayout: 'autoHeight',
        sideBar: false,
        advancedFiltering: true,
        gridElement: $element.find('.ag-master-grid'),
        expandedCardElement: $element.find('.ag-external-record-row'),
        noRowsMessage: 'Endurance Pledges will show up here.',
        getRowNodeId: data => data.EndurancePledgeId,
        datasource: agGridService.getDatasource('EndurancePledge', 'All'),
        defaultColDef: {
            flex: 1,
            minWidth: 160,
        },
    });

    vm.getRequiredData().then(data => {

        let columnDefs = [
            {
                field: 'EndurancePledgeId',
                headerName: 'ID',
                filter: 'agNumberColumnFilter',
                hide: true,
            },
            {
                field: 'CreatedOnString',
                headerName: 'Creation Date',
                filter: 'agDateColumnFilter',
                valueFormatter: agGridService.formatters.dateTime,
            },
            {
                field: 'EndurancePledgeState',
                headerName: 'Status',
                filter: true,
                valueFormatter: params => data.endurancePledgeState[params.value],
                filterParams: {
                    values: Object.keys(data.endurancePledgeState),
                    valueFormatter: params => data.endurancePledgeState[params.value],
                }
            },
            {
                field: 'Donor',
                headerName: 'Donor',
                valueGetter: (params) => helpersService.getUserDisplayName(params.data),
                filter: false,
                sortable: false,
            },
            {
                field: 'FirstName',
                headerName: 'First Name',
                filter: 'agTextColumnFilter',
                hide: true,
            },
            {
                field: 'LastName',
                headerName: 'Last Name',
                filter: 'agTextColumnFilter',
                hide: true,
            },
            {
                field: 'CompanyName',
                headerName: 'Company Name',
                filter: 'agTextColumnFilter',
                hide: true,
            },
            {
                field: 'AmountPerMetric',
                headerName: 'Pledge amount per metric',
                filter: 'agNumberColumnFilter',
                cellRenderer: function (params) {
                    if (!params.data) return params.value;

                    return params.data.FormattedAmountPerMetric;
                },
                filterParams: {
                    cellRenderer: function (params) {
                        return params.data.FormattedAmountPerMetric;
                    },
                },
            },
            {
                field: 'ChallengeName',
                headerName: 'Pledge for',
                filter: 'agTextColumnFilter',
            },
            {
                field: 'TotalPledged',
                headerName: 'Current pledge amount',
                filter: 'agNumberColumnFilter',
                cellRenderer: function (params) {
                    if (!params.data) return params.value;

                    return params.data.FormattedTotalPledgedAmount;
                },
                filterParams: {
                    cellRenderer: function (params) {
                        return params.data.FormattedCurrentPledgeAmount;
                    },
                },
            },
            {
                field: 'LimitToTotalAmount',
                headerName: 'Maximum pledge amount',
                filter: 'agNumberColumnFilter',
                cellRenderer: function (params) {
                    if (!params.data) return params.value;

                    return params.data.FormattedLimitToTotalAmount;
                },
                filterParams: {
                    cellRenderer: function (params) {
                        return params.data.FormattedPledgeAmount;
                    },
                },
            },
            {
                field: 'LimitToAfterDateTimeString',
                headerName: 'Start Date',
                filter: 'agDateColumnFilter',
                valueFormatter: agGridService.formatters.dateTime,
                hide: true
            },
            {
                field: 'LimitToBeforeDateTimeString',
                headerName: 'End Date',
                filter: 'agDateColumnFilter',
                valueFormatter: agGridService.formatters.dateTime,
                hide: true
            },
        ];

        vm.grid.api.setColumnDefs(columnDefs);
    });
}