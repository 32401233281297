angular.module("app").component("extjsIframe", {
  controller: extjsIframeComponentController,
  controllerAs: "vm",
  template: `<iframe style="height: 872px; width: 1px !important; min-width: 100% !important" frameborder="0" allowtransparency="true" scrolling = "no" ng-src="{{vm.iframeData.src}}" name="{{vm.iframeData.name}}"></iframe>`,
  bindings: {
    iframeData: '='
  }
});

function extjsIframeComponentController() {
  var vm = this;
}