angular
    .module('app')
    .component('outbox', {
        controller: outboxController,
        bindings: {
            $router: '<'
        },
        require: {
            parentComponent: '^email'
        },
        templateUrl: `outbox.tpl.html`,
        controllerAs: 'vm',
        $canActivate: function($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });

function outboxController($scope, agGridService, emailService, $q, $element, $mdDialog, formService) {
    const vm = this;
    vm.openRowBeforeChanges = {};

    var columnDefs = [
        {
            field: 'Title',
            colId: 'DisplayName',
            headerName: 'Template Name',
            filter: 'agTextColumnFilter',
        },
        {
            field: 'Scheduled For',
            colId: 'ScheduledDeliveryDate',
            headerName: 'Scheduled For',
            filter: 'agDateColumnFilter',
            valueGetter: agGridService.valueFromFields,
            valueFormatter: agGridService.formatters.dateTime,
            filterParams: {
                filterOptions: ['equals', 'greaterThanOrEqual', 'lessThanOrEqual'],
            },
        },
    ]
    
    vm.updateEmailDateDisplay = function(email) {
        email.DescriptionFunc = (item) => {
            let emailDate = formService.getFieldValueFromItem(item, 'Scheduled For');
            return `Scheduled for: <strong>${moment(emailDate).format('LLL')}</strong>`;
        }
        email.DescriptionAction = {
            text: 'Modify',
            hide: (item) => item.DisableEdit,
            action: (item) => vm.changeEmailSchedule(item),
        }
    }
    
    vm.loadRow = function(params) {
        emailService.addComposeEmailLogic(vm.grid.openRow.data);
        vm.updateEmailDateDisplay(vm.grid.openRow.data);
        vm.openRowBeforeChanges = _.cloneDeep(vm.grid.openRow);
        
        if (vm.pastSentTime(vm.grid.openRow.data)) {
            vm.grid.openRow.data.DisableEdit = true;
        }
    }
    
    vm.updateRow = function(updatedItem) {
        let updatedRow = vm.grid.api.getRowNode(updatedItem.Id);
        if (updatedRow) {
            updatedRow.setData(updatedItem);
        }
    }

    vm.cancelRow = function(updatedItem) {
        vm.updateRow(updatedItem);
    }

    vm.afterSave = function(updatedItem) {
        vm.updateRow(updatedItem);
    }
    
    vm.afterSaveField = function(updatedItem) {
        vm.updateRow(updatedItem);
    }

    vm.saveClosedRow = function(params, closedRow) {
        if (closedRow && closedRow.data && closedRow.data.saveIfChanges) {
            return closedRow.data.saveIfChanges(true);
        }
        
        return $q.resolve();
    }

    vm.pastSentTime = function(data) {
        return data && agGridService.getFieldValue(data.Fields, 'ScheduledDeliveryDelta') <= 0;
    }
    
    vm.isSendingSoon = function(data) {
        return data && agGridService.getFieldValue(data.Fields, 'ScheduledDeliveryDelta') < 60000;
    }
         
    vm.grid = agGridService.createNewGrid(columnDefs, {
        domLayout: 'autoHeight',
        sideBar: false,
        key: 'outbox',
        gridElement: $element.find('.ag-master-grid'),
        expandedCardElement: $element.find('.ag-external-record-row'),
        noRowsMessage: 'Scheduled emails will show up here.',
        datasource: agGridService.getDatasource('Email', 'PendingBlasts'),
        getRowNodeId: data => data.Id,
        rowClassRules: {
            // Sending soon applied if sending in less than one minute (delta is in milliseconds)
            'sending-soon': (params) => vm.isSendingSoon(params.data),
        },
        defaultColDef: {
            flex: 1,
            minWidth: 160,
        },
    }, {
        onRowGroupOpened: vm.loadRow,
        onOpenRowClosed: vm.saveClosedRow,
    });
    
    vm.refreshRows = function() {
        vm.grid.api.refreshServerSideStore()
    }

    vm.deleteEmail = function(email) {
        return emailService.deletePendingEmailBlast(email.Id).then(() => {
            vm.grid.closeOpenRow();
            vm.refreshRows();
        });
    }

    vm.saveAsTemplate = function(email) {
        emailService.showSaveAsEmailTemplateDialog(email);
    }

    vm.saveEmail = function(email) {
        return emailService.updateBlast(email);
    }

    vm.openEmail = function(email) {
        emailService.openEmail(email);

        let contactsField = formService.getFieldFromItem(email, 'Individual Recipients');
        
        emailService.getPendingRecipients(email.Id)
            .then(r => {
                let recipients = {};
                recipients.members = r.items[0].Members;
                recipients.contacts = r.items[0].Contacts;
                recipients.totalSelected = recipients.members.length + recipients.contacts.length
                contactsField.value = recipients;
                contactsField.loaded = true;
            })
    }
    
    vm.changeEmailSchedule = function(email) {
        var dateField = formService.getFieldFromItem(email, 'Scheduled For');
        var prompt = $mdDialog
            .prompt({
                bindToController: true,
                locals: {
                    required: true,
                    inputType: 'datetime-local',
                },
            })
            .title('Modify Email Schedule')
            .initialValue(new Date(dateField.value))
            .htmlContent(`This email is currently scheduled for: ${moment(dateField.value).format('LLL') }.<br> Enter a new scheduled date and time:`)
            .targetEvent(event)
            .ok('Update')
            .cancel('Cancel');

        return $mdDialog.show(prompt).then(newDate => {
            return email.saveField('Scheduled For', newDate)
        }, angular.noop);
    }

    vm.cardActions = [
        {
            label: 'Save as Template',
            action: (item, $event) => vm.saveAsTemplate(item),
        },
    ]
    vm.editorButtons = emailService.getEmailEditorButtons();
    
    $scope.$on('NewEmailBlastComposed', () => {
        vm.refreshRows();
    })
}