angular
  .module('app')
  .factory('configSettingsService', configSettingsService);


function configSettingsService($http, API_PREFIX, entityService, emailService) { 
  const CONFIG_FORM_LOCATIONS = {
    //Forms tab
    DONATION_SETTINGS: 1,
    REGISTRATION_SETTINGS: 2,
    REGISTRATION_BUILDER: 3,
    TICKET_BUILDER: 4,
    FORM_BUILDER: 5,
    CUSTOM_SETTINGS: 6,
    ADVANCED_SETTINGS: 7,
    
    FORMS_ADVANCED: 10,
    WEBSITE_ADVANCED: 20,

    //Fundraising tab
    FUNDRAISING_ADVANCED: 11,
    FUNDRAISING_OPTIONS: 12,
    STARTUP_GUIDE: 40,
    HQ_TASKS: 41,
    FUNDRAISER_PDF: 43,

    EMAIL_ADVANCED: 45,
    EMAIL_PDF_RECEIPTS: 46,
    EMAIL_PDF_RECEIPTS_ADVANCED: 47,
    EMAIL_HEADER_FOOTER: 48,

    SYSTEM_ADVANCED: 50,
    INTEGRATION: 55,

    PAY_PAL: 70,
    NATIONAL_TEAMS: 80,
  };
  return {
    //Forms tab
    getDonationSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.DONATION_SETTINGS),
    getPayPalSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.PAY_PAL),
    getRegistrationSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.REGISTRATION_SETTINGS),
    getRegistrationBuilder: () => getSettingsForm(CONFIG_FORM_LOCATIONS.REGISTRATION_BUILDER),
    getTicketBuilder: () => getSettingsForm(CONFIG_FORM_LOCATIONS.TICKET_BUILDER),
    getFormBuilder: () => getSettingsForm(CONFIG_FORM_LOCATIONS.FORM_BUILDER),
    getCustomSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.CUSTOM_SETTINGS),
    getFormsAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.FORMS_ADVANCED),
    getWebsiteAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.WEBSITE_ADVANCED),
    //Fundraising tab
    getFundraisingAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.FUNDRAISING_ADVANCED),
    getFundraisingOptions: () => getSettingsForm(CONFIG_FORM_LOCATIONS.FUNDRAISING_OPTIONS),
    getStartupGuideSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.STARTUP_GUIDE),
    getHqTaskSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.HQ_TASKS),
    
    //Email
    getEmailAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.EMAIL_ADVANCED),
    getEmailPdfReceiptSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.EMAIL_PDF_RECEIPTS),
    getEmailPdfReceiptAdvancedSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.EMAIL_PDF_RECEIPTS_ADVANCED),
    getEmailHeaderFooterSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.EMAIL_HEADER_FOOTER),

    //System
    getIntegrationSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.INTEGRATION),
    getSystemAdvancedSettings: () =>  getSettingsForm(CONFIG_FORM_LOCATIONS.SYSTEM_ADVANCED),

    //People
    getNationalTeamSettings: () => getSettingsForm(CONFIG_FORM_LOCATIONS.NATIONAL_TEAMS),

    //General updates
    updateSetting: settingObj => sendConfigRequest(settingObj, 'UpdateSetting'),
    adoptParent: settingID => sendConfigRequest({ settingID }, 'AdoptParent'),
    overrideParent: settingID => sendConfigRequest({ settingID }, 'OverrideParent'),
    clearOverrides: settingID => sendConfigRequest({ settingID }, 'DeleteConfigsOfChildren'),
  };

  function getSettingsForm(formID) {
    let entityId = entityService.getOpenedEntityId();

    return $http({
      url: `${API_PREFIX}Cmp/GetSettingsForm`,
      headers: { entityId },
      data: { formID },
      method: 'POST'
    }).then(response => response.data);
  }

  function sendConfigRequest(data, actionName) {
    let entityId = entityService.getOpenedEntityId();

    return $http({
      url: `${API_PREFIX}Cmp/${actionName}`,
      data,
      headers: { entityId },
      method: 'POST'
    }).then(response => response.data);
  }
}