angular
  .module('app')
  .component('socialShares', {
    controller: socialSharesController,
    templateUrl: 'socialShares.tpl.html',
    bindings: {
      $router: '<'
    },
    require: {
      website: '^website'
    },
    controllerAs: 'vm',
    $canActivate: function ($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
  });

function socialSharesController(entityContentService, orgService, entityService) {
  const vm = this;
  this.$routerOnActivate = function (next, prev) {
    this.website.showIframe = false;
    this.website.loading = false;
  }
  vm.entityType = entityService.getOpenEntity().entityType;
  vm.termType = 'Social';
  vm.getData = getData;
  vm.loading = true;

  function getData() {
    dataRequest()
      .then(items => {
        vm.grid = items;        
      }).catch(() => {
        
      }).finally(() => vm.loading = false);
  }

  function dataRequest() {
    return entityContentService.getSocialShares(vm.website.openEntity, vm.selectedLanguage)
  }

  orgService.orgInfoLoadedPromise.then(r => {
    entityContentService.getTemplatePlaceholders(vm.website.openEntity, 32).then(items => {
      CKEDITOR.config.macros = items;
      entityService.getEntitySettings(vm.website.openEntity).then(entity => {
        vm.languages = entity.settings.LanguagesKeyValue;
        vm.selectedLanguage = entity.settings.DefaultLanguage;
        vm.isMultiLanguage = entity.settings.IsMultiLanguage;
        getData();
      });
    });    
  });

  vm.clicked = function(item) {
    return vm.saveTerm(item, false);
  }

  vm.overrideParent = function(item) {
    return vm.saveTerm(item, true);
  }

  vm.saveTerm = function(item, createDuplicate) {
    return entityContentService.updateTerm(vm.website.openEntity, item, vm.termType, vm.selectedLanguage, createDuplicate);
  }

  vm.adoptParent = function(item) {
    return entityContentService.deleteTerm(vm.website.openEntity, item, vm.termType, vm.selectedLanguage).then(() => dataRequest());
  }
}
