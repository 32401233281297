angular
  .module('app')
  .component('salesforceDetails', {
    templateUrl: 'salesforce-details.tpl.html',
    bindings: {
      id: '=',
      $router: '<'
    },
    controller: SalesForceController,
    controllerAs: 'vm'
  });

function SalesForceController($scope, salesforceService) {
  const vm = this;

  vm.loading = true;
  vm.isSandbox = false;

  vm.sfInfo = {
    username: undefined,
    instanceUrl: undefined,
    loginPath: undefined,
  };

  vm.onReconnect = data => {  
    vm.$router.navigate(['Integration Details', {
      type: 'Salesforce',
      typeId: data.authId.split('/').join('~~')
    }]);
  }

  $scope.$watch('vm.id', () => {
    if (!vm.id) {
      return false;
    }
    vm.loading = true;
    salesforceService.getSalesforceInfo(vm.id)
      .then(sfInfo => {
        vm.sfInfo = sfInfo;
      })
      .catch(() => {
        vm.$router.navigate(['Integrations']);
      })
      .finally(() => {
        vm.loading = false;
      });
  })
}