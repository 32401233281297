angular
  .module('app')
  .directive('ckEditor', ckEditor);

function ckEditor(settingsService, $timeout) {
  return {
    require: '?ngModel',
    restrict: 'A',
    scope: {
      ckEditor: '=',
      ckEditorReadonly: '<',
      ckEditorMini: '<',
      ckEditorButtons: '<',
      ckEditorButtonsTargetItem: '<',
      ckEditorStartInSource: '<',
      ckEditorBeforeSetMode: '<',
      ckEditorItemEditable: '<',
      ckEditorDisplayFrame: '<',
    },
    link: function (scope, elm, attr, ngModel) {
      elm[0].name = attr.name;
      var ckEditor = scope.ckEditor;
      if (scope.ckEditorMini) {
        ckEditor = CKEDITOR.getMiniAdminConfig();
      }
      var config = angular.extend({}, ckEditor, { readOnly: scope.ckEditorReadonly || false });
      
      if (scope.ckEditorStartInSource) {
        config.startupMode = 'source';
      }
      
      if (scope.ckEditorButtons) {
        config.extraButtons = [];
        
        _.forEach(scope.ckEditorButtons, button => {
          if (!button.hide || !button.hide(scope.ckEditorButtonsTargetItem)) {
            button.command = (evnt) => { button.action(scope.ckEditorButtonsTargetItem, evnt, scope.ckEditorItemEditable) };
            config.extraButtons.push(button);
          }
        })
        
        if (config.extraPlugins) {
          config.extraPlugins += ',';
        } else {
          config.extraPlugins = '';
        }
        config.extraPlugins += 'extraButtons';
      }

      if (scope.ckEditorDisplayFrame) {
        config.sharedSpaces = {
          top: 'ckeditorTop' + attr.name,
          bottom: 'ckeditorBottom' + attr.name,
        };

        if (config.extraPlugins) {
          config.extraPlugins += ',';
        } else {
          config.extraPlugins = '';
        }
        config.extraPlugins += 'sharedspace,autogrow';
        config.removePlugins = 'maximize,resize';
        config.height = 200;
        config.autoGrow_minHeight = 200;
      }

      var ck = location.search.indexOf('fullCk') > -1
        ? CKEDITOR.replace(elm[0], { extraPlugins: 'placeholder' })
        : CKEDITOR.replace(elm[0], ckEditor ? config : CKEDITOR.getMiniAdminConfig());

      if (!ngModel) return;

      settingsService.getHelpLinks().then(r => window.ckediterHelpBtn = r.liquidTemplator);

      function updateModel() {
        $timeout(function() {
          ngModel.$setViewValue(ck.getData());
        });
      }
      ck.on('change', updateModel);
      ck.on('key', updateModel);
      ck.on('beforeSetMode', (evnt) => scope.ckEditorBeforeSetMode && scope.ckEditorBeforeSetMode(evnt));

      scope.$watch('ckEditorReadonly', function(value) {
        value !== undefined && ck && ck.editable() && ck.setReadOnly(value || false);
      });

      ngModel.$render = function (value) {
        ck.setData(ngModel.$viewValue);
      };
    }
  }
}