  angular
    .module('app')
    .component('reports', {
      templateUrl: 'reports.tpl.html',
      controllerAs: 'vm',
      bindings: { $router: '<' },
      controller: reportsController,
      $canActivate: function($nextInstruction, routingService) {
        return routingService.validateNoPendingChanges($nextInstruction);
      }
    });
  
    function reportsController($rootScope, routingService, entityService, extJsLoader, iframeMessageHandler, $location, $timeout) {
      const vm = this;
      vm.loading = true;
      vm.showComposeModal = false;
      vm.iframeData = {};

      this.$routerOnDeactivate = function(n, p) {
        extJsLoader.extJsController.iframeCreated = false;
        $timeout(function(){
          reloadPage(n,p);
        }, 100);
      }

      function reloadPage(n,p) {
        if ($location.search()['QueuedReport']) {
          if (n.componentType === p.componentType) {
            window.location.search = window.location.search;
          } else {
            window.location.search = '';
          }
        }
      }
  
      routingService.updateRouting()
        .then(r => {
          vm.openEntity = r;
          entityService.getEntitySettings(r).then(entity => {
            vm.onGroup = entity.entityType === 'CampaignGroup';
            vm.onOrg = entity.entityType === 'Org';
            var curEventId = entityService.getCurrentEventId(entity);
            if (curEventId) {
              vm.iframeSrc = `/site/${curEventId}?type=plat&value=`;
              if (!extJsLoader.extJsController.iframeCreated) {
                vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'reports');
                extJsLoader.extJsController.updateInnerPage('Reports');
              } else {
                vm.loading = false;
              }
              
              iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
            }
          });
         });
  
      $rootScope.$on('loaded', r => {
        extJsLoader.extJsController.loadInnerPage().then(r => {
          vm.loading = false;
        });
      });

      $rootScope.$on('emailFromReports', ($event, targetEvent) => {
        $timeout(function(){
          vm.showComposeModal = true;
        });
      });
    }