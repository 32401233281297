const app = angular.module('app', [
    'ngComponentRouter',
    'ngMaterial',
    'ngSanitize',
    'ngMessages',
    'ngImgCrop',
    'agGrid',
    'slickCarousel',
    'duScroll', //scroll spy'
    'templatesCache',
    'ngMaterialDatePicker',
    'rzModule',
    'infinite-scroll',
    'ngIdle',
    'ngAnimate'
]);

angular.lowercase = text => text.toLowerCase();

app.value('$routerRootComponent', 'app');

app.constant('API_PREFIX', (window.championApiPrefix || '') + '/Platform');

app.constant(
    'FORM_ITEM_TYPES', 
    {
        TOGGLE: 1,
        TEXT: 2,
        DATE: 3,
        DECIMAL: 4,
        SELECT: 5,
        MULTISELECT: 6,
        INT: 7,
        ORDERABLE: 8,
        TEXTAREA: 9,
        GROUP: 10,
    });

app.factory('rbTransformRequests', function($httpParamSerializerJQLike) {
    return {
        transformFormData: function(data) {
          var formData = new FormData();
          angular.forEach(data, function (value, key) {
              formData.append(key, value);
          });
          return formData;
        },
        transformForm: function (data) {
            return $httpParamSerializerJQLike(data);
        }
    };
});

app.config(['$httpProvider', function($httpProvider) {
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
}]);

var myApp = angular.module('myApp', []);

app.config(($compileProvider, $mdThemingProvider, $httpProvider, $mdInkRippleProvider) => {
    // disable debug directives
    // Increases performance
    // see https://code.angularjs.org/1.5.5/docs/guide/production
    // TODO uncomment in production
    //$compileProvider.debugInfoEnabled(false);

    // change default material primary colors
    let customPalette = $mdThemingProvider.extendPalette('indigo', {
        '400': '00698C',
        '500': '00698C',
        '600': '00698C'
    });

    //$mdInkRippleProvider.disableInkRipple(); //TODO update angular-material to 1.1.0 and uncomment

    $mdThemingProvider.definePalette('customIndigo', customPalette);

    $mdThemingProvider
        .theme('default')
        .primaryPalette('customIndigo');

    $httpProvider.interceptors.push(($q, $injector, $location) => {
        return {
            request(config) {
                if(config.method != 'GET') {
                    let currentMessageService = getCurrentMessageService(config);
                    if (currentMessageService.checkSetPendingRequest(config.url, config.data)) {
                        let response = {
                            config,
                            rejectedBcInProgress: true,
                            rejectedMsg: currentMessageService.config.requestInProgressMsg
                        };
                        return $q.reject(response);
                    }
                }
                addOrgIDToHeaders(config);
                return config;
            },
            response(response) {
                let currentMessageService = getCurrentMessageService(response.config);
                currentMessageService.resetPendingRequest(response.config.url, response.config.data);

                if (currentMessageService.config.suppressErrorHandling) {
                    return $q.resolve(response);
                }
                
                // handle json response
                if (response.headers()['content-type'] === "application/json; charset=utf-8") {
                    if (response && response.data) {
                        if (response.data.success){
                            currentMessageService.showSuccessToast();
                        }else {
                            if (response.data.msg) {
								 if (response.data.msg == 'LOGGED OUT') {
                                    location.href = window.isChampion
                                        ? '/Account/Logon?returnUrl=' + encodeURIComponent(location.pathname)
                                        : '/Admin/Account/LogOff?redirectToLogin=true&redirectTo=' + encodeURIComponent(location.pathname);
									return;
                                } else if (response.data.msg == 'ACCESS DENIED') {
                                    currentMessageService.showAccessDeniedDialog();
                                } else {
                                    currentMessageService.showErrorToast(response.data.msg)
                                }
                                return $q.reject(response.data.msg)
                            }
                            currentMessageService.showAdvancedError(response);
                            return $q.reject(response)
                        }
                    }
                }
                return $q.resolve(response);
            },
            // global error handler
            responseError(response) {
                let currentMessageService = getCurrentMessageService(response.config);

                if (response.rejectedBcInProgress){
                    currentMessageService.showRequestInProgressToast();
                    return $q.reject(response.rejectedMsg)
                } else if (response.status === 503) {
                    currentMessageService.resetPendingRequest(response.config.url, response.config.data);
                    currentMessageService.showErrorToast(response.data.msg);
                    return $q.reject(response.data.msg);
                }

                currentMessageService.resetPendingRequest(response.config.url, response.config.data);
                currentMessageService.showErrorToast();
                return $q.reject(response.statusText);
            }
        };

        function getCurrentMessageService(config) {
            let messageService = $injector.get('messageService');
            config.messageService = config.messageService || new messageService(config.messageConfig);
            return config.messageService;
        }

        function addOrgIDToHeaders(config) {
			var orgId = getOrgIdFromPath();
			if(orgId){ //it may have it from actual request and if iyt's initial call before url changed we don't want to remove it
				config.headers['orgId'] = getOrgIdFromPath();
			}
        }
    })
});

window.getOrgIdFromPath = function() {
    var pathSplit = location.pathname.split('/');
    var orgIndex = pathSplit.indexOf('org');
    if (orgIndex > -1 && pathSplit.length > orgIndex) {
        return pathSplit[orgIndex + 1];
    }
};

const startEndRegex = /(?:^\/+)|(?:\/+$)/;
const middleRegex = /\/+/;

window.getCampaignPathFromPath = function () {
    const pathSplit = location.pathname.replace(startEndRegex, '').split(middleRegex);
    if (pathSplit.length < 2 || pathSplit[1].toLowerCase() != 'championadmin') {
        return undefined;
    }
    return pathSplit[0];
};

app.config(['$mdAriaProvider', function ($mdAriaProvider) {
	$mdAriaProvider.disableWarnings();
}]);

app.config(function($locationProvider) {
	$locationProvider.html5Mode({
	  enabled: true
	});
});

app.config(function ($provide) {
    $provide.decorator('$mdDialog', function ($delegate) {
        let dialogs = ['confirm', 'prompt', 'alert'];
        let defaults = { templateUrl: 'generic-dialog.tpl.html', controllerAs: 'vm' };

        dialogs.forEach(dialog => {
            let originalDialog = $delegate[dialog];

            $delegate[dialog] = function () {
                let args = angular.extend({}, defaults,  arguments[0]);
                return originalDialog(args)
            };
        });

        return $delegate;
    });

    $provide.decorator("$exceptionHandler", ["$delegate", "$window", function($delegate, $window) {
        return function (exception, cause) {
            if (window.TrackJS) {
                if (cause != undefined) {
                    window.TrackJS.addMetadata("cause", cause);
                }
                window.TrackJS.track(exception);
                window.TrackJS.removeMetadata("cause");
            } else {
                // In *non-production* environments you may still want the error sent to the console.
                // You can delegate to the original handler (console.error) if you would like.
                // Warning, this can cause double tracking of errors, so do not use in production.
                // $delegate(exception, cause);
                $delegate(exception, cause)
            }
        };
    }]);
});

app.component('app', {
    templateUrl: 'app.tpl.html',
    $routeConfig: [ {
        path: 'org/:orgId/organization/...',
        name: 'Organization',
        component: 'organization',
        data: {
            authenticate: true,
            allowOnInstance: true
        }
    }, {
        path: 'my/...',
        name: 'Profile Settings',
        component: 'profileSettings',
        data: {
            authenticate: true,
            allowOnInstance: true
        }
    }, {
        path: 'p/...',
        name: 'Static',
        component: 'static'
    }, {
        path: 'org/:orgId/group/:groupId/campaign/:campaignId/instance/:instanceId/...',
        name: 'Instance In Group',
        component: 'campaigns',
        data: {
            authenticate: true,
            allowOnInstance: true
        }
    }, {
        path: 'org/:orgId/group/:groupId/campaign/:campaignId/...',
        name: 'Campaign In Group',
        component: 'campaigns',
        data: {
            authenticate: true,
            allowOnInstance: true
        }
    }, {
        path: 'org/:orgId/group/:groupId/...',
        name: 'Group',
        component: 'campaigns',
        data: {
            authenticate: true,
            allowOnInstance: true
        }
    }, {
        path: 'org/:orgId/campaign/:campaignId/instance/:instanceId/...',
        name: 'Instance',
        component: 'campaigns',
        data: {
            authenticate: true,
            allowOnInstance: true
        }
    }, {
        path: 'org/:orgId/campaign/:campaignId/...',
        name: 'Campaign',
        component: 'campaigns',
        data: {
            authenticate: true,
            allowOnInstance: true
        }
    }, {
        path: ':campaignPath/ChampionAdmin/...',
        name: 'ChampionAdmin',
        component: 'campaigns',
        data: {
            authenticate: true,
            allowOnInstance: true
        }
    },
    {
        path: 'org/:orgId/...',
        name: 'Org',
        component: 'campaigns',
        data: {
            authenticate: true,
            allowOnInstance: true
        },
        useAsDefault: true
    } ],
    controller: AppController,
    controllerAs: 'vm'
});

function AppController($http, API_PREFIX, $timeout, $q, $location, $rootScope, $mdToast, $sce, profileService, orgService, campaignService, authenticationService, entityService, settingsService, ckEditorService, cookieService) {
    const vm = this;

    

    vm.dataLoaded = false;
    vm.showNotification = false;
    vm.showExitButton = false;
    vm.showDontShowAgain = false;
    vm.currentNotificationId;
    vm.showTour = false;

    vm.closeBanner = closeBanner;
    vm.dontShowAgain = dontShowAgain;

    vm.currentYear = new Date().getFullYear();

    fetchData();
    ckEditorService.configureCkEditor();

    ///////////////////////////////////////////////////////

    function fetchData() {

        authenticationService.checkAuthentication()
            .then(isAuthenticated => {
                vm.isAuthenticated = isAuthenticated;

                if (vm.isAuthenticated) {
                    profileService.getProfileInfo()
                        .then((profileInfo) => {
                            let orgFromUrl = orgService.getUrlOrgId();
                            let orgOrgId = orgFromUrl || profileInfo.userSettings.orgId || profileInfo.orgs[0].orgId;
                            let curOrg;
                            if(orgOrgId){
                                profileInfo.orgs.forEach((item, index) => {
                                    if (item.orgId ==  orgOrgId) {
                                        curOrg = item;
                                    }
                                });
                            }
                            if (curOrg) {
                                orgService.setOrg(curOrg);								
								settingsService.loadStaticInfo();
                                campaignService.openGroup(0, orgService.getOrg().entityId)
                                    .then((campaigns) => {
                                        entityService.updateAllEntities();
                                        vm.dataLoaded = true;
                                    });

                                orgService.orgInfoLoadedPromise.then(r => {
                                    vm.splashNotificationsData = vm.splashNotificationsData.concat(r.currentSplashNotifications);
                                });

                                vm.splashNotificationsData = profileInfo.currentSplashNotifications;
                                vm.splashLastFinishedNotificationId = profileInfo.userSettings.lastFinishedSplashNotificationId || 0;

                                let seenInSession = cookieService.readCookie("rb_sawAlert");
                                if (!seenInSession) {
                                    vm.showExitButton = profileInfo.currentNotification.showCloseButton;
                                    vm.showDontShowAgain = profileInfo.currentNotification.showDontShowAgain;
                                    vm.msg = $sce.trustAsHtml(profileInfo.currentNotification.innerHtml);
                                    let lastFinishedNotificationId = profileInfo.userSettings.lastFinishedNotificationId || 0;
                                    vm.currentNotificationId = profileInfo.currentNotification.notificationId;
                                    if (vm.currentNotificationId > lastFinishedNotificationId) {
                                        var fn = function () {
                                            vm.showNotification = true;
                                        };
                                        $timeout(fn, 2000);
                                    }
                                }

                                if(profileInfo.userSettings.showPortAdminTour === '1') {
                                    vm.showTour = true; 
                                }
                                
                                // #region Pendo installation
                                // Run only in production environment where pendo api key is available
                                if (pendoApiKey && !window.isChampion){
                                    orgService.getOrgInfo()
                                        .then((data) => {
                                            if(!data.isDemoOrg || orgOrgId == 260 || orgOrgId == 432){
                                                let pendoValue = {
                                                    apiKey: pendoApiKey,
                                                    orgName: data.name,
                                                    firstName: profileInfo.firstName,
                                                    lastName: profileInfo.lastName,
                                                    salesforceId: data.salesforceAccountID,
                                                    creationDate: data.creationDate,
                                                    email: profileInfo.userEmail,
                                                    role: pendoRole
                                                };
                                                loadPendo(pendoValue);
                                            }
                                        });   
                                }
                                // #endregion

                            } else {
                                alert('Sorry, we had an issue finding your organization ' + (orgOrgId || ''));
                            }

                        })
                } else {
                    vm.dataLoaded = true;
                }
            })


    }

    vm.endTour = function sawTour() {
        vm.showTour = false;
        $http({
            url: API_PREFIX + 'Profile/UpdateUserSettings',
            data: { list: { showPortAdminTour: 0 } },
            method: 'POST'
        });
    }

    function dontShowAgain() {
        cookieService.createCookie("rb_sawAlert", "1");
        vm.showNotification = false;
        $http({
            url: API_PREFIX + 'Profile/UpdateUserSettings',
            data: { list: { lastFinishedNotificationId: vm.currentNotificationId } },
            method: 'POST'
        });
    }
    
    function closeBanner() {
        vm.showNotification = false;
        cookieService.createCookie("rb_sawAlert", "1");
        let notificationIdAndCnt = cookieService.readCookie("rb_alert_cnt");
        let cookieCnt;
        if (!notificationIdAndCnt) {
            cookieCnt = 1;
        } else {
            let splitArray = notificationIdAndCnt.split("|");
            let cookieNotificationId = splitArray[0];
            cookieCnt = splitArray[1];
            if (cookieCnt >= 3) {
                $http({
                    url: API_PREFIX + 'Profile/UpdateUserSettings',
                    data: { list: { lastFinishedNotificationId: vm.currentNotificationId } },
                    method: 'POST'
                });
            }
        }
        cookieService.createCookie("rb_alert_cnt", vm.currentNotificationId + "|" + (parseInt(cookieCnt) + 1), 30);
    }

    function loadPendo(pendoValue) {
        let apiKey = pendoValue.apiKey;
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
            v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

        setTimeout(() =>{
            if (pendo) {
                let salesforceId = pendoValue.salesforceId ? pendoValue.salesforceId : '';
                let visitorId = salesforceId + ' - ' + pendoValue.email;
                pendo.initialize({
                    visitor: {
                        id: visitorId,
                        firstname_fundraise: pendoValue.firstName,
                        lastname_fundraise: pendoValue.lastName,
                        email_fundraise: pendoValue.email,
                        role: pendoValue.role
                    },
                    account: {
                        id: salesforceId,
                        name: pendoValue.orgName,
                        creationDate: pendoValue.creationDate,
                    }
                });
            }
        }, 2000)
    }
}
