angular.module('app').component('groupItem', {
  bindings: {
    fields: '=',
    label: '<',
    configId: '<',
    onChange: '<',
    sectionUpdating: '<',
    number: '<',
    inherited: '<',
    isDefault: '<',
    parentName: '<',
    disabled: '<',
    tooltip: '<',
    entityType: '<',
    noLock: '<'
  },
  controllerAs: 'vm',
  controller: groupItemController,
  templateUrl: 'group-item.tpl.html'
});

function groupItemController(FORM_ITEM_TYPES, $q) {
  var vm = this;
  vm.formItemTypes = FORM_ITEM_TYPES;
  
  vm.itemEditing = false;
  vm.itemUpdating = false;
  
  vm.$onInit = function() {
    // Keep a copy of local fields so that changes to a field are only committed when saved
    // If the same object is used then a change to a textarea or text field would immediately update the fields object
    // which would be saved if a different field is saved (e.g. a toggle clicked) even if the field is still in edit mode
    vm.localFields = _.cloneDeep(vm.fields);
  }
  
  vm.onFieldChange = function(id, value) {
    let oldValue = getFieldValue(vm.localFields, id);
    
    setFieldValue(vm.localFields, id, value);
    
    return vm.onSave().then(success => {
      if (!success) {
        setFieldValue(vm.localFields, id, oldValue);
      }
      return success;
    })
  }
  
  function getFieldValue(fields, id) {
    let field = fields[id];
    if (!field) return;
    
    return field[getValuePropertyName(field)];
  }
  
  function setFieldValue(fields, id, value) {
    let field = fields[id];
    if (!field) return;
    
    field[getValuePropertyName(field)] = value;
  }
  
  function getValuePropertyName(field) {
    if (field.Type === FORM_ITEM_TYPES.TOGGLE) {
      return 'On';
    }
    return 'Value';
  }

  vm.onSave = function() {
    if (vm.inherited || vm.disabled || vm.itemUpdating) {
      vm.itemEditing = false;
      return $q.resolve(false);
    }

    vm.itemUpdating = true;
    return vm.onChange(vm.configId, vm.localFields, false, true)
      .then(function(success) {
        vm.itemUpdating = false;
        vm.itemEditing = false;
        
        return success;
      });
  };

}
