angular
    .module('app')
    .directive('ngModelFilter', ngModelFilter);

function ngModelFilter() {
    return {
        restrict: 'A',
        priority: 10, //Run after ngModel
        require: 'ngModel',
        link($scope, $element, $attrs, ngModelController) {
            let filter = $scope.$eval($attrs.ngModelFilter);
            let filterUpdateOn = $attrs.ngModelFilterUpdateOn;

            ngModelController.$parsers.push(value => {
                //convert data from view format to model format
                return filter.unfilter(value);
            });

            ngModelController.$formatters.push(value => {
                //convert data from model format to view format
                return filter.filter(value);
            });

            if(filterUpdateOn) {
                $element.on(filterUpdateOn, () => {
                    ngModelController.$viewValue = ngModelController.$formatters.reduceRight(((prev, fn) => fn(prev)), ngModelController.$modelValue);
                    ngModelController.$render();
                })
            }
        }
    }
}
