angular
    .module('app')
    .directive('escKey', function () {
    return function (scope, element, attrs) {
        element.bind('keydown keypress', function (event) {
            if(event.which === 27) { // 27 = esc key
                scope.$apply(function (){
                    scope.$eval(attrs.escKey);
                });

                event.preventDefault();
                event.stopPropagation();
            }
        });
        scope.$on('$destroy', function() {
            element.unbind('keydown keypress')
        })
    };
})