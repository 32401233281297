angular
  .module('app')
  .component('serviceCard', {
    bindings: {
      image: '@',
      name: '@'
    },
    transclude: true,
    templateUrl: 'service-card.tpl.html',
    controllerAs: 'vm'
  });

