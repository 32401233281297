angular
    .module('app')
    .component('modalAddAutomation', {
        bindings: {
            show: '=',
            onCreateAction: '='
        },
        controllerAs: 'vm',
        controller: ModalAddAutomationController
    })

function ModalAddAutomationController($scope, $mdDialog)
{
    const vm = this;

    $scope.$watch('vm.show', () => {
        if (vm.show) {
            showModal();
        }
    });

    function showModal()
    {
        $mdDialog.show({
            templateUrl: 'modal-add-automation.tpl.html',
            controller: ModalAddAutomationContentCtrl,
            controllerAs: 'vm',
            locals: {
                onCreateAction: vm.onCreateAction
            },
            clickOutsideToClose: true
        })
        .finally(() => {
            vm.show = false;
        })
    }
}

function ModalAddAutomationContentCtrl(
    $mdDialog, campaignService, automationService, onCreateAction, settingsService)
{
    const vm = this;
    vm.onCreateAction = onCreateAction;

    vm.timezone = {
        utcOffset: -7,
        name: 'Pacific Daylight Time'
    };
    settingsService.getTimeZoneInfo().then(tz => vm.timezone = tz);

    const defaultHour = 3; // 3AM
    const defaultDate = moment()
        .utcOffset(vm.timezone.utcOffset)
        .startOf('day')
        .add(1, 'days')
        .add(defaultHour, 'hours')
        .toDate(); // tommorow at 3AM

    // vm.currentTime = moment()
    //     .utcOffset(vm.timezone.utcOffset)
    //     .format('MMM D ha');

    automationService.getAvailableActions()
        .then(actionTypes => vm.actions = actionTypes);

    vm.action = {
        action: null,
        entity: null,
        hour: defaultHour,
        date: defaultDate
    }
    
    vm.querySearch = querySearch;
    vm.hours = loadHours();
    vm.campaigns = loadCampaigns();

    // between tommorow and December of 10 years from now (e.g. 2019-2029)
    vm.minDate = moment()
        .utcOffset(vm.timezone.utcOffset)
        .startOf('day')
        .add(1, 'days')
        .toDate();
    vm.maxDate = moment()
        .utcOffset(vm.timezone.utcOffset)
        .add(10, 'years')
        .endOf('year')
        .subtract(1, 'day')
        .toDate();

    vm.updateHour = updateHour;
    vm.validInput = validInput;
    vm.closeModal = closeModal;

    vm.save = save;

    function querySearch(query) {
        let results = query
            ? vm.campaigns.filter(createFilterFor(query))
            : vm.campaigns;
        return results;
    }

    function closeModal()
    {
        $mdDialog.hide();
    }

    function validInput() {
        return vm.action.entity
            && vm.action.action
            && vm.action.date
            && vm.action.hour >= 0
            && vm.action.date instanceof Date
            && vm.action.date >= vm.minDate
    }

    function save(){

        if (!vm.onCreateAction) {
            //console.error('onCreateAction method not set!', vm.onCreateAction)
            return;
        }

        vm.saving = true;

        vm.onCreateAction(
            vm.action.entity.entityId,
            vm.action.action,
            moment(vm.action.date).utcOffset(vm.timezone.utcOffset).toJSON()
        ).finally(() => {
            vm.saving = false;
            closeModal();
        });
    }

    function updateHour()
    {
        vm.action.date = moment(vm.action.date)
            .utcOffset(vm.timezone.utcOffset)
            .startOf('day')
            .add(vm.action.hour, 'hours')
            .toDate();
    }

    //#region helpers
    function createFilterFor(query) {
        var lowercaseQuery = angular.lowercase(query);
  
        return function filterFn(camp) {
            return (camp.value.indexOf(lowercaseQuery) === 0);
        };
    };

    function loadCampaigns() {
        // join campaigns and groups
        var campaigns = [].concat(
            campaignService.getRawGroups(),
            campaignService.getRawCampaigns()
        );

        return campaigns.map(campaign => ({
            value: campaign.name.toLowerCase(),
            entityId: campaign.entityId,
            entityType: campaign.entityType,
            url: campaign.url,
            smallImage: campaign.smallImage,
            display: `${campaign.name} (${campaign.entityType == 'Campaign' ? 'Campaign' : 'Group'})`
        }));
    }

    function loadHours() {
        let hours = [];

        for (var i = 0; i < 24; i++) {
            const label = moment()
                .startOf('day')
                .add(i, 'hours')
                .format('LT');
            hours.push({
                value: i,
                label: label
            });
        }

        return hours;
    }

    //#endregion
}