angular
  .module('app')
  .component('nationalTeams', {
    controller: nationalTeams,
    bindings: { 
      $router: '<'
    },
    require: {
      parentComponent: '^people'
    },
    controllerAs: 'vm',
    templateUrl: 'national-teams.tpl.html',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function nationalTeams(extJsLoader, $rootScope, $scope, $mdDialog, entityService, nationalTeamsService, configSettingsService, securityService) {
  const vm = this;
  var entity = entityService.getOpenEntity();
  vm.entityType = entity.entityType;
  vm.entityId = entity.entityId;
  vm.LOCK_KEY = nationalTeamsService.NT_CONFIG_ID;
  this.$routerOnActivate = function(next, prev) {
    this.parentComponent.showIframe = false;
    this.parentComponent.loading = false;
    vm.parentComponent = this.parentComponent;
  };
  
  this.$routerOnDeactivate = function(next, pref) {
    this.parentComponent.campaigns.updateShowAddBtn(false, 'campaign');
  };

  vm.security = securityService.getAdminPermissions(entity, 'PEOPLE_NATIONAL_TEAMS');
  vm.showNationalTeamModal = false;
  vm.currentTeam = {};

  vm.loading = true;
  nationalTeamsService.getEnabledNationalTeams().then(r => {
    vm.loading = false;
    vm.nationalTeams = r.nationalTeams;
    vm.setting = r.setting;
    vm.parentComponent.campaigns.updateShowAddBtn(!vm.setting.Inherited && vm.security.EDIT, 'nationalTeam', true);
  });

  vm.confirmRemoveNationalTeam = function(team, event) {
    var confirm = $mdDialog
        .confirm()
        .title('Are you sure?')
        .textContent(
            `This National Team will no longer be available on ${vm.entityType === 'Campaign' ? 'this campaign' : 'campaigns within this group'}.`
        )
        .targetEvent(event)
        .ok('Remove')
        .cancel('Cancel');

    $mdDialog.show(confirm).then(
        function() {
          vm.removeNationalTeam(team);
        },
        function() {
          return;
        }
    );
  }

  vm.viewNationalTeam = function(team) {
    vm.currentTeam = team;
    vm.showNationalTeamModal = true;
  }
  vm.removeNationalTeam = function(team) {
    nationalTeamsService.removeNationalTeam(team.TeamId)
    .then(response => {
      vm.setting.IntValues.splice(vm.setting.IntValues.indexOf(team.TeamId), 1);
      nationalTeamsService.getEntityNationalTeams(vm.setting.IntValues).then(r => {
        vm.nationalTeams = r.nationalTeams;
      });
    })
  }

  vm.refreshNationalTeams = function() {
    nationalTeamsService.getEntityNationalTeams(vm.setting.IntValues).then(r => {
      vm.nationalTeams = r.nationalTeams;
      vm.parentComponent.campaigns.updateShowAddBtn(!vm.setting.Inherited, 'nationalTeam');
    });
  }
  
  $rootScope.$on('addNewSelected', () => {
    vm.showNationalTeamModal = true;
  });

  $scope.$on('adoptParent', function (event, key) {
    if (key == vm.LOCK_KEY) {
      vm.sectionUpdating = true;
      configSettingsService
        .adoptParent(vm.LOCK_KEY)
        .then(r => {
          vm.setting = r.setting;
          vm.refreshNationalTeams();
        })
        .finally(() => {
          vm.sectionUpdating = false;
        });
    }
  });

  $scope.$on('overrideParent', function (event, key) {
    if (key == vm.LOCK_KEY) {
      vm.sectionUpdating = true;
      configSettingsService
        .overrideParent(vm.LOCK_KEY)
        .then(r => {
          vm.setting = r.setting;
          vm.refreshNationalTeams();
        })
        .finally(() => {
          vm.sectionUpdating = false;
        });
    }
  });

  $scope.$on('clearOverrides', function (event, key) {
    if (key == vm.LOCK_KEY) {
      vm.sectionUpdating = true;
      configSettingsService
        .clearOverrides(vm.LOCK_KEY)
        .then(r => {
          vm.setting = r.setting;
        })
        .finally(() => {
          vm.sectionUpdating = false;
        });
    }
  });

}