angular
  .module('app')
  .factory('authenticationService', authenticationService);

function authenticationService($http, API_PREFIX) {
  let authenticationPromise = fetchIsAuthenticated();

  return { checkAuthentication };

  function checkAuthentication() {
    return authenticationPromise;
  }

  function fetchIsAuthenticated() {
    return $http({ url: `${API_PREFIX}Unauthenticated/IsAuthenticated`, method: 'POST' })
      .then(response => response.data.IsAuthenticated);
  }
}