angular
  .module('app')
  .component('donors', {
    controller: donorsController,
    bindings: { 
      $router: '<'
    },
    require: {
      parentComponent: '^people'
    },
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function donorsController(extJsLoader, iframeMessageHandler) {
  const vm = this;
  this.$routerOnActivate = function(next, prev) {
    extJsLoader.extJsController.updateInnerPage('People-Donors');
    this.parentComponent.showIframe = true;
  }
}