angular
  .module('app')
  .directive('outsideClick', OutsideClick);

function OutsideClick($document, $timeout) {
  return {
    restrict: 'A',
    link($scope, $element, $attrs) {
      const scopeExpression = $attrs.outsideClick;

      const onDocumentClick = (event) => {
        let isChild = $element[0].contains(event.target);
         
        if (!isChild || event.target.parentNode.tagName === 'A') {
          $scope.$apply(scopeExpression);
        }
      };

      $timeout(() => {
        $document.on('click', onDocumentClick);
      });


      $element.on('$destroy', () => {
        $document.off('click', onDocumentClick);
      });
    }
  }
}