angular
  .module('app')
  .component('automation', {
    templateUrl: 'automation.tpl.html',
    controller: AutomationController,
    controllerAs: 'vm'
  });

function AutomationController(
  securityService, entityService, orgService, automationService, $mdDialog)
{
  const vm = this;

  loadData();

  vm.actions = [];
  vm.show = false;
  vm.save = save;
  vm.loadData = loadData;
  vm.showModal = showModal;
  vm.formatDate = formatDate;
  vm.security = securityService.getAdminPermissions(orgService.getOrg(), 'CAMPAIGN');
  vm.getEntity = getEntity;
  vm.showDeleteConfirm = showDeleteConfirm;

  automationService.getAvailableActions().then(function(actionTypes) {
    return vm.actionNames = actionTypes
      .reduce(function(object, action) {
        return Object.assign({}, object, { [action.value]: action.label })
      }, {});
    });

  function save(entityId, action, scheduledFor) {
    return automationService.createEntityAction(
      entityId, action, scheduledFor
    ).then(loadData);
  }

  function deleteAction(entityId, actionId) {
    return automationService.deleteEntityAction(
      entityId,
      actionId
    ).then(loadData);
  }

  function loadData() {
    vm.loaded = false;

    var entityId = entityService.getOrgEntityId();
    automationService.getEntityActions(entityId)
      .then(actions => {
        if (Array.isArray(actions)){
          vm.actions = actions
        } else {
          vm.unknownError = true;
        }
      })
      .finally(() => {
        vm.loaded = true
      });
  }

  function getEntity(entityId) {
    return entityService.getRawEntityById(entityId);
  }

  function formatDate(date) {
    return moment(date).format('L LT')
  }

  function showModal() {
    vm.show = true;
  }

  function showDeleteConfirm(entityId, actionId, event) {
    let confirm = $mdDialog.confirm()
      .clickOutsideToClose(true)
      .title('Are you sure?')
      .htmlContent(`This action will remove this automated action.`)
      .targetEvent(event)
      .ok('Remove Action')
      .cancel('Cancel');

    $mdDialog.show(confirm).then(() => deleteAction(entityId, actionId));
  }
}
