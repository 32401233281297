angular.module('app').component('toggleItem', {
  bindings: {
    toggled: '<',
    type: '<',
    label: '<',
    configId: '<',
    onChange: '<',
    sectionUpdating: '<',
    inherited: '<',
    isDefault: '<',
    parentName: '<',
    disabled: '<',
    tooltip: '<',
    draggable: '<',
    entityType: '<',
    noLock: '<',
    processorInstanceId: '<',
  },
  controllerAs: 'vm',
  controller: toggleController,
  templateUrl: 'toggle-item.tpl.html'
});


function toggleController() {
  var vm = this;
  vm.onToggle = function() {
    vm.onChange(vm.configId, vm.toggled, false, false, vm.processorInstanceId).then(r => {
      if(!r) {
        vm.toggled = !vm.toggled;
      }
      return r;
    });
  };
}