angular.module('app').component('emailAdvanced', {
  controllerAs: 'vm',
  controller: emailAdvancedController,
  require: {
    parentComponent: '^email'
  }, 
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateNoPendingChanges($nextInstruction);
  },
  template: `<div ng-if="vm.emailUnverified" class="warning-block md-box-shadow" style="margin-bottom: 50px">
                The domain <strong>{{vm.emailDomain}}</strong> is not verified to send emails. Any email addresses from that domain will be used as the "Reply To" email address, not the "From" email address. 
                <span ng-if="vm.hasEmailDomainsPermission"><a ng-href="/org/{{vm.orgId}}/organization/email-domains">Click here</a> to manage your email domain verification</span>
                <span ng-if="!vm.hasEmailDomainsPermission">Contact your administrator to verify the domain</span>
            </div>
            <form-template
               form-sections='vm.sections'
               on-update='vm.onChange'></form-template>`
});

function emailAdvancedController(configSettingsService, securityService, orgService, emailDomainsService) {
    const vm = this;
    const ADMIN_FROM_EMAIL_CONFIG_ID = 260;
    const ADVANCED_EMAIL_CONFIG_SECTION_ID = 12;
    vm.sections = [];
    vm.emailUnverified = false;
    vm.emailDomain = undefined;
    vm.orgId = orgService.getOrgId();
    vm.hasEmailDomainsPermission = securityService.getAdminPermissions(orgService.getOrg(), 'EMAIL_ADVANCED_OPTIONS').DELETE;

    configSettingsService.getEmailAdvancedSettings().then(function(r) {
      if (r.success && r.form) {
        vm.sections = r.form.FormSections;
        vm.checkEmailDomainVerified(vm.getAdminEmailFrom(vm.sections));
      } else {
      }
    });

    vm.onChange = function(config) {
        if (config.configId == ADMIN_FROM_EMAIL_CONFIG_ID) { // Admin From Email Address
            vm.checkEmailDomainVerified(config.value);
        }
        return configSettingsService.updateSetting(config);
    };

    vm.getAdminEmailFrom = function(sections) {
        var section = vm.sections.find(x => x.ConfigSectionId = ADVANCED_EMAIL_CONFIG_SECTION_ID);
        if (!section || !section.Fields) return;
        
        var emailField = section.Fields.find(x => x.SettingId == ADMIN_FROM_EMAIL_CONFIG_ID);
        if (!emailField) return;
        
        return emailField.Value
    }
    
    vm.checkEmailDomainVerified = function(email) {
        if (!email) return;
        if (!email.includes('@')) return;
        
        var domain = email.split('@')[1];
        vm.emailDomain = domain;
        
        emailDomainsService.getEmailDomainStatus(domain).then(verified => vm.emailUnverified = !verified)
    }
}