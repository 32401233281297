angular
  .module('app')
  .component('modalCrop', {
    controller: ModalCropCtrl,
    controllerAs: 'vm'
  });


function ModalCropCtrl($scope, $mdDialog, $rootScope) {
  const vm = this;

  let uploadHandler = $rootScope.$on('file.uploaded', (event, data) => {
    vm.entityId = data.entityId;
    vm.img = data.img;
    vm.type = data.imgType;
    vm.objectId = data.objectId;

    showModal()
  });

  $scope.$on('$destroy', uploadHandler);


  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-crop.tpl.html',
        controller: ModalCropInnerCtrl,
        controllerAs: 'vm',
        multiple: true,
        locals: {
          entityId: vm.entityId,
          img: vm.img,
          type: vm.type,
          objectId: vm.objectId
        },
        clickOutsideToClose: false
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalCropInnerCtrl($q, $mdDialog, $rootScope,
                            campaignService,
                            entityId, img, type, objectId) {
  const vm = this;

  vm.closeModal = closeModal;
  vm.applyCropped = applyCropped;

  vm.img = img;
  vm.croppedImage = '';
  vm.type = type;
  vm.objectId = objectId;

  function applyCropped() {

    if (vm.saveInProgress) return;

    vm.saveInProgress = true;

    if (vm.type == 'campaign') {
      campaignService.uploadCmpImage(entityId, vm.croppedImage)
      .then(response => {

        $rootScope.$emit('file.approved', response);
        vm.saveInProgress = false;
        closeModal()
      })
      .finally(() => {
      vm.saveInProgress = false;
      })
    } else if (vm.type == 'national-team') {
      $rootScope.$emit('file.cropped', {
        img: vm.croppedImage,
        id: vm.objectId
      });
      closeModal();
    }
  }

  function closeModal() {
    $mdDialog.hide();
  }
}

