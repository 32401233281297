angular
  .module('app')
  .component('modalAddProcessor', {
    bindings: {
      show: '=',
      admins: '=',
      roles: '=',
      onProcessorAdded: '='
    },
    controller: ModalAddProcessorCtrl,
    controllerAs: 'vm'
  });


function ModalAddProcessorCtrl($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-add-processor.tpl.html',
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          onProcessorAdded: vm.onProcessorAdded
        },
        clickOutsideToClose: true
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($scope, $timeout, $q, $mdDialog, $location, $mdConstant, entityService,
                          campaignService, orgProcessorsService, messageService, onProcessorAdded) {
  const vm = this;
  vm.processors = [];
  vm.dataLoaded = false;
  vm.selectedProcessor = undefined;
  vm.onProcessorAdded = onProcessorAdded;

  vm.selectProcessor = function(processor) {
    vm.selectedProcessor = processor;
  };

  vm.isSelected = function(processor) {
    return vm.selectedProcessor === processor;
  };

  loadProcessors();

  function loadProcessors (){
    orgProcessorsService.getAvailableProcessors()
      .then(processors => {
        vm.dataLoaded = true;
        vm.processors = processors;
      })
      .catch(() => {
        vm.error = "Unable to load processors. Please contact support"
      });
  };

  vm.addInProgress = false;
  vm.closeModal = closeModal;
  vm.addNewProcessor = addNewProcessor;

    function closeModal() {
        $mdDialog.hide();
    }

    function addNewProcessor() {
        vm.addInProgress = true;
        var data = {
            processorInstanceId: 0,
            processorId: vm.selectedProcessor.ProcessorId
        }
        orgProcessorsService
        .addInstance(data)
        .then(data => {
            vm.onProcessorAdded();
            closeModal();
        }).finally(() =>  vm.addInProgress = false);
    }
}
