angular
  .module('app')
  .directive('topNavMenu', topNavMenu);

function topNavMenu() {
  return {
    restrict: 'A',
    link($scope, $element) {
      $element.superfish({
        cssArrows: false,
        delay: 0,
        speed: 'fast'
      });
    }
  }
}