angular
  .module('app')
  .filter('activityFilter', ActivityFilter);

function ActivityFilter() {

    return function(daysLogs, filterParams) {

        let filteredActivities = [],
          {amountMin, amountMax, types} = filterParams;

          daysLogs.forEach(item => {

              let result = {};
              result.amount = true;

              if (types.size) {
                result.type = types.has(item.type);
              } else {
                result.type = false;//if no types -> hide all except ended
              }

              if (item.type == 'Transactions' || item.type == "Recurring Transactions") {
                  if (amountMin > item.transactionAmount || amountMax < item.transactionAmount) {
                      result.amount = false;
                  }
              }

              if (result.type && result.amount) {
                filteredActivities.push(item)
              }

        });

        return filteredActivities;
  }
}