angular.module('app').factory('securityService', function($http, $q, API_PREFIX) {
  let permissionType = {
    VIEW: 1,
    EDIT: 2,
    DELETE: 4
  };

  let navPermissions = {
    activity: ['CAMPAIGN'],
    statistics: ['CAMPAIGN'],
    transactions: ['DONATION', 'DONATION_RECURRING', 'TRANSACTION_REGISTRATIONS', 'TRANSACTIONS_TICKETS', 'DONOR_CAMPAIGNS'],
    people: ['PEOPLE_NATIONAL_TEAMS', 'PEOPLE_CONTACTS', 'PEOPLE_DONORS', 'PEOPLE_REGISTRANTS', 'PEOPLE_TEAMS'],
    email: ['EMAIL_SEND_EMAILS', 'EMAIL_SENT_EMAIL_BLAST', 'EMAIL_TEMPLATES', 'EMAIL_SENT_EMAIL_SYSTEM', 'EMAIL_PENDING_EMAILS',
           'FUNDRAISER_EMAIL_TEMPLATES', 'AUTO_RESPONDERS','PDF_RECEIPT_TEMPLATE', 'EMAIL_ADVANCED_OPTIONS'],
    forms: ['DONATION_SETTINGS', 'PROCESSING_FEES', 'POST_DONATION_OPTIONS', 'MATCH_SETTINGS', 'PAY_PAL', 'REGISTRATION_SETTINGS', 'REGISTRATION_FIELDS',
            'TICKETS', 'DISCOUNT_CODES', 'FORMS', 'FORMS_CUSTOMIZE', 'SETTINGS_ADVANCED'],
    website: ['CONTENT_FAQ', 'CONTENT_SPONSER', 'FEED', 'IMAGES', 'SETTINGS_CMP_DICTIONARY', 'SETTINGS_SOCIAL_SHARES', 'WEBSITE_ADVANCED'],
    fundraising: ['SETTINGS_HQ_TASKS', 'FUNDRAISING_OPTIONS', 'FUNDRAISER_TASK_LIST', 'FUNDRAISER_TASK_LIST_OPTIONS','FUNDRAISER_EMAIL_TEMPLATES',
                'FUNDRAISING_CUSTOMIZE', 'FUNDRAISING_ADVANCED', 'ENDURANCE_CHALLENGES', 'LIVESTREAMS'],
    reports: ['REPORTS'],
    system: ['SETTINGS_DONATION_PROCESSOR', 'SYSTEM_NOTIFICATIONS', 'SETTINGS_INTEGRATION_OPTIONS']
  };

  return {
    getAdminPermissions, navPermissions
  };

  // function hasPermission(resourcePermissions, resource, permission) {
  //   if(!resourcePermissions) {
  //     return false;
  //   }
  //   return checkPermission(resourcePermissions[resource], permission)
  // }

  function checkPermission(resourcePermissions, permission) {
    return (resourcePermissions & permissionType[permission]) == permissionType[permission];
  }

  function getPermissions(resourcePermissions, resource) {
    if(resourcePermissions) {
      var permissionsForResource = resourcePermissions[resource];
	  if(!permissionsForResource){
		  return {};
	  }
      return {
        VIEW: checkPermission(permissionsForResource, 'VIEW'),
        EDIT: checkPermission(permissionsForResource, 'EDIT'),
        DELETE: checkPermission(permissionsForResource, 'DELETE')
      }
    }
    return {};
  }

  function getAdminPermissions(entity, resource) {
	  if(!entity){
		  return {};
	  }
    return getPermissions(entity.permissions, resource);
  }  

});