angular
  .module('app')
  .component('objectField', {
      bindings: {
          id: '=',
          entityId: '=',
          objectEventId: '=',
          objectActivityInfo: '<',
          eventName: '=',
          name: '<',
          value: '=',
          initialValue: '=',
          displayedValue: '=',
          isReadonly: '<',
          isAutocomplete: '<',
          recurring: '=',
          isEditing: '=',
          setEditing: '&',
          index: '<',
          saved: '=',
		  saveFunction: '=',
		  fieldType: '=',
          objectIds: '=',
          moveDonations: '=',
          moveRecurringDonations: '='
      },
      transclude: true,
      controller: ObjectFieldController,
      templateUrl: 'object-field.tpl.html',
      controllerAs: 'vm'
  });

function ObjectFieldController($rootScope, $http, API_PREFIX,extJsLoader) {
    const vm = this;
	vm.id;
    vm.name;
    vm.isAutocomplete;
    vm.recurring;
    vm.isReadonly;
    vm.save = save;
    vm.cancel = cancel;
    vm.edit = edit;
    vm.orgValue;
    vm.editFlags;
    vm.isSaveActivated = false;
	vm.saveFunction;
	vm.fieldType;
    vm.moveDonations;
    vm.objectIds;
    vm.showLoader=false;

    vm.$onInit = function () {
        vm.isEditing = false;
        vm.saved = false;
        vm.displayedValue = { value: vm.value, eventName: vm.eventName };
        vm.initialValue = { value: vm.value, eventName: vm.eventName };
		vm.inputId = 'value-bar-' + vm.id + '-' + vm.name;
    }
    

    function save() {
        if (!vm.isSaveActivated) {
            return;
        }
        vm.showLoader=true;
		if(vm.saveFunction){
			var newVal = $('#' + vm.inputId).val();
			vm.saveFunction(vm.entityId, vm.id, vm.name, newVal)
			.then(function successCallback(response) {
					vm.value = newVal;
					vm.displayedValue.value = newVal;
					postSaveSuccess(response);
				},
				function errorCallback(response) {
                    hideLoader();
                }
			);
		} else if(vm.moveDonations){
            $http({
				url: `/PlatformDonation/DonationsChangedRecipient`,
				headers: { fromEntityId: vm.entityId, toEntityId: vm.value.entityID },
				data: { donationIds: vm.objectIds, toGoalId: vm.value.goalID },
				method: 'POST'                              
			}).then(function successCallback(response) {
					postSaveSuccess();
				}, function errorCallback(response) {
                    hideLoader();
				}
			);
        }
        else if(vm.moveRecurringDonations){
            $http({
				url: `/PlatformCmp/RecurringDonationsChangedRecipient`,
				headers: { entityId: vm.entityId, transferRecurringToEntityId: vm.value.entityID },
				data: { toGoalId: vm.value.goalID, recurringDonationIds: vm.objectIds },
				method: 'POST'                              
			}).then(function successCallback(response) {
					postSaveSuccess();
                    extJsLoader.extJsController.refreshInnerPage();
				}, function errorCallback(response) {
                    hideLoader();
				}
			);
        }
        else{
			$http({
				url: `/PlatformDonation/DonationChangedRecipient`,
				headers: { fromEntityId: vm.entityId, toEntityId: vm.value.entityID },
				data: { donationId: vm.id, toGoalId: vm.value.goalID },
				method: 'POST'                              
			}).then(function successCallback(response) {
					postSaveSuccess(response);
					$rootScope.$broadcast('updateActivityStream', { activityInfo: vm.objectActivityInfo, newId: response.data.newId, newEntityId: vm.value.entityID });
				}, function errorCallback(response) {
                    hideLoader();
				}
			);
		}
    }

    function hideLoader() {
        vm.showLoader=false;
    }
	
	function postSaveSuccess(response){
		vm.initialValue = angular.copy(vm.displayedValue);
		vm.setEditing({ index: vm.index, set: 'off' });
		vm.saved = true;
        vm.showLoader=false;
	}

    function cancel() {
        vm.isSaveActivated = false;
        vm.displayedValue = angular.copy(vm.initialValue);
        vm.setEditing({index: vm.index, set: 'off' });
    }

    function edit() {
        vm.setEditing({ index: vm.index});
		if(!vm.isAutocomplete){
			vm.isSaveActivated = true;
		}
    }
}