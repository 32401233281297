angular
    .module('app')
    .service('endurancePledgeService', endurancePledgeService);

function endurancePledgeService(requestHelperService, formService) {
    const vm = this;
    const rh = requestHelperService.getInstance('EndurancePledge');
    
    const endurancePledgeState = {
        1: 'Active',
        2: 'Suspended',
        3: 'Ended',
        4: 'Deleted',
        5: 'Charge Failed',
        6: 'Unfulfilled'
    };
    
     return {
        endurancePledgeState,
        getNewPledgeForm: () => rh.sendEntityRequest('AddForm'),
        addPledge: item => rh.sendCampaignRequest('Add', { item: formService.getBaseFormItem(item) }, rh.successToastMessage('Endurance Pledge has been added.') ),
        savePledge: item => rh.sendCampaignRequest('Edit', { item: formService.getBaseFormItem(item) } ),
        deletePledge: item => rh.sendCampaignRequest('Delete', { item: formService.getBaseFormItem(item) } ),
        endPledge: item => rh.sendCampaignRequest('End', { item: formService.getBaseFormItem(item) } ),
        chargePledge: item => rh.sendCampaignRequest('Charge', { item: formService.getBaseFormItem(item) }),
        getExistingUsers: () => rh.sendExtJsRequest('Member/AllMembersSimple', { limit: 10, page: 0, start: 0, noPaging: true }),
    }
}