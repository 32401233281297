angular
    .module('app')
    .filter('objectEmpty', ObjectEmptyFilter);

angular
    .module('app')
    .filter('objectNotEmpty', ObjectNotEmptyFilter);

function ObjectEmptyFilter() {
    return function (o) {
        return isObjectEmpty(o);
    }
}

function ObjectNotEmptyFilter() {
    return function (o) {
        return !isObjectEmpty(o);
    }
}

function isObjectEmpty(o) {
    return o && Object.keys(o).length === 0;
}