angular.module('app').factory('payPalService', payPalService);

function payPalService($http, API_PREFIX, entityService, messageService) {
    const messageConfig = {
        advancedErrorFunction: messageService.advancedErrorsDialog,
        advancedErrorEnabled: true
    };

    return {
        loadPayPal,
        getPayPalInfo,
        createPayPalReferral,
        deactivatePayPal,
        reconnectPayPal
    };

    function loadPayPal() {
        var callback = function() {
            PAYPAL.apps.Signup.loadScripts(document, "scripts");
            PAYPAL.apps.Signup.MiniBrowser.init();
        }
        if (!window.PAYPAL) {
            rbLoadScript('https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js', callback);
        } else {
            callback();
        }
    }

    function getPayPalInfo() {
        return $http({ 
            url: `${API_PREFIX}Cmp/GetPayPalInfo`,
            headers: { entityId: entityService.getOpenedEntityId() },
            method: 'POST',
          }).then(response => response.data);
    }

    function createPayPalReferral() {       
        return $http({ 
            url: `${API_PREFIX}Cmp/CreatePayPalReferralLink`,
            headers: { entityId: entityService.getOpenedEntityId() },
			data: { returnUrl: location.href },
            method: 'POST',
            messageConfig
          }).then(response => response.data
          ).catch(response => response)
    }
    
    function deactivatePayPal() {
        var mConfig = messageConfig;
        mConfig.successToastEnabled = true;
        return $http({ 
            url: `${API_PREFIX}Cmp/DisconnectPayPal`,
            headers: { entityId: entityService.getOpenedEntityId() },
            method: 'POST',
            messageConfig
          }).then(response => response.data);
    }

    function reconnectPayPal() {
        var mConfig = messageConfig;
        mConfig.successToastEnabled = true;
        return $http({ 
            url: `${API_PREFIX}Cmp/AdoptParentEntityPayPal`,
            headers: { entityId: entityService.getOpenedEntityId() },
            method: 'POST',
            messageConfig
          }).then(response => response.data);
    }
}