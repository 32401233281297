angular.module('app').component('enduranceChallenges', {
  controllerAs: 'vm',
  controller: enduranceChallengesController,
  templateUrl: 'endurance-challenges.tpl.html',
  require: {
    parentComponent: '^fundraising'
  }
});


function enduranceChallengesController($scope, enduranceChallengeService, securityService, extJsLoader, entityService, $q) {
  const vm = this;

  this.$routerOnActivate = function(next, prev) {
    this.parentComponent.loading = false;
    this.parentComponent.showIframe = false;
  };

  vm.CHALLENGES_LOCK_KEY = 'CHALLENGES';

  var entity = entityService.getOpenEntity();
  vm.entityType = entity.entityType;
  vm.entityId = entity.entityId;
  vm.loading = true;
  enduranceChallengeService.getEnduranceForm()
    .then(refreshChallenges)
    .finally(function () {
      vm.loading = false;
    });

  $scope.$on('refreshChallenges', function (event, response) {
    refreshChallenges(response);
  });

  function refreshChallenges(response) {
    if (response.success && response.challenges) {
      vm.availableChallenges = response.challenges;
      vm.challengesConfig = response.configItem;
      vm.defaultChallengeId = response.defaultChallengeId;
      vm.defaultActivityTypes = vm.defaultActivityTypes || response.defaultActivityTypes;
      vm.unitOptions = vm.unitOptions || response.unitOptions;
      vm.integrationProviders = vm.integrationProviders || response.integrationProviders;
      vm.durationFormats = vm.durationFormats || response.durationFormats;
      vm.distanceFormats = vm.distanceFormats || response.distanceFormats;
    }
    return response;
  }

    vm.permission = securityService.getAdminPermissions(entity, 'ENDURANCE_CHALLENGES');


  vm.selectedIndex = undefined;
  vm.hideShowChallengeItem = function (index) {
    if (vm.selectedIndex !== index) {
      vm.selectedIndex = index;
    }
  }
  
  vm.showFormAction = function() {
    vm.showForm = true;
  }

  $scope.$on('adoptParent', function (event, key) {
    if (key == vm.CHALLENGES_LOCK_KEY) {
      vm.sectionUpdating = true;
      enduranceChallengeService
        .adoptParent()
        .then(refreshChallenges)
        .finally(() => {
          vm.sectionUpdating = false;
        });
    }
  });

  $scope.$on('overrideParent', function (event, key) {
    if (key == vm.CHALLENGES_LOCK_KEY) {
      vm.sectionUpdating = true;
      enduranceChallengeService
        .overrideParent()
        .then(refreshChallenges)
        .finally(() => {
          vm.sectionUpdating = false;
        });
    }
  });

  $scope.$on('clearOverrides', function (event, key) {
    if (key == vm.CHALLENGES_LOCK_KEY) {
      vm.sectionUpdating = true;
      enduranceChallengeService
        .clearOverrides()
        .then(refreshChallenges)
        .finally(() => {
          vm.sectionUpdating = false;
        });
    }
  });
}
