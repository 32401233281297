angular.module('app').component('dateTimeItem', {
  bindings: {
    dateTime: '=',
    label: '<',
    configId: '<',
    onChange: '<',
    disabled: '<',
    sectionUpdating: '<',
    parentName: '<',
    type: '<',
    inherited: '<',
    isDefault: '<',
    tooltip: '<',
    entityType: '<',
    noLock: '<',
  },
  controllerAs: 'vm',
  controller: dateTimeItemController,
  templateUrl: 'date-time-item.tpl.html'
});

function dateTimeItemController(mdcDateTimeDialog) {
  var vm = this;
  vm.originalValue = vm.dateTime;

  vm.hover = false;
  vm.hoverIn = function() {
    vm.hover = true;
  }
  vm.hoverOut = function() {
    vm.hover = false;
  };

  vm.disableInput = function() {
    return vm.sectionUpdating || vm.disabled || vm.inherited ||  (vm.isDefault && vm.entityType != 'Org');
  }
  vm.getDisplayText = function(displayFormat) {
    if (!vm.dateTime) {
      return 'Add Date';
    }
    return new moment(vm.dateTime).format(displayFormat);
  }

  vm.updateDate = function(date) {
    vm.dateTime = new moment(date).format('YYYY-MM-DDTHH:mm:ss');
    doUpdate();
  };
  
  vm.clearDate = function() {
    vm.dateTime = null;
    doUpdate();
  };

  function doUpdate() {
    vm.onChange(vm.configId, vm.dateTime).then(function(success) {
      if (success) {
        vm.originalValue = vm.dateTime;
      } else {
        vm.dateTime = vm.originalValue;
      }
    });
  }


  vm.displayDateDialog = function() {
    mdcDateTimeDialog
      .show({
        time: false,
        date: true,
        currentDate: vm.dateTime,
        disableParentScroll: true,
        timeZoneHeader: true
      })
      .then(
        vm.updateDate,
        function() {}
      );
  };

  vm.displayTimeDialog = function() {
    mdcDateTimeDialog
      .show({
        time: true,
        date: false,
        shortTime: true,
        currentDate: vm.dateTime,
        disableParentScroll: true,
        timeZoneHeader: true
      })
      .then(
        vm.updateDate,
        function() {}
      );
  };
}