angular.module('app').component('pageDefaults', {
  controllerAs: 'vm',
  controller: pageDefaultsController,
  template: ``,
  bindings: { 
    $router: '<'
  },
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateRoute($nextInstruction);
  },
  require: {
    parentComponent: '^fundraising',
  },
});

function pageDefaultsController(extJsLoader) {
  this.$routerOnActivate = function(next, prev) {
    extJsLoader.extJsController.updateInnerPage('Content-PersonalPageDefaults');
    this.parentComponent.showIframe = true;
  }
}