angular.module('app').component('advancedSettings', {
  controllerAs: 'vm',
  controller: advancedSettingsController,
  require: {
    parentComponent: '^formsTab'
  },
  bindings: { 
    $router: '<'
  },
  template: `<form-template
               form-sections='vm.sections'
               on-update='vm.onChange'
               loading='vm.loading'></form-template>`,
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateNoPendingChanges($nextInstruction);
  }
});

function advancedSettingsController(configSettingsService, extJsLoader) {
  const vm = this;
  this.$routerOnActivate = function (next, prev) {
    this.parentComponent.showIframe = false;
    this.parentComponent.loading = false;
  }
  vm.loading = true;
  vm.sections = [];
  configSettingsService.getFormsAdvancedSettings().then(function(r) {
    vm.loading = false;
    if (r.success && r.form) {
      vm.sections = r.form.FormSections;
    } else {
    }
  });

  vm.onChange = function(config) {
    return configSettingsService.updateSetting(config);
  };
}