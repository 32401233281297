angular
  .module('app')
  .component('fundraiserPdf', {
    bindings: { 
      $router: '<'
    },
    template:
      '<form-template' +
        ' form-sections=\'vm.sections\'' +
        ' on-update=\'vm.onChange\'' +
        ' loading=\'vm.loading\'></form-template>' +
      '<div class="custom-setting-item">' +
      '  <fundraiser-pdf-template />' +
      '</div>' +
      '<form-template' +
      '  form-sections=\'vm.advancedSections\'' +
      '  on-update=\'vm.onChange\'' +
      '  loading=\'vm.advancedLoading\'></form-template>',
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});