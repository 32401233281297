angular.module('app').component('formTemplate', {
  templateUrl: 'form-template.tpl.html',
  controllerAs: 'vm',
  controller: formTemplateController,
  bindings: {
    formSections: '<',
    onUpdate: '<',
    loading: '<',
    filter: '<',
    noLock: '<'
  }
});

function formTemplateController(entityService) {
  const vm = this;
  vm.entityType = entityService.getOpenEntity().entityType;
}
