angular
  .module('app')
  .factory('profileService', profileService);


function profileService($q, $http, API_PREFIX, rbTransformRequests) {
  let profileInfo = null;
  let userNeonApps = null;
  const agGridSettingPrefix = 'agGridSetting-';

  return { getProfileInfo, getNotificationsSettings, saveNotifications, setProfileInfoCampView,
           updateFilters, updateSort, saveEmail, saveProfileImage, resetPassword, setNameForUser, getAgGridSetting,
	  		updateAgGridSetting, getUserNeonApps};

  function getProfileInfo() {
    if (profileInfo) {
      return $q.resolve(profileInfo);
    }

	return $http({
      url: API_PREFIX + 'Profile/GetMyInfo',
      method: 'POST'
    })
	.then(response => {
		profileInfo = response.data.data;
		if(!profileInfo.userSettings){
		  profileInfo.userSettings = {};  
		}
		if(!profileInfo.userSettings.campaignView){
		  profileInfo.userSettings.campaignView = 'listView';
		}
		if(!profileInfo.userSettings.adminView){
		  profileInfo.userSettings.adminView = 'tileView';
		}
		if(!profileInfo.userFilterSettings){
		  profileInfo.userFilterSettings = {};
		}
		if(!profileInfo.userFilterSettings.filterSettings){
		  profileInfo.userFilterSettings.filterSettings = {
			FundraisingPlatforms: true, // 1, //    1  
			DonationPages: true, // 2, //    2  
			TicketingPages: true, // 4, //    3 
			FundraisingCampaigns: true, // 8, //    4
			PublishedCampaigns: true, // 16, //    5 
			DelistedCampaigns: true, // 32, //    6
			EndedCampaigns: false, // 64,  //    7
			MemorialCampaigns: true // 128, //    8
		  };
		}
		if(!profileInfo.userSettings.sortSettings){
		  profileInfo.userSettings.sortSettings = 'Recent-0';
		}
		return profileInfo;
	});
  }
  
  function getUserNeonApps() {
	  if (userNeonApps) {
		  return $q.resolve(userNeonApps);
	  }

	  return $http({
		  url: API_PREFIX + 'Profile/GetMyNeonApps',
		  method: 'POST'
	  })
		  .then(response => {
			  userNeonApps = response.data.data ? response.data.data.applications : [];
			  return userNeonApps
		  });
		  
  }

  function setProfileInfoCampView(view) {

    profileInfo.userSettings.campaignView = view;

	return $http({
      url: API_PREFIX + 'Profile/UpdateUserSettings',
      data: { list : { campaignView: view } },
      method: 'POST'
    });

  }
  
  function updateSort(sorts) {

    profileInfo.userSettings.sortSettings = sorts;

	return $http({
      url: API_PREFIX + 'Profile/UpdateUserSettings',
      data: { list : { sortSettings: sorts }},
      method: 'POST'
    });
	
  }

  function updateFilters(filters) {

    profileInfo.userFilterSettings.filterSettings = filters;

	return $http({
      url: API_PREFIX + 'Profile/UpdateUserFilterSettings',
      data: { list : filters },
      method: 'POST'
    });
  }

  function getNotificationsSettings() {

	return $http({
      url: API_PREFIX + 'Profile/GetNotifySettings',
      method: 'POST'
    })
	.then((response) => {
        const columnsCount = response.data.columnsCount;

        return response.data.data.map(notificationOrg => {
			
			return {
				orgName: notificationOrg.orgName,
				campaigns: notificationOrg.campaigns.map(entry => {

				  // Checkboxes values
				  // 0 - checked, 1 - unchecked
				  let flags = _getBitmask(entry.flags, columnsCount);

				  // Checkboxes states
				  let permissionFlags = _getBitmask(entry.permissionFlags, columnsCount);
				  

				  return {
					orgId: entry.orgId,
					entityId: entry.entityId,
					campaignName: entry.campaignName,
					flags,
					permissionFlags
				  };
				  
				})
			};
        });
	});
  }

   function getAgGridSetting(key) {
	let fullKey = agGridSettingPrefix + key;
		return getProfileInfo()
		.then( (profileInfo) => {
			return profileInfo.userSettings[fullKey];
		});
	}

  function updateAgGridSetting(key, setting) {
	  let fullKey = agGridSettingPrefix + key;
	  	profileInfo.userSettings[fullKey] = JSON.stringify(setting);
		$http({
			url: `${API_PREFIX}Profile/UpdateUserSettings`,
			data:{
					list:{
						[fullKey]: JSON.stringify(setting)
					}
				},
			method: 'POST',
		});
  	}
  
  function saveEmail(email, messageConfig){
	return $http({ url: API_PREFIX + 'Profile/ChangeMyEmail', data: { newEmail: email }, method: 'POST' , messageConfig});
  }

   function resetPassword(oldPass, newPass, secondPass, messageConfig){
	return $http({ url: API_PREFIX + 'Profile/ResetMyPassword', data: { oldPass: oldPass, newPass: newPass, secondPass: secondPass }, headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, method: 'POST', transformRequest: rbTransformRequests.transformForm, messageConfig });
  }

   function setNameForUser(name, messageConfig){
	return $http({ url: API_PREFIX + 'Profile/SetMyName', data: { fullName: name }, method: 'POST', messageConfig });
   }

   function saveProfileImage(img, messageConfig) {
       return $http({ url: API_PREFIX + 'Profile/ChangeMyProfileImage', data: { file: img }, headers: { 'Content-Type': undefined }, method: 'POST', transformRequest: rbTransformRequests.transformFormData, messageConfig });
   }

  function saveNotifications(notificationsList) {
	var settings = [];
    notificationsList.forEach(notificationOrg => {	
		notificationOrg.campaigns.forEach(notification => {
		  let flags = notification.flags.slice();

		  flags.reverse();

		  settings.push({
			entityId: notification.entityId,
			orgId: notification.orgId,
			flags: parseInt( // boolean array => bitmask => decimal value
			  flags.map(flag => flag ? '1' : '0').join(''), 2)
		  });
		});
    });
	
	return $http({ url: API_PREFIX + 'Profile/UpdateNotifySettings', data: { settings }, method: 'POST' });
  }


  function _getBitmask(number, size) {
    let mask = [];

    let binaryNumber = number.toString(2).split('');

    for (let i = 0; i < size; i++) {
      mask[i] = binaryNumber[i - (size - binaryNumber.length)] == 1;
    }

    return mask.reverse();
  }

}