angular
  .module('app')
  .factory('nationalTeamsService', nationalTeamsService);

function nationalTeamsService($q, $http, API_PREFIX, entityService, configSettingsService, messageService) {
    let nationalTeams = null;    
    const NT_CONFIG_ID = 506;

    const messageConfig = {
        advancedErrorFunction: messageService.advancedErrorsDialog,
        advancedErrorEnabled: true
	};
    
    return { getEnabledNationalTeams,
        getEntityNationalTeams,
        getNonEnabledNationalTeams,
        addNationalTeamManager,
        removeNationalTeamManager,
        createNationalTeam,
        removeNationalTeam,
        editNationalTeam,
        addNationalTeam,
        NT_CONFIG_ID
     }

    function getNationalTeams(entityId) {
        if (nationalTeams) {
            return $q.resolve(nationalTeams)
        }
        
        return $http({
            url: API_PREFIX + 'Cmp/GetOrgNationalTeams',
            method: 'POST',
            headers: { entityId },
        }).then(response => {
            nationalTeams = response.data.nationalTeams;
            return nationalTeams;
        })
    }

    function getNonEnabledNationalTeams(ids) {
        return getNationalTeams(entityService.getOpenedEntityId()).then(
            r => { 
                return r.filter(x => !ids.includes(x.TeamId));
            }
        )
    }

    function getEntityNationalTeams(ids, setting) {
        return getNationalTeams(entityService.getOpenedEntityId()).then(
            r => { 
                return {
                    nationalTeams: r.filter(x => ids.includes(x.TeamId)),
                    setting: setting
                } 
            }
        )
    }

    function getEnabledNationalTeams() {
        return configSettingsService.getNationalTeamSettings().then(res => {
            //for now we only care for the ids setting. we may have to loop through more settings of more configs are exposed
            if (!res.form.FormSections.length) {
                return getEntityNationalTeams([], { Disabled: true, });
            }
            let setting = res.form.FormSections.find(x => x.Label = "National Teams").Fields.find(x => x.ConfigOptionID == NT_CONFIG_ID);
            let ids = setting.IntValues;
            return getEntityNationalTeams(ids, setting);
        });
    }

	function createNationalTeam(data) {
		return processDataOnServer(`${API_PREFIX}Cmp/CreateNationalTeam`, data, true)
		.then(response => {
            nationalTeams.push(response.data.nationalTeam);
            nationalTeams = sortNationalTeamsByName();
            return response.data.nationalTeam;
        });
	}

	function removeNationalTeam(nationalTeamId) {
        var data = {
            nationalTeamId: nationalTeamId
        }
        return processDataOnServer(`${API_PREFIX}Cmp/RemoveNationalTeam`, data)
            .then(response => response.data);
    }

    function addNationalTeamManager(nationalTeamId, email) {
        var data = {
            nationalTeamId: nationalTeamId,
            email: email
        }
        return processDataOnServer(`${API_PREFIX}Cmp/AddNationalTeamManager`, data, true)
            .then(response => {
                updateCachedNationalTeam(nationalTeamId, response.data.nationalTeam);
                return response.data
            });
    }

    function removeNationalTeamManager(nationalTeamId, userAccountId) {
        var data = {
            nationalTeamId: nationalTeamId,
            userAccountId: userAccountId
        }
        return processDataOnServer(`${API_PREFIX}Cmp/RemoveNationalTeamManager`, data)
            .then(response => {
                updateCachedNationalTeam(nationalTeamId, response.data.nationalTeam);
                return response.data
            });
    }

	function editNationalTeam(nationalTeamId, column, val) {
		var data = {
			nationalTeamId: nationalTeamId,
			val: val,
			column: column
		}

		return processDataOnServer(`${API_PREFIX}Cmp/UpdateNationalTeam`, data, true)
			.then(response => {
                updateCachedNationalTeam(nationalTeamId, response.data.nationalTeam);
                return response;
            });
    }
    
    function addNationalTeam(nationalTeamId) {
        var data = { nationalTeamId: nationalTeamId }
        return processDataOnServer(`${API_PREFIX}Cmp/AddNationalTeam`, data)
        .then(response => response.data);
    }
    
    function updateCachedNationalTeam(nationalTeamId, nationalTeam) {
        nationalTeams = nationalTeams.filter(t => t.TeamId != nationalTeamId);
        nationalTeams.push(nationalTeam);
        nationalTeams = sortNationalTeamsByName();
    }

    function processDataOnServer(url, data, handleErrorsLocally) {
		let entityId = entityService.getOpenedEntityId();
        return $http({
			url: url,
			data: data,
			headers: { entityId },
			method: 'POST',
			messageConfig: handleErrorsLocally ? { errorToastEnabled: false } : messageConfig,
		}).then(response => response);
    }
    
    function sortNationalTeamsByName() {
        return nationalTeams.sort((a, b) => { 
            var nameOfa = a.TeamName.toLowerCase();
            var nameOfb = b.TeamName.toLowerCase();

            if (nameOfa < nameOfb)
                return -1;
            else if (nameOfa > nameOfb)
                return 1;
            return 0;
         });
    }
}
