angular
    .module('app')
    .component('liquidTemplate', {
        templateUrl: 'liquid-template.tpl.html',
        controller: liquidTemplateController,
        controllerAs: 'vm',
		require: {
			parentComponent: '^settings'
		}
    });

function liquidTemplateController(entityContentService, securityService, routingService, entityService) {
    const vm = this;
	
	vm.show = false;
	vm.save = save;
	vm.showModal = showModal;
	
	var entity = entityService.getRawEntityById(entityService.getOpenedEntityId());
	
	vm.security = securityService.getAdminPermissions(entity, 'SETTINGS_EDIT_CONTENT_ON_SITE');

	this.$routerOnActivate = function (next, prev) {
		this.parentComponent.showIframe = false;
	}

    function loadLiquidTemplates(){
		vm.liquidTemplateLoading = true;
		vm.liquidTemplates = [];
		vm.activeTemplatePageId = -1;

		vm.editRow = function(row){
			if (!vm.saving)
				vm.activeTemplatePageId = row.id;
		};

		vm.saveRow = function(row) {
			vm.saving = true;

			entityContentService
				.updateLiquidTemplatePage(vm.entityId, row.id, row.name, row.content)
				.finally(() => { vm.saving = false });
		}	

		entityContentService
            .getLiquidTemplate(vm.entityId)
            .then(liquidTemplatePages => { vm.liquidTemplates = liquidTemplatePages; })
			.catch(() => { vm.liquidTemplates = []; })
			.finally(() => { vm.liquidTemplateLoading = false; });
	}	

    routingService
		.updateRouting()
		.then(r => {
			if (!r) {
            	vm.groupOrCmpNotFound = true;
        	} else {
            	vm.entityId = r;
            	let entity = entityService.getRawEntityById(r);
            	if (entity) {
                	vm.onGroup = entity.entityType === 'CampaignGroup';
                	loadLiquidTemplates();
            	}
        	}
    	});
		
	
	function showModal() {
		vm.show = true;
	}
	
	function save(pageName, pageType, pageExt) {
		return entityContentService.createNewLiquidTemplatePage(
			vm.entityId, vm.liquidTemplates[0].entityTemplateId, pageName, pageType, pageExt
		).then(loadLiquidTemplates);
	}
}