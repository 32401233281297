angular
  .module('app')
  .component('integrationCampaigns', {
    templateUrl: 'integration-campaigns.tpl.html',
    bindings: {
      $router: '<',
      type: '=',
      typeId: '=',
      deleting: '='
    },
    controller: IntegrationCampaignsController,
    controllerAs: 'vm'
  });

function IntegrationCampaignsController( orgIntegrationsService, campaignService, securityService, orgService, messageService ) {
  const vm = this;
  vm.filteredItems = [];
  
  const paginationSize = 20;
  
  vm.scrollCount = paginationSize;
  vm.loadMore = function() {
    if (vm.campaigns.length > vm.filteredItems.length) {
      vm.scrollCount += paginationSize;
    }
  };
  vm.search = {};

  vm.getSecurity = (entity, resource) => securityService.getAdminPermissions(entity, resource);

  vm.selectCampaign = function(campaign) {
    if (!vm.deleting) {
      window.open(orgService.getCampaignPath(campaign), '_blank');
    }
  };

  vm.deleteIntegration = function() {
    vm.deleting = true;
    return orgIntegrationsService.deleteIntegration(vm.type, vm.typeId)
      .then(() => {
        vm.$router.navigate(['Integrations'])
      })
      .catch((e) => {
        messageService.showErrorToast(e)
      });
  };

  vm.$onInit = function() {
    orgIntegrationsService.getIntegrationCampaigns(vm.type, vm.typeId)
      .then(campaigns => {
        vm.canDelete = campaigns.length === 0 
          && orgIntegrationsService.canDelete(vm.type)
          && vm.getSecurity(orgService.getOrg(), 'SETTINGS_INTEGRATION_OPTIONS').DELETE;
        vm.campaigns = campaignService.getRawCampaigns().filter(c => campaigns.indexOf(c.curEventId) >= 0);
        vm.hiddenCampaigns = campaigns.length != vm.campaigns.length;
      });
  }
}
