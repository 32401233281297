angular
  .module('app')
  .component('modalPassword', {
    bindings: {
      show: '=',
      password: '='
    },
    controller: ModalPasswordCtrl,
    controllerAs: 'vm'
  });

function ModalPasswordCtrl($scope, $mdDialog) {
  const vm = this;
  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-change-password.tpl.html',
        clickOutsideToClose: true,
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          password: vm.password
        }
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($mdDialog, profileService, password) {
  const vm = this;
  vm.password = password;
  vm.cancel = closeModal;
  vm.savePassword = savePassword;
  
  function closeModal() {
    $mdDialog.hide();
  }

  function savePassword() {
    vm.saveInProgress = true;

    let messageConfig = {
        successToastEnabled: true,
        successMsg: 'Password changed successfully.'
    };
    profileService.resetPassword(vm.currentPassword, vm.newPassword, vm.passwordConfirmation, messageConfig)
    .then((response) => {
          closeModal();
      })
    .finally(() => vm.saveInProgress = false);
    }
  }
