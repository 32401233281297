angular.module('app').component('lockManager', {
  controller: lockManagerController,
  controllerAs: 'vm',
  templateUrl: 'lock-manager.tpl.html',
  bindings: {
    inherited: '<',
    isDefault: '<',
    parentName: '<',
    setting: '<',
    updating: '<',
    hideOptions: '<',
    disabled: '<',
    confirmAdoption: '<',
    entityType: '<',
    isTerm: '<',
    configOptionId: '<',
    dynamicOption: '<',
  }
});

function lockManagerController($mdDialog, $scope) {
  var vm = this;
  
  vm.icon = function() {   
    let isOverriding = !vm.inherited && !vm.isDefault && !!vm.parentName;
    if ((vm.inherited || vm.isDefault) && vm.entityType !== 'Org') {
      return 'locked';
    } else if (vm.entityType !== 'Org' && (!vm.dynamicOption || isOverriding)) {
      return 'overriding';
    } else {
      return 'unlocked';
    }
  }
  vm.confirmAndDeleteChildOverrides = function(event, setting) {
    var confirm = $mdDialog
      .confirm()
      .title('Are you sure?')
      .textContent(`This will clear overrides on all groups and campaigns within this entity.`)
      .targetEvent(event)
      .ok('Clear overrides')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(
        function() {
          if (typeof setting === 'object') {
            setting.configOptionId = vm.configOptionId;
          }
          clearOverrides(setting);
        },
        function() {
          return;
        }
      );
  }

  function clearOverrides(setting) {
    $scope.$emit('clearOverrides', setting);
  }
}
