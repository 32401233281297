angular
    .module('app')
    .component('editableList', {
        controller: EditableList,
        template: `<div class='editable-list list' ng-transclude></div>`,
        transclude: true,
        bindings: {
            form: '<',
            security: '<',
            saveEdit: '<',
            onKeyUp: '<',
            editMode: '<',
            showErrorMessages: '<',
            serverErrors: '=',
        },
        controllerAs: 'vm'
    });

function EditableList($scope) {
    const vm = this;
    
    vm.saveInProgress = false;
    vm.prevSaveValue = undefined;
    vm.fieldBeingEdited = undefined;
    vm.fieldModelBeingEdited = undefined;
    vm.currentFieldValue = undefined;

    $scope.$on('modalClosed', function(event, saveBeforeClosing, closeModal) {
        tryExitOrSaveCurrentField(event, saveBeforeClosing, closeModal)
    });

    $scope.$on('tabSwitched', function(event, saveBeforeSwitching, switchTab) {
        tryExitOrSaveCurrentField(event, saveBeforeSwitching, switchTab)
    });
    
    function tryExitOrSaveCurrentField(event, trySave, onSaveComplete) {
        if (vm.editMode && vm.fieldBeingEdited) {

            var $ele = getElForField(vm.fieldBeingEdited);
            var valueChanged = $ele.val() !== vm.currentFieldValue;

            if (trySave && valueChanged) {

                event.preventDefault();
                $ele.focus();
                vm.saveField(vm.fieldBeingEdited, function (saveComplete) {
                    if (saveComplete) {
                        onSaveComplete()
                    } else {
                        highlightErrorField($ele);
                    }
                });

            } else {
                vm.exitEditMode();
            }
        }
    }

    vm.$onInit = function() {
        vm.canEdit = vm.editMode && vm.security.EDIT;
    }

    vm.editField = function(field) {
        if(!vm.editMode) {
            getElForField(field).focus();
        }

        if(vm.fieldBeingEdited !== field) {

            if(vm.fieldBeingEdited) {
                vm.saveField(vm.fieldBeingEdited, (saveComplete) => {
                    if(saveComplete) {
                        startEditingField(field);
                    } else {
                        var $ele = getElForField(vm.fieldBeingEdited);
                        highlightErrorField($ele);
                    }
                });
            } else {
                startEditingField(field);
            }
        }
    }

    function startEditingField(field) {
        vm.fieldBeingEdited = field;
        vm.currentFieldValue = getElForField(field).val();
    }

    vm.saveField = function(field, callback) {
        if(!vm.editMode) {
            return;
        }
        
        var $ele = getElForField(field);
        var val = $ele.val();
        vm.prevSaveValue = val;

        // Stop saving if there are any local errors
        if($ele.hasClass('ng-invalid')) {
            callback && callback(false);
            return;
        }
        
        if(val === vm.currentFieldValue) {
            // No need to save if nothing changed
            doneSaving(field, callback);
        } else {
            saveToServer(field, val, callback)
        }
    }
    
    function saveToServer(field, val, callback) {
        vm.saveInProgress = true;

        vm.saveEdit(field, val, (response) => {
            if (response.data.success) {
                doneSaving(field, callback);
            } else {
                errorSaving(field, response, callback);
            }
        });
    }

    function doneSaving(field, callback) {
        vm.serverErrors[field.toLowerCase()] = undefined;
        vm.saveInProgress = false;
        vm.exitEditMode();
        callback && callback(true);
    }

    function errorSaving(field, response, callback) {
        var errorMessage = "There was an error saving, please try again";
        if(response.data && response.data.errors && response.data.errors[0]) {
            errorMessage = response.data.errors[0].error;
        }
        vm.serverErrors[field.toLowerCase()] = errorMessage
        vm.saveInProgress = false;

        callback && callback(false);
    }
    
    function highlightErrorField($ele) {
        $ele.focus();
        $ele.closest('.list-item').effect('bounce', { direction: 'left', distance: '5', 'times': 3 }, 400);
    }

    vm.cancelEdit = function() {
        if(vm.fieldBeingEdited) {
            var $ele = getElForField(vm.fieldBeingEdited);
            $ele.val(vm.currentFieldValue).trigger('input');
            $ele.closest('md-dialog').focus();
        }
        
        vm.exitEditMode();
    }

    vm.exitEditMode = function() {

        if(vm.fieldBeingEdited) {
            vm.serverErrors[vm.fieldBeingEdited.toLowerCase()] = undefined;
        }
        
        vm.fieldBeingEdited = undefined;
        vm.currentFieldValue = undefined
        vm.prevSaveValue = undefined;
    }

    vm.onFieldKeyUp = function($event, field, form) {
        vm.onKeyUp($event, field, form);

        if($event.target.value != vm.prevSaveValue) {
            vm.serverErrors[field.toLowerCase()] = undefined;
        }
    }
    
    vm.onFieldKeyDown = function(event, field, form) {
        vm.checkHotKey(event, field, form);
    }

    const ESC = 27,
        ENTER = 13,
        TAB = 9;

    vm.checkHotKey = function(event, field, form) {
        let key = event.keyCode;
        
        if(vm.editMode && vm.fieldBeingEdited) {
            
            if (key === ESC) {
                event.preventDefault();
                event.stopPropagation();
                vm.cancelEdit();
            }

            if (key === ENTER) {
                event.preventDefault();
                event.stopPropagation();
                vm.saveField(field);
            }
        }
        
        if (key === TAB) {

            let $ele = getElForField(field);

            if ($ele) {
                let listItem = $ele.closest('editable-list-item');
                let siblingEl, field;

                if (event.shiftKey) {
                    siblingEl = listItem.prev();
                } else {
                    siblingEl = listItem.next();
                }

                if(siblingEl.length) {
                    field = siblingEl.find('md-input-container input').data('field');
                }

                if(field) {
                    event.preventDefault();
                    vm.editField(field);
                }
            }
        }
    }

    function getElForField(field) {
        return angular.element(`[data-field='${field}']`)
    }
}