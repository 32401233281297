angular
  .module('app')
  .component('sentAutoEmail', {
    controller: sentAutoEmailController,
    bindings: { 
      $router: '<'
    },
    require: {
      parentComponent: '^email'
    },
    template: `<div ag-grid="vm.grid" class="ag-theme-platform grid"></div>`,
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function sentAutoEmailController($scope, $compile, agGridService, emailService, entityService, $http, $mdDialog) {
    const vm = this;

    var columnDefs = [
        {
            field: 'DisplayName',
            headerName: 'Template Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'ProcessedDate',
            headerName: 'Sent',
            filter: 'agDateColumnFilter',
            valueFormatter: agGridService.formatters.dateTime,
            filterParams: {
                filterOptions: ['equals', 'greaterThanOrEqual', 'lessThanOrEqual'],
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'FirstName',
            headerName: 'First Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'LastName',
            headerName: 'Last Name',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'ToEmail',
            headerName: 'Email',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'ProcessedMessage',
            headerName: 'Processed Message',
            valueFormatter: agGridService.formatters.emailProcessedMessage,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [100,200,201,300,301],
                valueFormatter: agGridService.formatters.emailProcessedMessage,
                suppressAndOrCondition: true,
            },
        },
    ];

    vm.getEntity = function() {
        return vm.parentComponent.openEntity;
    }
    
    vm.loadRow = function(params) {
        emailService.getSystemEmailStats(params.data.ProcessedEmailID)
            .then(r => vm.grid.openRow.data.stats = r.emailStats)
            .finally(r => vm.grid.openRow.data.statsLoaded = true)
    }

    vm.grid = agGridService.createNewGrid(columnDefs, {
        domLayout: 'autoHeight',
        sideBar: false,
        key: 'sentAutoEmail',
        expandedCardTemplate: 'sentAutoEmailRecord',
        noRowsMessage: 'Sent emails will show up here.',
        datasource: agGridService.getDatasource('Email', 'SentSystemEmails'),
        defaultColDef: {
            flex: 1,
            minWidth: 160,
        },
    }, {
        onRowGroupOpened: vm.loadRow,
    });
    
    vm.resendEmail = function(record) {

        var locals = {};
        var templateMessage = 'The email will be re-sent exactly as it was when originally sent.'
        
        if (record.IsUsingNewTemplate && record.CanUseUpdatedTemplate) {
            locals.secondary = {
                text: 'Resend With Latest Template',
                action: () => {
                    resendEmailRequest(true);
                },
            };
            templateMessage = 'You can choose to resend the email exactly how it was when originally sent, or to use the latest template.';
        }
        
        var confirm = $mdDialog
            .confirm({
                bindToController: true,
                locals: locals,
            })
            .title('Resend Email')
            .htmlContent(`Would you like to resend this email to ${record.ToEmail}?<br><b>${templateMessage}</b>`)
            .targetEvent(event)
            .ok('Resend Original')
            .cancel('Cancel');

        $mdDialog.show(confirm).then(() => {
            resendEmailRequest(false);
        }, angular.noop);
        
        function resendEmailRequest(useUpdatedEmail) {
            emailService.resendEmail(record.ProcessedEmailID, useUpdatedEmail)
        }
    }
}