angular
  .module('app')
  .directive('mdDialogHeader', mdDialogHeader);

function mdDialogHeader() {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      title: '@',
      onClose: '&',
      externalLink: '@',
      actions: '=',
    },
    template: `
            <md-toolbar class="md-default">
                <div class="md-toolbar-tools">
                    <span class="popup__title">{{title || ''}}</span>
                    <div ng-transclude></div>
                    <span flex></span>
                    <custom-button ng-repeat="action in actions" button="action"></custom-button>
                    <md-button class="no-horizontal-margins md-icon-button" ng-if="externalLink" target="_blank" href="{{externalLink}}">
                        <md-icon>
                            <i class="zmdi zmdi-arrow-right-top"></i>
                        </md-icon>
                    </md-button>
                    <custom-button button="{class: 'md-icon-button', action: onClose, useLoader: true, skipComplete: true }">
                        <md-icon>
                            <i class="zmdi zmdi-close"></i>
                        </md-icon>
                    </custom-button>
                </div>
            </md-toolbar>
        `
  };
}