angular
  .module('app')
  .component('tickets', {
    templateUrl: 'tickets.tpl.html',
    controller: ticketsController,
    bindings: { 
      $router: '<'
    },
    require: {
      parentComponent: '^transactions'
    },
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function ticketsController(extJsLoader, iframeMessageHandler) {
  const vm = this;
  this.$routerOnActivate = function(next, prev) {
    extJsLoader.extJsController.updateInnerPage('Transactions-Transtickets');
    this.parentComponent.showIframe = true;
  }
}