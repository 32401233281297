angular.module('app').component('modalSalesforceLogs', {
    bindings: {
        show: '=',
        sfId: '='
    },
    controller: ModalSalesforceLogsCtrl,
    controllerAs: 'vm'
});

function ModalSalesforceLogsCtrl($scope, $mdDialog) {
    const vm = this;

    $scope.$watch('vm.show', () => {
        if (vm.show) {
            showModal();
        }
    });

    function showModal() {
        $mdDialog
            .show({
                templateUrl: 'modal-salesforce-logs.tpl.html',
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    sfId: vm.sfId,
                }
            })
            .finally(() => {
                vm.show = false;
            });
    }
}

function ModalContentCtrl($scope, $mdDialog, messageService, salesforceService, profileService, campaignService, agGridService, sfId) {
    const vm = this;
    vm.closeModal = closeModal;
    vm.reloadSalesforceLogs = reloadSalesforceLogs;
    vm.resyncWithSalesforce = resyncWithSalesforce;
    vm.resyncWithCrm = resyncWithSalesforce;
    vm.syncing = true;
    vm.sfId = sfId;

    vm.logOptions = {
        from: moment()
          .subtract(7, 'day')
          .startOf('day')
          .toDate(),
        to: moment()
          .startOf('day')
          .toDate(),
        maxDate: new Date() // today is the latest logs
    };

    vm.formatDate = (date, format) => moment(date).format(format);

    var columnDefs = [
        {
            colId: 'SalesforceQueueId',
            field: 'SalesforceQueueId',
            headerName: 'Queue ID',
            filter: false, 
            enableRowGroup: false,
        },
        {
            colId: 'SyncType',
            field: 'SyncType',
            headerName: 'Queue Type',
            sortable: false,
        },
        {
            colId: 'Status',
            field: 'Status',
            sortable: false,
            cellRenderer: agGridService.templates.status,
            valueFormatter: agGridService.formatters.capitalizeFirstLetter,
            tooltipValueGetter: agGridService.formatters.capitalizeFirstLetter
        },
        {
            colId: 'InitiatedOn',
            field: 'InitiatedOn',
            headerName: 'Initiated On',
            filter: false,
            valueFormatter: agGridService.formatters.dateTime,
        },
        {
            colId: 'CompletedOn',
            field: 'CompletedOn',
            headerName: 'Completed On',
            filter: false,
            hide: true,
            valueFormatter: agGridService.formatters.dateTime,
        },
        {
            colId: 'EventId',
            field: 'EventId',
            headerName: 'Campaign ID',
            filter: false,
            sortable: false,
        },
        {
            colId: 'UserId',
            field: 'UserId',
            headerName: 'User ID',
            sortable: false,
            cellRenderer: agGridService.templates.userId,
            enableRowGroup: false,
        },
        {
            colId: 'GoalId',
            field: 'GoalId',
            headerName: 'Goal ID',
            sortable: false,
            enableRowGroup: false,
        },
        {
            colId: 'DonationId',
            field: 'DonationId',
            headerName: 'Donation ID',
            sortable: false,
            cellRenderer: agGridService.templates.donationId,
            enableRowGroup: false,
        },
        {
            colId: 'MemberRoleId',
            field: 'MemberRoleId',
            headerName: 'Member Role ID',
            sortable: false,
            enableRowGroup: false,
        },
        {
            colId: 'RegistrationId',
            field: 'RegistrationId',
            headerName: 'Registration ID',
            sortable: false,
            enableRowGroup: false,
        },
        {
            colId: 'RbCreated',
            field: 'RbCreated',
            headerName: 'Rb Created',
            filter: false,
            sortable: false,
            hide: true,
            cellRenderer: agGridService.templates.booleanImage,
        },
        {
            colId: 'SalesforceId',
            field: 'SalesforceId',
            headerName: 'Salesforce ID',
            sortable: false,
            hide: true,
            cellRenderer: agGridService.templates.salesforce,
            enableRowGroup: false,
        },
        {
            colId: 'SalesforceId2',
            field: 'SalesforceId2',
            headerName: 'Salesforce ID2',
            sortable: false,
            hide: true,
            cellRenderer: agGridService.templates.salesforce,
            enableRowGroup: false,
        },
        {
            colId: 'SalesforceId3',
            field: 'SalesforceId3',
            headerName: 'Salesforce ID3',
            sortable: false,
            hide: true,
            cellRenderer: agGridService.templates.salesforce,
            enableRowGroup: false,
        },
        {
            colId: 'TeamId',
            field: 'TeamId',
            headerName: 'Team ID',
            hide: true,
            sortable: false,
            enableRowGroup: false,
        },
        {
            colId: 'Error',
            field: 'Error',
            filter: false,
            sortable: false,
        },
        {
            colId: 'IsResync',
            field: 'IsResync',
            headerName: 'Is Resync',
            filter: false,
            sortable: false,
            hide: true,
            cellRenderer: agGridService.templates.booleanImage,
            enableRowGroup: false,
        },
        {
            colId: 'LastRetriedOn',
            field: 'LastRetriedOn',
            headerName: 'Last Retried On',
            filter: false,
            hide: true,
            valueFormatter: agGridService.formatters.dateTime,
        },
        {
            colId: 'Resync',
            headerName: 'Resync',
            cellRenderer: agGridService.templates.resync,
            filter: false,
            sortable: false,
            enableRowGroup: false,
            lockVisible: true,
        },
        {
            colId: 'Latest',
            field: 'Latest',
            headerName: 'Previous Sync Records',
            filter: agGridService.latestSyncsOnlyFilter(),
            hide: true,
            suppressColumnsToolPanel: true,
        }
    ];

    vm.gridOptions = agGridService.createNewGrid(columnDefs, {key:'modal-salesforce-logs'}, { onGridReady: loadSalesforceLogs } );

    profileService.getProfileInfo().then(profile => (vm.gridOptions.csvFilename = `admin_${profile.userName.split(' ')[0]}_${moment().format('YYYYMMDD-HHmm')}.csv`));

    function loadSalesforceLogs() {
        vm.syncing = true;
        agGridService.runGridFilteringForLatest(vm);
        return salesforceService
            .getSalesforceLogs(vm.sfId, vm.logOptions.from, vm.logOptions.to)
            .then(salesforceLogs => {
                salesforceLogs.forEach(f => {
                    var campaign = campaignService.getCampaignByEventId(f.EventId);
                    if (campaign) {
                        f.campaignUrl = campaign.campaignUrl;
                    }
                });
                vm.gridOptions.api.setRowData(salesforceLogs);
                vm.syncing = false;
            })
            .catch(() => (vm.syncing = false));
    }
    
    function reloadSalesforceLogs() {
        if (vm.syncing === false) {
            return loadSalesforceLogs();
        }
    }

    function resyncWithSalesforce(record) {
        salesforceService
            .reSync(record)
            .then(response => {
                if (response.msgShown) return;
                if (response.success) {
                    messageService.showMsgDialog('Done', 'We added the record to be synced.');
                    loadSalesforceLogs();
                } else {
                    messageService.showErrorDialog('Oops', 'Sorry, an error occurred. <br/><b>' + response.msg + '</b>');
                }
            })
            .catch(response => {
                if (response.msgShown) return;
                messageService.showErrorDialog('Oops', 'Sorry, an error occurred. <br/><b>' + response.msg + '</b>');
            });
    }

    function closeModal() {
        $mdDialog.cancel();
    }
}