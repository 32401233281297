angular
    .module('app')
    .factory('messageService', messageService);

function messageService($mdToast, $mdDialog, $q) {

    let defaultConfig = {
        errorToastEnabled: true,
        successToastEnabled: false,
        requestInProgressToastEnabled: false,
        advancedErrorEnabled: true,
        fallbackErrorMsg: 'An unexpected error has occurred, please try again.',
        overrideErrorMsg: undefined,
        accessDeniedMsg: 'You do not have sufficient permission to access this resource.',
        successMsg: 'Action completed successfully.',
        requestInProgressMsg: 'Request in progress.',
        advancedErrorFunction: undefined,
        successActionFunction: undefined,
        successActionTitle: undefined,
        suppressErrorHandling: false,
    };

    let pendingRequests = {};

    function checkSetPendingRequest(url, data) {
        if(pendingRequests[requestIdentifier(url, data)]) return true;

        pendingRequests[requestIdentifier(url, data)] = true;
        return false;
    }

    function resetPendingRequest(url, data) {
        delete pendingRequests[requestIdentifier(url, data)];
    }
    
    function requestIdentifier(url, data) {
        return JSON.stringify([url,data]);
    }

    function showErrorToast(msg, parentElm, hideDelay) {
        return $mdToast.show(
            $mdToast.simple()
                .textContent(msg || defaultConfig.fallbackErrorMsg)
                .position('top right')
                .action('✖')
                .hideDelay(hideDelay)
                .parent(parentElm)
                .toastClass('toast-error')
        ).then((response) => {
            if(response == 'ok') return $mdToast.hide();
        }, angular.noop);
    }

    function showSuccessToast(msg, actionTitle, actionFunction){
        return $mdToast.show(
            $mdToast.simple()
                .textContent(msg || defaultConfig.successMsg)
                .action(actionTitle)
                .position('top right')
                .hideDelay(3000)
                .toastClass('toast-success')
        ).then((response) => {
            if(response == 'ok' && actionFunction) return actionFunction();
        }, angular.noop);
    }

    function showMsgDialog(title, htmlContent) {
        return $mdDialog.show(
            $mdDialog.alert({
                title: title,
                htmlContent: htmlContent,
                ok: 'Close',
                // skipHide: true
                //http://stackoverflow.com/questions/30724239/mddialog-stacked-nested-dialogs-is-it-possible
                multiple: true
            })
        )
    }

    function showAccessDeniedDialog(msg) {
        if (angular.element(document).find('md-dialog').length > 0) {
            var dialogs = angular.element(document).find('md-dialog');
            for (var i = 0; i < dialogs.length; i++) {
                if (dialogs[i].innerText.startsWith('Access Denied')) {
                    return;
                }
            }
        }
        return showMsgDialog('Access Denied', msg || defaultConfig.accessDeniedMsg);
    }

    function showErrorDialog(htmlContent) {
        return showMsgDialog('Error', htmlContent);
    }

    function showErrorDialogWithFirstError(currentMessageService, response) {
        let errorStr = 'An error occured';
        if (response && response.data && response.data.errors && response.data.errors[0]) {
            errorStr = response.data.errors[0].error;
        }
        return showErrorDialog(errorStr);
    }

    function showErrorDialogFromErrorsObj(errors, introMsg) {
        let errorStr = (introMsg ||'The following errors occurred:') + '<br>';
        angular.forEach(errors, error => {
            errorStr += ('<br><b>'+ error.ID + ': </b>' + error.error);
        });
        return showErrorDialog(errorStr);
    }

    function advancedErrorsDialog(currentMessageService, response) {
        return showErrorDialogFromErrorsObj(response.data.errors);
    }

    messageService.showErrorToast = showErrorToast;
    messageService.showSuccessToast = showSuccessToast;
    messageService.showAccessDeniedDialog = showAccessDeniedDialog;
    messageService.checkSetPendingRequest = checkSetPendingRequest;
    messageService.resetPendingRequest = resetPendingRequest;
    messageService.advancedErrorsDialog = advancedErrorsDialog;
    messageService.showErrorDialogFromErrorsObj = showErrorDialogFromErrorsObj;
    messageService.showErrorDialogWithFirstError = showErrorDialogWithFirstError;
    messageService.showErrorDialog = showErrorDialog;
    messageService.showMsgDialog = showMsgDialog;

    function messageService(config){
        //TODO: Return new if not used with new keyword
        config = angular.extend({}, defaultConfig, config);
        this.config = config;
        this.showErrorToast = (msg) => {if(config.errorToastEnabled) showErrorToast(config.overrideErrorMsg || msg || config.fallbackErrorMsg)};
        this.showSuccessToast = (msg) => {if(config.successToastEnabled) showSuccessToast(msg || config.successMsg, config.successActionTitle, config.successActionFunction )};
        this.showAccessDeniedDialog = (msg) => showAccessDeniedDialog(config.accessDeniedMsg || msg);
        this.showRequestInProgressToast = (msg) => {if(config.requestInProgressToastEnabled) showErrorToast(msg || config.requestInProgressMsg)};
        this.showAdvancedError = (response) => {if(config.advancedErrorEnabled) {config.advancedErrorFunction ? config.advancedErrorFunction(this, response) : this.showErrorToast()} };
        this.checkSetPendingRequest = checkSetPendingRequest;
        this.resetPendingRequest = resetPendingRequest;
    }


    return messageService;

}
