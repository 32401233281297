angular
  .module('app')
  .component('campaignsView', {
    templateUrl: 'campaigns-view.tpl.html',
    controller: CampaignsViewController,
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateNoPendingChanges($nextInstruction);
    },
  })

function CampaignsViewController($rootScope, $location, $window, $mdDialog, $sce,
                            campaignService, orgService, profileService, entityService, messageService, securityService,
                            $scope, $timeout) {
  const vm = this;

  vm.selectGroup = selectGroup;
  vm.selectCampaign = selectCampaign;
  vm.updateViewSetting = updateViewSetting;
  vm.highlight = highlight;
  vm.unHighlight = unHighlight;
  vm.trueSelectedItems = trueSelectedItems;
  vm.isSelected = isSelected;
  vm.adminPermissionsOnSelected = false;

  vm.inviveAdminModal = inviveAdminModal;
  vm.moveItems = moveItems;

  vm.toggleCheck = toggleCheck;
  vm.toggleSort = toggleSort;

  vm.campaignPath = orgService.getUrlCampaignPath();
  vm.campaignHost = orgService.getUrlHost();
  if (vm.campaignPath) {
    vm.campaignId = campaignService.getCampaignByUrl(vm.campaignHost + '/' + vm.campaignPath).campaignId;
  } else {
    vm.campaignId = orgService.getUrlCampaignId();
  }
  
  vm.loadingNewData = false;
  vm.groupEmpty = false;
  vm.groupOrCmpNotFound = false;
  
  vm.selectedItems = [];
  vm.lastClicked = null;
  vm.selectedType = null;
  vm.shiftFrom = -1;
  vm.shiftTo = -1;

  vm.selecetedItems = null;
  vm.updateSelected = updateSelected;
  vm.focusInput = focusInput;
  
  vm.checkedFiltersCount = 0;
  vm.allFiltersChecked = true;
  vm.turnOffFilters = turnOffFilters;
  
  vm.searchFilterUpdate = searchFilterUpdate;

  vm.sort = {};

  vm.filterParams = {
    search: undefined,
    types: new Set(),
    delisted: new Set(),
    ended: false
  };

  $scope.allowDrop = allowDrop;
  $scope.onLeaveFile = onLeaveFile;

  vm.fileUploadInput = null;

  function onLeaveFile(event){
     event.stopPropagation();
     document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
  }

  function allowDrop(event){
    document.querySelector('.fileInput-placeholder').style.zIndex = '1';
  }

  profileService.getProfileInfo()
    .then(info => {
      $rootScope.viewMode = info.userSettings.campaignView;

      vm.filterSettings = info.userFilterSettings.filterSettings;


      Object.keys(vm.filterSettings).forEach(key => {

        if (vm.filterSettings[key] == true) {
          
          vm.checkedFiltersCount++

          let keySpaces = key.replace(/([A-Z])/g, ' $1').replace(/\s/, '');

          switch(keySpaces) {
            case 'Fundraising Platforms':
              vm.filterParams.types.add('Platform');
              break
            case 'Donation Pages':
              vm.filterParams.types.add('Donation');
			  vm.filterParams.types.add('Global Donation Page');
              break
            case 'Ticketing Pages':
              vm.filterParams.types.add('Ticketing');	
              vm.filterParams.types.add('Standalone Ticketing');
              break
            case 'Fundraising Campaigns':
              vm.filterParams.types.add('Peer-to-peer');
              vm.filterParams.types.add('Fuse');
              vm.filterParams.types.add('Champion Campaign');			  
              break
			case 'Memorial Campaigns':
              vm.filterParams.types.add('Memorial'); 
              break
            case 'Personal Campaigns':
              vm.filterParams.types.add('Personal Fundraising'); 
              break
            case 'Published Campaigns':
              vm.filterParams.delisted.add(false)
              break
            case 'Delisted Campaigns':
              vm.filterParams.delisted.add(true)
              break
            case 'Ended Campaigns':
              vm.filterParams.ended = true
			  break
          }

        }else {
            vm.allFiltersChecked = false;
        }

      });


      let {sortSettings} = info.userSettings;

          vm.rule = sortSettings.slice(0, -2).replace(/([A-Z])/g, ' $1').replace(/\s/, '');
          vm.reverse = sortSettings.endsWith('1');

      switch(vm.rule) {
        case 'Recent':
          vm.sort.name = 'campaignId'
          break
        case 'Campaign Type':
          vm.sort.name = 'type'
          break
        case 'Amount Raised':
          vm.sort.name = 'amountRaised'
          break
        case 'Event Date':
          vm.sort.name = 'eventDate'
          break
        default:
          vm.sort.name = 'name'
      }

      vm.sort.reverse = vm.reverse;

  });

  campaignService.checkUpdateRouting()
      .then(setCampaignAndGroup)
      .catch(setNotFound);

  function setCampaignAndGroup(campaignAndGroup) {
      if (campaignAndGroup.campaign) {
          vm.campaign = campaignAndGroup.campaign;
          $rootScope.$emit('onCampaignPage');
      }

      if(campaignAndGroup.group) {
          var groupId = campaignAndGroup.group.groupId;
      }

      openGroupSetChildren(groupId);
  }

  function openGroupSetChildren(groupId) {
    campaignService.openGroup(groupId)
      .then((campaignsData) => {
            vm.campaigns = addAdminPanelUrl(campaignsData.campaigns);
            vm.groups = campaignsData.groups;

            if (!vm.campaigns.length && !vm.groups.length) {
                vm.groupEmpty = true;
            }

            $rootScope.$emit('group.opened');
            $rootScope.$emit('entity.changedInView')
      })
  }

  function setNotFound() {
      $rootScope.$emit('groupOrCampaignNotFound');
      vm.groupOrCmpNotFound = true;
  }

  $rootScope.$on('orgs.loaded', (event, data) => {
    vm.loadingNewData = false;

    vm.campaigns = addAdminPanelUrl(data.campaigns);
    vm.groups = data.groups;

    $rootScope.$emit('group.opened');
  });


  $rootScope.$on('tree.changed', (event, data) => {
    vm.loadingNewData = false;
    vm.campaigns = addAdminPanelUrl(data.campaigns);
    vm.groups = data.groups;
  });

  $rootScope.$on('tree.clear', () => {
    vm.loadingNewData = true;
    vm.campaigns = vm.groups = [];
  });

  $rootScope.$on('move.close', () => {
    vm.actionOnSelected = false;
    //vm.unHighlight();
    vm.moveTooltip = false
  });

  function selectOrg() {
    orgService.updatePathToOrg();
  }

  function selectGroup(group) {
    orgService.updatePathToGroup(group);
  }

  function selectCampaign(campaign) {
	orgService.updatePathToCampaign(campaign);
  }

  function highlight(item, event, longTouch) {

    let h = 'highlight',
        {ctrlKey, shiftKey, metaKey} = event,
        isCampaign = 'campaignId' in item,
        row = event.target.closest('.md-table-row') || event.target.closest('.md-card'),
        rows = [];

        if (row.closest('.md-table')) {
          rows = row.closest('.md-table').querySelectorAll('.md-table-row:not(.table-head)');
        } else if(row.closest('.grid-cards')) {
          rows = row.closest('.grid-cards').querySelectorAll('.md-card');
        }

    let rowsArr = Array.from(rows),
        index = rowsArr.indexOf(row);

    ctrlKey = ctrlKey || metaKey;

    if (!(ctrlKey || shiftKey) && !longTouch) {

        rows.forEach(item => item.classList.remove(h));
        vm.selectedItems = [];

        row.classList.add(h);
        vm.selectedItems[index] = row;

        vm.lastClicked = index;

    } else {

        if (ctrlKey || longTouch) {

        if (row.classList.contains(h)) {
          row.classList.remove(h);
          delete vm.selectedItems[index];
          vm.lastClicked = null;
        } else {
          row.classList.add(h);
          vm.selectedItems[index] = row;
          vm.lastClicked = index;
        }
      }

      if (shiftKey) {

        if (vm.lastClicked == null) {
          row.classList.add(h);
          vm.selectedItems[index] = row;
          vm.lastClicked = index;
        }

        let direction = 'none';

        if (index > vm.lastClicked) direction = 'down'
        else if (index < vm.lastClicked) direction = 'up';

        if (direction == 'none') { // if init shift click
          row.classList.remove(h);
          delete vm.selectedItems[index];
          vm.lastClicked = null;
        } else {

          rows.forEach((thisRow, thisIndex) => {


            if (direction == 'up') {

              if ( thisIndex <= vm.shiftFrom && thisIndex < index && thisIndex >= vm.shiftTo) {

                thisRow.classList.remove(h);
                delete vm.selectedItems[thisIndex];

              }

              if ( thisIndex >= index && thisIndex <= vm.lastClicked ) {
                thisRow.classList.add(h);
                vm.selectedItems[thisIndex] = thisRow;
              }

            }

            if (direction == 'down') {

              if ( thisIndex >= vm.shiftFrom && thisIndex > index && thisIndex <= vm.shiftTo ) {

                thisRow.classList.remove(h);
                delete vm.selectedItems[thisIndex];

              }

              if ( thisIndex <= index && thisIndex >= vm.lastClicked ) {
                thisRow.classList.add(h);
                vm.selectedItems[thisIndex] = thisRow;
              }

            }

          });

          vm.shiftFrom = vm.lastClicked;
          vm.shiftTo = index;

        }

      }

    }

    vm.selectedItems.isCampaign = isCampaign;
    var items = trueSelectedItems();
    var hasPermission = false;
    for (var i = 0; i < items.length; i++) {
        var hasPermission = items[i].permissions.ADMIN_USERS & 4;
        if (!hasPermission) {
            hasPermission = false;
            break;
        }
    }
     vm.adminPermissionsOnSelected = hasPermission;


  }

  function updateSelected() {
    vm.selecetedItems = trueSelectedItems();
  }

  function trueSelectedItems() {
    let items = [];

    vm.selectedItems.forEach( item => {

      let thisId = item.id.match(/\d+/g)[0];

      let source = null;

      if (vm.selectedItems.isCampaign) source = vm.campaigns.find(campaign => campaign.campaignId == thisId);
      else source = vm.groups.find(group => group.groupId == thisId);

      items.push(source);

    });

    items.isCampaign = vm.selectedItems.isCampaign;

    return items

  }

  function updateViewSetting(view) {
    unHighlight();
    $rootScope.viewMode = view;
    profileService.setProfileInfoCampView(view);
  }

  function unHighlight(target) {

    if (vm.actionOnSelected) return;

    let rows = document.querySelectorAll(`.table-${target} .md-table-row.highlight`),
        cards = document.querySelectorAll(`.grid-${target} .md-card.highlight`),
        items = null;

    if (target === undefined) {
      /*rows = document.querySelectorAll(`.md-table-row.highlight`);
      cards = document.querySelectorAll(`.md-card.highlight`);
      vm.selectedItems = [];*/
      // TODO think about it
    }

    if (rows.length) {
      items = Array.from(rows);
    }

    if (cards.length) {
      items = Array.from(cards);
    }

    if (items) {

      vm.moveTooltip = false;

      items.forEach(item => item.classList.remove('highlight'));

      if ( target == 'groups' && !vm.selectedItems.isCampaign
        || target == 'campaigns' && vm.selectedItems.isCampaign) { //when switch between groups and campaigns
        vm.selectedItems = []
      }

    }

  }

  function isSelected() {
    return vm.selectedItems.some(x => x !== undefined)
  }

  function turnOffFilters(){
      $scope.$broadcast('beforeFilterOrSortUpdated');
      vm.allFiltersChecked = true;
      vm.checkedFiltersCount = Object.keys(vm.filterSettings).length;
      Object.keys(vm.filterSettings).forEach((s) => {
          vm.filterSettings[s] = true;
      })
      vm.searchInput = undefined;
      vm.filterParams.search = undefined;
      vm.filterParams.types.add('Platform');
      vm.filterParams.types.add('Donation');
	    vm.filterParams.types.add('Global Donation Page');
      vm.filterParams.types.add('Ticketing');	
      vm.filterParams.types.add('Standalone Ticketing');
      vm.filterParams.types.add('Peer-to-peer');
      vm.filterParams.types.add('Fuse');
      vm.filterParams.types.add('Personal Fundraising');
      vm.filterParams.types.add('Champion Campaign');  
      vm.filterParams.types.add('Memorial'); 
      vm.filterParams.delisted.add(false)
      vm.filterParams.delisted.add(true)
      vm.filterParams.ended = true;
  }

  function toggleCheck(event) {
    $scope.$broadcast('beforeFilterOrSortUpdated');

    let item = event.target.closest('li'),
        itemProp = item.querySelector('.label').innerText,
        checked = item.classList.toggle('checked'),
        key = itemProp.replace(/\s+/g,'');

    switch(itemProp) {
      case 'Fundraising Platforms':
        if (checked) vm.filterParams.types.add('Platform')
        else vm.filterParams.types.delete('Platform')
        break
      case 'Donation Pages':
        if (checked){
			vm.filterParams.types.add('Donation');
			vm.filterParams.types.add('Global Donation Page');
        } else {
			vm.filterParams.types.delete('Donation');
			vm.filterParams.types.delete('Global Donation Page');
		}
        break
      case 'Ticketing Pages':
        if (checked){
			vm.filterParams.types.add('Ticketing');
			vm.filterParams.types.add('Standalone Ticketing');
		} else {
			vm.filterParams.types.delete('Ticketing');
			vm.filterParams.types.delete('Standalone Ticketing');
		}
        break
      case 'Fundraising Campaigns':
        if (checked){
			vm.filterParams.types.add('Peer-to-peer');
			vm.filterParams.types.add('Fuse');
			vm.filterParams.types.add('Champion Campaign');
        } else {
			vm.filterParams.types.delete('Peer-to-peer');
			vm.filterParams.types.delete('Fuse');
      vm.filterParams.types.delete('Champion Campaign');

		}
        break
      case 'Memorial Campaigns':
        if (checked){
			vm.filterParams.types.add('Memorial');
        } else {
			vm.filterParams.types.delete('Memorial');
		}
        break
      case 'Personal Campaigns':
        if (checked){
          vm.filterParams.types.add('Personal Fundraising');
        } else {
          vm.filterParams.types.delete('Personal Fundraising');
        }
        break		
      case 'Published Campaigns':
        if (checked) vm.filterParams.delisted.add(false)
        else vm.filterParams.delisted.delete(false)
        break
      case 'Delisted Campaigns':
        if (checked) vm.filterParams.delisted.add(true)
        else vm.filterParams.delisted.delete(true)
        break
      case 'Ended Campaigns':
        if (checked) vm.filterParams.ended = true
        else vm.filterParams.ended = false
		break
    }

    vm.filterSettings[key] = checked;

    if (checked) {
        vm.checkedFiltersCount++; 
        vm.allFiltersChecked = (vm.checkedFiltersCount == Object.keys(vm.filterSettings).length);
    } else {
        vm.allFiltersChecked = false;
        vm.checkedFiltersCount--;
    }

    profileService.updateFilters(vm.filterSettings);

  }

  function toggleSort(event) {
    $scope.$broadcast('beforeFilterOrSortUpdated');

    let item = event.target.closest('li'),
        itemProp = item.querySelector('.label').innerText;

    switch(itemProp) {
      case 'Recent':
        vm.sort.name = 'campaignId'
        break
      case 'Campaign Type':
        vm.sort.name = 'type'
        break;
      case 'Amount Raised':
        vm.sort.name = 'amountRaised'
        break
      case 'Event Date':
        vm.sort.name = 'eventDate'
        break
      default:
        vm.sort.name = 'name'
    }
    
    vm.rule = itemProp;

    let items = item.closest('.checks-list').querySelectorAll('.checks-list-item');

    if (item.classList.contains('sort-asc')) {

      items.forEach(item => item.classList.remove('sort-asc', 'sort-desc'));
      item.classList.add('sort-desc');
      vm.sort.reverse = vm.reverse = true;

    } else if (item.classList.contains('sort-desc')) {

      items.forEach(item => item.classList.remove('sort-asc', 'sort-desc'));
      item.classList.add('sort-asc');
      vm.sort.reverse = vm.reverse = false;

    } else {

      items.forEach(item => item.classList.remove('sort-asc', 'sort-desc'));
      item.classList.add('sort-asc');
      vm.sort.reverse = vm.reverse = false;

    }
    
    profileService.updateSort(`${vm.rule.replace(/\s+/, '')}-${vm.reverse ? 1 : 0}`);
  }

  function inviveAdminModal() {
    $rootScope.$emit('admin.invite', trueSelectedItems());
  }

  function moveItems(toIds) {

    let itemsIds = [],
        entityIds = [],
        items = trueSelectedItems(),
        isCampaign = items.isCampaign,
        entityId = orgService.getOrg().entityId,
        fromGroupId = entityService.getOpenedGroupId(),
        fromEntityId = entityService.getOpenedEntityId(),
        toEntityId = toIds.entityId,
        toGroupId = toIds.groupId || null;

    items.forEach(item => {
      itemsIds.push(isCampaign ? item.campaignId : item.groupId);
      entityIds.push(item.entityId);
    });

    let successActionFunction = function () {
        if (toGroupId) {
            let group = campaignService.getGroupById(toGroupId);
            return selectGroup(group);
        } else {
            return selectOrg();
        }
    };


    let messageConfig = {
        successToastEnabled: true,
        successMsg: `Successfully moved ${itemsIds.length} ${isCampaign ? 'campaign(s)' : 'group(s)'} .`,
        successActionTitle: 'Go to new parent',
        successActionFunction: successActionFunction
    };

    if (isCampaign) {
      entityService.moveCampaignsToGroup(fromEntityId, entityIds, toEntityId, messageConfig)
        .then((response)=>{

          movingHelper(entityId, itemsIds, toGroupId, fromGroupId, isCampaign)

        })
    } else {
      entityService.moveGroups(fromEntityId, entityIds, toEntityId, messageConfig)
        .then((response)=>{

          movingHelper(entityId, itemsIds, toGroupId, fromGroupId, isCampaign)

        })
    }
  }

  function movingHelper(entityId, itemsIds, toGroupId, fromGroupId, isCampaign) {
      $rootScope.$emit('tree.clear');

      if (isCampaign) {
          campaignService.moveCampaignCache(itemsIds, toGroupId || 0);
      } else {
          campaignService.moveGroupCache(itemsIds, toGroupId || 0);
      }

      campaignService.openGroup(fromGroupId, entityId)
          .then((campaignsData) => {
              $rootScope.$emit('tree.changed', campaignsData);
          })
  }

  function addAdminPanelUrl(campaigns) {

    campaigns.forEach(item => {

      if (item.archived || !item.eventHostReal) {
        item.adminUrl = item.curEventId;
      } else {
        item.adminUrl = item.eventHostReal.replace("/", "~");
      }

    });

    return campaigns

  }



  /* More ... actions */


  vm.delistCmps = delistCmps;
  vm.loaders = {};
  vm.archiveOrRelaunchClick = archiveOrRelaunchClick;

  function updateDelistCampaigns(entityIds, delist) {
      let update = entityIds.map(item => ({
          entityId: item,
          delisted: delist
      }));

      update.forEach(item => {
          campaignService.updateRawCampaigns(item)
      });
  }

  function updateRelaunchCampaigns(successfulCampaigns) {
      let update = successfulCampaigns;

      update.forEach(item => {
          campaignService.updateRawCampaigns(item)
      });
  }

  function updateArchiveCampaigns(entityIds) {
      let update = entityIds.map(item => ({
          entityId: item,
          archived: true
      }));

      update.forEach(item => {
          campaignService.updateRawCampaigns(item)
      });
  }
  
  function delistCmps(delist) {

    let selectedItems = trueSelectedItems(),
        entityIds = selectedItems
                      .filter(item => item.delisted !== delist)
                      .map(item => item.entityId);

    if (!entityIds.length) {
        let actionable = delist ? 'delistable' : 'publishable';
        showNoCampaignsMsg(actionable);
        return;
    }

    showConfirm(delist ? 'delist' : 'publish', entityIds.length)
      .then(() => {
        showLoading();

        function advancedErrors(currentMessageService, response) {
            let successfulEntityIds = response.data.successfulEntityIds;
            updateDelistCampaigns(successfulEntityIds, delist);
            let action = delist ? 'delist' : 'publish';
            let actioned = delist ? 'delisted' : 'published';
            showErrors(successfulEntityIds.length, entityIds.length, action, actioned, response.data.errors);
        }

        let messageConfig = {
            successToastEnabled: true,
            successMsg: `Successfully ${delist ? 'delisted' : 'published'} ${entityIds.length} campaign(s)!`,
            advancedErrorFunction: advancedErrors
        };

        campaignService.delistCampaigns(entityIds, delist, messageConfig)
          .then(() => updateDelistCampaigns(entityIds, delist))
          .finally(hideLoading);
        });
  }

    function archiveOrRelaunchInvalid(currentMessageService, response, endClickEvent, endType){
        response = response.data;
        if (response.liveDonationRecurringCount && (response.destinationOptions || endType === 'relaunch')){
            showMoveRecurrigModal(response, endClickEvent, endType);
        } else {
            showUnableToEndModal();
        }
    }

    function archiveOrRelaunchClick(endClickEvent, endType){
        if(vm.loaders.relaunch || vm.loaders.archive){
            return;
        }
        vm.loaders[endType] = true;
        if(!filterAndValidateEndCmps(endType)){
            return;
        }

        let messageConfig = {
            advancedErrorFunction: (currentMessageService, response) => archiveOrRelaunchInvalid(currentMessageService, response, endClickEvent, endType),
            advancedErrorEnabled: true
        };

        campaignService.validateArchiveOrRelaunchCmps(vm.relaunchOrEndEntityIds, messageConfig)
            .then(response => {
                endType === 'relaunch' ? showRelaunchConfirm(endClickEvent) : showArchiveConfirm(endClickEvent);
            }) //validation errors handled in message service advancedErrorFunction
            .finally(() => {
                vm.loaders[endType] = false;
                vm.moreTooltip = false;
            });
    }

    function filterAndValidateEndCmps(endType){
        let selectedItems = trueSelectedItems();
        vm.relaunchOrEndEntityIds = selectedItems
                      .filter(item => !item.archived)
                      .map(item => item.entityId);

        if (!vm.relaunchOrEndEntityIds.length) {
            let actionable = endType = 'relaunch' ? 'relaunchable' : 'endable';
            showNoCampaignsMsg(actionable);
            return false;
        }
        return true;
    }

  function showArchiveConfirm(endClickEvent, recurringPlanDestination) {

    showConfirm('end', vm.relaunchOrEndEntityIds.length, endClickEvent, recurringPlanDestination)
      .then(() => {
        showLoading();

        function advancedErrors(currentMessageService, response) {
            let successfulEntityIds = response.data.successfulEntityIds;
            updateArchiveCampaigns(successfulEntityIds);
            let action = 'end';
            let actioned = 'ended';
            showErrors(successfulEntityIds.length, entityIds.length, action, actioned, response.data.errors);
        }

        let messageConfig = {
            successToastEnabled: true,
            successMsg: `Successfully ended ${vm.relaunchOrEndEntityIds.length} campaign(s)!`,
            advancedErrorFunction: advancedErrors
        };

        campaignService.archiveCampaigns(vm.relaunchOrEndEntityIds, Boolean(vm.liveDonationRecurringCount), recurringPlanDestination, messageConfig)
          .then(() => updateArchiveCampaigns(vm.relaunchOrEndEntityIds))
          .finally(hideLoading);
    });

  }

  function showRelaunchConfirm(endClickEvent, recurringPlanDestination) {

    showConfirm('relaunch', vm.relaunchOrEndEntityIds.length, endClickEvent, recurringPlanDestination)
      .then(() => {
        showLoading();

        function advancedErrors(currentMessageService, response) {
            successfulCampaigns = response.data;
            updateRelaunchCampaigns(successfulCampaigns);
            let action = 'relaunch';
            let actioned = 'relaunched';
            showErrors(successfulCampaigns.length, vm.relaunchOrEndEntityIds.length, action, actioned, response.data.errors);
        }

        let messageConfig = {
          successToastEnabled: true,
          successMsg:`Successfully relaunched ${vm.relaunchOrEndEntityIds.length} campaign(s)!`,
          advancedErrorFunction: advancedErrors
        };

        campaignService.relaunchCampaign(vm.relaunchOrEndEntityIds, Boolean(vm.liveDonationRecurringCount), recurringPlanDestination, messageConfig)
          .then(response => updateRelaunchCampaigns(response.data))
          .finally(hideLoading);
    });

  }

    function showMoveRecurrigModal(response, endClickEvent, endType){
        vm.liveDonationRecurringCount = response.liveDonationRecurringCount;
        vm.destinationOptions = response.destinationOptions;
        vm.moveTrigger = endType;
        vm.targetEvent = endClickEvent;
        vm.confirmationCallback = endType === 'relaunch' ? showRelaunchConfirm : showArchiveConfirm;
        vm.showModalMoveRecurring = true;
    }

    function showUnableToEndModal(){
        messageService.showErrorDialog(`Campaigns cannot be ended while they have active recurring donation plans or donation matches. <br>Please end your active recurring plan(s) and/or match(es), after which you will be able to end the selected campaign(s).`)
    }

  function showNoCampaignsMsg(actionable) {
      return $mdDialog.show(
          $mdDialog.alert()
            .title('Notice')
            .textContent(`No ${actionable} campaigns selected.`)
            .ok('Close')
    )
  }

  function showConfirm(action, count, endClickEvent, recurringPlanDestination) {
    let unendedPlansMsg = ``;
    if(vm.liveDonationRecurringCount){
        let recurringDestinationStr = recurringPlanDestination ? recurringPlanDestination.eventName : `the newly relaunched campaign(s)`;
        unendedPlansMsg = `<br>${vm.liveDonationRecurringCount} active recurring plan(s) will be transferred to ${recurringDestinationStr}.`;
    }

    return $mdDialog.show(
      $mdDialog.confirm()
        .clickOutsideToClose(true)
        .title('Are you sure?')
        .htmlContent(`You are about to ${action} ${count} campaign(s).${unendedPlansMsg}`)
        .targetEvent(endClickEvent)
        .ok('Confirm')
        .cancel('Cancel')
    )
  }

    let loadingDialogVisible;

    function showLoading() {
    loadingDialogVisible = true;
    return $mdDialog.show({
        clickOutsideToClose: false,
        template: `<md-dialog>
                  <md-dialog-content>
                     <loader flex size="40"></loader>
                  </md-dialog-content>
                </md-dialog>`
    })
    .finally(() => loadingDialogVisible = false);
  }

  function hideLoading() {
      if (loadingDialogVisible) $mdDialog.cancel();
  }

  function showErrors(successCount, totalCount, action, actioned, errors) {
      function show(){
          let errorIntroMsg = `${successCount} out of ${totalCount} campaign(s) ${actioned} successfully. <br><br>The following campaigns failed to ${action}:`;
          messageService.showErrorDialogFromErrorsObj(errors, errorIntroMsg);
      }

      if (loadingDialogVisible) {
        $mdDialog.cancel()
        .then(show);
      }else {
        show();
      }
  }

  function focusInput(){

    let input = document.getElementById('searchBox');
        $timeout(function () { 
          input.focus(); 
        }, 0);
  }

  function searchFilterUpdate(){
      $scope.$broadcast('beforeFilterOrSortUpdated');
      vm.filterParams.search = vm.searchInput;
  }
}