angular
  .module('app')
  .component('modalConfirmPayPalChanges', {
    bindings: {
      show: '=',
      header: '=',
      body: '=',
      confirm: '=',
      func: '&',
    },
    controller: modalConfirmPayPalChanges,
    controllerAs: 'vm'
  });

function modalConfirmPayPalChanges($scope, $mdDialog) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  });

  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-confirm-pay-pal-changes.tpl.html',
        clickOutsideToClose: true,
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        locals: {
          header: vm.header,
          body: vm.body,
          confirm: vm.confirm,
          func: vm.func,
        }
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($mdDialog, header, body, confirm, func) {
  const vm = this;
  vm.header = header;
  vm.body = body;
  vm.confirm = confirm;
  
  vm.processClick = processClick;
  vm.cancel = closeModal;

  function closeModal() {
    $mdDialog.hide();
  }

  function processClick(){
    func().then(closeModal());
  }
}
