angular.module('app').component('unlockedIcon', {
  controller: unlockedIconController,
  controllerAs: 'vm',
  templateUrl: 'unlocked-icon.tpl.html',
  bindings: {
    parentName: '<',
    setting: '<',
    updating: '<',
    hideOptions: '<',
    disabled: '<',
    isTerm: '<',
    onCampaign: '<',
    removeOverrides: '<'
  }
});

function unlockedIconController() {
  var vm = this;
  vm.showPullout = false;

  vm.togglePullout = function() {
    vm.showPullout = !vm.showPullout;
  };

  vm.hidePullout = function() {
    vm.showPullout = false;
  };

  vm.confirmDeleteChildOverride = function(ev) {
    vm.removeOverrides(ev, vm.setting);
  };
}