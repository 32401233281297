  angular
    .module('app')
    .component('websiteRouter', {      
      controllerAs: 'vm',
      bindings: { $router: '<' },
      controller: websiteRouter
    });
  
    function websiteRouter(routingService, $rootScope) {
      var vm = this;
      this.$routerOnActivate = function(p, n) {
        routingService.getNextRoute(this.$router, 'website').then(routed => {
          if (!routed) {
            $rootScope.$emit('routeNotFound');
          }
        });
      }
    }