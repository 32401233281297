angular.module('app').factory('iframeMessageHandler', iframeMessageHandler);

function iframeMessageHandler($q) {  
    let rbPlatIframeController = {
        rbMessagePrefix: '_rb',
        hasMessageEventListener: false,
        messageListeningHandlers: {},
        promiseCollection: {},
        trustedSources: [],
        loadMessageListener: function (trustedUrl) {
            if (this.trustedSources.indexOf(trustedUrl) < 0) {
                this.trustedSources.push(trustedUrl);
            }
            if (this.hasMessageEventListener) {
                return;
            }
            window.addEventListener('message', this.processMessage, false);
            this.hasMessageEventListener = true;
        },
        processMessage: function (event) {
            var me = rbPlatIframeController;
            if (!event.origin || me.trustedSources.indexOf(event.origin) < 0) {
                return;
            }
            var message;
            try {
                 message = JSON.parse(event.data);
            } catch (e) {
                // can be CKEditor, in any case it is not an iframe message (see ckeditor-cors-fix.js)
                return;
            }

            var pageSource = message.pageSource;
            if (!pageSource || (!pageSource.indexOf(me.rbMessagePrefix) === 0)) {
                return;
            }
            var handler = me.messageListeningHandlers[pageSource.replace(me.rbMessagePrefix, '')];
            if (handler) {
                handler.processMessage(message);
                if (message.respondToMessage) {
                    var messagePromise = me.promiseCollection[message.promiseId];
                    if (messagePromise) {
                        messagePromise.resolve(message);
                    }
                }
            }
        },
        removeListener: function () {
            window.removeEventListener('message', this.processMessage, false); 
            this.hasMessageEventListener = false;
            this.messageListeningHandlers = {};
        },
        addHandler: function (handlerId, handler) {
            this.messageListeningHandlers[handlerId] = handler;
        },
        addPromise: function (id, promise) {
            this.promiseCollection[id] = promise;
        }
    }

    return {
        loadMessageListenerAndSetHandler,
        rbPlatIframeController
    }

    function loadMessageListenerAndSetHandler(url, handler) {
        rbPlatIframeController.loadMessageListener(url);
        rbPlatIframeController.addHandler(handler.id, handler);
    }
}