angular
  .module('app')
  .component('integrationDetails', {
    templateUrl: 'integration-details.tpl.html',
    bindings: {
      $router: '<'
    },
    controller: IntegrationController,
    controllerAs: 'vm'
  });

function IntegrationController($location, orgIntegrationsService) {
  const vm = this;
  
  vm.deleting = false;

  vm.modal = {
    showing: {},
    id: null
  };

  vm.showLog = function(type, id) {
    vm.modal.showing = {[type]: true};
    vm.modal.id = id;
  };
  
  vm.pages = {
    INFORMATION: 'Information',
    CAMPAIGNS: 'Campaigns'
  };
  
  vm.openPage = function($event, page) {
    vm.page = page;
  };

  this.$routerOnActivate = (next, previous) => {
    vm.type = next.params.type;
    vm.typeId = window.decodeURIComponent(next.params.typeId).split('~~').join('/');
    vm.hasInformation = orgIntegrationsService.hasInformation(vm.type);
    vm.hasLogs = orgIntegrationsService.hasLogs(vm.type);
    
    if (!vm.page) {
      vm.page = vm.hasInformation ? vm.pages.INFORMATION : vm.pages.CAMPAIGNS;
    }
  }
}
