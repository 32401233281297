angular.module('app').component('crmLogDateFilter', {
    bindings: {
        to: '=',
        from: '=',
        submit: '=',
        syncing: '=',
        maxDate: '=',
    },
    controllerAs: 'vm',
    templateUrl: 'crm-log-date-filter.tpl.html',
});
