angular.module('app').directive('dynamicFieldTag', function($compile) {
    return {
        restrict: 'E',
        require: '?ngModel',
        scope: {
            component: '&',
            item: '&',
            ngModel: '<',
            ngDisabled: '<',
        },
        link: function($scope, $element, attrs, ngModel) {
            $scope.$on('updateValue', function(event, value) {
                ngModel.$setViewValue(value)
            }, true);
            
            var component = angular.isFunction($scope.component) ? $scope.component() : '';
            var item = angular.isFunction($scope.item) ? $scope.item() : {};
            var domElement = angular.element('<'+component+' ng-disabled="ngDisabled" ng-model="ngModel" id="'+item.Id+'"></'+component+'>');
            $compile(domElement)($scope);
            $element.replaceWith(domElement);
        }
    };
});