angular.module('app').component('fundraisingCustomize', {
  templateUrl: 'fundraising-customize.tpl.html',
  controllerAs: 'vm',
  controller: fundraisingCustomizeController,
  require: {
    parentComponent: '^fundraising'
  },
  bindings: { 
    $router: '<'
  },
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateRoute($nextInstruction);
  }
});

function fundraisingCustomizeController(extJsLoader, entityContentService, orgService, entityService) {
  const vm = this;
  vm.entityType = entityService.getOpenEntity().entityType;
  vm.termType = 'Fundraising';
  vm.getData = getData;
  vm.loading = true;

  function getData() {
    dataRequest()
      .then(items => {
        vm.grid = items;
      }).catch(() => {

    }).finally(() => vm.loading = false);
  }

  function dataRequest() {
    return entityContentService.getFundraisingTerms(vm.parentComponent.openEntity, vm.selectedLanguage)
  }

  orgService.orgInfoLoadedPromise.then(r => {
    entityService.getEntitySettings(vm.parentComponent.openEntity).then(entity => {
      vm.languages = entity.settings.LanguagesKeyValue;
      vm.selectedLanguage = entity.settings.DefaultLanguage;
      vm.isMultiLanguage = entity.settings.IsMultiLanguage;
      vm.hasMediaModule = entity.settings.HasMediaModuleEnabled;
      if (vm.hasMediaModule) {
        extJsLoader.extJsController.updateInnerPage('Content-PersonalPageDefaults');
        this.parentComponent.showIframe = true;
      } else {
        this.parentComponent.loading = false;
        this.parentComponent.showIframe = false;
      }
      getData();
    });
    
  });

  vm.clicked = function(item) {
    return vm.saveTerm(item, false);
  }

  
  vm.open = function(item) {
    entityContentService.getTemplatePlaceholders(vm.parentComponent.openEntity, item.Flags).then(items =>
      CKEDITOR.config.macros = items
    );
  }

  vm.overrideParent = function(item) {
    return vm.saveTerm(item, true);
  }

  vm.saveTerm = function(item, createDuplicate) {
    return entityContentService.updateTerm(vm.parentComponent.openEntity, item, vm.termType, vm.selectedLanguage, createDuplicate);
  }

  vm.adoptParent = function(item) {
    return entityContentService.deleteTerm(vm.parentComponent.openEntity, item, vm.termType, vm.selectedLanguage).then(() => dataRequest());
  }
}