angular
    .module('app')
    .component('modalGenericObject', {
        bindings: {
            show: '=',
            type: '<',
            objectId: '<',
            objectFields: '<',
            objectEventId: '<',
            objectEntityId: '<',
            objectActivityInfo: '<',
            showViewInAdmin: '<',
            saveFunction: '<',
            objectIds: '<',
            moveDonations: '=',
            moveRecurringDonations: '='
        },
        transclude: true,
        controller: ModalObjectCtrl,
        controllerAs: 'vm'
    });

function ModalObjectCtrl($scope, $mdDialog) {
    const vm = this;

    $scope.$watch('vm.show', () => {
        if (vm.show) {
            showModal();
        }
    });

    function showModal() {
        $mdDialog
            .show({
                templateUrl: 'modal-generic-object.tpl.html',
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                locals: {
                    type: vm.type,
                    objectId: vm.objectId,
                    objectFields: vm.objectFields,
                    objectEventId: vm.objectEventId,
                    objectEntityId: vm.objectEntityId,
                    objectActivityInfo: vm.objectActivityInfo,
                    showViewInAdmin: vm.showViewInAdmin,
                    saveFunction: vm.saveFunction,
                    objectIds: vm.objectIds
                },
                clickOutsideToClose: true,
            })
            .finally(() => {
                vm.show = false;
            });
    }
}

function ModalContentCtrl($http, API_PREFIX, $mdDialog, orgService, campaignService, objectFields, objectEventId, objectEntityId, objectId, type, objectActivityInfo, showViewInAdmin, saveFunction, objectIds) {
    const vm = this;
    vm.closeModal = closeModal;
    vm.objectId = objectId;
    vm.objectEventId = objectEventId;
    vm.setEditing = setEditing;
    vm.lastIndex;
    vm.index;
    vm.savedFlags = [];
    vm.editFlags = [];
    vm.initialValues = [];
    vm.fields = [];
    vm.loadInProgress = true;
    vm.showViewInAdmin = showViewInAdmin;
    vm.saveFunction = saveFunction;
    vm.viewInAdmin = viewInAdmin;
    vm.objectActivityInfo = objectActivityInfo;
    vm.objectIds = objectIds;
    vm.moveDonations = false;
    vm.moveRecurringDonations = false;

    vm.entityId = objectEntityId;

    if (type == 'Online Donation' || type == 'Donation' || type == 'Moved Donation') {
        $http({ url: `${API_PREFIX}Donation/GetDonationViewData`, headers: { entityId: objectEntityId }, data: { EventID: objectEventId, DonationID: objectId }, method: 'POST' })
            .then(response => {
                if (response.data.sendToOldAdmin) {
                    $mdDialog.hide();
                    window.open(orgService.getCampaignPath(campaignService.getCampaignByEventId(objectEventId),'/transactions/donations?type=Donation&value=' + objectId), '_blank');
                } else {
                    vm.fields = response.data.donationFields;
                    vm.loadInProgress = false;
                    vm.title = response.data.title;
                    vm.eventName = response.data.eventName;
                }
            },
                response => {
                    $mdDialog.hide();
                }
            );
    } else if (type == 'EntityTemplatePage') { // we loaded modal with fields
        vm.fields = objectFields;
        vm.loadInProgress = false;
        vm.title = 'Edit';
    } else if (type == 'moveDonations') {
        vm.fields = prepareMoveDonationsViewData();
        vm.loadInProgress = false;
        vm.title = 'Move multiple donations';
        vm.moveDonations =  true;
    } else if (type == 'moveRecurringDonations') {
        vm.moveRecurringDonations =  true;
        vm.fields = prepareMoveDonationsViewData();
        vm.loadInProgress = false;
        vm.title = 'Move multiple recurring donations';
        
    }

    function setEditing(index, set, e) {
        if (vm.fields[index].isReadonly) {
            return;
        }
        if (set == 'off') {
            vm.editFlags[index] = false;
            $('.pop-up-field').eq(index).removeClass('grayBackground');
            return;
        }
        if (!vm.lastIndex && vm.lastIndex != 0) {
            vm.lastIndex = index;
        }
        if (index != vm.lastIndex) {
            $('.pop-up-field').eq(vm.lastIndex).removeClass('grayBackground');
            vm.editFlags[vm.lastIndex] = false;
            vm.displayedValue[vm.lastIndex] = angular.copy(vm.initialValues[vm.lastIndex]);
            vm.fields[vm.lastIndex].value = angular.copy(vm.initialValues[vm.lastIndex]);
        }
        $('.pop-up-field').eq(index).addClass('grayBackground');
        vm.editFlags[index] = true;
        vm.lastIndex = index;
    }

    function closeModal() {
        $mdDialog.hide();
    }

    function viewInAdmin() {
        window.open(
            orgService.getCampaignPath(campaignService.getCampaignByEventId(objectEventId), '/transactions/donations?type=Donation&value=' + objectId),
            '_blank'
        )
    }

    function prepareMoveDonationsViewData() {
        var obj = [
            {
               "value":"Various",
               "name":"Donor",
               "isReadonly":true,
               "fieldType":"Text"
            },
            {
               "value":"Various",
               "name":"Amount",
               "isReadonly":true,
               "fieldType":"Text"
            },
            {
               "value":"Click to change",
               "name": "Recipient",
               "isReadonly":false,
               "isAutocomplete":true,
               "fieldType":"Text",
               "recurring": vm.moveRecurringDonations
            },
            {
               "value":"Various",
               "name":"Method",
               "isReadonly":true,
               "fieldType":"Text"
            },
            {
               "value":"Various",
               "name":"Date Received",
               "isReadonly":true,
               "fieldType":"Text"
            }
         ];

         return obj;
    }
}

