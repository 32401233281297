angular
    .module('app')
    .component('modalAddTemplatePage', {
        bindings: {
            show: '=',
            onCreateAction: '='
        },
        controllerAs: 'vm',
        controller: ModalAddTemplatePageController
    })

function ModalAddTemplatePageController($scope, $mdDialog)
{
    const vm = this;

    $scope.$watch('vm.show', () => {
        if (vm.show) {
            showModal();
        }
    });

    function showModal()
    {
        $mdDialog.show({
            templateUrl: 'modal-add-template-page.tpl.html',
			controller: ModalAddTemplatePageContentCtrl,
            controllerAs: 'vm',
            locals: {
                onCreateAction: vm.onCreateAction
            },
            clickOutsideToClose: true
        })
        .finally(() => {
            vm.show = false;
        })
    }

}


function ModalAddTemplatePageContentCtrl($mdDialog, onCreateAction)
{
    const vm = this;
	
    vm.onCreateAction = onCreateAction;
    vm.closeModal = closeModal;
	vm.validInput = validInput;
    vm.save = save;
	
	vm.filename = '';
	vm.filetype = '';
	vm.filetypes = [
		{label: 'Js', value: '4-js'},
		{label: 'Less', value: '4-less'},
		{label: 'Page', value: '2-liquid'},
		{label: 'Widget', value: '3-liquid'}
	];
				
    function closeModal()
    {
        $mdDialog.hide();
    }
	
	function validInput() {
        return vm.filename && vm.filetype
    }

    function save(){

        if (!vm.onCreateAction) {
            console.error('onCreateAction method not set!', vm.onCreateAction)
            return;
        }

        vm.saving = true;
		
		var pageTypeSplit = vm.filetype.split('-');

        vm.onCreateAction(
            vm.filename, pageTypeSplit[0], pageTypeSplit[1]
        ).finally(() => {
            vm.saving = false;
            closeModal();
        });
    }

}
