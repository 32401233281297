angular
  .module('app')
  .factory('enduranceChallengeService', enduranceChallengeService);

  function enduranceChallengeService($http, API_PREFIX, entityService, messageService) {
    const ENDURANCE_ACTIONS = {
      CREATE: 1,
      EDIT: 2,
      REMOVE: 3,
      ADOPT_PARENT: 4,
      OVERRIDE_PARENT: 5,
      DELETE_CHILD_OVERRIDES: 6,
    };

    var errorHandler = {
      advancedErrorFunction: (currentMessageService, r) =>
        messageService.showErrorDialogFromErrorsObj(r.data.errors),
    };
    
    return {    
        getEnduranceForm: () => sendRequest('GetEnduranceForm'),
        updateEnduranceChallenge: data => sendRequest('UpdateEnduranceChallenge', data, errorHandler),
        createEnduranceChallenge: data => sendCreateDeleteRequest(ENDURANCE_ACTIONS.CREATE, data, errorHandler),
        removeEnduranceChallenge: data => sendCreateDeleteRequest(ENDURANCE_ACTIONS.REMOVE, data),

        adoptParent: () => sendCreateDeleteRequest(ENDURANCE_ACTIONS.ADOPT_PARENT),
        overrideParent: () => sendCreateDeleteRequest(ENDURANCE_ACTIONS.OVERRIDE_PARENT),
        clearOverrides: () => sendCreateDeleteRequest(ENDURANCE_ACTIONS.DELETE_CHILD_OVERRIDES)
    };


  function sendCreateDeleteRequest(actionType, data, errorHandler) {
    data = data || {};
    data.actionType = actionType;
    return sendRequest('CreateDeleteEnduranceChallenge', data, errorHandler);
  }

  function sendRequest(actionName, data, messageConfig) {
    let entityId = entityService.getOpenedEntityId();
    return $http({
      url: `${API_PREFIX}Cmp/${actionName}`,
      data,
      headers: { entityId },
      method: 'POST',
      messageConfig
    }).then(response => response.data);
  }
}