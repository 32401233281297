var routes = [
    {
      path: '/',
      name: 'FormsRouter',
      component: 'formsRouter',
      data: { dontRoute: true,
            showFunc: function() {return false;} },
      useAsDefault: true
    },
    {
      path: '/donationSettings',
      name: 'Donation Settings',
      component: 'donationSettings',
      data: {
        anyResource: ['DONATION_SETTINGS', 'PROCESSOR_SETTINGS', 'PROCESSING_FEES', 'POST_DONATION_OPTIONS', 'PAY_PAL']
      }
    },
    {
      path: '/registrationSettings',
      name: 'Registration Settings',
      component: 'registrationSettings',
      data: {
        anyResource: ['REGISTRATION_SETTINGS'],
        hideOnStandaloneAndTicketing: true
      }
    },
    {
      path: '/registrationFields',
      name: 'Registration Fields',
      component: 'registrationFields',
      data: {
        resource: 'REGISTRATION_FIELDS',
        limitToCampaign: true,
        routeFunc: function(entity) {
          return !entity.settings.HasRoleRegistration;
        },
        hideOnStandaloneAndTicketing: true
      }
    },
    {
      path: '/registrationBuilder',
      name: 'Registration Builder',
      component: 'registrationBuilder',
      data: {
        resource: 'REGISTRATION_FIELDS',
        limitToCampaign: true,
        routeFunc: function(entity) {
          return entity.settings.HasRoleRegistration;
        },
        hideOnStandaloneAndTicketing: true
      }
    },
    {
      path: '/ticketBuilder',
      name: 'Ticket Builder',
      component: 'ticketBuilder',
      data: {
        resource: 'TICKETS',
        limitToCampaign: true,
        routeFunc: function(entity) {
          return entity.type != 'Donation' && entity.type != 'Global Donation Page' && (entity.settings.EventHasTickets || (entity.type != 'Memorial' && entity.type != 'Personal Fundraising'));
        }
      }
    },
    {
      path: '/discountCodes',
      name: 'Discount Codes',
      component: 'discountCodes',
      data: {
        resource: 'DISCOUNT_CODES',
        limitToCampaign: true
      }
    },
    {
      path: '/formBuilder',
      name: 'Form Builder',
      component: 'formBuilder',
      data: {
        resource: 'FORMS',
        limitToCampaign: true
      }
    },
    {
      path: '/customizeSettings',
      name: 'Customize',
      component: 'customizeSettings',
      data: {
        resource: 'FORMS_CUSTOMIZE'
      }
    },
    {
      path: '/advancedSettings',
      name: 'Advanced',
      component: 'advancedSettings',
      data: {
        anyResource: ['SETTINGS_ADVANCED'],
      }
    }
  ];

angular.module('app').component('formsTab', {
  templateUrl: 'forms-tab.tpl.html',
  controllerAs: 'vm',
  controller: formsTabController,
  $routeConfig: routes,
  $canActivate: function($nextInstruction, routingService) {
    return routingService.validateNoPendingChanges($nextInstruction);
  }
});

angular.module('app').constant('formsRouteConfig',routes);

function formsTabController(routingService, entityService, orgService, $rootScope, extJsLoader, iframeMessageHandler) {
  const vm = this;
  vm.navs = routes;
  vm.iframeData = {};
  vm.loading = true;
  vm.showIframe = false;

  this.$routerOnDeactivate = function(p, n) {
    extJsLoader.extJsController.iframeCreated = false;
  }

  routingService.updateRouting().then(r => {
    vm.openEntity = r;
    let entity = entityService.getRawEntityById(r);
    if (entity) {
      vm.onGroup = entity.entityType === 'CampaignGroup';
      vm.onOrg = entity.entityType === 'Org';
      if (entity.curEventId) {
        vm.iframeSrc = `/site/${entity.curEventId}?${location.search.indexOf('pass') > -1 ? `pass=true&` : ``}type=plat&value=`;
        if (!extJsLoader.extJsController.iframeCreated) {
          vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'forms');
        } else {
          vm.loading = false;
        }
        
        iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
      }
    }
  });

  $rootScope.$on('loaded', r => {
    extJsLoader.extJsController.loadInnerPage().then(r => vm.loading = false);
  });
}
