angular
  .module('app')
  .filter('chunkArray', ChunkArray);

function ChunkArray() {
  /* Create 2d array with %chunkSize% columns from flat array */
  return (inputArray, chunkSize) => {
    let result = [];
    let currentRow = 0;

    for (let i = 0; i < inputArray.length;) {
      result[currentRow] = [];

      for (let j = 0; j < chunkSize && i < inputArray.length; j++, i++) {
        result[currentRow][j] = inputArray[i];
      }

      currentRow++;
    }

    return result;
  };
}