angular
  .module('app')
  .component('modalCropProfileImg', {
    controller: ModalCropCtrl,
    controllerAs: 'vm'
  });


function ModalCropCtrl($scope, $mdDialog, $rootScope) {
  const vm = this;

  let uploadHandler = $rootScope.$on('file.uploaded', (event, data) => {
    vm.img = data.img;

    showModal()
  });

  $scope.$on('$destroy', uploadHandler);


  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-crop.tpl.html',
        controller: ModalCropInnerCtrl,
        controllerAs: 'vm',
        locals: {
          img: vm.img
        },
        clickOutsideToClose: false
      })
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalCropInnerCtrl($q, $mdDialog, $rootScope,
                            profileService, img) {
  const vm = this;

  vm.closeModal = closeModal;
  vm.applyCropped = applyCropped;

  vm.img = img;
  vm.croppedImage = '';

  function applyCropped() {

    if (vm.saveInProgress) return;

    vm.saveInProgress = true;

      profileService.saveProfileImage(vm.croppedImage)
      .then(response => {

        $rootScope.$emit('file.approved', response);
        vm.saveInProgress = false;
        closeModal()
      })
      .finally(() => {
      vm.saveInProgress = false;
      })
  }

  function closeModal() {
    $mdDialog.hide();
  }
}

