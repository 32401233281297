angular
    .module('app')
    .directive("goalsGrid", () => ({
        controller: goalsGrid,
        controllerAs: 'vm',
        link: (scope, element, attrs, ngModel) => {
            scope.$on('updateValue', function(event, value) {

                if (!ngModel.$viewValue || !_.isEqual(_.sortBy(value.ids), _.sortBy(ngModel.$viewValue.ids))) {
                    ngModel.$setViewValue(value);
                    scope.$parent.$broadcast('updateValue', value);
                }
            }, true );

            ngModel.$isEmpty = function(value) {
                return !value || !value.totalSelected || value.totalSelected === 0;
            };
        },
        templateUrl: 'selectable-grid-container.tpl.html',
        restrict: 'E',
        transclude: true,
        require: '?ngModel',
        scope: {
            ngModel: '<',
            ngDisabled: '<',
            id: '<',
        }
    }));

function goalsGrid($scope, $timeout, agGridService, campaignService) {
    const vm = this;
    
    vm.itemName = 'goal';
    vm.itemNamePlural = vm.itemName + 's';

    vm.gridFunctions = {};
    
    vm.gridFunctions.getColumnDefs = function(editable) {
        return [
            {
                field: 'GoalId',
                headerName: 'Goal ID',
                checkboxSelection: editable,
                filter: 'agNumberColumnFilter'
            }, {
                field: 'Name',
                headerName: 'Name',
                filter: 'agTextColumnFilter',
            }, {
                field: 'GoalType',
                headerName: 'Type',
                filter: 'agSetColumnFilter',
                filterParams: {
                    values: ['Member', 'Team'],
                },
            }, {
                field: 'MemberId',
                headerName: 'Member Id',
                filter: 'agNumberColumnFilter',
                hide: true,
            }, {
                field: 'TeamId',
                headerName: 'Team Id',
                filter: 'agNumberColumnFilter',
                hide: true,
            },
        ]
    }

    vm.gridFunctions.getDatasource = function(editable, ngModel) {

        const action = editable ? 'AllGoals' : 'GoalsById';
        const data = {};
        if (!editable) {
            data.goalIds = ngModel.ids
        }
        return agGridService.getDatasource('Contact', action, () => (data), vm.filterData);
    }

    vm.gridFunctions.isNodeSelected = function(data, ngModel) {
        return ngModel && ngModel.ids && ngModel.ids.includes(data.GoalId);
    }

    vm.gridFunctions.updateSelectedRows = function(selectedNodes) {
        let value = { ids: [], totalSelected: 0};

        _.forEach(selectedNodes, (node) => {
            value.ids.push(node.data.GoalId);
        });

        value.totalSelected = selectedNodes.length;

        $scope.$broadcast('updateValue', value);
    }

    vm.gridFunctions.additionalProperties = function(editable) {
        return {
            advancedFiltering: true,
            getRowNodeId: (data) => data.GoalId,
        }
    }
    
    vm.gridFunctions.getServerSideStoreType = function(editable) {
        return 'full';
    }

    vm.filterData = function(items) {
        return items;
    }
}