angular.module('app').component('globalSearch', {
  templateUrl: 'global-search.tpl.html',
  controller: GlobalSearchCtrl,
  controllerAs: 'vm'
});

function GlobalSearchCtrl($rootScope, $timeout, $http, orgService, $location, $window, entityService, securityService, campaignService) {
  const vm = this;

  var sortTypes = [
      'Relevant',
      'Recent',
      'Alphabetical'
  ];
  vm.sortTypes = sortTypes;
  var defaultSort = 'Relevant';

  var groupings = {
      Relevant: function (a) {
          var entity = entityService.getOpenEntity();
          if (entity && a.members && a.members.some(member => ((member.campaignEntityID == entity.entityId) || (member.campaignGroupEntityIDs && member.campaignGroupEntityIDs.includes(entity.entityId))))) {
              return { order: 0, title: 'In ' + entity.name };
          }
          return (entity && ((a.campaignEntityID == entity.entityId) || (a.campaignGroupEntityIDs && a.campaignGroupEntityIDs.includes(entity.entityId))))
              ? { order: 0, title: 'In ' + entity.name }
              : { order: 1, title: 'Results From Entire Organization' };
      }
  }

  function recentSort(a, b) {
    return b.createdOn - a.createdOn;
  }
  
  function percentMatchTo(a, b) {
      var query = vm.originalQuery;
      
      if (!query.match(/\S{2}/))
          return recentSort(a, b);

      return percentMatch(query, b.info) - percentMatch(query, a.info);

      // determine the percent match with the original query
      // using http://stackoverflow.com/a/1663745
      // Source: http://www.catalysoft.com/articles/StrikeAMatch.html
      function percentMatch(a, b) {
          const pairs1 = wordLetterPairs(a.toUpperCase());
          const pairs2 = wordLetterPairs(b.toUpperCase());

          let intersection = 0;
          const union = pairs1.length + pairs2.length;

          for (let i = 0; i < pairs1.length; i++) {
              for (let j = 0; j < pairs2.length; j++) {
                  if (pairs1[i] == pairs2[j])
                  {
                      intersection++;
                      pairs2.splice(j, 1); // //Must remove the match to prevent "GGGG" from appearing to match "GG" with 100% success
                      break;
                  }
              }
          }

          return (2 * intersection) / union;

          function wordLetterPairs(string) {
              // get each word
              return string.split(/\s/)
                  // clear blank entries
                  .filter(word => !!word)
                  // get the letter pairs
                  .map(word => letterPairs(word))
                  // flatten the 2d array
                  .flat();
          }

          function letterPairs(string) {
              return string
                  // get each letter
                  .split('')
                  // get each pair (it and the one before it) into an array
                  .reduce((acc, val, index, letters) => { acc.push(letters[index - 1] + letters[index]); return acc }, [])
                  // remove the first one (it is junk, undefined + first letter)
                  .splice(1);
          }
      }
  }

  var sorts = {
    Relevant: percentMatchTo,
    Recent: recentSort,
    Alphabetical: function (a, b) {
      if (a.info == b.info) {
        return recentSort(a, b);
      }
      return a.info > b.info ? 1 : -1;
    },
  };

  var orgId = orgService.getOrgId();

  vm.activeSort = defaultSort
  vm.resultCategories = [];
  vm.selectedResultCategory = null;
  vm.results = {};
  vm.groupedAndSortedResults = {};
  vm.sortedResults = null;
  vm.resultGroups = {};
  vm.searching = false;
  vm.searched = false;
  vm.failed = false;
  vm.searchTerm = '';
  vm.triggerSearch = triggerSearch;
  vm.getAppend = getAppend;
  vm.select = function (type, id, eventId, campaignEntityId, result) {
        if (type === 'Group' && id){
            $window.open(`/org/${orgId}/group/${id}`, '_blank');
        }
        else if (type === 'Campaign' && id){
            $window.open(orgService.getCampaignPath(campaignService.getCampaignById(id)), '_blank');
        }
        else if (type === 'Donation' && id){
            donationClicked(type, id, eventId, campaignEntityId);
        }
        else if (eventId && type && id) {
            $window.open(orgService.getCampaignPath(campaignService.getCampaignByEventId(eventId), vm.getAppend(type, id)), '_blank');
        }
    };
    var $searchBar = $('#search-bar');

    function searchKeypress(event) {
        if (event.key == 'Enter') {
            event.preventDefault();
            triggerSearch();
        } else if (event.key == 'Escape') {
            event.preventDefault();
            vm.searched = false;    
        }
    };

    vm.searchKeypress = searchKeypress;

    function triggerSearch() {
        vm.failed = false;
        $searchBar.focus();
        vm.originalQuery = vm.searchTerm;
        if (vm.originalQuery.length < 1) {
            vm.resultCategories = [];
            vm.results = {};
            return;
        }
        vm.searching = true;
        $http.post('/Platform/OrgSearchGlobal', {
            query: vm.originalQuery,
            headers: {
                orgId: orgId
            }
        }).then(response => {
           if (!response || !response.data || !response.data.success) {
                vm.searched = true;
                vm.searching = false;
                vm.failed = true;
                return;
            }
            vm.searchTerm = vm.originalQuery;
            vm.results = {};
            for (var item of response.data.items) {
                if (!vm.results[item.type])
                    vm.results[item.type] = [];
                vm.results[item.type].push(item);
            }
            vm.resultCategories = Object.keys(vm.results);
            if (vm.resultCategories.length && !(vm.selectedResultCategory in vm.results)) {
                vm.selectedResultCategory = vm.resultCategories[0];
            }
            sortResults();
            vm.searched = true;
            vm.searching = false;
        }, error => {
            vm.searched = true;
            vm.searching = false;
            vm.failed = true;
        });
    }
    vm.triggerSearch = triggerSearch;

    function sortResults() {
        var sort = sorts[vm.activeSort];
        var grouping = groupings[vm.activeSort];
        if (grouping) {
            var results = {};
            var resultGroups = {};
            for (var category in vm.results) {
                var resultGroupsInCategory = {};
                results[category] = {};
                vm.results[category].forEach(function (result) {
                    if (result.members && result.members.length) {
                        var memberGroups = {};
                        var membersInGroups = {};
                        for (let member of result.members) {
                            var memberGroup = grouping(member);
                            if (!membersInGroups[memberGroup.order]) {
                                membersInGroups[memberGroup.order] = [];
                            }
                            membersInGroups[memberGroup.order].push(member);
                            memberGroups[memberGroup.order] = null;
                        }
                        var members = [];
                        for (let group of Object.keys(memberGroups).sort()) {
                            if (sort) {
                                membersInGroups[group].sort();
                            }
                            members = members.concat(membersInGroups[group]);
                        }
                        result.members = members;
                    }
                    var group = grouping(result);
                    if (!results[category][group.title]) {
                        results[category][group.title] = [];
                    }
                    results[category][group.title].push(result);
                    resultGroupsInCategory[group.order] = group.title;
                });
                resultGroups[category] = Object.keys(resultGroupsInCategory)
                    .sort()
                    .map(function (key) { return resultGroupsInCategory[key]; });
                if (sort) {
                    for (var group in results[category]) {
                        results[category][group].sort(sort);
                    }
                }
            }
            vm.sortedResults = null;
            vm.groupedAndSortedResults = results;
            vm.resultGroups = resultGroups;
        } else if (sort) {
            var results = {}
            for (var category in vm.results) {
                results[category] = Array.from(vm.results[category]);
                for (var result of results[category]) {
                    if (result.members) {
                        result.members.sort(sort);
                    }
                }
                results[category].sort(sort);
            }
            vm.sortedResults = results;
            vm.groupedAndSortedResults = null;
            vm.resultGroups = null;
        } else {
            vm.sortedResults = vm.results;
            vm.groupedAndSortedResults = null;
            vm.resultGroups = null;
        }
    }
    vm.sortResults = sortResults;

    function getAppend(type, id) {
        var path = '';
        if (type === 'Registrant') {
            path = '/people/registrants';
        }
        else if (type === 'Donor') {
            path = '/people/donors';
        }
        else if (type === 'Team') {
            path = '/people/teams';
        }
        else if (type === 'Donation') {
            path = '/transactions/donations';
        }
        return path + '?type=' + type + '&value=' + id;
    }

    function donationClicked(type, id, eventId, campaignEntityId) {
        var entity = entityService.getRawEntityById(campaignEntityId);
        var hasPermission = securityService.getAdminPermissions(entity, 'DONATION');
        if (hasPermission.DELETE) {
            vm.showObjectModal = true;
            vm.objectType = type;
            vm.objectId = id;
            vm.objectEventId = eventId;
            vm.objectEntityId = campaignEntityId;
            $('#search-bar').click();
        } else {
            window.open(
                orgService.getCampaignPath(campaignService.getCampaignByEventId(eventId),'/transactions/donations?type=Donation&value=' + id),
                '_blank'
            );
        }
    }

    $rootScope.$on('$locationChangeSuccess', function () {
        sortResults();
    });
}
