var routeConfig = [  
    {
      path: '/',
      name: 'WebsiteRouter',
      component: 'websiteRouter',
      data: { dontRoute: true,
             showFunc: function() {return false;} },
      useAsDefault: true

    },
    {
      path: '/faq',
      name: 'FAQs',
      component: 'faq',
      data: {
        resource: 'CONTENT_FAQ',
        limitToCampaign: true,
        hideOnStandaloneAndTicketing: true
      }
    },
    {
      path: '/sponsors',
      name: 'Sponsors',
      component: 'sponsors',
      data: {
        resource: 'CONTENT_SPONSER',
        limitToCampaign: true
      }
    },
    {
      path: '/blog',
      name: 'Blog',
      component: 'blog',
      data: {
        resource: 'FEED',
        limitToCampaign: true,
        hideOnStandaloneAndTicketing: true
      }
    },
    {
      path: '/mediaLibrary',
      name: 'Media Library',
      component: 'mediaLibrary',
      data: {
        resource: 'IMAGES',
        limitToCampaign: true
      }
    },
    {
      path: '/dictionary',
      name: 'Customize',
      component: 'dictionary',
      data: {
        resource: 'SETTINGS_CMP_DICTIONARY'
      }
    },
    {
      path: '/socialShares',
      name: 'Social Shares',
      component: 'socialShares',
      data: {
        resource: 'SETTINGS_SOCIAL_SHARES'
      }
    },
    {
      path: '/advanced',
      name: 'Advanced',
      component: 'websiteAdvanced',
      data: {
        hideOnNonStandaloneAndTicketing: true,
        anyResource: ['WEBSITE_ADVANCED']
      }
    }
  ];
  
  angular
    .module('app')
    .component('website', {
      templateUrl: 'website.tpl.html',
      controllerAs: 'vm',
      bindings: { $router: '<' },
      controller: websiteController,
      $routeConfig: routeConfig,
      $canActivate: function($nextInstruction, routingService) {
        return routingService.validateNoPendingChanges($nextInstruction);
      }
    });
  
    angular.module('app').constant('websiteRouteConfig',routeConfig);

    function websiteController($rootScope, routingService, entityService, orgService, extJsLoader, iframeMessageHandler, $rootRouter) {
      const vm = this;
      vm.loading = true;
      vm.navs = routeConfig;
      vm.iframeData = {};
      vm.showIframe = false;
  
      this.$routerOnDeactivate = function(p, n) {
        extJsLoader.extJsController.iframeCreated = false;
      }
  
      routingService.updateRouting()
      .then(r => {
          vm.openEntity = r;
          let entity = entityService.getRawEntityById(r);
          if (entity) {
            vm.onGroup = entity.entityType === 'CampaignGroup';
            vm.onOrg = entity.entityType === 'Org';
            if (entity.curEventId) {
              vm.iframeSrc = `/site/${entity.curEventId}?type=plat&value=`;
              if (!extJsLoader.extJsController.iframeCreated) {
                vm.iframeData = extJsLoader.extJsController.createAndLoadIframe(vm.iframeSrc, 'website');
              } else {
                vm.loading = false;
              }
              
              iframeMessageHandler.loadMessageListenerAndSetHandler(location.origin, extJsLoader.extJsController);
            }
          }
      });
  
      $rootScope.$on('loaded', r => {
        extJsLoader.extJsController.loadInnerPage().then(r => vm.loading = false);
      });
      $rootScope.$on('routeNotFound', () => {vm.show404 = true});
    }