angular
    .module('app')
    .component('endurancePledgeModal', {
        bindings: {
            showModal: '=',
            targetEvent: '<'
        },
        controller: endurancePledgeModalCtrl,
        controllerAs: 'vm'
    });

function endurancePledgeModalCtrl($scope, $mdDialog) {
    const vm = this;

    $scope.$watch('vm.showModal', () => {
        if (vm.showModal) {
            showModal();
        }
    });

    function showModal() {
        $mdDialog
            .show({
                templateUrl: 'add-edit-modal.tpl.html',
                escapeToClose: false,
                clickOutsideToClose: false,
                controller: ModalContentCtrl,
                controllerAs: 'vm',
                multiple: true,
                targetEvent: vm.targetEvent,
                locals: {
                    parent: $scope,
                }
            }).catch(() => {})
            .finally(() => {
                vm.showModal = false;
            });
    }
}

function ModalContentCtrl($q, $rootScope, $scope, $element, $mdDialog, $timeout, endurancePledgeService, formService, helpersService) {

    const vm = this;

    vm.item = undefined;
    vm.form = { };
    vm.errors = {};

    endurancePledgeService.getNewPledgeForm().then(r => {
        vm.item = r.item.Card;
        vm.item.InPopup = true;

        let usersField = formService.getFieldFromItem(vm.item, 'Existing User');
        usersField.loading = true;

        endurancePledgeService.getExistingUsers().then((r) => {
            usersField.loading = false;
            
            usersField.options = _.reduce(r.items, (result, value) => {
                value = helpersService.normalizeUserPropertyNames(value);
                result[value.MemberId] = `<div>${helpersService.getUserDisplayName(value, ['MemberId', 'Email'])}</div>`;
                return result;
            }, {})
        });
    })

    vm.cancel = closeModal;
    vm.nextGroup = nextGroup;
    vm.add = addPledge;
    vm.addButtonText = 'Add Endurance Pledge';
    vm.newTitleText = 'New Endurance Pledge';

    function closeModal() {
        $mdDialog.hide();
    }

    function nextGroup() {
        $scope.$broadcast('nextGroup');
    }

    function addPledge() {
        if (vm.validateActiveGroup()) {
            return endurancePledgeService.addPledge(vm.item).then(notifyScopeOfNewPledge).then(closeModal, () => $q.reject());
        } else {
            return $q.reject();
        }
    }

    function notifyScopeOfNewPledge() {
        $rootScope.$broadcast('NewEndurancePledgeAdded');
    }

    $scope.$watch('vm.form.$valid', function (valid) {
        if (!valid) {
            $scope.$broadcast('markGroupIncomplete', vm.form.activeGroup)
        }
    });

    $scope.$on('closeModal', function(event) {
        closeModal();
    })

}