angular.module('app').component('selectItem', {
  bindings: {
    options: '<',
    multiple: '<',
    requireSelection: '<',
    label: '<',
    configId: '<',
    onChange: '<',
    sectionUpdating: '<',
    inherited: '<',
    isDefault: '<',
    parentName: '<',
    disabled: '<',
    tooltip: '<',
    entityType: '<',
    noLock: '<',
  },
  controllerAs: 'vm',
  controller: selectItemController,
  templateUrl: 'select-item.tpl.html'
});
function selectItemController() {
  var vm = this;
  vm.OPTION_REQUIRED_TOOLTIP = 'At least one option must be selected.';
  vm.opened = false;
  vm.crmOptionTooltip = false;
  vm.selected = function(val) {
    if (angular.isDefined(val)) {
      vm._oldValue = vm._selected;
      vm._selected = val;
    } else if (!vm._selected) {
      if (vm.multiple) {
         vm._selected = vm.options.filter(x => x.Selected);
      } else {
        vm._selected = vm.options.find(x => x.Selected);
      }
    }
    return vm._selected;
  }

  vm.setOpened = function () { //workaround to not send initial setting as update
    vm.opened = true;
  }

  vm.lastOptionRequired = function (option) {
    var selected = vm.selected();
    return vm.requireSelection && selected.length == 1 && selected[0] == option;
  };

  vm.onSelected = function() {
    if (!vm.opened) return;
    var val = '';
    if(vm.multiple) {
      val = vm._selected.map(x => x.Value).join('|');
    } else {
      val = vm._selected.Value;
    }
    vm.onChange(vm.configId, val).then(function(success) {
      if (!success && vm._oldValue) {
        vm._selected = vm._oldValue;
      }
      return success;
    });
  };
}
