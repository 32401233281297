angular
    .module('app')
    .controller('IEDialog', ['$scope', '$mdDialog', function($scope, $mdDialog) {
        var alert;
        $scope.showDialog = showDialog;

        function showDialog($event) {
            var ua = window.navigator.userAgent,
                msie = ua.indexOf('MSIE ');

            alert = $mdDialog.alert({
                targetEvent: $event,
                templateUrl: 'browser-support.tpl.html',
                controllerAs: 'ctrl',
                controller: 'IEDialog'
            });
        if (navigator.appVersion.indexOf("MSIE 10") != -1 || msie > 0){
            $mdDialog
                .show(alert)
                .finally(function() {
                    alert = undefined;
                });
            }
        }
    }]);
