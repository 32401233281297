angular
  .module('app')
  .component('profile', {
    templateUrl: 'profile.tpl.html',
    controller: ProfileController,
    controllerAs: 'vm'
  });

function ProfileController($q, $scope, $rootScope, profileService) {
  const vm = this;

  vm.profile = null;

  vm.editFlag = {
    userName: false,
    userEmail: false,
    password: false
  };
  vm.showPasswordModal = false;
  vm.saveInProgress = false;
  vm.saveEdit = saveEdit;
  vm.cancelEdit = cancelEdit;
  vm.offEdits = offEdits;
  vm.changePassword = changePassword;
  loadProfileInfo();

  ///////////////////////////////////

  function changePassword($event) {
    $event.stopPropagation();
    if (!vm.profile) return; 
    if (vm.profile.isNeonSsoOnlyUser) {
      window.open(vm.profile.neonSsoUrl + '/user/password');
      return;
    }
    vm.showPasswordModal = true;
  }

  function loadProfileInfo() {
    profileService
      .getProfileInfo()
      .then((profileInfo) => {
        vm.profile = profileInfo;
        vm.previousProfile = angular.copy(vm.profile);
      });
  }

  function offEdits(field) {
   for (let f in vm.profile){
      if (f != field) {
        cancelEdit(f);
      }
    }
  }

  function saveEdit(field) {
    vm.saveInProgress = true;
    let messageConfig = { successToastEnabled: true };

    if (field == 'userName'){
      messageConfig.successMsg = 'Name changed successfully.';
      profileService.setNameForUser(vm.profile.userName, messageConfig)
        .then((response) => {
            vm.previousProfile.userName = vm.profile.userName;
            vm.editFlag.userName = false;
        })
        .finally(() => vm.saveInProgress = false)
    }
    if (field == 'userEmail'){
      messageConfig.successMsg = 'Email changed successfully';
        profileService.saveEmail(vm.profile.userEmail, messageConfig)
          .then((response) => {
              vm.previousProfile.userEmail = vm.profile.userEmail;
              vm.editFlag.userEmail = false;
          })
          .finally(() => vm.saveInProgress = false)
    }
  }

  function cancelEdit(field) {
    console.log(vm.previousProfile);
    vm.profile[field] = vm.previousProfile[field];
    vm.editFlag[field] = false;
  }


    //image upload

  $scope.onUploadFile = onUploadFile;
  $scope.onDropFile = onDropFile;
  $scope.allowDrop = allowDrop;
  vm.fileUploadInput = null;

  function allowDrop(event) {
      event.preventDefault();
      event.stopPropagation();
  }

  function addOverlay() {
      document.querySelector('.fileInput-placeholder').style.zIndex = '1';
  }

  function removeOverlay() {
      document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
  }

  function onDropFile(event) {
      event.preventDefault();
      document.querySelector('.fileInput-placeholder').style.zIndex = '-1';
      let file = event.dataTransfer.files[0];

      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = event => {

          $rootScope.$emit('file.uploaded', {
              img: event.target.result
          });
      }
  }

  function onUploadFile(event) {

      event.preventDefault();

      let file = event.currentTarget.files[0];

      vm.fileUploadInput = event.target;

      if (!file) return; //if open upload window, but click cancel

      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = event => {

          $rootScope.$emit('file.uploaded', {
              img: event.target.result
          });

      }
  }

  $rootScope.$on('file.approved', (event, data) => {
      let images = data.data.data;
      vm.profile.userAvatarLarge = images[0];
      vm.profile.userAvatarSmall = images[1];
  });

}