angular
    .module('app')
    .factory('ngModelFiltersService', ngModelFiltersService);

function ngModelFiltersService($filter, entityService) {

    let currencyFilter = {
        filter: value => {
            if (isNaN(value)) {
                return value;
            } else {
                const currencyKey = entityService
                    .getEntityWithSettings(entityService.getOpenedEntityId())
                    .settings.DefaultCurrency
                    .Symbol;
                return $filter('currency')(value, currencyKey, 0);
            }
        },
        unfilter: value => {
            let newValue = value.replace(/[,$]/g, '');
            if(isNaN(newValue)){
                return value;
            } else {
                return newValue;
            }
        }
    };

    let dateFilter = {
        filter: value => new Date(value),
        unfilter: value => value ? value.toISOString().substring(0, 10) : value
    };

    return {
        currencyFilter, dateFilter
    };
}