angular
    .module('app')
    .component('staticMailchimp', {
        templateUrl: 'static-mailchimp.tpl.html',
        controller: StaticMailchimpCtrl,
        controllerAs: 'vm'
    });

function StaticMailchimpCtrl($location) {
    const vm = this;
	vm.errCode = $location.$$search.err;
	if(vm.errCode){
		vm.isSuccess = false;
		vm.errorDescription = $location.$$search.error_description;
	} else {
		vm.isSuccess = true;
	}
}