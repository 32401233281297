angular
    .module('app')
    .component('staticLoading', {
        template: `<div style="padding-top: 50px;"><loader size="80"></loader></div>`,
        controller: StaticLoading,
        controllerAs: 'vm'
    });

function StaticLoading($location) {
    const vm = this;
}