angular
  .module('app')
  .component('organization', {
    templateUrl: 'organization.tpl.html',
    controllerAs: 'vm',
    controller: OrganizationController,
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    },
    bindings: { $router: '<' },
    $routeConfig: [
      {
        path: '/administrators',
        name: 'Administrator List',
        component: 'administratorsList',
        useAsDefault: true,
        authenticate: true
      },
      {
        path: '/security-roles',
        name: 'Security Roles',
        component: 'securityRoles',
        authenticate: true
      },
      {
        path: '/automation',
        name: 'Automation',
        component: 'automation',
        authenticate: true
      },
      {
        path: '/integrations',
        name: 'Integrations',
        component: 'orgIntegrations',
        authenticate: true
      },
      {
        path: '/integrations/:type/:typeId',
        name: 'Integration Details',
        component: 'integrationDetails',
        authenticate: true
      },
      {
        path: '/email-domains',
        name: 'Email Domains',
        component: 'emailDomains',
        authenticate: true
      }, 
      {
        path: '/payment-processors',
        name: 'Processors',
        component: 'orgProcessors',
        authenticate: true
      },   
      {
        path: '/**',
        name: 'Page Not Found',
        component: 'pageNotFound',
        authenticate: true
      }
    ]
  });

 function OrganizationController() {
    const vm = this;
  } 