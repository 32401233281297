angular
  .module('app')
  .component('pdfAttachment', {
    controller: pdfAttachmentController,
    bindings: { 
      $router: '<'
    },
    require: {
      parentComponent: '^email'
    },
    template:
      '<form-template' +
        ' form-sections=\'vm.sections\'' +
        ' on-update=\'vm.onChange\'' +
        ' loading=\'vm.loading\'></form-template>' +
      '<div class="custom-setting-item">' +
      '  <tax-receipt-template />' +
      '</div>' +
      '<form-template' +
      '  form-sections=\'vm.advancedSections\'' +
      '  on-update=\'vm.onChange\'' +
      '  loading=\'vm.advancedLoading\'></form-template>',
    controllerAs: 'vm',
    $canActivate: function($nextInstruction, routingService) {
      return routingService.validateRoute($nextInstruction);
    }
});

function pdfAttachmentController(configSettingsService) {
  const vm = this;

  vm.sections = [];
  vm.advancedSections = [];
  configSettingsService.getEmailPdfReceiptSettings()
    .then(r => {
      if (r.success && r.form) {
        vm.sections = r.form.FormSections;
      }
      configSettingsService.getEmailPdfReceiptAdvancedSettings()
        .then(r => {
          if (r.success && r.form) {
            vm.advancedSections = r.form.FormSections;
          }
        });
    });

  vm.onChange = config => configSettingsService.updateSetting(config);
}