angular
  .module('app')
  .factory('orgService', orgService);

function orgService($q, $http, $location, $rootRouter, API_PREFIX, profileService ) {
  let orgsList = null;
  let selectedOrg = null;
  let org;
  let globalSettings = {};
  let orgInfoLoadedDefer = $q.defer();
  let orgInfoLoadedPromise = orgInfoLoadedDefer.promise;

  return { setOrg, getOrg, saveEditOrg, getOrgInfo, getCachedOrg, getOrgId,
		updatePathToOrg, updatePathToGroup, updatePathToCampaign,
		getUrlOrgId, getUrlGroupId, getUrlCampaignId, getUrlCampaignInstanceId, orgInfoLoadedPromise, resolveOrgInfoLoadedPromise,
		getCampaignPath, getUrlHost, getUrlCampaignPath, getAvailableEventSeries };

  function getOrgInfo() {
	return $http({
      url: API_PREFIX + '/GetInfoByCurrentOrg',
      method: 'POST'
    })
	.then((response) => {
		org = response.data.data;
		return org;
	})
  }

  function resolveOrgInfoLoadedPromise(orgInfo) {
	orgInfoLoadedDefer.resolve(orgInfo);
  }

  function getCachedOrg(){
    return org;
  }

  function setOrg(org) {
    selectedOrg = org;
	document.title = (window.brandingName || 'Neon Fundraise') + ' Administration - ' + org.name;
  }

  function getOrg() {
    return selectedOrg;
  }
  
  function getOrgId() {
    return selectedOrg ? selectedOrg.orgId : getUrlOrgId();
  }

  function saveEditOrg(orgId, field, value) {
    return $http({ url: API_PREFIX + '/UpdateOrgInfo', data: { field: field, value: value }, headers: { orgId: orgId }, method: 'POST' })
  }
  
  
	function updatePathToOrg(append) {
		$location.path(getBaseUrl() + (append || ''));
	}

	function updatePathToGroup(group, append) {
		$location.path(getBaseUrl() + '/group/' + group.groupId + (append || ''));
	}

	function getCampaignPath(campaign, append) {
		if (!campaign) return;
		if (window.isChampion) {
			if (campaign.campaignId == window.championCampaignId) {
				return getBaseUrl();
			}
			return '';
		}

		var instanceId = getUrlCampaignInstanceId();
		var instancePath = instanceId ? '/instance/' + instanceId: '';
		append = instancePath + (append || '');
		if(campaign.groupId){
		    return getBaseUrl() + '/group/' + campaign.groupId + '/campaign/' + campaign.campaignId + append;
		}else{
		    return getBaseUrl() + "/campaign/" + campaign.campaignId + append;
		}
	}

	function updatePathToCampaign(campaign, append) {
		$location.path(getCampaignPath(campaign, append));
	}
	
	function getUrlOrgId(){
	    return getUrlPathParam('orgId') || getOrgIdFromPath();
	}
	
	function getUrlGroupId(){
		return getUrlPathParam('groupId');
	}
	
	function getUrlCampaignId(){
		return getUrlPathParam('campaignId');
	}

	function getUrlCampaignInstanceId(){
		return getUrlPathParam('instanceId');
	}

	function getUrlCampaignPath(){
		return getUrlPathParam('campaignPath') || getCampaignPathFromPath();
	}

	function getUrlHost() {
		return $location.host();
	}

	function getAvailableEventSeries() {
		return $http({
			url: API_PREFIX + '/GetEventSeriesAvailable',
			method: 'POST'
		  })
		  .then((response) => {
			  return response.data.data;
		  });
	}
	
	//private
	function getBaseUrl(orgId){
		return window.isChampion ? window.championPrefix : '/org/' + (orgId || getOrgId());
	}  

	function getUrlPathParam(key){
	    return $rootRouter._currentInstruction && $rootRouter._currentInstruction.component.params[key];
	}
}
