angular
    .module('app')
    .component('autoEmail', {
        controller: adminTemplatesController,
        bindings: {
            $router: '<'
        },
        require: {
            parentComponent: '^email'
        },
        templateUrl: 'auto-email.tpl.html',
        controllerAs: 'vm',
        $canActivate: function($nextInstruction, routingService) {
            return routingService.validateRoute($nextInstruction);
        }
    });

function adminTemplatesController($mdDialog, $controller, $q, $scope, orgService, entityService, emailService, securityService) {
    const vm = this;
    var entity = entityService.getOpenEntity();
    vm.configOptionId = emailService.EMAIL_CONFIG_OPTIONS.transactionEmails;
    vm.entityType = entity.entityType;
    vm.security = securityService.getAdminPermissions(entity, 'AUTO_RESPONDERS');
    vm.loading = true;

    vm.getData = function() {
        vm.dataRequest()
            .then(r => {
                vm.grids = r.items;
            }).catch(() => {

        }).finally(() => vm.loading = false);
    }

    vm.dataRequest = function() {
        return emailService.getTransactionalEmailTemplates();
    }

    orgService.orgInfoLoadedPromise.then(r => {
        vm.getData();
    });

    vm.showActivateOptions = (item, forTextDisplay) => emailService.showActivateOptions(item, forTextDisplay);
    vm.editorButtons = emailService.getEmailEditorButtons();

    vm.beforeOpenCard = function() {
        return $q.all(
            _.reduce(vm.grids, (result, value, key) => {
                if (value.saveAndCloseOpenCard) {
                    result.push(value.saveAndCloseOpenCard());
                }
                return result;
            }, [])
        );
    }
    
    vm.clicked = function(item) {
        return vm.saveEmail(item, false);
    }

    vm.open = function(item) {
        emailService.openEmail(item);
    }

    vm.overrideParent = function(item) {
        return vm.saveEmail(item).then(r => {
            return r.item;
        });
    }

    vm.saveEmail = function(item) {
        return emailService.createOrUpdateEmailTemplate(item);
    }

    vm.adoptParent = function(item) {
        return emailService.deleteEmailTemplate(item).then(r => {
            return r.item;
        });
    }

    $scope.$on('clearOverrides', function (event, item) {
        if (item.configOptionId === vm.configOptionId) {
            return emailService.clearEmailTemplateOverrides(item);
        }
    });
}