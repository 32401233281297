angular.module('app').factory('neonCrmService', neonCrmService);

function neonCrmService($http, $q, API_PREFIX, crmSyncsService) {
    return {
        getNeonCrmLogs,
        reSync  
    };

    function getNeonCrmLogs(neonOrgId, from, to) {
        return crmSyncsService.getCrmLogsForDates('NeonSyncs', neonOrgId, from, to);
    }

    function reSync(record) {
        // Don't send other fields back to server in case they have html
        let recordToSync = {
            NeonCrmQueueId: record.NeonCrmQueueId,
            EventId: record.EventId,
        }
        return crmSyncsService.reSync(recordToSync, 'NeonCrmResync');
    }
}
