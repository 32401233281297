angular
  .module('app')
  .component('activity', {
    templateUrl: 'activity.tpl.html',
    controller: ActivityController,
    controllerAs: 'vm'
  });

function ActivityController($http, API_PREFIX, $window, $scope, $rootScope, $location, $timeout, $interval, orgService, campaignService, entityService, messageService, securityService) {
    const vm = this;
    let $document = angular.element(document);
    let idleEvents = ['keydown', 'keyup', 'click', 'mousemove', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'focus'];

    vm.logs = [];
    vm.lastActivtyId = null;
    vm.firstActivityId = null;
    vm.openEntity = null;
    vm.currencySymbol = '$';
    vm.numberWithCommas = numberWithCommas;
    vm.checkboxToggle = checkboxToggle;
    vm.transactionsCheckbox = true; 
    vm.registrationsCheckbox = true; 
    vm.fundraisingMilestonesCheckbox = true;
    vm.campaignId = orgService.getUrlCampaignId();
    vm.groupOrCmpNotFound = false;
    vm.endOfResults = false;
    vm.loadMore = loadMore;
    vm.showObjectModal = false;
    vm.currentLog = null;
    vm.clicked = clicked;

    vm.clearError = clearError;
    vm.newActivityToastShowing = false;
    vm.isIntervalActive = false;
    vm.noFilters = false;
    vm.isChampion = !!window.isChampion;

    vm.slider = {
        min: 0,
        max: 0,
        options: {
            floor: 0,
            ceil: 0,
        }
    };

    vm.filterParams = {
        amountMin: 0,
        amountMax: 0,
        types: new Set(['Transactions', 'Recurring Transactions', 'Registrations', 'Fundraising Milestones']),
    };

    campaignService.checkUpdateRouting()
        .then(setCampaignAndGroup)
        .catch(setNotFound);

    function setCampaignAndGroup(campaignAndGroup) {        
        if (campaignAndGroup.campaign) {
            $rootScope.$emit('onCampaignPage');
            $rootScope.$emit('campaign.opened', {name: campaignAndGroup.campaign.name, campId: campaignAndGroup.campaign.campaignId});
            vm.openEntity = campaignAndGroup.campaign.entityId;
        }

        if(campaignAndGroup.group) {
            var groupId = campaignAndGroup.group.groupId;
            vm.openEntity = vm.openEntity || campaignAndGroup.group.entityId
        }

        vm.openEntity = vm.openEntity || entityService.getOrgEntityId();
        vm.currencySymbol = entityService
            .getEntityWithSettings(vm.openEntity)
            .settings.DefaultCurrency
            .Symbol;

        openGroup(groupId);

        intervalStart();
        startScrollWatch();
    }

    function openGroup(groupId) {
        campaignService.openGroup(groupId)
          .then(campaignsData => {
              $rootScope.$emit('group.opened');
          })
    }

    function setNotFound() {
        $rootScope.$emit('groupOrCampaignNotFound');
        vm.groupOrCmpNotFound = true;
    }

    function sliderFilter(transactionMax, transactionMin) {
        transactionMin = transactionMin || 0;
        transactionMax = transactionMax || 0;

        if(vm.slider.min == vm.slider.options.floor) {
            vm.slider.min = transactionMin;
            vm.filterParams.amountMin = transactionMin;
        };
        if(vm.slider.max == vm.slider.options.ceil) {
            vm.slider.max = transactionMax;
            vm.filterParams.amountMax = transactionMax;
        }

        if(vm.slider.floor != transactionMin || vm.slider.ceil != transactionMax) {
            vm.slider.options = {
                floor: transactionMin,
                ceil: transactionMax,
            }
        }
    }


    $scope.$on("slideEnded", function () {
        vm.filterParams.amountMin = vm.slider.min;
        vm.filterParams.amountMax = vm.slider.max;
    });
   
    function checkboxToggle(type) {
        if (type == 't') {
            if (vm.transactionsCheckbox) {
                vm.filterParams.types.add('Transactions')
                vm.filterParams.types.add('Recurring Transactions')
            } else {
                vm.filterParams.types.delete('Transactions')
                vm.filterParams.types.delete('Recurring Transactions')
            }
        } else if (type == 'r') {
            if (vm.registrationsCheckbox) {
                vm.filterParams.types.add('Registrations')
            } else {
                vm.filterParams.types.delete('Registrations')
            }
        } else if (type == 'f') {
            if (vm.fundraisingMilestonesCheckbox) {
                vm.filterParams.types.add('Fundraising Milestones')
            } else {
                vm.filterParams.types.delete('Fundraising Milestones')
            }
        }
    }

    $scope.$on('updateActivityStream', function (event, data) {
        if (data.activityInfo != undefined) {
            for (var i = 0; i < vm.logs.length; i++) {
                if (vm.logs[i].date == data.activityInfo.date) {
                    for (var j = 0; j < vm.logs[i].daysLogs.length; j++) {
                        if (vm.logs[i].daysLogs[j].activityId == data.activityInfo.id) {
                            //if doesnt have newId was moved in same event
                            if (data.newId) {
                                var evntId = entityService.getRawEntityById(data.newEntityId).curEventId;
                                vm.logs[i].daysLogs[j].currentDonationId = data.newId;
                                vm.logs[i].daysLogs[j].currentEntityId = data.newEntityId;
                                vm.logs[i].daysLogs[j].currentDonationEventId = evntId;
                            }
                            vm.logs[i].daysLogs[j].changeType = 2; //moved
                            break;
                        }
                    }
                    break;
                }
            }
        }
        loadLatest();
    });

    function formatLogs(unformattedLogs, latest) {
        let oldHeight = $document.height();
        let oldScrollTop = $document.scrollTop();

        if (unformattedLogs.length) {
            if (!latest) {
                let lastUnformattedLog = unformattedLogs[unformattedLogs.length - 1];
                vm.lastActivtyId = lastUnformattedLog.activityId;
            }
            if (latest || !vm.firstActivityId) {
                let firstUnformattedLog = unformattedLogs[0];
                vm.firstActivityId = firstUnformattedLog.activityId;
            }
        }

        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() -1);

        if (latest) unformattedLogs.reverse();

        unformattedLogs.forEach((log) => {
            let date = new Date(log.date);
            date.setHours(0, 0, 0, 0);

            let lastLog = vm.logs[vm.logs.length - 1];
            let firstLog = vm.logs[0];
            if (vm.logs.length && (!latest && lastLog.dateObj.getTime() == date.getTime()) || (latest && firstLog.dateObj.getTime() == date.getTime())) {
               !latest ? lastLog.daysLogs.push(log) : firstLog.daysLogs.unshift(log);
                
            } else {
                let viewDate;

                if (today.getTime() == date.getTime()) {
                    viewDate = 'Today';
                } else if (yesterday.getTime() == date.getTime()) {
                    viewDate = 'Yesterday';
                } else {
                    let locale = 'en-us';
                    let month = date.toLocaleString(locale, { month: 'short' });
                    let year = date.getFullYear();
                    viewDate = `${month}. ${date.getDate()}${year != today.getFullYear() ? ` ${year}` : ''}`;
                }

                let formattedLog = { dateObj: date, date: viewDate, daysLogs : [log] };
                !latest ? vm.logs.push(formattedLog) : vm.logs.unshift(formattedLog)
            }

            log.campaignUrl = campaignService.getCampaignById(log.campaignId).campaignUrl;
        });

        if(latest && oldScrollTop) {
            $timeout(() => {
                $document.scrollTop(oldScrollTop + ($document.height() - oldHeight));
            })
        }
    }

    function startScrollWatch() {
        $scope.$watch(() => {
            return {
                scrollHeight: document.body.scrollHeight,
                clientHeight: document.body.clientHeight
            }
        }, LoadMoreIfNoScroll, true);
    }

    $scope.$watch('vm.filterParams.types.size', (newValue, oldValue) => {
        vm.noFilters = !newValue;
    });

    function intervalStart() {
        if(!vm.isIntervalActive) {
            vm.isIntervalActive = true;
            idleEvents.forEach(event => $document.off(event, intervalStart));

            vm.idleInterval = $interval(loadLatest, 30000, 10);

            vm.idleInterval.then(() => {
                vm.isIntervalActive = false;
                idleEvents.forEach(event => $document.on(event, intervalStart));
            });
        }
    }

    function clearError() {
        vm.error = false;
        loadMore();
    }

    function LoadMoreIfNoScroll(lastTry) {
        let hasVScroll;
        $timeout(() => {
            hasVScroll = document.body.scrollHeight > document.body.clientHeight;
            if (!hasVScroll) {
                loadMore();
                //Fix for delayed/jumpy ui updates.
            } else if(!lastTry) {
                $timeout(() => LoadMoreIfNoScroll(true), 100)
            } else {
                vm.loader = false;
            }
        })
    }

    function loadMore() {
        if (angular.element(document.body).hasClass('md-dialog-is-showing')) {
             return;
        }
        if(!vm.endOfResults && !vm.loadInProgress && !vm.error && !vm.noFilters) {
            vm.loader = true;
            vm.loadInProgress = true;
            $http({ url: `${API_PREFIX}/GetNextActivityStream`, headers: { parentEntityId: vm.openEntity }, data: { lastId: vm.lastActivtyId }, method: 'POST' })
            .then(response => {
                    if (response.data.data.activityList.length === 0) {
                        vm.endOfResults = true;
                        vm.loader = false;
                    } else {
                        formatLogs(response.data.data.activityList);
                    }
                    if (response.data.data.maxTransactionAmount) {
                        sliderFilter(response.data.data.maxTransactionAmount, response.data.data.minTransactionAmount);
                    }

                    LoadMoreIfNoScroll();
                }
            )
            .catch(() => {
                vm.loader = false;
                vm.error = true
            })
            .finally(() => vm.loadInProgress = false);
        }
    }

    function loadLatest() {
        if(!vm.noFilters) {
            $http({ url: `${API_PREFIX}/GetNextActivityStream`, headers: { parentEntityId: vm.openEntity }, data: { firstId: vm.firstActivityId }, method: 'POST' })
            .then(response => {
                    if (response.data.data.activityList.length === 0) {
                        return;
                    }

                    formatLogs(response.data.data.activityList, true);

                    if (response.data.data.maxTransactionAmount) {
                        sliderFilter(response.data.data.maxTransactionAmount, response.data.data.minTransactionAmount);
                    }

                    if(!vm.newActivityToastShowing && document.body.scrollTop) {
                        vm.newActivityToastShowing = true;
                        messageService.showSuccessToast('You have new updates.', 'Show Me', scrollToTop)
                            .finally(() => vm.newActivityToastShowing = false);
                    }

                    loadLatest();
                }
            )
        }
    }


    function scrollToTop() {
        angular.element(document.body).animate({ scrollTop: 0 }, 'slow');
    }

    $scope.$on('$destroy', function() {
        // Make sure that the interval is destroyed too
        $interval.cancel(vm.idleInterval);
    });

    function clicked(log, day) {
        var entity = entityService.getRawEntityById(log.currentEntityId);
        var hasPermission = securityService.getAdminPermissions(entity, 'DONATION');
        var currentEventId = log.currentDonationEventId != null ? log.currentDonationEventId : log.eventId;
        var currentDonationId = log.currentDonationId != null ? log.currentDonationId : log.donationId;
        if (hasPermission.DELETE) {
            vm.objectActivityInfo = { date: day.date, id: log.activityId };
            vm.objectEntityId = log.currentEntityId;
            vm.objectEventId = currentEventId;
            vm.objectType = log.innerType;
            vm.objectId = currentDonationId;
            vm.showObjectModal = true;
        } else {
            window.open(
                log.campaignUrl + '/transactions/donations?type=Donation&value=' + currentDonationId,
                '_blank'
            );
        }
    }
}