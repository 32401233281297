angular
    .module('app')
    .component('static', {
        templateUrl: 'static.tpl.html',
        controller: StaticCtrl,
        controllerAs: 'vm',
        $routeConfig: [
            {
                path: '/salesforce',
                name: 'Salesforce',
                component: 'staticSalesforce'
            },
			{
                path: '/dropbox',
                name: 'Dropbox',
                component: 'staticDropbox'
            },
			{
                path: '/nationbuilder',
                name: 'NationBuilder',
                component: 'staticNationbuilder'
            },
			{
                path: '/mailchimp',
                name: 'Mailchimp',
                component: 'staticMailchimp'
            },
			{
                path: '/stripe',
                name: 'Stripe',
                component: 'staticStripe'
            },
			{
                path: '/constantcontact',
                name: 'ConstantContact',
                component: 'staticConstantcontact'
            },
            {
                path: '/loading',
                name: 'Loading',
                component: 'staticLoading'
            },
            {
                path: '/**',
                name: 'Page Not Found',
                component: 'pageNotFound'
            }
        ]
    });

function StaticCtrl() {
    const vm = this;
}