angular
  .module('app')
  .component('modalAddPayPal', {
    bindings: {
      show: '=',
      partner: '=',
    },
    controller: modalAddPayPal,
    controllerAs: 'vm'
  });

function modalAddPayPal($scope, $mdDialog, payPalService) {
  const vm = this;

  $scope.$watch('vm.show', () => {
    if (vm.show) {
      showModal();
    }
  }); 
  function showModal() {
    $mdDialog
      .show({
        templateUrl: 'modal-add-paypal.tpl.html',
        clickOutsideToClose: true,
        controller: ModalContentCtrl,
        controllerAs: 'vm',
        onComplete:  payPalService.loadPayPal,
        locals: { partner: vm.partner }
      }).catch(() => {})
      .finally(() => {
        vm.show = false;
      });
  }
}

function ModalContentCtrl($timeout, $mdDialog, partner) {
    const vm = this;
    vm.partner = partner;
    vm.cancel = closeModal;
    vm.loading = true;
    doneLoading();
    function doneLoading(){     
      if (typeof(PAYPAL) != 'undefined') {
        vm.loading = false;
      } else {
        $timeout(doneLoading, 3000);
      }
    }

    function closeModal() {
        $mdDialog.cancel();
    }
}